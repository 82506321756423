import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const ForecastVersionFreezeParamsSchema = z.object({
  version_id: z.coerce.number(),
});
export type ForecastVersionFreezeParams = z.infer<typeof ForecastVersionFreezeParamsSchema>;

export type ForecastVersionFreezeResponse = void;

export type ForecastVersionFreezeEndpoint = Endpoint<
  Method.POST,
  `/api/v2/forecasts-version/${ForecastVersionFreezeParams['version_id']}/freeze`,
  ForecastVersionFreezeResponse,
  ForecastVersionFreezeParams,
  undefined,
  undefined
>;
