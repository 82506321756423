import { z } from 'zod';
import {
  CreateMessageRequestSchema,
  Message,
  StaffingCandidateMessage,
  StaffingCandidateMessageSchema,
} from './';
import { Endpoint, Method } from '../../infrastructure';

export const CreateStaffingCandidateMessageRequestSchema = CreateMessageRequestSchema.merge(
  StaffingCandidateMessageSchema.pick({
    staffing_candidate_id: true,
  }),
);
export type CreateStaffingCandidateMessageRequest = z.infer<
  typeof CreateStaffingCandidateMessageRequestSchema
>;

export type CreateStaffingCandidateMessageResponse = Message & StaffingCandidateMessage;

export type CreateStaffingCandidateMessageEndpoint = Endpoint<
  Method.POST,
  '/api/v3/messages/staffing-candidate',
  CreateStaffingCandidateMessageResponse,
  undefined,
  undefined,
  CreateStaffingCandidateMessageRequest
>;
