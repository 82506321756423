import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const SkillGroupAscSchema = z.object({
  skill_group_id: z.number(),
  skill_id: z.number(),
  dt_created: dateString,
  dt_updated: dateString,
});

export type SkillGroupAsc = z.infer<typeof SkillGroupAscSchema>;
