import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import { constants } from '@redux/modules/reports/focus-report';
import * as api from '@redux/api/focus-report';

export function* getFocusReport(action) {
  try {
    const response = yield call(api.getFocusReport, action);
    yield put({
      type: constants.GET_FOCUS_REPORT.SUCCESS,
      focusReport: response.data,
    });
  } catch (e) {
    yield put({
      type: constants.GET_FOCUS_REPORT.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchGetFocusReport() {
  yield takeLatest(constants.GET_FOCUS_REPORT.REQUEST, getFocusReport);
}

export function* createResponsible(action) {
  const { payload } = action;
  try {
    const response = yield call(api.createResponsible, payload);
    yield put({
      type: constants.CREATE_RESPONSIBLE.SUCCESS,
      responsibleRecruiter: response,
    });
  } catch (e) {
    yield put({
      type: constants.CREATE_RESPONSIBLE.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchCreateResponsible() {
  yield takeLatest(constants.CREATE_RESPONSIBLE.REQUEST, createResponsible);
}

export function* removeResponsible(action) {
  const { id } = action;
  try {
    const response = yield call(api.removeResponsible, id);
    yield put({
      type: constants.REMOVE_RESPONSIBLE.SUCCESS,
      id: response.id,
    });
  } catch (e) {
    yield put({
      type: constants.REMOVE_RESPONSIBLE.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchRemoveResponsible() {
  yield takeLatest(constants.REMOVE_RESPONSIBLE.REQUEST, removeResponsible);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetFocusReport),
    fork(watchCreateResponsible),
    fork(watchRemoveResponsible),
  ]);
}
