import { all, fork } from 'redux-saga/effects';
import { watchGet } from './get';
import { watchGetStatuses, watchUpdateStatus, watchGetTechTestStatuses } from './statuses';
import { watchGetBrainsAssignments, watchResetBrainsAssignments } from './brainsAssignments';
import { watchGetCalendlyList } from './calendlyList';
import { watchGetRecruitersList } from './recruitersList';
import { watchGetActiveRecruitersList } from './activeRecruitersList';
import { watchGetContractOptions } from './contractOptions';
import { watchGetApplicationTestList } from './applicationTestList';
import {
  watchCreateApplicationInterview,
  watchDeleteApplicationInterview,
  watchGetInterviewDetails,
  watchGetInterviewList,
  watchScheduleInterview,
  watchScheduleInterviewNotification,
  watchBookCalendlyExtensionInterview,
  watchUpdateApplicationInterview,
  watchResetInterviewDetails,
  watchGetInterviewerAvailability,
  watchResetApplicationInfo,
  watchResetApplicationVideoAnswers,
} from './interview';
import { watchSendTest, watchSetSelectedTestId } from './tests';
import { watchGetSkills, watchUpdateApplicationSkills } from './skills';
import { watchGetTimelineList } from './timeline';
import { watchUpdateApplicationSummary } from './summary';
import { watchGetAssignmentOptions } from './assignments';
import { watchUpdateClock } from './clock';
import { watchDeleteApplicationVideoAnswer, watchGetApplicationVideoAnswers } from './videoAnswers';
import { watchUpdateApplicationJob } from './job';
import { watchUpdateApplicationOwner } from './owner';
import { watchUpdateApplicationAuthor } from './author';
import { watchUpdateApplicationTechInterviewer } from './techInterviewer';
import { watchCreateNewApplicant } from './newApplicant';
import { watchGetUsersList } from './ReferredBy';
import { watchGetSourceList } from './sourceList';
import { watchGetMediumList } from './mediumList';
import { watchGetAdminList } from './adminList';
import { watchGetSourcedByList } from './sourcedByList';
import { watchGetFoundList } from './foundList';
import { watchGetVoiceNoteURL } from './voiceNote';
import { watchUpdateApplicationContractStatus } from './contract-status';
import {
  watchUpdateDevskillerResult,
  watchCancelAssessmentRequest,
  watchArchiveDevskillerTest,
  watchSyncTests,
} from './devskiller';
import { watchUpdateReachDate } from './reachDate';
import { watchSetExcitement } from './excitements';
import { watchSetBackUrl } from './backUrl';
import { watchDeleteApplication, watchReEnableOnboardingForm } from './bottomPanel';
import { watchUploadDocument, watchDeleteDocument, watchDownloadDocument } from './document';
import { watchGetAppliedList } from './getAppliedList';
import {
  watchGetScorecardList,
  watchCreateScorecard,
  watchUpdateScorecard,
  watchDeleteScorecard,
  watchClearCurrentScorecard,
  watchGetScorecardDetails,
} from './scorecard';
import { watchDeleteError } from './error';
import { watchGetDepartmentOptions } from './departmentOptions';
import { watchSetDoNotContactBeforeDate } from './doNotContactBeforeDate';
import { watchSetApplicationReviewed } from './reviewed';
import { watchUpdateApplicationTags, watchGetApplicationTags } from './tags';

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  const sagas = [
    watchGet,
    watchGetStatuses,
    watchGetTechTestStatuses,
    watchGetBrainsAssignments,
    watchResetBrainsAssignments,
    watchGetCalendlyList,
    watchUpdateApplicationTags,
    watchGetApplicationTags,
    watchGetRecruitersList,
    watchGetActiveRecruitersList,
    watchGetContractOptions,
    watchGetApplicationTestList,
    watchUpdateStatus,
    watchUpdateClock,
    watchScheduleInterview,
    watchScheduleInterviewNotification,
    watchBookCalendlyExtensionInterview,
    watchSendTest,
    watchSetSelectedTestId,
    watchGetVoiceNoteURL,
    watchGetSkills,
    watchUpdateApplicationSkills,
    watchGetInterviewList,
    watchGetTimelineList,
    watchUpdateApplicationSummary,
    watchGetAssignmentOptions,
    watchCreateApplicationInterview,
    watchDeleteApplicationInterview,
    watchGetInterviewDetails,
    watchGetApplicationVideoAnswers,
    watchDeleteApplicationVideoAnswer,
    watchUpdateApplicationJob,
    watchUpdateApplicationOwner,
    watchUpdateApplicationAuthor,
    watchUpdateApplicationTechInterviewer,
    watchUpdateApplicationContractStatus,
    watchUpdateApplicationInterview,
    watchResetInterviewDetails,
    watchResetApplicationInfo,
    watchResetApplicationVideoAnswers,
    watchCreateNewApplicant,
    watchGetUsersList,
    watchGetSourceList,
    watchGetMediumList,
    watchGetFoundList,
    watchGetAdminList,
    watchGetSourcedByList,
    watchUpdateReachDate,
    watchSetExcitement,
    watchSetBackUrl,
    watchDeleteApplication,
    watchReEnableOnboardingForm,
    watchUpdateDevskillerResult,
    watchUploadDocument,
    watchDeleteDocument,
    watchGetInterviewerAvailability,
    watchGetAppliedList,
    watchGetScorecardList,
    watchCreateScorecard,
    watchUpdateScorecard,
    watchDeleteScorecard,
    watchClearCurrentScorecard,
    watchGetScorecardDetails,
    watchDownloadDocument,
    watchDeleteError,
    watchCancelAssessmentRequest,
    watchArchiveDevskillerTest,
    watchSyncTests,
    watchGetDepartmentOptions,
    watchSetDoNotContactBeforeDate,
    watchSetApplicationReviewed,
  ].map(fork);

  yield all(sagas);
}
