import { Endpoint, Method } from '../../infrastructure';

import { z } from 'zod';

export const MonthlyCostAllocSchema = z.object({
  person_id: z.string(),
  name: z.string(),
  department: z.string(),
  isbillable: z.boolean(),
  ymonth: z.string(),
  amount: z.number(),
  plan_category: z.string(),
  plan_section: z.string(),
  project_id: z.string(),
  project_name: z.string(),
  client_name: z.string(),
  gl_account_id: z.number(),
  gl_account_name: z.string(),
  paid_by: z.string(),
  total_paid: z.number(),
  hierarchy: z.number(),
});
export type MonthlyCostAlloc = z.infer<typeof MonthlyCostAllocSchema>;

export type MonthlyCostAllocFilters = {
  ymonth: string | null;
};

export type MonthlyCostAllocQuery = {
  filters: MonthlyCostAllocFilters;
};

//Define the response
export type MonthlyCostAllocResponse = { rows: readonly MonthlyCostAlloc[] };

//Define the endpoint
export type MonthlyCostAllocEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/monthly-cost-alloc/',
  MonthlyCostAllocResponse,
  undefined,
  MonthlyCostAllocQuery,
  undefined
>;
