import { Spin } from 'antd';
import React from 'react';

const LoadingSpinner = ({ tip = 'Loading...', padding = 20, textAlign = 'center' }) => {
  return (
    <div style={{ textAlign, padding }}>
      <Spin tip={tip} />
    </div>
  );
};

export default LoadingSpinner;
