import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { SFAccountSchema } from './base';

export const UpdateAccountRequestSchema = SFAccountSchema.pick({
  sf_account_name: true,
});
export type UpdateAccountRequest = z.infer<typeof UpdateAccountRequestSchema>;

export const UpdateAccountParamsSchema = z.object({
  brains_client_id: z.string(),
});
export type UpdateAccountParams = z.infer<typeof UpdateAccountParamsSchema>;

export type UpdateAccountResponse = void;

export type UpdateAccountEndpoint = Endpoint<
  Method.PUT,
  `/api/sf/clients/${UpdateAccountParams['brains_client_id']}`,
  UpdateAccountResponse,
  UpdateAccountParams,
  undefined,
  UpdateAccountRequest
>;
