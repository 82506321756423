import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  JOB_TITLES_LIST: createSagaAction('JOB_TITLES_LIST'),
  JOB_TITLES_CREATE: createSagaAction('JOB_TITLES_CREATE'),
  JOB_TITLES_UPDATE: createSagaAction('JOB_TITLES_UPDATE'),
  JOB_TITLES_DELETE: createSagaAction('JOB_TITLES_DELETE'),
  JOB_TITLES_SORT: createSagaAction('JOB_TITLES_SORT'),
};

// Action Creators
export const actions = {
  getJobTitles: () => ({
    type: constants.JOB_TITLES_LIST.REQUEST,
  }),
  createJobTitle: (args) => ({
    type: constants.JOB_TITLES_CREATE.REQUEST,
    ...args,
  }),
  updateJobTitle: (args) => ({
    type: constants.JOB_TITLES_UPDATE.REQUEST,
    ...args,
  }),
  deleteJobTitle: (args) => ({
    type: constants.JOB_TITLES_DELETE.REQUEST,
    ...args,
  }),
  sortJobTitles: (args) => ({
    type: constants.JOB_TITLES_SORT.REQUEST,
    ...args,
  }),
};

// Reducer
export const initialState = {
  isLoading: false,
  jobTitles: [],
  error: null,
};

const ACTION_HANDLERS = {
  // JOB_TITLES_LIST
  [constants.JOB_TITLES_LIST.REQUEST]: (state) => {
    return { ...state };
  },
  [constants.JOB_TITLES_LIST.SUCCESS]: (state, { jobTitles }) => {
    return { ...state, jobTitles };
  },
  [constants.JOB_TITLES_LIST.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },

  [constants.JOB_TITLES_CREATE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },

  //JOB_TITLES_UPDATE
  [constants.JOB_TITLES_UPDATE.SUCCESS]: (state, { updatedJobTitle }) => {
    //find index to allocate on same position
    const idx = state.jobTitles.findIndex((job) => job._id === updatedJobTitle._id);
    if (idx >= 0) {
      const newJobTitles = [...state.jobTitles];
      newJobTitles[idx] = { ...updatedJobTitle };

      return {
        ...state,
        jobTitles: [...newJobTitles],
      };
    }
    return { ...state };
  },
  [constants.JOB_TITLES_UPDATE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },

  [constants.JOB_TITLES_DELETE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },

  //JOB_TITLES_SORT
  [constants.JOB_TITLES_SORT.SUCCESS]: (state, { jobTitles }) => {
    return { ...state, jobTitles };
  },
  [constants.JOB_TITLES_SORT.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
