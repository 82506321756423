import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const ConvertOppToPreOppParamsSchema = z.object({
  project_id: z.string(),
});
export type ConvertOppToPreOppParams = z.infer<typeof ConvertOppToPreOppParamsSchema>;

export type ConvertOppToPreOppEndpoint = Endpoint<
  Method.POST,
  `/api/v2/sql-leads/${ConvertOppToPreOppParams['project_id']}/convert-to-pre-opp`,
  {},
  ConvertOppToPreOppParams,
  undefined,
  undefined
>;
