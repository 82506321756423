import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const PsnCoachAscSchema = z.object({
  psn_coach_asc_id: z.string(),
  person_id: z.string(),
  coach_id: z.string(),
  dt_start: dateString,
  dt_end: dateString,

  dt_created: dateString,
  dt_updated: dateString,
});
export type PsnCoachAsc = z.infer<typeof PsnCoachAscSchema>;
