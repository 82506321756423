import { z } from 'zod';
import {
  Endpoint,
  Method,
  FilterOperations,
  ReportsQuerySchema,
  makeSorter,
  ReportResponse,
} from '../../infrastructure';
import { dateString } from '../../infrastructure/schema';

export type ListPDFsByRequestsEndpoint = Endpoint<
  Method.GET,
  '/api/v2/pdfs/requests',
  ListPDFsByRequestsResponse,
  undefined,
  ListPDFsByRequestsQuery,
  undefined
>;

export const ListPDFsByRequestsFiltersSchema = z
  .object({
    person_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    year: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
  })
  .partial();
export type ListPDFsByRequestsFilters = z.infer<typeof ListPDFsByRequestsFiltersSchema>;

export const ListPDFsByRequestsSorterSchema = makeSorter(
  z.enum(['firstname', 'surname', 'amount', 'reimbursement_date', 'dtcreated']),
);
export type ListPDFsByRequestsSorter = z.infer<typeof ListPDFsByRequestsSorterSchema>;

export const ListPDFsByRequestsQuerySchema = ReportsQuerySchema.extend({
  sorter: ListPDFsByRequestsSorterSchema.optional(),
  filters: ListPDFsByRequestsFiltersSchema.optional(),
});
export type ListPDFsByRequestsQuery = z.infer<typeof ListPDFsByRequestsQuerySchema>;

export const ListPDFsByRequestsSchema = z.object({
  pdf_id: z.coerce.number(),
  person: z.object({
    _id: z.string(),
    name: z.string(),
    firstname: z.string(),
    surname: z.string(),
    gravatarURL: z.string(),
  }),
  year: z.number(),
  amount: z.number().finite(),
  reason: z.string(),
  receipt_url: z.string().nullable(),
  reimbursement_date: dateString.nullable(),
  dtcreated: dateString.nullable(),
  dtupdated: dateString.nullable(),
});

export type ListPDFByRequests = z.infer<typeof ListPDFsByRequestsSchema>;

export type ListPDFsByRequestsResponse = ReportResponse<ListPDFByRequests>;
