import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  CALENDLY_USERS_GET_ALL: createSagaAction('CALENDLY_USERS_GET_ALL'),
  CALENDLY_USERS_GET: createSagaAction('CALENDLY_USERS_GET'),
  CALENDLY_USERS_GET_BY_USER: createSagaAction('CALENDLY_USERS_GET_BY_USER'),
  CALENDLY_USERS_DELETE: createSagaAction('CALENDLY_USERS_DELETE'),
  CALENDLY_USERS_SAVE: createSagaAction('CALENDLY_USERS_SAVE'),
  CALENDLY_USERS_GET_ALL_TYPES: createSagaAction('CALENDLY_USERS_GET_ALL_TYPES'),
  CALENDLY_USERS_GET_ALL_CATEGORIES: createSagaAction('CALENDLY_USERS_GET_ALL_CATEGORIES'),
  CALENDLY_USERS_GET_ALL_EMAIL_TEMPLATES: createSagaAction(
    'CALENDLY_USERS_GET_ALL_EMAIL_TEMPLATES',
  ),
  CALENDLY_USERS_UPDATE_EVENT_TYPE: createSagaAction('CALENDLY_USERS_UPDATE_EVENT_TYPE'),
};

// Action Creators
export const actions = {
  getAllCalendlyUsers: () => ({
    type: constants.CALENDLY_USERS_GET_ALL.REQUEST,
  }),
  getCalendlyUser: (args) => ({
    type: constants.CALENDLY_USERS_GET.REQUEST,
    ...args,
  }),
  getCalendlyUserByUserId: (args) => ({
    type: constants.CALENDLY_USERS_GET_BY_USER.REQUEST,
    ...args,
  }),
  deleteCalendlyUser: (args) => ({
    type: constants.CALENDLY_USERS_DELETE.REQUEST,
    ...args,
  }),
  save: (args) => ({
    type: constants.CALENDLY_USERS_SAVE.REQUEST,
    ...args,
  }),
  getTypes: (args) => ({
    type: constants.CALENDLY_USERS_GET_ALL_TYPES.REQUEST,
    ...args,
  }),
  getCategories: (args) => ({
    type: constants.CALENDLY_USERS_GET_ALL_CATEGORIES.REQUEST,
    ...args,
  }),
  getEmailTemplates: (args) => ({
    type: constants.CALENDLY_USERS_GET_ALL_EMAIL_TEMPLATES.REQUEST,
    ...args,
  }),
  updateEventType: (args) => ({
    type: constants.CALENDLY_USERS_UPDATE_EVENT_TYPE.REQUEST,
    ...args,
  }),
};

// Reducer
export const initialState = {
  isLoading: false,
  error: null,
  calendlyUsers: [],
  calendlyUser: {},

  isLoadingTypes: false,
  types: [],
  categories: [],

  isLoadingEmailTemplates: false,
  emailTemplates: [],
};

const ACTION_HANDLERS = {
  // ALL
  [constants.CALENDLY_USERS_GET_ALL.REQUEST]: (state) => {
    return { ...state, isLoading: true, error: null };
  },
  [constants.CALENDLY_USERS_GET_ALL.SUCCESS]: (state, { calendlyUsers }) => {
    return { ...state, calendlyUsers, isLoading: false, error: null };
  },
  [constants.CALENDLY_USERS_GET_ALL.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  // GET
  [constants.CALENDLY_USERS_GET.REQUEST]: (state) => {
    return { ...state, calendlyUser: {}, isLoading: true, error: null };
  },
  [constants.CALENDLY_USERS_GET.SUCCESS]: (state, { calendlyUser }) => {
    return { ...state, calendlyUser, isLoading: false, error: null };
  },
  [constants.CALENDLY_USERS_GET.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.CALENDLY_USERS_GET_BY_USER.REQUEST]: (state) => {
    return { ...state, calendlyUser: {}, isLoading: true, error: null };
  },
  [constants.CALENDLY_USERS_GET_BY_USER.SUCCESS]: (state, { calendlyUser }) => {
    return { ...state, calendlyUser, isLoading: false, error: null };
  },
  [constants.CALENDLY_USERS_GET_BY_USER.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  // DELETE
  [constants.CALENDLY_USERS_DELETE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  // SAVE
  [constants.CALENDLY_USERS_SAVE.REQUEST]: (state) => {
    return { ...state, error: null };
  },
  [constants.CALENDLY_USERS_SAVE.SUCCESS]: (state, { calendlyUser }) => {
    return { ...state, error: null, calendlyUser };
  },
  [constants.CALENDLY_USERS_SAVE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  // TYPES
  [constants.CALENDLY_USERS_GET_ALL_TYPES.REQUEST]: (state) => {
    return { ...state, isLoadingTypes: true, error: null };
  },
  [constants.CALENDLY_USERS_GET_ALL_TYPES.SUCCESS]: (state, { types }) => {
    return { ...state, types, isLoadingTypes: false, error: null };
  },
  [constants.CALENDLY_USERS_GET_ALL_TYPES.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  // CATEGORIES
  [constants.CALENDLY_USERS_GET_ALL_CATEGORIES.REQUEST]: (state) => {
    return { ...state, isLoadingCategories: true, error: null };
  },
  [constants.CALENDLY_USERS_GET_ALL_CATEGORIES.SUCCESS]: (state, { categories }) => {
    return { ...state, categories, isLoadingCategories: false, error: null };
  },
  [constants.CALENDLY_USERS_GET_ALL_CATEGORIES.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  // EMAIL TEMPLATES
  [constants.CALENDLY_USERS_GET_ALL_EMAIL_TEMPLATES.REQUEST]: (state) => {
    return { ...state, isLoadingEmailTemplates: true, error: null };
  },
  [constants.CALENDLY_USERS_GET_ALL_EMAIL_TEMPLATES.SUCCESS]: (state, { emailTemplates }) => {
    return { ...state, emailTemplates, isLoadingEmailTemplates: false, error: null };
  },
  [constants.CALENDLY_USERS_GET_ALL_EMAIL_TEMPLATES.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  // UPDATE EVENT TYPE
  [constants.CALENDLY_USERS_UPDATE_EVENT_TYPE.REQUEST]: (state) => {
    return { ...state, error: null };
  },
  [constants.CALENDLY_USERS_UPDATE_EVENT_TYPE.SUCCESS]: (state, { calendlyUser }) => {
    return { ...state, calendlyUser, error: null };
  },
  [constants.CALENDLY_USERS_UPDATE_EVENT_TYPE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
