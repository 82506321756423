import { z } from 'zod';
export const ISO_DATE_REGEX = /\d{4}-[01]\d-[0-3]\d/;

export const dateString = z.string().regex(ISO_DATE_REGEX, 'date must be a valid ISO date');

const transformStringToBoolean = (element: string) => {
  if ([true, 'true', 1, '1'].includes(element)) {
    return true;
  }

  if ([false, 'false', 0, '0'].includes(element)) {
    return false;
  }
  return undefined;
};
export const stringToBoolean = z.boolean().or(z.string().transform(transformStringToBoolean));

export const NumberRangeSchema = z.object({
  from: z.coerce.number(),
  to: z.coerce.number(),
});
export type NumberRange = z.infer<typeof NumberRangeSchema>;
