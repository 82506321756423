import { PrjGoogleCalendarSchema } from '../base';
import { z } from 'zod';

export const PrjGoogleCalendarCreateSchema = PrjGoogleCalendarSchema.pick({
  project_id: true,
  google_calendar_id: true,
  title: true,
});

export type PrjGoogleCalendarCreate = z.infer<typeof PrjGoogleCalendarCreateSchema>;
