import { z } from 'zod';
import { SkillGroupSchema } from './base';

export const SkillGroupUpdateSchema = SkillGroupSchema.pick({
  mongo_skill_group_id: true,
  name: true,
  color: true,
});

export type SkillGroupUpdate = z.infer<typeof SkillGroupUpdateSchema>;
