// GET STATUSES

import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '@redux/api/application';
import { constants } from '@redux/modules/application/application';
import * as schemas from '@src/schemas';
import isFunction from 'lodash/isFunction';
import { actions as entitiesActions } from '@redux/modules/entities';
import { normalize } from 'normalizr';

// CLOCK APPLICATION

function* updateClock(action) {
  try {
    const response = yield call(api.updateClock, action);
    const normalized = normalize(response.application, schemas.application);
    const { applications } = normalized.entities;

    yield put(entitiesActions.mergeApplications(applications));

    yield put({ type: constants.APPLICATION_CLOCK_UPDATE.SUCCESS });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.APPLICATION_CLOCK_UPDATE.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail();
    }
  }
}

export function* watchUpdateClock() {
  yield takeLatest(constants.APPLICATION_CLOCK_UPDATE.REQUEST, updateClock);
}
