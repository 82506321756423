import { z } from 'zod';
import { PrjGoogleCalendar, PrjGoogleCalendarUpdateSchema } from '..';
import { Endpoint, Method } from '../../../infrastructure';

export const PrjGoogleCalendarUpdateRequestSchema = PrjGoogleCalendarUpdateSchema.pick({
  title: true,
});
export type PrjGoogleCalendarUpdateRequest = z.infer<typeof PrjGoogleCalendarUpdateRequestSchema>;

export const PrjGoogleCalendarUpdateParamsSchema = z.object({
  prj_google_calendar_id: z.coerce.number(),
});
export type PrjGoogleCalendarUpdateParams = z.infer<typeof PrjGoogleCalendarUpdateParamsSchema>;

export type PrjGoogleCalendarUpdateResponse = PrjGoogleCalendar;

export type PrjGoogleCalendarUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/prj-google-calendars/${PrjGoogleCalendarUpdateParams['prj_google_calendar_id']}`,
  PrjGoogleCalendarUpdateResponse,
  PrjGoogleCalendarUpdateParams,
  undefined,
  PrjGoogleCalendarUpdateRequest
>;
