import { z } from 'zod';
import { InterviewCalendarEvent, InterviewCalendarEventUpdateSchema } from '../';
import { Endpoint, Method } from '../../../infrastructure';

export const InterviewCalendarEventUpdateRequestSchema = InterviewCalendarEventUpdateSchema.pick({
  calendly_event_id: true,
  google_calendar_event_id: true,
  zoom_id: true,

  status: true,
  attendee_response: true,

  dt_start: true,
  dt_end: true,
  dt_canceled: true,
  dt_conducted: true,
  act_recruiter_id: true,
  dt_last_google_update: true,
});
export type InterviewCalendarEventUpdateRequest = z.infer<
  typeof InterviewCalendarEventUpdateRequestSchema
>;

export const InterviewCalendarEventUpdateParamsSchema = z.object({
  interview_calendar_event_id: z.coerce.number(),
});
export type InterviewCalendarEventUpdateParams = z.infer<
  typeof InterviewCalendarEventUpdateParamsSchema
>;

export type InterviewCalendarEventUpdateResponse = InterviewCalendarEvent;

export type InterviewCalendarEventUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/interview-calendar-events/${InterviewCalendarEventUpdateParams['interview_calendar_event_id']}`,
  InterviewCalendarEventUpdateResponse,
  InterviewCalendarEventUpdateParams,
  undefined,
  InterviewCalendarEventUpdateRequest
>;
