import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  GET_STATUSES_REPORT: createSagaAction('GET_STATUSES_REPORT'),
};

// Action Creators
export const actions = {
  getStatusesReport: (from, to) => ({
    type: constants.GET_STATUSES_REPORT.REQUEST,
    from,
    to,
  }),
};

// Reducer
export const initialState = {
  actions: [],
  isLoading: false,
  error: null,
};

const ACTION_HANDLERS = {
  [constants.GET_STATUSES_REPORT.SUCCESS]: (state, { actions }) => {
    return { ...state, actions, isLoading: false };
  },
  [constants.GET_STATUSES_REPORT.FAILURE]: (state, { error }) => {
    return { ...state, error, isLoading: false };
  },
  [constants.GET_STATUSES_REPORT.REQUEST]: (state) => ({ ...state, isLoading: true }),
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
