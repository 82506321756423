import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../../infrastructure';

export const PlanCloudServiceItemSchema = z.object({
  index: z.number(),
  section: z.string(),
  yyear: z.number(),
  ymonth: z.number(),
  tag_id: z.string(),
  amount: z.number(),
  is_percent: z.boolean(),
});
export type PlanCloudServiceItem = z.infer<typeof PlanCloudServiceItemSchema>;

export const PlanCloudServiceFiltersSchema = z.object({
  ymonth_period_start: z
    .record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth_period_end: z
    .record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
});
export type PlanCloudServiceFilters = z.infer<typeof PlanCloudServiceFiltersSchema>;

export const PlanCloudServiceQuerySchema = z.object({
  filters: PlanCloudServiceFiltersSchema.optional(),
});
export type PlanCloudServiceQuery = z.infer<typeof PlanCloudServiceQuerySchema>;

export type PlanCloudServiceResponse = PlanCloudServiceItem[];

export type PlanCloudServiceEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/monthly-plan-v2/cloud-service',
  PlanCloudServiceResponse,
  undefined,
  PlanCloudServiceQuery,
  undefined
>;
