import { z } from 'zod';
import { dateString } from '../../infrastructure';

export enum AppActionTypes {
  ReviewApp = 'ReviewApp',
  Sched1Int = 'Sched1Int',
  '1Int' = '1Int',
  'Sched2Int' = 'Sched2Int',
  '2Int' = '2Int',
  'SchedTest' = 'SchedTest',
  TakeTest = 'TakeTest',
  PlaceOnDeck = 'PlaceOnDeck',
  'RemoveOnDeck' = 'RemoveOnDeck',
  'RejectApp' = 'RejectApp',
  'Candidate' = 'Candidate',
  'CandidateRatingA' = 'CandidateRatingA',
  'CandidateRatingB' = 'CandidateRatingB',
  'CandidateRatingC' = 'CandidateRatingC',
  'CandidateRatingRemove' = 'CandidateRatingRemove',
  'AsnCandidate' = 'AsnCandidate',
  'UnCandidate' = 'UnCandidate',
  'Offer' = 'Offer',
  'SendContract' = 'SendContract',
  'SignContract' = 'SignContract',
  'OnBoard' = 'OnBoard',
}

export const AppActionSchema = z.object({
  app_action_id: z.number(),
  action_name: z.nativeEnum(AppActionTypes),
  application_id: z.string(),
  action_recruiter_id: z.string().nullable(),
  dt_action: dateString,
  result: z.record(z.string()).nullable(),
  dt_created: dateString,
  dt_updated: dateString,
});
export type AppAction = z.infer<typeof AppActionSchema>;
