import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { TimeOffRequest } from '../base';

export const TimeOffRequestApproveMultipleRequestSchema = z.object({
  time_off_requests_id: z.array(z.number()),
  approved_by: z.string(),
});

export type TimeOffRequestApproveMultipleRequest = z.infer<
  typeof TimeOffRequestApproveMultipleRequestSchema
>;

export type TimeOffRequestApproveMultipleResponse = TimeOffRequest[];

export type TimeOffRequestApproveMultipleEndpoint = Endpoint<
  Method.POST,
  '/api/v2/time-off-requests/approve-multiple',
  TimeOffRequestApproveMultipleResponse,
  undefined,
  undefined,
  TimeOffRequestApproveMultipleRequest
>;
