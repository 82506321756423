import { z } from 'zod';
import { dateString } from '../../../infrastructure';

export const GCECreateEventSchema = z.object({
  user: z.any(),
  calendar_id: z.string(),
  recruiter_email: z.string(),
  application_name: z.string(),
  application_email: z.string(),
  application_id: z.string(),
  zoom_link: z.string(),
  token: z.string(),
  dt_start: dateString,
  dt_end: dateString,
  calendly_reschedule_url: z.string().nullish(),
  calendly_cancel_url: z.string().nullish(),
});
export type GCECreateEvent = z.infer<typeof GCECreateEventSchema>;
