import { z } from 'zod';
import { Endpoint, Method } from '../../..';

export const ForecastVersionDeleteParamsSchema = z.object({
  version_id: z.coerce.number(),
});
export type ForecastVersionDeleteParams = z.infer<typeof ForecastVersionDeleteParamsSchema>;

export type ForecastVersionDeleteEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/forecasts-version/${ForecastVersionDeleteParams['version_id']}`,
  void,
  ForecastVersionDeleteParams,
  undefined,
  undefined
>;
