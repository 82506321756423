import { z } from 'zod';
import { BaseSchemaDW } from '../structure';

export const ApplicationStatusHistoryDWSchema = z
  .object({
    status_history_id: z.number().nullable(),
    application_id: z.number(),
    status_code: z.number(),
    yweek: z.string(),
    ymonth: z.string(),
    performer: z.string().nullable(),
    prev_status_code: z.number().nullable(),
    end_timestamp: z.date().nullable(),
    cycle_id: z.number().nullish(),
    person_termination: z.number().nullable(),
  })
  .merge(BaseSchemaDW);
export type ApplicationStatusHistoryDW = z.infer<typeof ApplicationStatusHistoryDWSchema>;

export const ApplicationStatusHistoryMongoSchema = z.object({
  endDate: z.date().nullable(),
  startDate: z.date(),
  oldStatus: z.number().nullable(),
  newStatus: z.number(),
  applicationId: z.number(),
  owner: z.string().nullable(),
});

export type ApplicationStatusHistoryMongo = z.infer<typeof ApplicationStatusHistoryMongoSchema>;
