import { z } from 'zod';

export const UploadTemplateSchema = z.object({
  url: z.string(),
});
export type UploadTemplate = z.infer<typeof UploadTemplateSchema>;

export const UploadBadgeGrantsSchema = z.object({
  fileUpstream: z.any(),
});
export type UploadBadgeGrants = z.infer<typeof UploadBadgeGrantsSchema>;

export const UploadPayoutsSchema = z.object({
  fileUpstream: z.any(),
});
export type UploadPayouts = z.infer<typeof UploadPayoutsSchema>;

export const UploadRaiseRequestsSchema = z.object({
  fileUpstream: z.any(),
});
export type UploadRaiseRequests = z.infer<typeof UploadRaiseRequestsSchema>;
