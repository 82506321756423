import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const ClientForecastDeleteRequestSchema = z.object({
  version_id: z.number().nullish(),
});
export type ClientForecastDeleteRequest = z.infer<typeof ClientForecastDeleteRequestSchema>;

export const ClientForecastDeleteParamsSchema = z.object({
  client_id: z.string(),
});

export type ClientForecastDeleteParams = z.infer<typeof ClientForecastDeleteParamsSchema>;

export type ClientForecastDeleteResponse = void;

export type ClientForecastDeleteEndpoint = Endpoint<
  Method.DELETE,
  `/api/clients/${ClientForecastDeleteParams['client_id']}/forecast`,
  ClientForecastDeleteResponse,
  ClientForecastDeleteParams,
  undefined,
  ClientForecastDeleteRequest
>;
