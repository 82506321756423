/* eslint-disable no-console */
import io from 'socket.io-client';
import config from '@src/config';
import { getUser } from '@redux/store';

const { API_BASE } = config;

let socket = null;

const getSocket = (autoReconnect) => {
  // Create new WebSocketWrapper
  const socket = io(API_BASE, {
    transports: ['websocket'],
    autoConnect: true,
    reconnection: true,
    reconnectionDelay: 10000,
    reconnectionDelayMax: 10000,

    // if you are in a CORS situation (the front domain is different from the server domain) and session affinity is achieved with a cookie, you need to allow credentials:
    withCredentials: true,

    // ingnore self-signed sert on local env
    rejectUnauthorized: config.ENV !== 'local',

    auth: (cb) => {
      const user = getUser();
      const auth = getSocketAuthParams(user);
      cb(auth);
    },
  });

  return socket;
};

const connect = () => {
  if (socket) {
    return socket;
  }

  socket = getSocket();

  window.socketIo = socket;

  socket.on('connect', () => {
    console.log('Socket.io connected');
  });

  socket.on('reconnect_attempt', (attempt) => {
    console.log('Socket.io reconnect attempt:' + attempt);
  });

  socket.on('disconnect', () => {
    console.log('Socket.io disconnected');
  });

  return socket;
};

const getSocketIoClient = async () => {
  if (!socket) {
    return connect();
  } else {
    return socket;
  }
};

const getSocketAuthParams = (user) => {
  if (!user) {
    return null;
  }

  return {
    'jwt-token': user.accessToken,
  };
};

export { getSocketIoClient, getSocketAuthParams };
