import { call, put, takeEvery } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  INTERVIEW_OPTIONS_GET: createSagaAction('INTERVIEW_OPTIONS_GET'),
};

const actions = {
  getInterviewOptions: (data) => ({
    ...data,
    type: constants['INTERVIEW_OPTIONS_GET'].REQUEST,
  }),
};

const handlers = {
  [constants.INTERVIEW_OPTIONS_GET.REQUEST]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [constants.INTERVIEW_OPTIONS_GET.SUCCESS]: (state, action) => {
    return { ...state, isLoading: false, interviewOptions: action.interviewOptions };
  },
  [constants.INTERVIEW_OPTIONS_GET.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

// GET INTERVIEW OPTIONS
// we used one from here https://github.com/clevertech/brain/blob/feature/restructurize-redux/hire-react/src/redux/sagas/application/interview.js#L125

function* getInterviewOptions(action) {
  try {
    const interviewOptions = yield call(apiGetApplicationInterviewOptions, action);

    yield put({
      type: constants.INTERVIEW_OPTIONS_GET.SUCCESS,
      interviewOptions,
    });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.INTERVIEW_OPTIONS_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetInterviewOptions() {
  yield takeEvery(constants.INTERVIEW_OPTIONS_GET.REQUEST, getInterviewOptions);
}

// API

export function apiGetApplicationInterviewOptions() {
  return request('/applications/interview-options', {
    method: 'GET',
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchGetInterviewOptions,

  // optionally we may
  // api: {
  //   getAccount: apiGetAccount
  // }
};
