import { z } from 'zod';
import {
  Endpoint,
  Method,
  FilterOperations,
  ReportsQuerySchema,
  makeSorter,
  ReportResponse,
} from '../../infrastructure';
import { dateString } from '../../infrastructure/schema';

export type ListPayoutsEndpoint = Endpoint<
  Method.GET,
  '/api/v2/payouts',
  ListPayoutsResponse,
  undefined,
  ListPayoutsQuery,
  undefined
>;

export const ListPayoutsFiltersSchema = z
  .object({
    person_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    payout_type_code: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN, FilterOperations.NOT_IN]),
      z.string().or(z.array(z.string())),
    ),
    acct_cost_code: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    target_pay_date: z.record(
      z.enum([FilterOperations.GREATER_THAN_OR_EQUAL, FilterOperations.LESS_THAN_OR_EQUAL]),
      dateString,
    ),
  })
  .partial();
export type ListPayoutsFilters = z.infer<typeof ListPayoutsFiltersSchema>;

export const ListPayoutsSorterSchema = makeSorter(
  z.enum([
    'firstname',
    'surname',
    'amount',
    'target_pay_date',
    'acct_cost_code',
    'payout_type_code',
    'description',
  ]),
);
export type ListPayoutsSorter = z.infer<typeof ListPayoutsSorterSchema>;

export const ListPayoutsQuerySchema = ReportsQuerySchema.extend({
  sorter: ListPayoutsSorterSchema.optional(),
  filters: ListPayoutsFiltersSchema.optional(),
});
export type ListPayoutsQuery = z.infer<typeof ListPayoutsQuerySchema>;

export const ListPayoutSchema = z.object({
  payout_id: z.number(),
  person_id: z.string(),
  firstname: z.string(),
  surname: z.string(),
  acct_cost_code: z.string(),
  meta: z.any().nullable(),
  request_date: dateString,
  target_pay_date: dateString,
  paid_date: dateString.nullable(),
  amount: z.number(),
  description: z.string(),
  created_by: z.string().nullable(),
  approved_by: z.string().nullable(),
  approved_date: dateString.nullable(),
  dtcreate: dateString.nullable(),
  dtupdate: dateString.nullable(),
  ymonth: z.number(),
  is_paid_immediately: z.boolean(),
  payout_type_code: z.string(),
  department: z.string(),
  isbillable: z.boolean(),
  paid_by: z.string(),
  pay_method: z.string(),
  dtterminated: dateString.nullable(),
});

export type ListPayout = z.infer<typeof ListPayoutSchema>;

export const ListPayoutWithPersonSchema = ListPayoutSchema.extend({
  person: z.object({
    _id: z.string(),
    name: z.string(),
    gravatarURL: z.string(),
  }),
});

export type ListPayoutWithPerson = z.infer<typeof ListPayoutWithPersonSchema>;

export type ListPayoutsResponse = ReportResponse<ListPayoutWithPerson>;
