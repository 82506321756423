import { z } from 'zod';
import { ProjectSchema } from './base';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';

//Base
export const ListProjectsQuarterlyMarginSchema = ProjectSchema.pick({
  project_id: true,
  client_id: true,
}).extend({
  projet_name: z.string(),
  client_name: z.string(),
  ymonth: z.number(),
  cost: z.number(),
  revenue: z.number(),
  margin: z.number(),
  off_from50: z.number(),
});
export type ListProjectsQuarterlyMargin = z.infer<typeof ListProjectsQuarterlyMarginSchema>;

//Filters
export const ListProjectsQuarterlyMarginFiltersSchema = z.object({
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
});
export type ListProjectsQuarterlyMarginFilters = z.infer<
  typeof ListProjectsQuarterlyMarginFiltersSchema
>;

//Query
export const ListProjectsQuarterlyMarginQuerySchema = z.object({
  filters: ListProjectsQuarterlyMarginFiltersSchema.optional(),
});
export type ListProjectsQuarterlyMarginQuery = z.infer<
  typeof ListProjectsQuarterlyMarginQuerySchema
>;

//Response
export type ListProjectsQuarterlyMarginResponse = ListProjectsQuarterlyMargin[];

//Endpoint
export type ListProjectsQuarterlyMarginEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-projects/quarterly-margin',
  ListProjectsQuarterlyMarginResponse,
  undefined,
  ListProjectsQuarterlyMarginQuery,
  undefined
>;
