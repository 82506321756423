import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const PersonSalarySchema = z.object({
  cost_usd: z.number().nullable(),
  currency: z.string().nullable(),
  currency_salary: z.number().nullable(),
  usd_salary: z.number().nullable(),
  contract_type: z.string().nullable(),
});

export const PersonSalaryQuerySchema = z.object({ personId: z.string() });

type PersonSalaryResponse = z.infer<typeof PersonSalarySchema>;

type PersonSalaryQuery = z.infer<typeof PersonSalaryQuerySchema>;

export type PersonSalaryEndpoint = Endpoint<
  Method.GET,
  `/api/v2/person-salary`,
  PersonSalaryResponse,
  undefined,
  PersonSalaryQuery,
  undefined
>;
