import { z } from 'zod';
import { PsnDepartmentSchema } from './base';

export const PsnDepartmentCreateSchema = PsnDepartmentSchema.pick({
  person_id: true,
  department_id: true,
  dt_start: true,
});

export type PsnDepartmentCreate = z.infer<typeof PsnDepartmentCreateSchema>;
