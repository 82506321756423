import { z } from 'zod';
import { PositionTemplateUpdateSchema } from '../crud';
import { PositionTemplate } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const PositionTemplateUpdateRequestSchema = PositionTemplateUpdateSchema.pick({
  english_level: true,
  name: true,
  position_name: true,
  revenue_amount: true,
  seniority: true,
  skills_id: true,
  target_cost: true,
});
export type PositionTemplateUpdateRequest = z.infer<typeof PositionTemplateUpdateRequestSchema>;

export const PositionTemplateUpdateParamsSchema = z.object({
  position_template_id: z.coerce.number(),
});
export type PositionTemplateUpdateParams = z.infer<typeof PositionTemplateUpdateParamsSchema>;

export type PositionTemplateUpdateResponse = PositionTemplate;

export type PositionTemplateUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/position-template/${PositionTemplateUpdateParams['position_template_id']}`,
  PositionTemplateUpdateResponse,
  PositionTemplateUpdateParams,
  undefined,
  PositionTemplateUpdateRequest
>;
