// # formatting - little library to format numbers into string,
// NOTE: it shouldn't have dependencies in '@require' array,
// because we use it on frontend as well
import isString from 'lodash/isString';
import isNull from 'lodash/isNull';

const delimiters = {
  thousands: ',',
  decimals: '.',
};

const parseDecimals = (decimals) => {
  // /^~?(\d*)$/.exec('~1') => [ '~1', '1' ]
  const reDecimalsIn = /^~?(\d*)$/;
  if (!decimals) {
    return 0;
  }

  return parseInt(reDecimalsIn.exec(decimals)[1], 10) || 0;
};

const formatNumber = (_x, decimals = 2, removeTrailingZeros = false) => {
  // allow to format numbers and strings only
  const x = isString(_x) ? _parseFloat(_x) : _x;

  if (isNull(x) || !isFinite(x)) {
    return '-';
  }

  const decimalsNum = parseDecimals(decimals);

  let parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, delimiters.thousands);
  parts[1] = parts[1] ? parts[1].slice(0, decimalsNum) : '';

  // fill end of line with zeros
  const diff = (parts[1] && parts[1].length) || 0;
  if (decimalsNum && diff < decimalsNum) {
    const a = [];
    let k = 0;
    while (k < decimalsNum - diff) {
      a[k++] = '0';
    }

    parts[1] += a.join('');
  }

  parts = parts.filter(Boolean);
  let formattedNumber = parts.join(delimiters.decimals);

  if (/^~/.test(decimals) || removeTrailingZeros) {
    formattedNumber = formattedNumber.replace(/(\.\d*?[1-9])0+$|\.(0)+$/, '$1');
  }

  return formattedNumber;
};

const formatNumberK = (num, decimals = 0, removeTrailingZeros = false) => {
  return formatNumber(_round((num || 0) / 1000, decimals), decimals, removeTrailingZeros);
};

const formatMoney = (_x, decimals, currency = '$') => {
  // allow to format numbers and strings only
  const x = isString(_x) ? _parseFloat(_x) : _x;

  if (isNull(x) || !isFinite(x)) {
    return '-';
  }

  let prefix = currency;
  if (x < 0) {
    prefix = `-${prefix}`;
  }

  return prefix + formatNumber(x, decimals).replace(/^-/, '');
};

const formatMoneyK = (_x, decimals, currency = '$', postfix = 'k') => {
  // allow to format numbers and strings only
  const x = isString(_x) ? _parseFloat(_x) : _x;

  if (isNull(x) || !isFinite(x)) {
    return '-';
  }

  return `${formatMoney((x || 0) / 1000, decimals, currency)}${postfix}`;
};

function _parseFloat(_str) {
  let str = _str;
  str = (str || 0).toString().replace(/[^\d.\-eE+]/g, '');
  return parseFloat(str);
}

function _round(num, _precision) {
  let precision = _precision;
  precision = precision || 0;
  return +`${Math.round(+`${num}e${precision}`)}e${-precision}` || 0;
}

function _toFixed(num, _precision) {
  let precision = _precision;
  precision = precision || 0;
  return _round(num, precision).toFixed(precision);
}

function sanitize(string) {
  // eslint-disable-next-line no-control-regex
  return (string || '').toString().replace(/[^\x00-\x7F]/g, '');
}

export default {
  formatNumber,
  formatNumberK,
  formatMoney,
  formatMoneyK,
  parseFloat: _parseFloat,
  round: _round,
  toFixed: _toFixed,
  sanitize,
};
