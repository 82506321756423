import { z } from 'zod';
import { AssignmentMongoSchema, AssignmentSchema } from './base';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';

export const ListCurrentAssignmentByProjectSchema = AssignmentSchema.extend({
  person_name: z.string(),
  person_salary: z.number(),
  role: z.string(),
  gravatar_url: z.string(),
  mongoAssignment: AssignmentMongoSchema,
});

export type ListCurrentAssignmentByProject = z.infer<typeof ListCurrentAssignmentByProjectSchema>;

//Filters
export const ListCurrentAssignmentByProjectFiltersSchema = z.object({
  project_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
});
export type ListCurrentAssignmentByProjectFilters = z.infer<
  typeof ListCurrentAssignmentByProjectFiltersSchema
>;

//Query
export const ListCurrentAssignmentByProjectQuerySchema = z.object({
  filters: ListCurrentAssignmentByProjectFiltersSchema,
});

export type ListCurrentAssignmentByProjectQuery = z.infer<
  typeof ListCurrentAssignmentByProjectQuerySchema
>;

//Response
export type ListCurrentAssignmentByProjectResponse = ListCurrentAssignmentByProject[];

//Endpoint
export type ListCurrentAssignmentByProjectEndpoint = Endpoint<
  Method.GET,
  '/api/v2/assignments/project-current-assignments',
  ListCurrentAssignmentByProjectResponse,
  undefined,
  ListCurrentAssignmentByProjectQuery,
  undefined
>;
