import { z } from 'zod';
import { Endpoint, Method } from '../../..';

import { ForecastVersion, ForecastVersionUpdateSchema } from '../';

export const ForecastVersionUpdateRequestSchema = ForecastVersionUpdateSchema;
export type ForecastVersionUpdateRequest = z.infer<typeof ForecastVersionUpdateRequestSchema>;

export const ForecastVersionUpdateParamsSchema = z.object({
  version_id: z.coerce.number(),
});
export type ForecastVersionUpdateParams = z.infer<typeof ForecastVersionUpdateParamsSchema>;

export type ForecastVersionUpdateResponse = ForecastVersion;

export type ForecastVersionUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/forecasts-version/${ForecastVersionUpdateParams['version_id']}`,
  ForecastVersionUpdateResponse,
  ForecastVersionUpdateParams,
  undefined,
  ForecastVersionUpdateRequest
>;
