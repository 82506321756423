import map from 'lodash/map';
import values from 'lodash/values';
import zipObject from 'lodash/zipObject';
import mapValues from 'lodash/mapValues';
import defaults from 'lodash/defaults';

import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';
import { setActions } from '@redux/modules/_utils';

import ACTION_MAP from './actions';

// Action Creators
const _keys = map(values(ACTION_MAP), 'key');

const _values = map(_keys, (key) => createSagaAction(key));

// Constants
export const constants = zipObject(_keys, _values);

// Action Creators
export const actions = mapValues(ACTION_MAP, (o) => {
  return (data) => defaults({ type: constants[o.key].REQUEST }, data);
});

// Reducer
export const initialState = {
  isLoading: false,
  isUploading: false,
  isLoadingVoiceNotes: false,
  isOfferTermsLoading: false,
  application: {},
  applicationStatuses: [],
  applicationCalendly: {},
  recruitersList: [],
  activeRecruitersList: [],
  applicationTests: [],
  applicationPast: [],
  applicationSkills: [],
  assignmentOptions: {},
  applicationVideoAnswers: [],
  backUrl: '/admin/dashboard',
  interviewOptions: [],
  interviewList: {},
  interviewerAvailability: {
    interviewer: '',
    date: '',
    list: [],
  },
  voiceNote: '',
  timelineList: [],
  interviewDetails: {},
  referredBy: {},
  adminList: {},
  sourcedByList: {},
  sourceList: {},
  mediumList: {},
  foundList: {},
  appliedList: {},
  contractOptions: [],
  error: null,
  createApplicantResult: '',
  showOnboardingButton: undefined,
  selectedTestId: null,
  isSendingTest: false,
  scorecardList: [],
  currentScorecard: {},
  techTestStatuses: [],
  departmentOptions: {},
  brainsAssignments: [],
  submitApplicationError: null,
};

const actionsDefinitions = values(ACTION_MAP);
const ACTION_HANDLERS = setActions(actionsDefinitions, constants);

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
