// GET CALENDLY LIST

import { call, takeLatest, put } from 'redux-saga/effects';
import * as api from '@redux/api/application';
import { constants } from '@redux/modules/application/application';
import reduce from 'lodash/reduce';

function* getActiveRecruitersList(action) {
  try {
    const activeRecruitersList = yield call(api.getActiveRecruitersList, action);

    const groupedRecruiters = reduce(
      activeRecruitersList.data,
      (grouped, recruiter) => {
        grouped[recruiter.category] = [...(grouped[recruiter.category] || []), recruiter];
        return grouped;
      },
      {},
    );
    yield put({
      type: constants.APPLICATION_ACTIVE_RECRUITERS_LIST.SUCCESS,
      activeRecruitersList: groupedRecruiters,
    });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_ACTIVE_RECRUITERS_LIST.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetActiveRecruitersList() {
  yield takeLatest(constants.APPLICATION_ACTIVE_RECRUITERS_LIST.REQUEST, getActiveRecruitersList);
}
