import { Select } from 'antd';

type NoticePeriodUnit = 'days' | 'weeks';

const NoticePeriodUnitSelect = ({
  defaultValue,
  value,
  onSelect,
}: {
  defaultValue: NoticePeriodUnit;
  value: NoticePeriodUnit;
  onSelect: (value: NoticePeriodUnit) => void;
}) => (
  <Select defaultValue={defaultValue} value={value} onSelect={onSelect}>
    <Select.Option value="days">days</Select.Option>
    <Select.Option value="weeks">weeks</Select.Option>
  </Select>
);

export default NoticePeriodUnitSelect;
