import { Endpoint, Method } from '../../../infrastructure';
import { PsnRctr } from '../base';
import { PsnRctrCreateSchema } from '../crud';
import { z } from 'zod';

export const PsnRctrCreateRequestSchema = PsnRctrCreateSchema.pick({
  person_id: true,
  zoom_link: true,
  rctr_group: true,
  rctr_role: true,
  email: true,
});
export type PsnRctrCreateRequest = z.infer<typeof PsnRctrCreateRequestSchema>;

export type PsnRctrCreateResponse = PsnRctr;

export type PsnRctrCreateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/psn-rctr`,
  PsnRctrCreateResponse,
  undefined,
  undefined,
  PsnRctrCreateRequest
>;
