import { createSagaAction } from '@shared/sagas';
import find from 'lodash/find';
import get from 'lodash/get';
import each from 'lodash/each';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';

// MODULE

const constants = {
  ASSIGNMENTS_REPORT_APPLICATION_REMOVE_ASSIGNMENT: createSagaAction(
    'ASSIGNMENTS_REPORT_APPLICATION_REMOVE_ASSIGNMENT',
  ),
  ASSIGNMENTS_REPORT_APPLICATION_MARK_TO_REMOVE_ASSIGNMENT: createSagaAction(
    'ASSIGNMENTS_REPORT_APPLICATION_MARK_TO_REMOVE_ASSIGNMENT',
  ),
};

const actions = {
  removeApplicationAssignment: (applicationId, assignmentId) => ({
    type: constants.ASSIGNMENTS_REPORT_APPLICATION_REMOVE_ASSIGNMENT.REQUEST,
    applicationId,
    assignmentId,
  }),
  removeApplicationAssignmentSuccess: (applicationId) => ({
    type: constants.ASSIGNMENTS_REPORT_APPLICATION_REMOVE_ASSIGNMENT.SUCCESS,
  }),
  removeApplicationAssignmentFail: (applicationId) => ({
    type: constants.ASSIGNMENTS_REPORT_APPLICATION_REMOVE_ASSIGNMENT.FAILURE,
    applicationId,
  }),
  markToRemoveApplicationAssignment: (applicationId, assignmentId, isToRemove) => ({
    type: constants.ASSIGNMENTS_REPORT_APPLICATION_MARK_TO_REMOVE_ASSIGNMENT.REQUEST,
    applicationId,
    assignmentId,
    isToRemove,
  }),
};

const handlers = {
  [constants.ASSIGNMENTS_REPORT_APPLICATION_REMOVE_ASSIGNMENT.REQUEST]: (
    state,
    { applicationId, assignmentId },
  ) => {
    const leadsAssignmentsData = state.leadsAssignmentsData;
    const leadsAssignmentsDataBackup = cloneDeep(leadsAssignmentsData);
    each(Object.keys(leadsAssignmentsData), (leadId) => {
      const leadPositions = leadsAssignmentsData[leadId];
      for (const positionId in leadPositions) {
        remove(leadPositions[positionId], (app) => {
          const assignment = find(app.assignments, ({ _id }) => _id === assignmentId);
          if (get(assignment, 'position') !== positionId) {
            return false;
          }
          return app._id === applicationId;
        });
      }
    });
    return {
      ...state,
      leadsAssignmentsData: { ...leadsAssignmentsData },
      leadsAssignmentsDataBackup,
      isActionRunning: true,
    };
  },
  [constants.ASSIGNMENTS_REPORT_APPLICATION_REMOVE_ASSIGNMENT.SUCCESS]: (state, action) => {
    return { ...state, isActionRunning: false };
  },
  [constants.ASSIGNMENTS_REPORT_APPLICATION_REMOVE_ASSIGNMENT.FAILURE]: (state, action) => {
    state.leadsAssignmentsData = { ...state.leadsAssignmentsDataBackup };
    delete state.leadsAssignmentsDataBackup;
    return { ...state, isActionRunning: false };
  },
  [constants.ASSIGNMENTS_REPORT_APPLICATION_MARK_TO_REMOVE_ASSIGNMENT.REQUEST]: (
    state,
    { applicationId, assignmentId, isToRemove },
  ) => {
    const leadsAssignmentsData = state.leadsAssignmentsData;
    each(Object.keys(leadsAssignmentsData), (leadId) => {
      const leadPositions = leadsAssignmentsData[leadId];
      for (const positionId in leadPositions) {
        const application = find(leadPositions[positionId], (app) => {
          const assignment = find(app.assignments, ({ _id }) => _id === assignmentId);
          if (get(assignment, 'position') !== positionId) {
            return false;
          }
          return app._id === applicationId;
        });
        if (application) {
          application.isToRemove = isToRemove;
        }
      }
    });
    return {
      ...state,
      leadsAssignmentsData: { ...leadsAssignmentsData },
      isActionRunning: isToRemove,
    };
  },
};

export function* watchNull() {}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchNull,
};
