import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';
import { PayoutTypeLabelSchema } from '../payouts';
import { POCSchema } from '../payment_change_requests/basePOC';

export const PaymentPaymentsSchema = z.object({
  amount: z.number(),
  acct_cost_type: PayoutTypeLabelSchema,
  paid_date: dateString,
});

export type PaymentPayments = z.infer<typeof PaymentPaymentsSchema>;

export const PaymentSchema = z.object({
  person_id: z.string(),
  payment_provider_name: z.string(),
  unpaid_days: z.number(),
  paid_days: z.number(),
  unpaid_leaves_amount: z.number(),
  pending_amount: z.number(),
  paid_amount: z.number(),
  total_amount: z.number(),
  payments: z.array(PaymentPaymentsSchema),
});
export type Payment = z.infer<typeof PaymentSchema>;

export const PaymentWithPersonSchema = PaymentSchema.merge(
  z.object({
    person: z.object({
      _id: z.string(),
      name: z.string(),
      gravatarURL: z.string(),
    }),
  }),
);
export type PaymentWithPerson = z.infer<typeof PaymentWithPersonSchema>;

export const PaymentReconciliationSchema = z.object({
  person_id: z.string(),
  person_name: z.string(),
  company_name: z.string(),
  paid_by: z.string(),
  payment_provider_id: z.number(),
  payment_provider_name: z.string(),
  department: z.string(),
  salary: z.number(),
  reimbursements: z.number(),
  unpaid: z.number(),
  total: z.number(),
  ymonth: z.number(),
  country: z.string(),
  company_id: z.string(),
});
export type PaymentReconciliation = z.infer<typeof PaymentReconciliationSchema>;

export const PaymentProviderReconciliationStatusSchema = z.object({
  payment_provider_id: z.number(),
  payment_provider_name: z.string(),
  isStale: z.boolean(),
});
export type PaymentProviderReconciliationStatus = z.infer<
  typeof PaymentProviderReconciliationStatusSchema
>;

export const PaymentsReconciliationStatusSchema = z.object({
  providers: z.array(PaymentProviderReconciliationStatusSchema),
});
export type PaymentsReconciliationStatus = z.infer<typeof PaymentsReconciliationStatusSchema>;

export const PaymentOptionChangeRequestSchema = z.object({
  _id: z.string(),
  person: z.string(),
  type: z.string(),
  payoneerID: z.string().optional(),
  huskyName: z.string().optional(),
  huskyCPFOrCNPJ: z.string().optional(),
  huskyEmail: z.string().optional(),
  achABA: z.string().optional(),
  achAccount: z.string().optional(),
  wireRoutingNumber: z.string().optional(),
  wireBankAccountNumber: z.string().optional(),
  bankCountryCode: z.string().optional(),
  swiftOrBICCode: z.string().optional(),
  beneficiaryAccountName: z.string().optional(),
  status: z.string(),
  startDate: z.string(),
});
export type PaymentOptionChangeRequest = z.infer<typeof PaymentOptionChangeRequestSchema>;

export const PaymentWithPOCRSchema = PaymentReconciliationSchema.merge(
  z.object({
    paymentOption: POCSchema,
  }),
);
export type PaymentWithPOCR = z.infer<typeof PaymentWithPOCRSchema>;

export const PaymentsTotalSummaryItemSchema = z.object({
  amount: z.number(),
  paid_amount: z.number().optional(),
  companies: z.array(
    z.object({
      company: z.string(),
      amount: z.number(),
      paid_amount: z.number().optional(),
    }),
  ),
});
export type PaymentsTotalSummaryItem = z.infer<typeof PaymentsTotalSummaryItemSchema>;

export const PaymentsTotalSummarySchema = z.object({
  ymonth: z.number(),
  salaries: PaymentsTotalSummaryItemSchema,
  reimbursements: PaymentsTotalSummaryItemSchema,
  total: PaymentsTotalSummaryItemSchema,
});
export type PaymentsTotalSummary = z.infer<typeof PaymentsTotalSummarySchema>;

export const PaymentProviderSchema = z.object({
  payment_provider_id: z.number(),
  name: z.string().max(40, { message: 'Name must be 40 or fewer characters long' }),
});
export type PaymentProvider = z.infer<typeof PaymentProviderSchema>;

export const PaymentProviderPsnAscSchema = z.object({
  person_id: z.string(),
  start_date: dateString,
  payment_provider_id: z.number(),
  name: z.string(),
});
export type PaymentProviderPsnAsc = z.infer<typeof PaymentProviderPsnAscSchema>;
