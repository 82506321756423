import { all, fork } from 'redux-saga/effects';

import reduce from 'lodash/reduce';
import merge from 'lodash/merge';
import map from 'lodash/map';

import { createReducer } from '@shared/reducers';

import Get from './get';

const modules = [Get];

// Reducer
export const initialState = {
  isLoading: false,
  data: [],
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
};

const ACTION_HANDLERS = reduce(map(modules, 'handlers'), merge, {});

export const reducer = createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});

// Actions
export const actions = reduce(map(modules, 'actions'), merge, {});

// Constants
// it's barely used, but we may need to trigger
// a redux action of any other module from saga,
// so it's worth to export it for consistency
export const constants = reduce(map(modules, 'constants'), merge, {});

// Root saga
// Export the root saga by forking all available sagas
export function* rootSaga() {
  const sagas = map(modules, 'watcher');
  yield all(sagas.map(fork));
}
