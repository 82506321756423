import { z } from 'zod';
import { BaseSchemaDW } from '../structure';

export const RecruitingSourceDWSchema = z
  .object({
    source_id: z.number().nullable(),
    mongo_source_id: z.string(),
    source_name: z.string(),
  })
  .merge(BaseSchemaDW);

export type RecruitingSourceDW = z.infer<typeof RecruitingSourceDWSchema>;

export const RecruitingSourceMongoSchema = z.object({
  _id: z.string(),
  name: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
});
export type RecruitingSourceMongo = z.infer<typeof RecruitingSourceMongoSchema>;
