import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../../infrastructure';

export const ListSalesCandidatesItemSchema = z.object({
  email: z.string(),
  name: z.string(),
  position: z.string(),
  project: z.string(),
  position_id: z.string(),
  application_id: z.string(),
  seniority: z.number().nullable(),
  english_level: z.number().nullable(),
});

export type ListSalesCandidatesItem = z.infer<typeof ListSalesCandidatesItemSchema>;

export const ListSalesCandidatesFiltersSchema = z.object({
  name: z.record(z.enum([FilterOperations.CONTAIN]), z.string()).optional(),
  position_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
});

export type ListSalesCandidatesFilters = z.infer<typeof ListSalesCandidatesFiltersSchema>;

export const ListSalesCandidatesQuerySchema = z.object({
  filters: ListSalesCandidatesFiltersSchema.optional(),
});
export type ListSalesCandidatesQuery = z.infer<typeof ListSalesCandidatesQuerySchema>;

export type ListSalesCandidatesResponse = ListSalesCandidatesItem[];

export type ListSalesCandidatesEndpoint = Endpoint<
  Method.GET,
  '/api/v2/staffing-candidates/sales-candidates',
  ListSalesCandidatesResponse,
  undefined,
  ListSalesCandidatesQuery,
  undefined
>;
