import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';
import isFunction from 'lodash/isFunction';
import filter from 'lodash/filter';

// MODULE
const constants = {
  DESTROY_UTM_PARAMS: createSagaAction('DESTROY_UTM_PARAMS'),
};

const actions = {
  destroy: (data) => ({
    ...data,
    type: constants.DESTROY_UTM_PARAMS.REQUEST,
  }),
};

const handlers = {
  [constants.DESTROY_UTM_PARAMS.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.DESTROY_UTM_PARAMS.SUCCESS]: (state, { data }) => {
    const newStateData = filter(state.data, (item) => item._id !== data._id);
    return { ...state, isLoading: false, data: newStateData };
  },
  [constants.DESTROY_UTM_PARAMS.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA
function* sagaDestroyUtmParams(action) {
  try {
    const { data } = yield call(apiDestroyUtmParams, action);

    yield put({ type: constants.DESTROY_UTM_PARAMS.SUCCESS, data });
  } catch (e) {
    yield put({
      type: constants.DESTROY_UTM_PARAMS.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onError)) {
      yield action.onError(e.message || e);
    }
  }
}

function* watchDestroyUtmParams() {
  yield takeLatest(constants.DESTROY_UTM_PARAMS.REQUEST, sagaDestroyUtmParams);
}

// API
function apiDestroyUtmParams({ id }) {
  return request(`/utm-param/${id}`, {
    method: 'DELETE',
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchDestroyUtmParams,
};
