import { put, takeEvery } from 'redux-saga/effects';

import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import each from 'lodash/each';
import find from 'lodash/find';

import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  ASSIGNMENTS_REPORT_SOCKET_UPDATE: createSagaAction('ASSIGNMENTS_REPORT_SOCKET_UPDATE'),
};

const actions = {
  processSocketUpdate: (data) => ({
    type: constants.ASSIGNMENTS_REPORT_SOCKET_UPDATE.REQUEST,
    data,
  }),
};

const handlers = {
  // WEBSOCKETS
  [constants.ASSIGNMENTS_REPORT_SOCKET_UPDATE.REQUEST]: (state) => {
    return { ...state };
  },
  [constants.ASSIGNMENTS_REPORT_SOCKET_UPDATE.SUCCESS]: (state, { socketEvent }) => {
    const { application: applicationUpdated } = socketEvent;
    if (socketEvent.created && applicationUpdated) {
      // real time update when an application is created
    } else if (socketEvent.updated && applicationUpdated) {
      const leadsAssignmentsData = state.leadsAssignmentsData;
      each(Object.keys(leadsAssignmentsData), (leadId) => {
        const leadPositions = leadsAssignmentsData[leadId];
        for (const positionId in leadPositions) {
          const idx = findIndex(
            leadPositions[positionId],
            (item) => item._id === applicationUpdated._id,
          );
          if (idx >= 0) {
            // updating application data
            const assignmentApplication = leadPositions[positionId][idx];
            copyApplicationFieldsValues(assignmentApplication, applicationUpdated);

            // removing assignment if needed
            if (!isApplicationAssignedTo(applicationUpdated, positionId)) {
              remove(leadPositions[positionId], (app) => {
                return app._id === applicationUpdated._id;
              });
            }
          }
        }

        // add assignment if needed
        each(applicationUpdated.assignments, (assignment) => {
          if (assignment.deleted) {
            return;
          }
          if (!leadPositions[assignment.position]) {
            leadPositions[assignment.position] = [];
          }
          const isAssignmentOnReport =
            find(leadPositions[assignment.position], ({ _id }) => _id === applicationUpdated._id) ||
            false;
          if (!isAssignmentOnReport) {
            leadPositions[assignment.position].push(applicationUpdated);
          }
        });
      });
      return { ...state, leadsAssignmentsData: { ...leadsAssignmentsData } };
    } else if (socketEvent.deleted) {
      // real time update when an application is deleted
    }
    return { ...state };
  },
  [constants.ASSIGNMENTS_REPORT_SOCKET_UPDATE.FAILURE]: (state) => {
    return { ...state };
  },
};

function isApplicationAssignedTo(application, positionId) {
  return (
    findIndex(
      application.assignments,
      (assignment) => assignment.position === positionId && !assignment.deleted,
    ) > -1
  );
}

function copyApplicationFieldsValues(destination, source) {
  for (const key in source) {
    destination[key] = source[key];
  }
}

// SAGA

function* processSocketUpdate(action) {
  try {
    const socketEvent = action.data;
    if (socketEvent.deleted) {
      yield put({
        type: constants.ASSIGNMENTS_REPORT_SOCKET_UPDATE.SUCCESS,
        socketEvent,
      });
    } else {
      yield put({
        type: constants.ASSIGNMENTS_REPORT_SOCKET_UPDATE.SUCCESS,
        socketEvent,
      });
    }
  } catch (e) {
    yield put({
      type: constants.ASSIGNMENTS_REPORT_SOCKET_UPDATE.FAILURE,
      message: e.message || e,
    });
  }
}

export function* watchProcessSocketUpdate() {
  yield takeEvery(constants.ASSIGNMENTS_REPORT_SOCKET_UPDATE.REQUEST, processSocketUpdate);
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchProcessSocketUpdate,
};
