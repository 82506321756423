import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { PayoutSchema } from './base';

export type RemovePayoutEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/payouts/:${RemovePayoutParams['payout_id']}`,
  {},
  RemovePayoutParams,
  undefined,
  undefined
>;

export const RemovePayoutParamsSchema = PayoutSchema.pick({
  payout_id: true,
});
export type RemovePayoutParams = z.infer<typeof RemovePayoutParamsSchema>;
