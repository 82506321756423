import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const CalendlyInviteeTrackingSchema = z.object({
  utm_campaign: z.string().nullable(),
  utm_source: z.string().nullable(),
  utm_medium: z.string().nullable(),
  utm_content: z.string().nullable(),
  utm_term: z.string().nullable(),
  salesforce_uuid: z.string().nullable(),
});
export type CalendlyInviteeTracking = z.infer<typeof CalendlyInviteeTrackingSchema>;

export enum CalendlyWebhookEventTypes {
  'Create' = 'invitee.created',
  'Canceled' = 'invitee.canceled',
  'NoShow' = 'invitee_no_show.created',
  'NoShowDelete' = 'invitee_no_show.deleted',
}

const CalendlyEventMembershipItemSchema = z.object({
  user: z.string(),
  user_email: z.string(),
  user_name: z.string(),
});
export type CalendlyEventMembershipItem = z.infer<typeof CalendlyEventMembershipItemSchema>;

export const CalendlyInviteeSchema = z.object({
  uri: z.string(),
  email: z.string(),
  event: z.string(),
  tracking: CalendlyInviteeTrackingSchema.nullable(),
  old_invitee: z.string().nullable(),
  new_invitee: z.string().nullable(),
  scheduled_event: z.object({
    event_memberships: z.array(CalendlyEventMembershipItemSchema),
  }),
  reschedule_url: z.string(),
  cancel_url: z.string(),
});
export type CalendlyInvitee = z.infer<typeof CalendlyInviteeSchema>;

export enum CalendlyNotificationStatus {
  'Processed' = 'Processed',
  'Skipped' = 'Skipped',
  'Pending' = 'Pending',
}
export const CalendlyNotificationSchema = z.object({
  calendly_notification_id: z.number(),
  calendly_invitee_uri: z.string(),
  token: z.string().nullable(),
  notification_type: z.string(),
  payload: CalendlyInviteeSchema,
  status: z.nativeEnum(CalendlyNotificationStatus),
  dt_created: dateString,
  dt_updated: dateString,
});
export type CalendlyNotification = z.infer<typeof CalendlyNotificationSchema>;

export const CalendlyWebhookSchema = z.object({
  payload: CalendlyInviteeSchema,
  event: z.nativeEnum(CalendlyWebhookEventTypes),
});
export type CalendlyWebhook = z.infer<typeof CalendlyWebhookSchema>;

export const CalendlyEventCalendarEventSchema = z.object({
  kind: z.string(),
  external_id: z.string(),
});
export type CalendlyEventCalendarEvent = z.infer<typeof CalendlyEventCalendarEventSchema>;
export const CalendlyEventSchema = z.object({
  uri: z.string(),
  name: z.string(),
  status: z.string(),
  calendar_event: CalendlyEventCalendarEventSchema.nullable(),
});
export type CalendlyEvent = z.infer<typeof CalendlyEventSchema>;
