import { z } from 'zod';
import { CommentSchema } from '../comment';
import { CommentTagSchema } from '../comment-tag';
import { dateString } from '../../infrastructure';

export const CommentTagAscSchema = CommentSchema.pick({ comment_id: true })
  .merge(CommentTagSchema.pick({ comment_tag_id: true }))
  .extend({
    dt_created: dateString,
    dt_updated: dateString,
  });
export type CommentTagAsc = z.infer<typeof CommentTagAscSchema>;
