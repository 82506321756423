import { z } from 'zod';
import { SkillGroupSchema } from './base';

export const SkillGroupCreateSchema = SkillGroupSchema.pick({
  mongo_skill_group_id: true,
  name: true,
  color: true,
});

export type SkillGroupCreate = z.infer<typeof SkillGroupCreateSchema>;
