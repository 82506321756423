import { z } from 'zod';
import { PositionTemplateSchema } from '../base';

export const PositionTemplateUpdateSchema = PositionTemplateSchema.pick({
  position_template_id: true,
  name: true,
  position_name: true,
  english_level: true,
  seniority: true,
  target_cost: true,
  revenue_amount: true,
})
  .partial({
    english_level: true,
    name: true,
    position_name: true,
    revenue_amount: true,
    seniority: true,
    target_cost: true,
  })
  .extend({
    skills_id: z.array(z.string()).nullish(),
  });

export type PositionTemplateUpdate = z.infer<typeof PositionTemplateUpdateSchema>;
