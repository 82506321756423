import forOwn from 'lodash/forOwn';

// Define the structure of the permission map where each key (action) maps to an object
// with properties as keys and boolean values indicating permission.
export type LegacyPermissionMap = Record<string, Record<string, boolean>>;

// Mapping of action keys to their respective shorthand representations.
const aggregateMap = {
  get: 'r',
  set: 'w',
};

// Reverse mapping created from aggMap for converting shorthand representations back to action keys.
// e.g: \
// const reverseAggMap ={
//  r: 'get',
//  w: 'set'
// }
const reverseAggMap: Record<string, string> = Object.entries(aggregateMap).reduce(
  (acc, [key, value]) => {
    acc[value] = key;
    return acc;
  },
  {} as Record<string, string>,
);

/**
 * Aggregates legacy permissions into a shorthand format.
 *
 * @param input - The permission map to be converted.
 * @returns A record where each property is mapped to a string containing shorthand notations for permissions.
 *
 * @example
 * ```
 * // Input:
 * const permissions = {
 *   get: { x: true, y: true, z: true },
 *   set: { x: true, y: true }
 * };
 * // Output:
 * const aggregated = aggregateLegacyPermissions(permissions);
 * console.log(aggregated); // { x: "rw", y: "rw", z: "r" }
 * ```
 */
export function aggregateLegacyPermissions(input: LegacyPermissionMap): Record<string, string> {
  const result: Record<string, string> = {};

  forOwn(input, (value, key) => {
    forOwn(value, (val, prop) => {
      if (val) {
        if (result[prop]) {
          result[prop] += aggregateMap[key as keyof typeof aggregateMap];
        } else {
          result[prop] = aggregateMap[key as keyof typeof aggregateMap];
        }
      }
    });
  });

  return result;
}

/**
 * Reverses the aggregation of legacy permissions from shorthand format back to the original permission map.
 *
 * @param input - The record with properties mapped to shorthand notations for permissions.
 * @returns The original permission map with actions mapped to objects containing properties with boolean permissions.
 *
 * @example
 * ```
 * // Input:
 * const aggregatedPermissions = { x: "rw", y: "rw", z: "r" };
 * // Output:
 * const permissions = reverseAggregateLegacyPermissions(aggregatedPermissions);
 * console.log(permissions);
 * // {
 * //   get: { x: true, y: true, z: true },
 * //   set: { x: true, y: true, z: false }
 * // }
 * ```
 */
export function reverseAggregateLegacyPermissions(
  input: Record<string, string>,
): LegacyPermissionMap {
  if (!input) {
    return {};
  }
  const result: LegacyPermissionMap = {};
  const actions = Object.keys(reverseAggMap);

  Object.keys(input).forEach((property) => {
    actions.forEach((action) => {
      const operation = reverseAggMap[action];
      result[operation] = result[operation] || {};
      result[operation][property] = input[property].includes(action);
    });
  });

  return result;
}

/**
 *
 * Reverses the aggregation of permissions from shorthand format back to the original permission map.
 * It ignores permissions that are not UI related
 *
 * @param input - The input map where each UI element is associated with an array of actions and conditions.
 * @returns The legacy permission map with actions and conditions as keys and objects with UI elements and boolean permissions.
 *
 * @example
 * ```
 * // Input:
 * const uiPermissions = {
 *   'ui:dashboard': ['read', 'create:own'],
 *   'ui:settings': ['read', 'update']
 * };
 * // Output:
 * const legacyPermissions = reverseAggregatePermissions(uiPermissions);
 * console.log(legacyPermissions);
 * // {
 * //   read: { 'ui:dashboard': true, 'ui:settings': true },
 * //   'create:own': { 'ui:dashboard': true },
 * //   update: { 'ui:settings': true }
 * // }
 * ```
 */
export function reverseAggregatePermissions(input: Record<string, string[]>): LegacyPermissionMap {
  return Object.keys(input).reduce((acc: LegacyPermissionMap, key) => {
    // Skip keys that don't start with 'ui:'
    if (!key.startsWith('ui:')) {
      return acc;
    }
    const actionConditions = input[key]; // shape: `${action}:${condition}`?[]  example: ['read', 'create:own', 'update']
    for (const actionCondition of actionConditions) {
      if (!acc[actionCondition]) {
        acc[actionCondition] = {};
      }
      acc[actionCondition][key] = true;
    }
    return acc;
  }, {} as LegacyPermissionMap); // Explicitly type the initial value as LegacyPermissionMap to avoid type errors.
}
