import { z } from 'zod';

export const SlackViewSchema = z.object({
  view_id: z.number(),
  view: z.string(),
  created_at: z.date(),
  version: z.number(),
});

export type SlackView = z.infer<typeof SlackViewSchema>;
