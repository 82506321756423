import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

import { setActions } from '@redux/modules/_utils';
import ACTION_MAP from './actions/actionsMap';
import actionDefinitions from './actions/actionsDefinitions';

import values from 'lodash/values';
import map from 'lodash/map';
import zipObject from 'lodash/zipObject';
import mapValues from 'lodash/mapValues';
import defaults from 'lodash/defaults';
import remove from 'lodash/remove';
import get from 'lodash/get';
import find from 'lodash/find';
const _keys = values(ACTION_MAP);
const _values = map(_keys, (key) => createSagaAction(key));

// Constants
export const constants = zipObject(_keys, _values);

// Action Creators
export const actions = mapValues(ACTION_MAP, (key) => {
  return (data) => defaults({ type: constants[key].REQUEST }, data);
});

// Reducer
export const initialState = {
  hired: [],
  isLoading: true,
  error: null,
};

const ACTION_HANDLERS = setActions(actionDefinitions, constants);

ACTION_HANDLERS[constants.STATUS_UPDATE.SUCCESS] = (state, data) => {
  return {
    ...state,
    isLoading: false,
    hired: remove(state.hired, function (el) {
      return el._id !== data.action.applicationId;
    }),
  };
};

ACTION_HANDLERS[constants.STATUS_UPDATE.REQUEST] = (state) => {
  return { ...state, isLoading: true };
};

ACTION_HANDLERS[constants.REPORT_WS_EVENT.REQUEST] = (state) => {
  return { ...state };
};
// eslint-disable-next-line max-statements
ACTION_HANDLERS[constants.REPORT_WS_EVENT.SUCCESS] = (state, { socketEvent }) => {
  const effects = get(socketEvent, 'effects');
  //Verify if the WS events will affect this report, if not, just return the state
  if (effects) {
    if (!effects.includes('hiredReport')) {
      return { ...state };
    }
  } else {
    return { ...state };
  }
  const newState = { ...state };
  newState.hired = [...state.hired];
  const createdOrUpated = get(socketEvent, 'created') || get(socketEvent, 'updated');
  const deleted = get(socketEvent, 'deleted');

  const application = get(socketEvent, 'application');
  const applicationState = find(newState.hired, { _id: application._id });
  const removeApplication = () => {
    remove(newState.hired, { _id: application._id });
  };
  const updateApplication = () => {
    if (applicationState) {
      removeApplication();
    }
    newState.hired.push(application);
  };
  if (createdOrUpated) {
    if ([9, 10].includes(application.status)) {
      updateApplication();
    } else if (applicationState) {
      removeApplication();
    }
  } else if (deleted) {
    if (deleted.includes('application')) {
      removeApplication();
    }
  }
  return newState;
};
ACTION_HANDLERS[constants.REPORT_WS_EVENT.FAILURE] = (state) => {
  return { ...state };
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state };
});
