import { z } from 'zod';

export enum BusinessActionEntityEnum {
  Position = 'position',
  Project = 'project',
  Assignment = 'assignment',
}

// Make sure that these actions exist in the database
export enum BusinessActionsEnum {
  PositionCreated = 'POSITION_CREATED',
  PositionUpdated = 'POSITION_UPDATED',
  PositionRemoved = 'POSITION_REMOVED',
  PositionStartDateChanged = 'POSITION_START_DATE_CHANGED',
  PositionEndDateChanged = 'POSITION_END_DATE_CHANGED',
  PositionRecruitingChanged = 'POSITION_RECRUITING_CHANGED',
  PositionRevenueChanged = 'POSITION_REVENUE_CHANGED',
  PositionCostChanged = 'POSITION_COST_CHANGED',
  PositionAssignmentSticky = 'POSITION_ASSIGNMENT_STICKY',
  PositionExtensionCreated = 'POSITION_EXTENSION_CREATED',
  PositionExtensionUpdated = 'POSITION_EXTENSION_UPDATED',
  PositionExtensionRemoved = 'POSITION_EXTENSION_REMOVED',
  PositionExtensionSigned = 'POSITION_EXTENSION_SIGNED',
  PositionTerminationChanged = 'POSITION_TERMINATION_CHANGED',
  PositionCommentCreated = 'POSITION_COMMENT_CREATED',
  PositionCommentUpdated = 'POSITION_COMMENT_UPDATED',

  ProjectArchivedChanged = 'PROJECT_ARCHIVED_CHANGED',
  ProjectAssignmentsCyberArkSync = 'PROJECT_ASSIGNMENTS_CYBERARK_SYNC',
  ProjectTerminationChanged = 'PROJECT_TERMINATION_CHANGED',
  ProjectOpportunityExtensionCreated = 'PROJECT_OPPORTUNITY_EXTENSION_CREATED',
  ProjectOpportunityExtensionUpdated = 'PROJECT_OPPORTUNITY_EXTENSION_UPDATED',
  ProjectOpportunityExtensionRemoved = 'PROJECT_OPPORTUNITY_EXTENSION_REMOVED',
  ProjectOpportunityExtensionSigned = 'PROJECT_OPPORTUNITY_EXTENSION_SIGNED',
  ProjectCommentCreated = 'PROJECT_COMMENT_CREATED',
  ProjectCommentUpdated = 'PROJECT_COMMENT_UPDATED',
  ProjectUpdated = 'PROJECT_UPDATED',
  ProjectFinancialUpdated = 'PROJECT_FINANCIAL_UPDATED',
  OpportunityCreated = 'OPPORTUNITY_CREATED',
  OpportunitySigned = 'OPPORTUNITY_SIGNED',
  OpportunityUpdated = 'OPPORTUNITY_UPDATED',
  OpportunityStartDateChanged = 'OPPORTUNITY_START_DATE_CHANGED',
  OpportunityArchivedChanged = 'OPPORTUNITY_ARCHIVED_CHANGED',

  AssignmentCreated = 'ASSIGNMENT_CREATED',
  AssignmentUpdated = 'ASSIGNMENT_UPDATED',
  AssignmentRemoved = 'ASSIGNMENT_REMOVED',

  LegacyUpdate = 'LEGACY_UPDATE',
}

export const BusinessActionsEnumSchema = z.nativeEnum(BusinessActionsEnum);
