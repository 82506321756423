import { z } from 'zod';
import { AssignmentSchema } from './base';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';

//Base
export const ListAllActiveAssignmentsSchema = AssignmentSchema.extend({
  client_id: z.string(),
  position_name: z.string(),
  person_name: z.string(),
  person_salary: z.number().nullish(),
  person_rev: z.number().nullish(),
  person_cost: z.number().nullish(),
  person_margin: z.number(),
  person_color: z.string(),
  person_timezone: z.string(),
  person_isbillable: z.boolean(),
});
export type ListAllActiveAssignments = z.infer<typeof ListAllActiveAssignmentsSchema>;

//Filters
export const ListAllActiveAssignmentsFiltersSchema = z.object({
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
  project_id: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.string()).optional(),
});
export type ListAllActiveAssignmentsFilters = z.infer<typeof ListAllActiveAssignmentsFiltersSchema>;

//Query
export const ListAllActiveAssignmentsQuerySchema = z.object({
  filters: ListAllActiveAssignmentsFiltersSchema.optional(),
});
export type ListAllActiveAssignmentsQuery = z.infer<typeof ListAllActiveAssignmentsQuerySchema>;

//Reponse
export type ListAllActiveAssignmentsResponse = ListAllActiveAssignments[];

//Endpoint
export type ListAllActiveAssignmentsEndpoint = Endpoint<
  Method.GET,
  '/api/v2/assignments/all-active',
  ListAllActiveAssignmentsResponse,
  undefined,
  ListAllActiveAssignmentsQuery,
  undefined
>;
