import * as api from '@redux/api/application';
import { call, put, takeEvery } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';

function* updateApplicationTags(action: { applicationId: string; tags: number[] }) {
  try {
    // @ts-ignore api file is not typed
    yield call(api.updateApplicationTags, action);
    yield put({ type: constants.APPLICATION_UPDATE_TAGS.SUCCESS });
  } catch (e: any) {
    yield put({
      type: constants.APPLICATION_UPDATE_TAGS.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchUpdateApplicationTags() {
  // @ts-ignore
  yield takeEvery(constants.APPLICATION_UPDATE_TAGS.REQUEST, updateApplicationTags);
}

function* getApplicationTags(action: {}) {
  try {
    // @ts-ignore api file is not typed
    const applicationTags = yield call(api.getApplicationTags, action);
    yield put({ type: constants.APPLICATION_GET_TAGS.SUCCESS, applicationTags });
  } catch (e: any) {
    yield put({
      type: constants.APPLICATION_GET_TAGS.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetApplicationTags() {
  // @ts-ignore
  yield takeEvery(constants.APPLICATION_GET_TAGS.REQUEST, getApplicationTags);
}
