import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../../infrastructure';

export const PlanPsnBenefitItemSchema = z.object({
  yyear: z.number(),
  person_id: z.string(),
  name: z.string(),
  ymonth: z.number(),
  plan_section: z.string(),
  amount: z.number(),
});
export type PlanPsnBenefitItem = z.infer<typeof PlanPsnBenefitItemSchema>;

export const PlanPsnBenefitFiltersSchema = z.object({
  ymonth_period_start: z
    .record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth_period_end: z
    .record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
});
export type PlanPsnBenefitFilters = z.infer<typeof PlanPsnBenefitFiltersSchema>;

export const PlanPsnBenefitQuerySchema = z.object({
  filters: PlanPsnBenefitFiltersSchema.optional(),
});
export type PlanPsnBenefitQuery = z.infer<typeof PlanPsnBenefitQuerySchema>;

export type PlanPsnBenefitResponse = PlanPsnBenefitItem[];

export type PlanPsnBenefitEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/monthly-plan-v2/psn-benefits',
  PlanPsnBenefitResponse,
  undefined,
  PlanPsnBenefitQuery,
  undefined
>;
