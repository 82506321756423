import { z } from 'zod';
import { CommentUpdateSchema } from '../crud';
import { Comment } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const CommentUpdateParamsSchema = z.object({
  comment_id: z.coerce.number(),
});
export type CommentUpdateParams = z.infer<typeof CommentUpdateParamsSchema>;

export const CommentUpdateRequestSchema = CommentUpdateSchema.pick({
  comment: true,
  comment_tags: true,
});
export type CommentUpdateRequest = z.infer<typeof CommentUpdateRequestSchema>;

export type CommentUpdateResponse = Comment;

export type CommentUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/comments/${CommentUpdateParams['comment_id']}`,
  CommentUpdateResponse,
  CommentUpdateParams,
  undefined,
  CommentUpdateRequest
>;
