import { put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';

// DELETE APPLICATION ERROR

function* deleteError() {
  yield put({ type: constants.APPLICATION_ERROR_DELETE.SUCCESS, error: null });
}
export function* watchDeleteError() {
  yield takeLatest(constants.APPLICATION_ERROR_DELETE.REQUEST, deleteError);
}
