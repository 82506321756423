import { call, put, takeLatest } from 'redux-saga/effects';

import { constants } from '@redux/modules/metrics/metrics';
import * as api from '@redux/api/metrics';

// GET MESSAGES
function* getMetricStatusMap(action) {
  try {
    const response = yield call(api.getMetricStatusMap, action);

    yield put({
      type: constants.METRICS_STATUS_MAP_GET.SUCCESS,
      statusMap: response,
    });
  } catch (e) {
    yield put({
      type: constants.METRICS_STATUS_MAP_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetMetricStatusMap() {
  yield takeLatest(constants.METRICS_STATUS_MAP_GET.REQUEST, getMetricStatusMap);
}
