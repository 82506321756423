import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  GET_FOCUS_REPORT: createSagaAction('GET_FOCUS_REPORT'),
  CREATE_RESPONSIBLE: createSagaAction('CREATE_RESPONSIBLE'),
  REMOVE_RESPONSIBLE: createSagaAction('REMOVE_RESPONSIBLE'),
};

// Action Creators
export const actions = {
  getFocusReport: () => ({
    type: constants.GET_FOCUS_REPORT.REQUEST,
  }),
  createResponsible: (args) => ({
    type: constants.CREATE_RESPONSIBLE.REQUEST,
    payload: { ...args },
  }),
  removeResponsible: (id) => ({
    type: constants.REMOVE_RESPONSIBLE.REQUEST,
    id,
  }),
};

// Reducer
export const initialState = {
  isLoading: false,
  error: null,
  calendlyUsers: [],
  focusReport: [],
};

const ACTION_HANDLERS = {
  [constants.GET_FOCUS_REPORT.SUCCESS]: (state, { focusReport }) => {
    return { ...state, focusReport, isLoading: false };
  },
  [constants.GET_FOCUS_REPORT.FAILURE]: (state, { error }) => {
    return { ...state, error, isLoading: false };
  },
  [constants.GET_FOCUS_REPORT.REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [constants.CREATE_RESPONSIBLE.SUCCESS]: (state, { responsibleRecruiter }) => {
    return {
      ...state,
      focusReport: [...state.focusReport, responsibleRecruiter],
      isLoading: false,
    };
  },
  [constants.CREATE_RESPONSIBLE.FAILURE]: (state, { error }) => {
    return { ...state, error, isLoading: false };
  },
  [constants.CREATE_RESPONSIBLE.REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [constants.REMOVE_RESPONSIBLE.SUCCESS]: (state) => {
    return { ...state, isLoading: false };
  },
  [constants.REMOVE_RESPONSIBLE.FAILURE]: (state, { error }) => {
    return { ...state, error, isLoading: false };
  },
  [constants.REMOVE_RESPONSIBLE.REQUEST]: (state, { id }) => ({
    ...state,
    focusReport: state.focusReport.filter((e) => e._id !== id),
    isLoading: true,
  }),
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
