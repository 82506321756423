import request from '../../shared/request';

export function getMessages(message) {
  const { leads, assignments, applications, positions, level } = message;
  return request('/messages', {
    method: 'GET',
    qs: {
      leads,
      assignments,
      applications,
      positions,
      level,
    },
  });
}

export function postMessage(message) {
  const { notify } = message;
  return request('/messages', {
    method: 'POST',
    body: {
      message,
      notify,
    },
  });
}

export function updateMessage(messageId, comment) {
  return request(`/messages/${messageId}`, {
    method: 'PUT',
    body: {
      comment,
    },
  });
}

export function togglePinMessage(messageId, groupBy) {
  return request(`/messages/${messageId}/toggle-pin`, {
    method: 'PUT',
    qs: {
      groupBy: groupBy,
    },
  });
}

export function deleteMessage(messageId) {
  return request(`/messages/${messageId}`, {
    method: 'DELETE',
  });
}
