import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  JOB_TEXT_QUESTIONS_GET_ALL: createSagaAction('JOB_TEXT_QUESTIONS_GET_ALL'),
  JOB_TEXT_QUESTIONS_CREATE: createSagaAction('JOB_TEXT_QUESTIONS_CREATE'),
  JOB_TEXT_QUESTIONS_UPDATE: createSagaAction('JOB_TEXT_QUESTIONS_UPDATE'),
  JOB_TEXT_QUESTIONS_DELETE: createSagaAction('JOB_TEXT_QUESTIONS_DELETE'),
  JOB_TEXT_QUESTIONS_UPLOAD_VIDEO: createSagaAction('JOB_TEXT_QUESTIONS_UPLOAD_VIDEO'),
};

// Action Creators
export const actions = {
  getAllQuestions: (id) => ({
    type: constants.JOB_TEXT_QUESTIONS_GET_ALL.REQUEST,
    id,
  }),
  createQuestion: (body, onSuccess) => ({
    type: constants.JOB_TEXT_QUESTIONS_CREATE.REQUEST,
    body,
    onSuccess,
  }),
  updateQuestion: (body) => ({
    type: constants.JOB_TEXT_QUESTIONS_UPDATE.REQUEST,
    body,
  }),
  deleteQuestion: (body) => ({
    type: constants.JOB_TEXT_QUESTIONS_DELETE.REQUEST,
    body,
  }),
};

// Reducer
export const initialState = {
  isLoading: false,
  error: null,
  questions: [],
  refetch: false,
  allJobsQuestions: [],
};

const ACTION_HANDLERS = {
  [constants.JOB_TEXT_QUESTIONS_GET_ALL.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.JOB_TEXT_QUESTIONS_GET_ALL.SUCCESS]: (state, { questions, allJobsQuestions }) => {
    return { ...state, questions, isLoading: false, refetch: false, allJobsQuestions };
  },
  [constants.JOB_TEXT_QUESTIONS_GET_ALL.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.JOB_TEXT_QUESTIONS_CREATE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.JOB_TEXT_QUESTIONS_CREATE.SUCCESS]: (state, { error }) => {
    return { ...state, error, refetch: true };
  },
  [constants.JOB_TEXT_QUESTIONS_UPDATE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.JOB_TEXT_QUESTIONS_UPDATE.SUCCESS]: (state, { error }) => {
    return { ...state, error, refetch: true };
  },
  [constants.JOB_TEXT_QUESTIONS_DELETE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.JOB_TEXT_QUESTIONS_DELETE.SUCCESS]: (state, { error }) => {
    return { ...state, error, refetch: true };
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
