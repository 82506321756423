import { z } from 'zod';
import { PsnDepartmentSchema } from './base';

export const PsnDepartmentDeleteSchema = PsnDepartmentSchema.pick({
  person_id: true,
  department_id: true,
  dt_start: true,
});

export type PsnDepartmentDelete = z.infer<typeof PsnDepartmentDeleteSchema>;
