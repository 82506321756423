import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE
const constants = {
  SEARCH_GET_ASSIGNMENTS_SUMMARY_MONGO: createSagaAction('SEARCH_GET_ASSIGNMENTS_SUMMARY_MONGO'),
};

const actions = {
  getAssignmentsSummary: (args) => ({
    type: constants.SEARCH_GET_ASSIGNMENTS_SUMMARY_MONGO.REQUEST,
    ...args,
  }),
};

const handlers = {
  [constants.SEARCH_GET_ASSIGNMENTS_SUMMARY_MONGO.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.SEARCH_GET_ASSIGNMENTS_SUMMARY_MONGO.SUCCESS]: (state, { assignmentsSummary }) => {
    return { ...state, isLoading: false, assignmentsSummary };
  },
  [constants.SEARCH_GET_ASSIGNMENTS_SUMMARY_MONGO.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA
function* getMongoAssignmentsSummary(action) {
  try {
    const response = yield call(getAssignmentsSummary, action);

    yield put({
      type: constants.SEARCH_GET_ASSIGNMENTS_SUMMARY_MONGO.SUCCESS,
      assignmentsSummary: response.assignmentsSummary,
    });
  } catch (error) {
    yield put({
      type: constants.SEARCH_GET_ASSIGNMENTS_SUMMARY_MONGO.FAILURE,
      error: error.message || error,
    });
  }
}

function* watchGetMongoAssignmentsSummary() {
  yield takeLatest(
    constants.SEARCH_GET_ASSIGNMENTS_SUMMARY_MONGO.REQUEST,
    getMongoAssignmentsSummary,
  );
}

// API
export function getAssignmentsSummary({ urlParams }) {
  return request(`/applications/assignments-summary?${urlParams}`, {
    method: 'GET',
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchGetMongoAssignmentsSummary,
};
