import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const PositionTemplateDeleteParamsSchema = z.object({
  position_template_id: z.coerce.number(),
});
export type PositionTemplateDeleteParams = z.infer<typeof PositionTemplateDeleteParamsSchema>;

export type PositionTemplateDeleteResponse = {};

export type PositionTemplateDeleteEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/position-template/${PositionTemplateDeleteParams['position_template_id']}`,
  PositionTemplateDeleteResponse,
  PositionTemplateDeleteParams,
  undefined,
  undefined
>;
