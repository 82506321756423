import { z } from 'zod';
import { StaffingCandidateSchema } from '../base';

export const StaffingCandidateUpdateSchema = StaffingCandidateSchema.pick({
  staffing_candidate_id: true,
  rating: true,
  status: true,
})
  .partial({
    rating: true,
    status: true,
  })
  .extend({
    replace_current_a: z.boolean().nullish(),
    user: z
      .object({
        name: z.string().optional(),
        email: z.string().optional(),
      })
      .optional(),
  });
export type StaffingCandidateUpdate = z.infer<typeof StaffingCandidateUpdateSchema>;
