import { call, put, takeLatest } from 'redux-saga/effects';
import qs from 'query-string';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE
const constants = {
  GET_ALL_JOBS: createSagaAction('GET_ALL_JOBS'),
};

const actions = {
  getAll: (args) => ({
    type: constants.GET_ALL_JOBS.REQUEST,
    ...args,
  }),
};

const handlers = {
  [constants.GET_ALL_JOBS.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.GET_ALL_JOBS.SUCCESS]: (state, { allJobs }) => {
    return { ...state, isLoading: false, allJobs };
  },
  [constants.GET_ALL_JOBS.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

// SAGA
function* getAllJobs(action) {
  try {
    const response = yield call(apiGetAllJobs, action);
    const allJobs = response.data;

    yield put({ type: constants.GET_ALL_JOBS.SUCCESS, allJobs });
  } catch (error) {
    yield put({
      type: constants.GET_ALL_JOBS.FAILURE,
      error: error.message || error,
    });
  }
}

function* watchGetAllJobs() {
  yield takeLatest(constants.GET_ALL_JOBS.REQUEST, getAllJobs);
}

// API
export function apiGetAllJobs({ sortBy }) {
  const query = qs.stringify({
    show: 'all',
    full: true,
    sortBy,
  });

  return request(`/jobs?${query}`, {
    method: 'GET',
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchGetAllJobs,
};
