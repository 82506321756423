import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { PayoutSchema, PayoutWithPersonSchema } from './base';

export type FindPayoutEndpoint = Endpoint<
  Method.GET,
  `/api/v2/payouts/:${FindPayoutParams['payout_id']}`,
  FindPayoutResponse,
  FindPayoutParams,
  undefined,
  undefined
>;

export const FindPayoutParamsSchema = PayoutSchema.pick({
  payout_id: true,
});
export type FindPayoutParams = z.infer<typeof FindPayoutParamsSchema>;

export const FindPayoutResponseSchema = PayoutWithPersonSchema;
export type FindPayoutResponse = z.infer<typeof FindPayoutResponseSchema>;
