import { z } from 'zod';

import { Endpoint, Method } from '../../infrastructure';
import { PreLead, PreLeadMetaSchema } from './base';

export const CreatePreLeadRequestSchema = PreLeadMetaSchema.pick({
  client_id: true,
  name: true,
  pct_confidence: true,
  from_ymonth: true,
  month_duration: true,
  team_size_range: true,
  rev_rate_range: true,
}).partial({
  from_ymonth: true,
  month_duration: true,
  team_size_range: true,
  rev_rate_range: true,
  pct_confidence: true,
});

export type CreatePreLeadRequest = z.infer<typeof CreatePreLeadRequestSchema>;

export type CreatePreLeadResponse = PreLead;

export type CreatePreLeadEndpoint = Endpoint<
  Method.POST,
  '/api/v3/pre-leads',
  CreatePreLeadResponse,
  undefined,
  undefined,
  CreatePreLeadRequest
>;
