// GET CALENDLY LIST

import { call, takeLatest, put } from 'redux-saga/effects';
import * as api from '@redux/api/application';
import { constants } from '@redux/modules/application/application';

function* getRecruitersList(action) {
  try {
    const recruitersList = yield call(api.getRecruitersList, action);
    yield put({
      type: constants.APPLICATION_RECRUITERS_LIST.SUCCESS,
      recruitersList,
    });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_RECRUITERS_LIST.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetRecruitersList() {
  yield takeLatest(constants.APPLICATION_RECRUITERS_LIST.REQUEST, getRecruitersList);
}
