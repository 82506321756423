import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import { constants } from '@redux/modules/reports/test-reminder-campaign';
import * as api from '@redux/api/test-reminder-campaign';

// GET SOURCES
function* getTestReminderCampaign(action) {
  try {
    const response = yield call(api.getTestReminderCampaign, action);

    yield put({ type: constants.TEST_REMINDER_CAMPAIGN_GET.SUCCESS, results: response });
  } catch (e) {
    yield put({
      type: constants.TEST_REMINDER_CAMPAIGN_GET,
      error: e.message || e,
    });
  }
}

function* watchGetTestReminderCampaign() {
  yield takeLatest(constants.TEST_REMINDER_CAMPAIGN_GET.REQUEST, getTestReminderCampaign);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([fork(watchGetTestReminderCampaign)]);
}
