import moment from 'moment';
import { interviewFilters } from '../Shared/InterviewFilter/InterviewFilter';

const dateFormat = 'YYYY-MM-DD';

export const SearchFormInitialState = {
  applications_excitement_from: '',
  applications_excitement_to: '',
  applications_salary_from: '',
  applications_salary_to: '',
  applications_yoe_from: '',
  applications_yoe_to: '',
  selectedAvailability: '',
  applications_score_from: '',
  applications_years_from: '',
  applications_years_to: '',
  countries: ['0', '1', '3'],
  dateFrom: moment().add(-1, 'year').format(dateFormat),
  touchedDateFrom: moment().add(-10, 'days').format(dateFormat),
  touchedDateTo: '',
  dateTo: '',
  fastDecisionMode: false,
  interviewFilter: {
    enabled: interviewFilters.noFilter,
    // eslint-disable-next-line newline-per-chained-call
    from: moment().add(-30, 'day').startOf('day'),
    // eslint-disable-next-line newline-per-chained-call
    to: moment().add(30, 'day').endOf('day'),
    status: '1',
    interviewer: [],
  },
  interviewFrom: '',
  interviewTo: '',
  kw: '',
  misc: ['isApplication', 'isReferred', 'isSourced', 'isSourcedDevTeam'],
  selectedAssignments: undefined,
  selectedAssignmentIds: [],
  selectedAuthors: [],
  selectedStatus: undefined,
  selectedTechTestStatus: undefined,
  selectedOwners: [],
  selectedTechInterviewers: [],
  selectedReviewedBy: [],
  selectedSources: [],
  selectedUtmParams: [],
  selectedEnglishLevels: [],
  selectedSeniorityLevels: [],
  selectedWorkingStatusLevels: [],
  selectedJobs: undefined,
  selectedSkills: [],
  noSkills: false,
  interviewInterviewer: [],
  selectedTechTests: [],
  techTestsScoreFrom: '',
  techTestsScoreTo: '',
  selectedTechSkills: [],
  techSkillsScoreFrom: '',
  techSkillsScoreTo: '',
  statusNotIn: false,
  englishLevel: '',
  is_gem: false,
};

export const InitialState = {
  defaultCurrentPage: 1,
  offset: '0',
  pagination: undefined,
  sortParam: 'statusIdx',
  sortOrder: 'ascend',
  limit: '50',

  filtered: false,
  filterDropdownVisible: false,
  skillDataSource: [],
  lastCommentDateFrom: '',
  lastCommentDateTo: '',
  salary: '',
  years: '',
  testScore: '',
  excitement: '',
  tzOffset: '',
  assignments: '',
  assignment: '',
  position: '1',
  tableUI: {
    selectedApplications: [],
    selectedAssignments: undefined,
    tableRef: {},
    initialTableState: {},
  },
  funnelTab: 1,
  techTestsScore: '',
  techSkillsScore: '',
};
