import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const PsnSkillSchema = z.object({
  person_id: z.string(),
  skill_id: z.number(),
  dt_created: dateString,
  dt_updated: dateString.nullable(),
});
export type PsnSkill = z.infer<typeof PsnSkillSchema>;
