import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const ForecastVersionSchema = z.object({
  version_id: z.number(),
  name: z.string(),
  is_frozen: z.boolean(),
  dt_frozen: dateString.nullable(),
  dt_created: dateString,
  dt_updated: dateString,
});
export type ForecastVersion = z.infer<typeof ForecastVersionSchema>;
