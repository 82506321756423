import { z } from 'zod';
import { Endpoint, Method, dateString } from '../../infrastructure';

export const BrainsSearchPersonSchema = z.object({
  id: z.string(),
  title: z.string(),
  url: z.string(),
  location: z.string(),
  gravatarUrl: z.string().nullable(),
  dtstart: z.string().nullable(),
  dtterminated: z.string().nullable(),
  projects: z
    .array(
      z.object({
        project_id: z.string(),
        assignment_id: z.string(),
        position_id: z.string(),
        client_id: z.string(),
        dtstart: dateString,
        dtend: dateString,
        project_name: z.string(),
        client_name: z.string(),
        position_name: z.string(),
        url: z.string(),
      }),
    )
    .nullable(),
  department_name: z.string().nullable(),
  timezone_gmt: z.string().nullable(),
  timezone_est: z.string().nullable(),
  time_offs: z
    .array(z.object({ dtstart: z.string(), dtend: z.string(), is_vacation: z.boolean() }))
    .nullable(),
  tags: z
    .object({
      person_id: z.string(),
      name: z.string(),
      description: z.string().nullable(),
      color: z.string().nullable(),
    })
    .array()
    .nullable(),
  year_started_profession: z.number().nullable(),
  salary: z.number().nullable(),
});

export const BrainsSearchProjectSchema = z.object({
  id: z.string(),
  title: z.string(),
  url: z.string(),
  client: z.string(),
  dtplannedstart: dateString,
  dtplannedend: dateString,
  end_date_certainty: z.string().nullable(),
  owner_name: z.string().nullable(),
  team_size: z.number().nullable(),
  duration: z.number().nullable(),
  revenue: z.number().nullable(),
  assignments: z.array(
    z.object({
      person_id: z.string(),
      name: z.string(),
      url: z.string(),
    }),
  ),
});

export type BrainsSearchPerson = z.infer<typeof BrainsSearchPersonSchema>;
export type BrainsSearchProject = z.infer<typeof BrainsSearchProjectSchema>;

export const BrainsSearchQuerySchema = z.object({ keywords: z.string() });

const BrainsSearchResponseSchema = z.object({
  activePeople: z.array(BrainsSearchPersonSchema),
  archivedPeople: z.array(BrainsSearchPersonSchema),
  activeProjects: z.array(BrainsSearchProjectSchema),
  archivedProjects: z.array(BrainsSearchProjectSchema),
});

type BrainsSearchQuery = z.infer<typeof BrainsSearchQuerySchema>;

type BrainsSearchResponse = z.infer<typeof BrainsSearchResponseSchema>;

export type BrainsSearchEndpoint = Endpoint<
  Method.GET,
  `/api/v2/brains-search`,
  BrainsSearchResponse,
  undefined,
  BrainsSearchQuery,
  undefined
>;
