import { z } from 'zod';
import { PsnPrjGoogleCalendarCreateSchema } from '../crud';
import { PsnPrjGoogleCalendar } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const PsnPrjGoogleCalendarCreateRequestSchema = PsnPrjGoogleCalendarCreateSchema.pick({
  person_id: true,
  prj_google_calendar_id: true,
});

export type PsnPrjGoogleCalendarCreateRequest = z.infer<
  typeof PsnPrjGoogleCalendarCreateRequestSchema
>;

export type PsnPrjGoogleCalendarCreateResponse = PsnPrjGoogleCalendar;

export type PsnPrjGoogleCalendarCreateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/psn-prj-google-calendar`,
  PsnPrjGoogleCalendarCreateResponse,
  undefined,
  undefined,
  PsnPrjGoogleCalendarCreateRequest
>;
