import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const ArchiveProjectRequestSchema = z.object({});

export type ArchiveProjectRequest = z.infer<typeof ArchiveProjectRequestSchema>;

export const ArchiveProjectParamsSchema = z.object({
  project_id: z.coerce.string(),
});
export type ArchiveProjectParams = z.infer<typeof ArchiveProjectParamsSchema>;

export type ArchiveProjectEndpoint = Endpoint<
  Method.PUT,
  `/projects/${ArchiveProjectParams['project_id']}/archive`,
  void,
  ArchiveProjectParams,
  undefined,
  ArchiveProjectRequest
>;
