import { z } from 'zod';
import { AssignmentMongoSchema, AssignmentSchema } from './base';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';

export const CurrentAssignmentsSchema = AssignmentSchema.omit({
  costalloc: true,
}).extend({
  person_name: z.string(),
  person_salary: z.number(),
  role: z.string(),
  gravatar_url: z.string(),
  mongoAssignment: AssignmentMongoSchema,
});
export type CurrentAssignments = z.infer<typeof CurrentAssignmentsSchema>;

export const ListCurrentAssignmentByPositionSchema = CurrentAssignmentsSchema;

export type ListCurrentAssignmentByPosition = z.infer<typeof ListCurrentAssignmentByPositionSchema>;

//Filters
export const ListCurrentAssignmentByPositionFiltersSchema = z.object({
  position_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
});
export type ListCurrentAssignmentByPositionFilters = z.infer<
  typeof ListCurrentAssignmentByPositionFiltersSchema
>;

//Query
export const ListCurrentAssignmentByPositionQuerySchema = z.object({
  filters: ListCurrentAssignmentByPositionFiltersSchema,
});

export type ListCurrentAssignmentByPositionQuery = z.infer<
  typeof ListCurrentAssignmentByPositionQuerySchema
>;

//Response
export type ListCurrentAssignmentsByPositionResponse = ListCurrentAssignmentByPosition[];

//Endpoint
export type ListCurrentAssignmentByPositionEndpoint = Endpoint<
  Method.GET,
  '/api/v2/assignments/position-current-assignments',
  ListCurrentAssignmentsByPositionResponse,
  undefined,
  ListCurrentAssignmentByPositionQuery,
  undefined
>;
