import { all, fork, call, put, takeEvery } from 'redux-saga/effects';
import { constants } from '@redux/modules/rejection-resons';
import * as api from '@redux/api/rejection-reasons';
import history from '@src/history';

function* getAll(action) {
  try {
    const response = yield call(api.getAllRejectionReasons, action);
    yield put({
      type: constants.REJECTION_REASONS_GET_ALL.SUCCESS,
      rejectionReasons: response.rejectionReasons,
    });
  } catch (e) {
    yield put({
      type: constants.REJECTION_REASONS_GET_ALL.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchGetAll() {
  yield takeEvery(constants.REJECTION_REASONS_GET_ALL.REQUEST, getAll);
}

function* create(action) {
  try {
    yield call(api.createRejectionReasons, action);
    yield put({ type: constants.REJECTION_REASONS_CREATE.SUCCESS });
    const response = yield call(api.getAllRejectionReasons, action);
    yield put({
      type: constants.REJECTION_REASONS_GET_ALL.SUCCESS,
      rejectionReasons: response.rejectionReasons,
    });
    // //;
    history.push('/admin/rejection-reasons');
  } catch (e) {
    yield put({
      type: constants.REJECTION_REASONS_CREATE.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchCreate() {
  yield takeEvery(constants.REJECTION_REASONS_CREATE.REQUEST, create);
}

function* update(action) {
  try {
    yield call(api.updateRejectionReasons, action);
    yield put({ type: constants.REJECTION_REASONS_UPDATE.SUCCESS });
    const response = yield call(api.getAllRejectionReasons, action);
    yield put({
      type: constants.REJECTION_REASONS_GET_ALL.SUCCESS,
      rejectionReasons: response.rejectionReasons,
    });
    history.push('/admin/rejection-reasons');
  } catch (e) {
    yield put({
      type: constants.REJECTION_REASONS_UPDATE.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchUpdate() {
  yield takeEvery(constants.REJECTION_REASONS_UPDATE.REQUEST, update);
}

function* deleteRejectionReasons(action) {
  try {
    yield call(api.deleteRejectionReasons, action);
    yield put({ type: constants.REJECTION_REASONS_DELETE.SUCCESS });
    const response = yield call(api.getAllRejectionReasons, action);
    yield put({
      type: constants.REJECTION_REASONS_GET_ALL.SUCCESS,
      rejectionReasons: response.rejectionReasons,
    });
    history.push('/admin/rejection-reasons');
  } catch (e) {
    yield put({
      type: constants.REJECTION_REASONS_DELETE.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchDelete() {
  yield takeEvery(constants.REJECTION_REASONS_DELETE.REQUEST, deleteRejectionReasons);
}

function* getAllRejected(action) {
  try {
    const response = yield call(api.getAllRejectedApplications, action);
    yield put({
      type: constants.APPLICATION_GET_ALL_REJECTED.SUCCESS,
      applicationsRejected: response.applicationsRejected,
    });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_GET_ALL_REJECTED.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchGetAllRejected() {
  yield takeEvery(constants.APPLICATION_GET_ALL_REJECTED.REQUEST, getAllRejected);
}
/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetAll),
    fork(watchCreate),
    fork(watchUpdate),
    fork(watchDelete),
    fork(watchGetAllRejected),
  ]);
}
