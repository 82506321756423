import { all, fork } from 'redux-saga/effects';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import merge from 'lodash/merge';

import { createReducer } from '@shared/reducers';

import All from './get-all';
import CreateJob from './create-job';
import DeleteJobImage from './delete-job-image';
import GetSearchable from './get-searchable';
import GetAllJobQuestions from './get-all-job-questions';
import GetDevskillerTests from './get-devskiller-tests';
import GetJobQuestions from './get-job-questions';
import MoveJobApplications from './move-job-applications';
import SortJobsByView from './sort-jobs-by-view';
import SortJobs from './sort-jobs';
import UpdateJobImage from './update-job-image';
import UpdateJobStatus from './update-job-status';
import UpdateJobs from './update-job';
import GetJobApplicationsSummary from './get-job-applications-summary';
import GetActiveTechJobs from './get-active-tech-jobs';

const modules = [
  All,
  CreateJob,
  DeleteJobImage,
  GetSearchable,
  GetAllJobQuestions,
  GetDevskillerTests,
  GetJobQuestions,
  MoveJobApplications,
  SortJobsByView,
  SortJobs,
  UpdateJobImage,
  UpdateJobStatus,
  UpdateJobs,
  GetJobApplicationsSummary,
  GetActiveTechJobs,
];

// Reducer
export const initialState = {
  isLoading: false,
  searchableJobs: [],
  allJobs: [],
  questions: [],
  allQuestions: [],
  devskillerTests: [],
  applicationsSummary: [],
  techJobs: [],
  error: null,
};

const ACTION_HANDLERS = reduce(map(modules, 'handlers'), merge, {});

export const reducer = createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});

// Actions
export const actions = reduce(map(modules, 'actions'), merge, {});

// Constants
// it's barely used, but we may need to trigger
// a redux action of any other module from saga
// so it's worth to export it for consistency
export const constants = reduce(map(modules, 'constants'), merge, {});

// Root saga
// Export the root saga by forking all available sagas
export function* rootSaga() {
  const sagas = map(modules, 'watcher');
  yield all(sagas.map(fork));
}
