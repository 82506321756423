import { z } from 'zod';
import { Endpoint, FilterOperations, Method, ReportResponse } from '../../../infrastructure';
import { dateString } from '../../../infrastructure/schema';

//Define the base return from database
export const ClientSnapshotComparisonSchema = z.object({
  ymonth: z.number(),
  client_id: z.string(),
  client_name: z.string(),
  first_day: dateString,
  first_amount: z.number(),
  second_day: dateString,
  second_amount: z.number(),
});
export type ClientSnapshotComparison = z.infer<typeof ClientSnapshotComparisonSchema>;

//Define filters
export const ClientSnapshotComparisonFiltersSchema = z.object({
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
  first_day: z.record(z.enum([FilterOperations.EQUAL]), dateString).optional(),
  second_day: z.record(z.enum([FilterOperations.EQUAL]), dateString).optional(),
});
export type ClientSnapshotComparisonFilters = z.infer<typeof ClientSnapshotComparisonFiltersSchema>;

//Define the query of the endpoint
export const ClientSnapshotComparisonQuerySchema = z.object({
  filters: ClientSnapshotComparisonFiltersSchema.optional(),
});
export type ClientSnapshotComparisonQuery = z.infer<typeof ClientSnapshotComparisonQuerySchema>;

//Define the response
export type ClientSnapshotComparisonResponse = ReportResponse<ClientSnapshotComparison>;

//Define the endpoint
export type ClientSnapshotComparisonEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/snapshot-comparison/client',
  ClientSnapshotComparisonResponse,
  undefined,
  ClientSnapshotComparisonQuery,
  undefined
>;
