import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { PtoBreakdown } from './base';

export const GetTimeOffPtoBreakdownParamsSchema = z.object({
  personId: z.string(),
});
export type GetTimeOffPtoBreakdownParams = z.infer<typeof GetTimeOffPtoBreakdownParamsSchema>;

export type GetTimeOffPtoBreakdownResponse = PtoBreakdown;

export type GetTimeOffPtoBreakdownEndpoint = Endpoint<
  Method.GET,
  `/api/v2/person/${GetTimeOffPtoBreakdownParams['personId']}/timeoffs/summary/pto-breakdown`,
  GetTimeOffPtoBreakdownResponse,
  GetTimeOffPtoBreakdownParams,
  undefined,
  undefined
>;
