import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  JOB_TYPES_LIST: createSagaAction('JOB_TYPES_LIST'),
  JOB_TYPES_CREATE: createSagaAction('JOB_TYPES_CREATE'),
  JOB_TYPES_UPDATE: createSagaAction('JOB_TYPES_UPDATE'),
  JOB_TYPES_DELETE: createSagaAction('JOB_TYPES_DELETE'),
  JOB_TYPES_SORT: createSagaAction('JOB_TYPES_SORT'),
};

// Action Creators
export const actions = {
  getJobTypes: () => ({
    type: constants.JOB_TYPES_LIST.REQUEST,
  }),
  createJobType: (args) => ({
    type: constants.JOB_TYPES_CREATE.REQUEST,
    ...args,
  }),
  updateJobType: (args) => ({
    type: constants.JOB_TYPES_UPDATE.REQUEST,
    ...args,
  }),
  deleteJobType: (args) => ({
    type: constants.JOB_TYPES_DELETE.REQUEST,
    ...args,
  }),
  sortJobTypes: (args) => ({
    type: constants.JOB_TYPES_SORT.REQUEST,
    ...args,
  }),
};

// Reducer
export const initialState = {
  isLoading: false,
  jobTypes: [],
  error: null,
};

const ACTION_HANDLERS = {
  // JOB_TYPES_LIST
  [constants.JOB_TYPES_LIST.REQUEST]: (state) => {
    return { ...state };
  },
  [constants.JOB_TYPES_LIST.SUCCESS]: (state, { jobTypes }) => {
    return { ...state, jobTypes };
  },
  [constants.JOB_TYPES_LIST.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },

  [constants.JOB_TYPES_CREATE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },

  //JOB_TYPES_UPDATE
  [constants.JOB_TYPES_UPDATE.SUCCESS]: (state, { updatedJobType }) => {
    //find index to allocate on same position
    const idx = state.jobTypes.findIndex((job) => job._id === updatedJobType._id);
    if (idx >= 0) {
      const newJobTypes = [...state.jobTypes];
      newJobTypes[idx] = { ...updatedJobType };
      //const sortedJobs = _.sortBy(newJobTypes, ['sort'], ['desc']);

      return {
        ...state,
        jobTypes: [...newJobTypes],
      };
    }
    return { ...state };
  },
  [constants.JOB_TYPES_UPDATE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },

  [constants.JOB_TYPES_DELETE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },

  //JOB_TYPES_SORT
  [constants.JOB_TYPES_SORT.SUCCESS]: (state, { jobTypes }) => {
    return { ...state, jobTypes };
  },
  [constants.JOB_TYPES_SORT.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
