import { z } from 'zod';

export const contractStatus = {
  SIGNED: 'SIGNED',
  OUT_FOR_SIGNATURE: 'OUT_FOR_SIGNATURE',
  WAITING_FOR_AUTHORING: 'WAITING_FOR_AUTHORING',
  UNSIGNED: 'UNSIGNED',
  SUPERSEDED: 'SUPERSEDED',
  TERMINATED: 'TERMINATED',
  WITHDRAWN: 'WITHDRAWN',
} as const;

export type ContractStatus = (typeof contractStatus)[keyof typeof contractStatus];

export const ContractSchema = z.object({
  contract_id: z.number().optional(),
  person_id: z.string(),
  signature_workflow_id: z.number().optional(),
  status: z.nativeEnum(contractStatus),
  dt_started: z.number(),
  dt_generated: z.number().optional(),
  dt_superseded: z.number().nullable().optional(),
  dt_terminated: z.number().nullable().optional(),
  type: z.string(),
  employment_type: z.string(),
  contractor_company: z.string().nullable().optional(),
  paid_by: z.string(),
  country: z.string(),
  state: z.string().nullable().optional(),
  document_storage_id: z.number().nullable().optional(),
  notice_period: z.string().nullable(),
});
export type Contract = z.infer<typeof ContractSchema>;
