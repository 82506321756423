import * as api from '@redux/api/application';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';

// GET SOURCE LIST

function* getSourceList(action) {
  try {
    const sourceList = yield call(api.getSourceList, action);
    yield put({ type: constants.APPLICATION_GET_SOURCE.SUCCESS, sourceList });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_GET_SOURCE.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchGetSourceList() {
  yield takeLatest(constants.APPLICATION_GET_SOURCE.REQUEST, getSourceList);
}
