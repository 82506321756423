import * as api from '@redux/api/application';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';
import isFunction from 'lodash/isFunction';

function* sendPreContractLetter(action) {
  try {
    const response = yield call(api.sendPreContractLetter, action);

    yield put({
      type: constants.APPLICATION_SEND_PRE_CONTRACT_LETTER.SUCCESS,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(response.message, response.warnings);
    }
    const timelineResponse = yield call(api.getApplicationTimelineList, action);
    const timelineList = (timelineResponse && timelineResponse.data) || [];
    yield put({ type: constants.APPLICATION_TIMELINES_GET.SUCCESS, timelineList });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_SEND_PRE_CONTRACT_LETTER.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onError)) {
      console.error(e);
      yield action.onError(e.message || e);
    }
  }
}

export function* watchSendPreContractLetter() {
  yield takeLatest(constants.APPLICATION_SEND_PRE_CONTRACT_LETTER.REQUEST, sendPreContractLetter);
}
