//APPLICATION_USER_TECH_INTERVIEWER_UPDATE
import * as api from '@redux/api/application';
import * as schemas from '@src/schemas';
import { actions as entitiesActions } from '@redux/modules/entities';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';
import { normalize } from 'normalizr';

function* updateApplicationTechInterviewer(action) {
  try {
    const response = yield call(api.updateApplicationTechInterviewer, action);
    const normalized = normalize(response.data, schemas.application);
    const { users, applications } = normalized.entities;

    yield put(entitiesActions.mergeApplications(applications));

    yield put(entitiesActions.mergeUsers(users));

    yield put({
      type: constants.APPLICATION_USER_TECH_INTERVIEWER_UPDATE.SUCCESS,
    });

    const timelineResponse = yield call(api.getApplicationTimelineList, action);
    const timelineList = (timelineResponse && timelineResponse.data) || [];
    yield put({ type: constants.APPLICATION_TIMELINES_GET.SUCCESS, timelineList });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_USER_TECH_INTERVIEWER_UPDATE.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchUpdateApplicationTechInterviewer() {
  yield takeLatest(
    constants.APPLICATION_USER_TECH_INTERVIEWER_UPDATE.REQUEST,
    updateApplicationTechInterviewer,
  );
}
