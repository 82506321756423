import { z } from 'zod';

import { BaseSchemaDW } from '../structure';

export const ApplicationSkillMongoSchema = z.object({
  skill: z.string(),
  level: z.number(),
  favorite: z.boolean(),
  name: z.string(),
});
export type ApplicationSkillMongo = z.infer<typeof ApplicationSkillMongoSchema>;

export const ApplicationSkillDWSchema = z
  .object({
    application_id: z.number(),
    skill_id: z.number(),
    level: z.number().nullable(),
    is_favorite: z.boolean().nullable(),
  })
  .merge(BaseSchemaDW);

export type ApplicationSkillDW = z.infer<typeof ApplicationSkillDWSchema>;
