// GET CALENDLY LIST

import { call, takeLatest, put } from 'redux-saga/effects';
import * as api from '@redux/api/application';
import { constants } from '@redux/modules/application/application';

function* getCalendlyList(action) {
  try {
    const applicationCalendly = yield call(api.getCalendlyList, action);
    yield put({
      type: constants.APPLICATION_CALENDLY_LIST.SUCCESS,
      applicationCalendly,
    });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_CALENDLY_LIST.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetCalendlyList() {
  yield takeLatest(constants.APPLICATION_CALENDLY_LIST.REQUEST, getCalendlyList);
}
