import { z } from 'zod';
import { WebSFContactSchema } from '../base';

export const WebSFContactCreateSchema = WebSFContactSchema.pick({
  first_name: true,
  last_name: true,
  name: true,
  email_address: true,
  phone_number: true,
  company: true,
  job_title: true,
  message: true,
  subscribe: true,
  agree_privacy_policy: true,
  inquiry_type: true,
  gclid: true,
  ip_address: true,
  timezone: true,
  browser_device: true,
  page_url: true,

  utm_campaign: true,
  utm_medium: true,
  utm_source: true,
  utm_term: true,
});
export type WebSFContactCreate = z.infer<typeof WebSFContactCreateSchema>;
