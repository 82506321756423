import { z } from 'zod';
import { ForecastSchema } from '../base';

export const ForecastCreateSchema = ForecastSchema.pick({
  client_id: true,
  project_id: true,
  yyear: true,
  months_amount: true,
  version_id: true,
  name: true,
});

export type ForecastCreate = z.infer<typeof ForecastCreateSchema>;
