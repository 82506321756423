/* eslint-disable no-unused-vars */

import { z } from 'zod';
import { makeSorter, PaginatedResponse, PaginationQuerySchema } from '../../infrastructure';

//eslint-disable-next-line no-shadow
export enum ActionRequestComponentKeys {
  RescheduleInterview = 'reschedule-interview',
  DropInterview = 'drop-interview',
  InterviewConducted = 'mark-interview-as-conducted',
  NoShowInterview = 'no-show-interview',
  SwitchRecruiter = 'switch-recruiter',
  CloseInterview = 'close-interview',
}
//eslint-disable-next-line no-shadow
export enum ActionRequests {
  WaitingForInterviewer = 'waiting-for-interviewer',
  InterviewDeclinedByApplicant = 'interview-declined-by-applicant',
  ApplicantNoResponse = 'applicant-no-response',
  InterviewStaled = 'interview-staled',
}

/*
 *  Metadata schemas (used in creation)
 */

export const BaseMetadataSchema = z.object({
  application_id: z.string(),
  interview_date: z.string().datetime(),
  applicant_name: z.string(),
  interview_calendar_event_id: z.number(),
});
export type BaseMetadata = z.infer<typeof BaseMetadataSchema>;

export const ActionRequestMetadataDiscriminatedUnionSchema = z.discriminatedUnion('slug', [
  z.object({
    slug: z.literal(ActionRequests.WaitingForInterviewer),
    metadata: BaseMetadataSchema,
  }),
  z.object({
    slug: z.literal(ActionRequests.InterviewDeclinedByApplicant),
    metadata: BaseMetadataSchema,
  }),
  z.object({
    slug: z.literal(ActionRequests.ApplicantNoResponse),
    metadata: BaseMetadataSchema,
  }),
  z.object({
    slug: z.literal(ActionRequests.InterviewStaled),
    metadata: BaseMetadataSchema,
  }),
]);

/*
 * Response
 */

export const ActionRequestSchema = z.object({
  action_request_id: z.number(),
  person_id: z.string(),
  email: z.string(),

  slug: z.nativeEnum(ActionRequests),
  group: z.string(),
  metadata: z.record(z.unknown()).nullable(),
  action_components: z.nativeEnum(ActionRequestComponentKeys).array().nullable(),

  dt_request: z.string().datetime(),
  dt_action_completed: z.string().datetime().nullable(),
  dt_action_due: z.string().datetime().nullable(),
  dt_created: z.string().datetime(),
});
export type ActionRequest = z.infer<typeof ActionRequestSchema>;
export type ListActionRequestsResponse = PaginatedResponse<ActionRequest>;

/*
 * Request
 */

export type ListMyActionRequestsResponse = ListActionRequestsResponse;
export const ListMyActionRequestsSorterSchema = makeSorter(z.enum(['dt_request']));

export const ListMyActionRequestsQuerySchema = z.object({
  sorter: ListMyActionRequestsSorterSchema.optional(),
  pagination: PaginationQuerySchema.optional(),
});
export type ListMyActionRequestsQuery = z.infer<typeof ListMyActionRequestsQuerySchema>;

export const PatchActionRequestRequestSchema = ActionRequestSchema.pick({
  dt_action_completed: true,
});
export type PatchActionRequestRequest = z.infer<typeof PatchActionRequestRequestSchema>;

export const BaseCreateActionRequestRequestSchema = ActionRequestSchema.pick({
  slug: true,
  dt_request: true,
  dt_action_due: true,
  metadata: true,
}).required({
  dt_request: true,
});

export const CreateActionRequestRequestSchema = BaseCreateActionRequestRequestSchema.and(
  ActionRequestMetadataDiscriminatedUnionSchema,
);
export type CreateActionRequestRequest = z.infer<typeof CreateActionRequestRequestSchema>;
