import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../../infrastructure';

export const PlanOverheadCostDetailItemSchema = z.object({
  person_id: z.string(),
  name: z.string(),
  ymonth: z.number(),
  yyear: z.number(),
  plan_category: z.string(),
  plan_section: z.string(),
  department_id: z.string(),
  department: z.string(),
  amount: z.number(),
  is_reimbursement: z.boolean(),
});
export type PlanOverheadCostDetailItem = z.infer<typeof PlanOverheadCostDetailItemSchema>;

export const PlanOverheadCostDetailFiltersSchema = z.object({
  ymonth_period_start: z
    .record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth_period_end: z
    .record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
});
export type PlanOverheadCostDetailFilters = z.infer<typeof PlanOverheadCostDetailFiltersSchema>;

export const PlanOverheadCostDetailQuerySchema = z.object({
  filters: PlanOverheadCostDetailFiltersSchema.optional(),
});
export type PlanOverheadCostDetailQuery = z.infer<typeof PlanOverheadCostDetailQuerySchema>;

export type PlanOverheadCostDetailResponse = PlanOverheadCostDetailItem[];

export type PlanOverheadCostDetailEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/monthly-plan-v2/overhead-cost-detail',
  PlanOverheadCostDetailResponse,
  undefined,
  PlanOverheadCostDetailQuery,
  undefined
>;
