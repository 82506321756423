import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  ASSIGNMENTS_REPORT_LEAD_ASSIGNMENTS_GET: createSagaAction(
    'ASSIGNMENTS_REPORT_LEAD_ASSIGNMENTS_GET',
  ),
};

const actions = {
  getLeadAssignments: (leadId, withHiredAndOnboarded) => ({
    type: constants.ASSIGNMENTS_REPORT_LEAD_ASSIGNMENTS_GET.REQUEST,
    leadId,
    withHiredAndOnboarded,
  }),
};

const handlers = {
  [constants.ASSIGNMENTS_REPORT_LEAD_ASSIGNMENTS_GET.REQUEST]: (state, action) => {
    return { ...state, isLeadAssignmentsLoading: true };
  },
  [constants.ASSIGNMENTS_REPORT_LEAD_ASSIGNMENTS_GET.SUCCESS]: (state, { leadId, data }) => {
    const leadsAssignmentsData = { ...state.leadsAssignmentsData };
    leadsAssignmentsData[leadId] = data;
    return {
      ...state,
      isLeadAssignmentsLoading: false,
      leadsAssignmentsData,
    };
  },
  [constants.ASSIGNMENTS_REPORT_LEAD_ASSIGNMENTS_GET.FAILURE]: (state, { error }) => {
    return { ...state, isLeadAssignmentsLoading: false, error };
  },
};

// SAGA

export function* sagaGetLeadAssignments({ leadId, withHiredAndOnboarded }) {
  try {
    const data = yield call(apiGetLeadAssignments, leadId, withHiredAndOnboarded);
    yield put({
      type: constants.ASSIGNMENTS_REPORT_LEAD_ASSIGNMENTS_GET.SUCCESS,
      leadId,
      data,
    });
  } catch (e) {
    yield put({
      type: constants.ASSIGNMENTS_REPORT_LEAD_ASSIGNMENTS_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetLeadAssignments() {
  yield takeLatest(
    constants.ASSIGNMENTS_REPORT_LEAD_ASSIGNMENTS_GET.REQUEST,
    sagaGetLeadAssignments,
  );
}

// API

export function apiGetLeadAssignments(leadId, withHiredAndOnboarded) {
  return request(`/assignments-report/leads/${leadId}/assignments`, {
    method: 'GET',
    qs: {
      withHiredAndOnboarded,
    },
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchGetLeadAssignments,
};
