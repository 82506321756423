import { call, put, takeEvery } from 'redux-saga/effects';

import { constants } from '@redux/modules/dashboard/dashboard';
import * as api from '@redux/api/dashboard';

// UPDATE STATUS
function* updateStatus(action) {
  try {
    yield call(api.newStatus, action);

    yield put({
      type: constants.STATUS_UPDATE.SUCCESS,
      action: action,
    });
  } catch (e) {
    yield put({
      type: constants.STATUS_UPDATE.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchUpdateStatus() {
  yield takeEvery(constants.STATUS_UPDATE.REQUEST, updateStatus);
}
