import { z } from 'zod';
import { NumberRangeSchema } from '../../infrastructure/schema';
import { GridBaseSchema } from '../../infrastructure/grid';

export const PreLeadSkillSchema = z.object({
  skill_id: z.number(),
  name: z.string(),
  count: z.number().int(),
});
export type PreLeadSkill = z.infer<typeof PreLeadSkillSchema>;

export const PreLeadTemplateSchema = z.object({
  from_ymonth: NumberRangeSchema,
  month_duration: NumberRangeSchema,
  team_size_range: NumberRangeSchema,
  rev_rate_range: NumberRangeSchema,
});
export type PreLeadTemplate = z.infer<typeof PreLeadTemplateSchema>;

export const PreLeadMetaSchema = z
  .object({
    client_id: z.string(),
    name: z.string(),
    pct_confidence: z.number(),
    skills: z.array(PreLeadSkillSchema),
  })
  .merge(PreLeadTemplateSchema.deepPartial());

export type PreLeadMeta = z.infer<typeof PreLeadMetaSchema>;

export const PreLeadSchema = GridBaseSchema.extend({
  meta: PreLeadMetaSchema,
});
export type PreLead = z.infer<typeof PreLeadSchema>;

export type PreLeadClient = {
  client_id: string;
  name: string;
  is_pre_client: boolean;
};

export const PreLeadCommentSchema = z.object({
  message_id: z.number(),
  author: z.object({
    person_id: z.string(),
    name: z.string(),
    gravatar_url: z.string(),
  }),
  txt_content: z.string().nullable(),
  dt_created: z.date(),
  dt_updated: z.date(),
});
export type PreLeadComment = z.infer<typeof PreLeadCommentSchema>;
