import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';

export const ForecastVersionUnfreezeParamsSchema = z.object({
  version_id: z.coerce.number(),
});
export type ForecastVersionUnfreezeParams = z.infer<typeof ForecastVersionUnfreezeParamsSchema>;

export type ForecastVersionUnfreezeResponse = void;

export type ForecastVersionUnfreezeEndpoint = Endpoint<
  Method.POST,
  `/api/v2/forecasts-version/${ForecastVersionUnfreezeParams['version_id']}/unfreeze`,
  ForecastVersionUnfreezeResponse,
  ForecastVersionUnfreezeParams,
  undefined,
  undefined
>;
