import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  ACCOUNT_UPDATE: createSagaAction('ACCOUNT_UPDATE'),
};

const actions = {
  update: (data) => ({
    ...data,
    type: constants.ACCOUNT_UPDATE.REQUEST,
  }),
};

const handlers = {
  [constants.ACCOUNT_UPDATE.REQUEST]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [constants.ACCOUNT_UPDATE.SUCCESS]: (state, { data }) => {
    return { ...state, isLoading: false, data };
  },
  [constants.ACCOUNT_UPDATE.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

function* sagaUpdateAccount(action) {
  try {
    const { user: data } = yield call(apiUpdateAccount, action);
    yield put({ type: constants.ACCOUNT_UPDATE.SUCCESS, data });
  } catch (e) {
    yield put({
      type: constants.ACCOUNT_UPDATE.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchUpdateAccount() {
  yield takeLatest(constants.ACCOUNT_UPDATE.REQUEST, sagaUpdateAccount);
}

// API

function apiUpdateAccount({ zoomLink }) {
  return request('/account/update', {
    method: 'PUT',
    body: {
      zoomLink,
    },
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchUpdateAccount,

  // optionally we may
  // api: {
  //   getAccount: apiGetAccount
  // }
};
