import { z } from 'zod';
import { CommentListItemSchema } from '../../comment';
import { Endpoint, Method } from '../../../infrastructure';

export const PosCommentListItemSchema = CommentListItemSchema.extend({
  position_id: z.string(),
  project_id: z.string(),
});
export type PosCommentListItem = z.infer<typeof PosCommentListItemSchema>;

export const PosCommentListParamsSchema = z.object({
  position_id: z.string(),
});
export type PosCommentListParams = z.infer<typeof PosCommentListParamsSchema>;

export type PosCommentListResponse = PosCommentListItem[];

export type PosCommentListEndpoint = Endpoint<
  Method.GET,
  `/api/v2/pos-comments/${PosCommentListParams['position_id']}`,
  PosCommentListResponse,
  PosCommentListParams,
  undefined,
  undefined
>;
