import { all, fork, call, put, takeEvery } from 'redux-saga/effects';

import { constants } from '@redux/modules/application-drip-campaigns/application-drip-campaigns';
import * as api from '@redux/api/application-drip-campaigns';

export function* getAll(action) {
  try {
    const response = yield call(api.getDripCampaigns, action);
    yield put({ type: constants.DRIP_CAMPAIGNS_GET.SUCCESS, dripCampaigns: response.data });
  } catch (e) {
    yield put({
      type: constants.DRIP_CAMPAIGNS_GET.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchGetAll() {
  yield takeEvery(constants.DRIP_CAMPAIGNS_GET.REQUEST, getAll);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([fork(watchGetAll)]);
}
