import { z } from 'zod';

export enum SlackMessageTemplateType {
  Blocks = 'BLOCKS',
  Type = 'TYPE',
}

export const SlackMessageTemplateTypeSchema = z.nativeEnum(SlackMessageTemplateType);

export const SlackMessageTemplateSchema = z.object({
  template_id: z.number(),
  action_id: z.number(),
  name: z.string(),
  template: z.record(z.any()),
  type: SlackMessageTemplateTypeSchema,
  created_at: z.string(),
  bot_title: z.string().nullable(),
  bot_emoji: z.string().nullable(),
});

export type SlackMessageTemplate = z.infer<typeof SlackMessageTemplateSchema>;

export const SlackTemplateSlackChannelSchema = z.object({
  slack_template_slack_channel_id: z.number(),
  template_id: z.number(),
  slack_channel: z.string(),
  columns: z.array(z.string()).nullable(),
});

export type SlackTemplateSlackChannel = z.infer<typeof SlackTemplateSlackChannelSchema>;
