import { all, fork, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';

//import { constants } from '@redux/modules/application';
//import * as api from '@redux/api/application';
import * as api from '@redux/api/job-types';
import { constants } from '@redux/modules/job-types';

//GET JOB TYPES
function* getJobTypes(action) {
  try {
    const response = yield call(api.getJobTypes, action);
    const jobTypes = response.data;

    yield put({ type: constants.JOB_TYPES_LIST.SUCCESS, jobTypes });
  } catch (error) {
    yield put({
      type: constants.JOB_TYPES_LIST.FAILURE,
      error: error.message || error,
    });
  }
}

function* watchGetJobTypes() {
  yield takeLatest(constants.JOB_TYPES_LIST.REQUEST, getJobTypes);
}

//JOB_TYPES_CREATE
function* createJobType(action) {
  try {
    const createJobTypeResponse = yield call(api.createJobType, action);
    const jobTypesResponse = yield call(api.getJobTypes, action);

    const jobTypes = jobTypesResponse.data;

    yield put({ type: constants.JOB_TYPES_LIST.SUCCESS, jobTypes });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(createJobTypeResponse.jobId);
    }
  } catch (error) {
    yield put({
      type: constants.JOB_TYPES_LIST.FAILURE,
      error: error.message || error,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(error.message || error);
    }
  }
}

function* watchCreateJobType() {
  yield takeLatest(constants.JOB_TYPES_CREATE.REQUEST, createJobType);
}

//UPDATE_JOB
function* updateJobType(action) {
  try {
    const response = yield call(api.updateJobType, action);
    const updatedJobType = response.data;

    yield put({ type: constants.JOB_TYPES_UPDATE.SUCCESS, updatedJobType });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(updatedJobType);
    }
  } catch (error) {
    yield put({
      type: constants.JOB_TYPES_UPDATE.FAILURE,
      error: error.message || error,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(error.message || error);
    }
  }
}

function* watchUpdateJobType() {
  yield takeLatest(constants.JOB_TYPES_UPDATE.REQUEST, updateJobType);
}

//SORT
function* sortJobTypes(action) {
  try {
    const response = yield call(api.sortJobTypes, action);

    yield put({
      type: constants.JOB_TYPES_SORT.SUCCESS,
      jobTypes: response.data,
    });
  } catch (e) {
    yield put({
      type: constants.JOB_TYPES_SORT.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchSortJobTypes() {
  yield takeLatest(constants.JOB_TYPES_SORT.REQUEST, sortJobTypes);
}

function* deleteJobType(action) {
  try {
    yield call(api.deleteJobType, action);
    yield put({ type: constants.JOB_TYPES_DELETE.SUCCESS });
    const response = yield call(api.getJobTypes, action);
    yield put({
      type: constants.JOB_TYPES_LIST.SUCCESS,
      jobTypes: response.data,
    });
    // history.push('/admin/rejection-reasons');
  } catch (e) {
    yield put({
      type: constants.JOB_TYPES_DELETE.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchDeleteJobType() {
  yield takeEvery(constants.JOB_TYPES_DELETE.REQUEST, deleteJobType);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetJobTypes),
    fork(watchCreateJobType),
    fork(watchUpdateJobType),
    fork(watchSortJobTypes),
    fork(watchDeleteJobType),
  ]);
}
