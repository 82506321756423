import { z } from 'zod';
import { FinBudget, FinBudgetCreateSchema } from '../';
import { Endpoint, Method } from '../../../infrastructure';

export const FinBudgetCreateRequestSchema = FinBudgetCreateSchema.pick({
  budget_name: true,
  year: true,
  cost_budget: true,
  revenue_budget: true,
});
export type FinBudgetCreateRequest = z.infer<typeof FinBudgetCreateRequestSchema>;

export type FinBudgetCreateResponse = FinBudget;

export type FinBudgetCreateEndpoint = Endpoint<
  Method.POST,
  '/api/v2/fin-budgets/',
  FinBudgetCreateResponse,
  undefined,
  undefined,
  FinBudgetCreateRequest
>;
