import { z } from 'zod';

import { dateString, Endpoint, Method } from '../../infrastructure';

export const AddProjectExtensionRequestSchema = z.object({
  project_id: z.string(),
  dt_opp_extension: dateString,
  sticky: z.boolean().nullish().default(true),
  positions_id: z.string().array().nullish(),
});
export type AddProjectExtensionRequest = z.infer<typeof AddProjectExtensionRequestSchema>;

export type AddProjectExtensionEndpoint = Endpoint<
  Method.POST,
  '/api/v2/sql-projects/opp-extension',
  void,
  undefined,
  undefined,
  AddProjectExtensionRequest
>;
