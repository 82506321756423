import { z } from 'zod';
import { PersonTagMongoSchema, PersonTagSchema } from '../base';

export const PersonTagCreateSchema = PersonTagSchema.pick({
  person_tag_mongo_id: true,
  name: true,
  description: true,
  color: true,
  cron_created: true,
  created_by_id: true,
});
export type PersonTagCreate = z.infer<typeof PersonTagCreateSchema>;

export const PersonTagMongoCreateSchema = PersonTagMongoSchema.pick({
  _id: true,
  name: true,
  description: true,
  color: true,
  cronCreated: true,
  createdBy: true,
});
export type PersonTagMongoCreate = z.infer<typeof PersonTagMongoCreateSchema>;
