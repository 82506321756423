import { z } from 'zod';
import { AssignmentByPerson } from './base';
import { Endpoint, Method } from '../../infrastructure';

export type ListAssignmentsByPersonIdEndpoint = Endpoint<
  Method.GET,
  `/api/v2/positions/:${ListAssignmentsByPersonIdParams['personId']}`,
  ListAssignmentsByPersonId,
  ListAssignmentsByPersonIdParams,
  undefined,
  undefined
>;

export type ListAssignmentsByPersonId = Array<AssignmentByPerson>;

export const ListAssignmentsByPersonIdParamsSchema = z.object({
  personId: z.string(),
});

export type ListAssignmentsByPersonIdParams = z.infer<typeof ListAssignmentsByPersonIdParamsSchema>;
