import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const PayoutMetaSalarySchema = z.object({
  paid_days: z.number(),
  unpaid_days: z.number(),
  unpaid_amount: z.number(),
});
export const PayoutMetaReferralBonusSchema = z.object({
  referee_person_id: z.string(),
  referee_hire_date: dateString,
});

export const PayoutMetaSchema = z.union([PayoutMetaSalarySchema, PayoutMetaReferralBonusSchema]);

export const PayoutSchema = z.object({
  payout_id: z.coerce.number(),
  person_id: z.string(),
  payout_type_code: z.string(),
  acct_cost_code: z.string(),
  amount: z.number().finite(),
  description: z.string(),
  department: z.string().optional(),
  created_by: z.string().nullable(),
  approved_by: z.string().nullable(),
  approved_date: dateString.nullable(),
  target_pay_date: dateString,
  request_date: dateString,
  paid_date: dateString.nullable(),
  paid_by: z.string().optional(),
  pay_method: z.string().optional(),
  is_paid_immediately: z.boolean(),
  meta: PayoutMetaSchema.nullish(),
  ymonth: z.number(),
  dtcreate: dateString.nullable(),
  dtupdate: dateString.nullable(),
});
export type Payout = z.infer<typeof PayoutSchema>;

export const PayoutWithPersonSchema = PayoutSchema.extend({
  person: z.object({
    _id: z.string(),
    name: z.string(),
    gravatarURL: z.string(),
  }),
});
export type PayoutWithPerson = z.infer<typeof PayoutWithPersonSchema>;

export const PayoutTypeCodeSchema = z.enum([
  'EoY Bonus',
  'Referral Bonus',
  'Performance Bonus',
  'PDF',
  'CNO',
  'CSAM',
  'LNO',
  'LSAM',
  'Monthly Payment',
  'Hourly Payment',
  'Term. Reimbursement',
  'Travel Expenses',
  'TravelBillable',
  'TravelNonBillable',
  'Equipment',
  'Software',
  'Swag Tax Reimb.',
  'Training',
  'Previous Salary',
  'Other',
]);
export type PayoutTypeCode = z.infer<typeof PayoutTypeCodeSchema>;

export const PayoutTypeLabelSchema = z.enum([
  'End of Year Bonus',
  'Referral Bonus',
  'Performance Bonus',
  'Personal Development Fund',
  'Clever Night Out',
  'Clever Share a Meal',
  'Lumen Night Out',
  'Lumen Share a Meal',
  'Monthly Payment',
  'Hourly Payment',
  'Termination Reimbursement',
  'Travel Expenses',
  'Travel - Billable',
  'Travel - Non-Billable',
  'Equipment',
  'Software',
  'Training',
  'Salary',
  'Previous Salary',
  'Other',
]);
export type PayoutTypeLabel = z.infer<typeof PayoutTypeLabelSchema>;

export const PayoutTypeSchema = z.object({
  code: PayoutTypeCodeSchema,
  label: PayoutTypeLabelSchema,
});
export type PayoutType = z.infer<typeof PayoutTypeSchema>;

export const PayoutsTotalSummarySchema = z.object({
  unpaid_amount: z.number(),
  paid_amount: z.number(),
  total_amount: z.number(),
});
export type PayoutsTotalSummary = z.infer<typeof PayoutsTotalSummarySchema>;

export const AcctCostAllocCodeSchema = z.enum(['COS', 'Overhead']);
export type AcctCostAllocCode = z.infer<typeof AcctCostAllocCodeSchema>;

export const AcctCostAllocLabelSchema = z.enum(['Cost of Sales', 'Overhead']);
export type AcctCostAllocLabel = z.infer<typeof AcctCostAllocLabelSchema>;

export const AcctCostAllocSchema = z.object({
  code: AcctCostAllocCodeSchema,
  label: AcctCostAllocLabelSchema,
});
export type AcctCostAlloc = z.infer<typeof AcctCostAllocSchema>;
