import { z } from 'zod';
import { TimeOffRequestCommentSchema } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const TimeOffRequestCommentGetByTorSchema = TimeOffRequestCommentSchema.extend({
  author_gravatar_url: z.string(),
  author_name: z.string(),
});

export const TimeOffRequestCommentGetByTorParamsSchema = z.object({
  time_off_request_id: z.coerce.number(),
});

export type TimeOffRequestCommentGetByTorParams = z.infer<
  typeof TimeOffRequestCommentGetByTorParamsSchema
>;

export type TimeOffRequestCommentGetByTorResponse = z.infer<typeof TimeOffRequestCommentGetByTorSchema>[];

export type TimeOffRequestCommentGetByTorEndpoint = Endpoint<
  Method.GET,
  `/api/v2/time-off-request-comments/${TimeOffRequestCommentGetByTorParams['time_off_request_id']}/by-tor`,
  TimeOffRequestCommentGetByTorResponse,
  TimeOffRequestCommentGetByTorParams,
  undefined,
  undefined
>;
