import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const PositionStaffingSetRecruitingParamsSchema = z.object({
  positionStaffingId: z.coerce.number(),
});
export type PositionStaffingSetRecruitingParams = z.infer<
  typeof PositionStaffingSetRecruitingParamsSchema
>;
export type PositionStaffingSetRecruitingEndpoint = Endpoint<
  Method.PUT,
  `/api/positions-staffing/${PositionStaffingSetRecruitingParams['positionStaffingId']}/recruiting`,
  {},
  PositionStaffingSetRecruitingParams,
  undefined,
  undefined
>;
