import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

let assignmentsReportActions;
import('@redux/assignments-report').then(({ actions }) => (assignmentsReportActions = actions));

// MODULE

const constants = {
  ASSIGNMENTS_REPORT_LEADS_GET: createSagaAction('ASSIGNMENTS_REPORT_LEADS_GET'),
};

const actions = {
  getLeads: () => ({
    type: constants.ASSIGNMENTS_REPORT_LEADS_GET.REQUEST,
  }),
};

const handlers = {
  [constants.ASSIGNMENTS_REPORT_LEADS_GET.REQUEST]: (state, action) => {
    return { ...state, isLeadsLoading: true };
  },
  [constants.ASSIGNMENTS_REPORT_LEADS_GET.SUCCESS]: (state, { data }) => {
    return { ...state, isLeadsLoading: false, leadsData: data };
  },
  [constants.ASSIGNMENTS_REPORT_LEADS_GET.FAILURE]: (state, { error }) => {
    return { ...state, isLeadsLoading: false, error };
  },
};

// SAGA

export function* sagaGetLeads(action) {
  try {
    const data = yield call(apiGetLeads, action);
    yield put({ type: constants.ASSIGNMENTS_REPORT_LEADS_GET.SUCCESS, data });
    yield put(assignmentsReportActions.preFillLeadsDetails(data));
  } catch (e) {
    yield put({
      type: constants.ASSIGNMENTS_REPORT_LEADS_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetLeads() {
  yield takeLatest(constants.ASSIGNMENTS_REPORT_LEADS_GET.REQUEST, sagaGetLeads);
}

// API

export function apiGetLeads() {
  return request('/assignments-report/leads', {
    method: 'GET',
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchGetLeads,
};
