import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';
import each from 'lodash/each';

// MODULE

const constants = {
  ASSIGNMENTS_REPORT_LEAD_GET: createSagaAction('ASSIGNMENTS_REPORT_LEAD_GET'),
  ASSIGNMENTS_REPORT_PRE_FILL_LEADS_DETAILS: createSagaAction(
    'ASSIGNMENTS_REPORT_PRE_FILL_LEADS_DETAILS',
  ),
};

const actions = {
  getLeadById: (leadId) => ({
    type: constants.ASSIGNMENTS_REPORT_LEAD_GET.REQUEST,
    leadId,
  }),
  preFillLeadsDetails: (leads) => ({
    type: constants.ASSIGNMENTS_REPORT_PRE_FILL_LEADS_DETAILS.REQUEST,
    leads,
  }),
};

const handlers = {
  [constants.ASSIGNMENTS_REPORT_LEAD_GET.REQUEST]: (state, action) => {
    return { ...state, isLeadLoading: true };
  },
  [constants.ASSIGNMENTS_REPORT_LEAD_GET.SUCCESS]: (state, { data }) => {
    const leadsDetailsData = { ...state.leadsDetailsData };
    leadsDetailsData[data._id] = { ...leadsDetailsData[data._id], ...data };
    return {
      ...state,
      isLeadLoading: false,
      leadsDetailsData,
    };
  },
  [constants.ASSIGNMENTS_REPORT_LEAD_GET.FAILURE]: (state, { error }) => {
    return { ...state, isLeadLoading: false, error };
  },
  [constants.ASSIGNMENTS_REPORT_PRE_FILL_LEADS_DETAILS.REQUEST]: (state, { leads }) => {
    const leadsDetailsData = { ...state.leadsDetailsData };
    each(Object.keys(leads), (hireGroupKey) => {
      const leadsList = leads[hireGroupKey];
      each(leadsList, (lead) => {
        if (!leadsDetailsData[lead._id]) {
          // // some fields needs to be removed because there are not sufficient info for rendering those
          // each(lead.positions, (position) => {
          //   delete position.person;
          // });
          leadsDetailsData[lead._id] = lead;
        }
      });
    });
    return { ...state, leadsDetailsData };
  },
};

// SAGA

export function* sagaGetLeadById({ leadId }) {
  try {
    const data = yield call(apiGetLeadById, leadId);
    yield put({
      type: constants.ASSIGNMENTS_REPORT_LEAD_GET.SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: constants.ASSIGNMENTS_REPORT_LEAD_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetLeadById() {
  yield takeLatest(constants.ASSIGNMENTS_REPORT_LEAD_GET.REQUEST, sagaGetLeadById);
}

// API

export function apiGetLeadById(leadId) {
  return request(`/assignments-report/leads/${leadId}`, {
    method: 'GET',
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchGetLeadById,
};
