import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';
import { Endpoint, Method } from '../../infrastructure';
import { PlanYearlyFiltersSchema } from './plan-yearly-report';

//Revenue data
export const PlanQuarterlyRevSchema = z.object({
  yyear: z.number(),
  ymonth: z.number(),
  client_name: z.string(),
  client_id: z.string(),
  is_pre_client: z.number(),
  project_name: z.string(),
  project_id: z.string(),
  isproject_a_lead: z.number(),
  islead: z.boolean(),
  is_pre_opp: z.number(),
  month_revenue: z.number(),
  end_date_certainty: z.string(),
  plan_revenue_comment: z.string(),
  plan_revenue_comment_dtupdate: dateString,
  activepositions: z.number(),
});
export type PlanQuarterlyRev = z.infer<typeof PlanQuarterlyRevSchema>;

//Query
export const PlanQuarterlyRevQuerySchema = z.object({
  filters: PlanYearlyFiltersSchema.partial(),
});
export type PlanQuarterlyRevQuery = z.infer<typeof PlanQuarterlyRevQuerySchema>;

//Define the response
export type PlanQuarterlyRevResponse = PlanQuarterlyRev[];

//Define the endpoint
export type PlanQuarterlyRevEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-report/plan-quarterly-rev',
  PlanQuarterlyRevResponse,
  undefined,
  PlanQuarterlyRevQuery,
  undefined
>;
