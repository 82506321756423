import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';
import { Endpoint, Method } from '../../infrastructure';
import { HireGroup } from './../positions/index';

export const ConvertToLeadRequestSchema = z.object({
  name: z.string(),
  dtplannedstart: dateString,
  dtplannedend: dateString.nullish(),
  duration: z.number(),
  hard_date: z.boolean().nullable(),
  hire_group: z.nativeEnum(HireGroup).nullable(),
  timezone: z.string().nullable(),
  expected_monthly_revenue: z.number().nullable(),
  expected_team_size: z.number().nullable(),
  expected_monthly_cost: z.number().nullable(),
});
export type ConvertToLeadRequest = z.infer<typeof ConvertToLeadRequestSchema>;

export type ConvertToLeadParams = {
  project_id: string;
};

export type ConvertToLeadResponse = {};

export type ConvertToLeadEndpoint = Endpoint<
  Method.POST,
  `/api/v2/pre-leads/${ConvertToLeadParams['project_id']}/convert-to-lead`,
  ConvertToLeadResponse,
  ConvertToLeadParams,
  undefined,
  ConvertToLeadRequest
>;
