import request from '../../shared/request';

export function getAssignmentTracingReport({ from, to }) {
  const formattedFrom = from.format('YYYY-MM-DD');
  const formattedTo = to.format('YYYY-MM-DD');

  return request(`/reports/assignment-tracing?from=${formattedFrom}&to=${formattedTo}`, {
    method: 'GET',
  });
}
