import { z } from 'zod';
import { Endpoint, FilterOperations, Method, dateString } from '../../infrastructure';

export enum TerminationType {
  'Termination' = 'A',
  'MissHire' = 'B',
  'OfferDeclined' = 'C',
}
export const PsnHireInOutItemSchema = z.object({
  person_id: z.string(),
  name: z.string(),
  gravatar_url: z.string().nullable(),
  dthired: dateString,
  dtstart: dateString.nullable(),
  role: z.string(),
  dtterminated: dateString.nullable(),

  //Termination
  comment: z.string().nullable(),
  reason: z.string().nullable(),
  startedby: z.string().nullable(),
  is_immediate_termination: z.boolean().nullable(),

  los_days: z.number(),
  current_assignments: z
    .object({
      project_id: z.string(),
      name: z.string(),
      islead: z.boolean(),
    })
    .array(),
  termination_type: z.nativeEnum(TerminationType),
  department_name: z.string().nullable(),
  isbillable: z.boolean().nullable(),
  is_termed: z.boolean(),
  is_new_hire: z.boolean(),
});
export type PsnHireInOutItem = z.infer<typeof PsnHireInOutItemSchema>;

export const PsnHireInOutFilterSchema = z.object({
  dt_start: z.record(z.enum([FilterOperations.EQUAL]), dateString).optional(),
  dt_end: z.record(z.enum([FilterOperations.EQUAL]), dateString).optional(),
  billable: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
});
export type PsnHireInOutFilter = z.infer<typeof PsnHireInOutFilterSchema>;

export const PsnHireInOutQuerySchema = z.object({
  filters: PsnHireInOutFilterSchema.optional(),
});
export type PsnHireInOutQuery = z.infer<typeof PsnHireInOutQuerySchema>;

export type PsnHireInOutResponse = PsnHireInOutItem[];

export type PsnHireInOutEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sql-reports/psn-hire-in-out`,
  PsnHireInOutResponse,
  undefined,
  PsnHireInOutQuery,
  undefined
>;
