import { call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE
const constants = {
  GET_JOB_DEVSKILLER_TESTS: createSagaAction('GET_JOB_DEVSKILLER_TESTS'),
};

const actions = {
  getDevskillerTests: () => ({
    type: constants.GET_JOB_DEVSKILLER_TESTS.REQUEST,
  }),
};

const handlers = {
  [constants.GET_JOB_DEVSKILLER_TESTS.SUCCESS]: (state, { devskillerTests }) => {
    return { ...state, devskillerTests };
  },
  [constants.GET_JOB_DEVSKILLER_TESTS.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

// SAGA
function* getDevskillerTests(action) {
  try {
    const response = yield call(apiGetDevskillerTests, action);
    const devskillerTests = get(response, '_embedded.tests', []);

    yield put({
      type: constants.GET_JOB_DEVSKILLER_TESTS.SUCCESS,
      devskillerTests,
    });
  } catch (error) {
    yield put({
      type: constants.GET_JOB_DEVSKILLER_TESTS.FAILURE,
      error: error.message || error,
    });
  }
}

function* watchGetDevskillerTests() {
  yield takeLatest(constants.GET_JOB_DEVSKILLER_TESTS.REQUEST, getDevskillerTests);
}

// API
function apiGetDevskillerTests() {
  return request('/tech-tests', {
    method: 'GET',
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchGetDevskillerTests,
};
