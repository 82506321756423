export const Brains001Error = new Error();

export const SFErrors = {
  'brains-001': {
    message: 'Required fields not available in the request',
    status: 400,
    error: 'brains-001',
    detail: 'Required fields not available in the request',
  },
  'brains-101': {
    message: 'Account already exists on brains with',
    status: 400,
    error: 'brains-101',
    detail: 'The SF account id is already present in Brains',
    brains_id: null,
  },
  'brains-102': {
    message: 'Account not found in brains',
    status: 404,
    error: 'brains-102',
    detail: 'The SF account id is not present in Brains',
  },
  'brains-106': {
    message: 'Account SF Id is invalid',
    status: 400,
    error: 'brains-106',
    detail: 'Account SF Id is invalid',
  },
  'brains-201': {
    message: 'Duplicate opportunity id',
    status: 400,
    error: 'brains-201',
    detail: 'sf_opportunity_id already exists in brains',
    brains_id: null,
  },
  'brains-202': {
    message: 'Opportunity not found in brains',
    status: 404,
    error: 'brains-202',
    detail: 'Opportunity not found in brains',
  },
  'brains-203': {
    message: 'Invalid start date ( < today -30 days)',
    status: 400,
    error: 'brains-203',
    detail: 'The opportunity start date is more than 30 days old',
  },
  'brains-204': {
    message: 'Opportunity SF ID invalid',
    status: 400,
    error: 'brains-204',
    detail: 'Opportunity SF ID invalid',
  },
  'brains-205': {
    message: 'Opportunity is signed in brains',
    status: 400,
    error: 'brains-205',
    detail: 'The opportunity is signed, and can not have updates in Brains',
  },
  'brains-206': {
    message: 'Opportunity SF ID invalid',
    status: 400,
    error: 'brains-206',
    detail: 'Opportunity SF ID invalid',
  },
  'brains-302': {
    message: 'Owner not found in brains',
    status: 404,
    error: 'brains-302',
    detail: 'The owner id is not present in Brains',
  },
  'brains-303': {
    message: 'The owner is not active in Brains',
    status: 400,
    error: 'brains-303',
    detail: 'The owner is not active in Brains (not hired or terminated)',
  },
  'brains-401': {
    message:
      'Person not found in brains with the respective email inside sales, marketing or management',
    status: 404,
    error: 'brains-401',
    detail:
      'Person not found in brains with the respective email inside sales, marketing or management',
  },
  'brains-402': {
    message: 'Missing required parameters',
    status: 400,
    error: 'brains-402',
    detail: 'Missing required parameters',
  },
};
