import { all, fork, call, put, takeEvery } from 'redux-saga/effects';
import { constants } from '@redux/modules/sources';
import * as api from '@redux/api/sources';
import history from '@src/history';

function* getAll(action) {
  try {
    const response = yield call(api.getAllSources, action);
    yield put({ type: constants.SOURCES_GET_ALL.SUCCESS, allSources: response.data });
  } catch (e) {
    yield put({
      type: constants.SOURCES_GET_ALL.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchGetAll() {
  yield takeEvery(constants.SOURCES_GET_ALL.REQUEST, getAll);
}

function* create(action) {
  try {
    yield call(api.createSource, action);
    yield put({ type: constants.SOURCES_CREATE.SUCCESS });
    const response = yield call(api.getAllSources, action);
    yield put({ type: constants.SOURCES_GET_ALL.SUCCESS, allSources: response.data });
    // //;
    history.push('/admin/sources');
  } catch (e) {
    yield put({
      type: constants.SOURCES_CREATE.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchCreate() {
  yield takeEvery(constants.SOURCES_CREATE.REQUEST, create);
}

function* update(action) {
  try {
    yield call(api.updateSource, action);
    yield put({ type: constants.SOURCES_UPDATE.SUCCESS });
    const response = yield call(api.getAllSources, action);
    yield put({ type: constants.SOURCES_GET_ALL.SUCCESS, allSources: response.data });
    history.push('/admin/sources');
  } catch (e) {
    yield put({
      type: constants.SOURCES_UPDATE.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchUpdate() {
  yield takeEvery(constants.SOURCES_UPDATE.REQUEST, update);
}

function* deleteSource(action) {
  try {
    yield call(api.deleteSource, action);
    yield put({ type: constants.SOURCES_DELETE.SUCCESS });
    const response = yield call(api.getAllSources, action);
    yield put({ type: constants.SOURCES_GET_ALL.SUCCESS, allSources: response.data });
    history.push('/admin/sources');
  } catch (e) {
    yield put({
      type: constants.SOURCES_DELETE.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchDelete() {
  yield takeEvery(constants.SOURCES_DELETE.REQUEST, deleteSource);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([fork(watchGetAll), fork(watchCreate), fork(watchUpdate), fork(watchDelete)]);
}
