import { z } from 'zod';
import { Forecast, ForecastCreateSchema } from '../';
import { Endpoint, Method } from '../../../infrastructure';

export const ForecastCreateRequestSchema = ForecastCreateSchema.merge(
  z.object({
    client_name: z.string().nullish(),
    client_short_name: z.string().nullish(),
  }),
);
export type ForecastCreateRequest = z.infer<typeof ForecastCreateRequestSchema>;

export type ForecastCreateResponse = Forecast;

export type ForecastCreateEndpoint = Endpoint<
  Method.POST,
  '/api/v2/forecasts/',
  ForecastCreateResponse,
  undefined,
  undefined,
  ForecastCreateRequest
>;
