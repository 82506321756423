export enum Method {
  'GET' = 'GET',
  'POST' = 'POST',
  'PUT' = 'PUT',
  'DELETE' = 'DELETE',
  'PATCH' = 'PATCH',
}

export type Endpoint<
  METHOD extends Method,
  URL extends string,
  RESPONSE extends {} | string | void | null,
  PARAMS extends {} | undefined,
  QUERY extends {} | undefined,
  REQUEST extends {} | undefined,
> = {
  method: METHOD;
  url: URL;
  response: RESPONSE;
  params: PARAMS;
  query: QUERY;
  request?: REQUEST;
};

export type EndpointInferResponse<T extends Endpoint<any, any, any, any, any, any>> =
  T extends Endpoint<any, any, infer RESPONSE, any, any, any> ? RESPONSE : never;

export type EndpointInferQuery<T extends Endpoint<any, any, any, any, any, any>> =
  T extends Endpoint<any, any, any, any, infer QUERY, any> ? QUERY : never;

export type EndpointInferRequest<T extends Endpoint<any, any, any, any, any, any>> =
  T extends Endpoint<any, any, any, any, any, infer REQUEST> ? REQUEST : never;

export type EndpointInferParams<T extends Endpoint<any, any, any, any, any, any>> =
  T extends Endpoint<any, any, any, infer PARAMS, any, any>
    ? PARAMS extends undefined
      ? {}
      : PARAMS
    : never;
