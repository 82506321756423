import { all, fork, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';
import * as api from '@redux/api/calendly-user-types';
import { constants } from '@redux/modules/calendly-user-types';

function* getCalendlyUserTypes(action) {
  try {
    const response = yield call(api.getCalendlyUserTypes, action);
    const calendlyUserTypes = response.data;

    yield put({ type: constants.CALENDLY_USER_TYPES_LIST.SUCCESS, calendlyUserTypes });
  } catch (error) {
    yield put({
      type: constants.CALENDLY_USER_TYPES_LIST.FAILURE,
      error: error.message || error,
    });
  }
}

function* watchGetCalendlyUserTypes() {
  yield takeLatest(constants.CALENDLY_USER_TYPES_LIST.REQUEST, getCalendlyUserTypes);
}

function* createCalendlyUserType(action) {
  try {
    const createCalendlyUserTypeResponse = yield call(api.createCalendlyUserType, action);
    const calendlyUserTypesResponse = yield call(api.getCalendlyUserTypes, action);

    const calendlyUserTypes = calendlyUserTypesResponse.data;

    yield put({ type: constants.CALENDLY_USER_TYPES_LIST.SUCCESS, calendlyUserTypes });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(createCalendlyUserTypeResponse.id);
    }
  } catch (error) {
    yield put({
      type: constants.CALENDLY_USER_TYPES_LIST.FAILURE,
      error: error.message || error,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(error.message || error);
    }
  }
}

function* watchCreateCalendlyUserType() {
  yield takeLatest(constants.CALENDLY_USER_TYPES_CREATE.REQUEST, createCalendlyUserType);
}

function* updateCalendlyUserType(action) {
  try {
    const response = yield call(api.updateCalendlyUserType, action);
    const updatedCalendlyUserType = response.data;

    yield put({ type: constants.CALENDLY_USER_TYPES_UPDATE.SUCCESS, updatedCalendlyUserType });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(updatedCalendlyUserType);
    }
  } catch (error) {
    yield put({
      type: constants.CALENDLY_USER_TYPES_UPDATE.FAILURE,
      error: error.message || error,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(error.message || error);
    }
  }
}

function* watchUpdateCalendlyUserType() {
  yield takeLatest(constants.CALENDLY_USER_TYPES_UPDATE.REQUEST, updateCalendlyUserType);
}

function* deleteCalendlyUserType(action) {
  try {
    yield call(api.deleteCalendlyUserType, action);
    yield put({ type: constants.CALENDLY_USER_TYPES_DELETE.SUCCESS });
    const response = yield call(api.getCalendlyUserTypes, action);
    yield put({
      type: constants.CALENDLY_USER_TYPES_LIST.SUCCESS,
      calendlyUserTypes: response.data,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (error) {
    yield put({
      type: constants.CALENDLY_USER_TYPES_DELETE.FAILURE,
      error: error.message || error,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(error.message || error);
    }
  }
}

function* watchDeleteCalendlyUserType() {
  yield takeEvery(constants.CALENDLY_USER_TYPES_DELETE.REQUEST, deleteCalendlyUserType);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetCalendlyUserTypes),
    fork(watchCreateCalendlyUserType),
    fork(watchUpdateCalendlyUserType),
    fork(watchDeleteCalendlyUserType),
  ]);
}
