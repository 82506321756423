import { z } from 'zod';
import { Endpoint, Method, ReportsQuerySchema, makeSorter } from '../../infrastructure';

export const ListAllPositionsOpenToSalesSchema = z.object({
  position_id: z.string(),
  name: z.string(),
  client_name: z.string(),
  client_short_name: z.string().nullish(),
  project_name: z.string(),
  project_short_name: z.string().nullish(),
  start_date: z.string().nullish(),
  end_date: z.string().nullish(),
  seniority: z.string().nullish(),
  english_level: z.string().nullish(),
  applications: z.number(),
});

export type ListAllPositionsOpenToSales = z.infer<typeof ListAllPositionsOpenToSalesSchema>;

export const ListAllPositionsOpenToSalesFiltersSchema = z
  .object({
    name: z.string().optional(),
  })
  .partial();

export type ListAllPositionsOpenToSalesFilters = z.infer<
  typeof ListAllPositionsOpenToSalesFiltersSchema
>;

export const ListAllPositionsOpenToSalesSorterSchema = makeSorter(
  z.enum(['name', 'start_date', 'end_date', 'project_name', 'client_name', 'applications']),
);

export type ListAllPositionsOpenToSalesEventsSorter = z.infer<
  typeof ListAllPositionsOpenToSalesSorterSchema
>;

export const ListAllPositionsOpenToSalesQuerySchema = ReportsQuerySchema.extend({
  filters: ListAllPositionsOpenToSalesFiltersSchema.optional(),
  sorter: ListAllPositionsOpenToSalesSorterSchema.optional(),
});

export type ListAllPositionsOpenToSalesParams = undefined;

export type ListAllPositionsOpenToSalesRequest = undefined;

export type ListAllPositionsOpenToSalesQuery = z.infer<
  typeof ListAllPositionsOpenToSalesQuerySchema
>;

export type ListAllPositionsOpenToSalesResponse = Array<ListAllPositionsOpenToSales>;

export type ListAllPositionsOpenToSalesEndpoint = Endpoint<
  Method.GET,
  '/api/v2/positions/all-open-to-sales',
  ListAllPositionsOpenToSalesResponse,
  ListAllPositionsOpenToSalesParams,
  ListAllPositionsOpenToSalesQuery,
  ListAllPositionsOpenToSalesRequest
>;
