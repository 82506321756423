import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  EMAILS_GET: createSagaAction('EMAILS_GET'),
  SEND_EMAIL: createSagaAction('SEND_EMAIL'),
  EMAILS_GET_MOST_USED_TEMPLATES: createSagaAction('EMAILS_GET_MOST_USED_TEMPLATES'),
  EMAILS_GET_CATEGORIES: createSagaAction('EMAILS_GET_CATEGORIES'),
  EMAILS_CREATE_MOST_USED_TEMPLATES: createSagaAction('EMAILS_CREATE_MOST_USED_TEMPLATES'),
  EMAILS_UPDATE_MOST_USED_TEMPLATES: createSagaAction('EMAILS_UPDATE_MOST_USED_TEMPLATES'),
  EMAILS_CREATE_TEMPLATE: createSagaAction('EMAILS_CREATE_TEMPLATE'),
};

// Action Creators
export const actions = {
  get: (applicationId) => ({
    type: constants.EMAILS_GET.REQUEST,
    applicationId,
  }),
  sendEmail: (applicationId, emailTemplate, onSuccess, onFail) => ({
    type: constants.SEND_EMAIL.REQUEST,
    applicationId,
    ...emailTemplate,
    onSuccess,
    onFail,
  }),
  getMostUsedEmails: () => ({
    type: constants.EMAILS_GET_MOST_USED_TEMPLATES.REQUEST,
  }),
  getEmailCategories: () => ({
    type: constants.EMAILS_GET_CATEGORIES.REQUEST,
  }),
  createEmailTemplateMetric: (slug) => ({
    type: constants.EMAILS_CREATE_MOST_USED_TEMPLATES.REQUEST,
    slug,
  }),
  updateEmailTemplateMetric: (emailTemplateId, slug) => ({
    type: constants.EMAILS_UPDATE_MOST_USED_TEMPLATES.REQUEST,
    emailTemplateId,
    slug,
  }),
  createEmailTemplate: ({ applicationId, emailTemplate, onSuccess, onFail }) => ({
    type: constants.EMAILS_CREATE_TEMPLATE.REQUEST,
    applicationId,
    ...emailTemplate,
    onSuccess,
    onFail,
  }),
};

// Reducer initial state
export const initialState = {
  isLoading: false,
  isLoadingTemplates: false,
  emails: {},
  error: null,
  mostUsedEmails: {},
  sendingEmail: false,
};

const ACTION_HANDLERS = {
  // EMAILS_GET
  [constants.EMAILS_GET.SUCCESS]: (state, { emails }) => {
    return { ...state, emails, isLoadingTemplates: false };
  },
  [constants.EMAILS_GET.FAILURE]: (state, { error }) => {
    return { ...state, error, isLoadingTemplates: false };
  },
  [constants.EMAILS_GET.REQUEST]: (state) => {
    return { ...state, isLoadingTemplates: true };
  },

  // SEND_EMAIL
  [constants.SEND_EMAIL.REQUEST]: (state) => {
    return { ...state, isLoading: true, sendingEmail: true };
  },
  [constants.SEND_EMAIL.SUCCESS]: (state) => {
    return { ...state, isLoading: false, sendingEmail: false };
  },
  [constants.SEND_EMAIL.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error: error, sendingEmail: false };
  },

  // EMAILS_GET_MOST_USED_TEMPLATES
  [constants.EMAILS_GET_MOST_USED_TEMPLATES.SUCCESS]: (state, { mostUsedEmails }) => {
    return { ...state, mostUsedEmails };
  },
  [constants.EMAILS_GET_MOST_USED_TEMPLATES.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.EMAILS_GET_MOST_USED_TEMPLATES.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },

  // EMAILS_CREATE_MOST_USED_TEMPLATES
  [constants.EMAILS_CREATE_MOST_USED_TEMPLATES.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.EMAILS_CREATE_MOST_USED_TEMPLATES.SUCCESS]: (state) => {
    return { ...state, isLoading: false };
  },
  [constants.EMAILS_CREATE_MOST_USED_TEMPLATES.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error: error };
  },

  // EMAILS_UPDATE_MOST_USED_TEMPLATES
  [constants.EMAILS_UPDATE_MOST_USED_TEMPLATES.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.EMAILS_UPDATE_MOST_USED_TEMPLATES.SUCCESS]: (state) => {
    return { ...state, isLoading: false };
  },
  [constants.EMAILS_UPDATE_MOST_USED_TEMPLATES.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error: error };
  },

  // EMAILS_CREATE_TEMPLATE
  [constants.EMAILS_CREATE_TEMPLATE.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.EMAILS_CREATE_TEMPLATE.SUCCESS]: (state) => {
    return { ...state, isLoading: false };
  },
  [constants.EMAILS_CREATE_TEMPLATE.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error: error };
  },

  // EMAILS_GET_CATEGORIES
  [constants.EMAILS_GET_CATEGORIES.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.EMAILS_GET_CATEGORIES.SUCCESS]: (state, { emailCategories }) => {
    return { ...state, isLoading: false, emailCategories };
  },
  [constants.EMAILS_GET_CATEGORIES.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error: error };
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
