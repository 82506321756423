import { z } from 'zod';
import { dateString } from '../../infrastructure';
import { GoogleCalendarEventAttendeResponse } from '../google_calendar_event';

export enum InterviewCalendarEventStatus {
  'Requested' = 'Requested',
  'Scheduled' = 'Scheduled',
  'Accepted' = 'Accepted',
  'Declined' = 'Declined',
  'Rescheduled' = 'Rescheduled',
  'Canceled and Rescheduled' = 'Canceled and Rescheduled',
  'Canceled to be rescheduled' = 'Canceled to be rescheduled',
  'No show' = 'No show',
  'Conducted' = 'Conducted',
  'Dropped' = 'Dropped',
}

export const InterviewCalendarEventSchema = z.object({
  interview_calendar_event_id: z.number(),
  interview_request_id: z.number(),
  sequence: z.number(),
  token: z.string(),

  //External IDs
  calendly_event_id: z.string().nullable(),
  calendly_reschedule_url: z.string().nullable(),
  calendly_cancel_url: z.string().nullable(),

  google_calendar_event_id: z.string().nullable(),
  zoom_id: z.string().nullable(),

  status: z.nativeEnum(InterviewCalendarEventStatus),
  attendee_response: z.nativeEnum(GoogleCalendarEventAttendeResponse).nullable(),

  //Dates
  dt_start: dateString.nullable(),
  dt_end: dateString.nullable(),
  dt_canceled: dateString.nullable(),
  dt_conducted: dateString.nullable(),

  dt_last_google_update: dateString.nullable(),

  act_recruiter_id: z.string().nullable(),

  dt_created: dateString,
  dt_updated: dateString,
});
export type InterviewCalendarEvent = z.infer<typeof InterviewCalendarEventSchema>;

export enum InterviewCalendarEventTransaction {
  'NewEvent' = 'NewEvent',
  'CancelAndReschedule' = 'CancelAndReschedule',
  'Cancel' = 'Cancel',
  'Reschedule' = 'Reschedule',
  'ApplicantDecline' = 'ApplicantDecline',
  'ApplicantAccept' = 'ApplicantAccept',
  'SwitchRecruiter' = 'SwitchRecruiter',
  'SwitchRecruiterAndReschedule' = 'SwitchRecruiterAndReschedule',
  'Closed' = 'Closed',

  //Not from webhooks
  'Dropped' = 'Dropped',
  'Conducted' = 'Conducted',
  'No show' = 'No show',
}
export const InterviewCalendarEventTransactionMessageSchema = z
  .object({
    transaction_code: z.nativeEnum(InterviewCalendarEventTransaction),
    event_data: z.object({}),
    ts: dateString,
  })
  .merge(
    InterviewCalendarEventSchema.pick({
      interview_calendar_event_id: true,
    }),
  );
export type InterviewCalendarEventTransactionMessage = z.infer<
  typeof InterviewCalendarEventTransactionMessageSchema
>;
