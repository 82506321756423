import { z } from 'zod';
import { TimeOffRequestCommentUpdateSchema } from '../crud';
import { TimeOffRequestComment } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const TimeOffRequestCommentUpdateRequestSchema = TimeOffRequestCommentUpdateSchema.pick({
  comment: true,
});

export type TimeOffRequestCommentUpdateRequest = z.infer<
  typeof TimeOffRequestCommentUpdateRequestSchema
>;

export const TimeOffRequestCommentUpdateParamsSchema = z.object({
  time_off_request_comment_id: z.coerce.number(),
});
export type TimeOffRequestCommentUpdateParams = z.infer<
  typeof TimeOffRequestCommentUpdateParamsSchema
>;

export type TimeOffRequestCommentUpdateResponse = TimeOffRequestComment;

export type TimeOffRequestCommentUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/time-off-request-comments/${TimeOffRequestCommentUpdateParams['time_off_request_comment_id']}`,
  TimeOffRequestCommentUpdateResponse,
  TimeOffRequestCommentUpdateParams,
  undefined,
  TimeOffRequestCommentUpdateRequest
>;
