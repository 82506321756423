import { z } from 'zod';
import { StaffingCandidateSchema } from '../base';

export const StaffingCandidateCreateSchema = StaffingCandidateSchema.pick({
  application_id: true,
  person_id: true,
  position_staffing_id: true,
  rating: true,
  staffing_recruiter_id: true,
  status: true,
})
  .extend({
    user: z
      .object({
        name: z.string().optional(),
        email: z.string().optional(),
      })
      .optional(),
    replace_current_a: z.boolean().nullish(),
  })
  .partial({
    status: true,
  });

export type StaffingCandidateCreate = z.infer<typeof StaffingCandidateCreateSchema>;
