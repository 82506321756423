// GET TEST LIST

import { call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import * as api from '@redux/api/application';
import { constants } from '@redux/modules/application/application';

function* getApplicationTestList(action) {
  try {
    const payload = yield call(api.getApplicationTestList, action);
    const applicationTests = get(payload, '_embedded.tests', []);
    yield put({ type: constants.APPLICATION_TEST_LIST.SUCCESS, applicationTests });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_TEST_LIST.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetApplicationTestList() {
  yield takeLatest(constants.APPLICATION_TEST_LIST.REQUEST, getApplicationTestList);
}
