import { z } from 'zod';
import { PsnDepartmentSchema } from './base';
import { Endpoint, Method } from '../../infrastructure';

export const PsnDepartmentListByPersonSchema = PsnDepartmentSchema.pick({
  psn_department_id: true,
  department_id: true,
  person_id: true,
  dt_end: true,
  dt_start: true,
  dt_update: true,
  dt_create: true,
}).extend({
  department: z.string(),
  person: z.string(),
  isbillable: z.boolean(),
});
export type PsnDepartmentListByPerson = z.infer<typeof PsnDepartmentListByPersonSchema>;

//params
export const PsnDepartmentListByPersonParamsSchema = z.object({
  person_id: z.string(),
});

export type PsnDepartmentListByPersonParams = z.infer<typeof PsnDepartmentListByPersonParamsSchema>;

//Response
export const PsnDepartmentListByPersonResponseSchema = z.array(PsnDepartmentListByPersonSchema);
export type PsnDepartmentListByPersonResponse = z.infer<
  typeof PsnDepartmentListByPersonResponseSchema
>;

//Endpoint
export type PsnDepartmentListByPersonEndpoint = Endpoint<
  Method.GET,
  `/api/v2/psn-departments/list-by-person/:${PsnDepartmentListByPersonParams['person_id']}`,
  PsnDepartmentListByPersonResponse,
  PsnDepartmentListByPersonParams,
  undefined,
  undefined
>;
