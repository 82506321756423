import React from 'react';
import { Row, Col, Button, Spin } from 'antd';
import PropTypes from 'prop-types';

const propTypes = {
  searchResult: PropTypes.array,
  isLoading: PropTypes.bool,
  filterRedirect: PropTypes.func,
};

const QuickSearchNoContentPlaceholder = (props) => {
  const { searchResult, isLoading, filterRedirect } = props;

  if (isLoading || !searchResult) {
    return (
      <Row align="middle">
        <Col flex="auto">Searching...</Col>
        <Col>
          <Spin size="small" />
        </Col>
      </Row>
    );
  }

  return (
    <Row align="middle">
      <Col flex="auto">No applications found.</Col>
      {filterRedirect && (
        <Col>
          <Button type="default" size="small" onClick={filterRedirect}>
            Advanced Search
          </Button>
        </Col>
      )}
    </Row>
  );
};

QuickSearchNoContentPlaceholder.propTypes = propTypes;

export default QuickSearchNoContentPlaceholder;
