import request from '../../shared/request';

// GET CANDIDATE BY EMAIL
export function getCandidateInfo(email, applicationId) {
  const encodedEmail = encodeURIComponent(email);
  const encodedApplicationId = encodeURIComponent(applicationId);
  return request(`/candidates/info?email=${encodedEmail}&applicationId=${encodedApplicationId}`, {
    method: 'GET',
  });
}

// GET CANDIDATE'S ASSESSMENTS
export function getCandidateAssessments(candidateId) {
  return request(`/candidates/${candidateId}/assessments`, {
    method: 'GET',
  });
}
