import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const OpportunityDraftCancelParamsSchema = z.object({
  project_id: z.string(),
});

export type OpportunityDraftCancelParams = z.infer<typeof OpportunityDraftCancelParamsSchema>;

export type OpportunityDraftCancelResponse = {};

export type OpportunityDraftCancelEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/sql-leads/${OpportunityDraftCancelParams['project_id']}/cancel-draft`,
  OpportunityDraftCancelResponse,
  OpportunityDraftCancelParams,
  undefined,
  undefined
>;
