import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import { constants } from '@redux/modules/emails';
import * as api from '@redux/api/emails';
import { constants as applicationConstants } from '@redux/modules/application/application';
import { getApplicationTimelineList } from '@redux/api/application';
import { actions as entitiesActions } from '@redux/modules/entities';
import isFunction from 'lodash/isFunction';

// GET EMAILS LIST
function* getEmails(action) {
  try {
    const response = yield call(api.get, action);

    // there is no need to normalize the data, can go directly into mergeEmails
    yield put(entitiesActions.mergeEmails(response));

    yield put({
      type: constants.EMAILS_GET.SUCCESS,
    });
  } catch (e) {
    yield put({
      type: constants.EMAILS_GET.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchgetEmails() {
  yield takeLatest(constants.EMAILS_GET.REQUEST, getEmails);
}

// CREATE NEW INTERVIEW

function* sendEmails(action) {
  try {
    yield call(api.sendEmail, action);
    yield put({
      type: constants.SEND_EMAIL.SUCCESS,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
    const timelineResponse = yield call(getApplicationTimelineList, action);
    const timelineList = (timelineResponse && timelineResponse.data) || [];
    yield put({ type: applicationConstants.APPLICATION_TIMELINES_GET.SUCCESS, timelineList });

    // update most used templates list
    const mostUsedEmailsResponse = yield call(api.getMostUsedEmailTemplates, action);
    const mostUsedEmailsResponseList = mostUsedEmailsResponse || [];
    yield put({
      type: constants.EMAILS_GET_MOST_USED_TEMPLATES.SUCCESS,
      mostUsedEmailsResponseList,
    });
    yield put(entitiesActions.mergeMostUsedEmails(mostUsedEmailsResponseList));
  } catch (e) {
    yield put({
      type: constants.SEND_EMAIL.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onFail(e.message || e);
    }
  }
}

function* watchSendEmails() {
  yield takeLatest(constants.SEND_EMAIL.REQUEST, sendEmails);
}

// GET MOST USED EMAIL TEMPLATES LIST
function* getMostUsedEmails(action) {
  try {
    const response = yield call(api.getMostUsedEmailTemplates, action);
    // there is no need to normalize the data, can go directly into mergeEmails
    yield put(entitiesActions.mergeMostUsedEmails(response));

    yield put({
      type: constants.EMAILS_GET_MOST_USED_TEMPLATES.SUCCESS,
    });
  } catch (e) {
    yield put({
      type: constants.EMAILS_GET_MOST_USED_TEMPLATES.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchgetMostUsedEmails() {
  yield takeLatest(constants.EMAILS_GET_MOST_USED_TEMPLATES.REQUEST, getMostUsedEmails);
}

function* getEmailCategories(action) {
  try {
    const response = yield call(api.getEmailCategories);

    yield put({
      type: constants.EMAILS_GET_CATEGORIES.SUCCESS,
      emailCategories: response,
    });
  } catch (e) {
    yield put({
      type: constants.EMAILS_GET_CATEGORIES.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetEmailCategories() {
  yield takeLatest(constants.EMAILS_GET_CATEGORIES.REQUEST, getEmailCategories);
}

function* createEmailTemplateMetric(action) {
  try {
    yield call(api.createEmailTemplateMetric, action);
    yield put({
      type: constants.EMAILS_CREATE_MOST_USED_TEMPLATES.SUCCESS,
    });

    // update most used templates list
    const mostUsedEmailsResponse = yield call(api.getMostUsedEmailTemplates, action);
    const mostUsedEmailsResponseList = mostUsedEmailsResponse || [];
    yield put({
      type: constants.EMAILS_GET_MOST_USED_TEMPLATES.SUCCESS,
      mostUsedEmailsResponseList,
    });
    yield put(entitiesActions.mergeMostUsedEmails(mostUsedEmailsResponseList));
  } catch (e) {
    yield put({
      type: constants.EMAILS_CREATE_MOST_USED_TEMPLATES.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchCreateEmailTemplateMetric() {
  yield takeLatest(constants.EMAILS_CREATE_MOST_USED_TEMPLATES.REQUEST, createEmailTemplateMetric);
}

function* updateEmailTemplateMetric(action) {
  try {
    yield call(api.updateEmailTemplateMetric, action);

    const mostUsedEmailsResponse = yield call(api.getMostUsedEmailTemplates, action);
    const mostUsedEmailsResponseList = mostUsedEmailsResponse || [];
    yield put({
      type: constants.EMAILS_GET_MOST_USED_TEMPLATES.SUCCESS,
      mostUsedEmailsResponseList,
    });

    yield put(entitiesActions.mergeMostUsedEmails(mostUsedEmailsResponseList));

    yield put({
      type: constants.EMAILS_UPDATE_MOST_USED_TEMPLATES.SUCCESS,
    });
  } catch (e) {
    yield put({
      type: constants.EMAILS_UPDATE_MOST_USED_TEMPLATES.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchUpdateEmailTemplateMetric() {
  yield takeLatest(constants.EMAILS_UPDATE_MOST_USED_TEMPLATES.REQUEST, updateEmailTemplateMetric);
}

function* createEmailTemplate(action) {
  try {
    yield call(api.createEmailTemplate, action);
    yield put({
      type: constants.EMAILS_CREATE_TEMPLATE.SUCCESS,
    });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }

    const response = yield call(api.get, action);
    yield put(entitiesActions.mergeEmails(response));
    yield put({
      type: constants.EMAILS_GET.SUCCESS,
    });
  } catch (e) {
    yield put({
      type: constants.EMAILS_CREATE_TEMPLATE.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onFail(e.message || e);
    }
  }
}

function* watchCreateEmailTemplate() {
  yield takeLatest(constants.EMAILS_CREATE_TEMPLATE.REQUEST, createEmailTemplate);
}
/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchgetEmails),
    fork(watchSendEmails),
    fork(watchgetMostUsedEmails),
    fork(watchCreateEmailTemplateMetric),
    fork(watchUpdateEmailTemplateMetric),
    fork(watchCreateEmailTemplate),
    fork(watchGetEmailCategories),
  ]);
}
