import { z } from 'zod';
import { PlanCloudServiceFiltersSchema } from './cloud-service';
import { PlanPrjServiceFiltersSchema } from './prj-service';

export * from './prj-service';
export * from './cloud-service';
export * from './rev-detail';
export * from './prj-dpt-cost-detail';
export * from './overhead-cost-detail';
export * from './psn-tax';
export * from './psn-benefit';

export const MonthlyPlanV2FiltersSchema = PlanPrjServiceFiltersSchema.merge(
  PlanCloudServiceFiltersSchema,
);
export type MonthlyPlanV2Filters = z.infer<typeof MonthlyPlanV2FiltersSchema>;

export enum PlanServiceTypes {
  'PROJECT' = 'PS',
  'CLOUD' = 'CS',
}
export enum ProjectServiceSections {
  'REVENUE' = 'REV',
  'COS' = 'COS',
  'OVERHEAD' = 'OVER',
  'CALULATED' = 'CALC',
  'BUDGETED' = 'BUD',
}

export enum ProjectServiceCostTypes {
  'Non Project' = 'NPRJ',
  'Project' = 'PRJ',
}

export enum ProjectServiceOverheadTypes {
  'MGM' = 'MGM',
  'Internal Projects' = 'Internal Projects',
  'OTHERS' = 'OTHERS',
}

export enum ProjectServiceCostSubTypes {
  'Available' = 'AVA',
  'TermReimbursement' = 'TERM',
  'Vacation' = 'VAC',
}

export enum CloudServiceSections {
  'REVENUE' = 'REV',
  'COST' = 'COST',
  'PROFIT' = 'PROFIT',
  'MARGIN' = 'MARGIN',
  'PERCENT_COST' = 'PERCENT_COST',
}

export enum BudgetedTypes {
  'REVENUE' = 'REV',
  'COST' = 'COST',
}

export enum CalculatedTypes {
  'Cost %' = 'COST',
  'EXPECTED_COST' = 'EXP_COST',
  'MARGIN' = 'MARGIN',
  'OVERAGE' = 'OVERAGE',
  'PROFIT' = 'PROFIT',
  'EXPECTED_PROFIT' = 'EXP_PROFIT',
  'EXPECTED_PROFIT_MARGIN' = 'EXP_PROFIT_MARGIN',
}

export enum RevenueTypes {
  'PRJ' = 'PRJ',
  'OPP' = 'OPP',
  'PRE' = 'PRE',
}
