import { z } from 'zod';
import {
  Endpoint,
  FilterOperations,
  Method,
  ReportResponse,
  ReportsQuerySchema,
} from '../../infrastructure';
import { dateString } from '../../infrastructure/schema';

//Define the base return from database
export const RevenueTotalThroughMonthSchema = z.object({
  ymonth: z.number(),
  dtsnapshot: dateString,
  amount: z.number(),
});
export type RevenueTotalThroughMonth = z.infer<typeof RevenueTotalThroughMonthSchema>;

//Define filters
export const RevenueTotalThroughMonthFiltersSchema = z.object({
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
});
export type RevenueTotalThroughMonthFilters = z.infer<typeof RevenueTotalThroughMonthFiltersSchema>;

//Define the query of the endpoint
export const RevenueTotalThroughMonthQuerySchema = ReportsQuerySchema.extend({
  filters: RevenueTotalThroughMonthFiltersSchema.optional(),
});
export type RevenueTotalThroughMonthQuery = z.infer<typeof RevenueTotalThroughMonthQuerySchema>;

//Define the response
export type RevenueTotalThroughMonthResponse = ReportResponse<RevenueTotalThroughMonth>;

//Define the endpoint
export type RevenueTotalThroughMonthEndpoint = Endpoint<
  Method.GET,
  '/api/v2/revenue-total-through-month-endpoint',
  RevenueTotalThroughMonthResponse,
  undefined,
  RevenueTotalThroughMonthQuery,
  undefined
>;
