import request from '../../shared/request';
import qs from 'query-string';

export function getDailyFunnelReport({ countries, jobs, from, to }) {
  const query = qs.stringify({
    jobs,
    countries,
    from,
    to,
  });

  return request(`/reports/funnel-new?${query}`, {
    method: 'GET',
  });
}
