import { z } from 'zod';
import { Endpoint, FilterOperations, Method, ReportResponse } from '../../../infrastructure';
import { dateString } from '../../../infrastructure/schema';
import { ClientSnapshotComparisonFiltersSchema } from './client-snapshot-comparison-report';

//Define the base return from database
export const ProjectSnapshotComparisonSchema = z.object({
  ymonth: z.number(),
  client_id: z.string(),
  project_id: z.string(),
  project_name: z.string(),
  first_day: dateString,
  first_amount: z.number(),
  second_day: dateString,
  second_amount: z.number(),
});
export type ProjectSnapshotComparison = z.infer<typeof ProjectSnapshotComparisonSchema>;

//Define the filters
export const ProjectSnapshotComparisonFiltersSchema = ClientSnapshotComparisonFiltersSchema.extend({
  client_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
});
export type ProjectSnapshotComparisonFilters = z.infer<
  typeof ProjectSnapshotComparisonFiltersSchema
>;

//Define the query of the endpoint
export const ProjectSnapshotComparisonQuerySchema = z.object({
  filters: ProjectSnapshotComparisonFiltersSchema,
});
export type ProjectSnapshotComparisonQuery = z.infer<typeof ProjectSnapshotComparisonQuerySchema>;

//Define the response
export type ProjectSnapshotComparisonResponse = ReportResponse<ProjectSnapshotComparison>;

//Define the endpoint
export type ProjectSnapshotComparisonEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/snapshot-comparison/project',
  ProjectSnapshotComparisonResponse,
  undefined,
  ProjectSnapshotComparisonQuery,
  undefined
>;
