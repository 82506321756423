const actionDefinitions = [
  {
    key: 'HIRED_GET',
    opts: { successKey: 'hired' },
  },
  {
    key: 'STATUS_UPDATE',
    opts: { successKey: 'statusUpdate' },
  },
  {
    key: 'REPORT_WS_EVENT',
    opts: { successKey: 'processSocket' },
  },
];

export default actionDefinitions;
