import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  ASSIGNMENTS_REPORT_LEAD_START_DATE_UPDATE: createSagaAction(
    'ASSIGNMENTS_REPORT_LEAD_START_DATE_UPDATE',
  ),
};

const actions = {
  updateLeadStartDate: (leadId, startDate) => ({
    type: constants.ASSIGNMENTS_REPORT_LEAD_START_DATE_UPDATE.REQUEST,
    leadId,
    startDate,
  }),
};

const handlers = {
  [constants.ASSIGNMENTS_REPORT_LEAD_START_DATE_UPDATE.REQUEST]: (state, action) => {
    return { ...state, isLeadStartDateUpdating: true };
  },
  [constants.ASSIGNMENTS_REPORT_LEAD_START_DATE_UPDATE.SUCCESS]: (state, { data }) => {
    const leadsDetailsData = { ...state.leadsDetailsData };
    if (leadsDetailsData[data._id]) {
      leadsDetailsData[data._id].startDate = data.startDate;
    }
    return {
      ...state,
      leadsDetailsData,
    };
  },
  [constants.ASSIGNMENTS_REPORT_LEAD_START_DATE_UPDATE.FAILURE]: (state, { error }) => {
    return {
      ...state,
      isLeadStartDateUpdating: false,
      leadStartDateError: error,
    };
  },
};

// SAGA

export function* sagaUpdateLeadStartDate({ leadId, startDate }) {
  try {
    // TODO roll it back if backend return an error
    yield put({
      type: constants.ASSIGNMENTS_REPORT_LEAD_START_DATE_UPDATE.SUCCESS,
      data: { leadId, startDate },
    });
    yield call(apiUpdateLeadStartDate, leadId, startDate);
  } catch (e) {
    yield put({
      type: constants.ASSIGNMENTS_REPORT_LEAD_START_DATE_UPDATE.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchUpdateLeadStartDate() {
  yield takeLatest(
    constants.ASSIGNMENTS_REPORT_LEAD_START_DATE_UPDATE.REQUEST,
    sagaUpdateLeadStartDate,
  );
}

// API

export function apiUpdateLeadStartDate(leadId, startDate) {
  return request(`/assignments-report/leads/${leadId}`, {
    method: 'GET',
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchUpdateLeadStartDate,
};
