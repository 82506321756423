import { PsnRctrGoogleCalendarChannelSchema } from '../base';
import { z } from 'zod';

export const PsnRctrGoogleCalendarChannelCreateSchema = PsnRctrGoogleCalendarChannelSchema.pick({
  person_id: true,
  address: true,
  email: true,
  calendar_id: true,
}).partial({
  calendar_id: true,
  email: true,
  address: true,
});
export type PsnRctrGoogleCalendarChannelCreate = z.infer<
  typeof PsnRctrGoogleCalendarChannelCreateSchema
>;
