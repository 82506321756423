import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';
import { message } from 'antd';

// Constants
export const constants = {
  ON_DECK_REPORT_GET: createSagaAction('ON_DECK_REPORT_GET'),
  ON_DECK_UPDATE_APPLICATION_MGMT_REVIEWED: createSagaAction('UPDATE_APPLICATION_MGMT_REVIEWED'),
};

// Action Creators
export const actions = {
  getOnDeckReport: () => ({
    type: constants.ON_DECK_REPORT_GET.REQUEST,
  }),
  updateApplicationMgmtReviewed: ({ applicationId, reviewed }) => ({
    type: constants.ON_DECK_UPDATE_APPLICATION_MGMT_REVIEWED.REQUEST,
    applicationId,
    reviewed,
  }),
};

// Reducer
export const initialState = {
  reportByJobs: [],
  isLoading: false,
  error: null,
};

const ACTION_HANDLERS = {
  [constants.ON_DECK_REPORT_GET.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.ON_DECK_REPORT_GET.SUCCESS]: (state, { reportByJobs }) => {
    return { ...state, reportByJobs, isLoading: false };
  },
  [constants.ON_DECK_REPORT_GET.FAILURE]: (state, { error }) => {
    message.error('Error fetching On Deck report');
    return { ...state, error, isLoading: false };
  },
  [constants.ON_DECK_UPDATE_APPLICATION_MGMT_REVIEWED.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.ON_DECK_UPDATE_APPLICATION_MGMT_REVIEWED.SUCCESS]: (
    state,
    { applicationId, reviewed },
  ) => {
    const reportByJobs = state.reportByJobs.map((job) => {
      const applications = job.applications.map((application) => {
        if (application._id === applicationId) {
          return { ...application, mgmtReviewed: reviewed };
        }
        return application;
      });
      return { ...job, applications };
    });
    message.success('Successfully updated Application Mgmt Reviewed');
    return { ...state, reportByJobs, isLoading: false };
  },
  [constants.ON_DECK_UPDATE_APPLICATION_MGMT_REVIEWED.FAILURE]: (state, { error }) => {
    message.error('Error updating Application Mgmt Reviewed');
    return { ...state, error, isLoading: false };
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
