import { z } from 'zod';
import { dateString } from '../../infrastructure';
import { PositionPostgresSchema } from './base';

export const RecruitingPropsChangeSChema = PositionPostgresSchema.pick({
  position_id: true,
  isstaffit: true,
  hire_group: true,
}).merge(
  z.object({
    dt_staffit: dateString.nullable(),
  }),
);

export type RecruitingPropsChange = z.infer<typeof RecruitingPropsChangeSChema>;
