import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  ASSIGNMENTS_REPORT_LEAD_COMMENTS_GET: createSagaAction('ASSIGNMENTS_REPORT_LEAD_COMMENTS_GET'),
};

const actions = {
  getLeadComments: (leadId) => ({
    type: constants.ASSIGNMENTS_REPORT_LEAD_COMMENTS_GET.REQUEST,
    leadId,
  }),
};

const handlers = {
  [constants.ASSIGNMENTS_REPORT_LEAD_COMMENTS_GET.REQUEST]: (state, action) => {
    return { ...state, isLeadCommentsLoading: true };
  },
  [constants.ASSIGNMENTS_REPORT_LEAD_COMMENTS_GET.SUCCESS]: (state, { leadId, data }) => {
    const leadsCommentsData = { ...state.leadsCommentsData };
    leadsCommentsData[leadId] = data;
    return {
      ...state,
      isLeadCommentsLoading: false,
      leadsCommentsData,
    };
  },
  [constants.ASSIGNMENTS_REPORT_LEAD_COMMENTS_GET.FAILURE]: (state, { error }) => {
    return { ...state, isLeadCommentsLoading: false, error };
  },
};

// SAGA

export function* sagaGetLeadComments({ leadId }) {
  try {
    const data = yield call(apiGetLeadComments, leadId);
    yield put({
      type: constants.ASSIGNMENTS_REPORT_LEAD_COMMENTS_GET.SUCCESS,
      leadId,
      data,
    });
  } catch (e) {
    yield put({
      type: constants.ASSIGNMENTS_REPORT_LEAD_COMMENTS_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetLeadComments() {
  yield takeLatest(constants.ASSIGNMENTS_REPORT_LEAD_COMMENTS_GET.REQUEST, sagaGetLeadComments);
}

// API

export function apiGetLeadComments(leadId) {
  return request(`/assignments-report/leads/${leadId}/comments`, {
    method: 'GET',
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchGetLeadComments,
};
