import moment from 'moment';
import { createReducer } from '@shared/reducers';

import set from 'lodash/set';

export const constants = {
  GET_PAGE_STATE: 'GET_PAGE_STATE',
  SET_PAGE_STATE: 'SET_PAGE_STATE',
  SET_PAGE_ITEM_STATE: 'SET_PAGE_ITEM_STATE',
};

export const actions = {
  getPageState: (pageKey) => ({ type: constants.GET_PAGE_STATE, pageKey }),
  setPageState: (pageKey, pageState) => ({ type: constants.SET_PAGE_STATE, pageKey, pageState }),
  setPageItemState: (pageKey, itemKey, newState) => ({
    type: constants.SET_PAGE_ITEM_STATE,
    pageKey,
    itemKey,
    newState,
  }),
};

export const initialState = {
  'late-stages': {
    date: moment().startOf('year').format('YYYY-MM-DD'),
    context: 'Hires',
    cos: 'all',
    activeKey: 'latest-hires',
  },
};

export default createReducer(initialState, (state, action) => {
  switch (action.type) {
    case constants.SET_PAGE_STATE: {
      state[action.pageKey] = action.pageState;
      return { ...state };
    }
    case constants.GET_PAGE_STATE: {
      return { ...state };
    }
    case constants.SET_PAGE_ITEM_STATE: {
      set(state[action.pageKey], action.itemKey, action.newState);
      return { ...state };
    }
    default:
      return state;
  }
});
