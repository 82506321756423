import { Endpoint, Method } from '../../../infrastructure';
import { z } from 'zod';

// request schema (for validation of body)
export const GenericTablePutRequestSchema = z.any();
export type GenericTablePutRequest = z.infer<typeof GenericTablePutRequestSchema>;

// params schema (for validation of params)
export const GenericTablePutParamsSchema = z.object({
  table_name: z.string(),
  key_name: z.string(),
});

// params
export type GenericTablePutParams = z.infer<typeof GenericTablePutParamsSchema>;

// response (for validation of response, could be any using any table)
export type GenericTablePutResponse = any;

// endpoint -> method, url, response, params, query, body
export type GenericTablePutEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/generic-table/${GenericTablePutParams['table_name']}/${GenericTablePutParams['key_name']}`,
  GenericTablePutResponse,
  GenericTablePutParams,
  undefined,
  GenericTablePutRequest
>;
