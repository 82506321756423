import { call, put, takeLatest } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  BULK_UPDATE_APPLICATIONS_JOB: createSagaAction('BULK_UPDATE_APPLICATIONS_JOB'),
};

const actions = {
  bulkUpdateApplicationsJob: (data) => ({
    ...data,
    type: constants.BULK_UPDATE_APPLICATIONS_JOB.REQUEST,
  }),
};

const handlers = {
  [constants.BULK_UPDATE_APPLICATIONS_JOB.REQUEST]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [constants.BULK_UPDATE_APPLICATIONS_JOB.SUCCESS]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [constants.BULK_UPDATE_APPLICATIONS_JOB.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

function* BulkUpdateApplicationsJob(action) {
  try {
    yield call(apiBulkUpdateApplicationsJob, action);
    yield put({
      type: constants.BULK_UPDATE_APPLICATIONS_JOB.SUCCESS,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.BULK_UPDATE_APPLICATIONS_JOB.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchBulkUpdateApplicationsJob() {
  yield takeLatest(constants.BULK_UPDATE_APPLICATIONS_JOB.REQUEST, BulkUpdateApplicationsJob);
}

// API

export function apiBulkUpdateApplicationsJob({ applicationsIds, jobId }) {
  const promises = applicationsIds.map((applicationId) => {
    return request(`/applications/${applicationId}/job`, {
      method: 'PUT',
      body: {
        jobId,
      },
    });
  });
  return Promise.all(promises);
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchBulkUpdateApplicationsJob,

  // optionally we may
  // api: {
  //   getAccount: apiGetAccount
  // }
};
