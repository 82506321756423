import { z } from 'zod';

import { Endpoint, Method } from '../../infrastructure';
import { PreLeadComment } from './base';

export type AddPreLeadCommentParams = {
  grid_row_id: number;
};

export const AddPreLeadCommentRequestSchema = z.object({
  author_id: z.string(),
  txt_content: z.string(),
});
export type AddPreLeadCommentRequest = z.infer<typeof AddPreLeadCommentRequestSchema>;

export type AddPreLeadCommentResponse = { comment: PreLeadComment };

export type AddPreLeadCommentEndpoint = Endpoint<
  Method.POST,
  `/api/v3/pre-leads/${AddPreLeadCommentParams['grid_row_id']}/comments`,
  AddPreLeadCommentResponse,
  AddPreLeadCommentParams,
  undefined,
  AddPreLeadCommentRequest
>;
