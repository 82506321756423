import { z } from 'zod';
import { TimeOffRequestSchema } from '../base';

export const TimeOffRequestUpdateSchema = TimeOffRequestSchema.pick({
  time_off_request_id: true,
  date_range: true,
  is_flexible: true,
  is_paid: true,
  status: true,
  category: true,
  approved_by: true,
  dt_approved: true,
  declined_by: true,
  dt_declined: true,
  working_days: true,
  last_year_days: true,
  working_days_last_year: true,
  working_days_next_year: true,
  exceptional_not_counted: true,
}).partial({
  category: true,
  date_range: true,
  is_flexible: true,
  is_paid: true,
  status: true,
  approved_by: true,
  dt_approved: true,
  declined_by: true,
  dt_declined: true,
  working_days: true,
  last_year_days: true,
  working_days_last_year: true,
  working_days_next_year: true,
  exceptional_not_counted: true,
});

export type TimeOffRequestUpdate = z.infer<typeof TimeOffRequestUpdateSchema>;
