import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { dateString } from '../../infrastructure/schema';

export const PositionEarlyTerminationRequestSchema = z.object({
  dt_early_termination: dateString,
  recompute_revenue: z.boolean().nullish(),
});
export type PositionEarlyTerminationRequest = z.infer<typeof PositionEarlyTerminationRequestSchema>;

export const PositionEarlyTerminationParamsSchema = z.object({
  position_id: z.string(),
});
export type PositionEarlyTerminationParams = z.infer<typeof PositionEarlyTerminationParamsSchema>;

export type PositionEarlyTerminationResponse = void;

export type PositionEarlyTerminationEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/positions/early-termination/${PositionEarlyTerminationParams['position_id']}`,
  PositionEarlyTerminationResponse,
  PositionEarlyTerminationParams,
  undefined,
  PositionEarlyTerminationRequest
>;
