import request from '../../shared/request';

export function getUser({ userId }) {
  return request(`/users/${userId}`, {
    method: 'GET',
  });
}
export function searchUsersByEmail({ email }) {
  return request(`/users/search?email=${encodeURIComponent(email)}`, {
    method: 'GET',
    encode: true,
  });
}
export function searchUsersByName({ name }) {
  return request(`/users/search?name=${name}`, {
    method: 'GET',
  });
}
export function editUser({ userId, applicationId, user }) {
  return request(`/users/${userId}`, {
    method: 'PUT',
    body: {
      ...user,
      applicationId,
    },
  });
}
export function editUserName({ name, userId }) {
  return request(`/users/${userId}/name`, {
    method: 'PUT',
    body: {
      name,
      userId,
    },
  });
}
export function createGoogleAccount({ userId }) {
  return request(`/users/${userId}/create-google-account`, {
    method: 'POST',
  });
}
export function sendWelcomeLetter({ userId, email, title, role }) {
  return request(`/users/${userId}/send-welcome-letter`, {
    method: 'POST',
    body: {
      email,
      title,
      role,
    },
  });
}
export function sendFirstDayInstructions({ userId }) {
  return request(`/users/${userId}/send-first-day-instructions`, {
    method: 'POST',
  });
}
