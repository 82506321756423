import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import { constants } from '@redux/modules/reports/statuses-report';
import * as api from '@redux/api/statuses-report';

// GET SOURCES
function* getActions(action) {
  try {
    const response = yield call(api.getStatusesReport, action);
    const actions = response.actions;

    yield put({ type: constants.GET_STATUSES_REPORT.SUCCESS, actions });
  } catch (e) {
    yield put({
      type: constants.GET_STATUSES_REPORT.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetActions() {
  yield takeLatest(constants.GET_STATUSES_REPORT.REQUEST, getActions);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([fork(watchGetActions)]);
}
