import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../../infrastructure';

export const PlanPrjDptCostDetailItemSchema = z.object({
  person_id: z.string(),
  name: z.string(),
  ymonth: z.number(),
  yyear: z.number(),
  plan_category: z.string(),
  plan_section: z.string(),
  department_id: z.string(),
  department: z.string(),
  amount: z.number(),
});
export type PlanPrjDptCostDetailItem = z.infer<typeof PlanPrjDptCostDetailItemSchema>;

export const PlanPrjDptCostDetailFiltersSchema = z.object({
  ymonth_period_start: z
    .record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth_period_end: z
    .record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
});
export type PlanPrjDptCostDetailFilters = z.infer<typeof PlanPrjDptCostDetailFiltersSchema>;

export const PlanPrjDptCostDetailQuerySchema = z.object({
  filters: PlanPrjDptCostDetailFiltersSchema.optional(),
});
export type PlanPrjDptCostDetailQuery = z.infer<typeof PlanPrjDptCostDetailQuerySchema>;

export type PlanPrjDptCostDetailResponse = PlanPrjDptCostDetailItem[];

export type PlanPrjDptCostDetailEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/monthly-plan-v2/prj-dpt-cost-detail',
  PlanPrjDptCostDetailResponse,
  undefined,
  PlanPrjDptCostDetailQuery,
  undefined
>;
