import React, { Component } from 'react';
import './NoMatch.less';

const propTypes = {};

class NoMatch extends Component {
  render() {
    return (
      <div>
        <div className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">
                Page not found
                <br />
                ¯\_(`ツ`)_/¯
              </h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NoMatch.propTypes = propTypes;
export default NoMatch;
