//UPDATE USER LINKEDIN URL
import * as api from '../../../api/application';
import * as schemas from '../../../../schemas';
import { actions as entitiesActions } from '../../../modules/entities';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '../../../modules/application/application';
import { normalize } from 'normalizr';

function* updateUserLinkedinURL(action) {
  try {
    const response = yield call(api.updateUserLinkedinURL, action);
    const normalized = normalize(response.application, schemas.application);
    const { users, applications } = normalized.entities;

    yield put(entitiesActions.mergeApplications(applications));

    yield put(entitiesActions.mergeUsers(users));

    yield put({
      type: constants.APPLICATION_USER_LINKEDIN_URL_UPDATE.SUCCESS,
    });

    const timelineResponse = yield call(api.getApplicationTimelineList, action);
    const timelineList = (timelineResponse && timelineResponse.data) || [];
    yield put({ type: constants.APPLICATION_TIMELINES_GET.SUCCESS, timelineList });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_USER_LINKEDIN_URL_UPDATE.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchUpdateUserLinkedinURL() {
  yield takeLatest(constants.APPLICATION_USER_LINKEDIN_URL_UPDATE.REQUEST, updateUserLinkedinURL);
}
