import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

import isFunction from 'lodash/isFunction';

// MODULE

const constants = {
  INTERVIEWS_HAPPENED_GET: createSagaAction('INTERVIEWS_HAPPENED_GET'),
};

const actions = {
  getInterviewsHappened: (data) => ({
    ...data,
    type: constants.INTERVIEWS_HAPPENED_GET.REQUEST,
  }),
};

const handlers = {
  [constants.INTERVIEWS_HAPPENED_GET.REQUEST]: (state, action) => {
    return { ...state, isLoading: true, resultsHappened: [] };
  },
  [constants.INTERVIEWS_HAPPENED_GET.SUCCESS]: (state, { data }) => {
    return { ...state, isLoading: false, resultsHappened: data };
  },
  [constants.INTERVIEWS_HAPPENED_GET.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

export function* sagaGetInterviewsHappened(action) {
  try {
    const data = yield call(apiGetInterviewsHappened, action);
    yield put({ type: constants.INTERVIEWS_HAPPENED_GET.SUCCESS, data });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.INTERVIEWS_HAPPENED_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetInterviewsHappened() {
  yield takeLatest(constants.INTERVIEWS_HAPPENED_GET.REQUEST, sagaGetInterviewsHappened);
}

// API

export function apiGetInterviewsHappened({ urlParams }) {
  return request(`/applications/interviews?${urlParams}`, {
    method: 'GET',
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchGetInterviewsHappened,

  // optionally we may
  // api: {
  //   getAccount: apiGetAccount
  // }
};
