import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';

export const PositionStaffingGetApplicationMatchSchema = z.object({
  project_id: z.string(),
  project_name: z.string(),
  position_id: z.string(),
  position_name: z.string(),
  position_staffing_id: z.number(),
  recruiting_category: z.string(),
  expected_english_level: z.number(),
  expected_seniority: z.number(),
  expected_skills: z.array(z.string()),
  application_id: z.number(),
  requested_salary: z.number(),
  agreed_salary: z.number(),
  english_level: z.number(),
  seniority: z.number(),
  country: z.string(),
  excitement_rate: z.number(),
  app_skills: z.string(),
  applicant: z.string(),
});

export type PositionStaffingGetApplicationMatch = z.infer<
  typeof PositionStaffingGetApplicationMatchSchema
>;

export const PositionStaffingGetApplicationMatchFiltersSchema = z.object({
  project_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  position_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  hire_group: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  match_seniority: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.boolean()).optional(),
  match_english_level: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.boolean()).optional(),
});
export type PositionStaffingGetApplicationMatchFilters = z.infer<
  typeof PositionStaffingGetApplicationMatchFiltersSchema
>;

export const PositionStaffingGetApplicationMatchQuerySchema = z.object({
  filters: PositionStaffingGetApplicationMatchFiltersSchema,
});
export type PositionStaffingGetApplicationMatchQuery = z.infer<
  typeof PositionStaffingGetApplicationMatchQuerySchema
>;

export type PositionStaffingGetApplicationMatchResponse = PositionStaffingGetApplicationMatch[];

//Define the endpoint
export type PositionStaffingGetApplicationMatchEndpoint = Endpoint<
  Method.GET,
  '/api/v2/positions-staffing/application-match',
  PositionStaffingGetApplicationMatchResponse,
  undefined,
  PositionStaffingGetApplicationMatchQuery,
  undefined
>;
