import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { ListProjectSchema } from './listProject';

export const ProjectGetByIdSchema = ListProjectSchema;
export type ProjectGetById = z.infer<typeof ProjectGetByIdSchema>;

//filters
export const ProjectGetByIdParamsSchema = z.object({
  project_id: z.string(),
});

export type ProjectGetByIdParams = z.infer<typeof ProjectGetByIdParamsSchema>;

//Response
export type ProjectGetByIdResponse = ProjectGetById;

//Endpoint
export type ProjectGetByIdEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sql-projects/${ProjectGetByIdParams['project_id']}`,
  ProjectGetByIdResponse,
  ProjectGetByIdParams,
  undefined,
  undefined
>;
