import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../../infrastructure';

export const PlanPsnTaxItemSchema = z.object({
  yyear: z.number(),
  person_id: z.string(),
  name: z.string(),
  ymonth: z.number(),
  plan_section: z.string(),
  amount: z.number(),
});
export type PlanPsnTaxItem = z.infer<typeof PlanPsnTaxItemSchema>;

export const PlanPsnTaxFiltersSchema = z.object({
  ymonth_period_start: z
    .record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth_period_end: z
    .record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
});
export type PlanPsnTaxFilters = z.infer<typeof PlanPsnTaxFiltersSchema>;

export const PlanPsnTaxQuerySchema = z.object({
  filters: PlanPsnTaxFiltersSchema.optional(),
});
export type PlanPsnTaxQuery = z.infer<typeof PlanPsnTaxQuerySchema>;

export type PlanPsnTaxResponse = PlanPsnTaxItem[];

export type PlanPsnTaxEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/monthly-plan-v2/psn-taxes',
  PlanPsnTaxResponse,
  undefined,
  PlanPsnTaxQuery,
  undefined
>;
