//APPLICATION_SET_BACK_URL

import { put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';

function* setBackUrl(action) {
  try {
    yield put({
      type: constants.APPLICATION_SET_BACK_URL.SUCCESS,
      backUrl: action.backUrl,
    });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_SET_BACK_URL.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchSetBackUrl() {
  yield takeLatest(constants.APPLICATION_SET_BACK_URL.REQUEST, setBackUrl);
}
