import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  ASSIGNMENTS_REPORT_ALL_LEAD_COMMENTS_GET: createSagaAction(
    'ASSIGNMENTS_REPORT_ALL_LEAD_COMMENTS_GET',
  ),
};

const actions = {
  getAllLeadsComments: (leadsId) => ({
    type: constants.ASSIGNMENTS_REPORT_ALL_LEAD_COMMENTS_GET.REQUEST,
    leadsId,
  }),
};

const handlers = {
  [constants.ASSIGNMENTS_REPORT_ALL_LEAD_COMMENTS_GET.REQUEST]: (state, action) => {
    return { ...state, isLeadCommentsLoading: true };
  },
  [constants.ASSIGNMENTS_REPORT_ALL_LEAD_COMMENTS_GET.SUCCESS]: (state, { data }) => {
    return {
      ...state,
      isLeadCommentsLoading: false,
      allLeadsComments: data?.comments ?? [],
    };
  },
  [constants.ASSIGNMENTS_REPORT_ALL_LEAD_COMMENTS_GET.FAILURE]: (state, { error }) => {
    return { ...state, isLeadCommentsLoading: false, error };
  },
};

// SAGA

export function* sagaGetLeadComments({ leadsId }) {
  try {
    const data = yield call(apiGetLeadComments, leadsId);
    yield put({
      type: constants.ASSIGNMENTS_REPORT_ALL_LEAD_COMMENTS_GET.SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: constants.ASSIGNMENTS_REPORT_ALL_LEAD_COMMENTS_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetLeadComments() {
  yield takeLatest(constants.ASSIGNMENTS_REPORT_ALL_LEAD_COMMENTS_GET.REQUEST, sagaGetLeadComments);
}

// API

export function apiGetLeadComments(leadsId) {
  return request(`/assignments-report/leads/comments`, {
    method: 'GET',
    qs: { leadsId },
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchGetLeadComments,
};
