import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

const constants = {
  JOB_PRIORITIES_GET: createSagaAction('JOB_PRIORITIES_GET'),
};

const actions = {
  get: () => ({
    type: constants.JOB_PRIORITIES_GET.REQUEST,
  }),
};

const handlers = {
  [constants.JOB_PRIORITIES_GET.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.JOB_PRIORITIES_GET.SUCCESS]: (state, { jobPriorities }) => {
    return { ...state, isLoading: false, jobPriorities };
  },
  [constants.JOB_PRIORITIES_GET.FAILURE]: (state, { error }) => {
    return { ...state, error, isLoading: false };
  },
};

function* getJobPriorities(action) {
  try {
    const response = yield call(apiGetJobPriorities, action);
    const jobPriorities = response.data;

    yield put({ type: constants.JOB_PRIORITIES_GET.SUCCESS, jobPriorities });
  } catch (error) {
    yield put({
      type: constants.JOB_PRIORITIES_GET.FAILURE,
      error: error.message || error,
    });
  }
}

function* watchGetJobPriorities() {
  yield takeLatest(constants.JOB_PRIORITIES_GET.REQUEST, getJobPriorities);
}

export function apiGetJobPriorities() {
  return request('/job-priorities', {
    method: 'GET',
  });
}

export default {
  actions,
  constants,
  handlers,
  watcher: watchGetJobPriorities,
};
