import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  ASSIGNMENT_OPTIONS_GET: createSagaAction('ASSIGNMENT_OPTIONS_GET'),
};

const actions = {
  getAssignmentOptions: (data) => ({
    ...data,
    type: constants.ASSIGNMENT_OPTIONS_GET.REQUEST,
  }),
};

const handlers = {
  [constants.ASSIGNMENT_OPTIONS_GET.REQUEST]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [constants.ASSIGNMENT_OPTIONS_GET.SUCCESS]: (state, action) => {
    return { ...state, isLoading: false, assignmentOptions: action.assignmentOptions };
  },
  [constants.ASSIGNMENT_OPTIONS_GET.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

// GET ASSIGNMENTS OPTIONS

function* getAssignmentOptions(action) {
  try {
    const response = yield call(apiGetAssignmentOptions, action);
    const assignmentOptions = response;

    yield put({ type: constants.ASSIGNMENT_OPTIONS_GET.SUCCESS, assignmentOptions });
  } catch (e) {
    yield put({
      type: constants.ASSIGNMENT_OPTIONS_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetAssignmentOptions() {
  yield takeLatest(constants.ASSIGNMENT_OPTIONS_GET.REQUEST, getAssignmentOptions);
}

// API

export function apiGetAssignmentOptions() {
  return request('/applications/assignment-options?show=current,deleted,special', {
    method: 'GET',
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchGetAssignmentOptions,

  // optionally we may
  // api: {
  //   getAccount: apiGetAccount
  // }
};
