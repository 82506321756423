import { call, put, takeLatest } from 'redux-saga/effects';

import { createSagaAction } from '@shared/sagas';
import request from '@shared/request';

import flatten from 'lodash/flatten';

// MODULE
const constants = {
  LEADS_LIST: createSagaAction('LEADS_LIST'),
};

const actions = {
  getLeads: (data) => ({
    data,
    type: constants.LEADS_LIST.REQUEST,
  }),
};

const handlers = {
  [constants.LEADS_LIST.REQUEST]: (state) => {
    return {
      ...state,
      loadedById: {}, // Resetting loadedById since we are loading new leads
      isLoading: true,
      error: null,
      updateSuccessMessage: null,
      updateErrorMessage: null,
    };
  },
  [constants.LEADS_LIST.SUCCESS]: (state, { leads, leadsList }) => {
    return {
      ...state,
      leads,
      leadsList,
      isLoading: false,
      error: null,
      updateSuccessMessage: null,
      updateErrorMessage: null,
    };
  },
  [constants.LEADS_LIST.FAILURE]: (state, { error }) => {
    return {
      ...state,
      error,
      isLoading: false,
      updateErrorMessage: null,
      updateSuccessMessage: null,
    };
  },
};

// SAGA
function* sagaGetLeads(action) {
  try {
    const response = yield call(apiGetLeads, action);
    const leads = response.data;

    yield put({
      leads,
      type: constants.LEADS_LIST.SUCCESS,
      leadsList: flatten(Object.values(leads)),
    });
  } catch (e) {
    yield put({
      type: constants.LEADS_LIST.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetLeads() {
  yield takeLatest(constants.LEADS_LIST.REQUEST, sagaGetLeads);
}

// API
function apiGetLeads() {
  return request('/leads', {
    method: 'GET',
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchGetLeads,
};
