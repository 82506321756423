import { z } from 'zod';
import { TimeOffRequestCreateSchema } from '../crud';
import { TimeOffRequest } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const TimeOffRequestCreateRequestSchema = TimeOffRequestCreateSchema.pick({
  category: true,
  date_range: true,
  is_flexible: true,
  is_paid: true,
  last_year_days: true,
  person_id: true,
  status: true,
});

export type TimeOffRequestCreateRequest = z.infer<typeof TimeOffRequestCreateRequestSchema>;

export type TimeOffRequestCreateResponse = TimeOffRequest;

export type TimeOffRequestCreateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/time-off-requests`,
  TimeOffRequestCreateResponse,
  undefined,
  undefined,
  TimeOffRequestCreateRequest
>;
