import { all, fork } from 'redux-saga/effects';

import {
  watchGetMessages,
  watchPostMessage,
  watchDeleteMessage,
  watchUpdateMessage,
  watchTogglePinMessage,
} from './messages';

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  const sagas = [
    watchGetMessages,
    watchPostMessage,
    watchDeleteMessage,
    watchUpdateMessage,
    watchTogglePinMessage,
  ].map(fork);

  yield all(sagas);
}
