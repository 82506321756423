import { Endpoint, Method } from '../../../infrastructure';
import { InterviewCalendarEventActionSchema } from '../base';
import { z } from 'zod';

export const InterviewCalendarEventActionSendMessageRequestSchema =
  InterviewCalendarEventActionSchema.pick({
    action_code: true,
    interview_calendar_event_id: true,
    transaction_code: true,
  });
export type InterviewCalendarEventActionSendMessageRequest = z.infer<
  typeof InterviewCalendarEventActionSendMessageRequestSchema
>;

export type InterviewCalendarEventActionSendMessageResponse = {};

export type InterviewCalendarEventActionSendMessageEndpoint = Endpoint<
  Method.POST,
  '/api/v2/interview-calendar-event-actions',
  InterviewCalendarEventActionSendMessageResponse,
  undefined,
  undefined,
  InterviewCalendarEventActionSendMessageRequest
>;
