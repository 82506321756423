import { z } from 'zod';
import { Lead, LeadSchema } from './base';
import { Endpoint, Method } from '../../infrastructure';

export const LeadSetExpectedTeamSizeRequestSchema = z.object({
  expected_team_size: z.number(),
});
export type LeadSetExpectedTeamSizeRequest = z.infer<typeof LeadSetExpectedTeamSizeRequestSchema>;

export const LeadSetExpectedTeamSizeParamsSchema = LeadSchema.pick({
  project_id: true,
});

export type LeadSetExpectedTeamSizeParams = z.infer<typeof LeadSetExpectedTeamSizeParamsSchema>;

export type LeadSetExpectedTeamSizeResponse = Lead;

export type LeadSetExpectedTeamSizeEndpoint = Endpoint<
  Method.PUT,
  `/api/sql-leads/${LeadSetExpectedTeamSizeParams['project_id']}/set-expected-team-size`,
  LeadSetExpectedTeamSizeResponse,
  LeadSetExpectedTeamSizeParams,
  undefined,
  LeadSetExpectedTeamSizeRequest
>;
