import { z } from 'zod';
import { Endpoint, Method, FilterOperations } from '../../infrastructure';
import { PayoutsTotalSummarySchema } from './base';
import { dateString } from '../../infrastructure/schema';

export type GetPayoutsTotalSummaryEndpoint = Endpoint<
  Method.GET,
  '/api/v2/payouts/total-summary',
  GetPayoutsTotalSummaryResponse,
  undefined,
  GetPayoutsTotalSummaryQuery,
  undefined
>;

export const GetPayoutsTotalSummaryQuerySchema = z.object({
  filters: z
    .object({
      payout_type_code: z.record(
        z.enum([FilterOperations.EQUAL, FilterOperations.IN]),
        z.string().or(z.array(z.string())),
      ),
      acct_cost_code: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
      target_pay_date: z.record(
        z.enum([FilterOperations.GREATER_THAN_OR_EQUAL, FilterOperations.LESS_THAN_OR_EQUAL]),
        dateString,
      ),
    })
    .partial()
    .optional(),
});
export type GetPayoutsTotalSummaryQuery = z.infer<typeof GetPayoutsTotalSummaryQuerySchema>;

export const GetPayoutsTotalSummaryResponseSchema = z.object({
  total_summary: PayoutsTotalSummarySchema,
});
export type GetPayoutsTotalSummaryResponse = z.infer<typeof GetPayoutsTotalSummaryResponseSchema>;
