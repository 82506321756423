import { Card, Typography, Flex, Tag } from 'antd';
import { NotificationConfig } from './NotificationTypes';

import moment from 'moment';

import { ActionRequest } from '@ct-internal/api';

import { NotificationDate, getDueDateTagColor } from './helpers';
import { ActionComponent, ActionConfig } from './ActionTypes';
import CloseNotification from './ActionTypes/CloseNotification';

interface NotificationCardProps {
  notification: ActionRequest;
}

const NotificationCard = ({ notification }: NotificationCardProps) => {
  const config = NotificationConfig[notification.slug];

  const actions: (ActionComponent | undefined)[] = [CloseNotification];

  if (notification.action_components) {
    actions.unshift(...notification.action_components.map((key) => ActionConfig[key]));
  }

  const due_date = moment(notification.dt_action_due);

  return (
    <Card
      className="notification-card"
      size="small"
      title={
        <Flex vertical style={{ marginBottom: 8, marginTop: 8 }}>
          <Flex justify="space-between" align="center">
            <Typography.Text strong>{config?.title}</Typography.Text>
            {notification.dt_action_due && (
              <Flex gap={4}>
                <Typography.Text>Due Date:</Typography.Text>
                <Tag color={getDueDateTagColor(due_date.toISOString())} style={{ margin: 0 }}>
                  {due_date.format('ll')}
                </Tag>
              </Flex>
            )}
          </Flex>
          <NotificationDate date={moment(notification.dt_request).toDate()} />
        </Flex>
      }
      actions={actions?.map((Component) => Component && <Component notification={notification} />)}
    >
      {config?.getDescription(notification)}
    </Card>
  );
};

export default NotificationCard;
