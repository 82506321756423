import { AppActionSchema } from '../base';
import { z } from 'zod';

export const AppActionCreateSchema = AppActionSchema.pick({
  action_recruiter_id: true,
  action_name: true,

  application_id: true,
  dt_action: true,

  result: true,
});
export type AppActionCreate = z.infer<typeof AppActionCreateSchema>;
