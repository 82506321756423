import { z } from 'zod';
import { PositionPostgresSchema } from '..';

export const SQLPositionUpdateSchema = PositionPostgresSchema.pick({
  position_id: true,
  isactive: true,
  isstaffit: true,
  islead: true,
  name: true,
  dtsigned: true,
  dtplannedstart: true,
  dtplannedend: true,
  notes: true,
  inv_pos_name: true,
  target_cost: true,
  hire_group: true,
  seniority: true,
  english_level: true,
  hard_date: true,
  dt_staffit: true,
}).partial({
  isactive: true,
  isstaffit: true,
  islead: true,
  name: true,
  dtsigned: true,
  dtplannedstart: true,
  dtplannedend: true,
  notes: true,
  inv_pos_name: true,
  target_cost: true,
  hire_group: true,
  seniority: true,
  english_level: true,
  hard_date: true,
  dt_staffit: true,
});

export type SQLPositionUpdate = z.infer<typeof SQLPositionUpdateSchema>;
