import { z } from 'zod';
import { BaseSchemaDW } from '../structure';

export enum InterviewContactType {
  'hr' = 'hr',
  'mgmt' = 'mgmt',
  'tech-all' = 'tech-all',
}
export const InterviewContactTypeSchema = z.nativeEnum(InterviewContactType);

export const ApplicationCTPersonDWSchema = z
  .object({
    application_id: z.number(),
    person_id: z.string(),
    contact_type: InterviewContactTypeSchema,
  })
  .merge(BaseSchemaDW);

export type ApplicationCTPersonDW = z.infer<typeof ApplicationCTPersonDWSchema>;

export const ApplicationInterviewMongoSchema = z.object({
  applicationId: z.number(),
  interviewerPersonId: z.string(),
  interviewerEmail: z.string().nullable(),
  type: z.string(),
  status: z.number(),
  datetime: z.date(),
  bookedAt: z.date(),
  created_at: z.date(),
  updated_at: z.date(),
});
export type ApplicationInterviewMongo = z.infer<typeof ApplicationInterviewMongoSchema>;
