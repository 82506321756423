import { call, put, takeLatest } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  BULK_SEND_EMAIL: createSagaAction('BULK_SEND_EMAIL'),
};

const actions = {
  bulkSendEmail: (data) => ({
    ...data,
    type: constants.BULK_SEND_EMAIL.REQUEST,
  }),
};

const handlers = {
  [constants.BULK_SEND_EMAIL.REQUEST]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [constants.BULK_SEND_EMAIL.SUCCESS]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [constants.BULK_SEND_EMAIL.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

function* BulkSendEmail(action) {
  try {
    yield call(apiBulkSendEmail, action);
    yield put({
      type: constants.BULK_SEND_EMAIL.SUCCESS,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.BULK_SEND_EMAIL.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchBulkSendEmail() {
  yield takeLatest(constants.BULK_SEND_EMAIL.REQUEST, BulkSendEmail);
}

// API

export function apiBulkSendEmail({
  applicationsIds,
  text,
  slug,
  subject,
  name,
  when,
  useCurrentUserFromAddress,
  extras,
  urlParams,
}) {
  return request(`/applications/email-templates?${urlParams}`, {
    method: 'POST',
    body: {
      text,
      slug,
      subject,
      name,
      when,
      useCurrentUserFromAddress,
      extras,
      applicationsIds,
    },
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchBulkSendEmail,

  // optionally we may
  // api: {
  //   getAccount: apiGetAccount
  // }
};
