import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../../infrastructure';
import { dateString } from '../../../infrastructure/schema';
import { ProjectSnapshotComparisonFiltersSchema } from './project-snapshot-comparison-report';

//Define the base return from database
export const InvoiceSnapshotComparisonSchema = z.object({
  first_day: dateString,
  second_day: dateString,
  client_id: z.string(),
  client_name: z.string(),
  project_id: z.string(),
  project_name: z.string(),
  c_invoice_id: z.string(),
  person_id: z.string(),
  person_name: z.string(),
  c_ymonth: z.number(),
  c_seq_num: z.number(),
  c_invoice_num: z.number(),
  comp_section: z.string(),
  var_what: z.string(),
  var_how: z.string(),
  var_id: z.string(),
  qty_type: z.string(),
  p_qty: z.number(),
  c_qty: z.number(),
  p_rate_type: z.string(),
  c_rate_type: z.string(),
  p_rate: z.string(),
  c_rate: z.string(),
  p_amount: z.number(),
  c_amount: z.number(),
  description: z.string(),
  hierarchy: z.number(),
});
export type InvoiceSnapshotComparison = z.infer<typeof InvoiceSnapshotComparisonSchema>;

//Define filters
export const InvoiceSnapshotComparisonFiltersSchema = ProjectSnapshotComparisonFiltersSchema.pick({
  first_day: true,
  second_day: true,
}).extend({
  project_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
});
export type InvoiceSnapshotComparisonFilters = z.infer<
  typeof InvoiceSnapshotComparisonFiltersSchema
>;

//Define the query of the endpoint
export const InvoiceSnapshotComparisonQuerySchema = z.object({
  filters: InvoiceSnapshotComparisonFiltersSchema,
});
export type InvoiceSnapshotComparisonQuery = z.infer<typeof InvoiceSnapshotComparisonQuerySchema>;

//Define the response
export const InvoiceSnapshotComparisonResponseSchema = InvoiceSnapshotComparisonSchema.array();
export type InvoiceSnapshotComparisonResponse = z.infer<
  typeof InvoiceSnapshotComparisonResponseSchema
>;

//Define the endpoint
export type InvoiceSnapshotComparisonEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/snapshot-comparison/invoice',
  InvoiceSnapshotComparisonResponse,
  undefined,
  InvoiceSnapshotComparisonQuery,
  undefined
>;
