import { z } from 'zod';
import { PositionSchema } from './base';
import { Endpoint, FilterOperations, Method, dateString, makeSorter } from '../../infrastructure';
import { ListSQLPositionsSchema } from './listSQLPositions';
import { stringToBoolean } from '../../infrastructure/schema';

export const ListPositionWithMongoSchema = ListSQLPositionsSchema.extend({
  mongoPosition: PositionSchema,
});
export type ListPositionWithMongo = z.infer<typeof ListPositionWithMongoSchema>;

//Filters
export const ListPositionWithMongoFiltersSchema = z.object({
  project_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  dtplannedend: z
    .record(
      z.enum([FilterOperations.GREATER_THAN_OR_EQUAL, FilterOperations.LESS_THAN_OR_EQUAL]),
      dateString,
    )
    .optional(),
  dtplannedstart: z
    .record(
      z.enum([FilterOperations.GREATER_THAN_OR_EQUAL, FilterOperations.LESS_THAN_OR_EQUAL]),
      dateString,
    )
    .optional(),
  islead: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.boolean()).optional(),
  isstaffit: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.boolean()).optional(),
  is_assigned: z
    .record(z.enum([FilterOperations.EQUAL, FilterOperations.NOT_EQUAL]), z.coerce.boolean())
    .optional(),
  searchText: z.record(z.enum([FilterOperations.CONTAIN]), z.string()).optional(),
  includeArchived: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean).optional(),
});
export type ListPositionWithMongoFilters = z.infer<typeof ListPositionWithMongoFiltersSchema>;

export const ListPositionWithMongoSorterSchema = makeSorter(
  z.enum([
    'name',
    'seniority',
    'english_level',
    'target_cost',
    'dtplannedstart',
    'dtplannedend',
    'skills',
    'revenueamount',
    'hire_group',
    'asn_start',
    'asn_end',
  ]),
);
export type ListPositionWithMongoSorter = z.infer<typeof ListPositionWithMongoSorterSchema>;

//Query
export const ListPositionWithMongoQuerySchema = z.object({
  filters: ListPositionWithMongoFiltersSchema.optional(),
  sorter: ListPositionWithMongoSorterSchema.optional(),
});
export type ListPositionWithMongoQuery = z.infer<typeof ListPositionWithMongoQuerySchema>;

//Response
export type ListPositionWithMongoResponse = ListPositionWithMongo[];

//Endpoint
export type ListPositionWithMongoEndpoint = Endpoint<
  Method.GET,
  '/api/v2/positions/positions-with-mongo',
  ListPositionWithMongoResponse,
  undefined,
  ListPositionWithMongoQuery,
  undefined
>;
