import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  SEARCH_GET_SUMMARY_MONGO: createSagaAction('SEARCH_GET_SUMMARY_MONGO'),
};

const actions = {
  getSummaryMongo: (data) => ({
    ...data,
    type: constants.SEARCH_GET_SUMMARY_MONGO.REQUEST,
  }),
};

const handlers = {
  [constants.SEARCH_GET_SUMMARY_MONGO.REQUEST]: (state, action) => {
    return { ...state, isLoadingSearchSummary: true };
  },
  [constants.SEARCH_GET_SUMMARY_MONGO.SUCCESS]: (state, { searchSummary }) => {
    return { ...state, isLoadingSearchSummary: false, searchSummary };
  },
  [constants.SEARCH_GET_SUMMARY_MONGO.FAILURE]: (state, { error }) => {
    return { ...state, isLoadingSearchSummary: false, error };
  },
};

// SAGA

function* getMongoSearchSummary({ urlParams }) {
  try {
    const response = yield call(apiGetMongoSummary, urlParams);
    yield put({
      type: constants.SEARCH_GET_SUMMARY_MONGO.SUCCESS,
      searchSummary: response,
    });
  } catch (e) {
    yield put({
      type: constants.SEARCH_GET_SUMMARY_MONGO.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetSummary() {
  yield takeLatest(constants.SEARCH_GET_SUMMARY_MONGO.REQUEST, getMongoSearchSummary);
}

// API

export function apiGetMongoSummary(urlParams) {
  return request(`/applications/summary-mongo?${urlParams}`, {
    method: 'GET',
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchGetSummary,

  // optionally we may
  // api: {
  //   getAccount: apiGetAccount
  // }
};
