import { createSelector } from 'reselect';
import { currentApplicationSelector } from './application';

export const getCurrentUserEmail = (state, params = {}) => params.userEmail || null;

export const getUsers = (state) => state.entities.users;

export const getUserbyId = (state) => state.user.user;

export const searchUsersByEmail = (state) =>
  state.user.searchResult ? state.user.searchResult.byEmail : [];

export const searchUsersByName = (state) =>
  state.user.searchResult ? state.user.searchResult.byName : [];

export const userForCurrentApplicationSelector = createSelector(
  [getUsers, currentApplicationSelector],
  (users, application) => users[application.user] || {},
);

export const userByIdSelector = (state) => (userId) =>
  createSelector([getUsers], (users) => users[userId] || {})(state);
