import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const PrjCommentSchema = z.object({
  comment_id: z.number(),
  project_id: z.string(),

  dt_created: dateString,
});
export type PrjComment = z.infer<typeof PrjCommentSchema>;
