import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../..';
import { PersonSchema } from '../people';

export const PlanPsnAvailableMonthlyCostItemSchema = z
  .object({
    yyear: z.number(),
    ymonth: z.number(),
    month_cost: z.number(),
    person_id: z.string(),
  })
  .merge(
    PersonSchema.pick({
      name: true,
      firstname: true,
      surname: true,
      dtstart: true,
      dthired: true,
      timezone: true,
    }),
  );
export type PlanPsnAvailableMonthlyCostItem = z.infer<typeof PlanPsnAvailableMonthlyCostItemSchema>;

export const PlanPsnAvailableMonthlyCostFiltersSchema = z.object({
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
  ymonth_period_start: z
    .record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth_period_end: z
    .record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
});
export type PlanPsnAvailableMonthlyCostFilters = z.infer<
  typeof PlanPsnAvailableMonthlyCostFiltersSchema
>;

export const PlanPsnAvailableMonthlyCostQuerySchema = z.object({
  filters: PlanPsnAvailableMonthlyCostFiltersSchema.optional(),
});
export type PlanPsnAvailableMonthlyCostQuery = z.infer<
  typeof PlanPsnAvailableMonthlyCostQuerySchema
>;

export type PlanPsnAvailableMonthlyCostResponse = PlanPsnAvailableMonthlyCostItem[];

export type PlanPsnAvailableMonthlyCostEndpoint = Endpoint<
  Method.GET,
  `/api/sql-reports/plan-psn-available-monthly-cost`,
  PlanPsnAvailableMonthlyCostResponse,
  undefined,
  PlanPsnAvailableMonthlyCostQuery,
  undefined
>;
