import { z } from 'zod';
import { StaffingCandidateSchema } from '../base';

export const StaffingCandidateDeleteSchema = StaffingCandidateSchema.pick({
  staffing_candidate_id: true,
}).extend({
  user: z
    .object({
      name: z.string().optional(),
      email: z.string().optional(),
    })
    .optional(),
});

export type StaffingCandidateDelete = z.infer<typeof StaffingCandidateDeleteSchema>;
