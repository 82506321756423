import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const BulkPositionRemoveOppExtensionRequestSchema = z.object({
  positions_id: z.array(z.string()),
  project_id: z.string(),
});
export type BulkPositionRemoveOppExtensionRequest = z.infer<
  typeof BulkPositionRemoveOppExtensionRequestSchema
>;

export type BulkPositionRemoveOppExtensionResponse = void;

export type BulkPositionRemoveOppExtensionEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/positions/bulk-opp-extension`,
  BulkPositionRemoveOppExtensionResponse,
  undefined,
  undefined,
  BulkPositionRemoveOppExtensionRequest
>;
