import { z } from 'zod';
import { ProjectSchema } from './base';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';
import { stringToBoolean } from '../../infrastructure/schema';

//Base
export const ListProjectSchema = ProjectSchema.extend({
  client_name: z.string(),
  client_short_name: z.string(),
  client_as_of_ymonth: z.number().nullish(),
  project_sf_id: z.string(),
  qty_positions: z.number(),
  qty_assignments: z.number(),
});
export type ListProject = z.infer<typeof ListProjectSchema>;

//Filters
export const ListProjectFiltersSchema = z.object({
  project_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  client_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  isactive: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean).optional(),
  islead: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean).optional(),
  isarchived: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean).optional(),
  end_date_certainty: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
});
export type ListProjectFilters = z.infer<typeof ListProjectFiltersSchema>;

//Sorters
//TODO

//Query
export const ListProjectQuerySchema = z.object({
  filters: ListProjectFiltersSchema.optional(),
});
export type ListProjectQuery = z.infer<typeof ListProjectQuerySchema>;

//Response
export type ListProjectResponse = ListProject[];

//Endpoint
export type ListProjectEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-projects/',
  ListProjectResponse,
  undefined,
  ListProjectQuery,
  undefined
>;
