/* eslint-disable no-console */
import { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import map from 'lodash/map';
import forEach from 'lodash/forEach';

import { getSocketIoClient as getWebsocketClient } from '@shared/socket-io';
import { getUser } from '@redux/store';

export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    // This is how we prevent debounced value from updating if value is changed ...
    // .. within the delay period. Timeout gets cleared and restarted.
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]); // Only re-call effect if value or delay changes

  return debouncedValue;
};

function _getSocketHandler(eventName, handlerFn, opts) {
  const { ignoreMyUpdates = false } = opts || {};
  return function (payload = {}) {
    try {
      const socket = getWebsocketClient();
      const isMine = socket.id === get(payload, 'sender', null);

      if (isMine && ignoreMyUpdates) {
        console.log(`WEBSOCKET event "${eventName}" skipped`, payload);
        return null;
      }

      console.log(`WEBSOCKET event ${eventName} processed`, payload);
      return handlerFn(payload);
    } catch (err) {
      console.error(`WEBSOCKET event "${eventName}" error`, err, payload);
      return null;
    }
  };
}

export const useSocketListener = (eventName, _opts, _fn) => {
  const isOptsMissing = !_fn && isFunction(_opts);
  const fn = isOptsMissing ? _opts : _fn;
  const opts = isOptsMissing ? {} : _opts;
  const isTest = process.env.NODE_ENV === 'test';

  const handlerFnRef = useRef();
  handlerFnRef.current = _getSocketHandler(eventName, fn, opts);

  const user = getUser();
  const userId = user && user._id;

  useEffect(() => {
    async function setListener() {
      try {
        const socket = await getWebsocketClient();
        if (socket && !isTest) {
          socket.on(eventName, handlerFnRef.current);
          console.log(`Socket on "${eventName}"`);
        }
      } catch (err) {
        console.error(`Socket on "${eventName}" error:`, err);
      }
    }

    setListener();

    return async () => {
      try {
        const socket = await getWebsocketClient();
        if (socket && !isTest) {
          socket.off(eventName, handlerFnRef.current);
          console.log(`Socket off "${eventName}"`);
        }
      } catch (err) {
        console.error(`Socket off "${eventName}" error:`, err);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventName, userId, isTest]);
};

export const useWindowSize = () => {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

export const useModalTitle = (leadId, name) => {
  const [clientName, setClientName] = useState(null);
  const [title, setTitle] = useState('');
  const [leadData, setLeadData] = useState('');

  const leadsDetailsData = useSelector((state) => state.assignmentsReportV2.leadsDetailsData);

  const getClientName = (leadData) => {
    if (clientName !== null) {
      return;
    }
    if (!leadData.client) {
      return;
    }
    setClientName(leadData.client.name);
  };

  const getModalTitle = () => {
    if (!leadData) {
      return;
    }
    const clientAndPositionInformation = [clientName, leadData.name, name];

    forEach(clientAndPositionInformation, (item, idx) => {
      return (clientAndPositionInformation[idx] = item ? item : '');
    });
    const titleArray = map(clientAndPositionInformation, (item, idx) => {
      if (!clientAndPositionInformation[idx + 1] || item === '') {
        return item;
      }
      return `${item} — `;
    });
    return titleArray.join('');
  };

  useEffect(() => {
    if (leadsDetailsData[leadId]) {
      setLeadData(leadsDetailsData[leadId]);
    }
  }, [leadsDetailsData]);

  useEffect(() => {
    if (!leadData) {
      return;
    }
    setTitle(getModalTitle());
  }, [clientName, leadData]);

  useEffect(() => {
    if (!leadData || !clientName) {
      return;
    }
    if (leadData.name.includes(clientName)) {
      return setClientName(null);
    }
  }, [clientName]);

  useEffect(() => {
    if (!leadData) {
      return;
    }
    getClientName(leadData);
  }, [leadData]);

  return title;
};

export const useDidUpdate = (effect, dependencies) => {
  const hasMounted = useRef(false);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }

    effect();
  }, dependencies);
};
