import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  ASSIGNMENTS_REPORT_LEAD_NAME_UPDATE: createSagaAction('ASSIGNMENTS_REPORT_LEAD_NAME_UPDATE'),
};

const actions = {
  updateLeadName: (leadId, name) => ({
    type: constants.ASSIGNMENTS_REPORT_LEAD_NAME_UPDATE.REQUEST,
    leadId,
    name,
  }),
};

const handlers = {
  [constants.ASSIGNMENTS_REPORT_LEAD_NAME_UPDATE.REQUEST]: (state, action) => {
    return { ...state, isLeadNameUpdating: true };
  },
  [constants.ASSIGNMENTS_REPORT_LEAD_NAME_UPDATE.SUCCESS]: (state, { data }) => {
    const leadsDetailsData = { ...state.leadsDetailsData };
    if (leadsDetailsData[data._id]) {
      leadsDetailsData[data._id].name = data.name;
    }
    return {
      ...state,
      leadsDetailsData,
    };
  },
  [constants.ASSIGNMENTS_REPORT_LEAD_NAME_UPDATE.FAILURE]: (state, { error }) => {
    return { ...state, isLeadNameUpdating: false, leadNameError: error };
  },
};

// SAGA

export function* sagaUpdateLeadName({ leadId, name }) {
  try {
    // TODO roll it back if backend return an error
    yield put({
      type: constants.ASSIGNMENTS_REPORT_LEAD_NAME_UPDATE.SUCCESS,
      data: { leadId, name },
    });
    yield call(apiUpdateLeadName, leadId, name);
  } catch (e) {
    yield put({
      type: constants.ASSIGNMENTS_REPORT_LEAD_NAME_UPDATE.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchUpdateLeadName() {
  yield takeLatest(constants.ASSIGNMENTS_REPORT_LEAD_NAME_UPDATE.REQUEST, sagaUpdateLeadName);
}

// API

export function apiUpdateLeadName(leadId, name) {
  return request(`/assignments-report/leads/${leadId}`, {
    method: 'GET',
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchUpdateLeadName,
};
