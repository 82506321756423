import { useState } from 'react';
import { Flex, Badge, Drawer, Typography } from 'antd';
import { BellOutlined } from '@ant-design/icons';

import { useNotifications } from '@src/queries/Notification';

import Notifications from './index';
import { NewInfoTooltip } from '@ct-internal/ui';

const NotificationBell = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { data: notifications } = useNotifications({});

  const showDrawer = () => {
    setIsDrawerOpen(true);
  };

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <Flex className="notification-bell" onClick={showDrawer}>
        <Badge count={notifications?.count}>
          <BellOutlined />
        </Badge>
        <Flex style={{ height: 20, margin: '4px 0 0px -20px' }}>
          <NewInfoTooltip>
            New hire notifications. The number next to the bell, is the count of outstanding
            notifications you have
          </NewInfoTooltip>
        </Flex>
      </Flex>
      <Drawer
        className="notification-drawer"
        title={
          <Flex align="center" justify="space-between">
            <Typography.Text>Action Requests</Typography.Text>
            <Badge count={notifications?.count} />
          </Flex>
        }
        placement="right"
        onClose={onClose}
        open={isDrawerOpen}
        forceRender={true}
        width={500}
      >
        <Notifications showDrawer={showDrawer} />
      </Drawer>
    </>
  );
};

export default NotificationBell;
