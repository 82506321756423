import request from '../../shared/request';

// JOB TYPES
export function getJobTypes() {
  return request('/job-types', {
    method: 'GET',
  });
}

export function createJobType({ jobType }) {
  return request('/job-types', {
    method: 'POST',
    body: {
      ...jobType,
    },
  });
}

export function updateJobType({ _id, jobType }) {
  return request(`/job-types/${_id}`, {
    method: 'PUT',
    body: {
      ...jobType,
    },
  });
}

export function deleteJobType({ _id }) {
  return request(`/job-types/${_id}`, {
    method: 'DELETE',
  });
}

export function sortJobTypes({ jobTypesIds }) {
  return request('/job-types/sort', {
    method: 'POST',
    body: {
      jobTypesIds: jobTypesIds || [],
    },
  });
}
