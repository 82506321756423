import React from 'react';
import { message } from 'antd';
import PropTypes from 'prop-types';

import { useGoogleLogin } from '@react-oauth/google';

const googleLoginStyle = {
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  padding: 0,
};

const propTypes = {
  location: PropTypes.object,
  user: PropTypes.object,
  fingerprint: PropTypes.string,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  isSignedIn: PropTypes.bool,
};

const LoginButton = (props) => {
  const successOauth = (response) => {
    const { fingerprint, location } = props;
    const { accessToken, code } = response;

    props.login({
      accessToken,
      fingerprint,
      location,
      code,
    });
  };

  const failOauth = (response) => {
    message.error("Can't sign in to your Google Account");
  };

  const loginWithGoogle = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: successOauth,
    onFailure: failOauth,
    scope:
      'profile email https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/gmail.readonly',
  });

  const logout = () => {
    props.logout();
  };

  // render
  const { user } = props;
  return user ? (
    <a href="#/" onClick={logout}>
      Logout
    </a>
  ) : (
    <button
      onClick={loginWithGoogle}
      style={
        props.style
          ? { ...props.style, color: '#460b38' }
          : { ...googleLoginStyle, color: '#460b38' }
      }
    >
      Login
    </button>
  );
};

LoginButton.propTypes = propTypes;
export default LoginButton;
