import { z } from 'zod';
import { BaseSchemaDW } from '../structure';

export const JobDWStatusEnum = ['Active', 'Passive', 'Closed', 'Archived', 'Evergreen'] as const;
export type JobDWStatusValue = (typeof JobDWStatusEnum)[number];
export enum JobStatus {
  'Active' = 1,
  'Passive' = 2,
  'Closed' = 3,
  'Archived' = 4,
  'Evergreen' = 5,
}

export const JobSchema = z
  .object({
    job_id: z.number().nullable(),
    mongo_job_id: z.string(),
    job_type_id: z.string(),
    job_name: z.string(),
    job_short_name: z.string(),
    job_status: z.enum(JobDWStatusEnum),
    description: z.string(),
    is_employee: z.boolean().default(false),
    lead_experience: z.boolean().default(false),
    salary_range: z.any(),
    seniority_from: z.number().default(2),
    seniority_to: z.number().optional(),
    english_level_from: z.number().default(2),
    english_level_to: z.number().optional(),
    requires_voice_note: z.boolean(),
  })
  .merge(BaseSchemaDW);
export type JobDW = z.infer<typeof JobSchema>;

export const JobMongoSchema = z.object({
  _id: z.string(),
  slug: z.string(),
  name: z.string(),
  shortName: z.string(),
  description: z.string(),
  sort: z.number().default(99),
  hiring: z.nativeEnum(JobStatus).default(JobStatus.Active),
  owner: z.string(),
  type: z.string(),
  department: z.string().optional(),
  salaryFromValue: z.number().optional(),
  salaryToValue: z.number().optional(),
  priority: z.string().optional(),
  employee: z.boolean().optional(),
  requiresVoiceNote: z.boolean().nullish(),
  created_at: z.date(),
  updated_at: z.date(),
  deleted_at: z.date().optional(),
  created_by: z.string().optional(),
  updated_by: z.string().optional(),
  deleted_by: z.string().optional(),
  skills: z.array(z.string()),
});
export type JobMongoDW = z.infer<typeof JobMongoSchema>;
