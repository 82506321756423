import { Endpoint, Method } from '../../../infrastructure';
import { InterviewCalendarEventSchema } from '../base';
import { z } from 'zod';

export const ICESwitchRecruiterRequestSchema = InterviewCalendarEventSchema.pick({
  dt_start: true,
  dt_end: true,
})
  .extend({
    recruiter_email: z.string(),
  })
  .partial({
    dt_end: true,
    dt_start: true,
  });

export type ICESwitchRecruiterRequest = z.infer<typeof ICESwitchRecruiterRequestSchema>;

export const ICESwitchRecruiterParamsSchema = z.object({
  interview_calendar_event_id: z.coerce.number(),
});
export type ICESwitchRecruiterParams = z.infer<typeof ICESwitchRecruiterParamsSchema>;

export type ICESwitchRecruiterResponse = {};

export type ICESwitchRecruiterEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/interview-calendar-events/${ICESwitchRecruiterParams['interview_calendar_event_id']}/switch-recruiter`,
  ICESwitchRecruiterResponse,
  ICESwitchRecruiterParams,
  undefined,
  ICESwitchRecruiterRequest
>;
