import { call, put, takeEvery } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';
import isFunction from 'lodash/isFunction';

// MODULE

const constants = {
  COMMENT_DELETE: createSagaAction('COMMENT_DELETE'),
};

const actions = {
  remove: (data) => ({
    ...data,
    type: constants.COMMENT_DELETE.REQUEST,
  }),
};

const handlers = {
  [constants.COMMENT_DELETE.REQUEST]: (state, action) => {
    return { ...state, isLoading: true, data: [] };
  },
  [constants.COMMENT_DELETE.SUCCESS]: (state, { data }) => {
    return { ...state, isLoading: false, data };
  },
  [constants.COMMENT_DELETE.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

function* sagaRemoveComment(action) {
  try {
    const data = yield call(apiRemoveComment, action);
    yield put({ type: constants.COMMENT_DELETE.SUCCESS, data });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.COMMENT_DELETE.FAILURE,
      error: e.message || e,
    });

    if (isFunction(action.onFailure)) {
      yield action.onFailure(e.message || e);
    }
  }
}

function* watchRemoveComment() {
  yield takeEvery(constants.COMMENT_DELETE.REQUEST, sagaRemoveComment);
}

// API

function apiRemoveComment({ applicationId, commentId }) {
  return request(`/applications/${applicationId}/comments/${commentId}`, {
    method: 'DELETE',
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchRemoveComment,

  // optionally we may
  // api: {
  //   removeComment: apiRemoveComment
  // }
};
