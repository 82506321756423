import { z } from 'zod';
import { Endpoint, Method, dateString } from '../../infrastructure';
import { Lead, LeadSchema } from './base';

export const LeadSetStartDateRequestSchema = z.object({
  dtplannedstart: dateString,
});
export type LeadSetStartDateRequest = z.infer<typeof LeadSetStartDateRequestSchema>;

export const LeadSetStartDateParamsSchema = LeadSchema.pick({
  project_id: true,
});
export type LeadSetStartDateParams = z.infer<typeof LeadSetStartDateParamsSchema>;

export type LeadSetStartDateResponse = Lead;

export type LeadSetStartDateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/sql-leads/${LeadSetStartDateParams['project_id']}/set-start-date`,
  LeadSetStartDateResponse,
  LeadSetStartDateParams,
  undefined,
  LeadSetStartDateRequest
>;
