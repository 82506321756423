import { z } from 'zod';
import { BaseSchemaDW } from '../structure';
import { SkillGroupMongoDWSchema } from './SkillGroup';

export enum RecruitingMode {
  'OC' = 'Occasionally Recruiting',
  'AR' = 'Always Recruiting',
}

export const SkillDWSchema = z
  .object({
    skill_id: z.number().nullable(),
    mongo_skill_id: z.string(),
    name: z.string(),
    description: z.string().nullable(),
    recruiting_mode: z.nativeEnum(RecruitingMode).nullable(),
  })
  .merge(BaseSchemaDW);
export type SkillDW = z.infer<typeof SkillDWSchema>;

export const SkillMongoDWSchema = z.object({
  _id: z.string(),
  skill: z.string(),
  recruitingMode: z.nativeEnum(RecruitingMode).nullish(),
  description: z.string().nullish(),
  order: z.string().nullish(),
  group: SkillGroupMongoDWSchema.nullish(),
  groups: z.array(SkillGroupMongoDWSchema).nullish(),
  oldGroup: z.string().nullish(),
  internalSourcing: z.string().nullish(),
  created_at: z.string(),
  updated_at: z.string(),
  deletedAt: z.string().nullish(),
  deletedBy: z.string().nullish(),
});
export type SkillMongoDW = z.infer<typeof SkillMongoDWSchema>;
