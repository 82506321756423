import request from '../../shared/request';
import qs from 'query-string';

export function get(options) {
  const query = qs.stringify(options);
  return request(`/todos?${query}`, {
    method: 'GET',
  });
}
export function create({ applicationId, todo }) {
  return request(`/applications/${applicationId}/todos`, {
    method: 'POST',
    body: {
      ...todo,
    },
  });
}

export function update({ applicationId, todo }) {
  return request(`/applications/${applicationId}/todos/${todo._id}`, {
    method: 'PUT',
    body: {
      ...todo,
    },
  });
}

export function remove({ applicationId, todoId }) {
  return request(`/applications/${applicationId}/comments/${todoId}`, {
    method: 'DELETE',
  });
}
