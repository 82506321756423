import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';
import { setActions } from '@redux/modules/_utils';

import map from 'lodash/map';
import values from 'lodash/values';
import zipObject from 'lodash/zipObject';
import mapValues from 'lodash/mapValues';
import defaults from 'lodash/defaults';

export const ACTION_MAP = {
  getDailyFunnelReport: {
    key: 'DAILY_FUNNEL_REPORT_GET',
    opts: { successKey: 'data' },
  },
};

// Action Creators
const _keys = map(values(ACTION_MAP), 'key');

const _values = map(_keys, (key) => createSagaAction(key));

// Constants
export const constants = zipObject(_keys, _values);

// Action Creators
export const actions = mapValues(ACTION_MAP, (o) => {
  return (data) => defaults({ type: constants[o.key].REQUEST }, data);
});

// Reducer
export const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

const actionsDefinitions = values(ACTION_MAP);
const ACTION_HANDLERS = setActions(actionsDefinitions, constants);

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
