import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';
import isFunction from 'lodash/isFunction';
import findIndex from 'lodash/findIndex';

// MODULE
const constants = {
  UPDATE_UTM_PARAMS: createSagaAction('UPDATE_UTM_PARAMS'),
};

const actions = {
  update: (data) => ({
    type: constants.UPDATE_UTM_PARAMS.REQUEST,
    ...data,
  }),
};

const handlers = {
  [constants.UPDATE_UTM_PARAMS.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.UPDATE_UTM_PARAMS.SUCCESS]: (state, { utmParam }) => {
    // replace the old utmParam with the new one
    const index = findIndex(state.data, (item) => item._id === utmParam.id);

    const newStateData = [...state.data.slice(0, index), utmParam, ...state.data.slice(index + 1)];

    return { ...state, isLoading: false, data: newStateData };
  },
  [constants.UPDATE_UTM_PARAMS.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA
function* sagaUpdateUtmParams(action) {
  try {
    const { utmParam } = yield call(apiUpdateUtmParams, action);
    yield put({ type: constants.UPDATE_UTM_PARAMS.SUCCESS, utmParam });
  } catch (e) {
    yield put({
      type: constants.UPDATE_UTM_PARAMS.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onError)) {
      yield action.onError(e.message || e);
    }
  }
}

function* watchUpdateUtmParams() {
  yield takeLatest(constants.UPDATE_UTM_PARAMS.REQUEST, sagaUpdateUtmParams);
}

// API
function apiUpdateUtmParams(utm_param) {
  return request(`/utm-param/${utm_param.id}`, {
    method: 'PUT',
    body: {
      ...utm_param,
    },
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchUpdateUtmParams,
};
