import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';
import isFunction from 'lodash/isFunction';

// MODULE

const constants = {
  CALENDLY_URL_GET: createSagaAction('CALENDLY_URL_GET'),
};

const actions = {
  getCalendlyUrl: (data) => ({
    ...data,
    type: constants.CALENDLY_URL_GET.REQUEST,
  }),
};

const handlers = {
  [constants.CALENDLY_URL_GET.REQUEST]: (state, action) => {
    return { ...state, isLoading: true, applicationCalendlyUrl: null };
  },
  [constants.CALENDLY_URL_GET.SUCCESS]: (state, { calendlyUrl }) => {
    return { ...state, isLoading: false, applicationCalendlyUrl: calendlyUrl };
  },
  [constants.CALENDLY_URL_GET.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

function* sagaGetCalendlyUrl(action) {
  try {
    const { quickBookInterview } = yield call(apiGetCalendlyUrl, action);
    yield put({
      type: constants.CALENDLY_URL_GET.SUCCESS,
      calendlyUrl: quickBookInterview.url,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(quickBookInterview.url);
    }
  } catch (e) {
    yield put({
      type: constants.CALENDLY_URL_GET.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetCalendlyUrl() {
  yield takeLatest(constants.CALENDLY_URL_GET.REQUEST, sagaGetCalendlyUrl);
}

// API
export function apiGetCalendlyUrl({ applicationId, calendlyId, calendlyUser, eventTypeId }) {
  return request(`/applications/${applicationId}/calendly-url`, {
    method: 'GET',
    qs: {
      calendlyId,
      calendlyUser,
      eventTypeId,
    },
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchGetCalendlyUrl,

  // optionally we may
  // api: {
  //   getCalendlyUrl: apiGetCalendlyUrl
  // }
};
