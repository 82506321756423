import { call, put, takeLatest, cancel, fork } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE
const constants = {
  SEARCH_GET_MONGO: createSagaAction('SEARCH_GET_MONGO'),
};

const actions = {
  mongoSearch: (data) => ({
    ...data,
    type: constants.SEARCH_GET_MONGO.REQUEST,
  }),
};

const handlers = {
  [constants.SEARCH_GET_MONGO.REQUEST]: (state, action) => {
    return { ...state, isLoadingSearch: true };
  },
  [constants.SEARCH_GET_MONGO.SUCCESS]: (state, action) => {
    return { ...state, isLoadingSearch: false, results: action.results };
  },
  [constants.SEARCH_GET_MONGO.FAILURE]: (state, { error }) => {
    return { ...state, isLoadingSearch: false, error };
  },
};

// SAGA
function* watchGetResultsMongo() {
  let task;
  let abortController;

  function* getResultsMongo(action) {
    try {
      const response = yield call(apiGetApplicaitonsMongo, {
        ...action,
        signal: abortController.signal,
      });
      yield put({
        type: constants.SEARCH_GET_MONGO.SUCCESS,
        results: response,
      });

      if (isFunction(action.onSuccess)) {
        yield action.onSuccess();
      }
    } catch (e) {
      if (e.name === 'AbortError') {
        return;
      }
      yield put({
        type: constants.SEARCH_GET_MONGO.FAILURE,
        error: e.message || e,
      });
    }
  }

  function* cancelGetResultsMongo() {
    if (task) {
      yield cancel(task);
      if (abortController) {
        abortController.abort();
      }
    }
  }

  function* searchFlow(action) {
    yield call(cancelGetResultsMongo);
    abortController = new AbortController();
    task = yield fork(getResultsMongo, action);
  }

  yield takeLatest(constants.SEARCH_GET_MONGO.REQUEST, searchFlow);
}

// API
export function apiGetApplicaitonsMongo({ urlParams, signal }) {
  return request(
    `/applications/mongo?${urlParams}`,
    {
      method: 'GET',
      encode: true,
    },
    signal,
  );
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchGetResultsMongo,
};
