import { CommentSchema } from '../base';
import { z } from 'zod';

export const CommentCreateSchema = CommentSchema.pick({
  author_id: true,
  comment: true,
  mongo_id: true,
})
  .partial({
    mongo_id: true,
  })
  .extend({
    comment_tags: z.string().array().nullish(),
  });
export type CommentCreate = z.infer<typeof CommentCreateSchema>;
