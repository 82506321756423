import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  ROLES_GET_ALL: createSagaAction('ROLES_GET_ALL'),
  ROLES_ADD_EMAIL: createSagaAction('ROLES_ADD_EMAIL'),
  ROLES_REMOVE_EMAIL: createSagaAction('ROLES_REMOVE_EMAIL'),
};

// Action Creators
export const actions = {
  getAll: () => ({
    type: constants.ROLES_GET_ALL.REQUEST,
  }),
  addEmail: (args) => ({
    type: constants.ROLES_ADD_EMAIL.REQUEST,
    ...args,
  }),
  removeEmail: (args) => ({
    type: constants.ROLES_REMOVE_EMAIL.REQUEST,
    ...args,
  }),
};

// Reducer
export const initialState = {
  roles: [],
  refetch: false,
  isLoading: false,
  error: null,
};

const ACTION_HANDLERS = {
  // ALL
  [constants.ROLES_GET_ALL.REQUEST]: (state) => {
    return { ...state, error: null, isLoading: true };
  },
  [constants.ROLES_GET_ALL.SUCCESS]: (state, { roles }) => {
    return { ...state, roles, refetch: false, error: null, isLoading: false };
  },
  [constants.ROLES_GET_ALL.FAILURE]: (state, { error }) => {
    return { ...state, error, isLoading: false };
  },
  // ADD EMAIL
  [constants.ROLES_ADD_EMAIL.REQUEST]: (state) => {
    return { ...state, error: null, isLoading: true };
  },
  [constants.ROLES_ADD_EMAIL.SUCCESS]: (state) => {
    return { ...state, refetch: true, error: null, isLoading: false };
  },
  [constants.ROLES_ADD_EMAIL.FAILURE]: (state, { error }) => {
    return { ...state, error, isLoading: false };
  },
  // REMOVE EMAIL
  [constants.ROLES_REMOVE_EMAIL.REQUEST]: (state) => {
    return { ...state, error: null, isLoading: true };
  },
  [constants.ROLES_REMOVE_EMAIL.SUCCESS]: (state) => {
    return { ...state, refetch: true, error: null, isLoading: false };
  },
  [constants.ROLES_REMOVE_EMAIL.FAILURE]: (state, { error }) => {
    return { ...state, error, isLoading: false };
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
