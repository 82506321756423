import { z } from 'zod';
import { ProjectSchema } from './base';
import { Endpoint, Method } from '../../infrastructure';

export const ProjectGeneralInfoItemSchema = ProjectSchema.pick({
  project_id: true,
  client_id: true,
  isactive: true,
  name: true,
  dtsigned: true,
  dtplannedstart: true,
  dtplannedend: true,
  dtcreate: true,
  dtupdate: true,
  alternate_names: true,
  project_serial_id: true,
  end_date_certainty: true,
  plan_revenue_comment: true,
  plan_revenue_comment_dtupdate: true,
  drive_folder_id: true,
  short_name: true,
  owner_id: true,
  sf_stage: true,
  manage_team: true,
  equipment_needed: true,
  signed_contract_url: true,
  dt_opp_extension: true,
  project_sf_id: true,
}).extend({
  client_name: z.string(),
  freshbook_id: z.string(),
  owner_name: z.string(),
});
export type ProjectGeneralInfoItem = z.infer<typeof ProjectGeneralInfoItemSchema>;

export const ProjectGeneralInfoParamsSchema = z.object({
  project_id: z.string(),
});

export type ProjectGeneralInfoParams = z.infer<typeof ProjectGeneralInfoParamsSchema>;

export type ProjectGeneralInfoResponse = ProjectGeneralInfoItem;

export type ProjectGeneralInfoEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sql-projects/${ProjectGeneralInfoParams['project_id']}/general-info`,
  ProjectGeneralInfoResponse,
  ProjectGeneralInfoParams,
  undefined,
  undefined
>;
