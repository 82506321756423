import { z } from 'zod';
import { ForecastVersion } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const ForecastVersionDuplicateRequestSchema = z.object({
  new_name: z.string(),
});
export type ForecastVersionDuplicateRequest = z.infer<typeof ForecastVersionDuplicateRequestSchema>;

export const ForecastVersionDuplicateParamsSchema = z.object({
  version_id: z.coerce.number(),
});
export type ForecastVersionDuplicateParams = z.infer<typeof ForecastVersionDuplicateParamsSchema>;

export type ForecastVersionDuplicateResponse = ForecastVersion;

export type ForecastVersionDuplicateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/forecasts-version/${ForecastVersionDuplicateParams['version_id']}/duplicate`,
  ForecastVersionDuplicateResponse,
  ForecastVersionDuplicateParams,
  undefined,
  ForecastVersionDuplicateRequest
>;
