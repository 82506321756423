import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';

//Revenue data
export const InvoiceAdjustmentSchema = z.object({
  acct_ymonth: z.number(),
  name: z.string(),
  ctgr_code: z.string(),
  line_text: z.string(),
  amount: z.number(),
  invoice_num: z.number(),
  client_name: z.string(),
});
export type InvoiceAdjustment = z.infer<typeof InvoiceAdjustmentSchema>;

//Query
export const InvoiceAdjustmentQuerySchema = z
  .object({
    ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
  })
  .partial();
export type InvoiceAdjustmentQuery = z.infer<typeof InvoiceAdjustmentQuerySchema>;

//Define the response
export type InvoiceAdjustmentResponse = { rows: readonly InvoiceAdjustment[] };

//Define the endpoint
export type InvoiceAdjustmentEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/invoice-adjustment/',
  InvoiceAdjustmentResponse,
  undefined,
  InvoiceAdjustmentQuery,
  undefined
>;
