import * as api from '@redux/api/application';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';

// GET INTERVIEW LIST

function* getAppliedList(action) {
  try {
    const appliedList = yield call(api.getAppliedList, action);
    yield put({ type: constants.APPLICATION_GET_APPLIED.SUCCESS, appliedList });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_GET_APPLIED.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetAppliedList() {
  yield takeLatest(constants.APPLICATION_GET_APPLIED.REQUEST, getAppliedList);
}
