import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const RemovePositionExtensionParamsSchema = z.object({
  position_id: z.string(),
});
export type RemovePositionExtensionParams = z.infer<typeof RemovePositionExtensionParamsSchema>;

export type RemovePositionExtensionEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/positions/opp-extension/${RemovePositionExtensionParams['position_id']}`,
  void,
  RemovePositionExtensionParams,
  undefined,
  undefined
>;
