import { z } from 'zod';
import { TimeOffRequestSchema } from '../base';
import {
  Endpoint,
  FilterOperations,
  Method,
  dateString,
  makeSorter,
} from '../../../infrastructure';

export const TORReportItemProjectSchema = z.object({
  project_id: z.string(),
  islead: z.boolean(),
  name: z.string(),
});
export type TORReportItemProject = z.infer<typeof TORReportItemProjectSchema>;

export const TORReportItemSchema = TimeOffRequestSchema.pick({
  time_off_request_id: true,
  person_id: true,
  is_flexible: true,
  is_from_hire: true,
  is_paid: true,
  last_year_days: true,
  mongo_id: true,
  status: true,
  working_days: true,
  category: true,
  date_range: true,
  exceptional_not_counted: true,

  approved_by: true,
  dt_approved: true,
  declined_by: true,
  dt_declined: true,

  dt_created: true,
  dt_updated: true,
}).extend({
  person_name: z.string().nullable(),
  decliner_name: z.string().nullable(),
  approver_name: z.string().nullable(),
  dt_start: z.string().nullable(),
  dt_end: z.string().nullable(),
  projects: z.array(TORReportItemProjectSchema).nullable(),
  pto_balance: z.number().nullable(),
});

export type TORReportItem = z.infer<typeof TORReportItemSchema>;

export const TORReportFiltersSchema = z.object({
  user_id: z.string(),
  dt_start: z
    .record(
      z.enum([
        FilterOperations.EQUAL,
        FilterOperations.GREATER_THAN_OR_EQUAL,
        FilterOperations.LESS_THAN_OR_EQUAL,
      ]),
      dateString,
    )
    .optional(),
  dt_end: z
    .record(
      z.enum([
        FilterOperations.EQUAL,
        FilterOperations.GREATER_THAN_OR_EQUAL,
        FilterOperations.LESS_THAN_OR_EQUAL,
      ]),
      dateString,
    )
    .optional(),
  dt_created: z
    .record(
      z.enum([
        FilterOperations.EQUAL,
        FilterOperations.GREATER_THAN_OR_EQUAL,
        FilterOperations.LESS_THAN_OR_EQUAL,
      ]),
      dateString,
    )
    .optional(),
  projects: z.record(z.enum([FilterOperations.IN]), z.string().array()).optional(),
  status: z.record(z.enum([FilterOperations.IN]), z.string().array()).optional(),
});
export type TORReportFilters = z.infer<typeof TORReportFiltersSchema>;

export const TORReportSorterSchema = makeSorter(
  z.enum(['person_name', 'dt_created', 'dt_start', 'status', 'working_days']),
);
export type TORReportSorter = z.infer<typeof TORReportSorterSchema>;

export const TORReportQuerySchema = z.object({
  filters: TORReportFiltersSchema,
  sorter: TORReportSorterSchema.optional(),
});
export type TORReportQuery = z.infer<typeof TORReportQuerySchema>;

export type TORReportResponse = TORReportItem[];

export type TORReportEndpoint = Endpoint<
  Method.GET,
  `/api/v2/time-off-requests/tor-report`,
  TORReportResponse,
  undefined,
  TORReportQuery,
  undefined
>;
