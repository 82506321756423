import { call, put, takeLatest } from 'redux-saga/effects';

import { constants } from '@redux/modules/dashboard/dashboard';
import * as api from '@redux/api/dashboard';

// GET HIRED
function* getHired(action) {
  try {
    const response = yield call(api.getHired, action);

    yield put({
      type: constants.HIRED_GET.SUCCESS,
      hired: response.data,
    });
  } catch (e) {
    yield put({
      type: constants.HIRED_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetHired() {
  yield takeLatest(constants.HIRED_GET.REQUEST, getHired);
}
