import { z } from 'zod';
import { PositionPostgresSchema } from './base';
import { Endpoint, Method } from '../../infrastructure';

export type FindPositionByIdEndpoint = Endpoint<
  Method.GET,
  `/api/v2/positions/:${FindPositionByIdParams['position_id']}`,
  FindPositionByIdResponse,
  FindPositionByIdParams,
  undefined,
  undefined
>;

export const FindPositionByIdResponseSchema = z.object({
  position: PositionPostgresSchema,
});

export type FindPositionByIdResponse = z.infer<typeof FindPositionByIdResponseSchema>;

export const FindPositionByIdParamsSchema = z.object({
  position_id: z.string(),
});

export type FindPositionByIdParams = z.infer<typeof FindPositionByIdParamsSchema>;
