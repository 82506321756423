import { z } from 'zod';
import { TimeOffRequestCommentSchema } from '../base';

export const TimeOffRequestCommentCreateSchema = TimeOffRequestCommentSchema.pick({
  author_id: true,
  comment: true,
  time_off_request_id: true,
}).extend({
  send_slack_message: z.boolean().nullish(),
});
export type TimeOffRequestCommentCreate = z.infer<typeof TimeOffRequestCommentCreateSchema>;
