import request from '@shared/request';

export function getAllCalendlyUsers() {
  return request('/calendly-users', {
    method: 'GET',
  });
}

export function getCalendlyUser({ id }) {
  return request(`/calendly-users/${id}`, {
    method: 'GET',
  });
}

export function getCalendlyUserByUserId({ userId }) {
  return request(`/users/${userId}/calendly-user`, {
    method: 'GET',
  });
}

export function deleteCalendlyUser({ id }) {
  return request(`/calendly-users/${id}`, {
    method: 'DELETE',
  });
}

export function save({ id, data }) {
  return request(`/calendly-users${id ? `/${id}` : ''}`, {
    method: id ? 'PUT' : 'POST',
    body: data,
  });
}

export function getTypes() {
  return request('/calendly-user-types', {
    method: 'GET',
  });
}

export function getCategories() {
  return request('/calendly-users-categories', {
    method: 'GET',
  });
}

export function getEmailTemplates() {
  return request('/calendly-users-email-templates', {
    method: 'GET',
  });
}

export function updateEventType({ calendlyUserId, eventTypeId, data }) {
  return request(`/calendly-users/${calendlyUserId}/event-types/${eventTypeId}`, {
    method: 'PUT',
    body: data,
  });
}
