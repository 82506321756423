import { z } from 'zod';
import {
  CreateMessageRequestSchema,
  Message,
  PositionStaffingMessage,
  PositionStaffingMessageSchema,
} from './';
import { Endpoint, Method } from '../../infrastructure';

export const CreatePositionStaffingMessageRequestSchema = PositionStaffingMessageSchema.pick({
  position_staffing_id: true,
}).merge(CreateMessageRequestSchema);

export type CreatePositionStaffingMessageRequest = z.infer<
  typeof CreatePositionStaffingMessageRequestSchema
>;

export type CreatePositionStaffingMessageResponse = Message & PositionStaffingMessage;

export type CreatePositionStaffingMessageEndpoint = Endpoint<
  Method.POST,
  '/api/v3/messages/position-staffing',
  CreatePositionStaffingMessageResponse,
  undefined,
  undefined,
  CreatePositionStaffingMessageRequest
>;
