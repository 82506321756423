import { all, fork } from 'redux-saga/effects';

import { rootSaga as authentication } from './authentication';
import { rootSaga as assignmentReport } from './assignments-report/index';
import { rootSaga as dashboard } from './dashboard/index';
import { rootSaga as application } from './application/index';
import { rootSaga as applicationUser } from './application/user/index';
import { rootSaga as messages } from './messages/index';
import { rootSaga as metrics } from './metrics/index';

import { rootSaga as emails } from './emails';
import { rootSaga as user } from './user';
import { rootSaga as jobTypes } from './job-types';
import { rootSaga as jobTitles } from './job-titles';
import { rootSaga as jobTextQuestions } from './job-text-questions';
import { rootSaga as questions } from './questions';
import { rootSaga as rejectionReasons } from './rejection-reasons';
import { rootSaga as scorecardQuestions } from './scorecard-questions';
import { rootSaga as sources } from './sources';
import { rootSaga as todos } from './todos';
import { rootSaga as candidate } from './application/candidate';

import { rootSaga as sourcesReport } from './reports/sources-report';
import { rootSaga as actionsReport } from './reports/actions-report';
import { rootSaga as dailyFunnelReport } from './reports/daily-funnel-report';
import { rootSaga as testReminderCampaign } from './reports/test-reminder-report';
import { rootSaga as inboundEmailErrors } from './reports/inbound-email-errors';
import { rootSaga as scheduledEmails } from './reports/scheduled-emails';

import { rootSaga as rejectedCandidatesReport } from './reports/statuses-report';
import { rootSaga as assignmentTracingReport } from './reports/assignment-tracing-report';
import { rootSaga as applicationsReport } from './reports/applications';
import { rootSaga as applicationsProgressReport } from './reports/application-progress';
import { rootSaga as onDeckReport } from './reports/on-deck-report';
import { rootSaga as focusReport } from './reports/focus-report';

import { rootSaga as featureFlags } from './feature-flags';

import { rootSaga as calendlyUsers } from './calendly-users';
import { rootSaga as calendlyUserTypes } from './calendly-user-types';

import { rootSaga as applicationDripCampaigns } from './application-drip-campaigns';

import { rootSaga as roles } from './roles';

import { rootSaga as skills } from './skills';
import { rootSaga as mediums } from './mediums';
// refactored
import { rootSaga as account } from '@redux/account';
import { rootSaga as jobPriorities } from '@redux/job-priorities';
import { rootSaga as jobs } from '@redux/jobs';
import { rootSaga as search } from '@redux/search';
import { rootSaga as assignmentsReportV2 } from '@redux/assignments-report';
import { rootSaga as applicationV2 } from '@redux/application';
import { rootSaga as interviewsReport } from '@redux/interviews-report';
import { rootSaga as comments } from '@redux/comments';
import { rootSaga as unresponsiveReport } from '@redux/reports/unresponsive-application';
import { rootSaga as leads } from '@redux/leads';
import { rootSaga as utmParams } from '@redux/utm-params';

function* rootSaga() {
  const sagas = [
    authentication,
    assignmentReport,
    dashboard,
    comments,
    search,
    user,
    emails,
    jobs,
    jobTypes,
    jobPriorities,
    jobTitles,
    questions,
    messages,
    metrics,
    application,
    applicationUser,
    applicationV2,
    sourcesReport,
    actionsReport,
    sources,
    interviewsReport,
    testReminderCampaign,
    dailyFunnelReport,
    todos,
    scorecardQuestions,
    account,
    inboundEmailErrors,
    scheduledEmails,
    rejectionReasons,
    rejectedCandidatesReport,
    assignmentTracingReport,
    candidate,
    applicationsReport,
    applicationsProgressReport,
    featureFlags,
    jobTextQuestions,
    calendlyUsers,
    calendlyUserTypes,
    applicationDripCampaigns,
    roles,
    assignmentsReportV2,
    leads,
    unresponsiveReport,
    skills,
    utmParams,
    onDeckReport,
    focusReport,
    mediums,
  ].map(fork);

  yield all(sagas);
}

export default rootSaga;
