import request from '../../shared/request';
import qs from 'query-string';

export function getSources({ countries, from, to }) {
  const query = qs.stringify({
    countries,
    from: from.format('YYYY-MM-DD'),
    to: to.format('YYYY-MM-DD'),
  });

  return request(`/reports/sources?${query}`, {
    method: 'GET',
  });
}

export function getAdvancedSourcingReport({
  countries,
  from,
  to,
  jobs,
  sources,
  groupBy,
  statuses,
  timeResolution,
}) {
  const query = qs.stringify({
    from,
    to,
    jobs,
    countries,
    sources,
    groupBy,
    statuses,
    timeResolution,
  });

  return request(`/reports/sources/advanced?${query}`, {
    method: 'GET',
  });
}
