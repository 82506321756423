import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const ScheduledEmailSchema = z.object({
  scheduled_email_id: z.number(),
  application_id: z.string(),
  scheduled_email_mongo_id: z.string(),
  email_template_id: z.number(),
  dt_created: z.union([z.number(), dateString]).nullable(),
  dt_scheduled: z.union([z.number(), dateString]),
  is_sent: z.boolean().default(false),
  is_rejection: z.boolean().default(false),
  is_cancelled: z.boolean().default(false),
});

export const ScheduledEmailMongoSchema = z.object({
  _id: z.string(),
  application: z.string(),
  slug: z.string(),
  created_at: z.date().nullish(),
  updated: z.date().nullish(),
  when: z.date(),
  sent: z.boolean().default(false),
  cancelled: z.boolean().default(false),
});

export type ScheduledEmail = z.infer<typeof ScheduledEmailSchema>;
export type ScheduledEmailMongo = z.infer<typeof ScheduledEmailMongoSchema>;
