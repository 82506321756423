import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE
const constants = {
  GET_ACTIVE_TECH_JOBS: createSagaAction('GET_ACTIVE_TECH_JOBS'),
};

const actions = {
  getActiveTechJobs: (args) => ({
    type: constants.GET_ACTIVE_TECH_JOBS.REQUEST,
    ...args,
  }),
};

const handlers = {
  [constants.GET_ACTIVE_TECH_JOBS.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.GET_ACTIVE_TECH_JOBS.SUCCESS]: (state, { techJobs }) => {
    return { ...state, isLoading: false, techJobs };
  },
  [constants.GET_ACTIVE_TECH_JOBS.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

// SAGA
function* getActiveTechJobs(action) {
  try {
    const techJobs = yield call(apiGetActiveTechJobs, action);

    yield put({ type: constants.GET_ACTIVE_TECH_JOBS.SUCCESS, techJobs });
  } catch (error) {
    yield put({
      type: constants.GET_ACTIVE_TECH_JOBS.FAILURE,
      error: error.message || error,
    });
  }
}

function* watchGetAllJobs() {
  yield takeLatest(constants.GET_ACTIVE_TECH_JOBS.REQUEST, getActiveTechJobs);
}

// API
export function apiGetActiveTechJobs() {
  return request('/jobs/tech', {
    method: 'GET',
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchGetAllJobs,
};
