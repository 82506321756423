import { z } from 'zod';
import { TimeOffRequest } from '../base';
import { Endpoint, Method } from '../../../infrastructure';
import { TimeOffRequestApproveSchema } from '../crud';

export const TimeOffRequestApproveRequestSchema = TimeOffRequestApproveSchema.pick({
  approved_by: true,
});
export type TimeOffRequestApproveRequest = z.infer<typeof TimeOffRequestApproveRequestSchema>;

export const TimeOffRequestApproveParamsSchema = z.object({
  time_off_request_id: z.coerce.number(),
});
export type TimeOffRequestApproveParams = z.infer<typeof TimeOffRequestApproveParamsSchema>;

export type TimeOffRequestApproveResponse = TimeOffRequest;

export type TimeOffRequestApproveEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/time-off-requests/${TimeOffRequestApproveParams['time_off_request_id']}/approve`,
  TimeOffRequestApproveResponse,
  TimeOffRequestApproveParams,
  undefined,
  TimeOffRequestApproveRequest
>;
