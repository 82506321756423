import { z } from 'zod';
import { PositionTemplateSchema } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const PositionTemplateListQuerySchema = PositionTemplateSchema.pick({
  english_level: true,
  name: true,
  position_name: true,
  revenue_amount: true,
  seniority: true,
  target_cost: true,
}).partial();
export type PositionTemplateListQuery = z.infer<typeof PositionTemplateListQuerySchema>;

export const PositionTemplateListResponseSchema = PositionTemplateSchema.extend({
  skills_id: z.array(z.number()).nullable(),
  skills_name: z.array(z.string()).nullable(),
});
export type PositionTemplateListResponse = z.infer<typeof PositionTemplateListResponseSchema>[];

export type PositionTemplateListEndpoint = Endpoint<
  Method.GET,
  `/api/v2/position-templates/`,
  PositionTemplateListResponse,
  undefined,
  PositionTemplateListQuery,
  undefined
>;
