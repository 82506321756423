import { z } from 'zod';
import { Endpoint, Method, FilterOperations } from '../../infrastructure';
import { PaymentsTotalSummarySchema } from './base';

export type GetPaymentsTotalSummaryEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/payments/total-summary',
  GetPaymentsTotalSummaryResponse,
  undefined,
  GetPaymentsTotalSummaryQuery,
  undefined
>;

export const GetPaymentsTotalSummaryQuerySchema = z.object({
  filters: z
    .object({
      ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
      payment_provider_id: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
    })
    .partial()
    .optional(),
});
export type GetPaymentsTotalSummaryQuery = z.infer<typeof GetPaymentsTotalSummaryQuerySchema>;

export const GetPaymentsTotalSummaryResponseSchema = z.object({
  total_summary: PaymentsTotalSummarySchema,
});
export type GetPaymentsTotalSummaryResponse = z.infer<typeof GetPaymentsTotalSummaryResponseSchema>;
