import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../../infrastructure';

export const PlanPrjServiceItemSchema = z.object({
  index: z.number(),
  section: z.string(),
  yyear: z.number(),
  ymonth: z.number(),
  tag_id: z.string(),
  type: z.string().nullable(),
  subtype: z.string().nullable(),
  amount: z.number(),
  is_percent: z.boolean(),
  budget_name: z.string().nullish(),
  budget_id: z.number().nullish(),
});
export type PlanPrjServiceItem = z.infer<typeof PlanPrjServiceItemSchema>;

export const PlanPrjServiceFiltersSchema = z.object({
  ymonth_period_start: z
    .record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth_period_end: z
    .record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
  budget_id: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
  budget_name: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.string()).optional(),
  isInvoicedRevVisible: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
  isOppRevVisible: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
  isPreOppRevVisible: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
});
export type PlanPrjServiceFilters = z.infer<typeof PlanPrjServiceFiltersSchema>;

export const PlanPrjServiceQuerySchema = z.object({
  filters: PlanPrjServiceFiltersSchema.optional(),
});
export type PlanPrjServiceQuery = z.infer<typeof PlanPrjServiceQuerySchema>;

export type PlanPrjServiceResponse = PlanPrjServiceItem[];

export type PlanPrjServiceEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/monthly-plan-v2/project-service',
  PlanPrjServiceResponse,
  undefined,
  PlanPrjServiceQuery,
  undefined
>;
