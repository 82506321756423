import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '@redux/modules/feature-flags';
import get from 'lodash/get';

const { getFeatureFlags } = actions;

const useUserFeatureFlags = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);
  const userId = get(user, '_id');

  useEffect(() => {
    if (userId) {
      dispatch(getFeatureFlags());
    }
  }, [dispatch, userId]);
};

export default useUserFeatureFlags;
