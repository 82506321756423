import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import './home.less';

const propTypes = {};

class Home extends Component {
  state = {
    name: 'React',
  };

  render() {
    // TODO: This isn't gonna be the final design/content.
    return <Redirect to="/admin/dashboard" />;
    // return (
    //   <div className="home-view">
    //     <h1>Welcome to CleverHire</h1>
    //   </div>
    // );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

Home.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(Home);
