import { z } from 'zod';

export const GetFunnelReportItemSchema = z.object({
  created: z.number().min(0),
  senior: z.number().min(0),
  requestedFirstInterview: z.number().min(0),
  hadFirstInterview: z.number().min(0),
  requestedSecondInterview: z.number().min(0),
  hadSecondInterview: z.number().min(0),
  reachedFinalState: z.number().min(0),
});

export type GetFunnelReportItem = z.infer<typeof GetFunnelReportItemSchema>;
