import * as api from '@redux/api/application';
import { put, takeLatest, call } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';
import * as schemas from '@src/schemas';
import { actions as entitiesActions } from '@redux/modules/entities';
import { normalize } from 'normalizr';
import isFunction from 'lodash/isFunction';

function* sendTest(action) {
  try {
    yield call(api.sendTest, action);
    yield put({
      type: constants.APPLICATION_SEND_TEST.SUCCESS,
    });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }

    const timelineResponse = yield call(api.getApplicationTimelineList, action);
    const timelineList = (timelineResponse && timelineResponse.data) || [];
    yield put({ type: constants.APPLICATION_TIMELINES_GET.SUCCESS, timelineList });

    const applicationResponse = yield call(api.get, action);
    const normalized = normalize(applicationResponse.data.application, schemas.application);
    const { applications } = normalized.entities;
    yield put(entitiesActions.mergeApplications(applications));
    yield put({ type: constants.APPLICATION_STATUS_UPDATE.SUCCESS });
  } catch (e) {
    const error = e.message || e;
    yield put({
      type: constants.APPLICATION_SEND_TEST.FAILURE,
      error,
    });

    if (isFunction(action.onFail)) {
      yield action.onFail(error);
    }
  }
}

export function* watchSendTest() {
  yield takeLatest(constants.APPLICATION_SEND_TEST.REQUEST, sendTest);
}

function* setSelectedTestId(action) {
  try {
    yield put({
      type: constants.APPLICATION_SET_SELECTED_TEST_ID.SUCCESS,
      selectedTestId: action.testId,
    });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_SET_SELECTED_TEST_ID.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchSetSelectedTestId() {
  yield takeLatest(constants.APPLICATION_SET_SELECTED_TEST_ID.REQUEST, setSelectedTestId);
}
