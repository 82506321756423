import request from '../../shared/request';

export function getInboundEmailErrors() {
  return request('/reports/inbound-email-errors', {
    method: 'GET',
  });
}

export function resolveInboundEmailError({ applicationId, messageId }) {
  return request('/reports/inbound-email-errors', {
    method: 'POST',
    body: {
      applicationId,
      messageId,
    },
  });
}

export function getSelectedApplication({ applicationId }) {
  return request(`/applications/${applicationId}`, {
    method: 'GET',
  });
}
