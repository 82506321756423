import { z } from 'zod';
import { TimeOffRequest } from '../base';
import { Endpoint, Method } from '../../../infrastructure';
import { TimeOffRequestDeclineSchema } from '../crud';

export const TimeOffRequestDeclineRequestSchema = TimeOffRequestDeclineSchema.pick({
  declined_by: true,
});
export type TimeOffRequestDeclineRequest = z.infer<typeof TimeOffRequestDeclineRequestSchema>;

export const TimeOffRequestDeclineParamsSchema = z.object({
  time_off_request_id: z.coerce.number(),
});
export type TimeOffRequestDeclineParams = z.infer<typeof TimeOffRequestDeclineParamsSchema>;

export type TimeOffRequestDeclineResponse = TimeOffRequest;

export type TimeOffRequestDeclineEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/time-off-requests/${TimeOffRequestDeclineParams['time_off_request_id']}/decline`,
  TimeOffRequestDeclineResponse,
  TimeOffRequestDeclineParams,
  undefined,
  TimeOffRequestDeclineRequest
>;
