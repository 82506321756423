import { z } from 'zod';
import { dateString } from '../../infrastructure';
import { EnumAppBusinessErrorCode } from '../app_business_error_code/base';

export const AppBusinessErrorSchema = z.object({
  app_business_error_id: z.number(),
  email: z.string(),
  previous_application_id: z.string().nullable(),
  app_business_error_code: z.nativeEnum(EnumAppBusinessErrorCode),
  dt_created: dateString,
});

export type AppBusinessError = z.infer<typeof AppBusinessErrorSchema>;
