import zipObject from 'lodash/zipObject';
import mapValues from 'lodash/mapValues';
import defaults from 'lodash/defaults';
import includes from 'lodash/includes';
import remove from 'lodash/remove';
import reduce from 'lodash/reduce';
import values from 'lodash/values';
import filter from 'lodash/filter';
import clone from 'lodash/clone';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';

import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';
import { setActions } from '@redux/modules/_utils';
import ACTION_MAP from './actions/actionsMap';
import actionDefinitions from './actions/actionsDefinitions';

const keys = values(ACTION_MAP);
const sagaValues = map(keys, (key) => createSagaAction(key));

// Constants
export const constants = zipObject(keys, sagaValues);

// Action Creators
export const actions = mapValues(ACTION_MAP, (key) => {
  return (data) => defaults({ type: constants[key].REQUEST }, data);
});

// Reducer
export const initialState = {
  messages: [],
  isLoading: false,
  isCommentsModalOpen: false,
  commentsOptions: {},
  error: null,
};

const ACTION_HANDLERS = setActions(actionDefinitions, constants);

ACTION_HANDLERS[constants.MESSAGES_GET.SUCCESS] = (state, action) => {
  const { data } = action;
  return {
    ...state,
    messages: data,
    isLoading: false,
    error: null,
  };
};

ACTION_HANDLERS[constants.MESSAGE_POST.SUCCESS] = (state, { message }) => {
  return {
    ...state,
    messages: [...state.messages, message],
    isLoading: false,
    error: null,
  };
};

ACTION_HANDLERS[constants.MESSAGE_DELETE.SUCCESS] = (state, { messageId }) => {
  return {
    ...state,
    messages: filter(state.messages, ({ _id }) => _id !== messageId),
    isLoading: false,
    error: null,
  };
};

ACTION_HANDLERS[constants.MESSAGE_UPDATE.SUCCESS] = (state, { messageId, updatedMessage }) => {
  return {
    ...state,
    messages: map(state.messages, (message) =>
      message._id === messageId ? updatedMessage : message,
    ),
    isLoading: false,
    error: null,
  };
};

ACTION_HANDLERS[constants.MESSAGE_TOGGLE_PIN.SUCCESS] = (state, { groupBy, messages }) => {
  const messagesMap = reduce(
    messages,
    (accum, message) => ({ ...accum, [message._id]: message }),
    {},
  );

  return {
    ...state,
    messages: map(state.messages, (message) =>
      messagesMap[message._id] ? messagesMap[message._id] : message,
    ),
    isLoading: false,
    error: null,
  };
};

ACTION_HANDLERS[constants.COMMENTS_MODAL_OPEN.REQUEST] = (state, action) => {
  return {
    ...state,
    commentsOptions: action.data,
    isCommentsModalOpen: true,
  };
};

ACTION_HANDLERS[constants.COMMENTS_MODAL_CLOSE.REQUEST] = (state) => {
  return {
    ...state,
    commentsOptions: {},
    messages: [],
    isCommentsModalOpen: false,
  };
};

ACTION_HANDLERS[constants.PROCESS_SOCKET_UPDATE.REQUEST] = (state, action) => {
  const newState = clone(state);
  const { created, updated, deleted } = action;
  if (created) {
    const { message } = action;
    if (message) {
      const {
        lead: messageLead,
        assignment: messageAssignment,
        application: messageApplication,
      } = message;
      const {
        commentsOptions: { leads, assignments, applications },
      } = state;
      if (
        includes(leads, messageLead) ||
        includes(assignments, messageAssignment) ||
        includes(applications, messageApplication)
      ) {
        newState.messages = [...newState.messages, message];
      }
    }
  } else if (updated) {
    const { message } = action;
    if (message) {
      const messageToUpdate = find(get(newState, 'messages', []), (m) => m._id === message._id);
      if (messageToUpdate && message.comment) {
        messageToUpdate.comment = message.comment;
      }
    }
  } else if (deleted) {
    const { messageId } = action;
    if (messageId) {
      remove(newState.messages, (m) => m._id === messageId);
    }
  }
  return newState;
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
