import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const PositionTemplateSchema = z.object({
  position_template_id: z.number(),
  name: z.string(),
  position_name: z.string(),
  english_level: z.number(),
  seniority: z.string(),
  target_cost: z.number(),
  revenue_amount: z.number(),

  dt_created: dateString,
  dt_updated: dateString,
});
export type PositionTemplate = z.infer<typeof PositionTemplateSchema>;
