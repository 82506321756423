import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  CANDIDATE_INFO: createSagaAction('CANDIDATE_INFO'),
  CANDIDATE_ASSESSMENTS: createSagaAction('CANDIDATE_ASSESSMENTS'),
};

// Action Creators
export const actions = {
  getCandidateInfo: (args) => ({
    type: constants.CANDIDATE_INFO.REQUEST,
    ...args,
  }),
  getCandidateAssessments: (args) => ({
    type: constants.CANDIDATE_ASSESSMENTS.REQUEST,
    ...args,
  }),
};

// Reducer
export const initialState = {
  isLoading: true,
  candidateInfo: {},
  candidateAssessments: [],
  error: null,
};

const ACTION_HANDLERS = {
  [constants.CANDIDATE_INFO.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.CANDIDATE_INFO.SUCCESS]: (state, { candidateInfo }) => {
    return { ...state, candidateInfo, isLoading: true };
  },
  [constants.CANDIDATE_ASSESSMENTS.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.CANDIDATE_ASSESSMENTS.SUCCESS]: (state, { candidateAssessments }) => {
    return { ...state, candidateAssessments, isLoading: false };
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state };
});
