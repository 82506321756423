import * as api from '@redux/api/application';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';

function* setApplicationReviewed(action) {
  try {
    yield call(api.setReviewed, action);
    yield put({ type: constants.APPLICATION_SET_REVIEWED.SUCCESS });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_SET_REVIEWED.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchSetApplicationReviewed() {
  yield takeLatest(constants.APPLICATION_SET_REVIEWED.REQUEST, setApplicationReviewed);
}
