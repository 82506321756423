import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  SEARCH_APPLICATION_AVAILABILITY_GET: createSagaAction('SEARCH_APPLICATION_AVAILABILITY_GET'),
};

const actions = {
  getAvailability: (data) => ({
    ...data,
    type: constants.SEARCH_APPLICATION_AVAILABILITY_GET.REQUEST,
  }),
};

const handlers = {
  [constants.SEARCH_APPLICATION_AVAILABILITY_GET.REQUEST]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [constants.SEARCH_APPLICATION_AVAILABILITY_GET.SUCCESS]: (state, action) => {
    return {
      ...state,
      isLoading: false,
      applicationAvailability: action.applicationAvailability,
    };
  },
  [constants.SEARCH_APPLICATION_AVAILABILITY_GET.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

function* getAvailability(action) {
  try {
    const response = yield call(apiGetAvailability, action);
    const applicationAvailability = response.data;
    yield put({
      type: constants.SEARCH_APPLICATION_AVAILABILITY_GET.SUCCESS,
      applicationAvailability,
    });
  } catch (e) {
    yield put({
      type: constants.SEARCH_APPLICATION_AVAILABILITY_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchAvailability() {
  yield takeLatest(constants.SEARCH_APPLICATION_AVAILABILITY_GET.REQUEST, getAvailability);
}

// API

export function apiGetAvailability() {
  return request('/applications/availability-options', {
    method: 'GET',
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchAvailability,

  // optionally we may
  // api: {
  //   getAccount: apiGetAccount
  // }
};
