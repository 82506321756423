import { z } from 'zod';
import { ForecastSchema } from '../';
import { Endpoint, Method } from '../../../infrastructure';

export const ForecastDeleteParamsSchema = z.object({
  forecast_id: z.coerce.number(),
});
export type ForecastDeleteParams = z.infer<typeof ForecastDeleteParamsSchema>;

export type ForecastDeleteEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/forecasts/${ForecastDeleteParams['forecast_id']}`,
  void,
  ForecastDeleteParams,
  undefined,
  undefined
>;
