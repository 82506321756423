import { z } from 'zod';
import { InterviewRequestSchema } from '../base';

export const InterviewRequestUpdateSchema = InterviewRequestSchema.pick({
  interview_request_id: true,
  num_days: true,
  dt_scheduled: true,
  dt_canceled: true,
  dt_conducted: true,
  act_recruiter_id: true,
}).partial({
  num_days: true,
  dt_scheduled: true,
  dt_canceled: true,
  dt_conducted: true,
  act_recruiter_id: true,
});

export type InterviewRequestUpdate = z.infer<typeof InterviewRequestUpdateSchema>;
