import * as api from '@redux/api/application';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';

function* getTimeLineList(action) {
  try {
    const response = yield call(api.getApplicationTimelineList, action);
    const timelineList = (response && response.data) || [];
    yield put({ type: constants.APPLICATION_TIMELINES_GET.SUCCESS, timelineList });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_TIMELINES_GET.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchGetTimelineList() {
  yield takeLatest(constants.APPLICATION_TIMELINES_GET.REQUEST, getTimeLineList);
}
