import { z } from 'zod';
import { PositionTemplateSchema } from '../base';

export const PositionTemplateCreateSchema = PositionTemplateSchema.pick({
  name: true,
  position_name: true,
  english_level: true,
  seniority: true,
  target_cost: true,
  revenue_amount: true,
}).extend({
  skills_id: z.array(z.string()).nullish(),
});
export type PositionTemplateCreate = z.infer<typeof PositionTemplateCreateSchema>;
