import { ReactNode } from 'react';
import { ActionRequest, ActionRequests } from '@ct-internal/api';

import { WaitingForInterviewer, WaitingForInterviewerMessage } from './WaitingForInterviewer';
import {
  InterviewDeclinedByApplicant,
  InterviewDeclinedByApplicantMessage,
} from './InterviewDeclinedByApplicant';
import { ApplicantNoResponse, ApplicantNoResponseMessage } from './ApplicantNoResponse';
import { InterviewStaled, InterviewStaledMessage } from './InterviewStaled';

export type NotificationConfigMessage = (notification: ActionRequest) => string;

export interface NotificationConfigItem {
  title: string;
  getDescription: (notification: ActionRequest) => ReactNode;
}

export const NotificationConfig: Partial<Record<ActionRequests, NotificationConfigItem>> = {
  [ActionRequests.WaitingForInterviewer]: WaitingForInterviewer,
  [ActionRequests.InterviewDeclinedByApplicant]: InterviewDeclinedByApplicant,
  [ActionRequests.ApplicantNoResponse]: ApplicantNoResponse,
  [ActionRequests.InterviewStaled]: InterviewStaled,
};

export const NotificationMessage: Partial<Record<ActionRequests, NotificationConfigMessage>> = {
  [ActionRequests.WaitingForInterviewer]: WaitingForInterviewerMessage,
  [ActionRequests.InterviewDeclinedByApplicant]: InterviewDeclinedByApplicantMessage,
  [ActionRequests.ApplicantNoResponse]: ApplicantNoResponseMessage,
  [ActionRequests.InterviewStaled]: InterviewStaledMessage,
};
