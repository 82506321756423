import request from '../../shared/request';

export function getAllQuestions(body) {
  return request(`/job-text-questions/${body.id}`, {
    method: 'GET',
  });
}

export function createQuestion(body) {
  return request('/job-text-questions', {
    method: 'POST',
    body,
  });
}

export function updateQuestion(body) {
  const baseUrl = `/job-text-questions/${body._id}`;
  const url = body.job ? baseUrl + `/${body.job}` : baseUrl;

  return request(url, {
    method: 'PUT',
    body,
  });
}

export function deleteQuestion({ id, job }) {
  return request(`/job-text-questions/${id}/${job}`, {
    method: 'DELETE',
  });
}
