import { z } from 'zod';
import { POCSchema } from './basePOC';

export const UpdatePOCSchema = POCSchema.pick({
  payment_option_change_id: true,
  company_tax_id: true,
  person_tax_id: true,
  payment_info: true,
  comment: true,
})
  .required({
    payment_option_change_id: true,
  })
  .partial({
    company_tax_id: true,
    person_tax_id: true,
    payment_info: true,
    comment: true,
  });

export type UpdatePOC = z.infer<typeof UpdatePOCSchema>;
