import { all, fork } from 'redux-saga/effects';
import { watchUpdateUserSalary } from './salary';
import { watchUpdateUserSalaryCurrency } from './salaryCurrency';
import { watchUpdateUserYears } from './years';
import { watchUpdateUserEnglishLevel } from './englishLevel';
import { watchGetApplicationPast } from './pastApplications';
import { watchUpdateUserAvailability } from './availability';
import { watchUpdateUserStartDate } from './startDate';
import { watchUpdateUserAssignments, watchUpdateUserAssignment } from './assignments';
import { watchUpdateUserHireDate } from './hireDate';
import { watchUpdateUserOfferTerms } from './offerTerms';
import { watchUpdateUserSeniority } from './seniority';
import { watchUpdateUserWorkingStatus } from './workingStatus';
import { watchUpdateUserLeadExperience } from './leadExperience';
import { watchUpdateUserNativeEnglish } from './nativeEnglish';
import { watchInitializeUserOnBrains } from './initializeUserOnBrains';
import { watchUpdateUserNeedContract } from './needContract';
import { watchSendPreContractLetter } from './preContractLetter';
import { watchSendOnBoardingLetter } from './onBoardingLetter';
import { watchUpdateUserLinkedinURL } from './linkedinUrl';
import { watchUpdateUserPhone } from './phone';
import { watchUpdateVisaRequired } from './visaRequired';
import { watchUpdateApplicationMgmtReviewed } from './mgmtReviewed';
import { watchUpdateApplicationAutoSignContractCT } from './autoSignContractCT';

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  const sagas = [
    watchUpdateUserSalary,
    watchUpdateUserSalaryCurrency,
    watchUpdateUserPhone,
    watchUpdateVisaRequired,
    watchUpdateUserYears,
    watchUpdateUserEnglishLevel,
    watchGetApplicationPast,
    watchUpdateUserAvailability,
    watchUpdateUserStartDate,
    watchUpdateUserAssignments,
    watchUpdateUserAssignment,
    watchUpdateUserHireDate,
    watchUpdateUserOfferTerms,
    watchUpdateUserSeniority,
    watchUpdateUserWorkingStatus,
    watchUpdateUserLeadExperience,
    watchUpdateUserNativeEnglish,
    watchUpdateUserLinkedinURL,
    watchInitializeUserOnBrains,
    watchUpdateUserNeedContract,
    watchSendPreContractLetter,
    watchSendOnBoardingLetter,
    watchUpdateApplicationMgmtReviewed,
    watchUpdateApplicationAutoSignContractCT,
  ].map(fork);

  yield all(sagas);
}
