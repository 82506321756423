import filter from 'lodash/filter';

import {
  getFormattedCost,
  getFormattedSalaryWithContractType,
} from '@components/Person/PersonCostAndSalary';

export default function (application) {
  const salaryData = {
    cost_usd: (application.cost ?? application.salaryValue) * 1000,
    currency: application.salaryCurrency,
    currency_salary: application.salaryValue * 1000,
    usd_salary: application.usdSalary ?? application.salaryValue,
    contract_type: application.contractType,
  };
  const { formattedYearlySalary, contractTypeFormatted } = getFormattedSalaryWithContractType({
    data: salaryData,
  });

  return filter(
    [
      application.testScore ? application.testScore.toFixed(2) : null,
      `${getFormattedCost({
        data: salaryData,
      })} - ${formattedYearlySalary}${contractTypeFormatted} `,
      `${new Date().getFullYear() - application.degreeYear}y`,
      `${application.availabilityShort}`,
      `${application.timezoneShort}`,
      `${application.countryShort}`,
    ],
    (sub) => !!sub && `${sub}`.length,
  ).join(', ');
}
