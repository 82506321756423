import { InterviewCalendarEvent, InterviewCalendarEventCreateSchema } from '../';

import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const InterviewCalendarEventCreateRequestSchema = InterviewCalendarEventCreateSchema.pick({
  interview_request_id: true,
  status: true,
  zoom_id: true,
  act_recruiter_id: true,
  calendly_event_id: true,
  dt_canceled: true,
  dt_conducted: true,
  dt_end: true,
  dt_last_google_update: true,
  dt_start: true,
  google_calendar_event_id: true,
});
export type InterviewCalendarEventCreateRequest = z.infer<
  typeof InterviewCalendarEventCreateRequestSchema
>;

export type InterviewCalendarEventCreateResponse = InterviewCalendarEvent;

export type InterviewCalendarEventCreateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/inteview-calendar-events`,
  InterviewCalendarEventCreateResponse,
  undefined,
  undefined,
  InterviewCalendarEventCreateRequest
>;
