import { z } from 'zod';
import { dateString, stringToBoolean } from '../../infrastructure/schema';
import { Endpoint, FilterOperations, Method, makeSorter } from '../../infrastructure';

//Base
export const LeadListSchema = z.object({
  lead_id: z.string(),
  lead_name: z.string(),
  islead: z.boolean(),
  client_id: z.string(),
  client_name: z.string(),
  plannedstart: dateString,
  plannedend: dateString,
  hard_date: z.boolean(),
  isstaffit: z.boolean(),
  team_size: z.number(),
  target_Cost: z.number(),
  expected_revenue: z.number(),
  expected_cost: z.number(),
  timezone: z.string(),
  expected_duration: z.number(),
  expected_margin: z.number(),
  hire_group: z.array(z.string()),
  skills: z.string(),
  skills_array: z.array(z.string()),
  default_duration: z.number(),
  default_expected_team_size: z.number(),
  default_expected_monthly_revenue: z.number(),
  default_expected_monthly_cost: z.number(),
  sf_expected_monthly_revenue: z.number(),
  dt_archived: dateString.nullable(),
  is_plan_included: z.boolean(),
  owner_name: z.string(),
  project_sf_id: z.string(),
  sf_stage: z.string().nullable(),
  probability: z.number().nullable(),
  duration_weeks: z.number().nullable(),
  is_sf_locked: z.boolean().nullable(),
});

export type LeadList = z.infer<typeof LeadListSchema>;

export const LeadListSorterSchema = makeSorter(z.enum(['plannedstart', 'client_name']));
export type LeadListSorter = z.infer<typeof LeadListSorterSchema>;

//Filters
export const LeadListFiltersSchema = z.object({
  lead_name: z.record(z.enum([FilterOperations.CONTAIN]), z.string()).optional(),
  hire_group: z.record(z.enum([FilterOperations.IN]), z.string().array()).optional(),
  skills: z.record(z.enum([FilterOperations.IN]), z.string().array()).optional(),
  include_archived: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean).optional(),
  only_sf: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean).optional(),
  is_pre_opportunity: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean).optional(),
});
export type LeadListFilters = z.infer<typeof LeadListFiltersSchema>;

//Query
export const LeadListQuerySchema = z.object({
  filters: LeadListFiltersSchema.optional(),
  sorter: LeadListSorterSchema.optional(),
});
export type LeadListQuery = z.infer<typeof LeadListQuerySchema>;

//Response
export type LeadListResponse = LeadList[];

//Endpoint
export type LeadListEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-projects/leads',
  LeadListResponse,
  undefined,
  LeadListQuery,
  undefined
>;
