import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const PersonTagAscSchema = z.object({
  person_tag_asc_id: z.number(),
  person_id: z.string(),
  person_tag_id: z.number(),
  dt_start: z.union([z.number(), dateString]),
  dt_end: z.union([z.number(), dateString]).nullable(),
  is_active: z.boolean(),
});

export type PersonTagAsc = z.infer<typeof PersonTagAscSchema>;
