import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { MessageSchema } from './base';

export const DeleteMessageParamsSchema = MessageSchema.pick({
  message_id: true,
});
export type DeleteMessageParams = z.infer<typeof DeleteMessageParamsSchema>;

export type DeleteMessageEndpoint = Endpoint<
  Method.DELETE,
  `/api/v3/message/:${DeleteMessageParams['message_id']}`,
  '',
  DeleteMessageParams,
  undefined,
  undefined
>;
