import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { dateString } from '../../infrastructure/schema';

export const BulkPositionAddOppExtensionRequestSchema = z.object({
  positions_id: z.array(z.string()),
  project_id: z.string(),
  dt_opp_extension: dateString,
});
export type BulkPositionAddOppExtensionRequest = z.infer<
  typeof BulkPositionAddOppExtensionRequestSchema
>;

export type BulkPositionAddOppExtensionResponse = void;

export type BulkPositionAddOppExtensionEndpoint = Endpoint<
  Method.POST,
  `/api/v2/positions/bulk-opp-extension`,
  BulkPositionAddOppExtensionResponse,
  undefined,
  undefined,
  BulkPositionAddOppExtensionRequest
>;
