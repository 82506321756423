import { call, put, takeLatest } from 'redux-saga/effects';

import { createSagaAction } from '@shared/sagas';
import request from '@shared/request';

import filter from 'lodash/filter';
import uniq from 'lodash/uniqBy';
import size from 'lodash/size';
import map from 'lodash/map';

// MODULE
const constants = {
  LEADS_BY_ID: createSagaAction('LEADS_BY_ID'),
};

const actions = {
  getLeadsById: (leadsId) => ({
    type: constants.LEADS_BY_ID.REQUEST,
    leadsId,
  }),
};

const handlers = {
  [constants.LEADS_BY_ID.REQUEST]: (state) => {
    return {
      ...state,
      loadingById: true,
      error: null,
    };
  },
  [constants.LEADS_BY_ID.SUCCESS]: (state, { leads }) => {
    const updatedIds = new Set(map(leads, '_id'));
    const leadsList = state.leadsList || [];

    if (!size(leads)) {
      return { ...state, loadingById: false };
    }

    const filteredList = filter(leadsList, (lead) => {
      return !updatedIds.has(lead._id);
    });

    return {
      ...state,
      leadsList: [...filteredList, ...leads],
      loadingById: false,
      error: null,
    };
  },
  [constants.LEADS_BY_ID.FAILURE]: (state, { error }) => {
    return {
      ...state,
      loadingById: false,
      error,
    };
  },
};

// SAGA
function* sagaGetLeadById({ leadsId }) {
  try {
    const response = yield map(uniq(leadsId), (leadId) => call(apiGetLeadById, { leadId }));

    yield put({
      type: constants.LEADS_BY_ID.SUCCESS,
      leads: response,
    });
  } catch (e) {
    yield put({
      type: constants.LEADS_BY_ID.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetLeadById() {
  yield takeLatest(constants.LEADS_BY_ID.REQUEST, sagaGetLeadById);
}

// API
function apiGetLeadById({ leadId }) {
  return request(`/leads/${leadId}`, {
    method: 'GET',
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchGetLeadById,
};
