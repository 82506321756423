import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { ActiveAssignmentsMarginSummarySchema } from './base';
import { ListActiveAssignmentsMarginFiltersSchema } from './listActiveAssignmentsMargin';

export type GetActiveAssignmentsMarginSummaryEndpoint = Endpoint<
  Method.GET,
  '/api/v2/payouts/active-margin-summary',
  GetActiveAssignmentsMarginSummaryResponse,
  undefined,
  GetActiveAssignmentsMarginSummaryQuery,
  undefined
>;

export const GetActiveAssignmentsMarginSummaryQuerySchema = z.object({
  filters: ListActiveAssignmentsMarginFiltersSchema,
});
export type GetActiveAssignmentsMarginSummaryQuery = z.infer<
  typeof GetActiveAssignmentsMarginSummaryQuerySchema
>;

export const GetActiveAssignmentsMarginSummaryResponseSchema = z.object({
  summary: ActiveAssignmentsMarginSummarySchema,
});
export type GetActiveAssignmentsMarginSummaryResponse = z.infer<
  typeof GetActiveAssignmentsMarginSummaryResponseSchema
>;
