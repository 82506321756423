import { all, fork } from 'redux-saga/effects';

import reduce from 'lodash/reduce';
import merge from 'lodash/merge';
import map from 'lodash/map';

import { createReducer } from '@shared/reducers';

import GetLeads from './get-leads';
import GetLeadsById from './get-leads-by-id';
import UpdateHireGroup from './update-hire-group';
import UpdatePositionResponsible from './update-position-responsible';
import GetSlackMessages from './get-slack-messages';
import GetLeadTimeline from './get-lead-timeline';
import UpdatePositionCost from './update-position-target-cost';

const modules = [
  GetLeads,
  GetLeadsById,
  UpdateHireGroup,
  UpdatePositionResponsible,
  GetSlackMessages,
  GetLeadTimeline,
  UpdatePositionCost,
];

// Reducer
export const initialState = {
  leads: {},
  leadsList: [],
  isLoading: false,
  loadingById: false,
  error: null,
  updateSuccessMessage: null,
  updateErrorMessage: null,
  leadTimeline: null,
  loadingTimeline: false,
  slackMessages: [],
  isLoadingSlackMessages: false,
};

const ACTION_HANDLERS = reduce(map(modules, 'handlers'), merge, {});

export const reducer = createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});

// Actions
export const actions = reduce(map(modules, 'actions'), merge, {});

// Constants
// it's barely used, but we may need to trigger
// a redux action of any other module from saga,
// so it's worth to export it for consistency
export const constants = reduce(map(modules, 'constants'), merge, {});

// Root saga
// Export the root saga by forking all available sagas
export function* rootSaga() {
  const sagas = map(modules, 'watcher');
  yield all(sagas.map(fork));
}
