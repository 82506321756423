import { call, put, takeLatest } from 'redux-saga/effects';

import { createSagaAction } from '@shared/sagas';
import request from '@shared/request';

// MODULE
const constants = {
  LEADS_FETCH_SLACK_MESSAGES: createSagaAction('LEADS_FETCH_SLACK_MESSAGES'),
};

const actions = {
  getLeadSlackMessages: (leadId) => ({
    type: constants.LEADS_FETCH_SLACK_MESSAGES.REQUEST,
    leadId,
  }),
};

const handlers = {
  [constants.LEADS_FETCH_SLACK_MESSAGES.REQUEST]: (state) => {
    return {
      ...state,
      isLoadingSlackMessages: true,
    };
  },
  [constants.LEADS_FETCH_SLACK_MESSAGES.SUCCESS]: (state, { messages }) => {
    return {
      ...state,
      isLoadingSlackMessages: false,
      slackMessages: messages,
    };
  },
  [constants.LEADS_FETCH_SLACK_MESSAGES.FAILURE]: (state, { error }) => {
    return {
      ...state,
      error,
      slackMessages: [],
      isLoadingSlackMessages: false,
    };
  },
};

// SAGA
function* sagaGetLeadMessages(action) {
  try {
    const { messages } = yield call(apiGetLeadMessages, action.leadId);

    yield put({ type: constants.LEADS_FETCH_SLACK_MESSAGES.SUCCESS, messages });
  } catch (e) {
    yield put({
      type: constants.LEADS_FETCH_SLACK_MESSAGES.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetLeadMessages() {
  yield takeLatest(constants.LEADS_FETCH_SLACK_MESSAGES.REQUEST, sagaGetLeadMessages);
}

// API
function apiGetLeadMessages(leadId) {
  return request(`/leads/${leadId}/slack-messages`, {
    method: 'GET',
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchGetLeadMessages,
};
