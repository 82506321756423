import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { dateString } from '../../infrastructure/schema';

export const BulkPositionEarlyTerminationRequestSchema = z.object({
  positions_id: z.array(z.string()),
  project_id: z.string(),
  dt_early_termination: dateString,
});
export type BulkPositionEarlyTerminationRequest = z.infer<
  typeof BulkPositionEarlyTerminationRequestSchema
>;

export type BulkPositionEarlyTerminationResponse = void;

export type BulkPositionEarlyTerminationEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/positions/bulk-early-termination`,
  BulkPositionEarlyTerminationResponse,
  undefined,
  undefined,
  BulkPositionEarlyTerminationRequest
>;
