import request from '../../shared/request';

// STATUSES

export function get({ applicationId }) {
  return request(`/applications/${applicationId}/email-templates`, {
    method: 'GET',
  });
}

export function sendEmail({
  applicationId,
  text,
  slug,
  subject,
  name,
  when,
  useCurrentUserFromAddress,
  extras,
}) {
  return request(`/applications/${applicationId}/email-templates`, {
    method: 'POST',
    body: {
      text,
      slug,
      subject,
      name,
      when,
      useCurrentUserFromAddress,
      extras,
    },
  });
}

// MOST USED EMAIL TEMPLATES

export function getMostUsedEmailTemplates() {
  return request('/template-metrics', {
    method: 'GET',
  });
}

export function createEmailTemplateMetric({ slug }) {
  return request('/template-metrics', {
    method: 'POST',
    body: { slug },
  });
}

export function updateEmailTemplateMetric({ emailTemplateId, slug }) {
  return request(`/template-metrics/${emailTemplateId}`, {
    method: 'PUT',
    body: { slug },
  });
}

export function createEmailTemplate({
  applicationId,
  name,
  slug,
  group,
  subject,
  html,
  applicationStatus,
}) {
  const route = `/applications/${applicationId ? `${applicationId}/` : ''}email-templates/${slug}`;

  return request(route, {
    method: 'POST',
    body: {
      name,
      slug,
      group,
      subject,
      html,
      applicationStatus,
    },
  });
}

export function getEmailCategories() {
  return request('/applications/email-categories', {
    method: 'GET',
  });
}
