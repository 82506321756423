// SCHEDULE INTERVIEW
import get from 'lodash/get';
import isString from 'lodash/isString';

import * as api from '@redux/api/application';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';

// CREATE NEW INTERVIEW

function* createNewApplicant(action) {
  try {
    const createApplicant = yield call(api.createNewApplicant, action);
    if (createApplicant) {
      yield put({
        type: constants.APPLICATION_CREATE_APPLICANT.SUCCESS,
        payload: createApplicant,
      });
    }
  } catch (e) {
    let errorMessage = 'An error has occurred.';
    if (isString(get(e, 'message'))) {
      errorMessage = e.message;
    } else if (isString(e)) {
      errorMessage = e;
    }

    yield put({
      type: constants.APPLICATION_CREATE_APPLICANT.FAILURE,
      error: errorMessage,
    });
  }
}

export function* watchCreateNewApplicant() {
  yield takeLatest(constants.APPLICATION_CREATE_APPLICANT.REQUEST, createNewApplicant);
}
