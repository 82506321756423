//Modules
import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const DepartmentSchema = z.object({
  department_id: z.string(),
  pers_department_id: z.number().nullable(),
  name: z.string(),
  isBillable: z.boolean(),
  assigned_cost: z.string().default('Project Cost'),
  unassigned_cost: z.string(),
  display_color: z.string(),
  is_dev_group: z.boolean().default(false),
  is_dev_group_acct: z.boolean().default(false),
  dtcreate: dateString,
  dtupdate: dateString,
});

export type Department = z.infer<typeof DepartmentSchema>;
