import { z } from 'zod';

export enum PsnDeepSkillStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Declined = 'Declined',
}

export const SQLPsnDeepSkillSchema = z.object({
  psn_deep_skill_id: z.string(),
  mongo_psn_skill_id: z.string(),
  name: z.string(),
  display_name: z.string(),
  description: z.string(),
  category_id: z.number(),
  status: z.nativeEnum(PsnDeepSkillStatus),
  requested_by: z.string(),
  updated_by: z.string(),
  dt_created: z.number(),
  dt_updated: z.number(),
});
export type SQLPsnDeepSkill = z.infer<typeof SQLPsnDeepSkillSchema>;

export const RelatedPsnDeepSkillSchema = z.object({
  _id: z.string(),
  display: z.string().optional(),
  description: z.string().optional(),
});
export type RelatedPsnDeepSkill = z.infer<typeof RelatedPsnDeepSkillSchema>;

export const PsnDeepSkillSchema = z.object({
  _id: z.string(),
  skill: z.string(),
  display: z.string(),
  description: z.string(),
  category: z.string(),
  status: z.nativeEnum(PsnDeepSkillStatus),
  relatedSkills: z.array(RelatedPsnDeepSkillSchema),
  requestedBy: z.object({
    person_id: z.string(),
    name: z.string(),
    gravatarURL: z.string(),
  }),
  updatedBy: z.string(),
});
export type PsnDeepSkill = z.infer<typeof PsnDeepSkillSchema>;
