import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { PayoutDownloadMstSchema } from './base';

//Define params that is going to be used in this endpoint
export const ConfirmPayoutDownloadMstUploadParamsSchema = PayoutDownloadMstSchema.pick({
  pdlf_id: true,
});

export type ConfirmPayoutDownloadMstUploadParams = z.infer<
  typeof ConfirmPayoutDownloadMstUploadParamsSchema
>;

//Define response
export const ConfirmPayoutDownloadMstUploadResponseSchema = PayoutDownloadMstSchema.extend({
  payment_provider_name: z.string(),
});
export type ConfirmPayoutDownloadMstUploadResponse = z.infer<
  typeof ConfirmPayoutDownloadMstUploadResponseSchema
>;

export type ConfirmPayoutDownloadMstUploadEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/payout-download-mst/${ConfirmPayoutDownloadMstUploadParams['pdlf_id']}/confirm-upload`,
  ConfirmPayoutDownloadMstUploadResponse,
  ConfirmPayoutDownloadMstUploadParams,
  undefined,
  undefined
>;
