import { schema } from 'normalizr';

export const job = new schema.Entity('jobs', undefined, { idAttribute: '_id' });

export const user = new schema.Entity('users', undefined, { idAttribute: '_id' });

export const comment = new schema.Entity('comments', { user }, { idAttribute: '_id' });

export const todo = new schema.Entity('todos', { assignee: user }, { idAttribute: '_id' });

export const application = new schema.Entity(
  'applications',
  {
    job,
    user,
    comments: [comment],
  },
  { idAttribute: '_id' },
);
