import { all, fork } from 'redux-saga/effects';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import merge from 'lodash/merge';

import { createReducer } from '@shared/reducers';

import BulkScheduleInterview from './bulk-schedule-interview';
import BulkScheduleInterviewNotificaiton from './bulk-schedule-interview-notification';
import BulkSendEmail from './bulk-send-email';
import BulkUpdateApplicationsAssignments from './bulk-update-applicaitons-assignments';
import BulkUpdateApplicationsJob from './bulk-update-applicaitons-job';
import BulkUpdateApplicationsOwner from './bulk-update-applicaitons-owner';
import BulkUpdateApplicationsStatus from './bulk-update-applicaitons-status';
import DeleteError from './delete-error';
import GetAssignmentOptions from './get-assignments-options';
import GetAvailability from './get-availability';
import GetInterviewOptions from './get-interview-options';
import GetStatuses from './get-statuses';
import MongoSummary from './mongo-summary';
import MongoAssignmentsSummary from './mongo-assignments-summary';
import GetTechTestStatuses from './get-tech-test-statuses';
import NavSearch from './nav-search';
import MongoSearch from './mongo-search';
import ProcessSocketUpdate from './process-socket-update';

const modules = [
  BulkScheduleInterview,
  BulkScheduleInterviewNotificaiton,
  BulkSendEmail,
  BulkUpdateApplicationsAssignments,
  BulkUpdateApplicationsJob,
  BulkUpdateApplicationsOwner,
  BulkUpdateApplicationsStatus,
  DeleteError,
  GetAssignmentOptions,
  GetAvailability,
  GetInterviewOptions,
  GetStatuses,
  MongoSummary,
  GetTechTestStatuses,
  MongoSearch,
  MongoAssignmentsSummary,
  NavSearch,
  ProcessSocketUpdate,
];

// Reducer

export const initialState = {
  isLoadingSearch: true,
  isLoadingSearchSummary: false,
  isLoadingNavSearch: false,
  assignmentOptions: {},
  applicationStatuses: [],
  techTestStatuses: [],
  applicationAvailability: [],
  results: {},
  navResults: {},
  error: null,
  searchSummary: {
    total: 0,
    data: [],
  },
  assignmentsSummary: [],
};

const ACTION_HANDLERS = reduce(map(modules, 'handlers'), merge, {});

export const reducer = createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});

// Actions

export const actions = reduce(map(modules, 'actions'), merge, {});

// Constants
// it's barely used, but we may need to trigger
// a redux action of any other module from saga
// so it's worth to export it for consistency

export const constants = reduce(map(modules, 'constants'), merge, {});

// Root saga
// Export the root saga by forking all available sagas

export function* rootSaga() {
  const sagas = map(modules, 'watcher');
  yield all(sagas.map(fork));
}
