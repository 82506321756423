import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const PersonTagSchema = z.object({
  person_tag_id: z.number(),
  person_tag_mongo_id: z.string().length(24),
  created_by_id: z.string().length(24).nullish(),
  name: z.string(),
  description: z.string().nullable(),
  color: z.string().nullable(),
  cron_created: z.boolean().default(false),
  dt_created: z.union([z.number(), dateString]),
  dt_updated: z.union([z.number(), dateString]),
  dt_deleted: z.union([z.number(), z.string()]).nullish(),
});

export type PersonTag = z.infer<typeof PersonTagSchema>;

export const PersonTagMongoSchema = z.object({
  color: z.string().nullable(),
  createdBy: z.string().nullish(),
  created_at: z.date(),
  cronCreated: z.boolean(),
  deleted: z.boolean(),
  description: z.string(),
  name: z.string(),
  updated_at: z.date(),
  _id: z.string(),
});

export type PersonTagMongo = z.infer<typeof PersonTagMongoSchema>;
