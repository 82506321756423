import request from '../../shared/request';

export function login({ accessToken, fingerprint, code }) {
  return request('/auth/google-token', {
    method: 'POST',
    isAuthV3: true,
    body: {
      access_token: accessToken,
      code,
      fingerprint,
    },
  });
}

export function loginWithToken({ accessToken, refreshToken, fingerprint }) {
  return request('/auth/dyn-env-signin', {
    method: 'POST',
    isAuthV3: true,
    body: {
      access_token: accessToken,
      refresh_token: refreshToken,
      fingerprint,
    },
  });
}

export function mocklogin({ userEmail }) {
  return request(`/auth/mock?user=${userEmail}`, {
    method: 'POST',
  });
}

export function refresh({ refreshToken, fingerprint }) {
  return request('/auth/refresh-token', {
    method: 'POST',
    isAuthV3: true,
    body: {
      refreshToken,
      fingerprint,
    },
  });
}

export function getAccount() {
  return request('/users/account');
}

export function getPermissions() {
  return request('/users/permissions', {
    method: 'GET',
  });
}
