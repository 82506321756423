import * as api from '@redux/api/application';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';

function* getVoiceNoteURL(action) {
  try {
    const voiceNote = yield call(api.getApplicationVoiceNote, action);

    yield put({ type: constants.APPLICATION_VOICE_NOTE_GET.SUCCESS, voiceNote });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_VOICE_NOTE_GET.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchGetVoiceNoteURL() {
  yield takeLatest(constants.APPLICATION_VOICE_NOTE_GET.REQUEST, getVoiceNoteURL);
}
