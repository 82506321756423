import { z } from 'zod';
import { TimeOffRequestCommentCreateSchema } from '../crud';
import { TimeOffRequestComment } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const TimeOffRequestCommentCreateRequestSchema = TimeOffRequestCommentCreateSchema.pick({
  author_id: true,
  comment: true,
  time_off_request_id: true,
});

export type TimeOffRequestCommentCreateRequest = z.infer<
  typeof TimeOffRequestCommentCreateRequestSchema
>;

export type TimeOffRequestCommentCreateResponse = TimeOffRequestComment;

export type TimeOffRequestCommentCreateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/time-off-request-comments`,
  TimeOffRequestCommentCreateResponse,
  undefined,
  undefined,
  TimeOffRequestCommentCreateRequest
>;
