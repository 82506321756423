import { call, put, takeLatest } from 'redux-saga/effects';
import { message as Message } from 'antd';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  GET_APPLICATIONS_BY_ID: createSagaAction('GET_APPLICATIONS_BY_ID'),
};

const actions = {
  getApplications: (applicationsId) => ({
    type: constants.GET_APPLICATIONS_BY_ID.REQUEST,
    applicationsId,
  }),
};

const handlers = {
  [constants.GET_APPLICATIONS_BY_ID.REQUEST]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [constants.GET_APPLICATIONS_BY_ID.SUCCESS]: (state, { applications }) => {
    return {
      ...state,
      applications,
      isLoading: false,
    };
  },
  [constants.GET_APPLICATIONS_BY_ID.FAILURE]: (state, { error }) => {
    Message.error(error);
    return { ...state, isLoading: false };
  },
};

// SAGA

export function* saga({ applicationsId }) {
  try {
    const applications = yield call(api, applicationsId);
    yield put({
      type: constants.GET_APPLICATIONS_BY_ID.SUCCESS,
      applications,
    });
  } catch (e) {
    yield put({
      type: constants.GET_APPLICATIONS_BY_ID.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watcher() {
  yield takeLatest(constants.GET_APPLICATIONS_BY_ID.REQUEST, saga);
}

// API

export function api(applicationsId) {
  return request(`/applications/by-ids/`, {
    method: 'GET',
    qs: { ids: applicationsId },
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher,
};
