import { z } from 'zod';
import { dateString, Endpoint, Method } from '../../infrastructure';

export const SignPositionExtensionRequestSchema = z.object({
  dt_opp_extension_signed: dateString,
});
export type SignPositionExtensionRequest = z.infer<typeof SignPositionExtensionRequestSchema>;

export const SignPositionExtensionParamsSchema = z.object({
  position_id: z.string(),
});
export type SignPositionExtensionParams = z.infer<typeof SignPositionExtensionParamsSchema>;

export type SignPositionExtensionEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/opp-extension/${SignPositionExtensionParams['position_id']}`,
  void,
  SignPositionExtensionParams,
  undefined,
  SignPositionExtensionRequest
>;
