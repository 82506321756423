import { all, fork, call, put, takeEvery } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';

import { constants } from '@redux/modules/job-text-questions';
import * as api from '@redux/api/job-text-questions';
import history from '@src/history';

function* getAll(action) {
  try {
    const { questions, allJobsQuestions } = yield call(api.getAllQuestions, action);
    yield put({ type: constants.JOB_TEXT_QUESTIONS_GET_ALL.SUCCESS, questions, allJobsQuestions });
  } catch (e) {
    yield put({
      type: constants.JOB_TEXT_QUESTIONS_GET_ALL.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchGetAll() {
  yield takeEvery(constants.JOB_TEXT_QUESTIONS_GET_ALL.REQUEST, getAll);
}

function* create(action) {
  try {
    const { job, question } = yield call(api.createQuestion, action.body);
    yield put({ type: constants.JOB_TEXT_QUESTIONS_CREATE.SUCCESS });

    if (job) {
      const { questions, allJobsQuestions } = yield call(api.getAllQuestions, { id: job });
      yield put({
        type: constants.JOB_TEXT_QUESTIONS_GET_ALL.SUCCESS,
        questions,
        allJobsQuestions,
      });

      history.push(`/admin/job/${job}`);
    }

    if (isFunction(action.onSuccess)) {
      action.onSuccess(question);
    }
  } catch (e) {
    yield put({
      type: constants.JOB_TEXT_QUESTIONS_CREATE.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(e.message || e);
    }
  }
}
function* watchCreate() {
  yield takeEvery(constants.JOB_TEXT_QUESTIONS_CREATE.REQUEST, create);
}

function* update(action) {
  try {
    const { job } = yield call(api.updateQuestion, action.body);
    yield put({ type: constants.JOB_TEXT_QUESTIONS_UPDATE.SUCCESS });

    if (job) {
      const { questions, allJobsQuestions } = yield call(api.getAllQuestions, { id: job });
      yield put({
        type: constants.JOB_TEXT_QUESTIONS_GET_ALL.SUCCESS,
        questions,
        allJobsQuestions,
      });

      history.push(`/admin/job/${job}`);
    }
  } catch (e) {
    yield put({
      type: constants.JOB_TEXT_QUESTIONS_UPDATE.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchUpdate() {
  yield takeEvery(constants.JOB_TEXT_QUESTIONS_UPDATE.REQUEST, update);
}

function* deleteQuestion(action) {
  try {
    const { job } = yield call(api.deleteQuestion, action.body);
    yield put({ type: constants.JOB_TEXT_QUESTIONS_DELETE.SUCCESS });
    const { questions, allJobsQuestions } = yield call(api.getAllQuestions, { id: job });
    yield put({ type: constants.JOB_TEXT_QUESTIONS_GET_ALL.SUCCESS, questions, allJobsQuestions });
    history.push(`/admin/job/${job}`);
  } catch (e) {
    yield put({
      type: constants.JOB_TEXT_QUESTIONS_DELETE.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchDelete() {
  yield takeEvery(constants.JOB_TEXT_QUESTIONS_DELETE.REQUEST, deleteQuestion);
}
/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([fork(watchGetAll), fork(watchCreate), fork(watchUpdate), fork(watchDelete)]);
}
