import { call, put, takeEvery } from 'redux-saga/effects';

import { createSagaAction } from '@shared/sagas';
import request from '@shared/request';

import get from 'lodash/get';

//MODULE
const constants = {
  LEAD_GET_LEAD_TIMELINE: createSagaAction('LEAD_GET_LEAD_TIMELINE'),
};

const actions = {
  getLeadTimeline: (leadId, filter) => ({
    type: constants.LEAD_GET_LEAD_TIMELINE.REQUEST,
    leadId,
    filter,
  }),
};

const handlers = {
  [constants.LEAD_GET_LEAD_TIMELINE.REQUEST]: (state) => {
    return { ...state, loadingTimeline: true };
  },
  [constants.LEAD_GET_LEAD_TIMELINE.SUCCESS]: (state, { leadTimeline }) => {
    return { ...state, leadTimeline, loadingTimeline: false };
  },
  [constants.LEAD_GET_LEAD_TIMELINE.FAILURE]: (state) => {
    return { ...state, loadingTimeline: false };
  },
};

//SAGA
function* sagaGetLeadTimeline(action) {
  try {
    const { leadTimeline } = yield call(apiGetLeadTimeline, action);
    yield put({
      type: constants.LEAD_GET_LEAD_TIMELINE.SUCCESS,
      leadTimeline,
    });
  } catch (e) {
    yield put({
      type: constants.LEAD_GET_LEAD_TIMELINE.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetLeadTimeline() {
  yield takeEvery(constants.LEAD_GET_LEAD_TIMELINE.REQUEST, sagaGetLeadTimeline);
}

//API
async function apiGetLeadTimeline(action) {
  const leadId = get(action, 'leadId');
  const filter = get(action, 'filter');
  if (!leadId) {
    return null;
  }
  const url = `/leads/${leadId}/timeline`;
  return request(url, {
    method: 'GET',
    qs: { filter },
  });
}

export default {
  actions,
  constants,
  handlers,
  watcher: watchGetLeadTimeline,
};
