import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  CALENDLY_USER_TYPES_LIST: createSagaAction('CALENDLY_USER_TYPES_LIST'),
  CALENDLY_USER_TYPES_CREATE: createSagaAction('CALENDLY_USER_TYPES_CREATE'),
  CALENDLY_USER_TYPES_UPDATE: createSagaAction('CALENDLY_USER_TYPES_UPDATE'),
  CALENDLY_USER_TYPES_DELETE: createSagaAction('CALENDLY_USER_TYPES_DELETE'),
};

// Action Creators
export const actions = {
  getCalendlyUserTypes: () => ({
    type: constants.CALENDLY_USER_TYPES_LIST.REQUEST,
  }),
  createCalendlyUserType: (args) => ({
    type: constants.CALENDLY_USER_TYPES_CREATE.REQUEST,
    ...args,
  }),
  updateCalendlyUserType: (args) => ({
    type: constants.CALENDLY_USER_TYPES_UPDATE.REQUEST,
    ...args,
  }),
  deleteCalendlyUserType: (args) => ({
    type: constants.CALENDLY_USER_TYPES_DELETE.REQUEST,
    ...args,
  }),
};

// Reducer
export const initialState = {
  isLoading: false,
  calendlyUserTypes: [],
  error: null,
};

const ACTION_HANDLERS = {
  [constants.CALENDLY_USER_TYPES_LIST.REQUEST]: (state) => {
    return { ...state };
  },
  [constants.CALENDLY_USER_TYPES_LIST.SUCCESS]: (state, { calendlyUserTypes }) => {
    return { ...state, calendlyUserTypes };
  },
  [constants.CALENDLY_USER_TYPES_LIST.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },

  [constants.CALENDLY_USER_TYPES_CREATE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },

  [constants.CALENDLY_USER_TYPES_UPDATE.SUCCESS]: (state, { updatedCalendlyUserType }) => {
    const idx = state.calendlyUserTypes.findIndex(
      (item) => item._id === updatedCalendlyUserType._id,
    );
    if (idx >= 0) {
      const newCalendlyUserTypes = [...state.calendlyUserTypes];
      newCalendlyUserTypes[idx] = { ...updatedCalendlyUserType };

      return {
        ...state,
        calendlyUserTypes: [...newCalendlyUserTypes],
      };
    }
    return { ...state };
  },
  [constants.CALENDLY_USER_TYPES_UPDATE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },

  [constants.CALENDLY_USER_TYPES_DELETE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
