import { z } from 'zod';
import { dateString } from '../../infrastructure';

export enum ApplicantSalaryCurrency {
  'USD' = 'USD',
  'CAD' = 'CAD',
}

export enum ApplicantSenionity {
  'N/A' = '0',
  'Super-Senior' = '1',
  'Senior' = '2',
  'Mid-Senior' = '3',
  'Mid' = '4',
  'Junior' = '5',
  'Super-Junior' = '6',
}

export enum ApplicantEnglishLevel {
  'N/A' = '0',
  'Native' = '1',
  'Accent, professional English (no missed words), good speed' = '2',
  'Better than passable, not quite professional' = '2.5',
  'Accent, slow English, no missing words' = '3',
  'Accent, missing words' = '4',
  'Accent, slow, missing words, struggling' = '5',
}

export const ApplicantSchema = z.object({
  applicant_id: z.string(),

  //Basic information
  email: z.string(),
  name: z.string(),
  title: z.string().nullable(),
  degree_year: z.number().nullable(),
  english_level: z.string().nullable(), //z.nativeEnum(ApplicantEnglishLevel).nullable(),
  native_english: z.boolean().nullable(),
  seniority: z.string().nullable(), //z.nativeEnum(ApplicantSenionity).nullable(),
  salary_currency: z.string().nullable(), //z.nativeEnum(ApplicantSalaryCurrency).default(ApplicantSalaryCurrency['USD']),
  referred_by: z.string().nullable(),

  //URLS
  linkedin_url: z.string().nullable(),
  github_url: z.string().nullable(),
  zoom_url: z.string().nullable(),

  //Addres information
  country: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  longitude: z.string().nullable(),
  latitude: z.string().nullable(),
  timezone: z.string().nullable(),
  phone: z.string().nullable(),

  //System control fields
  brains_id: z.string().nullable(),
  slack_id: z.string().nullable(),
  slack_internal_id: z.string().nullable(),

  //Date controll

  created_at: dateString,
  updated_at: dateString,
});
export type Applicant = z.infer<typeof ApplicantSchema>;
