import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { Flex, Typography, notification as AntDNotification } from 'antd';
import NotificationCard from './NotificationCard';

import { ActionRequest } from '@ct-internal/api';
import { MY_NOTIFICATIONS_KEY, useNotifications } from '@src/queries/Notification';
import { useSocketListener } from '@src/shared/hooks';

import { NotificationMessage } from './NotificationTypes';
import './index.less';
import { useSelector } from 'react-redux';

interface NotificationsProps {
  showDrawer: () => void;
}

const Notifications = ({ showDrawer }: NotificationsProps) => {
  const queryClient = useQueryClient();

  const user = useSelector((store: any) => store.authentication.user);

  const { data: notifications } = useNotifications({
    query: {
      sorter: [['dt_request', 'desc']],
    },
  });

  const handleSocketEvent = useCallback(
    (context: ActionRequest) => {
      if (context.email === user?.email) {
        queryClient.refetchQueries([MY_NOTIFICATIONS_KEY]);
        AntDNotification.success({
          message: 'New action requested',
          description: NotificationMessage[context.slug]?.(context) ?? '',
          placement: 'topRight',
          onClick: () => {
            showDrawer();
            AntDNotification.destroy();
          },
        });
      }
    },
    [queryClient, showDrawer, user?.email],
  );

  useSocketListener('action-request:created', handleSocketEvent);

  const groupedNotifications = useMemo(() => {
    return (
      notifications?.rows.reduce((acc, notification) => {
        const groupName = notification.group || 'Other';
        if (!acc[groupName]) {
          acc[groupName] = [];
        }
        acc[groupName].push(notification);
        return acc;
      }, {} as Record<string, ActionRequest[]>) ?? []
    );
  }, [notifications]);

  return (
    <Flex vertical gap={8} className="notifications-container">
      {Object.entries(groupedNotifications).map(([contextName, notifications]) => (
        <Flex vertical gap={8} key={contextName}>
          <Typography.Text type="secondary">{contextName}</Typography.Text>
          {notifications.map((notification) => (
            <NotificationCard key={notification.action_request_id} notification={notification} />
          ))}
        </Flex>
      ))}
    </Flex>
  );
};

export default Notifications;
