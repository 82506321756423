import React, { Suspense } from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react';
import map from 'lodash/map';
import get from 'lodash/get';

import history from '@src/history';

import App from '@containers/App/App';
import EnsureAuthenticatedUser from '@containers/EnsureAuthenticatedUser/EnsureAuthenticatedUser';

import Home from '@containers/Home/Home';

import NoMatch from '@components/NoMatch/NoMatch';
import MockLogin from '@containers/MockLogin/MockLogin';
import LoadingSpinner from '@components/Shared/LoadingSpinner/LoadingSpinner';

import { useSelector, useDispatch } from 'react-redux';

import { adminRoutes, techRoutes, salesRoutes } from './navigation-routes';
import { actions as authActions } from '@redux/modules/authentication';
import useUserFeatureFlags from '@hooks/useUserFeatureFlags';
import config from '@src/config';
import { useDynamicEnvAuth } from './hooks/useDynamicEnvAuth';

const env = process.env.NODE_ENV || 'development';

const DefaultRouteAdmin = () => <ApmRoute render={() => <Redirect to={'/admin/dashboard'} />} />;

const DefaultRouteTech = () => <ApmRoute render={() => <Redirect to={'/tech/dashboard'} />} />;

const DefaultRouteSales = () => <ApmRoute render={() => <Redirect to={'/sales/positions'} />} />;

const getAuthorizedRoutes = (userIsAdmin, userIsTech, userIsSalesInterviewer) => {
  if (userIsAdmin) {
    return [...adminRoutes, ...techRoutes];
  }

  if (userIsTech) {
    return techRoutes;
  }

  if (userIsSalesInterviewer) {
    return salesRoutes;
  }

  return [];
};

const useUserWithRoutes = () => {
  const user = useSelector((state) => state.authentication.user);

  const userIsAdmin = get(user, 'isAdmin');
  const userIsTech = get(user, 'isTechRecruiter');
  const userIsSalesInterviewer = get(user, 'isSalesInterviewer');
  const userIsSalesFullInterviewer = get(user, 'isSalesFullInterviewer');
  const userIsSales = userIsSalesInterviewer || userIsSalesFullInterviewer;

  const authorizedRoutes = getAuthorizedRoutes(userIsAdmin, userIsTech, userIsSales);

  return {
    userIsAdmin,
    userIsTech,
    userIsSales,
    userIsSalesInterviewer,
    userIsSalesFullInterviewer,
    authorizedRoutes,
  };
};

const ApplicationRouter = () => {
  const { userIsAdmin, userIsTech, userIsSales, authorizedRoutes } = useUserWithRoutes();
  const dispatch = useDispatch();

  useDynamicEnvAuth({
    dispatch,
    loginAction: authActions.loginWithToken,
    frontendBase: config.FRONTEND_BASE,
  });
  useUserFeatureFlags();

  return (
    <>
      <ApmRoute path="/" children={<Home />} exact />
      {(env.includes('test') || env === 'development') && (
        <ApmRoute path="/mocklogin/:userEmail" children={<MockLogin />} exact />
      )}

      <EnsureAuthenticatedUser>
        <Suspense fallback={<LoadingSpinner />}>
          <Switch>
            {map(authorizedRoutes, (item) => (
              <ApmRoute key={item.path} {...item} />
            ))}

            {!userIsAdmin && userIsTech && (
              <ApmRoute
                key="/admin/application/:applicationId"
                path="/admin/application/:applicationId"
                exact={true}
                render={(props) => {
                  const applicationId = get(props, 'match.params.applicationId');
                  return <Redirect to={`/tech/application/${applicationId}`} />;
                }}
              />
            )}

            {!userIsTech && userIsSales && (
              <ApmRoute
                key="/tech/application/:applicationId"
                path="/tech/application/:applicationId"
                exact={true}
                render={(props) => {
                  const applicationId = get(props, 'match.params.applicationId');
                  return <Redirect to={`/sales/application/${applicationId}`} />;
                }}
              />
            )}

            {!userIsAdmin && userIsSales && (
              <ApmRoute
                key="/admin/application/:applicationId"
                path="/admin/application/:applicationId"
                exact={true}
                render={(props) => {
                  const applicationId = get(props, 'match.params.applicationId');
                  return <Redirect to={`/sales/application/${applicationId}`} />;
                }}
              />
            )}

            {userIsAdmin && <DefaultRouteAdmin />}
            {userIsTech && <DefaultRouteTech />}
            {userIsSales && <DefaultRouteSales />}
            <ApmRoute children={<NoMatch />} />
          </Switch>
        </Suspense>
      </EnsureAuthenticatedUser>
    </>
  );
};

export default () => {
  return (
    <Router history={history}>
      <App>
        <Switch>
          <ApplicationRouter />
        </Switch>
      </App>
    </Router>
  );
};
