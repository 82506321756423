import { z } from 'zod';
import { BaseSchemaDW } from '../structure';

export const UtmSourceDWSchema = z
  .object({
    source_id: z.number().nullable(),
    source_name: z.string(),
  })
  .merge(BaseSchemaDW);
export type UtmSourceDW = z.infer<typeof UtmSourceDWSchema>;
