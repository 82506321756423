/* eslint-disable max-statements */
import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';
import { statusesQuickOptions } from '@shared/Util';

import values from 'lodash/values';
import map from 'lodash/map';
import zipObject from 'lodash/zipObject';
import mapValues from 'lodash/mapValues';
import defaults from 'lodash/defaults';
import clone from 'lodash/clone';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import forIn from 'lodash/forIn';
import includes from 'lodash/includes';
import findIndex from 'lodash/findIndex';
import keyBy from 'lodash/keyBy';
import assign from 'lodash/assign';
import omit from 'lodash/omit';
import reject from 'lodash/reject';
import find from 'lodash/find';
import extend from 'lodash/extend';

export const ACTION_MAP = {
  // api-function-name: saga-action-name (must be unique through the app)
  getLeads: 'ASSIGNMENTS_REPORT__LEADS__GET',
  getLeadByID: 'ASSIGNMENTS_REPORT__LEAD_BY_ID__GET',
  removeLeadByID: 'ASSIGNMENTS_REPORT__LEAD_BY_ID__REMOVE',
  stopSpinner: 'STOP_SPINNER',
  startSpinner: 'START_SPINNER',
  changeLeadPinnedComment: 'ASSIGNMENTS_REPORT__LEAD_CHANGED_PINNED_COMMENT',
  changeAssignmentPinnedComment: 'ASSIGNMENTS_REPORT__ASSIGNMENT_CHANGED_PINNED_COMMENT',
  openLeadNameEditModal: 'LEAD_NAME_EDIT_MODAL_OPEN',
  updateClientAndProjectNotes: 'CLIENT_AND_PROJECT_NOTES_UPDATE',
  openAssignmentsModal: 'ASSIGNMENTS_MODAL_OPEN',
  closeLeadNameEditModal: 'LEAD_NAME_EDIT_MODAL_CLOSE',
  closeAssignmentsModal: 'ASSIGNMENTS_MODAL_CLOSE',
  updateLeadName: 'LEAD_NAME_UPDATE',
  updateAssignment: 'APPLICATION_USER_ASSIGNMENT_UPDATE',
  reloadAssignmentsState: 'RELOAD_APPLICATION_USER_ASSIGNMENTS',
  updateAssignmentsReportData: 'ASSIGNMENTS_REPORT__ASSIGNMENTS__MERGE',
  updateAssignmentsFilters: 'ASSIGNMENTS_FILTERS_UPDATE',
  getStatusApplications: 'APPLICATION_GET_STATUS_APPLICATIONS',
  assignOnBrains: 'APPLICATION_PUT_POSITION_BRAINS',
  assignFromBrains: 'ASSIGNMENTS_REPORT_APPLICATION_BRAINS_ASSIGNMENTS_UPDATE',
  assignPersonOrApplicationOnBrains: 'PERSON_OR_APPLICATION_BRAINS_ASSIGNMENT',
};

const _keys = values(ACTION_MAP);
const _values = map(_keys, (key) => createSagaAction(key));

// Constants
export const constants = zipObject(_keys, _values);

// Action Creators
export const actions = mapValues(ACTION_MAP, (key) => {
  return (data) => defaults({ type: constants[key].REQUEST }, data);
});

// Reducer
export const initialState = {
  positionAssignmentsMap: {},
  leads: [],
  summary: {},
  isLoading: false,
  callReload: false,
  error: null,
  isLeadNameEditModalOpen: false,
  clientNotes: null,
  projectNotes: null,
  projectId: null,
  currentProjectHasClient: null,
  isAssignmentsModalOpen: false,
  isCommentsModalOpen: false,
  currentLeadShortNote: '',
  currentSkillShortNote: '',
  currentAssignmentId: null,
  currentApplication: null,
  leadNameUpdateSuccess: false,
  assignmentsUpdateSuccess: false,
  fullInfoLeads: {},
  statusApplications: {},
  applicationsContractsInformation: {},
  filters: {
    applications_excitement_from: '',
    applications_score_from: '',
    applications_salary_from: '',
    applications_salary_to: '',
    applications_yoe_from: '',
    applications_yoe_to: '',
    applications_option_for_assignment: '',
    countries: [],
    skills: undefined,
    englishLevels: [],
    statuses: statusesQuickOptions['Full Pipe'],
  },
};

const ACTION_HANDLERS = {
  [constants.RELOAD_APPLICATION_USER_ASSIGNMENTS.REQUEST]: (state, action) => {
    const newPosMap = clone(state.positionAssignmentsMap);
    const app = action.application;

    if (app) {
      each(isEmpty(app.assignments) ? [] : app.assignments, (assng) => {
        if (newPosMap[assng.position]) {
          remove(newPosMap[assng.position], (posMap) => posMap._id === assng._id);
        }
        return { id: assng._id, position: assng.position };
      });
      forIn(isEmpty(action.updatedApplications) ? {} : action.updatedApplications, (value, key) => {
        if (value._id && app._id && value.id === app._id) {
          app.assignments = value.assignments;
          each(isEmpty(app.assignments) ? [] : app.assignments, (assignment) => {
            assignment.application = app;
            if (!newPosMap[assignment.position]) {
              newPosMap[assignment.position] = [];
            }
            newPosMap[assignment.position].push(assignment);
          });
        }
      });
    }

    return {
      ...state,
      positionAssignmentsMap: newPosMap,
    };
  },

  [constants.ASSIGNMENTS_REPORT__LEAD_CHANGED_PINNED_COMMENT.REQUEST]: (
    state,
    { lead, comment },
  ) => {
    return {
      ...state,
      fullInfoLeads: {
        ...state.fullInfoLeads,
        [lead]: {
          ...state.fullInfoLeads[lead],
          pinnedComment: comment,
        },
      },
    };
  },

  [constants.ASSIGNMENTS_REPORT__ASSIGNMENT_CHANGED_PINNED_COMMENT.REQUEST]: (
    state,
    { comment, leadId, skillId, position },
  ) => {
    return {
      ...state,
      leads: map(state.leads, (lead) => {
        if (lead._id === leadId) {
          each(lead.positions, (s) => {
            if (s.skillId === skillId && s._id === position) {
              s.pinnedComment = comment;
            }
          });
        }
        return lead;
      }),
    };
  },

  [constants.APPLICATION_USER_ASSIGNMENT_UPDATE.REQUEST]: (state, action) => {
    const newPosMap = clone(state.positionAssignmentsMap);

    if (
      !isEmpty(action.optionsForAssignmentFilter) &&
      !includes(action.optionsForAssignmentFilter, action.assignment.optionForAssignment)
    ) {
      newPosMap[action.assignment.position].splice(action.index, 1);
    } else {
      const appIdx = findIndex(
        newPosMap[action.assignment.position],
        (apps) => apps.application && apps.application._id === action.applicationId,
      );

      if (appIdx >= 0) {
        newPosMap[action.assignment.position][appIdx].optionForAssignment = Number(
          action.assignment.optionForAssignment,
        );

        const assgs = newPosMap[action.assignment.position][appIdx].application.assignments;
        for (let i = 0; i < assgs.length; i++) {
          if (assgs[i]._id !== action.assignment._id) {
            continue;
          }
          newPosMap[action.assignment.position][appIdx].application.assignments[
            i
          ].optionForAssignment = Number(action.assignment.optionForAssignment);
        }
      }
    }

    return {
      ...state,
      positionAssignmentsMap: newPosMap,
    };
  },
  [constants.LEAD_NAME_EDIT_MODAL_OPEN.REQUEST]: (state, action) => {
    return {
      ...state,
      isLeadNameEditModalOpen: true,
      currentLeadShortNote: action.leadShortNote,
      currentSkillShortNote: action.skillShortNote,
      currentLeadId: action.leadId,
      currentSkillId: action.skillId,
      leadNameUpdateSuccess: false,
    };
  },
  [constants.LEAD_NAME_EDIT_MODAL_CLOSE.REQUEST]: (state) => {
    return {
      ...state,
      isLeadNameEditModalOpen: false,
      currentLeadShortNote: '',
      currentSkillShortNote: '',
      currentLeadId: null,
      currentSkillId: null,
    };
  },
  [constants.ASSIGNMENTS_MODAL_OPEN.REQUEST]: (state, action) => {
    return {
      ...state,
      isAssignmentsModalOpen: true,
      currentApplication: action.application,
      assignmentsUpdateSuccess: false,
    };
  },
  [constants.ASSIGNMENTS_MODAL_CLOSE.REQUEST]: (state) => {
    return {
      ...state,
      currentApplication: null,
      isAssignmentsModalOpen: false,
    };
  },
  [constants.START_SPINNER.REQUEST]: (state) => {
    return {
      ...state,
      callReload: true,
    };
  },
  [constants.STOP_SPINNER.REQUEST]: (state) => {
    return {
      ...state,
      callReload: false,
    };
  },
  [constants.LEAD_NAME_UPDATE.SUCCESS]: (state, { leads, leadNameUpdateSuccess }) => {
    return {
      ...state,
      leadNameUpdateSuccess,
      leads,
    };
  },
  [constants.APPLICATION_GET_STATUS_APPLICATIONS.SUCCESS]: (state, { statusApplications }) => {
    const stateStatusAppsCopy = clone(state.statusApplications);
    // eslint-disable-next-line no-param-reassign
    statusApplications = keyBy(statusApplications, 'brainsId');

    assign(stateStatusAppsCopy, statusApplications);

    return { ...state, statusApplications: stateStatusAppsCopy };
  },
  [constants.ASSIGNMENTS_REPORT__LEAD_BY_ID__GET.SUCCESS]: (state, { fullInfoLead }) => {
    const fullInfoLeads = clone(state.fullInfoLeads);

    fullInfoLeads[fullInfoLead._id] = fullInfoLead;

    return { ...state, fullInfoLeads };
  },
  [constants.ASSIGNMENTS_REPORT__LEAD_BY_ID__REMOVE.SUCCESS]: (state, { leadId }) => {
    const fullInfoLeads = omit(state.fullInfoLeads, leadId);
    const leads = reject(state.leads, { _id: leadId });

    return { ...state, leads, fullInfoLeads };
  },
  [constants.ASSIGNMENTS_REPORT__ASSIGNMENTS__MERGE.REQUEST]: (state, action) => {
    const newPosMap = clone(state.positionAssignmentsMap);
    const newSkillsMap = clone(state.skillAssignmentsMap);
    const app = (action && action.application && action.application.application) || undefined;
    if (!app) {
      return;
    }

    const applicationStatuses = (action && action.applicationStatuses) || [];
    const statuses = (state && state.filters && state.filters.statuses) || [];
    const appStatus = find(applicationStatuses, { status: app.status });
    const assignments = isEmpty(app.assignments) ? [] : app.assignments;

    // SKILLS MAP
    each(newSkillsMap, (skillMap) => {
      each(skillMap, (skill) => {
        if (!skill) {
          return;
        }
        remove(newSkillsMap[skill.skill], (m) => m.application._id === app._id);
      });
    });

    // POSITION MAP
    each(newPosMap, (posMap) => {
      each(posMap, (assignment) => {
        if (!assignment) {
          return;
        }
        remove(newPosMap[assignment.position], (m) => m.application._id === app._id);
      });
    });

    const aggregates = applicationStatuses.filter(
      (item) =>
        item.aggregates &&
        item.aggregates.includes(app.status) &&
        statuses &&
        statuses.indexOf(item.id) >= 0,
    );

    if (
      statuses &&
      appStatus &&
      !statuses.includes(appStatus.id) &&
      appStatus.optgroup &&
      appStatus.optgroup.toLowerCase &&
      !statuses.includes(appStatus.optgroup.toLowerCase()) &&
      (!aggregates || aggregates.length <= 0)
    ) {
      return {
        ...state,
        positionAssignmentsMap: newPosMap,
        skillAssignmentsMap: newSkillsMap,
      };
    }

    // POSITION MAP
    each(assignments, (assng) => {
      if (!newPosMap || !assng) {
        return;
      }

      if (!newPosMap[assng.position]) {
        extend(newPosMap, { [assng.position]: [] });
      }

      if (newPosMap[assng.position]) {
        const index = newPosMap[assng.position].findIndex(
          (item) => item.application._id === app._id,
        );

        if (index <= -1) {
          newPosMap[assng.position].push(extend({ application: app }, assng));
        } else {
          if (newPosMap[assng.position][index] && newPosMap[assng.position][index].application) {
            newPosMap[assng.position][index].application = app;
          }
        }
      }
    });

    return {
      ...state,
      positionAssignmentsMap: newPosMap,
      skillAssignmentsMap: newSkillsMap,
    };
  },
  [constants.ASSIGNMENTS_FILTERS_UPDATE.REQUEST]: (state, { filters }) => {
    return {
      ...state,
      filters: { ...state.filters, ...filters },
    };
  },
};

_setAction(ACTION_MAP.getLeads, {
  successKey: 'leads',
});

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});

/*
  // _setAction is helper function, it maybe worth to refactor it to OOP tho
  // example:

  _setAction('RECENT_GET', { successKey: 'recent' });

  // this call ↑ would be converted into this:

  ACTION_HANDLERS[constants.RECENT_GET.SUCCESS] = (state, { recent }) => ({
    ...state, recent
  })
  ACTION_HANDLERS[constants.RECENT_GET.FAILURE] = (state, { error }) => ({
    ...state, error
  })
*/
function _setAction(key, opts) {
  const actionKey = constants[key];

  each(
    {
      SUCCESS: opts.successKey || 'data',
      FAILURE: opts.failureKey || 'error',
    },
    (dataKey, stateKey) => {
      ACTION_HANDLERS[actionKey[stateKey]] = (state, data) => ({
        ...state,
        [dataKey]: data[dataKey],
      });
    },
  );
}
