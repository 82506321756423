import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { CarryoverStatus } from './base';

export const GetTimeOffCarryoverStatusParamsSchema = z.object({
  personId: z.string(),
});
export type GetTimeOffCarryoverStatusParams = z.infer<typeof GetTimeOffCarryoverStatusParamsSchema>;

export type GetTimeOffCarryoverStatusResponse = CarryoverStatus;

export type GetTimeOffCarryoverStatusEndpoint = Endpoint<
  Method.GET,
  `/api/v2/person/${GetTimeOffCarryoverStatusParams['personId']}/timeoffs/carryover-status`,
  GetTimeOffCarryoverStatusResponse,
  GetTimeOffCarryoverStatusParams,
  undefined,
  undefined
>;
