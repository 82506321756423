import { Endpoint, Method } from '../../infrastructure';
import type { PositionPostgres } from './base';
import { z } from 'zod';

export const UpdatePositionIsOpenToSalesRequestSchema = z.object({
  is_open_to_sales: z.boolean(),
});

export const UpdatePositionIsOpenToSalesParamsSchema = z.object({
  position_id: z.string(),
});

export type UpdatePositionIsOpenToSalesResponse = PositionPostgres | null;

export type UpdatePositionIsOpenToSalesParams = z.infer<
  typeof UpdatePositionIsOpenToSalesParamsSchema
>;

export type UpdatePositionIsOpenToSalesQuery = undefined;

export type UpdatePositionIsOpenToSalesRequest = z.infer<
  typeof UpdatePositionIsOpenToSalesRequestSchema
>;

export type UpdatePositionIsOpenToSalesEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/positions/${UpdatePositionIsOpenToSalesParams['position_id']}/is-open-to-sales`,
  UpdatePositionIsOpenToSalesResponse,
  UpdatePositionIsOpenToSalesParams,
  UpdatePositionIsOpenToSalesQuery,
  UpdatePositionIsOpenToSalesRequest
>;
