import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  QUESTIONS_GET_ALL: createSagaAction('QUESTIONS_GET_ALL'),
  QUESTIONS_CREATE: createSagaAction('QUESTIONS_CREATE'),
  QUESTIONS_UPDATE: createSagaAction('QUESTIONS_UPDATE'),
  QUESTIONS_DELETE: createSagaAction('QUESTIONS_DELETE'),
  QUESTIONS_UPLOAD_VIDEO: createSagaAction('QUESTIONS_UPLOAD_VIDEO'),
};

// Action Creators
export const actions = {
  getAllQuestions: () => ({
    type: constants.QUESTIONS_GET_ALL.REQUEST,
  }),
  createQuestion: (args) => ({
    type: constants.QUESTIONS_CREATE.REQUEST,
    ...args,
  }),
  updateQuestion: (id, name, helper, sort) => ({
    type: constants.QUESTIONS_UPDATE.REQUEST,
    id,
    name,
    helper,
    sort,
  }),
  deleteQuestion: (id) => ({
    type: constants.QUESTIONS_DELETE.REQUEST,
    id,
  }),
  uploadVideo: (args) => ({
    type: constants.QUESTIONS_UPLOAD_VIDEO.REQUEST,
    ...args,
  }),
};

// Reducer
export const initialState = {
  isLoading: false,
  error: null,
  questions: [],
};

const ACTION_HANDLERS = {
  [constants.QUESTIONS_GET_ALL.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.QUESTIONS_GET_ALL.SUCCESS]: (state, { questions }) => {
    return { ...state, questions, isLoading: false };
  },
  [constants.QUESTIONS_GET_ALL.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.QUESTIONS_CREATE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.QUESTIONS_UPDATE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.QUESTIONS_DELETE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.QUESTIONS_UPLOAD_VIDEO.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
