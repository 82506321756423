import { z } from 'zod';
import { PaymentProviderMonthSchema } from './base';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';

export const ListPPMSchema = PaymentProviderMonthSchema.extend({
  payment_provider_name: z.string(),
});
export type ListPPM = z.infer<typeof ListPPMSchema>;

export const ListPPMFiltersSchema = z.object({
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
});
export type ListPPMFilters = z.infer<typeof ListPPMFiltersSchema>;

export const ListPPMQuerySchema = z.object({
  filters: ListPPMFiltersSchema.optional(),
});
export type ListPPMQuery = z.infer<typeof ListPPMQuerySchema>;

export type ListPPMResponse = ListPPM[];

export type ListPPMEndpoint = Endpoint<
  Method.GET,
  '/api/v2/payment-provider-months/',
  ListPPMResponse,
  undefined,
  ListPPMQuery,
  undefined
>;
