import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  DRIP_CAMPAIGNS_GET: createSagaAction('DRIP_CAMPAIGNS_GET'),
};

// Action Creators
export const actions = {
  getDripCampaigns: (args) => ({
    type: constants.DRIP_CAMPAIGNS_GET.REQUEST,
    ...args,
  }),
};

// Reducer
export const initialState = {
  isLoading: false,
  error: null,
  dripCampaigns: [],
};

const ACTION_HANDLERS = {
  // ALL
  [constants.DRIP_CAMPAIGNS_GET.REQUEST]: (state) => {
    return { ...state, isLoading: true, error: null };
  },
  [constants.DRIP_CAMPAIGNS_GET.SUCCESS]: (state, { dripCampaigns }) => {
    return { ...state, dripCampaigns, isLoading: false, error: null };
  },
  [constants.DRIP_CAMPAIGNS_GET.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
