import { z } from 'zod';
import { dateString, FilterOperations, ReportsQuerySchema, makeSorter } from '../../infrastructure';
import { BusinessEventSchema } from './base';

export const ListBusinessEventsSchema = BusinessEventSchema.pick({
  event_id: true,
  metadata: true,
  is_salesforce: true,
  created_at: true,
}).extend({
  project_id: z.string(),
  project_name: z.string(),
  project_short_name: z.string(),

  client_id: z.string(),
  client_name: z.string(),
  client_short_name: z.string(),

  action_id: z.number(),
  action_name: z.string(),
  action_entity: z.string(),
  action_description: z.string().nullable(),

  author_id: z.string().nullable(),
  author_name: z.string().nullable(),
  author_gravatar_url: z.string().nullable(),

  position_id: z.string().nullable(),
  position_name: z.string().nullable(),

  assigned_person_id: z.string().nullable(),
  assigned_person_name: z.string().nullable(),
  assigned_person_gravatar_url: z.string().nullable(),

  changes: z.array(
    z.object({
      name: z.string(),
      old: z.string().nullable(),
      new: z.string().nullable(),
      message: z.string(),
    }),
  ),
});

export type ListBusinessEvents = z.infer<typeof ListBusinessEventsSchema>;

export const ListBusinessEventsFiltersSchema = z
  .object({
    actionId: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN]),
      z.string().or(z.array(z.string())),
    ),
    assignmentId: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN]),
      z.string().or(z.array(z.string())),
    ),
    projectId: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN]),
      z.string().or(z.array(z.string())),
    ),
    positionId: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN]),
      z.string().or(z.array(z.string())),
    ),
    clientId: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN]),
      z.string().or(z.array(z.string())),
    ),
    isSalesforce: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN]),
      z.string().or(z.array(z.string())),
    ),
    startDate: z.record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), dateString),
    endDate: z.record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), dateString),
    authorId: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN]),
      z.string().or(z.array(z.string())),
    ),
    personId: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN]),
      z.string().or(z.array(z.string())),
    ),
  })
  .partial();
export type ListBusinessEventsFilters = z.infer<typeof ListBusinessEventsFiltersSchema>;

export const ListBusinessEventsSorterSchema = makeSorter(
  z.enum(['created_at', 'author_name', 'action_description', 'project_name']),
);

export type ListBusinessEventsSorter = z.infer<typeof ListBusinessEventsSorterSchema>;

export const ListBusinessEventsQuerySchema = ReportsQuerySchema.extend({
  filters: ListBusinessEventsFiltersSchema.optional(),
  sorter: ListBusinessEventsSorterSchema.optional(),
});
export type ListBusinessEventsQuery = z.infer<typeof ListBusinessEventsQuerySchema>;
