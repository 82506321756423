import { call, put, takeLatest } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  BULK_SCHEDULE_INTERVIEW_NOTIFICATION: createSagaAction('BULK_SCHEDULE_INTERVIEW_NOTIFICATION'),
};

const actions = {
  bulkScheduleInterviewNotification: (data) => ({
    ...data,
    type: constants.BULK_SCHEDULE_INTERVIEW_NOTIFICATION.REQUEST,
  }),
};

const handlers = {
  [constants.BULK_SCHEDULE_INTERVIEW_NOTIFICATION.REQUEST]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [constants.BULK_SCHEDULE_INTERVIEW_NOTIFICATION.SUCCESS]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [constants.BULK_SCHEDULE_INTERVIEW_NOTIFICATION.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

function* BulkScheduleInterviewNotification(action) {
  try {
    yield call(apiBulkScheduleInterviewNotification, action);
    yield put({
      type: constants.BULK_SCHEDULE_INTERVIEW_NOTIFICATION.SUCCESS,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.BULK_SCHEDULE_INTERVIEW_NOTIFICATION.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onError)) {
      yield action.onError();
    }
  }
}

export function* watchBulkScheduleInterviewNotification() {
  yield takeLatest(
    constants.BULK_SCHEDULE_INTERVIEW_NOTIFICATION.REQUEST,
    BulkScheduleInterviewNotification,
  );
}

// API

export function apiBulkScheduleInterviewNotification({ applicationsIds, directedAt, isUrgent }) {
  const promises = applicationsIds.map((applicationId) => {
    return request(`/applications/${applicationId}/notifications`, {
      method: 'POST',
      body: {
        directedAt,
        isUrgent,
      },
    });
  });

  return Promise.all(promises);
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchBulkScheduleInterviewNotification,

  // optionally we may
  // api: {
  //   getAccount: apiGetAccount
  // }
};
