import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerReducer } from 'react-router-redux';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import maxBy from 'lodash/maxBy';
import pick from 'lodash/pick';
import set from 'lodash/set';
import throttle from 'lodash/throttle';

import reducers from '@redux/modules';
import rootSaga from '@redux/sagas';

import { initialState as clearPageState } from './modules/page-state';

export function loadState(isCreatingStore = false) {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }

    if (isCreatingStore) {
      const latestHiresDetails = get(parsedJson, 'pageState.late-stages', {});

      if (!isEmpty(latestHiresDetails)) {
        const initialPageState = set(clearPageState, 'late-stages', latestHiresDetails);
        parsedJson.pageState = initialPageState;
      }
    }

    const parsedJson = JSON.parse(serializedState);

    return parsedJson;
  } catch (err) {
    return undefined;
  }
}

export function saveState({ authentication, fingerprint, pageState }) {
  const pageStateToSave = pick(pageState, ['late-stages']);
  try {
    if (authentication.user) {
      const sharedUser = get(loadState(), 'authentication.user');
      authentication.user = maxBy([authentication.user, sharedUser], 'expires');
    }

    localStorage.setItem('state', JSON.stringify({ authentication, pageState: pageStateToSave }));
  } catch (err) {
    console.error('Error storing redux state in localStorage', err);
  }
}

// Initialize the Redux set up
const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 1000,
    })) ||
  compose;
const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  combineReducers({ ...reducers, routing: routerReducer }),
  loadState(),
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000),
);

// Run the saga now
sagaMiddleware.run(rootSaga);

export function getUser() {
  return get(store.getState(), 'authentication.user');
}

export function getFingerprint() {
  return get(store.getState(), 'authentication.fingerprint');
}
