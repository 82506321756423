import { call, put, takeLatest } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  NAV_SEARCH_GET: createSagaAction('NAV_SEARCH_GET'),
};

const actions = {
  navSearch: (data) => ({
    ...data,
    type: constants.NAV_SEARCH_GET.REQUEST,
  }),
};

const handlers = {
  [constants.NAV_SEARCH_GET.REQUEST]: (state, action) => {
    return { ...state, isLoadingNavSearch: true, navResults: [] };
  },
  [constants.NAV_SEARCH_GET.SUCCESS]: (state, { navResults }) => {
    return { ...state, isLoadingNavSearch: false, navResults };
  },
  [constants.NAV_SEARCH_GET.FAILURE]: (state, { error }) => {
    return { ...state, isLoadingNavSearch: false, error };
  },
};

// SAGA

// GET RESULTS FROM ELASTIC SEARCH
function* getNavResults(action) {
  try {
    const response = yield call(apiGetApplicaitons, action);
    yield put({
      type: constants.NAV_SEARCH_GET.SUCCESS,
      navResults: response,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.NAV_SEARCH_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetNavResults() {
  yield takeLatest(constants.NAV_SEARCH_GET.REQUEST, getNavResults);
}

// API

export function apiGetApplicaitons({ urlParams }) {
  return request(`/applications/mongo?quick=1&${urlParams}`, {
    method: 'GET',
    encode: true,
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchGetNavResults,

  // optionally we may
  // api: {
  //   getAccount: apiGetAccount
  // }
};
