import { z } from 'zod';
import { POCSchema, POCStatus } from './basePOC';

export const ApprovePOCSchema = POCSchema.pick({
  payment_option_change_id: true,
  approved_at: true,
  approved_by: true,
  dt_start: true,
})
  .partial({
    dt_start: true,
  })
  .extend({
    status: z.enum([POCStatus.Active, POCStatus.Approved]),
  });

export type ApprovePOC = z.infer<typeof ApprovePOCSchema>;
