import { z } from 'zod';
import { SkillSchema } from './base';

export const SkillUpdateSchema = SkillSchema.pick({
  mongo_skill_id: true,
  name: true,
  seq: true,
  internal_sourcing: true,
  recruiting_mode: true,
  description: true,
});

export type SkillUpdate = z.infer<typeof SkillUpdateSchema>;
