import { z } from 'zod';
import { Comment, CommentCreateSchema } from '../../comment';
import { PosCommentCreateSchema } from '../crud';
import { Endpoint, Method } from '../../../infrastructure';

export const PosCommentCreateRequestSchema = CommentCreateSchema.merge(
  PosCommentCreateSchema.pick({
    position_id: true,
  }),
)
  .extend({
    is_from_hire: z.boolean().default(false).nullish(),
    email: z.string().nullish(),
  })
  .partial({
    author_id: true,
  });
export type PosCommentCreateRequest = z.infer<typeof PosCommentCreateRequestSchema>;

export type PosCommentCreateResponse = Comment;

export type PosCommentCreateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/pos-comments/`,
  PosCommentCreateResponse,
  undefined,
  undefined,
  PosCommentCreateRequest
>;
