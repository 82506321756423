import { CalendlyNotificationSchema } from '..';
import { z } from 'zod';

export const CalendlyNotificationUpdateSchema = CalendlyNotificationSchema.pick({
  calendly_notification_id: true,
  status: true,
  token: true,
}).partial({
  status: true,
  token: true,
});

export type CalendlyNotificationUpdate = z.infer<typeof CalendlyNotificationUpdateSchema>;
