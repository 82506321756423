import { z } from 'zod';
import { Endpoint, Method, dateString } from '../../infrastructure';
import { HireGroup } from './base';

export const PositionBulkStaffingSchema = z.object({
  positions: z.array(z.string()),
  staffing: z.boolean(),
  hire_group: z.nativeEnum(HireGroup),
  dt_staffit: dateString.optional(),
  project_id: z.string(),
});
export type PositionBulkStaffingBody = z.infer<typeof PositionBulkStaffingSchema>;

export type PositionBulkStaffingResponse = {};

export type PositionBulkStaffingEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/positions/bulk-staffing`,
  PositionBulkStaffingResponse,
  undefined,
  undefined,
  PositionBulkStaffingBody
>;
