// GET APPLICATION

import { call, put, takeEvery } from 'redux-saga/effects';
import { actions as entitiesActions } from '@redux/modules/entities';
import { normalize } from 'normalizr';
import * as schemas from '@src/schemas';
import * as api from '@redux/api/application';
import { constants } from '@redux/modules/application/application';

function* get(action) {
  try {
    const response = yield call(api.get, action);
    const showOnboardingButton = response.data.showOnboardingButton;

    const normalized = normalize(response.data.application, schemas.application);
    const { jobs, users, comments, applications } = normalized.entities;

    yield put(entitiesActions.mergeApplications(applications));
    yield put(entitiesActions.mergeJobs(jobs));
    yield put(entitiesActions.mergeComments(comments));
    yield put(entitiesActions.mergeUsers(users));

    yield put({
      type: constants.APPLICATION_GET.SUCCESS,
    });
    yield put({
      type: constants.APPLICATION_GET_SHOW_ONBOARDING_BUTTON.SUCCESS,
      showOnboardingButton,
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: constants.APPLICATION_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGet() {
  yield takeEvery(constants.APPLICATION_GET.REQUEST, get);
}
