import { z } from 'zod';

export enum GenTableType {
  'SHIV_T' = 'SHIV_T',
  'KAIO_T' = 'KAIO_T',
  'NAT_T' = 'NAT_T',
  'JACOB_T' = 'JACOB_T',
}

export const GenericTableSchema = z.object({
  poc_gen_table_id: z.string().uuid(),
  boolean_field: z.boolean(),
  varchar_field: z.string(),
  date_field: z.string(),
  type_field: z.nativeEnum(GenTableType),
  number_field: z.number(),
});

export type GenericTable = z.infer<typeof GenericTableSchema>;
