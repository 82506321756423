import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const PrjGoogleCalendarSchema = z.object({
  prj_google_calendar_id: z.number(),
  project_id: z.string(),
  google_calendar_id: z.string(),
  title: z.string(),
  dt_created: dateString,
  dt_updated: dateString,
});

export type PrjGoogleCalendar = z.infer<typeof PrjGoogleCalendarSchema>;

export const ERR_PRJ_GOOGLE_CALENDAR_NOT_FOUND = 'Project Google calendar record not found.';
