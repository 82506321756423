import { z } from 'zod';
import {
  Endpoint,
  Method,
  FilterOperations,
  ReportResponse,
  ReportsQuerySchema,
  makeSorter,
} from '../../infrastructure';
import { PayoutDownloadMstSchema } from './base';

//Define the filters that are going to be used on this endpoint query
export const ListPayoutDownloadMstFiltersSchema = z.object({
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
});

export type ListPayoutDownloadMstFilters = z.infer<typeof ListPayoutDownloadMstFiltersSchema>;

//Define the sorters
export const ListPayoutDownloadMstSorterSchema = makeSorter(
  z.enum([
    'payment_provider_name',
    'dt_download',
    'num_payouts',
    'num_persons',
    'file_amount',
    'dt_confirmed',
  ]),
);
export type ListPayoutDownloadMstSorter = z.infer<typeof ListPayoutDownloadMstSorterSchema>;

//Define the query that is going to be used on this endpoint
export const ListPayoutDownloadMstQuerySchema = ReportsQuerySchema.extend({
  sorter: ListPayoutDownloadMstSorterSchema.optional(),
  filters: ListPayoutDownloadMstFiltersSchema.optional(),
});
export type ListPayoutDownloadMstQuery = z.infer<typeof ListPayoutDownloadMstQuerySchema>;

//Define the response
export const ListPayoutDownloadMstShema = PayoutDownloadMstSchema.extend({
  payment_provider_name: z.string(),
});

export type ListPayoutDownloadMst = z.infer<typeof ListPayoutDownloadMstShema>;

export type ListPayoutDownloadMstResponse = ReportResponse<ListPayoutDownloadMst>;

//Define the endpoint itself, and specifies the contract with BE.
export type ListPaymentDownloadMstEndpoint = Endpoint<
  Method.GET,
  '/api/v2/payout-download-mst',
  ListPayoutDownloadMstResponse,
  undefined,
  ListPayoutDownloadMstQuery,
  undefined
>;
