import request from '../../shared/request';

export function getAllSources() {
  return request('/sources', {
    method: 'GET',
  });
}

export function createSource({ source }) {
  return request('/sources', {
    method: 'POST',
    body: {
      ...source,
    },
  });
}

export function updateSource({ id, source }) {
  // //;
  return request(`/sources/${id}`, {
    method: 'PUT',
    body: {
      ...source,
    },
  });
}

export function deleteSource({ id }) {
  return request(`/sources/${id}`, {
    method: 'DELETE',
  });
}
