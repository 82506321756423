import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import { constants } from '@redux/modules/feature-flags';
import * as api from '@redux/api/feature-flags';

function* getFeatureFlags() {
  try {
    const features = yield call(api.getFeatureFlags);

    yield put({ type: constants.FEATURE_FLAGS_GET.SUCCESS, features });
  } catch (e) {
    yield put({
      type: constants.FEATURE_FLAGS_GET.FAILURE,
      message: e.message || e,
    });
  }
}

function* watchGetFeatureFlags() {
  yield takeLatest(constants.FEATURE_FLAGS_GET.REQUEST, getFeatureFlags);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([fork(watchGetFeatureFlags)]);
}
