import request from '@shared/request';

export function getScheduledEmails() {
  return request('/applications/report/scheduled-emails', {
    method: 'GET',
  });
}

export function deleteScheduledEmail(emailId) {
  return request(`/applications/report/scheduled-emails/${emailId}`, {
    method: 'DELETE',
  });
}
