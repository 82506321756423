import { z } from 'zod';
import { Comment, CommentCreateSchema } from '../../comment';
import { PrjCommentCreateSchema } from '../crud';
import { Endpoint, Method } from '../../../infrastructure';

export const PrjCommentCreateRequestSchema = CommentCreateSchema.merge(
  PrjCommentCreateSchema.pick({
    project_id: true,
  }),
);
export type PrjCommentCreateRequest = z.infer<typeof PrjCommentCreateRequestSchema>;

export type PrjCommentCreateResponse = Comment;

export type PrjCommentCreateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/prj-comments/`,
  PrjCommentCreateResponse,
  undefined,
  undefined,
  PrjCommentCreateRequest
>;
