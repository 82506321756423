import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const ApplicationTagSchema = z.object({
  application_tag_id: z.number(),
  created_by_id: z.string().length(24).nullable(),
  name: z.string(),
  description: z.string().nullable(),
  dt_created: z.union([z.number(), dateString]),
  dt_updated: z.union([z.number(), dateString]),
  dt_deleted: z.union([z.number(), z.string()]).nullish(),
});

export type ApplicationTag = z.infer<typeof ApplicationTagSchema>;
