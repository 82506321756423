import { z } from 'zod';
import { PositionStaffingSchema } from './base';

export const PositionStaffingCreateSchema = PositionStaffingSchema.pick({
  position_id: true,
  dt_staffit: true,
  previous_assignment_id: true,
  recruiting_category: true,
  position_specs: true,
}).partial({
  previous_assignment_id: true,
});

export type PositionStaffingCreate = z.infer<typeof PositionStaffingCreateSchema>;
