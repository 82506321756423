import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const PersonBonusProgramStreamSchema = z.object({
  bp_stream_id: z.string(),
  bp_id: z.string(),
  tier: z.string(),
  pay_month: z.number(),
  pay_amount: z.number(),
  dtcreate: dateString,
  dtupdate: dateString,
});

export type PersonBonusProgramStream = z.infer<typeof PersonBonusProgramStreamSchema>;

export const PersonBonusProgramSchema = z.object({
  bp_id: z.string(),
  bp_name: z.string(),
  bp_program: z.string(),
  isActive: z.boolean(),
  dt_effective: dateString,
  dtcreate: dateString,
  dtupdate: dateString,
  streams: z.array(PersonBonusProgramStreamSchema),
});

export type PersonBonusProgram = z.infer<typeof PersonBonusProgramSchema>;
