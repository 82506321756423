import request from '../../shared/request';

// STATUSES

export function getHired() {
  return request('/admin-dashboard/hired', {
    method: 'GET',
  });
}

export function newStatus({ applicationId, statusId }) {
  return request(`/applications/${applicationId}/status/${statusId}`, {
    method: 'POST',
    body: {
      opts: {},
    },
  });
}
