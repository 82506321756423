import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const PosCommentSchema = z.object({
  position_id: z.string(),
  comment_id: z.number(),

  dt_created: dateString,
});
export type PosComment = z.infer<typeof PosCommentSchema>;
