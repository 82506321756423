import { Endpoint, Method } from '../../../infrastructure';
import { InterviewCalendarEvent } from '../base';
import { z } from 'zod';

export const ICESetNoShowParamsSchema = z.object({
  interview_calendar_event_id: z.coerce.number(),
});
export type ICESetNoShowParams = z.infer<typeof ICESetNoShowParamsSchema>;

export type ICESetNoShowResponse = InterviewCalendarEvent;

export type ICESetNoShowEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/interview-calendar-events/${ICESetNoShowParams['interview_calendar_event_id']}/no-show`,
  ICESetNoShowResponse,
  ICESetNoShowParams,
  undefined,
  undefined
>;
