import { all, fork } from 'redux-saga/effects';

import { watchGetHired } from './hired';
import { watchUpdateStatus } from './statuses';
import { watchSagaProcessSocket } from './report-ws-event';

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  const sagas = [watchGetHired, watchUpdateStatus, watchSagaProcessSocket].map(fork);

  yield all(sagas);
}
