import { all, fork } from 'redux-saga/effects';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import merge from 'lodash/merge';

import { createReducer } from '@shared/reducers';

import EditComment from './edit-comment';
import Post from './post';
import Remove from './remove';
import SendNotification from './send-notification';

const modules = [EditComment, Post, Remove, SendNotification];

// Reducer

export const initialState = {
  isLoading: false,
  error: null,
};

const ACTION_HANDLERS = reduce(map(modules, 'handlers'), merge, {});

export const reducer = createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});

// Actions

export const actions = reduce(map(modules, 'actions'), merge, {});

// Constants
// it's barely used, but we may need to trigger
// a redux action of any other module from saga
// so it's worth to export it for consistency

export const constants = reduce(map(modules, 'constants'), merge, {});

// Root saga
// Export the root saga by forking all available sagas

export function* rootSaga() {
  const sagas = map(modules, 'watcher');
  yield all(sagas.map(fork));
}
