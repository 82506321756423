import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';

//Base
export const LeadStaffingListSchema = z.object({
  lead_id: z.string(),
  lead_name: z.string(),
  islead: z.boolean(),
  client_id: z.string(),
  client_name: z.string(),
  plannedstart: dateString,
  plannedend: dateString,
  hard_date: z.boolean(),
  isstaffit: z.boolean(),
  team_size: z.number(),
  target_cost: z.number(),
  expected_revenue: z.number(),
  expected_cost: z.number(),
  timezone: z.string(),
  expected_duration: z.number(),
  expected_margin: z.number(),
  hire_group: z.array(z.string()),
  skills: z.string(),
  skills_array: z.array(z.string()),
  sf_description: z.string().nullable(),
  sf_description_updated_at: dateString.or(z.number()).nullish(),
  sf_notes_updated_at: dateString.or(z.number()).nullish(),
  assigned_positions: z.number().nullable(),
  a_candidates: z.number().nullable(),
  a_candidates_cost: z.number().nullable(),
  a_candidates_revenue: z.number().nullable(),
  assigned_cost: z.number().nullable(),
  assigned_revenue: z.number().nullable(),
});

export type LeadStaffingList = z.infer<typeof LeadStaffingListSchema>;

//Filters
export const LeadStaffingListFiltersSchema = z.object({
  lead_name: z.record(z.enum([FilterOperations.CONTAIN]), z.string()).optional(),
  hire_group: z.record(z.enum([FilterOperations.IN]), z.string().array()).optional(),
  skills: z.record(z.enum([FilterOperations.IN]), z.string().array()).optional(),
  lead_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
});
export type LeadStaffingListFilters = z.infer<typeof LeadStaffingListFiltersSchema>;

//Query
export const LeadStaffingListQuerySchema = z.object({
  filters: LeadStaffingListFiltersSchema.optional(),
});
export type LeadStaffingListQuery = z.infer<typeof LeadStaffingListQuerySchema>;

//Response
export type LeadStaffingListResponse = LeadStaffingList[];

//Endpoint
export type LeadStaffingListEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-projects/leads-staffing',
  LeadStaffingListResponse,
  undefined,
  LeadStaffingListQuery,
  undefined
>;
