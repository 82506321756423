import { put, takeEvery } from 'redux-saga/effects';
import { createSagaAction } from '@shared/sagas';

//MODULE

const constants = {
  INTERVIEW_FILTER_SET: createSagaAction('INTERVIEW_FILTER_SET'),
};

const actions = {
  setInterviewFilter: (data) => ({
    ...data,
    type: constants.INTERVIEW_FILTER_SET.REQUEST,
  }),
};

const handlers = {
  [constants.INTERVIEW_FILTER_SET.REQUEST]: (state, { interviewFilter }) => {
    return { ...state };
  },
  [constants.INTERVIEW_FILTER_SET.SUCCESS]: (state, { interviewFilter }) => {
    const newState = { ...state };
    newState.interviewFilter = { ...state.interviewFilter };
    if (interviewFilter) {
      newState.interviewFilter = interviewFilter;
    }
    return newState;
  },
  [constants.INTERVIEW_FILTER_SET.FAILURE]: (state) => {
    return { ...state };
  },
};
//SAGAS
function* sagaSetInterviewFilter(action) {
  try {
    const interviewFilter = action.interviewFilter;
    yield put({
      type: constants.INTERVIEW_FILTER_SET.SUCCESS,
      interviewFilter,
    });
  } catch (e) {
    yield put({
      type: constants.INTERVIEW_FILTER_SET.FAILURE,
      message: e.message || e,
    });
  }
}

export function* watchSetInterviewFilter() {
  yield takeEvery(constants.INTERVIEW_FILTER_SET.REQUEST, sagaSetInterviewFilter);
}

export default {
  actions,
  constants,
  handlers,
  watcher: watchSetInterviewFilter,
};
