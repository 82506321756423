import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { RNVWaterfallSchema } from './base';
import { PersonSchema } from '../people';

export type GetPersonRNVSummaryEndpoint = Endpoint<
  Method.GET,
  `/api/v2/people/:${GetPersonRNVSummaryParams['person_id']}`,
  GetPersonRNVSummaryResponse,
  GetPersonRNVSummaryParams,
  undefined,
  undefined
>;

export const GetPersonRNVSummaryParamsSchema = PersonSchema.pick({
  person_id: true,
});
export type GetPersonRNVSummaryParams = z.infer<typeof GetPersonRNVSummaryParamsSchema>;

export const GetPersonRNVSummaryResponseSchema = z.object({
  years_of_service: z.number(),
  current_balance: z.number(),
  future_rnv_waterfall: z.array(RNVWaterfallSchema),
});
export type GetPersonRNVSummaryResponse = z.infer<typeof GetPersonRNVSummaryResponseSchema>;
