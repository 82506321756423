/**
 * Internal function to create async saga action types.
 *
 * @param  String type Type to create
 * @return Object      Containing REQUEST|SUCCESS|FAILURE properties
 */
export function createSagaAction(type) {
  return {
    REQUEST: `${type}.REQUEST`,
    SUCCESS: `${type}.SUCCESS`,
    FAILURE: `${type}.FAILURE`,
  };
}
