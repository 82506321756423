import { all, fork, call, put, takeEvery } from 'redux-saga/effects';
import { constants } from '@redux/modules/mediums';
import * as api from '@redux/api/mediums';
import history from '@src/history';

function* getAll(action) {
  try {
    const response = yield call(api.getAllMediums, action);
    yield put({ type: constants.MEDIUMS_GET_ALL.SUCCESS, allMediums: response.data });
  } catch (e) {
    yield put({
      type: constants.MEDIUMS_GET_ALL.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchGetAll() {
  yield takeEvery(constants.MEDIUMS_GET_ALL.REQUEST, getAll);
}

function* create(action) {
  try {
    yield call(api.createMedium, action);
    yield put({ type: constants.MEDIUMS_CREATE.SUCCESS });
    const response = yield call(api.getAllMediums, action);
    yield put({ type: constants.MEDIUMS_GET_ALL.SUCCESS, allMediums: response.data });
    history.push('/admin/mediums');
  } catch (e) {
    yield put({
      type: constants.MEDIUMS_CREATE.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchCreate() {
  yield takeEvery(constants.MEDIUMS_CREATE.REQUEST, create);
}

function* update(action) {
  try {
    yield call(api.updateMedium, action);
    yield put({ type: constants.MEDIUMS_UPDATE.SUCCESS });
    const response = yield call(api.getAllMediums, action);
    yield put({ type: constants.MEDIUMS_GET_ALL.SUCCESS, allMediums: response.data });
    history.push('/admin/mediums');
  } catch (e) {
    yield put({
      type: constants.MEDIUMS_UPDATE.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchUpdate() {
  yield takeEvery(constants.MEDIUMS_UPDATE.REQUEST, update);
}

function* deleteMedium(action) {
  try {
    yield call(api.deleteMedium, action);
    yield put({ type: constants.MEDIUMS_DELETE.SUCCESS });
    const response = yield call(api.getAllMediums, action);
    yield put({ type: constants.MEDIUMS_GET_ALL.SUCCESS, allMediums: response.data });
    history.push('/admin/mediums');
  } catch (e) {
    yield put({
      type: constants.MEDIUMS_DELETE.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchDelete() {
  yield takeEvery(constants.MEDIUMS_DELETE.REQUEST, deleteMedium);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([fork(watchGetAll), fork(watchCreate), fork(watchUpdate), fork(watchDelete)]);
}
