//APPLICATION_USER_JOB_UPDATE
import * as api from '@redux/api/application';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';
import isFunction from 'lodash/isFunction';
import { normalize } from 'normalizr';
import * as schemas from '@src/schemas';
import { actions as entitiesActions } from '@redux/modules/entities';

let assignmentsReportActions;
import('@redux/assignments-report').then(({ actions }) => (assignmentsReportActions = actions));

function* setExcitement(action) {
  try {
    yield put(
      assignmentsReportActions.updateApplicationExcitement(action.applicationId, action.excitement),
    );
    const response = yield call(api.setExcitement, action);
    const normalized = normalize(response.application, schemas.application);
    const { applications } = normalized.entities;

    yield put(entitiesActions.mergeApplications(applications));
    yield put({
      type: constants.APPLICATION_EXCITEMENT_SET.SUCCESS,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }

    const timelineResponse = yield call(api.getApplicationTimelineList, action);
    const timelineList = (timelineResponse && timelineResponse.data) || [];
    yield put({
      type: constants.APPLICATION_TIMELINES_GET.SUCCESS,
      timelineList,
    });
    yield put(assignmentsReportActions.updateApplicationExcitementSuccess());
  } catch (e) {
    yield put({
      type: constants.APPLICATION_EXCITEMENT_SET.FAILURE,
      error: e.message || e,
    });
    yield put(assignmentsReportActions.updateApplicationExcitementFail(action.applicationId));
    if (isFunction(action.onFail)) {
      yield action.onFail(e.message || e);
    }
  }
}

export function* watchSetExcitement() {
  yield takeLatest(constants.APPLICATION_EXCITEMENT_SET.REQUEST, setExcitement);
}
