import { call, put, takeLatest } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';
import { getJobApplicationsSummaryApi } from '@src/redux/jobs/get-job-applications-summary';

import { constants as jobsConstants } from '@redux/jobs';

// MODULE
export const constants = {
  MOVE_JOB_APPLICATIONS: createSagaAction('MOVE_JOB_APPLICATIONS'),
};

const actions = {
  moveApplications: (params) => ({
    type: constants.MOVE_JOB_APPLICATIONS.REQUEST,
    ...params,
  }),
};

const handlers = {
  [constants.MOVE_JOB_APPLICATIONS.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

// SAGA
function* moveJobApplications(action) {
  try {
    yield call(apiMoveJobApplications, action);

    yield put({ type: constants.MOVE_JOB_APPLICATIONS.SUCCESS });

    const response = yield call(getJobApplicationsSummaryApi, {
      totalApplications: true,
    });

    const applicationsSummary = response.data;

    yield put({ type: jobsConstants.GET_JOB_APPLICATIONS_SUMMARY.SUCCESS, applicationsSummary });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (error) {
    yield put({
      type: constants.MOVE_JOB_APPLICATIONS.FAILURE,
      error: error.message || error,
    });

    if (isFunction(action.onError)) {
      yield action.onError(error.message || error);
    }
  }
}

function* watchMoveJobApplications() {
  yield takeLatest(constants.MOVE_JOB_APPLICATIONS.REQUEST, moveJobApplications);
}

// API
export function apiMoveJobApplications({ fromJobId, toJobId, closeJob }) {
  if (!fromJobId || !toJobId) {
    return Promise.reject({ message: 'Invalid Job ID' });
  }

  return request(`/jobs/${fromJobId}/move-applications`, {
    method: 'PUT',
    body: {
      toJobId,
      closeJob,
    },
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchMoveJobApplications,
};
