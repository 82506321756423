/* eslint-disable max-len */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { put, takeEvery } from 'redux-saga/effects';

import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import get from 'lodash/get';
import size from 'lodash/size';
import remove from 'lodash/remove';
import moment from 'moment';
import { createSagaAction } from '@shared/sagas';

//MODULE
const constants = {
  INTERVIEW_WS_EVENT: createSagaAction('INTERVIEW_WS_EVENT'),
};
const actions = {
  processSocket: (data) => ({
    type: constants.INTERVIEW_WS_EVENT.REQUEST,
    data,
  }),
};

const handlers = {
  [constants.INTERVIEW_WS_EVENT.REQUEST]: (state) => {
    return { ...state };
  },
  [constants.INTERVIEW_WS_EVENT.SUCCESS]: (state, { socketEvent }) => {
    const event = get(socketEvent, 'event');
    const createdOrUpdated = get(event, 'created') || get(event, 'updated');
    const deleted = get(event, 'deleted');
    const filterToBeUsed = {};
    const newState = { ...state };
    const application = get(event, 'application') || null;
    //Cloning the array of applications
    newState.resultsList = { ...state.resultsList };
    newState.resultsSummary = { ...state.resultsSummary };
    newState.resultsSummary.totalSummary = [...state.resultsSummary.totalSummary];
    const interview = get(event, 'interview') || null;
    const arrayOfInterviwers = filter(newState.resultsSummary.totalSummary, (element) => {
      return get(element, 'title') !== 'Totals';
    });
    const interviewFilter = { ...state.interviewFilter };
    filterToBeUsed.from = interviewFilter.from
      ? moment(interviewFilter.from).format('YYYY-MM-DD')
      : moment().startOf('day').format('YYYY-MM-DD');
    filterToBeUsed.to = interviewFilter.to
      ? moment(interviewFilter.to).format('YYYY-MM-DD')
      : moment().endOf('day').format('YYYY-MM-DD');
    const statusFilters = map(get(interviewFilter, 'status'), (status) => parseInt(status));
    filterToBeUsed.status = size(statusFilters) ? statusFilters : [1, 2];
    filterToBeUsed.interviewer = size(interviewFilter.interviewer)
      ? interviewFilter.interviewer
      : [];

    const interviews = get(application, 'interviews');
    const interviewsFiltered = filter(interviews, (interview) => {
      const matchDate =
        moment(interview.datetime).format('YYYY-MM-DD') >= filterToBeUsed.from &&
        moment(interview.datetime).format('YYYY-MM-DD') <= filterToBeUsed.to;
      const matchInterviewers =
        size(filterToBeUsed.interviewer) > 0
          ? filterToBeUsed.interviewer.includes(interview.interviewer)
          : true;
      const matchStatus = filterToBeUsed.status.includes(interview.status);
      return matchDate && matchInterviewers && matchStatus;
    });

    // };
    //Bullding the application which are in the state, getting that reference
    const applicationState = find(newState.resultsList.applications, {
      _id: application._id,
    });
    //Application are being deleted, so just remove
    if (deleted) {
      if (deleted.includes('interview')) {
        //resultsList Logic
        //Removing only the interview which have been deleted
        remove(applicationState.interviews, {
          _id: get(event, 'interview._id'),
        });
        //IF the candidate have any more interviews in that range, just removed it
        if (interviewsFiltered.length === 0) {
          remove(newState.resultsList.applications, { _id: application._id });
        }

        //resultsSummary Logic
        //If the interview have datetime and the array exists
        if (interview.datetime && arrayOfInterviwers) {
          //For each interviewer inside the array of summary
          arrayOfInterviwers.forEach((element) => {
            //Makes the match between the interviewer in the array of interviewer and the interviewer off the interview deleted
            if (element._id === interview.interviewer) {
              const arrayOfRanges = get(element, 'ranges');
              // For each range inside the array of ranges, he will verify if that interview belong that range
              arrayOfRanges.forEach((range) => {
                //Here we catch every interview which belong that interviwer and are in the range filtered.
                const interviewsInTheRange = filter(get(application, 'interviews'), (interview) => {
                  return isInRange({
                    range,
                    datetime: get(interview, 'datetime'),
                  });
                });
                //If have no more interviews in that range, and the deleted interview are in range, so remove 1, because that value are building the total of the range
                if (
                  interviewsInTheRange.length === 0 &&
                  isInRange({ range, datetime: get(interview, 'datetime') })
                ) {
                  range.count--;
                }
              });
            }
          });
        }
        //If the application are being deleted, remove the application from the arrays
      } else if (deleted.includes('application')) {
        //resultsSummary Logic
        arrayOfInterviwers.forEach((element) => {
          const arrayOfRanges = get(element, 'ranges');
          arrayOfRanges.forEach((range) => {
            //try to find all interviewers which are inside that interval, if has any one, will decrease the range count
            const interviewsInTheRange = filter(get(application, 'interviews'), (interview) => {
              return (
                get(interview, 'interviewer') === get(element, '_id') &&
                isInRange({ range, datetime: get(interview, 'datetime') })
              );
            });
            if (interviewsInTheRange > 0) {
              range.count--;
            }
          });
        });
        //resultList Logic
        remove(newState.resultsList.applications, { _id: application._id });
      }
      //If are a Create or event which includes interview, then ->
    } else if (createdOrUpdated) {
      if (createdOrUpdated.includes('interview')) {
        // START ----- resultsSummary Logic
        if (interview.datetime && arrayOfInterviwers) {
          arrayOfInterviwers.forEach((element) => {
            if (element._id === interview.interviewer) {
              const arrayOfRanges = get(element, 'ranges');
              processRangeCP({
                arrayOfRanges,
                oldDatetime: get(interview, 'oldDatetime'),
                interviews: filter(get(application, 'interviews'), (interview) => {
                  return interview.interviewer === element._id;
                }),
                interview,
              });
            }
          });
        }

        // START ------- resultsList Logic
        if (interviewsFiltered.length) {
          if (applicationState) {
            applicationState.interviews = [...interviewsFiltered];
          } else {
            application.interviews = [...interviewsFiltered];
            newState.resultsList.applications.push(application);
          }
          //When a update remove this guy from the range
        } else {
          remove(newState.resultsList.applications, { _id: application._id });
        }

        //If are not updating the interviews, so just update the application in the state.
      } else if (interviewsFiltered.length) {
        remove(newState.resultsList.applications, { _id: application._id });
        newState.resultsList.applications.push(application);
      }
    }
    return newState;
  },
  [constants.INTERVIEW_WS_EVENT.FAILURE]: (state, { socketEvent }) => {
    return { ...state };
  },
};
//Process the create and update of a interview inside the resultSummary
function processRangeCP({
  arrayOfRanges,
  oldDatetime,
  interviews,
  interview,
  arrrayOfRangeOfTotals,
}) {
  arrayOfRanges.forEach((range) => {
    const interviewsInTheRange = filter(interviews, (interview) => {
      return isInRange({
        range,
        datetime: get(interview, 'datetime'),
      });
    });

    // Treat when a update remove a interview from that range.
    if (isInRange({ range, datetime: oldDatetime }) && interviewsInTheRange.length <= 1) {
      range.count--;
    }
    // Treat when the interview get to a new range, and the application wasnt inside the range yet
    if (
      interviewsInTheRange.length <= 1 &&
      isInRange({ range, datetime: get(interview, 'datetime') })
    ) {
      range.count++;
    }
  });
}
function isInRange({ range, datetime }) {
  if (datetime) {
    if (range.isWeek) {
      const from = moment(range.date).format('YYYY-MM-DD');
      const to = moment(range.date).add(7, 'days').format('YYYY-MM-DD');
      return moment(datetime).format('YYYY-MM-DD') >= from && datetime <= to;
    } else if (range.isDay) {
      const day = moment(range.date).format('YYYY-MM-DD');
      return moment(datetime).format('YYYY-MM-DD') === day;
    }
  }
}
//SAGA
function* sagaProcessSocket(action) {
  try {
    const socketEvent = action.data;
    if (socketEvent.sender) {
      yield put({
        type: constants.INTERVIEW_WS_EVENT.SUCCESS,
        socketEvent,
      });
    } else {
      yield put({
        type: constants.INTERVIEW_WS_EVENT.SUCCESS,
        socketEvent,
      });
    }
  } catch (e) {
    yield put({
      type: constants.INTERVIEW_WS_EVENT.FAILURE,
      message: e.message || e,
    });
  }
}
export function* watchProcessSocket() {
  yield takeEvery(constants.INTERVIEW_WS_EVENT.REQUEST, sagaProcessSocket);
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchProcessSocket,
};
