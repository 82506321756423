import { put, takeEvery } from 'redux-saga/effects';
import { constants } from '@redux/modules/dashboard/dashboard';

//Function to call the procss of the socket
function* sagaProcessSocket(action) {
  try {
    const socketEvent = action.event;
    yield put({
      type: constants.REPORT_WS_EVENT.SUCCESS,
      socketEvent,
    });
  } catch (e) {
    yield put({
      type: constants.REPORT_WS_EVENT.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchSagaProcessSocket() {
  yield takeEvery(constants.REPORT_WS_EVENT.REQUEST, sagaProcessSocket);
}
