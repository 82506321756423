import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const OppMergeRequestSchema = z.object({
  merger_id: z.string(),
  receiver_id: z.string(),
});

export type OppMergeRequest = z.infer<typeof OppMergeRequestSchema>;

export type OppMergeRespone = void;

export type OppMergeEndpoint = Endpoint<
  Method.POST,
  '/api/v2/sql-leads/opp-merge',
  OppMergeRespone,
  undefined,
  undefined,
  OppMergeRequest
>;
