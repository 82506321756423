import React from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Menu, Grid } from 'antd';

import endsWith from 'lodash/endsWith';
import includes from 'lodash/includes';
import get from 'lodash/get';

import HelpOptions from './HelpOptions/HelpOptions';
import LoginButton from './LoginButton';

import './MainMenu.less';

const { useBreakpoint } = Grid;

const propTypes = {
  location: PropTypes.string,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
};

const MainMenu = (props) => {
  const screens = useBreakpoint();

  const { onMenuItemClick, login, loginWithToken, logout, location } = props;

  const user = useSelector((state) => state.authentication.user);
  const fingerprint = useSelector((state) => state.authentication.fingerprint);

  const isResponsive = screens.xs || (screens.sm && !screens.lg);

  const getLocation = () => {
    if (endsWith(location, '/admin/dashboard')) {
      return '/admin/dashboard';
    } else if (endsWith(location, '/admin/refer')) {
      return '/admin/refer';
    } else if (includes(location, '/admin/reports/')) {
      return '/admin/reports/index';
    } else if (includes(location, '/admin/jobs')) {
      return '/admin/jobs';
    } else if (includes(location, '/tech/account')) {
      return '/tech/account';
    } else if (includes(location, '/sales/account')) {
      return '/sales/account';
    } else {
      return null;
    }
  };

  // render

  const isAdmin = get(user, 'isAdmin');
  const isTech = get(user, 'isTechRecruiter');
  const isSalesInterviewer = get(user, 'isSalesInterviewer');
  const isSalesFullInterviewer = get(user, 'isSalesFullInterviewer');
  const isSales = isSalesInterviewer || isSalesFullInterviewer;
  const mode = isResponsive ? 'inline' : 'horizontal';

  const MenuLink = ({ path, label }) => <Link to={path}>{label}</Link>;

  const settingsItems = [
    {
      label: <MenuLink label="My Account" path="/admin/account" />,
      key: '/admin/account',
    },
    {
      label: <HelpOptions />,
      key: 'help-options',
    },
    {
      label: <MenuLink label="Job Types" path="/admin/job-types" />,
      key: '/admin/job-types',
    },
    {
      label: <MenuLink label="Job Priorities" path="/admin/job-priorities" />,
      key: '/admin/job-priorities',
    },
    {
      label: <MenuLink label="Job Titles" path="/admin/job-titles" />,
      key: '/admin/job-titles',
    },
    {
      label: <MenuLink label="Scorecard Questions" path="/admin/scorecard-questions" />,
      key: '/admin/scorecard-questions',
    },
    {
      label: <MenuLink label="Videos" path="/admin/questions" />,
      key: '/admin/questions',
    },
    {
      label: <MenuLink label="Sources" path="/admin/sources" />,
      key: '/admin/sources',
    },
    {
      label: <MenuLink label="Mediums" path="/admin/mediums" />,
      key: '/admin/mediums',
    },
    {
      label: <MenuLink label="Rejection Reasons" path="/admin/rejection-reasons" />,
      key: '/admin/rejection-reasons',
    },
    {
      label: <MenuLink label="Calendly Users" path="/admin/calendly-users" />,
      key: '/admin/calendly-users',
    },
    {
      label: <MenuLink label="Calendly User Types" path="/admin/calendly-user-types" />,
      key: '/admin/calendly-user-types',
    },
    {
      label: <MenuLink label="Roles" path="/admin/roles" />,
      key: '/admin/roles',
    },
    {
      label: <MenuLink label="UTM Params" path="/admin/utm-params" />,
      key: '/admin/utm-params',
    },
    {
      label: (
        <MenuLink label="Countries excluded from Applications" path="/admin/rejected-countries" />
      ),
      key: '/admin/rejected-countries',
    },
  ];

  const adminItems = isAdmin
    ? [
        {
          label: <MenuLink label="Dashboard" path="/admin/dashboard" />,
          key: '/admin/dashboard',
        },
        {
          label: <MenuLink label="+Applicant" path="/admin/refer" />,
          key: '/admin/refer',
        },
        {
          label: <MenuLink label="Reports" path="/admin/reports/index" />,
          key: '/admin/reports/index',
        },
        {
          label: <MenuLink label="Jobs" path="/admin/jobs" />,
          key: '/admin/jobs',
        },
        {
          label: 'Settings',
          key: 'settings',
          children: settingsItems,
        },
      ]
    : [];

  const techRecruitersItems =
    isTech && !isAdmin
      ? [
          {
            label: <MenuLink label="Dashboard" path="/tech/dashboard" />,
            key: '/tech/dashboard',
          },
          {
            label: <MenuLink label="My Account" path="/tech/account" />,
            key: '/tech/account',
          },
        ]
      : [];

  const salesItems =
    isSales && !isAdmin && !isTech
      ? [
          {
            label: <MenuLink label="Positions" path="/sales/positions" />,
            key: '/sales/positions',
          },
          {
            label: <MenuLink label="My Account" path="/sales/account" />,
            key: '/sales/account',
          },
        ]
      : [];

  const commonItems = [
    {
      label: (
        <LoginButton
          user={user}
          fingerprint={fingerprint}
          login={login}
          loginWithToken={loginWithToken}
          logout={logout}
          location={location}
        />
      ),
      key: 'login-logout',
    },
  ];

  const menuItems = [...adminItems, ...techRecruitersItems, ...salesItems, ...commonItems];

  return (
    <Menu
      theme="dark"
      mode={mode}
      selectedKeys={[getLocation()]}
      forceSubMenuRender={true}
      items={menuItems}
      onClick={onMenuItemClick}
    />
  );
};

MainMenu.propTypes = propTypes;
export default MainMenu;
