import { Endpoint, Method } from '../../../infrastructure';
import { z } from 'zod';
import { PsnRctrGoogleCalendarChannelSchema } from '../base';

export const PsnRctrGoogleCalendarChannelDeleteParamsSchema =
  PsnRctrGoogleCalendarChannelSchema.pick({
    calendar_id: true,
  });
export type PsnRctrGoogleCalendarChannelDeleteParams = z.infer<
  typeof PsnRctrGoogleCalendarChannelDeleteParamsSchema
>;

export type PsnRctrGoogleCalendarChannelDeleteResponse = void;

export type PsnRctrGoogleCalendarChannelDeleteEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/psn-rctr-google-calendar-channel/${PsnRctrGoogleCalendarChannelDeleteParams['calendar_id']}`,
  PsnRctrGoogleCalendarChannelDeleteResponse,
  PsnRctrGoogleCalendarChannelDeleteParams,
  undefined,
  undefined
>;
