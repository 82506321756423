import { Endpoint, Method } from '../../../infrastructure';
import { PsnRctr } from '../base';
import { PsnRctrUpdateSchema } from '../crud';
import { z } from 'zod';

export const PsnRctrUpdateRequestSchema = PsnRctrUpdateSchema.pick({
  zoom_link: true,
  rctr_group: true,
  rctr_role: true,
  email: true,
});
export type PsnRctrUpdateRequest = z.infer<typeof PsnRctrUpdateRequestSchema>;

export const PsnRctrUpdateParamsSchema = PsnRctrUpdateSchema.pick({
  person_id: true,
});

export type PsnRctrUpdateParams = z.infer<typeof PsnRctrUpdateParamsSchema>;

export type PsnRctrUpdateResponse = PsnRctr;

export type PsnRctrUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/psn-rctr/${PsnRctrUpdateParams['person_id']}`,
  PsnRctrUpdateResponse,
  PsnRctrUpdateParams,
  undefined,
  PsnRctrUpdateRequest
>;
