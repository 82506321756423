// GET STATUSES

import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '@redux/api/application';
import { constants } from '@redux/modules/application/application';
import * as schemas from '@src/schemas';
import isFunction from 'lodash/isFunction';
import { actions as entitiesActions } from '@redux/modules/entities';
import { normalize } from 'normalizr';

function* getStatuses(action) {
  try {
    const applicationStatuses = yield call(api.getStatuses, action);
    yield put({ type: constants.APPLICATION_STATUSES_GET.SUCCESS, applicationStatuses });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_STATUSES_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetStatuses() {
  yield takeLatest(constants.APPLICATION_STATUSES_GET.REQUEST, getStatuses);
}

// UPDATE STATUS

function* updateStatus(action) {
  try {
    const response = yield call(api.updateStatus, action);
    const normalized = normalize(response.application, schemas.application);
    const { applications } = normalized.entities;

    yield put(entitiesActions.mergeApplications(applications));

    yield put({ type: constants.APPLICATION_STATUS_UPDATE.SUCCESS });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.APPLICATION_STATUS_UPDATE.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail();
    }
  }
}

export function* watchUpdateStatus() {
  yield takeLatest(constants.APPLICATION_STATUS_UPDATE.REQUEST, updateStatus);
}

function* getTechTestStatuses(action) {
  try {
    const techTestStatuses = yield call(api.getTechTestStatuses, action);

    yield put({ type: constants.APPLICATION_TECH_TEST_STATUSES_GET.SUCCESS, techTestStatuses });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_TECH_TEST_STATUSES_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetTechTestStatuses() {
  yield takeLatest(constants.APPLICATION_TECH_TEST_STATUSES_GET.REQUEST, getTechTestStatuses);
}
