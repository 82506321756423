import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { Message, MessageSchema } from './base';

export const UpdateMessageRequestSchema = z.object({
  txt_content: z.string(),
});

export type UpdateMessageRequest = z.infer<typeof UpdateMessageRequestSchema>;

export type UpdateMessageResponse = Message;

export const UpdateMessageParamsSchema = MessageSchema.pick({
  message_id: true,
});
export type UpdateMessageParams = z.infer<typeof UpdateMessageParamsSchema>;

export type UpdateMessageEndpoint = Endpoint<
  Method.PUT,
  `/api/v3/message/:${UpdateMessageParams['message_id']}`,
  UpdateMessageResponse,
  UpdateMessageParams,
  undefined,
  UpdateMessageRequest
>;
