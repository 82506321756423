import { z } from 'zod';
import { dateString, Endpoint, Method } from '../../infrastructure';

export const SignProjectExtensionRequestSchema = z.object({
  dt_opp_extension_signed: dateString,
});
export type SignProjectExtensionRequest = z.infer<typeof SignProjectExtensionRequestSchema>;

export const SignProjectExtensionParamsSchema = z.object({
  project_id: z.string(),
});
export type SignProjectExtensionParams = z.infer<typeof SignProjectExtensionParamsSchema>;

export type SignProjectExtensionEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/opp-extension/${SignProjectExtensionParams['project_id']}`,
  void,
  SignProjectExtensionParams,
  undefined,
  SignProjectExtensionRequest
>;
