import { z } from 'zod';
import { Forecast, ForecastUpdateSchema } from '../';
import { Endpoint, Method } from '../../../infrastructure';

export const ForecastUpdateRequestSchema = ForecastUpdateSchema;
export type ForecastUpdateRequest = z.infer<typeof ForecastUpdateRequestSchema>;

export const ForecastUpdateParamsSchema = z.object({
  forecast_id: z.coerce.number(),
});
export type ForecastUpdateParams = z.infer<typeof ForecastUpdateParamsSchema>;

export type ForecastUpdateResponse = Forecast;

export type ForecastUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/forecasts/${ForecastUpdateParams['forecast_id']}`,
  ForecastUpdateResponse,
  ForecastUpdateParams,
  undefined,
  ForecastUpdateRequest
>;
