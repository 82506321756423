import * as api from '@redux/api/application';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';

function* getSourcedByList(action) {
  try {
    const sourcedByList = yield call(api.getSourcedByList, action);
    yield put({ type: constants.APPLICATION_GET_SOURCED_BY.SUCCESS, sourcedByList });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_GET_SOURCED_BY.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchGetSourcedByList() {
  yield takeLatest(constants.APPLICATION_GET_SOURCED_BY.REQUEST, getSourcedByList);
}
