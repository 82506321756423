import { z } from 'zod';
import { Lead, LeadSchema } from './base';
import { Endpoint, Method } from '../../infrastructure';

export const LeadSetExpectedMonthlyRevenueRequestSchema = z.object({
  expected_monthly_revenue: z.number(),
});

export type LeadSetExpectedMonthlyRevenueRequest = z.infer<
  typeof LeadSetExpectedMonthlyRevenueRequestSchema
>;

export const LeadSetExpectedMonthlyRevenueParamsSchema = LeadSchema.pick({
  project_id: true,
});

export type LeadSetExpectedMonthlyRevenueParams = z.infer<
  typeof LeadSetExpectedMonthlyRevenueParamsSchema
>;

export type LeadSetExpectedMonthlyRevenueResponse = Lead;

export type LeadSetExpectedMonthlyRevenueEndpoint = Endpoint<
  Method.PUT,
  `/api/sql-leads/${LeadSetExpectedMonthlyRevenueParams['project_id']}/set-expected-monthly-revenue`,
  LeadSetExpectedMonthlyRevenueResponse,
  LeadSetExpectedMonthlyRevenueParams,
  undefined,
  LeadSetExpectedMonthlyRevenueRequest
>;
