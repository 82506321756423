import { z } from 'zod';
import { FinBudgetSchema } from '../';

export const FinBudgetCreateSchema = FinBudgetSchema.pick({
  budget_name: true,
  year: true,
  cost_budget: true,
  revenue_budget: true,
});

export type FinBudgetCreate = z.infer<typeof FinBudgetCreateSchema>;
