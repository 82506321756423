//Redux
import { put, takeEvery } from 'redux-saga/effects';
import { createSagaAction } from '@shared/sagas';

//Lodash
import get from 'lodash/get';
import remove from 'lodash/remove';

//MODULES
const constants = {
  ASSIGNMENTS_REPORT_ON_CLEAR_SKILL_TAG_FILTER: createSagaAction(
    'ASSIGNMENTS_REPORT_ON_CLEAR_SKILL_TAG_FILTER',
  ),
};

const actions = {
  onClearSkillTagFilter: (data) => ({
    type: constants.ASSIGNMENTS_REPORT_ON_CLEAR_SKILL_TAG_FILTER.REQUEST,
    data,
  }),
};

const handlers = {
  [constants.ASSIGNMENTS_REPORT_ON_CLEAR_SKILL_TAG_FILTER.REQUEST]: (state) => {
    return { ...state };
  },
  [constants.ASSIGNMENTS_REPORT_ON_CLEAR_SKILL_TAG_FILTER.SUCCESS]: (state, { skillId }) => {
    const newState = { ...state };
    newState.skillTagFilter = [...state.skillTagFilter] || [];
    // That id could be a array of string with 2 ids or more
    remove(newState.skillTagFilter, (element) => element === skillId);
    return newState;
  },
  [constants.ASSIGNMENTS_REPORT_ON_CLEAR_SKILL_TAG_FILTER.FAILURE]: (state) => {
    return { ...state };
  },
};
//SAGAS

function* onClearSkillTagFilter(action) {
  try {
    const skillId = get(action, 'data.skillId');
    yield put({
      type: constants.ASSIGNMENTS_REPORT_ON_CLEAR_SKILL_TAG_FILTER.SUCCESS,
      skillId,
    });
  } catch (e) {
    yield put({
      type: constants.ASSIGNMENTS_REPORT_ON_CLEAR_SKILL_TAG_FILTER.FAILURE,
      message: e.message || e,
    });
  }
}

export function* watchOnClearSkillTagFilter() {
  yield takeEvery(
    constants.ASSIGNMENTS_REPORT_ON_CLEAR_SKILL_TAG_FILTER.REQUEST,
    onClearSkillTagFilter,
  );
}

//INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchOnClearSkillTagFilter,
};
