//UPDATE USER ASSIGNMENTS
import * as api from '@redux/api/application';
import * as schemas from '@src/schemas';
import { actions as entitiesActions } from '@redux/modules/entities';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';
import { normalize } from 'normalizr';
import { actions as assignmentReportActions } from '@redux/modules/reports/assignments-report';

import isFunction from 'lodash/isFunction';

let assignmentsReportActions;
import('@redux/assignments-report').then(({ actions }) => (assignmentsReportActions = actions));

function* updateUserAssignments(action) {
  try {
    yield put(
      assignmentsReportActions.updateApplicationAssignments(
        action.applicationId,
        action.assignments,
      ),
    );
    const response = yield call(api.updateUserAssignments, action);
    const normalized = normalize(response.application, schemas.application);
    const { users, applications } = normalized.entities;

    yield put(
      assignmentReportActions.reloadAssignmentsState({
        application: action.application,
        updatedApplications: applications,
      }),
    );

    yield put(entitiesActions.mergeApplications(applications));

    yield put(entitiesActions.mergeUsers(users));

    yield put({
      type: constants.APPLICATION_USER_ASSIGNMENTS_UPDATE.SUCCESS,
    });
    yield put(assignmentsReportActions.updateApplicationAssignmentsSuccess());

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(response.message || '');
    }

    const timelineResponse = yield call(api.getApplicationTimelineList, action);
    const timelineList = (timelineResponse && timelineResponse.data) || [];
    yield put({
      type: constants.APPLICATION_TIMELINES_GET.SUCCESS,
      timelineList,
    });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_USER_ASSIGNMENTS_UPDATE.FAILURE,
      error: e.message || e,
    });
    yield put(assignmentsReportActions.updateApplicationAssignmentsFail(action.applicationId));

    if (isFunction(action.onFailure)) {
      yield action.onFailure(e.message || e);
    }
  }
}

export function* watchUpdateUserAssignments() {
  yield takeLatest(constants.APPLICATION_USER_ASSIGNMENTS_UPDATE.REQUEST, updateUserAssignments);
}

function* updateUserAssignment(action) {
  try {
    yield put(
      assignmentsReportActions.updateApplicationAssignment(action.applicationId, action.assignment),
    );
    yield call(api.updateUserAssignment, action);

    yield put({
      type: constants.APPLICATION_USER_ASSIGNMENT_UPDATE.SUCCESS,
    });
    yield put(assignmentsReportActions.updateApplicationAssignmentSuccess());
  } catch (e) {
    yield put({
      type: constants.APPLICATION_USER_ASSIGNMENT_UPDATE.FAILURE,
      error: e.message || e,
    });
    yield put(assignmentsReportActions.updateApplicationAssignmentFail(action.applicationId));
    if (isFunction(action.onFail)) {
      yield action.onFail(e.message || e);
    }
  }
}

export function* watchUpdateUserAssignment() {
  yield takeLatest(constants.APPLICATION_USER_ASSIGNMENT_UPDATE.REQUEST, updateUserAssignment);
}
