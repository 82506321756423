import { z } from 'zod';
import {
  ReportsQuerySchema,
  Endpoint,
  FilterOperations,
  Method,
  makeSorter,
  ReportResponse,
} from '../../infrastructure';
import { dateString } from '../../infrastructure/schema';

//Reponse
export const ListActiveAssignmentsMarginSchema = z.object({
  client_id: z.string(),
  client_name: z.string(),
  project_id: z.string(),
  project_name: z.string(),
  assignment_id: z.string(),
  position_id: z.string(),
  position_name: z.string(),
  person_id: z.string(),
  person_name: z.string(),
  person_gravatar_url: z.string(),
  department_name: z.string(),
  department_id: z.string(),
  dtstart: dateString,
  dtend: dateString,
  person_salary: z.number(),
  person_rev: z.number(),
  person_total_rev: z.number(),
  person_cost: z.number(),
  person_total_cost: z.number(),
  person_cos_p: z.number(),
  person_total_cos_p: z.number(),
  ymonth: dateString,
});
export type ListActiveAssignmentsMargin = z.infer<typeof ListActiveAssignmentsMarginSchema>;
export type ListActiveAssignmentsMarginResponse = ReportResponse<ListActiveAssignmentsMargin>;

//Filters
export const ListActiveAssignmentsMarginFiltersSchema = z.object({
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
  project_id: z
    .record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN, FilterOperations.NOT_IN]),
      z.string().or(z.array(z.string())),
    )
    .optional(),
  client_id: z
    .record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN, FilterOperations.NOT_IN]),
      z.string().or(z.array(z.string())),
    )
    .optional(),
  department_id: z
    .record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN, FilterOperations.NOT_IN]),
      z.string().or(z.array(z.string())),
    )
    .optional(),
});
export type ListActiveAssignmentsMarginFilters = z.infer<
  typeof ListActiveAssignmentsMarginFiltersSchema
>;

//Query
export const ListZeroRevRateAssignmentSorterEnumSchema = z.enum([
  'client_name',
  'project_name',
  'position_name',
  'department_name',
  'person_name',
  'dtstart',
  'dtend',
  'person_cos_p',
  'person_total_cos_p',
]);
export type ListZeroRevRateAssignmentSorterEnum = z.infer<
  typeof ListZeroRevRateAssignmentSorterEnumSchema
>;
export const ListZeroRevRateAssignmentSorterSchema = makeSorter(
  ListZeroRevRateAssignmentSorterEnumSchema,
);
export type ListZeroRevRateAssignmentSorter = z.infer<typeof ListZeroRevRateAssignmentSorterSchema>;

export const ListActiveAssignmentsMarginQuerySchema = ReportsQuerySchema.extend({
  sorter: ListZeroRevRateAssignmentSorterSchema.optional(),
  filters: ListActiveAssignmentsMarginFiltersSchema,
});
export type ListActiveAssignmentsMarginQuery = z.infer<
  typeof ListActiveAssignmentsMarginQuerySchema
>;

//Endpoint
export type ListActiveAssignmentsMarginEndpoint = Endpoint<
  Method.GET,
  '/api/v2/assignments/active-margin',
  ListActiveAssignmentsMarginResponse,
  undefined,
  ListActiveAssignmentsMarginQuery,
  undefined
>;
