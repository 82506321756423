import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const ForecastSchema = z.object({
  forecast_id: z.number(),
  client_id: z.string(),
  project_id: z.string().nullable(),
  version_id: z.number(),
  yyear: z.number(),
  name: z.string(),
  months_amount: z.array(z.number()),
  dt_created: dateString,
  dt_updated: dateString,
});
export type Forecast = z.infer<typeof ForecastSchema>;
