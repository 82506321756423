import moment from 'moment';
import { Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { ActionComponent } from '.';
import { useUpdateNotification } from '@src/queries/Notification';
import { useICESetConducted } from '@src/queries/InterviewCalendarEvent';

export const InterviewConducted: ActionComponent = ({ notification }) => {
  const { mutateAsync: updateNotification } = useUpdateNotification();

  const { mutateAsync: updateICE } = useICESetConducted({
    onSuccess: () => {
      updateNotification({
        params: { action_request_id: notification.action_request_id },
        request: { dt_action_completed: moment().toISOString() },
      });
    },
  });

  const markAsHappened = () => {
    updateICE({
      params: {
        interview_calendar_event_id: notification.metadata?.interview_calendar_event_id as number,
      },
    });
  };

  return (
    <Button type="link" size="small" icon={<CheckOutlined />} onClick={markAsHappened}>
      Mark as Conducted
    </Button>
  );
};

export default InterviewConducted;
