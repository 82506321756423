import _debounce from 'lodash/debounce';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import _get from 'lodash/get';
import moment from 'moment/moment';
import config from '@src/config';
import qs from 'qs';

// import { getWebsocketClient } from '@shared/websocket';
import { getJwtData } from '@src/shared/Util';
import { getSocketIoClient } from '@shared/socket-io';
import { getFingerprint, getUser, store } from '@redux/store';

// Local development setup
import https from 'https';

const { API_BASE, BRAINS_API_BASE, AUTH_API_BASE, NEST_API_BASE } = config;
const { actions } = require('@redux/modules/authentication');
const { constants } = require('@redux/modules/authentication');

const _getPath = async (path, opts) => {
  try {
    const parsedUrl = /(.*)(\?.*)/.exec(path);
    let urlPath = path;
    let urlQuery = '';
    if (parsedUrl) {
      [, urlPath, urlQuery] = parsedUrl;
    }

    const queryParams = urlQuery ? qs.parse(urlQuery, { ignoreQueryPrefix: true }) : {};

    const params = opts.qs || opts.params || {};

    // append params with socket_id
    const socket = await getSocketIoClient();
    if (socket && socket.id) {
      params.socket_id = socket.id;
    }

    const queryString = qs.stringify(
      {
        ...queryParams,
        ...params,
      },
      {
        addQueryPrefix: true,
        encode: opts.encode || false,
      },
    );

    return urlPath + queryString;
  } catch (err) {
    console.error('_getPath err', err);
    return path;
  }
};

const _getResponse = async (path, options, signal, baseUrl = API_BASE) => {
  let opts = getPayload(options);

  // refresh the "access token" if expired
  if (isTokenExpired()) {
    const refreshedUser = await debouncedRefreshSession().catch((err) => {
      store.dispatch(actions.logout());
      throw createError(err);
    });

    if (refreshedUser) {
      updateUserRefreshToken(refreshedUser);
      opts = getPayload(options);
    }
  }

  const _path = await _getPath(path, opts);
  const requestUrl = options.isAuthV3
    ? // new auth service URL
      `${AUTH_API_BASE}/api/v3${_path}`
    : options.isNestApi
    ? `${NEST_API_BASE}/api/v3${_path}`
    : `${baseUrl}/api/v2${_path}`;

  const response = await fetch(requestUrl, { ...opts, signal });

  return response;
};

const request = async (path, options, signal, baseUrl = 'hire-api') => {
  const response = await _getResponse(
    path,
    options,
    signal,
    baseUrl === 'brains-api' ? BRAINS_API_BASE : API_BASE,
  );
  const json = options.asRaw ? response : await jsonBody(response);

  if (response.status === 401 && isLoggedIn()) {
    store.dispatch(actions.logout());
    setTimeout(() => {
      store.dispatch(actions.login());
    }, 1000);
    throw createError(json);
  }

  if (!response.ok) {
    throw createError(json, response.status);
  }

  return json;
};

function getPayload(options) {
  const { body } = options;
  const isJson = Object.prototype.toString.call(body) === '[object Object]';

  // (DEV) Ignored self-signed cert
  const agent = new https.Agent({ rejectUnauthorized: config.ENV !== 'local' });

  let headers = isJson ? { 'Content-Type': 'application/json' } : {};

  headers = Object.assign(headers, options.headers, {
    Authorization: getAuthorization(options),
  });

  const extras = isJson ? { body: JSON.stringify(body) } : {};

  const result = Object.assign({}, options, { headers }, extras, { agent });

  return result;
}

const isTokenExpired = () => {
  const user = getUser();
  if (user) {
    const { expires } = user;
    const timeLeft = moment.unix(expires).diff(moment.utc());

    if (timeLeft <= threshold) {
      return true;
    }
  }

  return false;
};

const isLoggedIn = () => {
  return getUser();
};

export const refreshSession = async (path, token) => {
  const user = getUser();
  const fingerprint = getFingerprint();
  const refreshToken = _get(user, 'refreshToken') || token;
  const refreshPath = '/auth/refresh-token';
  const options = getPayload({
    authorizationHeader: `Bearer ${refreshToken}`,
    body: { refreshToken, fingerprint },
    method: 'POST',
  });

  const response = await fetch(`${path || AUTH_API_BASE}/api/v3${refreshPath}`, options);

  if (!response.ok) {
    throw new Error(`Refresh session failed`);
  }
  return response.json();
};

const debouncedRefreshSession = _debounce(refreshSession, 1000, {
  leading: true,
  trailing: false,
});

const updateUserRefreshToken = (payload) => {
  const { user, permissions } = getJwtData(payload);

  store.dispatch({
    type: constants.AUTHENTICATION_REFRESH.SUCCESS,
    user,
  });

  if (permissions) {
    store.dispatch({
      type: constants.AUTHENTICATION_GET_PERMISSIONS.SUCCESS,
      permissions,
    });
  }
};

function createError(data, status) {
  if (_isString(data.message)) {
    return data.message;
  } else if (_isObject(data.error)) {
    return data.error.message;
  } else if (_isString(data.error)) {
    return data.error;
  } else {
    return 'Unexpected error';
  }
}

const getAuthorization = ({ authorizationHeader } = {}) => {
  const user = getUser();
  const token = user?.accessToken;
  return authorizationHeader || (token ? `JWT ${token}` : void 0);
};

const threshold = 30 * 1000; // 30 sec

const jsonBody = async (response) => {
  try {
    return response.json();
  } catch (err) {
    console.warn('The server did not send a JSON response', err);
    return {};
  }
};

export { getAuthorization };
export default request;
