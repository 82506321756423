import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const PositionTemplateSkillSchema = z.object({
  position_template_id: z.number(),
  skill_id: z.number(),
  dt_created: dateString,
});

export type PositionTemplateSkill = z.infer<typeof PositionTemplateSkillSchema>;
