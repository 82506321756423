import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';
import { setActions } from './_utils';

import map from 'lodash/map';
import values from 'lodash/values';
import zipObject from 'lodash/zipObject';
import mapValues from 'lodash/mapValues';
import defaults from 'lodash/defaults';

// map
const ACTION_MAP = {
  getQuestions: {
    key: 'SCORECARD_QUESTIONS_GET',
    opts: { successKey: 'questions' },
  },
  createQuestion: {
    key: 'SCORECARD_QUESTION_CREATE',
    opts: { successKey: null },
  },
  getQuestionDetails: {
    key: 'SCORECARD_QUESTION_DETAILS_GET',
    opts: { successKey: null },
  },
  updateQuestion: {
    key: 'SCORECARD_QUESTION_UPDATE',
    opts: { successKey: null },
  },
  deleteQuestion: {
    key: 'SCORECARD_QUESTION_DELETE',
    opts: { successKey: null },
  },
};

const _keys = map(values(ACTION_MAP), 'key');

const _values = map(_keys, (key) => createSagaAction(key));

export const constants = zipObject(_keys, _values);

export const actions = mapValues(ACTION_MAP, (o) => {
  return (data) => defaults({ type: constants[o.key].REQUEST }, data);
});

// Reducer
export const initialState = {
  isLoading: false,
  questions: [],
};

const actionsDefinitions = values(ACTION_MAP);
const ACTION_HANDLERS = setActions(actionsDefinitions, constants);

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
