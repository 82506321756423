// GET APPLICATION_VIDEO_ANSWERS
import * as api from '@redux/api/application';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';

function* getApplicationVideoAnswers(action) {
  try {
    const response = yield call(api.getApplicationVideoAnswers, action);

    const applicationVideoAnswers = response.data;
    yield put({ type: constants.APPLICATION_VIDEO_ANSWERS_GET.SUCCESS, applicationVideoAnswers });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_VIDEO_ANSWERS_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetApplicationVideoAnswers() {
  yield takeLatest(constants.APPLICATION_VIDEO_ANSWERS_GET.REQUEST, getApplicationVideoAnswers);
}

// DELETE APPLICATION_VIDEO_ANSWER
function* deleteApplicationVideoAnswer(action) {
  try {
    yield call(api.deleteApplicationVideoAnswer, action);
    const deletedId = action.answerId;
    yield put({ type: constants.APPLICATION_VIDEO_ANSWER_DELETE.SUCCESS, deletedId });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_VIDEO_ANSWER_DELETE.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchDeleteApplicationVideoAnswer() {
  yield takeLatest(constants.APPLICATION_VIDEO_ANSWER_DELETE.REQUEST, deleteApplicationVideoAnswer);
}
