import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { TimeOffSummary } from './base';

export const GetTimeOffSummaryParamsSchema = z.object({
  personId: z.string(),
});

export type GetTimeOffSummaryParams = z.infer<typeof GetTimeOffSummaryParamsSchema>;

export type GetTimeOffSummaryEndpoint = Endpoint<
  Method.GET,
  `/api/v2/person/${GetTimeOffSummaryParams['personId']}/timeoffs/summary`,
  TimeOffSummary,
  GetTimeOffSummaryParams,
  undefined,
  undefined
>;
