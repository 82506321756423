import { z } from 'zod';
import { Lead, LeadSchema } from './base';
import { Endpoint, Method } from '../../infrastructure';

export const LeadCreateSchema = LeadSchema.pick({
  client_id: true,
  dtplannedend: true,
  dtplannedstart: true,
  duration: true,
  hard_date: true,
  hire_group: true,
  name: true,
  timezone: true,
  expected_monthly_revenue: true,
  expected_monthly_cost: true,
  expected_team_size: true,
  owner_id: true,
  project_sf_id: true,
  sf_stage: true,
  sf_description: true,
  sf_description_updated_at: true,
  sf_notes_updated_at: true,
  sf_expected_monthly_revenue: true,
  is_pre_opportunity: true,
  duration_weeks: true,
  is_draft_opportunity: true,
}).partial({
  is_draft_opportunity: true,
  project_sf_id: true,
  sf_description: true,
  sf_description_updated_at: true,
  sf_notes_updated_at: true,
  sf_stage: true,
  owner_id: true,
  sf_expected_monthly_revenue: true,
  is_pre_opportunity: true,
  duration_weeks: true,
});

export type LeadCreate = z.infer<typeof LeadCreateSchema>;

export const LeadCreateBodySchema = LeadCreateSchema.pick({
  client_id: true,
  dtplannedstart: true,
  dtplannedend: true,
  duration: true,
  hard_date: true,
  hire_group: true,
  name: true,
  timezone: true,
  expected_monthly_revenue: true,
  expected_monthly_cost: true,
  expected_team_size: true,
  is_pre_opportunity: true,
  is_draft_opportunity: true,
})
  .extend({
    client_name: z.string().nullish(),
    client_short_name: z.string().nullish(),
  })
  .partial({
    dtplannedend: true,
    duration: true,
  });
export type LeadCreateBody = z.infer<typeof LeadCreateBodySchema>;

export const LeadCreateParamsSchema = z.object({
  project_id: z.string(),
});

export type LeadCreateParams = z.infer<typeof LeadCreateParamsSchema>;

export type LeadCreateResponse = Lead;

export type LeadCreateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/sql-leads/`,
  LeadCreateResponse,
  LeadCreateParams,
  undefined,
  LeadCreateBody
>;
