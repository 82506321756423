import { z } from 'zod';
import { SkillSchema } from './base';

export const SkillCreateSchema = SkillSchema.pick({
  mongo_skill_id: true,
  internal_sourcing: true,
  name: true,
  seq: true,
  recruiting_mode: true,
  description: true,
});
export type SkillCreate = z.infer<typeof SkillCreateSchema>;
