import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const FinBudgetDeleteParamsSchema = z.object({
  budget_id: z.coerce.number(),
});
export type FinBudgetDeleteParams = z.infer<typeof FinBudgetDeleteParamsSchema>;

export type FinBudgetDeleteEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/fin-budgets/${FinBudgetDeleteParams['budget_id']}`,
  undefined,
  FinBudgetDeleteParams,
  undefined,
  undefined
>;
