import request from '../../shared/request';

export function getAllMediums() {
  return request('/mediums', {
    method: 'GET',
  });
}

export function createMedium({ medium }) {
  return request('/mediums', {
    method: 'POST',
    body: medium,
  });
}

export function updateMedium({ id, medium }) {
  return request(`/mediums/${id}`, {
    method: 'PUT',
    body: medium,
  });
}

export function deleteMedium({ id }) {
  return request(`/mediums/${id}`, {
    method: 'DELETE',
  });
}
