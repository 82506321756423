import { message as Message } from 'antd';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  ASSIGNMENTS_REPORT_APPLICATION_BRAINS_ASSIGNMENTS_UPDATE: createSagaAction(
    'ASSIGNMENTS_REPORT_APPLICATION_BRAINS_ASSIGNMENTS_UPDATE',
  ),
};

const actions = {
  updateApplicationBrainsAssignments: (
    leadId,
    positionId,
    personId,
    assignment = null,
    callbackFn,
  ) => ({
    type: constants.ASSIGNMENTS_REPORT_APPLICATION_BRAINS_ASSIGNMENTS_UPDATE.REQUEST,
    leadId,
    positionId,
    personId,
    assignment,
    callbackFn,
  }),
  updateApplicationBrainsAssignmentsSuccess: (applicationId) => ({
    type: constants.ASSIGNMENTS_REPORT_APPLICATION_BRAINS_ASSIGNMENTS_UPDATE.SUCCESS,
  }),
  updateApplicationBrainsAssignmentsFail: (applicationId) => ({
    type: constants.ASSIGNMENTS_REPORT_APPLICATION_BRAINS_ASSIGNMENTS_UPDATE.FAILURE,
    applicationId,
  }),
};

const handlers = {
  [constants.ASSIGNMENTS_REPORT_APPLICATION_BRAINS_ASSIGNMENTS_UPDATE.REQUEST]: (
    state,
    { leadId, positionId, personId },
  ) => {
    return {
      ...state,
      isActionRunning: true,
      leadId,
      positionId,
      personId,
    };
  },
  [constants.ASSIGNMENTS_REPORT_APPLICATION_BRAINS_ASSIGNMENTS_UPDATE.SUCCESS]: (
    state,
    { lead, callbackFn },
  ) => {
    const leadsDetailsData = { ...state.leadsDetailsData };

    leadsDetailsData[lead._id] = { ...leadsDetailsData[lead._id], ...lead };

    if (callbackFn) {
      callbackFn();
    }
    Message.success('Assignment updated successfully');

    return {
      ...state,
      isActionRunning: false,
      leadsDetailsData,
    };
  },
  [constants.ASSIGNMENTS_REPORT_APPLICATION_BRAINS_ASSIGNMENTS_UPDATE.FAILURE]: (state, action) => {
    const { message } = action;
    Message.error(`Error updating assignment ${message}`);
    return { ...state, isActionRunning: false };
  },
};

export function* watchNull() {}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchNull,
};
