import each from 'lodash/each';
import isUndefined from 'lodash/isUndefined';

export const setActions = (actionsDefinitions = [], constants) => {
  return actionsDefinitions.reduce((accumulator, nextValue) => {
    const newActionHandler = _setAction(constants, nextValue.key, nextValue.opts);
    return { ...accumulator, ...newActionHandler };
  }, {});
};
const _setAction = (constants, key, opts) => {
  const actionKey = constants[key];

  const ACTION_HANDLER = {};
  each(
    {
      REQUEST: null,
      SUCCESS: isUndefined(opts.successKey) ? 'data' : opts.successKey,
      FAILURE: isUndefined(opts.failureKey) ? 'error' : opts.failureKey,
    },
    (dataKey, stateKey) => {
      ACTION_HANDLER[actionKey[stateKey]] = (state, data) => {
        const result = {
          ...state,
          [opts.isLoadingKey || 'isLoading']: stateKey === 'REQUEST',
        };

        if (dataKey) {
          const value = (opts.getValueFn && opts.getValueFn(state, data)) || data[dataKey];
          result[dataKey] = value;
        }

        return result;
      };
    },
  );
  return ACTION_HANDLER;
};
/*

// this call ↑ would be converted into this:

  ACTION_HANDLERS[constants.YYYYY.SUCCESS] = (state, { recent }) => ({
    ...state, recent
  })
  ACTION_HANDLERS[constants.YYYYY.FAILURE] = (state, { error }) => ({
    ...state, error
  })
 */
