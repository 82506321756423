import { Endpoint, Method } from '../../../infrastructure';
import { z } from 'zod';
import { PsnRctrSchema } from '../base';

export const PsnRctrDeleteParamsSchema = PsnRctrSchema.pick({
  person_id: true,
});
export type PsnRctrDeleteParams = z.infer<typeof PsnRctrDeleteParamsSchema>;

export type PsnRctrDeleteResponse = void;

export type PsnRctrDeleteEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/psn-rctr/${PsnRctrDeleteParams['person_id']}`,
  PsnRctrDeleteResponse,
  PsnRctrDeleteParams,
  undefined,
  undefined
>;
