import { call, put, takeLatest } from 'redux-saga/effects';

import { constants, ACTION_MAP } from '@redux/modules/reports/assignments-report';
import * as api from '@redux/api/assignments-report';

import isFunction from 'lodash/isFunction';

const REQUEST = 'assignPersonOrApplicationOnBrains';
const _key = ACTION_MAP[REQUEST];

// ASSIGN TO BRAINS POSITION

function* assignPersonOrApplicationOnBrains(action) {
  try {
    const response = yield call(api.assignPersonOrApplicationOnBrains, action);

    yield put({
      type: constants[_key].SUCCESS,
      assigned: response,
    });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(response.message);
    }
  } catch (e) {
    yield put({
      type: constants[_key].FAILURE,
      error: e.message || e,
    });

    if (isFunction(action.onError)) {
      yield action.onError(e.message || e);
    }
  }
}

export function* watchAssignPersonOrApplicationOnBrains() {
  yield takeLatest(constants[_key].REQUEST, assignPersonOrApplicationOnBrains);
}
