import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';

export const PeopleMonthAnalysisSchema = z.object({
  person_id: z.string(),
  firstname: z.string(),
  surname: z.string(),
  name: z.string(),
  dthired: dateString,
  dtstart: dateString,
  email: z.string(),
  role: z.string().nullable(),
  is_hired_in_this_month: z.boolean(),
  department: z.string(),
  isbillable: z.boolean(),
  dttermination: dateString.nullable(),
  reason: z.string().nullable(),
  startedby: z.string().nullable(),
  is_immediate_termination: z.boolean().nullable(),
  term_days_paid: z.number().nullable(),
  voluntary: z.boolean().nullable(),
  hired_time: z.number().nullable(),
  immigration_program: z.string().nullable(),
  country: z.string(),
  ymonth: z.string(),
});
export type PeopleMonthAnalysis = z.infer<typeof PeopleMonthAnalysisSchema>;

export const StaffAnalysisSchema = z.object({
  people_month_analysis: z.array(PeopleMonthAnalysisSchema),
  product_per_client: z.number(),
});
export type StaffAnalysis = z.infer<typeof StaffAnalysisSchema>;
//Query

export const PeopleMonthAnalysisFiltersSchema = z.object({
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
});
export type PeopleMonthAnalysisFilters = z.infer<typeof PeopleMonthAnalysisFiltersSchema>;

export const PeopleMonthAnalysisQuerySchema = z.object({
  filters: PeopleMonthAnalysisFiltersSchema.partial(),
});
export type PeopleMonthAnalysisQuery = z.infer<typeof PeopleMonthAnalysisQuerySchema>;

export type PeopleMonthAnalysisResponse = StaffAnalysis;

export type PeopleMonthAnalysisEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/people-month-analysis',
  PeopleMonthAnalysisResponse,
  undefined,
  PeopleMonthAnalysisQuery,
  undefined
>;
