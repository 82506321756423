import { z } from 'zod';
import { dateString } from '../../infrastructure';

export enum InterviewRequestStage {
  '1st' = '1st',
  '2nd' = '2nd',
  'Other' = 'Other',
}
export const InterviewRequestSchema = z.object({
  interview_request_id: z.number(),
  applicant_id: z.string(),
  application_id: z.string(),
  stage: z.nativeEnum(InterviewRequestStage),
  ts_interview_request: dateString,
  req_recruiter_id: z.string().nullable(),
  ask_recruiter_id: z.string().nullable(),

  num_days: z.number().nullable(),
  dt_scheduled: dateString.nullable(),
  dt_canceled: dateString.nullable(),
  dt_conducted: dateString.nullable(),
  act_recruiter_id: z.string().nullable(),

  dt_created: dateString,
  dt_updated: dateString,
});
export type InterviewRequest = z.infer<typeof InterviewRequestSchema>;
