import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { TimeOffSummaryHistoryItem } from './base';

export const GetTimeOffSummaryHistoryParamsSchema = z.object({
  personId: z.string(),
});

export type GetTimeOffSummaryHistoryParams = z.infer<typeof GetTimeOffSummaryHistoryParamsSchema>;

export type GetTimeOffSummaryHistoryResponse = {
  summaryHistory: TimeOffSummaryHistoryItem[];
};

export type GetTimeOffSummaryHistoryEndpoint = Endpoint<
  Method.GET,
  `/api/v2/person/${GetTimeOffSummaryHistoryParams['personId']}/timeoffs/summary/history`,
  GetTimeOffSummaryHistoryResponse,
  GetTimeOffSummaryHistoryParams,
  undefined,
  undefined
>;
