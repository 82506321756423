import { call, put, takeLatest } from 'redux-saga/effects';

import { createSagaAction } from '@shared/sagas';
import request from '@shared/request';

// MODULE
const constants = {
  LEAD_GROUP_UPDATE: createSagaAction('LEAD_GROUP_UPDATE'),
};

const actions = {
  updateLeadGroup: (lead, hireGroup) => ({
    type: constants.LEAD_GROUP_UPDATE.REQUEST,
    lead,
    hireGroup,
  }),
};

const handlers = {
  [constants.LEAD_GROUP_UPDATE.REQUEST]: (state) => {
    return {
      ...state,
      isLoading: true,
      error: null,
      updateSuccessMessage: null,
      updateErrorMessage: null,
    };
  },
  [constants.LEAD_GROUP_UPDATE.SUCCESS]: (state) => {
    return {
      ...state,
      isLoading: false,
      error: null,
      updateSuccessMessage: null,
      updateErrorMessage: null,
    };
  },
  [constants.LEAD_GROUP_UPDATE.FAILURE]: (state, { message }) => {
    return {
      ...state,
      isLoading: false,
      updateErrorMessage: message,
      updateSuccessMessage: null,
    };
  },
};

// SAGA
function* sagaUpdateLeadGroup(action) {
  try {
    const { lead, hireGroup } = action;

    const leadToUpdate = {
      id: lead._id,
      hireGroup,
    };

    const updatedLead = yield call(apiUpdateLeadGroup, leadToUpdate);

    yield put({
      type: constants.LEAD_GROUP_UPDATE.SUCCESS,
      message: 'Opportunity updated successfully!',
      updatedLead,
    });
  } catch (e) {
    yield put({
      type: constants.LEAD_GROUP_UPDATE.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchUpdateLeadGroup() {
  yield takeLatest(constants.LEAD_GROUP_UPDATE.REQUEST, sagaUpdateLeadGroup);
}

// API
function apiUpdateLeadGroup(lead) {
  return request(`/leads/${lead.id}/hire-group`, {
    method: 'PUT',
    body: {
      hireGroup: lead.hireGroup,
    },
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchUpdateLeadGroup,
};
