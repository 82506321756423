import { Endpoint, Method } from '../../../infrastructure';
import { z } from 'zod';
import { PsnRctrGoogleCalendarChannel, PsnRctrGoogleCalendarChannelSchema } from '../base';

export const PsnRctrGoogleCalendarChannelGetParamsSchema = PsnRctrGoogleCalendarChannelSchema.pick({
  calendar_id: true,
});
export type PsnRctrGoogleCalendarChannelGetParams = z.infer<
  typeof PsnRctrGoogleCalendarChannelGetParamsSchema
>;

export type PsnRctrGoogleCalendarChannelGetResponse = PsnRctrGoogleCalendarChannel | null;

export type PsnRctrGoogleCalendarChannelGetEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/psn-rctr-google-calendar-channel/${PsnRctrGoogleCalendarChannelGetParams['calendar_id']}`,
  PsnRctrGoogleCalendarChannelGetResponse,
  PsnRctrGoogleCalendarChannelGetParams,
  undefined,
  undefined
>;
