import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  ACCOUNT_GET: createSagaAction('ACCOUNT_GET'),
};

const actions = {
  get: (data) => ({
    ...data,
    type: constants.ACCOUNT_GET.REQUEST,
  }),
};

const handlers = {
  [constants.ACCOUNT_GET.REQUEST]: (state, action) => {
    return { ...state, isLoading: true, data: [] };
  },
  [constants.ACCOUNT_GET.SUCCESS]: (state, { data }) => {
    return { ...state, isLoading: false, data };
  },
  [constants.ACCOUNT_GET.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

function* sagaGetAccount(action) {
  try {
    const { user: data } = yield call(apiGetAccount, action);
    yield put({ type: constants.ACCOUNT_GET.SUCCESS, data });
  } catch (e) {
    yield put({
      type: constants.ACCOUNT_GET.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetAccount() {
  yield takeLatest(constants.ACCOUNT_GET.REQUEST, sagaGetAccount);
}

// API

function apiGetAccount() {
  return request('/account', {
    method: 'GET',
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchGetAccount,

  // optionally we may
  // api: {
  //   getAccount: apiGetAccount
  // }
};
