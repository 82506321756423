import { createSagaAction } from '@shared/sagas';
import findIndex from 'lodash/findIndex';
import each from 'lodash/each';

// MODULE

const constants = {
  ASSIGNMENTS_REPORT_APPLICATION_EXCITEMENT_UPDATE: createSagaAction(
    'ASSIGNMENTS_REPORT_APPLICATION_EXCITEMENT_UPDATE',
  ),
};

const actions = {
  updateApplicationExcitement: (applicationId, excitement) => ({
    type: constants.ASSIGNMENTS_REPORT_APPLICATION_EXCITEMENT_UPDATE.REQUEST,
    applicationId,
    excitement,
  }),
  updateApplicationExcitementSuccess: (applicationId) => ({
    type: constants.ASSIGNMENTS_REPORT_APPLICATION_EXCITEMENT_UPDATE.SUCCESS,
  }),
  updateApplicationExcitementFail: (applicationId) => ({
    type: constants.ASSIGNMENTS_REPORT_APPLICATION_EXCITEMENT_UPDATE.FAILURE,
    applicationId,
  }),
};

const handlers = {
  [constants.ASSIGNMENTS_REPORT_APPLICATION_EXCITEMENT_UPDATE.REQUEST]: (
    state,
    { applicationId, excitement },
  ) => {
    const leadsAssignmentsData = state.leadsAssignmentsData;
    each(Object.keys(leadsAssignmentsData), (leadId) => {
      const leadPositions = leadsAssignmentsData[leadId];
      for (const positionId in leadPositions) {
        const idx = findIndex(leadPositions[positionId], (item) => item._id === applicationId);
        if (idx >= 0) {
          const assignmentApplication = leadPositions[positionId][idx];
          assignmentApplication.excitementBackup = assignmentApplication.excitementRate;
          assignmentApplication.excitementRate = excitement;
        }
      }
    });
    return {
      ...state,
      leadsAssignmentsData: { ...leadsAssignmentsData },
      isActionRunning: true,
    };
  },
  [constants.ASSIGNMENTS_REPORT_APPLICATION_EXCITEMENT_UPDATE.SUCCESS]: (state, action) => {
    return { ...state, isActionRunning: false };
  },
  [constants.ASSIGNMENTS_REPORT_APPLICATION_EXCITEMENT_UPDATE.FAILURE]: (
    state,
    { applicationId },
  ) => {
    const leadsAssignmentsData = state.leadsAssignmentsData;
    each(Object.keys(leadsAssignmentsData), (leadId) => {
      const leadPositions = leadsAssignmentsData[leadId];
      for (const positionId in leadPositions) {
        const idx = findIndex(leadPositions[positionId], (item) => item._id === applicationId);
        if (idx >= 0) {
          const assignmentApplication = leadPositions[positionId][idx];
          assignmentApplication.excitementRate = assignmentApplication.excitementBackup;
          delete assignmentApplication.excitementBackup;
        }
      }
    });
    return {
      ...state,
      leadsAssignmentsData: { ...leadsAssignmentsData },
      isActionRunning: false,
    };
  },
};

export function* watchNull() {}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchNull,
};
