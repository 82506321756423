import { call, put, takeEvery } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';
import isFunction from 'lodash/isFunction';

import * as schemas from '@src/schemas';
import { actions as entitiesActions } from '@redux/modules/entities';
import { constants as applicationConstants } from '@redux/modules/application/application';
import { getApplicationInterviewList } from '@redux/api/application';

// MODULE

const constants = {
  COMMENT_POST: createSagaAction('COMMENT_POST'),
};

const actions = {
  post: (data) => ({
    ...data,
    type: constants.COMMENT_POST.REQUEST,
  }),
};

const handlers = {
  [constants.COMMENT_POST.REQUEST]: (state, action) => {
    return { ...state, isLoading: true, data: [] };
  },
  // [ constants.COMMENT_POST.SUCCESS ]: (state, { data }) => {
  //   return { ...state, isLoading: false, data };
  // },
  // [ constants.COMMENT_POST.FAILURE ]: (state, { error }) => {
  //   return { ...state, isLoading: false, error };
  // }
};

// SAGA

function* sagaPostComment(action) {
  try {
    const response = yield call(apiPostComment, action);
    const normalized = normalize(response.application, schemas.application);
    const { comments, applications } = normalized.entities;

    yield put(entitiesActions.mergeApplications(applications));
    yield put(entitiesActions.mergeComments(comments));

    yield put({ type: constants.COMMENT_POST.SUCCESS });

    if (action.commentType === 'interview-summary') {
      const interviewList = yield call(getApplicationInterviewList, action);
      yield put({
        type: applicationConstants.APPLICATION_INTERVIEW_LIST_GET.SUCCESS,
        interviewList,
      });
    }

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(comments, applications);
    }
  } catch (e) {
    console.error('comment post error', e);
    yield put({
      type: constants.COMMENT_POST.FAILURE,
      error: e.message || e,
    });

    if (isFunction(action.onFailure)) {
      yield action.onFailure(e.message || e);
    }
  }
}

function* watchPostComment() {
  yield takeEvery(constants.COMMENT_POST.REQUEST, sagaPostComment);
}

// API

function apiPostComment({ applicationId, commentType, comment }) {
  return request(`/applications/${applicationId}/comments`, {
    method: 'POST',
    body: {
      id: applicationId,
      commentType,
      comment,
    },
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchPostComment,

  // optionally we may
  // api: {
  //   postComment: apiPostComment
  // }
};
