import { call, put, takeLatest } from 'redux-saga/effects';
import queryString from 'query-string';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  ASSIGNMENTS_REPORT_ALL_LEADS_ASSIGNMENTS_GET: createSagaAction(
    'ASSIGNMENTS_REPORT_ALL_LEADS_ASSIGNMENTS_GET',
  ),
};

const actions = {
  getAllLeadsAssignments: (leadsIds = []) => ({
    type: constants.ASSIGNMENTS_REPORT_ALL_LEADS_ASSIGNMENTS_GET.REQUEST,
    leadsIds,
  }),
};

const handlers = {
  [constants.ASSIGNMENTS_REPORT_ALL_LEADS_ASSIGNMENTS_GET.REQUEST]: (state, action) => {
    return { ...state, isAllLeadsAssignmentsLoading: true };
  },
  [constants.ASSIGNMENTS_REPORT_ALL_LEADS_ASSIGNMENTS_GET.SUCCESS]: (state, { data }) => {
    return {
      ...state,
      isAllLeadsAssignmentsLoading: false,
      allLeadsAssignmentsData: data,
    };
  },
  [constants.ASSIGNMENTS_REPORT_ALL_LEADS_ASSIGNMENTS_GET.FAILURE]: (state, { error }) => {
    return { ...state, isAllLeadsAssignmentsLoading: false, error };
  },
};

// SAGA

export function* sagaGetAllLeadsAssignments({ leadsIds }) {
  try {
    const data = yield call(apiGetAllLeadsAssignments, leadsIds);
    yield put({
      type: constants.ASSIGNMENTS_REPORT_ALL_LEADS_ASSIGNMENTS_GET.SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: constants.ASSIGNMENTS_REPORT_ALL_LEADS_ASSIGNMENTS_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetAllLeadsAssignments() {
  yield takeLatest(
    constants.ASSIGNMENTS_REPORT_ALL_LEADS_ASSIGNMENTS_GET.REQUEST,
    sagaGetAllLeadsAssignments,
  );
}

// API

export function apiGetAllLeadsAssignments(leadsIds = []) {
  const leadsParam = queryString.stringify(
    {
      assignment: leadsIds,
    },
    {
      arrayFormat: 'bracket',
    },
  );
  return request(`/assignments-report/assignments?${leadsParam}`, {
    method: 'GET',
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchGetAllLeadsAssignments,
};
