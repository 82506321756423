import { all, fork } from 'redux-saga/effects';

import {
  watchGetLeads,
  watchUpdateLeadName,
  watchGetLeadByID,
  watchRemoveLeadByID,
  watchUpdateClientAndProjectNotes,
  watchUpdateBrainsAssign,
} from './leads';
import { watchGetStatusApplications } from './people-statuses';
import { watchAssignOnBrains } from './assign';
import { watchAssignPersonOrApplicationOnBrains } from './assign-person-application';

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  const sagas = [
    watchGetLeads,
    watchUpdateLeadName,
    watchUpdateClientAndProjectNotes,
    watchGetLeadByID,
    watchRemoveLeadByID,
    watchGetStatusApplications,
    watchAssignOnBrains,
    watchUpdateBrainsAssign,
    watchAssignPersonOrApplicationOnBrains,
  ].map(fork);

  yield all(sagas);
}
