import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import { constants } from '@redux/modules/reports/daily-funnel-report';
import * as api from '@redux/api/daily-funnel-report';

// GET DAILY FUNNEL REPORT
function* getDailyFunnelReport(action) {
  try {
    const response = yield call(api.getDailyFunnelReport, action);
    const data = response.data;

    yield put({
      type: constants.DAILY_FUNNEL_REPORT_GET.SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: constants.DAILY_FUNNEL_REPORT_GET.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetDailyFunnelReport() {
  yield takeLatest(constants.DAILY_FUNNEL_REPORT_GET.REQUEST, getDailyFunnelReport);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([fork(watchGetDailyFunnelReport)]);
}
