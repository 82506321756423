import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const CommentTagSchema = z.object({
  comment_tag_id: z.number(),
  name: z.string(),
  mongo_id: z.string().nullable(),
  description: z.string().nullable(),
  emoji: z.string().nullable(),
  color: z.string().nullable(),
  author_id: z.string(),

  dt_created: dateString,
  dt_updated: dateString,
});

export type CommentTag = z.infer<typeof CommentTagSchema>;

export const ERROR_COMMENT_TAG_NOT_FOUND = 'Comment tag not found.';
export const ERROR_COMMENT_TAG_DUPLICATED_NAME =
  'Already exists comment tag with this name, duplication not allowed';

export const FINANCIAL_TAG_NAME = 'Financial';
