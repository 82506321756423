import request from '../../shared/request';

export function getStatusesReport({ from, to }) {
  return request(
    `/reports/statuses?from=${from.format('YYYY-MM-DD')}&to=${to.format('YYYY-MM-DD')}
    `,
    {
      method: 'GET',
    },
  );
}
