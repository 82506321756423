import { z } from 'zod';
import { ExternalGoogleCalendarEventSchema } from '../base';

export const GoogleCalendarEventCreateSchema = ExternalGoogleCalendarEventSchema.pick({
  summary: true,
  description: true,
  location: true,
  start: true,
  end: true,
  attendees: true,
});
export type GoogleCalendarEventCreate = z.infer<typeof GoogleCalendarEventCreateSchema>;
