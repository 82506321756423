import { call, put, takeLatest } from 'redux-saga/effects';

import isFunction from 'lodash/isFunction';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';
import { constants as jobsConstants } from '@redux/jobs';

// MODULE
const constants = {
  DELETE_JOB_IMAGE: createSagaAction('DELETE_JOB_IMAGE'),
};

const actions = {
  deleteJobImage: (args) => ({
    type: constants.DELETE_JOB_IMAGE.REQUEST,
    ...args,
  }),
};

const handlers = {};

// SAGA
function* deleteJobImage(action) {
  try {
    const response = yield call(apiDeleteJobImage, action);
    const updatedJob = response.data;

    yield put({ type: jobsConstants.UPDATE_JOB_IMAGE.SUCCESS, updatedJob });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(updatedJob);
    }
  } catch (error) {
    yield put({
      type: jobsConstants.UPDATE_JOB_IMAGE.FAILURE,
      error: error.message || error,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(error.message || error);
    }
  }
}

function* watchDeleteJobImage() {
  yield takeLatest(constants.DELETE_JOB_IMAGE.REQUEST, deleteJobImage);
}

// API
export function apiDeleteJobImage({ jobId }) {
  return request(`/jobs/${jobId}/image`, {
    method: 'DELETE',
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchDeleteJobImage,
};
