import { z } from 'zod';
import { ClientSchema } from '../base';
import { Endpoint, FilterOperations, Method } from '../../../infrastructure';

export const ClientListSchema = ClientSchema.pick({
  client_id: true,
  ext_billing_client_id: true,
  isactive: true,
  isdev: true,
  name: true,
  dtcreate: true,
  dtupdate: true,
});
export type ClientList = z.infer<typeof ClientListSchema>;

export const ClientListFiltersSchema = z.object({
  client_name: z.record(z.enum([FilterOperations.CONTAIN]), z.string()).optional(),
  client_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
});
export type ClientListFilters = z.infer<typeof ClientListFiltersSchema>;

export const ClientListQuerySchema = z.object({
  filters: ClientListFiltersSchema.optional(),
});
export type ClientListQuery = z.infer<typeof ClientListQuerySchema>;

export type ClientListResponse = ClientList[];

export type ClientListEndpoint = Endpoint<
  Method.GET,
  '/api/v2/clients/sql-list',
  ClientListResponse,
  undefined,
  ClientListQuery,
  undefined
>;
