import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

const constants = {
  JOB_PRIORITIES_SORT: createSagaAction('JOB_PRIORITIES_SORT'),
};

const actions = {
  sort: (data) => ({
    type: constants.JOB_PRIORITIES_SORT.REQUEST,
    ...data,
  }),
};

const handlers = {
  [constants.JOB_PRIORITIES_SORT.SUCCESS]: (state, { jobPriorities }) => {
    return { ...state, jobPriorities };
  },
  [constants.JOB_PRIORITIES_SORT.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

function* sortJobPriorities(action) {
  try {
    const response = yield call(apiSortJobPriorities, action);

    yield put({
      type: constants.JOB_PRIORITIES_SORT.SUCCESS,
      jobTypes: response.data,
    });
  } catch (e) {
    yield put({
      type: constants.JOB_PRIORITIES_SORT.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchSortJobPriorities() {
  yield takeLatest(constants.JOB_PRIORITIES_SORT.REQUEST, sortJobPriorities);
}

function apiSortJobPriorities({ jobPrioritiesIds }) {
  return request('/job-priorities/sort', {
    method: 'POST',
    body: {
      jobPrioritiesIds: jobPrioritiesIds || [],
    },
  });
}

export default {
  actions,
  constants,
  handlers,
  watcher: watchSortJobPriorities,
};
