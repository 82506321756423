import { z } from 'zod';
import { CSVEndpoint } from '../../infrastructure';
import { FilterOperations } from '@ct-internal/api';

export type CSVReconciliationEndpoint = CSVEndpoint<
  '/api/v2/sql-reports/reconciliation.csv',
  CSVReconciliationQuery
>;

export const CSVReconciliationFilterSchema = z.object({
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
});

export type CSVReconciliationFilter = z.infer<typeof CSVReconciliationFilterSchema>;

export const CSVReconciliationQuerySchema = z.object({
  filters: CSVReconciliationFilterSchema,
});

export type CSVReconciliationQuery = z.infer<typeof CSVReconciliationQuerySchema>;

export type CSVReconciliationResponse = string;
