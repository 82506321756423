import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { dateString } from '../../infrastructure/schema';

export const UploadRaiseRequestsRequestSchema = z.object({
  createdBy: z.string(),
  fileUpstream: z.any().optional(),
  user: z.string(),
});
export type UploadRaiseRequestsRequest = z.infer<typeof UploadRaiseRequestsRequestSchema>;

export type UploadRaiseRequestsEndpoint = Endpoint<
  Method.POST,
  '/api/v2/payouts/upload',
  {},
  undefined,
  undefined,
  UploadRaiseRequestsRequest
>;

export const ForeignCurrencies = Object.freeze({
  CAD: 'CAD',
  USD: 'USD',
});

export const RaiseRequestUploadRowSchema = z.object({
  personIdOrEmail: z.string(),
  foundPersonId: z.string(),
  personName: z.string(),
  amount: z.number(),
  startDate: dateString,
  foreignAmount: z.number().optional(),
  foreignCurrency: z.nativeEnum(ForeignCurrencies).optional(),
});
export type RaiseRequestUploadRow = z.infer<typeof RaiseRequestUploadRowSchema>;

export const UploadRaiseRequestsRowErrorSchema = z.object({
  personIdOrEmail: z.string(),
  foundPersonId: z.string(),
  personName: z.string(),
  amount: z.number(),
  startDate: dateString,
  foreignAmount: z.number().optional(),
  foreignCurrency: z.nativeEnum(ForeignCurrencies).optional(),
  errorMessage: z.string(),
});
export type UploadRaiseRequestsRowError = z.infer<typeof UploadRaiseRequestsRowErrorSchema>;

export const UploadRaiseRequestsResponseSchema = z.object({
  total: z.number(),
  processed: z.number(),
  failures: z.number(),
  failedRecords: z.array(UploadRaiseRequestsRowErrorSchema),
});
export type UploadRaiseRequestsResponse = z.infer<typeof UploadRaiseRequestsResponseSchema>;
