import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import { constants } from '@redux/modules/reports/sources-report/index';
import * as api from '@redux/api/sources-report';

// GET SOURCES
function* getSources(action) {
  try {
    const response = yield call(api.getSources, action);
    const data = response.data;

    yield put({ type: constants.SOURCES_REPORT_GET.SUCCESS, data });
  } catch (e) {
    yield put({
      type: constants.SOURCES_REPORT_GET.FAILURE,
      error: e.message || e,
    });
  }
}

// GET SOURCES
function* getAdvancedSourcingReport(action) {
  try {
    const response = yield call(api.getAdvancedSourcingReport, action);
    const data = response.data;

    yield put({
      type: constants.ADVANCED_SOURCING_REPORT_GET.SUCCESS,
      advancedSourcingReport: data,
    });
  } catch (e) {
    yield put({
      type: constants.ADVANCED_SOURCING_REPORT_GET.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetSources() {
  yield takeLatest(constants.SOURCES_REPORT_GET.REQUEST, getSources);
}

function* watchGetAdvancedSourcingReport() {
  yield takeLatest(constants.ADVANCED_SOURCING_REPORT_GET.REQUEST, getAdvancedSourcingReport);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([fork(watchGetSources)]);
  yield all([fork(watchGetAdvancedSourcingReport)]);
}
