import { put, takeEvery } from 'redux-saga/effects';

import set from 'lodash/set';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import merge from 'lodash/merge';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import inRange from 'lodash/inRange';
import parseInt from 'lodash/parseInt';
import map from 'lodash/map';
import some from 'lodash/some';

import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  SEARCH_APPLICATION_SOCKET_UPDATE: createSagaAction('SEARCH_APPLICATION_SOCKET_UPDATE'),
};

const { SEARCH_APPLICATION_SOCKET_UPDATE } = constants;

const actions = {
  processSocketUpdate: (data) => ({
    type: SEARCH_APPLICATION_SOCKET_UPDATE.REQUEST,
    data,
  }),
};

const handlers = {
  [SEARCH_APPLICATION_SOCKET_UPDATE.REQUEST]: (state) => state,
  [SEARCH_APPLICATION_SOCKET_UPDATE.SUCCESS]: (state, { socketEvent }) => {
    if (!socketEvent.application) {
      return state;
    }

    const newState = { ...state };

    const { applications, searchStatuses, salary, excitement } = state.results;

    const { application, updated, created, deleted, _id: eventId } = socketEvent;

    const { _id: applicationId } = application;

    const updateNewState = (_fields) => {
      const idx = findIndex(applications, ({ _id }) => _id === applicationId);

      if (idx < 0) {
        return;
      }

      const fields = isArray(_fields) ? _fields : [];
      if (isEmpty(fields)) {
        newState.results.applications[idx] = merge(
          {},
          newState.results.applications[idx],
          application,
        );
      } else {
        forEach(fields, (field) => {
          const value = application[field];
          if (value) {
            set(newState, ['results', 'applications', idx, field], value);
          }
        });
      }
    };

    const unlistApplicationById = (exludeId) => {
      set(
        newState,
        ['results', 'applications'],
        filter(applications, ({ _id }) => _id !== exludeId),
      );
    };

    if (created) {
      updateNewState(created);
    } else if (updated) {
      updateNewState(updated);

      // this object defines if the KEY field updated by calling VALUE function
      // then we should filter the applciaton out the list
      const filterMap = {
        status: () => searchStatuses && !includes(searchStatuses, application.status),
        excitementRate: () => {
          if (!excitement) {
            return false;
          }

          const [from, to] = map(excitement.split(':'), parseInt);
          const fromVal = from || 0;
          const toVal = (to || 3) + 0.01;
          return !inRange(application.excitementRate, fromVal, toVal);
        },
        salaryValue: () => {
          if (!salary) {
            return false;
          }

          const [from, to] = map(salary.split(':'), parseInt);
          const fromVal = from || 0;
          const toVal = (to || Infinity) + 0.01;
          return !inRange(application.salaryValue, fromVal, toVal);
        },
      };

      const shouldFilterOut = some(filterMap, (fn, key) => {
        return includes(updated, key) && fn();
      });

      if (shouldFilterOut) {
        unlistApplicationById(applicationId);
      }
    } else if (deleted) {
      const deletedId = get(application, '_id', eventId);
      unlistApplicationById(deletedId);
    }

    return newState;
  },
  [SEARCH_APPLICATION_SOCKET_UPDATE.FAILURE]: (state) => state,
};

// SAGA
function* processSocketUpdate(action) {
  try {
    const socketEvent = action.data;
    if (socketEvent.deleted) {
      yield put({
        type: SEARCH_APPLICATION_SOCKET_UPDATE.SUCCESS,
        socketEvent,
      });
    } else {
      yield put({
        type: SEARCH_APPLICATION_SOCKET_UPDATE.SUCCESS,
        socketEvent,
      });
    }
  } catch (e) {
    yield put({
      type: SEARCH_APPLICATION_SOCKET_UPDATE.FAILURE,
      message: e.message || e,
    });
  }
}

export function* watchProcessSocketUpdate() {
  yield takeEvery(SEARCH_APPLICATION_SOCKET_UPDATE.REQUEST, processSocketUpdate);
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchProcessSocketUpdate,
};
