import { CommentSchema } from '../base';
import { z } from 'zod';

export const CommentUpdateSchema = CommentSchema.pick({
  comment_id: true,
  comment: true,
})
  .extend({
    comment_tags: z.string().array().nullish(),
  })
  .partial({
    comment: true,
  });
export type CommentUpdate = z.infer<typeof CommentUpdateSchema>;
