import { z } from 'zod';
import { ForecastSchema } from '../base';

export const ForecastUpdateSchema = ForecastSchema.pick({
  forecast_id: true,
  months_amount: true,
  name: true,
}).partial({
  months_amount: true,
  name: true,
});
export type ForecastUpdate = z.infer<typeof ForecastUpdateSchema>;
