import { call, put, takeLatest } from 'redux-saga/effects';

import { constants, ACTION_MAP } from '@redux/modules/reports/assignments-report';
import * as api from '@redux/api/assignments-report';

const REQUEST = 'getStatusApplications';
const _key = ACTION_MAP[REQUEST];

// GET STATUS APPLICATIONS

function* getStatusApplications(action) {
  try {
    const response = yield call(api.getStatusApplications, action);

    yield put({
      type: constants[_key].SUCCESS,
      statusApplications: response,
    });
  } catch (e) {
    yield put({
      type: constants[_key].FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetStatusApplications() {
  yield takeLatest(constants[_key].REQUEST, getStatusApplications);
}
