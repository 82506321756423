import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { StaffingCandidate } from '../base';

export const CreateStaffingCandidateRequestSchema = z.object({
  opportunity_id: z.string().optional(),
  position_staffing_id: z.number(),
  application_id: z.string().nullable().optional(),
  person_id: z.string().nullable().optional(),
  staffing_recruiter_email: z.string(),
  rating: z.number(),
  user: z
    .object({
      name: z.string().optional(),
      email: z.string().optional(),
    })
    .optional(),
  replace_current_a: z.boolean().nullish(),
});

export type CreateStaffingCandidateRequest = z.infer<typeof CreateStaffingCandidateRequestSchema>;

export type CreateStaffingCandidateResponse = StaffingCandidate;

export type CreateStaffingCandidateEndpoint = Endpoint<
  Method.POST,
  '/api/v3/staffing-candidates',
  CreateStaffingCandidateResponse,
  undefined,
  undefined,
  CreateStaffingCandidateRequest
>;
