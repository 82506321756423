import { call, put, takeEvery } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';
import isFunction from 'lodash/isFunction';

// MODULE

const constants = {
  COMMENT_NOTIFICATION: createSagaAction('COMMENT_NOTIFICATION'),
};

const actions = {
  sendNotification: (data) => ({
    ...data,
    type: constants.COMMENT_NOTIFICATION.REQUEST,
  }),
};

const handlers = {
  [constants.COMMENT_NOTIFICATION.REQUEST]: (state, action) => {
    return { ...state, isLoading: true, data: [] };
  },
  [constants.COMMENT_NOTIFICATION.SUCCESS]: (state, { data }) => {
    return { ...state, isLoading: false, data };
  },
  [constants.COMMENT_NOTIFICATION.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

function* sagaSendNotification(action) {
  try {
    const data = yield call(apiSendNotification, action);
    yield put({ type: constants.COMMENT_NOTIFICATION.SUCCESS, data });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.COMMENT_NOTIFICATION.FAILURE,
      error: e.message || e,
    });

    if (isFunction(action.onFailure)) {
      yield action.onFailure(e.message || e);
    }
  }
}

function* watchSendNotification() {
  yield takeEvery(constants.COMMENT_NOTIFICATION.REQUEST, sagaSendNotification);
}

// API

function apiSendNotification({ applicationId, commentId, publicNotification, meta, isNew }) {
  return request(`/applications/${applicationId}/comments/${commentId}/notification`, {
    method: 'POST',
    qs: {
      isNew,
      publicNotification,
    },
    body: {
      id: applicationId,
      meta,
    },
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchSendNotification,

  // optionally we may
  // api: {
  //   sendNotification: apiSendNotification
  // }
};
