import { z } from 'zod';
import {
  Endpoint,
  FilterOperations,
  Method,
  ReportResponse,
  ReportsQuerySchema,
} from '../../infrastructure';
import { dateString } from '../../infrastructure/schema';

//Define the base return from database
export const ProjectTotalThroughMonthSchema = z.object({
  client_id: z.string(),
  client_name: z.string(),
  project_id: z.string(),
  project_name: z.string(),
  dtsnapshot: dateString,
  ymonth: z.number(),
  amount: z.number(),
});

export type ProjectTotalThroughMonth = z.infer<typeof ProjectTotalThroughMonthSchema>;

//Define the filters
export const ProjectTotalThroughMonthFiltersSchema = z.object({
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
});

export type ProjectTotalThroughMonthFilters = z.infer<typeof ProjectTotalThroughMonthFiltersSchema>;

//Define the query of the endpoints
export const ProjectTotalThroughMonthQuerySchema = ReportsQuerySchema.extend({
  filters: ProjectTotalThroughMonthFiltersSchema.optional(),
});

export type ProjectTotalThroughMonthQuery = z.infer<typeof ProjectTotalThroughMonthQuerySchema>;

//Define the response
export type ProjectTotalThroughMonthResponse = ReportResponse<ProjectTotalThroughMonth>;

//Define the endpoint
export type ProjectTotalThroughMonthEndpoint = Endpoint<
  Method.GET,
  '/api/v2/project-total-through-month-endpoint',
  ProjectTotalThroughMonthResponse,
  undefined,
  ProjectTotalThroughMonthQuery,
  undefined
>;
