import { call, put, takeLatest } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE
const constants = {
  BULK_UPDATE_APPLICATIONS_OWNER: createSagaAction('BULK_UPDATE_APPLICATIONS_OWNER'),
};

const actions = {
  bulkUpdateApplicationsOwner: (data) => ({
    ...data,
    type: constants.BULK_UPDATE_APPLICATIONS_OWNER.REQUEST,
  }),
};

const handlers = {
  [constants.BULK_UPDATE_APPLICATIONS_OWNER.REQUEST]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [constants.BULK_UPDATE_APPLICATIONS_OWNER.SUCCESS]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [constants.BULK_UPDATE_APPLICATIONS_OWNER.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA
function* bulkUpdateApplicationsOwner(action) {
  try {
    yield call(apiBulkUpdateApplicationsOwner, action);

    yield put({
      type: constants.BULK_UPDATE_APPLICATIONS_OWNER.SUCCESS,
    });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.BULK_UPDATE_APPLICATIONS_OWNER.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchBulkUpdateApplicationsOwner() {
  yield takeLatest(constants.BULK_UPDATE_APPLICATIONS_OWNER.REQUEST, bulkUpdateApplicationsOwner);
}

// API
export function apiBulkUpdateApplicationsOwner({ applicationsIds, recruiterId }) {
  return request('/applications/owner', {
    method: 'PUT',
    body: {
      applicationsIds,
      recruiterId,
    },
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchBulkUpdateApplicationsOwner,
};
