import { z } from 'zod';

import { FinBudget } from '../';
import { Endpoint, Method } from '../../../infrastructure';

export const FinBudgetListFiltersSchema = z
  .object({
    year: z.coerce.number(),
    budget_name: z.string(),
    budget_id: z.coerce.number(),
  })
  .partial();

export type FinBudgetListFilters = z.infer<typeof FinBudgetListFiltersSchema>;
export const FinBudgetListQuerySchema = z.object({
  filters: FinBudgetListFiltersSchema.partial(),
});
export type FinBudgetListQuery = z.infer<typeof FinBudgetListQuerySchema>;

export type FinBudgetListResponse = FinBudget[];

export type FinBudgetListEndpoint = Endpoint<
  Method.GET,
  '/api/v2/fin-budgets/',
  FinBudgetListResponse,
  undefined,
  FinBudgetListQuery,
  undefined
>;
