//APPLICATION_UPDATE_DEVSKILLER_SCORE
import * as api from '@redux/api/application';
import * as schemas from '@src/schemas';
import { actions as entitiesActions } from '@redux/modules/entities';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';
import { normalize } from 'normalizr';

function* updateDevskillerResult(action) {
  try {
    const response = yield call(api.updateDevskillerResult, action);
    const normalized = normalize(response.application, schemas.application);
    const { applications } = normalized.entities;

    yield put(entitiesActions.mergeApplications(applications));

    yield put({
      type: constants.DEVSKILLER_RESULT_UPDATE.SUCCESS,
    });
  } catch (e) {
    yield put({
      type: constants.DEVSKILLER_RESULT_UPDATE.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchUpdateDevskillerResult() {
  yield takeLatest(constants.DEVSKILLER_RESULT_UPDATE.REQUEST, updateDevskillerResult);
}

function* cancelAssessmentRequest(action) {
  try {
    yield call(api.cancelAssessmentRequest, action);

    // TODO: Technical Debt: The /cancel-request request does not return the list of assessments.
    // TODO: Instead, it returns a lean application, and when merged it clear the assessment list.
    // const response = yield call(api.cancelAssessmentRequest, action);
    // const normalized = normalize(response.application, schemas.application);
    // const { applications } = normalized.entities;
    // yield put(entitiesActions.mergeApplications(applications));

    const timelineResponse = yield call(api.getApplicationTimelineList, action);
    const timelineList = (timelineResponse && timelineResponse.data) || [];
    yield put({ type: constants.APPLICATION_TIMELINES_GET.SUCCESS, timelineList });

    const applicationResponse = yield call(api.get, action);
    const normalized = normalize(applicationResponse.data.application, schemas.application);
    const { applications } = normalized.entities;
    yield put(entitiesActions.mergeApplications(applications));
    yield put({ type: constants.APPLICATION_STATUS_UPDATE.SUCCESS });

    yield put({ type: constants.CANCEL_ASSESSMENT_REQUEST.SUCCESS });
  } catch (e) {
    yield put({
      type: constants.CANCEL_ASSESSMENT_REQUEST.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchCancelAssessmentRequest() {
  yield takeLatest(constants.CANCEL_ASSESSMENT_REQUEST.REQUEST, cancelAssessmentRequest);
}

function* archiveDevskillerTest(action) {
  try {
    yield call(api.archiveDevskillerTest, action);

    const timelineResponse = yield call(api.getApplicationTimelineList, action);
    const timelineList = (timelineResponse && timelineResponse.data) || [];
    yield put({ type: constants.APPLICATION_TIMELINES_GET.SUCCESS, timelineList });

    const applicationResponse = yield call(api.get, action);
    const normalized = normalize(applicationResponse.data.application, schemas.application);
    const { applications } = normalized.entities;
    yield put(entitiesActions.mergeApplications(applications));
    yield put({ type: constants.APPLICATION_STATUS_UPDATE.SUCCESS });

    yield put({
      type: constants.ARCHIVE_DEVSKILLER_TEST.SUCCESS,
    });
  } catch (e) {
    yield put({
      type: constants.ARCHIVE_DEVSKILLER_TEST.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchArchiveDevskillerTest() {
  yield takeLatest(constants.ARCHIVE_DEVSKILLER_TEST.REQUEST, archiveDevskillerTest);
}

function* syncTests(action) {
  try {
    const response = yield call(api.syncTests, action);
    yield put({
      type: constants.SYNC_DEVSKILLER_TESTS.SUCCESS,
      response,
    });
  } catch (e) {
    yield put({
      type: constants.SYNC_DEVSKILLER_TESTS.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchSyncTests() {
  yield takeLatest(constants.SYNC_DEVSKILLER_TESTS.REQUEST, syncTests);
}
