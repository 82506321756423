import { z } from 'zod';

export const BusinessEventChangesSchema = z.array(
  z.object({
    name: z.string(),
    old: z.any(),
    new: z.any(),
  }),
);
export type BusinessEventChanges = z.infer<typeof BusinessEventChangesSchema>;

export const BusinessEventSchema = z.object({
  event_id: z.number(),
  issued_by: z.string().nullable(),
  is_salesforce: z.boolean(),
  business_action_id: z.number(),
  created_at: z.string(),
  project_id: z.string().nullable(),
  position_id: z.string().nullable(),
  assignment_id: z.string().nullable(),
  metadata: BusinessEventChangesSchema.nullable(),
});

export type BusinessEvent = z.infer<typeof BusinessEventSchema>;

export type BusinessEvents = {
  data: {
    rows: BusinessEvent[];
    skip: number;
    count: number;
    limit: number;
  };
};
