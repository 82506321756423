import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  REJECTION_REASONS_GET_ALL: createSagaAction('REJECTION_REASONS_GET_ALL'),
  REJECTION_REASONS_CREATE: createSagaAction('REJECTION_REASONS_CREATE'),
  REJECTION_REASONS_UPDATE: createSagaAction('REJECTION_REASONS_UPDATE'),
  REJECTION_REASONS_DELETE: createSagaAction('REJECTION_REASONS_DELETE'),
  APPLICATION_GET_ALL_REJECTED: createSagaAction('APPLICATION_GET_ALL_REJECTED'),
};

// Action Creators
export const actions = {
  getAllRejectionReasons: () => ({
    type: constants.REJECTION_REASONS_GET_ALL.REQUEST,
  }),
  createRejectionReasons: ({ rejectionReason }) => ({
    type: constants.REJECTION_REASONS_CREATE.REQUEST,
    rejectionReason,
  }),
  updateRejectionReasons: (id, { rejectionReason }) => {
    return {
      type: constants.REJECTION_REASONS_UPDATE.REQUEST,
      id,
      rejectionReason,
    };
  },
  deleteRejectionReasons: (id) => ({
    type: constants.REJECTION_REASONS_DELETE.REQUEST,
    id,
  }),
  getAllApplicationsRejected: () => ({
    type: constants.APPLICATION_GET_ALL_REJECTED.REQUEST,
  }),
};

// Reducer
export const initialState = {
  error: null,
  rejectionReasons: [],
};

const ACTION_HANDLERS = {
  [constants.REJECTION_REASONS_GET_ALL.REQUEST]: (state) => {
    return { ...state };
  },
  [constants.REJECTION_REASONS_GET_ALL.SUCCESS]: (state, { rejectionReasons }) => {
    return { ...state, rejectionReasons };
  },
  [constants.APPLICATION_GET_ALL_REJECTED.SUCCESS]: (state, { applicationsRejected }) => {
    return { ...state, applicationsRejected };
  },
  [constants.REJECTION_REASONS_GET_ALL.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.REJECTION_REASONS_CREATE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.REJECTION_REASONS_UPDATE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.REJECTION_REASONS_DELETE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.APPLICATION_GET_ALL_REJECTED.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state };
});
