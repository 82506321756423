import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  SEARCH_APPLICATION_STATUSES_GET: createSagaAction('SEARCH_APPLICATION_STATUSES_GET'),
};

const actions = {
  getStatuses: (data) => ({
    ...data,
    type: constants.SEARCH_APPLICATION_STATUSES_GET.REQUEST,
  }),
};

const handlers = {
  [constants.SEARCH_APPLICATION_STATUSES_GET.REQUEST]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [constants.SEARCH_APPLICATION_STATUSES_GET.SUCCESS]: (state, action) => {
    return { ...state, isLoading: false, applicationStatuses: action.applicationStatuses };
  },
  [constants.SEARCH_APPLICATION_STATUSES_GET.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

// GET RESULTS FROM ELASTIC SEARCH
function* getStatuses(action) {
  try {
    const applicationStatuses = yield call(apiGetStatuses, action);
    yield put({ type: constants.SEARCH_APPLICATION_STATUSES_GET.SUCCESS, applicationStatuses });
  } catch (e) {
    yield put({
      type: constants.SEARCH_APPLICATION_STATUSES_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetStatuses() {
  yield takeLatest(constants.SEARCH_APPLICATION_STATUSES_GET.REQUEST, getStatuses);
}

// API

export function apiGetStatuses() {
  return request('/applications/statuses?show=active,aggregations', {
    method: 'GET',
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchGetStatuses,

  // optionally we may
  // api: {
  //   getAccount: apiGetAccount
  // }
};
