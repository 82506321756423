import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { SFOpportunitySchema } from './base';

export const UpdateSFOpportunityRequestSchema = SFOpportunitySchema.pick({
  opportunity_name: true,
  brains_owner_id: true,
  start_date: true,
  end_date: true,
  revenue: true,
  stage: true,
  description: true,
}).partial({
  brains_owner_id: true,
  opportunity_name: true,
  revenue: true,
  stage: true,
  description: true,
  start_date: true,
  end_date: true,
});
export type UpdateSFOpportunityRequest = z.infer<typeof UpdateSFOpportunityRequestSchema>;

export const UpdateSFOpportunityParamsSchema = z.object({
  brains_project_id: z.string(),
});
export type UpdateSFOpportunityParams = z.infer<typeof UpdateSFOpportunityParamsSchema>;

export type UpdateSFOpportunityResponse = void;

export type UpdateSFOpportunityEndpoint = Endpoint<
  Method.PUT,
  `/api/sf/opportunities/${UpdateSFOpportunityParams['brains_project_id']}`,
  UpdateSFOpportunityResponse,
  UpdateSFOpportunityParams,
  undefined,
  UpdateSFOpportunityRequest
>;
