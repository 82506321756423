import { call, put, takeLatest } from 'redux-saga/effects';

import { constants, ACTION_MAP } from '@redux/modules/reports/assignments-report';
import * as api from '@redux/api/assignments-report';
import * as leadsApi from '@redux/api/leads';

import map from 'lodash/map';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';

const GET_LEADS_ACTION = 'getLeads';
const UPDATE_NAME_ACTION = 'updateLeadName';
const UPDATE_NOTES_ACTION = 'updateClientAndProjectNotes';
const GET_LEAD_BY_ID_ACTION = 'getLeadByID';
const REMOVE_LEAD_BY_ID_ACTION = 'removeLeadByID';
const GET_STATUS_APPS = 'getStatusApplications';
const UPDATE_BRAINS_ASSIGNMENT = 'assignFromBrains';
const _getLeadsKey = ACTION_MAP[GET_LEADS_ACTION];
const _getLeadByIDKey = ACTION_MAP[GET_LEAD_BY_ID_ACTION];
const _removeLeadByIDKey = ACTION_MAP[REMOVE_LEAD_BY_ID_ACTION];
const _updateNameKey = ACTION_MAP[UPDATE_NAME_ACTION];
const _updateNotesKey = ACTION_MAP[UPDATE_NOTES_ACTION];
const _getStatusApps = ACTION_MAP[GET_STATUS_APPS];
const _updateBrainsAssignment = ACTION_MAP[UPDATE_BRAINS_ASSIGNMENT];

// GET LEADS

function* getLeads(action) {
  try {
    const response = yield call(api.getLeads, action);

    yield put({
      type: constants[_getLeadsKey].SUCCESS,
      leads: response,
    });
  } catch (e) {
    yield put({
      type: constants[_getLeadsKey].FAILURE,
      error: e.message || e,
    });
  }
}

function* getLeadByID(action) {
  try {
    const response = yield call(leadsApi.getLeadByID, action);

    yield put({
      type: constants[_getLeadByIDKey].SUCCESS,
      fullInfoLead: response,
    });

    let brainsIDs = map(response && response.assignments, (assignment) => {
      return assignment.person && assignment.person._id;
    });

    brainsIDs = compact(brainsIDs);
    brainsIDs = uniq(brainsIDs);

    const statusApps = yield call(api.getStatusApplications, { brainsIDs });

    yield put({
      type: constants[_getStatusApps].SUCCESS,
      statusApplications: statusApps,
    });
  } catch (e) {
    yield put({
      type: constants[_getLeadByIDKey].FAILURE,
      error: e.message || e,
    });
  }
}

function* removeLeadByID(action) {
  try {
    yield put({
      type: constants[_removeLeadByIDKey].SUCCESS,
      leadId: action.leadId,
    });
  } catch (e) {
    yield put({
      type: constants[_removeLeadByIDKey].FAILURE,
      error: e.message || e,
    });
  }
}

function* updateLeadName(action) {
  try {
    yield call(leadsApi.updateLeadShortNote, action);

    const leads = yield call(api.getLeads, action);

    yield put({
      type: constants[_updateNameKey].SUCCESS,
      leadNameUpdateSuccess: true,
      leads,
    });
  } catch (e) {
    yield put({
      type: constants[_updateNameKey].FAILURE,
      error: e.message || e,
      leadNameUpdateSuccess: false,
    });
  }
}

function* updateClientAndProjectNotes(action = {}) {
  try {
    const { projectId, projectNotes, clientNotes, onSuccess } = action;
    yield call(leadsApi.updateLeadNotes, {
      id: projectId,
      projectNotes,
      clientNotes,
    });
    onSuccess();
  } catch (e) {
    const { onFail } = action;
    onFail();
  }
}

function* updateBrainsAssign(action) {
  try {
    const { leadId, positionId, personId, assignment } = action;
    let updatedLead;

    if (personId) {
      updatedLead = yield call(leadsApi.addBrainsAssign, leadId, positionId, personId);
    } else {
      updatedLead = yield call(leadsApi.removeBrainsAssign, leadId, positionId, assignment);
    }

    if (action.callbackFn) {
      action.callbackFn();
    }

    yield put({
      type: constants.ASSIGNMENTS_REPORT_APPLICATION_BRAINS_ASSIGNMENTS_UPDATE.SUCCESS,
      lead: updatedLead,
    });
  } catch (e) {
    yield put({
      type: constants.ASSIGNMENTS_REPORT_APPLICATION_BRAINS_ASSIGNMENTS_UPDATE.FAILURE,
      message: e.message || e,
    });
  }
}

export function* watchGetLeads() {
  yield takeLatest(constants[_getLeadsKey].REQUEST, getLeads);
}

export function* watchGetLeadByID() {
  yield takeLatest(constants[_getLeadByIDKey].REQUEST, getLeadByID);
}

export function* watchRemoveLeadByID() {
  yield takeLatest(constants[_removeLeadByIDKey].REQUEST, removeLeadByID);
}

export function* watchUpdateLeadName() {
  yield takeLatest(constants[_updateNameKey].REQUEST, updateLeadName);
}

export function* watchUpdateClientAndProjectNotes() {
  yield takeLatest(constants[_updateNotesKey].REQUEST, updateClientAndProjectNotes);
}

export function* watchUpdateBrainsAssign() {
  yield takeLatest(constants[_updateBrainsAssignment].REQUEST, updateBrainsAssign);
}
