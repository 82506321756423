import { z } from 'zod';
import { POCSchema, POCStatus } from './basePOC';

export const RevokePOCSchema = POCSchema.pick({
  payment_option_change_id: true,
  comment: true,
})
  .partial({
    comment: true,
  })
  .extend({
    status: z.enum([POCStatus.Revoked]),
  });

export type RevokePOC = z.infer<typeof RevokePOCSchema>;
