import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  SETTINGS_SKILL_CREATE: createSagaAction('SETTINGS_SKILL_CREATE'),
  SETTINGS_SKILL_GROUPS_GET: createSagaAction('SETTINGS_SKILL_GROUPS_GET'),
};

// Action Creators
export const actions = {
  createSkill: (body) => ({ type: constants.SETTINGS_SKILL_CREATE.REQUEST, body }),
  getSkillGroupValues: () => ({ type: constants.SETTINGS_SKILL_GROUPS_GET.REQUEST }),
};

// Reducer
const initialState = {
  skills: [],
  skillGroups: [],
  loading: false,
  error: null,
};

export default createReducer(initialState, (state, action) => {
  const actionsMap = {
    [constants.SETTINGS_SKILL_CREATE.REQUEST]: () => {
      return { ...state, error: null, loading: true };
    },

    [constants.SETTINGS_SKILL_CREATE.SUCCESS]: () => {
      return { ...state, error: null, refetch: true, loading: false };
    },

    [constants.SETTINGS_SKILL_CREATE.FAILURE]: () => {
      return { ...state, error: action.message, loading: false };
    },

    // skill groups
    [constants.SETTINGS_SKILL_GROUPS_GET.REQUEST]: () => {
      return { ...state, error: null };
    },

    [constants.SETTINGS_SKILL_GROUPS_GET.SUCCESS]: () => {
      return { ...state, skillGroups: action.skillGroups, refetch: false };
    },

    [constants.SETTINGS_SKILL_GROUPS_GET.FAILURE]: () => {
      return { ...state, error: action.message };
    },
  };

  if (actionsMap[action.type]) {
    return actionsMap[action.type]();
  }

  return state;
});
