import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { PersonSchema } from './base';

export type FindPersonIdByEmailEndpoint = Endpoint<
  Method.GET,
  `/api/v2/people/:${FindPersonIdByEmailParams['email']}/id`,
  FindPersonIdByEmailResponse,
  FindPersonIdByEmailParams,
  undefined,
  undefined
>;

export const FindPersonIdByEmailParamsSchema = PersonSchema.pick({
  email: true,
});
export type FindPersonIdByEmailParams = z.infer<typeof FindPersonIdByEmailParamsSchema>;

export type FindPersonIdByEmailResponse = { id: string };
