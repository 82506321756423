import React from 'react';
import { Button, notification } from 'antd';

export function notifyNewAppVersion() {
  const key = 'new-app-version-hire-notification';
  const btn = (
    <Button
      type="primary"
      size="small"
      onClick={() => {
        notification.close(key);
        window.location.reload();
      }}
    >
      Upgrade
    </Button>
  );

  notification.open({
    key,
    btn,
    duration: null,
    className: key,
    message: 'New Hire Version',
    description: 'There is a new Hire version available. Please reload the app.',
    placement: 'bottomCenter',
  });
}
