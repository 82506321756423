import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const PrjAsnSummarySchema = z.object({
  project_id: z.string(),
  ymonth: z.number(),
  charging_total: z.number(),
  revenue_adjustments: z.number(),
  adjustments: z.number(),
  estimated_cost: z.number(),
  invoiced_amount: z.number(),
  revenue_amount: z.number(),
  assigned_cost: z.number(),
  profit: z.number(),
  margin: z.number(),
});
export type PrjAsnSummary = z.infer<typeof PrjAsnSummarySchema>;

export const PrjAsnSummaryParamsSchema = z.object({
  projectId: z.string(),
});
export type PrjAsnSummaryParams = z.infer<typeof PrjAsnSummaryParamsSchema>;

export type PrjAsnSummaryResponse = readonly PrjAsnSummary[];

export type PrjAsnSummaryEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sql-projects/${PrjAsnSummaryParams['projectId']}/asn-summary`,
  PrjAsnSummaryResponse,
  PrjAsnSummaryParams,
  undefined,
  undefined
>;
