import { z } from 'zod';
import { PsnRctrSchema } from '../base';

export const PsnRctrCreateSchema = PsnRctrSchema.pick({
  person_id: true,
  rctr_group: true,
  rctr_role: true,
  zoom_link: true,
  email: true,
}).partial({
  zoom_link: true,
});

export type PsnRctrCreate = z.infer<typeof PsnRctrCreateSchema>;
