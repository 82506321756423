import { z } from 'zod';
import { dateString, stringToBoolean } from '../../infrastructure/schema';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';

//Base
export const ListLeadPositionSchema = z.object({
  lead_id: z.string(),
  lead_name: z.string(),
  position_id: z.string(),
  position_name: z.string(),
  dtplannedstart: dateString,
  dtplannedend: dateString,
  target_cost: z.number(),
  hire_group: z.string(),
  seniority: z.string(),
  english_level: z.number(),
  isstaffit: z.boolean(),
  hard_date: z.boolean(),
  revenuerate_id: z.string(),
  revenueamount: z.number(),
  psn_cost: z.number(),
  skills: z.string(),
  skill_color: z.string(),
  display_color: z.string(),
  department: z.string(),
  isbillable: z.boolean(),
  person_id: z.string().nullable(),
  person_name: z.string().nullable(),
  dthired: dateString.nullable(),
  location: z.string().nullable(),
  asn_dt_created: dateString.nullable(),
});

export type ListLeadPosition = z.infer<typeof ListLeadPositionSchema>;

//Filters
export const ListLeadPositionFiltersSchema = z.object({
  lead_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  include_archived: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean).optional(),
});
export type ListLeadPositionFilters = z.infer<typeof ListLeadPositionFiltersSchema>;

//Query
export const ListLeadPositionQuerySchema = z.object({
  filters: ListLeadPositionFiltersSchema.optional(),
});
export type ListLeadPositionQuery = z.infer<typeof ListLeadPositionQuerySchema>;

//Response
export type ListLeadPositionResponse = ListLeadPosition[];

//Endpoint
export type ListLeadPositionEndpoint = Endpoint<
  Method.GET,
  '/api/v2/positions/lead-positions',
  ListLeadPositionResponse,
  undefined,
  ListLeadPositionQuery,
  undefined
>;
