import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const RemoveProjectExtensionRequestSchema = z.object({
  sticky: z.boolean().nullish().default(true),
  positions_id: z.array(z.string()).nullish(),
});

export type RemoveProjectExtensionRequest = z.infer<typeof RemoveProjectExtensionRequestSchema>;

export const RemoveProjectExtensionParamsSchema = z.object({
  project_id: z.string(),
});

export type RemoveProjectExtensionParams = z.infer<typeof RemoveProjectExtensionParamsSchema>;

export type RemoveProjectExtensionEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/sql-projects/opp-extension/${RemoveProjectExtensionParams['project_id']}`,
  void,
  RemoveProjectExtensionParams,
  undefined,
  RemoveProjectExtensionRequest
>;
