import { z } from 'zod';
import { PositionListItemSchema } from './base';
import {
  Endpoint,
  Method,
  makeSorter,
  FilterOperations,
  ReportsQuerySchema,
} from '../../infrastructure';

export type ListPositionsEndpoint = Endpoint<
  Method.GET,
  '/api/v2/positions/list',
  ListPositionsResponse,
  undefined,
  ListPositionsQuery,
  undefined
>;

export const ListPositionsSchema = z.object({
  positions: z.array(PositionListItemSchema),
});

export const ListPositionsFiltersSchema = z.object({
  project_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
});

export const ListPositionsSorterSchema = makeSorter(z.enum(['name']));

export const ListPositionsQuerySchema = ReportsQuerySchema.extend({
  filters: ListPositionsFiltersSchema.optional(),
  sorter: ListPositionsSorterSchema.optional(),
});

export type ListPositionsFilters = z.infer<typeof ListPositionsFiltersSchema>;

export type ListPositionsSorter = z.infer<typeof ListPositionsSorterSchema>;

export type ListPositionsResponse = z.infer<typeof ListPositionsSchema>;

export type ListPositionsQuery = z.infer<typeof ListPositionsQuerySchema>;
