import { z } from 'zod';
import { BaseSchemaDW } from '../structure';

export const RecruiterRoleRefPsnAscSchema = z
  .object({
    person_id: z.string(),
    recruiter_role: z.number(),
  })
  .merge(BaseSchemaDW);
export type RecruiterRoleRefPsnAscDW = z.infer<typeof RecruiterRoleRefPsnAscSchema>;

export const RecruiterRolePsnAscMongoSchema = z.object({
  category: z.string(),
  brainsId: z.string(),
});
export type RecruiterRolePsnAscMongo = z.infer<typeof RecruiterRolePsnAscMongoSchema>;
