import { z } from 'zod';

export const MessageSchema = z.object({
  message_id: z.number(),
  author_id: z.string(),
  txt_content: z.string().nullable(),
  dt_created: z.date(),
  dt_updated: z.date(),
});
export type Message = z.infer<typeof MessageSchema>;

export const PositionStaffingMessageSchema = z.object({
  position_staffing_id: z.number(),
  message_id: z.number(),
});

export type PositionStaffingMessage = z.infer<typeof PositionStaffingMessageSchema>;

export const StaffingCandidateMessageSchema = z.object({
  staffing_candidate_id: z.number(),
  message_id: z.number(),
});

export type StaffingCandidateMessage = z.infer<typeof StaffingCandidateMessageSchema>;
