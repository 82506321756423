import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/candidate';

import * as api from '@redux/api/candidates';

//GET CANDIDATE INFO
function* getCandidate(action) {
  try {
    const response = yield call(api.getCandidateInfo, action.email, action.applicationId);
    const candidateInfo = response.data;

    yield put({ type: constants.CANDIDATE_INFO.SUCCESS, candidateInfo });

    yield getCandidateAssessments({
      type: constants.CANDIDATE_ASSESSMENTS.REQUEST,
      candidateId: candidateInfo,
    });
  } catch (error) {
    yield put({
      type: constants.CANDIDATE_INFO.FAILURE,
      error: error.message || error,
    });
  }
}

function* watchGetCandidate() {
  yield takeLatest(constants.CANDIDATE_INFO.REQUEST, getCandidate);
}

//GET CANDIDATE ASSESSMENTS
function* getCandidateAssessments(action) {
  try {
    const response = yield call(api.getCandidateAssessments, action.candidateId.id);
    const candidateAssessments = response.data;
    yield put({ type: constants.CANDIDATE_ASSESSMENTS.SUCCESS, candidateAssessments });
  } catch (error) {
    const candidateAssessments = [];
    yield put({ type: constants.CANDIDATE_ASSESSMENTS.SUCCESS, candidateAssessments });
  }
}

function* watchgetCandidateAssessments() {
  yield takeLatest(constants.CANDIDATE_ASSESSMENTS.REQUEST, getCandidateAssessments);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([fork(watchGetCandidate), fork(watchgetCandidateAssessments)]);
}
