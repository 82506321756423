import { z } from 'zod';
import { ApplicantSchema } from '../base';

export const ApplicantUpdateSchema = ApplicantSchema.pick({
  applicant_id: true,
  email: true,
  name: true,
  title: true,
  degree_year: true,
  english_level: true,
  native_english: true,
  seniority: true,
  salary_currency: true,
  referred_by: true,
  linkedin_url: true,
  github_url: true,
  country: true,
  city: true,
  state: true,
  longitude: true,
  latitude: true,
  timezone: true,
  phone: true,
  brains_id: true,
  slack_id: true,
  slack_internal_id: true,
  zoom_url: true,
}).partial({
  email: true,
  name: true,
  title: true,
  degree_year: true,
  english_level: true,
  native_english: true,
  seniority: true,
  salary_currency: true,
  referred_by: true,
  linkedin_url: true,
  github_url: true,
  country: true,
  city: true,
  state: true,
  longitude: true,
  latitude: true,
  timezone: true,
  phone: true,
  brains_id: true,
  slack_id: true,
  slack_internal_id: true,
  zoom_url: true,
});

export type ApplicantUpdate = z.infer<typeof ApplicantUpdateSchema>;
