import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE
const constants = {
  SORT_JOBS: createSagaAction('SORT_JOBS'),
};

const actions = {
  sortJobs: (args) => ({
    type: constants.SORT_JOBS.REQUEST,
    ...args,
  }),
};

const handlers = {
  [constants.SORT_JOBS.SUCCESS]: (state, { allJobs }) => {
    return { ...state, allJobs };
  },
  [constants.SORT_JOBS.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

// SAGA
function* sortJobs(action) {
  try {
    const response = yield call(apiSortJobs, action);

    yield put({
      type: constants.SORT_JOBS.SUCCESS,
      allJobs: response.data,
    });
  } catch (e) {
    yield put({
      type: constants.SORT_JOBS.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchSortJobs() {
  yield takeLatest(constants.SORT_JOBS.REQUEST, sortJobs);
}

// API
export function apiSortJobs({ jobsIds, view }) {
  return request('/jobs/sort', {
    method: 'POST',
    body: {
      jobsIds: jobsIds || [],
      view: view || 'hireSorting',
    },
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchSortJobs,
};
