import { z } from 'zod';
import { RelatedPsnDeepSkillSchema } from './base';

export const UpdatePsnDeepSkillRequestSchema = z.object({
  skill: z.string().optional(),
  display: z.string().optional(),
  category: z.string().optional(),
  description: z.string().optional(),
  relatedSkills: z.array(RelatedPsnDeepSkillSchema).optional(),
});
export type UpdatePsnDeepSkillRequest = z.infer<typeof UpdatePsnDeepSkillRequestSchema>;
