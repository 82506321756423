// APPLICATION_UPDATE_CONTRACT_STATUS
import * as api from '@redux/api/application';
import * as schemas from '../../../schemas';
import { actions as entitiesActions } from '@redux/modules/entities';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';
import { normalize } from 'normalizr';

function* updateApplicationContractStatus(action) {
  try {
    const response = yield call(api.updateApplicationContractStatus, action);
    const normalized = normalize(response.data, schemas.application);
    const { users, applications } = normalized.entities;

    yield put(entitiesActions.mergeApplications(applications));

    yield put(entitiesActions.mergeUsers(users));

    yield put({
      type: constants.APPLICATION_UPDATE_CONTRACT_STATUS.SUCCESS,
    });

    const timelineResponse = yield call(api.getApplicationTimelineList, action);
    const timelineList = (timelineResponse && timelineResponse.data) || [];
    yield put({ type: constants.APPLICATION_TIMELINES_GET.SUCCESS, timelineList });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_UPDATE_CONTRACT_STATUS.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchUpdateApplicationContractStatus() {
  yield takeLatest(
    constants.APPLICATION_UPDATE_CONTRACT_STATUS.REQUEST,
    updateApplicationContractStatus,
  );
}
