import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { StaffingCandidate } from '../base';

export const MoveStaffingCandidateRequestSchema = z.object({
  position_staffing_id: z.number(),
  user: z
    .object({
      name: z.string().optional(),
      email: z.string().optional(),
    })
    .optional(),
});

export type MoveStaffingCandidateRequest = z.infer<typeof MoveStaffingCandidateRequestSchema>;

export type MoveStaffingCandidateResponse = StaffingCandidate;

export const MoveStaffingCandidateParamsSchema = z.object({
  staffing_candidate_id: z.coerce.number(),
  opportunity_id: z.string().optional(),
});
export type MoveStaffingCandidateParams = z.infer<typeof MoveStaffingCandidateParamsSchema>;

export type MoveStaffingCandidateEndpoint = Endpoint<
  Method.PUT,
  `/api/v3/staffing-candidate/:${MoveStaffingCandidateParams['staffing_candidate_id']}/move`,
  MoveStaffingCandidateResponse,
  MoveStaffingCandidateParams,
  undefined,
  MoveStaffingCandidateRequest
>;
