import { CalendlyNotificationSchema } from '../';
import { z } from 'zod';

export const CalendlyNotificationCreateSchema = CalendlyNotificationSchema.pick({
  calendly_invitee_uri: true,
  notification_type: true,
  payload: true,
  status: true,
}).partial({
  status: true,
});
export type CalendlyNotificationCreate = z.infer<typeof CalendlyNotificationCreateSchema>;
