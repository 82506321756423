import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import { constants } from '@redux/modules/reports/scheduled-emails';
import * as api from '@redux/api/scheduled-emails';

function* getScheduledEmails(action) {
  try {
    const data = yield call(api.getScheduledEmails, action);
    yield put({
      type: constants.SCHEDULED_EMAILS_GET.SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: constants.SCHEDULED_EMAILS_GET.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetScheduledEmails() {
  yield takeLatest(constants.SCHEDULED_EMAILS_GET.REQUEST, getScheduledEmails);
}

function* deleteScheduledEmail(action) {
  try {
    yield call(api.deleteScheduledEmail, action.emailId);
    yield put({
      type: constants.SCHEDULED_EMAILS_DELETE.SUCCESS,
      emailId: action.emailId,
    });
  } catch (e) {
    yield put({
      type: constants.SCHEDULED_EMAILS_DELETE.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchDeleteScheduledEmail() {
  yield takeLatest(constants.SCHEDULED_EMAILS_DELETE.REQUEST, deleteScheduledEmail);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([fork(watchGetScheduledEmails), [fork(watchDeleteScheduledEmail)]]);
}
