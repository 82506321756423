import { z } from 'zod';
import { Endpoint, Method, dateString } from '../../infrastructure';

export const PositionBulkRevenueSchema = z.object({
  project_id: z.string(),
  positions: z.array(z.string()),
  revenueamount: z.number(),
  dtfrom: dateString,
});

export type PositionBulkRevenueBody = z.infer<typeof PositionBulkRevenueSchema>;

export type PositionBulkRevenueResponse = {};

export type PositionBulkRevenueEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/positions/bulk-revenue`,
  PositionBulkRevenueResponse,
  undefined,
  undefined,
  PositionBulkRevenueBody
>;
