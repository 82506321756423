import request from '../../shared/request';

export function getAllQuestions() {
  return request('/questions', {
    method: 'GET',
  });
}

export function createQuestion({ data }) {
  return request('/questions', {
    method: 'POST',
    body: data,
  });
}

export function updateQuestion({ id, name, helper, sort }) {
  return request(`/questions/${id}`, {
    method: 'PUT',
    body: {
      name,
      helper,
      sort,
    },
  });
}

export function deleteQuestion({ id }) {
  return request(`/questions/${id}`, {
    method: 'DELETE',
  });
}

export function uploadVideo({ id, video }) {
  return request(`/questions/${id}/video`, {
    method: 'PUT',
    body: video,
  });
}
