import { z } from 'zod';
import { SFUSer } from './base';
import { Endpoint, Method } from '../../../infrastructure';

export const SFGetBrainsPersonParamsSchema = z.object({
  email: z.string(),
});
export type SFGetBrainsPersonParams = z.infer<typeof SFGetBrainsPersonParamsSchema>;

export type SFGetBrainsPersonResponse = SFUSer;

export type SFGetBrainsPersonEndpoint = Endpoint<
  Method.GET,
  `/api/sf/user/${SFGetBrainsPersonParams['email']}`,
  SFGetBrainsPersonResponse,
  SFGetBrainsPersonParams,
  undefined,
  undefined
>;
