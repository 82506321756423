import { all, fork, call, put, takeEvery } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import * as schemas from '@src/schemas';
import { actions as entitiesActions } from '@redux/modules/entities';

import { constants } from '@redux/modules/todos';
import * as api from '@redux/api/todos';
import isFunction from 'lodash/isFunction';

// TODO CREATE
function* createTodo(action) {
  try {
    const response = yield call(api.create, action);
    const normalized = normalize(response.data, schemas.application);
    const { todos, applications } = normalized.entities;

    yield put(entitiesActions.mergeApplications(applications));
    yield put(entitiesActions.mergeTodos(todos));

    yield put({ type: constants.TODO_CREATE.SUCCESS });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(todos, applications);
    }
  } catch (e) {
    yield put({
      type: constants.TODO_CREATE.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(e.message || e);
    }
  }
}
function* watchCreate() {
  yield takeEvery(constants.TODO_CREATE.REQUEST, createTodo);
}

function* updateTodo(action) {
  try {
    yield call(api.update, action);
    yield put({ type: constants.TODO_UPDATE.SUCCESS });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.TODO_UPDATE.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(e.message || e);
    }
  }
}
function* watchUpdate() {
  yield takeEvery(constants.TODO_UPDATE.REQUEST, updateTodo);
}

function* deleteTodo(action) {
  try {
    yield call(api.remove, action);
    yield put({ type: constants.TODO_DELETE.SUCCESS });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.TODO_DELETE.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(e.message || e);
    }
  }
}
function* watchDelete() {
  yield takeEvery(constants.TODO_DELETE.REQUEST, deleteTodo);
}

function* getTodos(action) {
  try {
    const response = yield call(api.get, action.options);
    yield put({ type: constants.TODO_GET.SUCCESS, results: response.data });
  } catch (e) {
    yield put({
      type: constants.TODO_GET.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchGet() {
  yield takeEvery(constants.TODO_GET.REQUEST, getTodos);
}

function* sagaSetOptions(action) {
  try {
    const options = action.data;
    yield put({
      type: constants.TODO_SET_OPTIONS.SUCCESS,
      options,
    });
  } catch (e) {
    yield put({
      type: constants.TODO_SET_OPTIONS.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchSagaSetOptions() {
  yield takeEvery(constants.TODO_SET_OPTIONS.REQUEST, sagaSetOptions);
}
function* sagaProcessSocket(action) {
  try {
    const socketEvent = action.data;
    yield put({ type: constants.TODO_WS_EVENT.SUCCESS, socketEvent });
  } catch (e) {
    yield put({
      type: constants.TODO_WS_EVENT.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchSagaProcessSocket() {
  yield takeEvery(constants.TODO_WS_EVENT.REQUEST, sagaProcessSocket);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchCreate),
    fork(watchDelete),
    fork(watchUpdate),
    fork(watchGet),
    fork(watchSagaProcessSocket),
    fork(watchSagaSetOptions),
  ]);
}
