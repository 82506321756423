import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const TimeOffRequestCommentSchema = z.object({
  time_off_request_comment_id: z.number(),
  time_off_request_id: z.number(),
  author_id: z.string(),
  comment: z.string(),
  dt_created: dateString,
  dt_updated: dateString,
});

export type TimeOffRequestComment = z.infer<typeof TimeOffRequestCommentSchema>;
