import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  ACTIONS_REPORT_GET: createSagaAction('ACTIONS_REPORT_GET'),
};

// Action Creators
export const actions = {
  getActions: () => ({
    type: constants.ACTIONS_REPORT_GET.REQUEST,
  }),
};

// Reducer
export const initialState = {
  actions: [],
  isLoading: false,
  error: null,
};

const ACTION_HANDLERS = {
  [constants.ACTIONS_REPORT_GET.SUCCESS]: (state, { actions }) => {
    return { ...state, actions };
  },
  [constants.ACTIONS_REPORT_GET.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
