import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import * as api from '@redux/api/scorecard-questions';
import { constants } from '@redux/modules/scorecard-questions';
import { message } from 'antd';

function* getQuestions(action) {
  try {
    const questions = yield call(api.getQuestions, action);
    yield put({ type: constants.SCORECARD_QUESTIONS_GET.SUCCESS, questions });
  } catch (e) {
    yield put({
      type: constants.SCORECARD_QUESTIONS_GET.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetQuestions() {
  yield takeLatest(constants.SCORECARD_QUESTIONS_GET.REQUEST, getQuestions);
}

function* createQuestion(action) {
  try {
    yield call(api.createQuestion, action);
    yield put({ type: constants.SCORECARD_QUESTION_CREATE.SUCCESS, question: action.data });
    const questions = yield call(api.getQuestions, action);
    yield put({ type: constants.SCORECARD_QUESTIONS_GET.SUCCESS, questions });
    message.success('Saved successfully');
  } catch (e) {
    yield put({
      type: constants.SCORECARD_QUESTION_CREATE.FAILURE,
      error: e.message || e,
    });
    message.error('Could not save, please try again');
  }
}

function* watchCreateQuestion() {
  yield takeLatest(constants.SCORECARD_QUESTION_CREATE.REQUEST, createQuestion);
}

function* getQuestionDetails(action) {
  try {
    yield call(api.getQuestionDetails, action);
    yield put({ type: constants.SCORECARD_QUESTION_DETAILS_GET.SUCCESS, question: action.data });
    message.success('Saved successfully');
  } catch (e) {
    yield put({
      type: constants.SCORECARD_QUESTION_DETAILS_GET.FAILURE,
      error: e.message || e,
    });
    message.error('Could not save, please try again');
  }
}

function* watchGetQuestionDetails() {
  yield takeLatest(constants.SCORECARD_QUESTION_DETAILS_GET.REQUEST, getQuestionDetails);
}

function* updateQuestion(action) {
  try {
    yield call(api.updateQuestion, action);
    yield put({ type: constants.SCORECARD_QUESTION_UPDATE.SUCCESS, question: action.data });
    const questions = yield call(api.getQuestions, action);
    yield put({ type: constants.SCORECARD_QUESTIONS_GET.SUCCESS, questions });
    message.success('Updated successfully');
  } catch (e) {
    yield put({
      type: constants.SCORECARD_QUESTION_UPDATE.FAILURE,
      error: e.message || e,
    });
    message.error('Could not update, please try again');
  }
}

function* watchUpdateQuestion() {
  yield takeLatest(constants.SCORECARD_QUESTION_UPDATE.REQUEST, updateQuestion);
}

function* deleteQuestion(action) {
  try {
    yield call(api.deleteQuestion, action);
    yield put({ type: constants.SCORECARD_QUESTION_DELETE.SUCCESS, question: action.data });
    const questions = yield call(api.getQuestions, action);
    yield put({ type: constants.SCORECARD_QUESTIONS_GET.SUCCESS, questions });
    message.success('Deleted successfully');
  } catch (e) {
    yield put({
      type: constants.SCORECARD_QUESTION_DELETE.FAILURE,
      error: e.message || e,
    });
    message.error('Could not delete, please try again');
  }
}

function* watchDeleteQuestion() {
  yield takeLatest(constants.SCORECARD_QUESTION_DELETE.REQUEST, deleteQuestion);
}

export function* rootSaga() {
  yield all(
    [
      watchGetQuestions,
      watchCreateQuestion,
      watchGetQuestionDetails,
      watchUpdateQuestion,
      watchDeleteQuestion,
    ].map(fork),
  );
}
