import { Endpoint, Method, GenericFilters, SorterQuery } from '../../../infrastructure';
import { z } from 'zod';

export const GenericTableGetParamsSchema = z.object({
  table_name: z.string(),
});
export type GenericTableGetParams = z.infer<typeof GenericTableGetParamsSchema>;

export type GenericTableGetQuery = {
  sorter?: SorterQuery;
  filters?: GenericFilters<unknown>;
};

export type GenericTableGetResponse = any;

export type GenericTableGetEndpoint = Endpoint<
  Method.GET,
  `/api/v2/generic-table/${GenericTableGetParams['table_name']}`,
  GenericTableGetResponse,
  GenericTableGetParams,
  GenericTableGetQuery,
  undefined
>;
