// GET APPLICATION PAST

import { call, takeLatest, put } from 'redux-saga/effects';
import * as api from '@redux/api/application';
import { constants } from '@redux/modules/application/application';

function* getApplicationPast(action) {
  try {
    const response = yield call(api.getPast, action);
    const applicationPast = response.applications;
    yield put({
      type: constants.APPLICATION_PAST_GET.SUCCESS,
      applicationPast,
    });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_PAST_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetApplicationPast() {
  yield takeLatest(constants.APPLICATION_PAST_GET.REQUEST, getApplicationPast);
}
