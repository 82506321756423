import { z } from 'zod';
import { FinBudgetSchema } from '../';

export const FinBudgetUpdateSchema = FinBudgetSchema.pick({
  budget_id: true,
  budget_name: true,
  cost_budget: true,
  revenue_budget: true,
}).partial({
  budget_name: true,
  cost_budget: true,
  revenue_budget: true,
});
export type FinBudgetUpdate = z.infer<typeof FinBudgetUpdateSchema>;
