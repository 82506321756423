//UPDATE USER PHONE
import * as api from '@redux/api/application';
import * as schemas from '@src/schemas';
import { actions as entitiesActions } from '@redux/modules/entities';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';
import { normalize } from 'normalizr';

function* updateVisaRequired(action) {
  try {
    const application = yield call(api.updateApplicationVisaRequired, action);
    const normalized = normalize(application, schemas.application);
    const { users, applications } = normalized.entities;

    yield put(entitiesActions.mergeApplications(applications));
    yield put(entitiesActions.mergeUsers(users));

    yield put({
      type: constants.APPLICATION_VISA_REQUIRED_UPDATE.SUCCESS,
    });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_VISA_REQUIRED_UPDATE.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchUpdateVisaRequired() {
  yield takeLatest(constants.APPLICATION_VISA_REQUIRED_UPDATE.REQUEST, updateVisaRequired);
}
