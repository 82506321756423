import { z } from 'zod';
import { PayoutDownloadMstSchema } from './base';

//Define params
export const BuildCSvParamsSchema = PayoutDownloadMstSchema.pick({
  ymonth: true,
  payment_provider_id: true,
});
export type BuildCSvParams = z.infer<typeof BuildCSvParamsSchema>;

export const BuildCsvResultSchema = z.object({
  p_pdlf_id: z.number(),
});
export type BuildCsvResult = z.infer<typeof BuildCsvResultSchema>;
