import * as api from '@redux/api/application';
import * as schemas from '@src/schemas';
import { actions as entitiesActions } from '@redux/modules/entities';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';
import { normalize } from 'normalizr';
import isFunction from 'lodash/isFunction';

export function* initializeUserOnBrains(action) {
  try {
    const response = yield call(api.initializeUserOnBrains, action);
    const normalized = normalize(response.user, schemas.user);
    const { users } = normalized.entities;

    yield put(entitiesActions.mergeUsers(users));

    yield put({
      type: constants.APPLICATION_USER_BRAINS_CREATE.SUCCESS,
    });
    const { applicationId } = action;
    yield put({ type: constants.APPLICATION_GET.REQUEST, applicationId });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(response.message);
    }
    const timelineResponse = yield call(api.getApplicationTimelineList, action);
    const timelineList = (timelineResponse && timelineResponse.data) || [];
    yield put({ type: constants.APPLICATION_TIMELINES_GET.SUCCESS, timelineList });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_USER_BRAINS_CREATE.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onError)) {
      console.error(e);
      yield action.onError(e.message || e);
    }
  }
}

export function* watchInitializeUserOnBrains() {
  yield takeLatest(constants.APPLICATION_USER_BRAINS_CREATE.REQUEST, initializeUserOnBrains);
}
