import { Endpoint, Method } from '../../../infrastructure';
import { z } from 'zod';
// body only includes key_value to delete
export const GenericTableDeleteRequestSchema = z.object({
  key_value: z.number(),
});
export type GenericTableDeleteRequest = z.infer<typeof GenericTableDeleteRequestSchema>;
// params schema

export const GenericTableDeleteParamsSchema = z.object({
  table_name: z.string(),
  key_name: z.string(),
});
export type GenericTableDeleteParams = z.infer<typeof GenericTableDeleteParamsSchema>;

// response
export type GenericTableDeleteResponse = {};

// endpoint -> method, url, response, params, query, body
export type GenericTableDeleteEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/generic-table/${GenericTableDeleteParams['table_name']}/${GenericTableDeleteParams['key_name']}`,
  GenericTableDeleteResponse,
  GenericTableDeleteParams,
  undefined,
  GenericTableDeleteRequest
>;
