import { createSagaAction } from '@shared/sagas';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import each from 'lodash/each';
import cloneDeep from 'lodash/cloneDeep';

// MODULE

const constants = {
  ASSIGNMENTS_REPORT_APPLICATION_ASSIGNMENTS_UPDATE: createSagaAction(
    'ASSIGNMENTS_REPORT_APPLICATION_ASSIGNMENTS_UPDATE',
  ),
};

const actions = {
  updateApplicationAssignments: (applicationId, assignments) => ({
    type: constants.ASSIGNMENTS_REPORT_APPLICATION_ASSIGNMENTS_UPDATE.REQUEST,
    applicationId,
    assignments,
  }),
  updateApplicationAssignmentsSuccess: (applicationId) => ({
    type: constants.ASSIGNMENTS_REPORT_APPLICATION_ASSIGNMENTS_UPDATE.SUCCESS,
  }),
  updateApplicationAssignmentsFail: (applicationId) => ({
    type: constants.ASSIGNMENTS_REPORT_APPLICATION_ASSIGNMENTS_UPDATE.FAILURE,
    applicationId,
  }),
};

const handlers = {
  [constants.ASSIGNMENTS_REPORT_APPLICATION_ASSIGNMENTS_UPDATE.REQUEST]: (
    state,
    { applicationId, assignments },
  ) => {
    const leadsAssignmentsData = state.leadsAssignmentsData;
    const leadsAssignmentsDataBackup = cloneDeep(leadsAssignmentsData);
    each(Object.keys(leadsAssignmentsData), (leadId) => {
      const leadPositions = leadsAssignmentsData[leadId];
      for (const positionId in leadPositions) {
        const idx = findIndex(leadPositions[positionId], (item) => item._id === applicationId);
        if (idx >= 0) {
          const assignmentApplication = leadPositions[positionId][idx];
          assignmentApplication.assignments = assignments;

          each(assignmentApplication.assignments, (assignment) => {
            if (!leadPositions[assignment.position]) {
              leadPositions[assignment.position] = [];
            }
            const isAssignmentOnReport =
              find(
                leadPositions[assignment.position],
                ({ _id }) => _id === assignmentApplication._id,
              ) || false;
            if (!isAssignmentOnReport) {
              leadPositions[assignment.position].push({
                ...assignmentApplication,
              });
            }
          });
        }
      }
    });
    return {
      ...state,
      leadsAssignmentsData: { ...leadsAssignmentsData },
      leadsAssignmentsDataBackup,
      isActionRunning: true,
    };
  },
  [constants.ASSIGNMENTS_REPORT_APPLICATION_ASSIGNMENTS_UPDATE.SUCCESS]: (state, action) => {
    return { ...state, isActionRunning: false };
  },
  [constants.ASSIGNMENTS_REPORT_APPLICATION_ASSIGNMENTS_UPDATE.FAILURE]: (
    state,
    { applicationId },
  ) => {
    state.leadsAssignmentsData = { ...state.leadsAssignmentsDataBackup };
    delete state.leadsAssignmentsDataBackup;
    return { ...state, isActionRunning: false };
  },
};

export function* watchNull() {}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchNull,
};
