import { Endpoint, Method } from '../../../infrastructure';
import { InterviewCalendarEvent } from '../base';
import { z } from 'zod';

export const ICESetConductedParamsSchema = z.object({
  interview_calendar_event_id: z.coerce.number(),
});
export type ICESetConductedParams = z.infer<typeof ICESetConductedParamsSchema>;

export type ICESetConductedResponse = InterviewCalendarEvent;

export type ICESetConductedEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/interview-calendar-events/${ICESetConductedParams['interview_calendar_event_id']}/conducted`,
  ICESetConductedResponse,
  ICESetConductedParams,
  undefined,
  undefined
>;
