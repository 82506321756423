import { z } from 'zod';
import { POCSchema } from './basePOC';

export const CreatePOCSchema = POCSchema.pick({
  payment_option_change_id: true,
  person_id: true,
  person_tax_id: true,
  company_tax_id: true,
  dt_start: true,
  status: true,
  payment_info: true,
})
  .partial({
    dt_start: true,
    status: true,
    payment_info: true,
    person_tax_id: true,
    company_tax_id: true,
  })
  .extend({
    provider: z.enum([
      'Payoneer',
      'Wire',
      'Husky',
      'ACH',
      'Gusto',
      'Paytrak',
      'AlephBeta',
      'Other',
    ]),
  });

export type CreatePOC = z.infer<typeof CreatePOCSchema>;
