import * as api from '@redux/api/application';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';

function* getAssignmentOptions(action) {
  try {
    const response = yield call(api.getAssignmentOptions, action);
    const assignmentOptions = response;

    yield put({ type: constants.APPLICATION_ASSIGNMENT_OPTIONS_GET.SUCCESS, assignmentOptions });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_ASSIGNMENT_OPTIONS_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetAssignmentOptions() {
  yield takeLatest(constants.APPLICATION_ASSIGNMENT_OPTIONS_GET.REQUEST, getAssignmentOptions);
}
