import { z } from 'zod';
import { dateString } from '../../infrastructure';

export enum EnumAppBusinessErrorCode {
  'APPLIED_LESS_30_DAYS' = 'APPLIED_LESS_30_DAYS',
  'APPLIED_LESS_6_MONTHS' = 'APPLIED_LESS_6_MONTHS',
  'ALREADY_CONSIDERING_PROFILE' = 'ALREADY_CONSIDERING_PROFILE',
  'ALREADY_IN_HIRING_PROCESS' = 'ALREADY_IN_HIRING_PROCESS',
  'ALREADY_HIRED' = 'ALREADY_HIRED',
  'JOB_NOT_ACTIVE' = 'JOB_NOT_ACTIVE',
  'JOB_NOT_FOUND' = 'JOB_NOT_FOUND',
  'MISSING_INFORMATION' = 'MISSING_INFORMATION',
}

export enum EnumAppBusinessErrorDescription {
  'APPLIED_LESS_30_DAYS' = 'You have already applied for a open position less than 30 days ago.',
  'APPLIED_LESS_6_MONTHS' = 'You already applied less than 6 months ago.',
  'ALREADY_CONSIDERING_PROFILE' = 'Hey, we are actively considering your profile.',
  'ALREADY_IN_HIRING_PROCESS' = 'You are already in the hiring process.',
  'ALREADY_HIRED' = 'You are already hired.',
  'JOB_NOT_ACTIVE' = 'The job post you are looking for is no longer active. Please see our current list of job openings.',
  'JOB_NOT_FOUND' = 'The job post you are looking for does not exist. Please see our current list of job openings.',
  'MISSING_INFORMATION' = 'The application are missing important informations.',
}

export const AppBusinessErrorCodeSchema = z.object({
  app_business_error_code: z.nativeEnum(EnumAppBusinessErrorCode),
  app_business_error_description: z.nativeEnum(EnumAppBusinessErrorDescription),
  dt_created: dateString,
});

export type AppBusinessErrorCode = z.infer<typeof AppBusinessErrorCodeSchema>;
