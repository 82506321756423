import { z } from 'zod';

const createdTimestampSchema = z.object({
  created_by: z.string().nullish(),
  dt_created: z.date(),
});
const updatedTimestampSchema = z.object({
  updated_by: z.string().nullish(),
  dt_updated: z.date(),
});

const deletedTimestampSchema = z.object({
  deleted_by: z.string().nullish(),
  dt_deleted: z.date().nullish(),
});

export const BaseSchemaDW = z
  .object({})
  .merge(createdTimestampSchema)
  .merge(updatedTimestampSchema)
  .merge(deletedTimestampSchema);

export type BaseDW = z.infer<typeof BaseSchemaDW>;
