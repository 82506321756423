import * as api from '@redux/api/application';
import * as schemas from '@src/schemas';
import { actions as entitiesActions } from '@redux/modules/entities';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';
import { normalize } from 'normalizr';
import isFunction from 'lodash/isFunction';

type UpdateAutoSignContractCTApiResponse = Awaited<
  ReturnType<typeof api.updateApplicationAutoSignContractCT>
>;
type UpdateAutoSignContractCTAction = Parameters<typeof api.updateApplicationAutoSignContractCT>[0];

export function* updateApplicationAutoSignContractCT(
  action: UpdateAutoSignContractCTAction & {
    onSuccess: (message: string) => void;
    onError: (error: unknown) => void;
  },
) {
  try {
    const response: UpdateAutoSignContractCTApiResponse = yield call(
      api.updateApplicationAutoSignContractCT,
      action,
    );
    const normalized = normalize(response.application, schemas.application);
    const { users, applications } = normalized.entities;

    yield put(entitiesActions.mergeApplications(applications));

    yield put(entitiesActions.mergeUsers(users));

    yield put({
      type: constants.APPLICATION_AUTO_SIGN_CONTRACT_CT_UPDATE.SUCCESS,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess('Update Successful');
    }
  } catch (e) {
    const error = e instanceof Error ? e.message : e;

    yield put({
      type: constants.APPLICATION_AUTO_SIGN_CONTRACT_CT_UPDATE.FAILURE,
      error,
    });
    if (isFunction(action.onError)) {
      yield action.onError(error);
    }
  }
}

export function* watchUpdateApplicationAutoSignContractCT() {
  // @ts-ignore
  yield takeLatest(
    constants.APPLICATION_AUTO_SIGN_CONTRACT_CT_UPDATE.REQUEST,
    updateApplicationAutoSignContractCT,
  );
}
