import { z } from 'zod';
import { PaymentProviderMonthSchema } from './base';

export const PaymentProviderMonthCreateSchema = PaymentProviderMonthSchema.pick({
  payment_provider_id: true,
  ymonth: true,
  cut_off_day: true,
  last_business_day: true,
}).partial({
  cut_off_day: true,
  last_business_day: true,
});
export type PaymentProviderMonthCreate = z.infer<typeof PaymentProviderMonthCreateSchema>;
