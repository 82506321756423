import moment from 'moment';
import { Link } from 'react-router-dom';
import { Flex, Typography, Descriptions } from 'antd';
import { NotificationConfigItem, NotificationConfigMessage } from '.';

export const InterviewStaledMessage: NotificationConfigMessage = (notification) => {
  return `Interview with ${
    notification.metadata?.applicant_name ?? ''
  } is awaiting your input. Its scheduled time has passed.`;
};

export const InterviewStaled: NotificationConfigItem = {
  title: 'Stale Invite',
  getDescription: (notification) =>
    notification.metadata && (
      <Flex gap={8} vertical>
        <Typography.Text>
          We requested scheduling more than 3 days ago but the{' '}
          <Link
            target="_blank"
            to={`/admin/application/${notification.metadata.application_id}`}
            style={{ textDecoration: 'underline', color: 'inherit' }}
          >
            {notification.metadata.applicant_name as string}
          </Link>{' '}
          has not scheduled it yet.
        </Typography.Text>
        <Descriptions bordered size="small">
          <Descriptions.Item label="Interview">
            {moment(notification.metadata.interview_date as string).format('ll HH:mm')}
          </Descriptions.Item>
        </Descriptions>
      </Flex>
    ),
};
