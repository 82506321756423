import { InterviewCalendarEvent } from '../base';
import { InterviewRequestSchema } from '../../interview-request';

import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const ICEInitiateRequestSchema = InterviewRequestSchema.pick({
  application_id: true,
  stage: true,
})
  .partial({
    stage: true,
  })
  .extend({
    recruiter_email: z.string(),
  });

export type ICEInitiateRequest = z.infer<typeof ICEInitiateRequestSchema>;

export type ICEInitiateResponse = InterviewCalendarEvent;

export type ICEInitiateEndpoint = Endpoint<
  Method.POST,
  '/api/v2/interview-calendar-events/initiate',
  ICEInitiateResponse,
  undefined,
  undefined,
  ICEInitiateRequest
>;
