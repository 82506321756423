import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import { constants } from '@redux/modules/reports/inbound-email-errors';
import * as api from '@redux/api/inbound-email-errors';

function* getInboundEmailErrors(action) {
  try {
    const data = yield call(api.getInboundEmailErrors, action);
    yield put({
      type: constants.INBOUND_EMAIL_ERRORS_GET.SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: constants.INBOUND_EMAIL_ERRORS_GET.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetInboundEmailErrors() {
  yield takeLatest(constants.INBOUND_EMAIL_ERRORS_GET.REQUEST, getInboundEmailErrors);
}

function* getSelectedApplication(action) {
  try {
    const response = yield call(api.getSelectedApplication, action);
    yield put({
      type: constants.SELECTED_APPLICATION_GET.SUCCESS,
      selectedApplication: response && response.data && response.data.application,
    });
  } catch (e) {
    yield put({
      type: constants.SELECTED_APPLICATION_GET.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetSelectedApplication() {
  yield takeLatest(constants.SELECTED_APPLICATION_GET.REQUEST, getSelectedApplication);
}

function* resolveInboundEmailError(action) {
  try {
    const data = yield call(api.resolveInboundEmailError, action);
    yield put({
      type: constants.INBOUND_EMAIL_ERROR_RESOLVE.SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: constants.INBOUND_EMAIL_ERROR_RESOLVE.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchResolveInboundEmailError() {
  yield takeLatest(constants.INBOUND_EMAIL_ERROR_RESOLVE.REQUEST, resolveInboundEmailError);
}
/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetInboundEmailErrors),
    fork(watchGetSelectedApplication),
    fork(watchResolveInboundEmailError),
  ]);
}
