import { z } from 'zod';
import { FinBudget, FinBudgetUpdateSchema } from '../';
import { Endpoint, Method } from '../../../infrastructure';

export const FinBudgetUpdateRequestSchema = FinBudgetUpdateSchema.pick({
  budget_name: true,
  cost_budget: true,
  revenue_budget: true,
});

export type FinBudgetUpdateRequest = z.infer<typeof FinBudgetUpdateRequestSchema>;

export const FinBudgetUpdateParamsSchema = z.object({
  budget_id: z.coerce.number(),
});
export type FinBudgetUpdateParams = z.infer<typeof FinBudgetUpdateParamsSchema>;

export type FinBudgetUpdateResponse = FinBudget;

export type FinBudgetUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/fin-budgets/${FinBudgetUpdateParams['budget_id']}`,
  FinBudgetUpdateResponse,
  FinBudgetUpdateParams,
  undefined,
  FinBudgetUpdateRequest
>;
