import { z } from 'zod';
import { dateString } from '../../infrastructure';

export enum TimeOffRequestStatus {
  'Pending' = 'Pending',
  'Approved' = 'Approved',
  'Declined' = 'Declined',
}

export enum TimeOffRequestCategory {
  'Vacation' = 'Vacation',
  'Family Leave' = 'Family Leave',
  'Bereavement' = 'Bereavement',
  'Paternity/Maternity' = 'Paternity/Maternity',
  'Jury Duty' = 'Jury Duty',
  'Other' = 'Other',
}

export const TimeOffRequestSchema = z.object({
  time_off_request_id: z.number(),
  person_id: z.string(),
  date_range: z.tuple([dateString, dateString]),
  is_flexible: z.boolean(),
  is_paid: z.boolean(),
  is_from_hire: z.boolean(),
  last_year_days: z.number().nullable(),

  status: z.nativeEnum(TimeOffRequestStatus),
  approved_by: z.string().nullable(),
  dt_approved: dateString.nullable(),
  declined_by: z.string().nullable(),
  dt_declined: dateString.nullable(),

  category: z.nativeEnum(TimeOffRequestCategory).nullable(),
  mongo_id: z.string(),
  exceptional_not_counted: z.boolean().nullable(),
  working_days: z.number().nullable(),
  working_days_last_year: z.number().nullable(),
  working_days_next_year: z.number().nullable(),

  year: z.number(),
  dt_created: dateString,
  dt_updated: dateString,
});

export type TimeOffRequest = z.infer<typeof TimeOffRequestSchema>;
