import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';
import { Endpoint, Method } from '../../infrastructure';

//Body
export const ReplaceCurrentPositionAssignmentSchema = z.object({
  previous_asn_end: dateString,
  new_asn_start: dateString,
  new_asn_end: dateString,
  new_asn_person: z.string(),
});
export type ReplaceCurrentPositionAssignment = z.infer<
  typeof ReplaceCurrentPositionAssignmentSchema
>;

export const ReplaceCurrentPositionAssignmentParamsSchema = z.object({
  position_id: z.string(),
});
export type ReplaceCurrentPositionAssignmentParams = z.infer<
  typeof ReplaceCurrentPositionAssignmentParamsSchema
>;

export const ReplaceCurrentPositionAssignmentResponseSchema = z.object({
  message: z.string(),
});
export type ReplaceCurrentPositionAssignmentResponse = z.infer<
  typeof ReplaceCurrentPositionAssignmentResponseSchema
>;

export type ReplaceCurrentPositionAssignmentEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/positions/${ReplaceCurrentPositionAssignmentParams['position_id']}/replace-current-assignment`,
  ReplaceCurrentPositionAssignmentResponse,
  ReplaceCurrentPositionAssignmentParams,
  undefined,
  ReplaceCurrentPositionAssignment
>;
