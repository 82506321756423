import { z } from 'zod';
export const PositionInvoicedRevenueByProjectItemSchema = z.object({
  position_id: z.string(),
  project_id: z.string(),
  ymonth: z.string(),
  invoicedrevenue: z.number(),
});
export type PositionInvoicedRevenueByProjectItem = z.infer<
  typeof PositionInvoicedRevenueByProjectItemSchema
>;

export const PositionInvoicedRevenueByProjectFiltersSchema = z.object({
  project_id: z.string(),
  start_month: z.number(),
  end_month: z.number(),
});
export type PositionInvoicedRevenueByProjectFilters = z.infer<
  typeof PositionInvoicedRevenueByProjectFiltersSchema
>;
