import { z } from 'zod';
import { TimeOffRequestUpdateSchema } from '../crud';
import { TimeOffRequest } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const TimeOffRequestUpdateRequestSchema = TimeOffRequestUpdateSchema.pick({
  category: true,
  date_range: true,
  is_flexible: true,
  is_paid: true,
  last_year_days: true,
  exceptional_not_counted: true,
});
export type TimeOffRequestUpdateRequest = z.infer<typeof TimeOffRequestUpdateRequestSchema>;

export const TimeOffRequestUpdateParamsSchema = z.object({
  time_off_request_id: z.coerce.number(),
});
export type TimeOffRequestUpdateParams = z.infer<typeof TimeOffRequestUpdateParamsSchema>;

export type TimeOffRequestUpdateResponse = TimeOffRequest;

export type TimeOffRequestUpdateEndpoint = Endpoint<
  Method.GET,
  `/api/v2/time-off-requests/${TimeOffRequestUpdateParams['time_off_request_id']}`,
  TimeOffRequestUpdateResponse,
  TimeOffRequestUpdateParams,
  undefined,
  TimeOffRequestUpdateRequest
>;
