import { z } from 'zod';
import { BaseSchemaDW } from '../structure';

export const RecruiterRoleRefDWSchema = z
  .object({
    role_id: z.number().nullable(),
    role_name: z.string(),
  })
  .merge(BaseSchemaDW);
export type RecruiterRoleRefDW = z.infer<typeof RecruiterRoleRefDWSchema>;

export const RecruiterRoleMongoSchema = z.object({
  category: z.string(),
});

export type RecruiterRoleMongo = z.infer<typeof RecruiterRoleMongoSchema>;
