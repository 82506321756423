import { call, put, takeLatest } from 'redux-saga/effects';

import isFunction from 'lodash/isFunction';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';
import { apiGetAllJobs } from '@src/redux/jobs/get-all';
import { constants as jobsConstants } from '@redux/jobs';

// MODULE
const constants = {
  CREATE_JOB: createSagaAction('CREATE_JOB'),
};

const actions = {
  createJob: (args) => ({
    type: constants.CREATE_JOB.REQUEST,
    ...args,
  }),
};

const handlers = {};

// SAGA
function* createJob(action) {
  try {
    const createJobResponse = yield call(apiCreateJob, action);
    const allJobsResponse = yield call(apiGetAllJobs, action);

    const allJobs = allJobsResponse.data;

    yield put({ type: jobsConstants.GET_ALL_JOBS.SUCCESS, allJobs });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(createJobResponse.jobId);
    }
  } catch (error) {
    yield put({
      type: jobsConstants.GET_ALL_JOBS.FAILURE,
      error: error.message || error,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(error.message || error);
    }
  }
}

function* watchCreateJob() {
  yield takeLatest(constants.CREATE_JOB.REQUEST, createJob);
}

// API
export function apiCreateJob({ job }) {
  return request('/jobs', {
    method: 'POST',
    body: {
      ...job,
    },
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchCreateJob,
};
