import { Endpoint, Method } from '../../../infrastructure';
import { Application } from '../base';
import { z } from 'zod';

export const ApplicationSetVisaInfoRequestSchema = z.object({
  is_visa_required: z.boolean(),
  visa_details: z.string().nullable(),
  visa_status: z.number().nullable(),
});
export type ApplicationSetVisaInfoRequest = z.infer<typeof ApplicationSetVisaInfoRequestSchema>;

export const ApplicationSetVisaInfoParamsSchema = z.object({
  application_id: z.string(),
});
export type ApplicationSetVisaInfoParams = z.infer<typeof ApplicationSetVisaInfoParamsSchema>;

export type ApplicationSetVisaInfoResponse = Application;

export type ApplicationSetVisaInfoEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/applications/${ApplicationSetVisaInfoParams['application_id']}/set-visa-info`,
  ApplicationSetVisaInfoResponse,
  ApplicationSetVisaInfoParams,
  undefined,
  ApplicationSetVisaInfoRequest
>;
