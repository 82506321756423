import { InterviewRequestSchema } from '../../interview-request';
import { z } from 'zod';
import { dateString, Endpoint, Method } from '../../../infrastructure';

export const ICEManualLinkGoogleEventRequestSchema = InterviewRequestSchema.pick({
  application_id: true,
  stage: true,
})
  .extend({
    recruiter_email: z.string(),
    dt_start: dateString,
    dt_end: dateString,
  })
  .partial({
    stage: true,
  });

export type ICEManualLinkGoogleEventRequest = z.infer<typeof ICEManualLinkGoogleEventRequestSchema>;

export type ICEManualLinkGoogleEventResponse = {};

export type ICEManualLinkGoogleEventEndpoint = Endpoint<
  Method.POST,
  '/api/v2/interview-calendar-events/manual-link-google-event',
  ICEManualLinkGoogleEventResponse,
  undefined,
  undefined,
  ICEManualLinkGoogleEventRequest
>;
