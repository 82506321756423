import { z } from 'zod';
import { GridBaseSchema } from '../../infrastructure';

export const FinBudgetSchema = z.object({
  budget_id: z.number(),
  year: z.number(),
  budget_name: z.string(),
  revenue_budget: z.array(z.number()),
  cost_budget: z.array(z.number()),
});
export type FinBudget = z.infer<typeof FinBudgetSchema>;

export const BudgetProjectionMetaSchema = z.object({
  year: z.coerce.number(),
  budget_name: z.string(),
});
export type BudgetProjectionMeta = z.infer<typeof BudgetProjectionMetaSchema>;

export const BudgetProjectionSchema = GridBaseSchema.extend({
  meta: BudgetProjectionMetaSchema,
});
export type BudgetProjection = z.infer<typeof BudgetProjectionSchema>;
