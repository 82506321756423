const actionDefinitions = [
  {
    key: 'SOURCES_REPORT_GET',
    opts: { successKey: 'data' },
  },
  {
    key: 'ADVANCED_SOURCING_REPORT_GET',
    opts: { successKey: 'advancedSourcingReport' },
  },
];

export default actionDefinitions;
