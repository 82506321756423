import { useEffect } from 'react';
import { isDynamicEnv } from '@src/shared/Util';

export const useDynamicEnvAuth = ({ dispatch, loginAction, frontendBase }) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (isDynamicEnv(frontendBase) && params.has('accessToken') && params.has('refreshToken')) {
      dispatch(
        loginAction({
          accessToken: params.get('accessToken'),
          refreshToken: params.get('refreshToken'),
        }),
      );
    }
  }, []);
};
