import { z } from 'zod';
import { MongoEndDateCertainty, PersonMongoSchema } from '../';
import { dateString, Endpoint, FilterOperations, Method } from '../../infrastructure';

export const AvailabilityLastProjectAssignmentSchema = z.object({
  _id: z.string(),
  endDate: dateString,
  endDateCertainty: z.nativeEnum(MongoEndDateCertainty),
});

export const AvailabilityLastProject = z.object({
  _id: z.string(),
  name: z.string(),
  isSigned: z.string(),
  client: z.object({
    name: z.string(),
  }),
});

export const PositionStaffingGetAvailablePeopleSchema = z.object({
  project_id: z.string(),
  position_id: z.string(),
  position_name: z.string(),
  position_staffing_id: z.number(),
  person_id: z.string(),
  position_skills: z.array(z.string()),
  person_skills: z.array(z.string()),
  person_name: z.string(),
  availabledate: dateString,
  lastprojectassignment: AvailabilityLastProjectAssignmentSchema.nullable(),
  lastproject: AvailabilityLastProject.nullable(),
  country: z.string(),
  pto: z.array(z.tuple([dateString, dateString])).nullable(),
  staffing_candidate_id: z.string().nullable(),
});

export type PositionStaffingGetAvailablePeople = z.infer<
  typeof PositionStaffingGetAvailablePeopleSchema
>;

export const PositionStaffingGetAvailablePeopleFiltersSchema = z.object({
  project_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
  position_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  hire_group: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  minimumAvailability: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
  daysInterval: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
});
export type PositionStaffingGetAvailablePeopleFilters = z.infer<
  typeof PositionStaffingGetAvailablePeopleFiltersSchema
>;

export const PositionStaffingGetAvailablePeopleQuerySchema = z.object({
  filters: PositionStaffingGetAvailablePeopleFiltersSchema,
});
export type PositionStaffingGetAvailablePeopleQuery = z.infer<
  typeof PositionStaffingGetAvailablePeopleQuerySchema
>;

export const PositionStaffingAvailablePersonSchema = PersonMongoSchema.pick({
  _id: true,
  gravatarURL: true,
  name: true,
  email: true,
  publicSkills: true,
  salary: true,
  salaries: true,
  timezoneId: true,
  timezone: true,
  timezoneShort: true,
  timezoneEST: true,
  timezoneShortEST: true,
  clevertimeId: true,
  hireDate: true,
  hirePeriods: true,
  terminationDate: true,
  isBillable: true,
  isHourlyBillable: true,
  role: true,
  shortName: true,
  isAvailableNow: true,
  sort: true,
  linkedinURL: true,
  cleverHireID: true,
  activeTags: true,
}).extend({
  department: z.object({
    _id: z.string(),
    name: z.string(),
    parent: z.string(),
  }),
});

export type PositionStaffingAvailablePerson = z.infer<typeof PositionStaffingAvailablePersonSchema>;

export const PositionStaffingGetAvailablePeopleResponseSchema =
  PositionStaffingAvailablePersonSchema.extend({
    availableDate: dateString,
    lastProject: AvailabilityLastProject.nullable(),
    lastProjectAssignment: AvailabilityLastProjectAssignmentSchema.nullable(),
    country: z.string(),
    timeOff: z
      .object({
        startDate: dateString,
        endDate: dateString,
      })
      .array(),
    positionStaffingMatchs: z.array(
      z.object({
        position_id: z.string(),
        project_id: z.string(),
        position_staffing_id: z.number(),
        position_name: z.string().optional(),
        staffing_candidate_id: z.string().nullable(),
      }),
    ),
    skills: z.array(z.string()),
  }).array();
export type PositionStaffingGetAvailablePeopleResponse = z.infer<
  typeof PositionStaffingGetAvailablePeopleResponseSchema
>;

//Define the endpoint
export type PositionStaffingGetAvailablePeopleEndpoint = Endpoint<
  Method.GET,
  '/api/v2/positions-staffing/psn-available',
  PositionStaffingGetAvailablePeopleResponse,
  undefined,
  PositionStaffingGetAvailablePeopleQuery,
  undefined
>;
