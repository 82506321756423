import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

import map from 'lodash/map';
import zipObject from 'lodash/zipObject';
import mapValues from 'lodash/mapValues';
import defaults from 'lodash/defaults';
import clone from 'lodash/clone';
import filter from 'lodash/filter';
import values from 'lodash/values';

export const ACTION_MAP = {
  getScheduledEmails: {
    key: 'SCHEDULED_EMAILS_GET',
  },
  deleteScheduledEmail: {
    key: 'SCHEDULED_EMAILS_DELETE',
  },
};

// Action Creators
const _keys = map(values(ACTION_MAP), 'key');

const _values = map(_keys, (key) => createSagaAction(key));

// Constants
export const constants = zipObject(_keys, _values);

// Action Creators
export const actions = mapValues(ACTION_MAP, (o) => {
  return (data) => defaults({ type: constants[o.key].REQUEST }, data);
});

// Reducer
export const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

const ACTION_HANDLERS = {
  [constants.SCHEDULED_EMAILS_GET.REQUEST]: (state, { data }) => {
    return { ...state, isLoading: true };
  },
  [constants.SCHEDULED_EMAILS_GET.SUCCESS]: (state, { data }) => {
    return { ...state, data, isLoading: false };
  },
  [constants.SCHEDULED_EMAILS_GET.FAILURE]: (state, { error }) => {
    return { ...state, error, isLoading: false };
  },
  [constants.SCHEDULED_EMAILS_DELETE.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.SCHEDULED_EMAILS_DELETE.SUCCESS]: (state, { emailId }) => {
    let emails = clone(state.data);

    emails = filter(emails, (email) => email._id !== emailId);

    return { ...state, data: emails, isLoading: false };
  },
  [constants.SCHEDULED_EMAILS_DELETE.FAILURE]: (state, { error }) => {
    return { ...state, error, isLoading: false };
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
