import { InterviewRequest, InterviewRequestUpdateSchema } from '../';
import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const InterviewRequestUpdateRequestSchema = InterviewRequestUpdateSchema.pick({
  act_recruiter_id: true,
  dt_canceled: true,
  dt_conducted: true,
  dt_scheduled: true,
  num_days: true,
});
export type InterviewRequestUpdateRequest = z.infer<typeof InterviewRequestUpdateRequestSchema>;

export const InterviewRequestUpdateParamsSchema = z.object({
  interview_request_id: z.coerce.number(),
});
export type InterviewRequestUpdateParams = z.infer<typeof InterviewRequestUpdateParamsSchema>;

export type InterviewRequestUpdateResponse = InterviewRequest;

export type InterviewRequestUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/interview-requests/${InterviewRequestUpdateParams['interview_request_id']}`,
  InterviewRequestUpdateResponse,
  InterviewRequestUpdateParams,
  undefined,
  InterviewRequestUpdateRequest
>;
