import { z } from 'zod';
import { BaseSchemaDW } from '../structure';

export const ApplicantDWSchema = z
  .object({
    applicant_id: z.number().nullable(),
    mongo_user_id: z.string(),
    first_name: z.string().nullable(),
    last_name: z.string().nullable(),
    full_name: z.string(),
    email: z.string(),
    person_id: z.string().nullable(),
  })
  .merge(BaseSchemaDW);

export type ApplicantDW = z.infer<typeof ApplicantDWSchema>;

export const ApplicantMongoDWSchema = z.object({
  _id: z.string(),
  name: z.string(),
  email: z.string(),
  brainsId: z.string().nullish(),
  updated_at: z.string(),
  created_at: z.string(),
});

export type ApplicantMongoDW = z.infer<typeof ApplicantMongoDWSchema>;
