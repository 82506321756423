import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  AUTHENTICATION_GET_ACCOUNT: createSagaAction('AUTHENTICATION_GET_ACCOUNT'),
  AUTHENTICATION_GET_USER: createSagaAction('AUTHENTICATION_GET_USER'),
  AUTHENTICATION_LOGIN: createSagaAction('AUTHENTICATION_LOGIN'),
  AUTHENTICATION_LOGIN_TOKEN: createSagaAction('AUTHENTICATION_LOGIN_TOKEN'),
  AUTHENTICATION_MOCK: createSagaAction('AUTHENTICATION_MOCK'),
  AUTHENTICATION_REFRESH: createSagaAction('AUTHENTICATION_REFRESH'),
  AUTHENTICATION_LOGOUT: createSagaAction('AUTHENTICATION_LOGOUT'),
  AUTHENTICATION_GET_PERMISSIONS: createSagaAction('AUTHENTICATION_GET_PERMISSIONS'),
};

// Action Creators

export const actions = {
  login: ({ accessToken, location, code }) => ({
    type: constants.AUTHENTICATION_LOGIN.REQUEST,
    accessToken,
    location,
    code,
  }),
  loginWithToken: ({ accessToken, refreshToken, location }) => ({
    type: constants.AUTHENTICATION_LOGIN_TOKEN.REQUEST,
    accessToken,
    refreshToken,
    location,
  }),
  mocklogin: ({ userEmail }) => ({
    type: constants.AUTHENTICATION_MOCK.REQUEST,
    userEmail,
  }),
  logout: () => ({
    type: constants.AUTHENTICATION_LOGOUT.REQUEST,
  }),
  getAccount: () => ({
    type: constants.AUTHENTICATION_GET_ACCOUNT.REQUEST,
  }),
  getPermissions: () => ({
    type: constants.AUTHENTICATION_GET_PERMISSIONS.REQUEST,
  }),
};

// Reducer

const initialState = {
  permissions: {},
};

export default createReducer(initialState, (state, action) => {
  switch (action.type) {
    case constants.AUTHENTICATION_LOGIN.REQUEST:
      return { ...state, error: null, loggingIn: true };

    case constants.AUTHENTICATION_MOCK.REQUEST: {
      const { userEmail } = action;
      return { ...state, userEmail, error: null, loggingIn: true };
    }

    case constants.AUTHENTICATION_LOGIN.SUCCESS: {
      const { user, fingerprint } = action;
      return { ...state, user, fingerprint };
    }

    case constants.AUTHENTICATION_LOGIN.FAILURE:
      return {
        ...state,
        error: action.message,
      };

    case constants.AUTHENTICATION_REFRESH.REQUEST:
      return { ...state, error: null, refreshingToken: true };
    case constants.AUTHENTICATION_REFRESH.SUCCESS: {
      const user = action.user;
      return { ...state, user };
    }

    case constants.AUTHENTICATION_REFRESH.FAILURE:
      return {
        ...state,
        error: action.message,
      };

    case constants.AUTHENTICATION_LOGOUT.REQUEST:
      return { ...initialState, fingerprint: state.fingerprint };

    case constants.AUTHENTICATION_GET_USER.SUCCESS:
      return { ...state, user: action.user };

    case constants.AUTHENTICATION_GET_USER.FAILURE:
      return { ...state, error: action.message };

    case constants.AUTHENTICATION_GET_PERMISSIONS.REQUEST:
      return { ...state, error: null };

    case constants.AUTHENTICATION_GET_PERMISSIONS.SUCCESS: {
      const { permissions } = action;
      return { ...state, permissions };
    }

    case constants.AUTHENTICATION_GET_PERMISSIONS.FAILURE:
      return { ...state, error: action.message };

    default:
      return state;
  }
});
