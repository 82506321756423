import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE
const constants = {
  GET_ALL_JOBS_QUESTIONS: createSagaAction('GET_ALL_JOBS_QUESTIONS'),
};

const actions = {
  getAllJobQuestions: () => ({
    type: constants.GET_ALL_JOBS_QUESTIONS.REQUEST,
  }),
};

const handlers = {
  [constants.GET_ALL_JOBS_QUESTIONS.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.GET_ALL_JOBS_QUESTIONS.SUCCESS]: (state, { questions }) => {
    return { ...state, isLoading: false, allQuestions: questions };
  },
  [constants.GET_ALL_JOBS_QUESTIONS.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

// SAGA
function* getAllJobQuestions(action) {
  try {
    const response = yield call(apiGetAllJobQuestions, action);
    const questions = response.data;

    yield put({ type: constants.GET_ALL_JOBS_QUESTIONS.SUCCESS, questions });
  } catch (error) {
    yield put({
      type: constants.GET_ALL_JOBS_QUESTIONS.FAILURE,
      error: error.message || error,
    });
  }
}

function* watchGetAllJobQuestions() {
  yield takeLatest(constants.GET_ALL_JOBS_QUESTIONS.REQUEST, getAllJobQuestions);
}

// API
export function apiGetAllJobQuestions() {
  return request('/questions', {
    method: 'GET',
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchGetAllJobQuestions,
};
