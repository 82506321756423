const ACTION_MAP = {
  get: {
    key: 'APPLICATION_GET',
    opts: { successKey: null },
  },

  getContractOptions: {
    key: 'APPLICATION_CONTRACT_OPTIONS_GET',
    opts: {
      successKey: 'contractOptions',
      getValueFn: (state, { data }) => data,
    },
  },

  getCalendlyList: {
    key: 'APPLICATION_CALENDLY_LIST',
    opts: { successKey: 'applicationCalendly' },
  },

  getRecruitersList: {
    key: 'APPLICATION_RECRUITERS_LIST',
    opts: { successKey: 'recruitersList' },
  },

  getActiveRecruitersList: {
    key: 'APPLICATION_ACTIVE_RECRUITERS_LIST',
    opts: { successKey: 'activeRecruitersList' },
  },

  getApplicationTestList: {
    key: 'APPLICATION_TEST_LIST',
    opts: { successKey: 'applicationTests' },
  },

  getApplicationPast: {
    key: 'APPLICATION_PAST_GET',
    opts: { successKey: 'applicationPast' },
  },

  getStatuses: {
    key: 'APPLICATION_STATUSES_GET',
    opts: { successKey: 'applicationStatuses' },
  },
  getTechTestStatuses: {
    key: 'APPLICATION_TECH_TEST_STATUSES_GET',
    opts: {
      successKey: 'techTestStatuses',
    },
  },
  updateStatus: {
    key: 'APPLICATION_STATUS_UPDATE',
    opts: { successKey: null },
  },

  scheduleInterview: {
    key: 'APPLICATION_SCHEDULE_INTERVIEW',
    opts: { successKey: null },
  },

  // TODO: do we use it? why there was no action for this method?
  scheduleInterviewNotification: {
    key: 'APPLICATION_SCHEDULE_INTERVIEW_NOTIFICATION',
    opts: { successKey: null },
  },

  bookCalendlyExtensionInterview: {
    key: 'APPLICATION_SCHEDULE_INTERVIEW_GET_CALENDLY_LINK',
    opts: { successKey: null },
  },

  setSelectedTestId: {
    key: 'APPLICATION_SET_SELECTED_TEST_ID',
    opts: { successKey: 'selectedTestId' },
  },

  setApplicationReviewed: {
    key: 'APPLICATION_SET_REVIEWED',
    opts: { successKey: null },
  },

  sendTest: {
    key: 'APPLICATION_SEND_TEST',
    opts: { successKey: null, isLoadingKey: 'isSendingTest' },
  },

  updateUserYears: {
    key: 'APPLICATION_USER_YEARS_UPDATE',
    opts: { successKey: null },
  },
  updateUserSalary: {
    key: 'APPLICATION_USER_SALARY_UPDATE',
    opts: { successKey: null },
  },
  updateUserSalaryCurrency: {
    key: 'APPLICATION_USER_SALARY_CURRENCY_UPDATE',
    opts: { successKey: null },
  },
  updateUserPhone: {
    key: 'APPLICATION_USER_PHONE_UPDATE',
    opts: { successKey: null },
  },
  updateVisaRequired: {
    key: 'APPLICATION_VISA_REQUIRED_UPDATE',
    opts: { successKey: null },
  },
  updateUserEnglishLevel: {
    key: 'APPLICATION_USER_ENGLISH_LEVEL_UPDATE',
    opts: { successKey: null },
  },
  updateUserLinkedinURL: {
    key: 'APPLICATION_USER_LINKEDIN_URL_UPDATE',
    opts: { successKey: null },
  },
  updateApplicationSummary: {
    key: 'APPLICATION_SUMMARY_UPDATE',
    opts: { successKey: null },
  },
  updateUserAvailability: {
    key: 'APPLICATION_USER_AVAILABILITY_UPDATE',
    opts: { successKey: null },
  },
  updateUserStartDate: {
    key: 'APPLICATION_USER_START_DATE_UPDATE',
    opts: { successKey: null, isLoadingKey: 'isOfferTermsLoading' },
  },

  getSkills: {
    key: 'APPLICATION_SKILLS_GET',
    opts: { successKey: 'applicationSkills' },
  },
  getAssignmentOptions: {
    key: 'APPLICATION_ASSIGNMENT_OPTIONS_GET',
    opts: { successKey: 'assignmentOptions' },
  },
  // theoretically we can reuse search redux for this
  getInterviewOptions: {
    key: 'INTERVIEW_OPTIONS_GET',
    opts: { successKey: 'interviewOptions' },
  },
  getTimelineList: {
    key: 'APPLICATION_TIMELINES_GET',
    opts: { successKey: 'timelineList' },
  },

  getAllRejectionReasons: {
    key: 'REJECTION_REASONS_GET_ALL',
    opts: { successKey: null },
  },

  getInterviewerAvailability: {
    key: 'APPLICATION_INTERVIEWER_AVAILABILITY_GET',
    opts: { successKey: 'interviewerAvailability' },
  },

  getInterviewList: {
    key: 'APPLICATION_INTERVIEW_LIST_GET',
    opts: { successKey: 'interviewList' },
  },
  getVoiceNoteURL: {
    key: 'APPLICATION_VOICE_NOTE_GET',
    opts: {
      isLoadingKey: 'isLoadingVoiceNotes',
      successKey: 'voiceNote',
      getValueFn: (state, { voiceNote }) => voiceNote,
    },
  },
  createInterview: {
    key: 'APPLICATION_CREATE_INTERVIEW',
    opts: { successKey: null },
  },

  updateTags: {
    key: 'APPLICATION_UPDATE_TAGS',
    opts: { successKey: null },
  },

  getApplicationTags: {
    key: 'APPLICATION_GET_TAGS',
    opts: { successKey: 'applicationTags', isLoadingKey: 'loadingApplicationTags' },
  },

  deleteInterview: {
    key: 'APPLICATION_DELETE_INTERVIEW',
    opts: { successKey: null },
  },
  getInterview: {
    key: 'APPLICATION_GET_INTERVIEW_DETAILS',
    opts: {
      successKey: 'interviewDetails',
      getValueFn: (state, { interviewDetails }) => {
        return state.interviewDetails ? interviewDetails : {};
      },
    },
  },

  updateUserAssignments: {
    key: 'APPLICATION_USER_ASSIGNMENTS_UPDATE',
    opts: { successKey: null },
  },
  updateUserAssignment: {
    key: 'APPLICATION_USER_ASSIGNMENT_UPDATE',
    opts: { successKey: null },
  },
  getApplicationVideoAnswers: {
    key: 'APPLICATION_VIDEO_ANSWERS_GET',
    opts: { successKey: 'applicationVideoAnswers' },
  },
  deleteApplicationVideoAnswer: {
    key: 'APPLICATION_VIDEO_ANSWER_DELETE',
    opts: {
      successKey: 'applicationVideoAnswers',
      getValueFn: (state, { deletedId }) => {
        return state.applicationVideoAnswers.filter((aw) => aw._id !== deletedId);
      },
    },
  },

  updateApplicationSkill: {
    key: 'APPLICATION_SKILL_UPDATE',
    opts: { successKey: null },
  },

  updateApplicationJob: {
    key: 'APPLICATION_USER_JOB_UPDATE',
    opts: { successKey: null },
  },
  updateApplicationOwner: {
    key: 'APPLICATION_USER_OWNER_UPDATE',
    opts: { successKey: null },
  },
  updateApplicationAuthor: {
    key: 'APPLICATION_USER_AUTHOR_UPDATE',
    opts: { successKey: null },
  },
  updateApplicationTechInterviewer: {
    key: 'APPLICATION_USER_TECH_INTERVIEWER_UPDATE',
    opts: { successKey: null },
  },
  updateApplicationContractStatus: {
    key: 'APPLICATION_UPDATE_CONTRACT_STATUS',
    opts: { successKey: null },
  },
  updateInterview: {
    key: 'APPLICATION_UPDATE_INTERVIEW',
    opts: { successKey: null },
  },
  resetInterviewDetails: {
    key: 'APPLICATION_RESET_INTERVIEW_DETAILS',
    opts: {
      successKey: 'interviewDetails',
      getValueFn: (state) => {
        return (state.interviewDetails = {});
      },
    },
  },
  resetApplicationInfo: {
    key: 'APPLICATION_RESET_APPLICATION_INFO',
    opts: {
      successKey: 'timelineList',
      getValueFn: (state) => {
        return (state.timelineList = []);
      },
    },
  },
  resetApplicationVideoAnswers: {
    key: 'APPLICATION_RESET_APPLICATION_VIDEO_ANSWERS',
    opts: {
      successKey: 'applicationVideoAnswers',
      getValueFn: (state) => {
        return (state.applicationVideoAnswers = []);
      },
    },
  },
  resetBrainsAssignments: {
    key: 'APPLICATION_RESET_BRAINS_ASSIGNMENTS',
    opts: {
      successKey: 'brainsAssignments',
      getValueFn: (state) => {
        return (state.brainsAssignments = []);
      },
    },
  },
  createApplicant: {
    key: 'APPLICATION_CREATE_APPLICANT',
    opts: {
      successKey: 'createApplicantResult',
      failureKey: 'submitApplicationError',
      getValueFn: (state, { payload, error }) => {
        if (error) {
          return (state.submitApplicationError = error);
        }

        state.submitApplicationError = null;
        return (state.createApplicantResult =
          payload && payload.data ? payload.data.application._id : null);
      },
    },
  },
  getReferredBy: {
    key: 'APPLICATION_GET_REFERRAL',
    opts: { successKey: 'referredBy' },
  },
  getSourceList: {
    key: 'APPLICATION_GET_SOURCE',
    opts: { successKey: 'sourceList' },
  },
  getMediumList: {
    key: 'APPLICATION_GET_MEDIUM',
    opts: { successKey: 'mediumList' },
  },
  getFoundList: {
    key: 'APPLICATION_GET_FOUND',
    opts: { successKey: 'foundList' },
  },
  getAppliedList: {
    key: 'APPLICATION_GET_APPLIED',
    opts: { successKey: 'appliedList' },
  },
  getAdminList: {
    key: 'APPLICATION_GET_ADMINS',
    opts: { successKey: 'adminList' },
  },
  getSourcedByList: {
    key: 'APPLICATION_GET_SOURCED_BY',
    opts: { successKey: 'sourcedByList' },
  },
  updateDevskillerResult: {
    key: 'DEVSKILLER_RESULT_UPDATE',
    opts: { successKey: null },
  },
  syncTests: {
    key: 'SYNC_DEVSKILLER_TESTS',
    opts: { successKey: null },
  },
  cancelAssessmentRequest: {
    key: 'CANCEL_ASSESSMENT_REQUEST',
    opts: { successKey: 'assessmentRequestCanceled' },
  },
  archiveDevskillerTest: {
    key: 'ARCHIVE_DEVSKILLER_TEST',
    opts: { successKey: 'assessmentTestArchived' },
  },
  // Finalizing panel

  setDoNotContactBeforeDate: {
    key: 'APPLICATION_DO_NOT_CONTACT_BEFORE_DATE_SET',
    opts: { successKey: null },
  },

  updateUserHireDate: {
    key: 'APPLICATION_USER_HIRE_DATE_UPDATE',
    opts: { successKey: null },
  },

  updateUserOfferTerms: {
    key: 'APPLICATION_USER_OFFER_TERMS_UPDATE',
    opts: { successKey: null, isLoadingKey: 'isOfferTermsLoading' },
  },

  updateUserNativeEnglish: {
    key: 'APPLICATION_USER_NATIVE_ENGLISH_UPDATE',
    opts: { successKey: null },
  },

  updateUserLeadExperience: {
    key: 'APPLICATION_USER_LEAD_EXPERIENCE_UPDATE',
    opts: { successKey: null },
  },

  updateApplicationMgmtReviewed: {
    key: 'APPLICATION_MGMT_REVIEWED_UPDATE',
    opts: { successKey: null },
  },

  updateUserSeniority: {
    key: 'APPLICATION_USER_SENIORITY_UPDATE',
    opts: { successKey: null },
  },

  updateUserWorkingStatus: {
    key: 'APPLICATION_USER_WORKING_STATUS_UPDATE',
    opts: { successKey: null },
  },

  initializeUserOnBrains: {
    key: 'APPLICATION_USER_BRAINS_CREATE',
    opts: { successKey: null },
  },

  updateUserNeedContract: {
    key: 'APPLICATION_USER_NEED_CONTRACT_UPDATE',
    opts: { successKey: null },
  },
  updateApplicationAutoSignContractCT: {
    key: 'APPLICATION_AUTO_SIGN_CONTRACT_CT_UPDATE',
    opts: { successKey: null },
  },
  sendPreContractLetter: {
    key: 'APPLICATION_SEND_PRE_CONTRACT_LETTER',
    opts: { successKey: null },
  },
  sendOnBoardingLetter: {
    key: 'APPLICATION_SEND_ONBOARDING_LETTER',
    opts: { successKey: null },
  },
  getShowOnboardingButton: {
    key: 'APPLICATION_GET_SHOW_ONBOARDING_BUTTON',
    opts: { successKey: 'showOnboardingButton' },
  },

  // Other

  updateReachDate: {
    key: 'APPLICATION_REACH_DATE_UPDATE',
    opts: { successKey: null },
  },

  setExcitement: {
    key: 'APPLICATION_EXCITEMENT_SET',
    opts: { successKey: null },
  },

  setBackUrl: {
    key: 'APPLICATION_SET_BACK_URL',
    opts: { successKey: 'backUrl' },
  },

  deleteApplication: {
    key: 'APPLICATION_DELETE',
    opts: { successKey: null },
  },
  reEnableOnboardingForm: {
    key: 'REENABLE_ONBOARDING_FORM',
    opts: { successKey: null },
  },

  uploadDocument: {
    key: 'APPLICATION_UPLOAD_DOCUMENT',
    opts: { successKey: null, isLoadingKey: 'isUploading' },
  },

  deleteDocument: {
    key: 'APPLICATION_DELETE_DOCUMENT',
    opts: { successKey: null, isLoadingKey: 'isUploading' },
  },
  downloadDocument: {
    key: 'APPLICATION_DOWNLOAD_DOCUMENT',
    opts: { successKey: null, isLoadingKey: 'isUploading' },
  },
  getScorecardList: {
    key: 'APPLICATION_SCORECARD_LIST_GET',
    opts: { successKey: 'scorecardList' },
  },
  getScorecardDetails: {
    key: 'APPLICATION_SCORECARD_DETAILS_GET',
    opts: { successKey: 'currentScorecard' },
  },
  createScorecard: {
    key: 'APPLICATION_SCORECARD_CREATE',
    opts: { successKey: 'currentScorecard' },
  },
  updateScorecard: {
    key: 'APPLICATION_SCORECARD_UPDATE',
    opts: { successKey: 'currentScorecard' },
  },
  deleteScorecard: {
    key: 'APPLICATION_SCORECARD_DELETE',
    opts: { successKey: null },
  },
  clearCurrentScorecard: {
    key: 'APPLICATION_SCORECARD_CLEAR_CURRENT',
    opts: { successKey: 'currentScorecard' },
  },
  deleteError: {
    key: 'APPLICATION_ERROR_DELETE',
    opts: { successKey: 'error' },
  },
  updateClock: {
    key: 'APPLICATION_CLOCK_UPDATE',
    opts: { successKey: null },
  },
  getDepartmentOptions: {
    key: 'APPLICATION_DEPARTMENT_OPTIONS_GET',
    opts: {
      successKey: 'departmentOptions',
      getValueFn: (state, { data }) => data,
    },
  },
  getBrainsAssignments: {
    key: 'APPLICATION_GET_BRAINS_ASSIGNMENTS',
    opts: { successKey: 'brainsAssignments' },
  },
};

export default ACTION_MAP;
