import { z } from 'zod';
import { BaseSchemaDW } from '../structure';

export const ApplicationStatusRefDWSchema = z
  .object({
    status_code: z.number(),
    status_name: z.string(),
    is_final: z.boolean(),
    status_order: z.number(),
  })
  .merge(BaseSchemaDW);
export type ApplicationStatusRefDW = z.infer<typeof ApplicationStatusRefDWSchema>;

export const ApplicationStatusRefMongoSchema = z.object({
  name: z.string(),
  shortName: z.string(),
  id: z.string(),
  optgroup: z.string(),
  status: z.number().nullable(),
  aggregates: z.array(z.number()).nullable(),
  is_final: z.boolean().optional(),
  order: z.number(),
});

export type ApplicationStatusRefMongo = z.infer<typeof ApplicationStatusRefMongoSchema>;

export enum AppStatusCode {
  APPLYING = -1,
  NEW = 0,
  NEW_SOURCED = 1,
  REVIEWED = 2,
  RECRUITING_APPROVED = 13,
  TECH_TEST_SUBMITTED = 62,
  TECH_TEST_SCORED = 63,
  FIRST_INTERVIEW = 101,
  SECOND_INTERVIEW = 103,
  PRE_MH_INTERVIEW = 111,
  PRE_KS_INTERVIEW = 104,
  SCREENING = 204,
  VIDEOS_SUBMITTED = 200,
  TECH_TEST = 110,
  PENDING = 120,
  OFFERED = 9,
  REJECTED = 4,
  PASSIVE_RELATIONSHIP = 5,
  ACTIVE_RELATIONSHIP = 7,
  MOVED_ON = 17,
  APPLICANT_DECLINED = 8,
  UNRESPONSIVE = 6,
  HIRED = 10,
  HIRED_AND_ONBOARDED = 12,
  ASSIGNED = 301,
  THREE_MONTHS_ANIVERSARY = 302,
  SIX_MONTHS_ANIVERSARY = 303,
  TWELVE_MONTHS_ANIVERSARY = 304,
  TERMINATED_NOT_FAULT = 305,
  TERMINATED_FAULT = 306,
}
