import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { TimeOffRequest } from '../base';

export const TimeOffRequestDeclineMultipleRequestSchema = z.object({
  time_off_requests_id: z.array(z.number()),
  declined_by: z.string(),
});

export type TimeOffRequestDeclineMultipleRequest = z.infer<
  typeof TimeOffRequestDeclineMultipleRequestSchema
>;

export type TimeOffRequestDeclineMultipleResponse = TimeOffRequest[];

export type TimeOffRequestDeclineMultipleEndpoint = Endpoint<
  Method.POST,
  '/api/v2/time-off-requests/decline-multiple',
  TimeOffRequestDeclineMultipleResponse,
  undefined,
  undefined,
  TimeOffRequestDeclineMultipleRequest
>;
