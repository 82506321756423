import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  MEDIUMS_GET_ALL: createSagaAction('MEDIUMS_GET_ALL'),
  MEDIUMS_CREATE: createSagaAction('MEDIUMS_CREATE'),
  MEDIUMS_UPDATE: createSagaAction('MEDIUMS_UPDATE'),
  MEDIUMS_DELETE: createSagaAction('MEDIUMS_DELETE'),
};

// Action Creators
export const actions = {
  getAllMediums: () => ({
    type: constants.MEDIUMS_GET_ALL.REQUEST,
  }),
  createMedium: ({ medium }) => ({
    type: constants.MEDIUMS_CREATE.REQUEST,
    medium,
  }),
  updateMedium: (id, { medium }) => {
    return {
      type: constants.MEDIUMS_UPDATE.REQUEST,
      id,
      medium,
    };
  },
  deleteMedium: (id) => ({
    type: constants.MEDIUMS_DELETE.REQUEST,
    id,
  }),
};

// Reducer
export const initialState = {
  isLoading: false,
  error: null,
  mediums: [],
};

const ACTION_HANDLERS = {
  [constants.MEDIUMS_GET_ALL.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.MEDIUMS_GET_ALL.SUCCESS]: (state, { allMediums }) => {
    return { ...state, allMediums, isLoading: false };
  },
  [constants.MEDIUMS_GET_ALL.FAILURE]: (state, { error }) => {
    return { ...state, error, isLoading: false };
  },
  [constants.MEDIUMS_CREATE.FAILURE]: (state, { error }) => {
    return { ...state, error, isLoading: false };
  },
  [constants.MEDIUMS_UPDATE.FAILURE]: (state, { error }) => {
    return { ...state, error, isLoading: false };
  },
  [constants.MEDIUMS_DELETE.FAILURE]: (state, { error }) => {
    return { ...state, error, isLoading: false };
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
