import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  SOURCES_GET_ALL: createSagaAction('SOURCES_GET_ALL'),
  SOURCES_CREATE: createSagaAction('SOURCES_CREATE'),
  SOURCES_UPDATE: createSagaAction('SOURCES_UPDATE'),
  SOURCES_DELETE: createSagaAction('SOURCES_DELETE'),
};

// Action Creators
export const actions = {
  getAllSources: () => ({
    type: constants.SOURCES_GET_ALL.REQUEST,
  }),
  createSource: ({ source }) => ({
    type: constants.SOURCES_CREATE.REQUEST,
    source,
  }),
  updateSource: (id, { source }) => {
    return {
      type: constants.SOURCES_UPDATE.REQUEST,
      id,
      source,
    };
  },
  deleteSource: (id) => ({
    type: constants.SOURCES_DELETE.REQUEST,
    id,
  }),
};

// Reducer
export const initialState = {
  isLoading: false,
  error: null,
  sources: [],
};

const ACTION_HANDLERS = {
  [constants.SOURCES_GET_ALL.REQUEST]: (state) => {
    // //;
    return { ...state, isLoading: true };
  },
  [constants.SOURCES_GET_ALL.SUCCESS]: (state, { allSources }) => {
    // //;
    return { ...state, allSources, isLoading: false };
  },
  [constants.SOURCES_GET_ALL.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.SOURCES_CREATE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.SOURCES_UPDATE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.SOURCES_DELETE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
