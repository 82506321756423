import { InterviewCalendarEventSchema } from '../base';
import { z } from 'zod';

export const InterviewCalendarEventUpdateSchema = InterviewCalendarEventSchema.pick({
  interview_calendar_event_id: true,

  calendly_event_id: true,
  calendly_reschedule_url: true,
  calendly_cancel_url: true,

  google_calendar_event_id: true,
  zoom_id: true,

  status: true,
  attendee_response: true,

  dt_start: true,
  dt_end: true,
  dt_canceled: true,
  dt_conducted: true,
  act_recruiter_id: true,
  dt_last_google_update: true,
}).partial({
  calendly_event_id: true,
  calendly_reschedule_url: true,
  calendly_cancel_url: true,
  google_calendar_event_id: true,
  zoom_id: true,

  status: true,
  attendee_response: true,

  dt_start: true,
  dt_end: true,
  dt_canceled: true,
  dt_conducted: true,
  act_recruiter_id: true,
  dt_last_google_update: true,
});
export type InterviewCalendarEventUpdate = z.infer<typeof InterviewCalendarEventUpdateSchema>;
