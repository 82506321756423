import _ from 'lodash';
import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const PayoonerPaymentInfoSchema = z.object({
  payoneer_id: z.string().nullable(),
});
export type PayoonerPaymentInfo = z.infer<typeof PayoonerPaymentInfoSchema>;

export const HuskyPaymentInfoSchema = z.object({
  husky_name: z.string(),
  husky_email: z.string(),
  husky_cpf_or_cnpj: z.string(),
});
export type HuskyPaymentInfo = z.infer<typeof HuskyPaymentInfoSchema>;

export const ACHPaymentInfoSchema = z.object({
  ach_aba: z.string(),
  ach_account: z.string(),
});
export type ACHPaymentInfo = z.infer<typeof ACHPaymentInfoSchema>;

export const WirePaymentInfoSchema = z.object({
  wire_routing_number: z.string().nullish(),
  wire_bank_account_number: z.string(),
  swift_or_bic_code: z.string().nullish(),
  beneficiary_account_name: z.string(),
  bank_country_code: z.string(),
  bank_name: z.string().nullish(),
});
export type WirePaymentInfo = z.infer<typeof WirePaymentInfoSchema>;

export const PaymentInfoSchema = z.union([
  PayoonerPaymentInfoSchema,
  HuskyPaymentInfoSchema,
  ACHPaymentInfoSchema,
  WirePaymentInfoSchema,
]);

export type PaymentInfo = z.infer<typeof PaymentInfoSchema>;

export enum POCStatus {
  'Pending' = 'Pending',
  'Approved' = 'Approved',
  'Declined' = 'Declined',
  'Revoked' = 'Revoked',
  'Active' = 'Active',
}

export const POCSchema = z.object({
  payment_option_change_id: z.string(),
  person_id: z.string(),
  payment_provider_id: z.number(),
  dt_start: dateString,
  person_tax_id: z.string(),
  company_tax_id: z.string(),
  comment: z.string(),
  status: z.nativeEnum(POCStatus).default(POCStatus.Pending),
  approved_by: z.string(),
  approved_at: dateString,
  declined_by: z.string(),
  declined_at: dateString,
  payment_info: PaymentInfoSchema,
});

export type POC = z.infer<typeof POCSchema>;

export function isPayoonerPaymentInfo(paymentInfo: any): paymentInfo is PayoonerPaymentInfo {
  return paymentInfo && !_.isNil(paymentInfo.payoneer_id);
}

export function isHuskyPaymentInfo(paymentInfo: any): paymentInfo is HuskyPaymentInfo {
  return (
    paymentInfo &&
    (!_.isNil(paymentInfo.husky_name) ||
      !_.isNil(paymentInfo.husky_email) ||
      !_.isNil(paymentInfo.husky_cpf_or_cnpj))
  );
}

export function isACHPaymentInfo(paymentInfo: any): paymentInfo is ACHPaymentInfo {
  return paymentInfo && (!_.isNil(paymentInfo.ach_aba) || !_.isNil(paymentInfo.ach_account));
}

export function isWirePaymentInfo(paymentInfo: any): paymentInfo is WirePaymentInfo {
  return (
    paymentInfo &&
    (!_.isNil(paymentInfo.wire_bank_account_number) ||
      !_.isNil(paymentInfo.bank_country_code) ||
      !_.isNil(paymentInfo.beneficiary_account_name))
  );
}
