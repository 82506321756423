import request from '@shared/request';

export function addSkill(body) {
  return request('/skills', {
    method: 'POST',
    body,
  });
}

export function getSkillGroups() {
  return request('/skills/groups', {
    method: 'GET',
  });
}
