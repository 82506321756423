import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';

export const PsnCountryConcentrationMonthlyItemSchema = z.object({
  ymonth: z.number(),
  country: z.string(),
  qty_psn: z.number(),
  percent_of_total: z.number(),
  total_people: z.number(),
});

export type PsnCountryConcentrationMonthlyItem = z.infer<
  typeof PsnCountryConcentrationMonthlyItemSchema
>;

export const PsnCountryConcentrationMonthlyFiltersSchema = z.object({
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
  country: z.record(z.enum([FilterOperations.CONTAIN]), z.coerce.string()).optional(),
});
export type PsnCountryConcentrationMonthlyFilters = z.infer<
  typeof PsnCountryConcentrationMonthlyFiltersSchema
>;

export const PsnCountryConcentrationMonthlyQuerySchema = z.object({
  filters: PsnCountryConcentrationMonthlyFiltersSchema.optional(),
});
export type PsnCountryConcentrationMonthlyQuery = z.infer<
  typeof PsnCountryConcentrationMonthlyQuerySchema
>;

export type PsnCountryConcentrationMonthlyResponse = PsnCountryConcentrationMonthlyItem[];

export type PsnCountryConcentrationMonthlyEndpoint = Endpoint<
  Method.GET,
  `/api/v2/people/country-concentration-monthly`,
  PsnCountryConcentrationMonthlyResponse,
  undefined,
  PsnCountryConcentrationMonthlyQuery,
  undefined
>;
