import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const CommentDeleteParamsSchema = z.object({
  comment_id: z.coerce.number(),
});
export type CommentDeleteParams = z.infer<typeof CommentDeleteParamsSchema>;

export type CommentDeleteResponse = {};

export type CommentDeleteEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/comments/${CommentDeleteParams['comment_id']}`,
  CommentDeleteResponse,
  CommentDeleteParams,
  undefined,
  undefined
>;
