import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import { constants } from '@redux/modules/reports/assignment-tracing-report';
import * as api from '@redux/api/assignment-tracing-report';

// GET ASSINGMENT MOVEMENTS
function* getActions(action) {
  try {
    const actions = yield call(api.getAssignmentTracingReport, action);

    yield put({ type: constants.GET_ASSIGNMENT_TRACING_REPORT.SUCCESS, actions });
  } catch (e) {
    yield put({
      type: constants.GET_ASSIGNMENT_TRACING_REPORT.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetActions() {
  yield takeLatest(constants.GET_ASSIGNMENT_TRACING_REPORT.REQUEST, getActions);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([fork(watchGetActions)]);
}
