import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { PersonActiveAssignmentItemSchema } from './getPsnActiveAssignments';

export const PeopleFindOneItemSchema = z.object({
  name: z.string(),
  surname: z.string(),
  firstname: z.string(),
  slackId: z.string(),
  department: z.string(),
  departmentId: z.string(),
  isActive: z.boolean(),
  cultureCoach: z.string(),
  email: z.string(),
  personId: z.string(),
  activeProjects: z.array(PersonActiveAssignmentItemSchema),
});

export type PeopleFindOneItem = z.infer<typeof PeopleFindOneItemSchema>;

export const PeopleFindOneFiltersSchema = z
  .object({
    email: z.string(),
    personId: z.string(),
  })
  .partial();
export type PeopleFindOneFilters = z.infer<typeof PeopleFindOneFiltersSchema>;

export const PeopleFindOneQuerySchema = z.object({
  filters: PeopleFindOneFiltersSchema.partial(),
});

export type PeopleFindOneQuery = z.infer<typeof PeopleFindOneQuerySchema>;

export type PeopleFindOneResponse = PeopleFindOneItem;

export type PeopleFindOneEndpoint = Endpoint<
  Method.GET,
  `/api/people/slim`,
  PeopleFindOneResponse,
  undefined,
  PeopleFindOneQuery,
  undefined
>;
