import { all, fork, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { constants } from '@redux/modules/roles';
import * as api from '@redux/api/roles';

export function* getAll(action) {
  try {
    const response = yield call(api.getAll, action);
    yield put({ type: constants.ROLES_GET_ALL.SUCCESS, roles: response.data });
  } catch (e) {
    yield put({
      type: constants.ROLES_GET_ALL.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchGetAll() {
  yield takeLatest(constants.ROLES_GET_ALL.REQUEST, getAll);
}

export function* addEmail(action) {
  try {
    yield call(api.addEmail, action);
    yield put({ type: constants.ROLES_ADD_EMAIL.SUCCESS });
  } catch (e) {
    yield put({
      type: constants.ROLES_ADD_EMAIL.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchAddEmail() {
  yield takeEvery(constants.ROLES_ADD_EMAIL.REQUEST, addEmail);
}

export function* removeEmail(action) {
  try {
    yield call(api.removeEmail, action);
    yield put({ type: constants.ROLES_REMOVE_EMAIL.SUCCESS });
  } catch (e) {
    yield put({
      type: constants.ROLES_REMOVE_EMAIL.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchRemoveEmail() {
  yield takeEvery(constants.ROLES_REMOVE_EMAIL.REQUEST, removeEmail);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([fork(watchGetAll), fork(watchAddEmail), fork(watchRemoveEmail)]);
}
