import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const LeadSetIsPlanIncludedRequestSchema = z.object({
  is_plan_included: z.boolean(),
});
export type LeadSetIsPlanIncludedRequest = z.infer<typeof LeadSetIsPlanIncludedRequestSchema>;

export const LeadSetIsPlanIncludedParamsSchema = z.object({
  project_id: z.string(),
});
export type LeadSetIsPlanIncludedParams = z.infer<typeof LeadSetIsPlanIncludedParamsSchema>;

export type LeadSetIsPlanIncludedResponse = void;

export type LeadSetIsPlanIncludedEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/sql-leads/${LeadSetIsPlanIncludedParams['project_id']}/set-plan-included`,
  LeadSetIsPlanIncludedResponse,
  LeadSetIsPlanIncludedParams,
  undefined,
  LeadSetIsPlanIncludedRequest
>;
