import { Endpoint, Method } from '../../../infrastructure';
import { CalendlyInviteeSchema, CalendlyWebhookEventTypes } from '../base';
import { z } from 'zod';

export const OnCalendlyWebhookRequestSchema = z.object({
  event: z.nativeEnum(CalendlyWebhookEventTypes),
  payload: CalendlyInviteeSchema,
});
export type OnCalendlyWebhookRequest = z.infer<typeof OnCalendlyWebhookRequestSchema>;

export type OnCalendlyWebhookResponse = {};

export type OnCalendlyWebhookEndpoint = Endpoint<
  Method.POST,
  `/api/v2/calendly-notifications`,
  OnCalendlyWebhookResponse,
  undefined,
  undefined,
  OnCalendlyWebhookRequest
>;
