import { call, put, takeLatest } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  BULK_UPDATE_APPLICATIONS_ASSIGNMENTS: createSagaAction('BULK_UPDATE_APPLICATIONS_ASSIGNMENTS'),
};

const actions = {
  bulkUpdateApplicationsAssignments: (data) => ({
    ...data,
    type: constants.BULK_UPDATE_APPLICATIONS_ASSIGNMENTS.REQUEST,
  }),
};

const handlers = {
  [constants.BULK_UPDATE_APPLICATIONS_ASSIGNMENTS.REQUEST]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [constants.BULK_UPDATE_APPLICATIONS_ASSIGNMENTS.SUCCESS]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [constants.BULK_UPDATE_APPLICATIONS_ASSIGNMENTS.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

function* BulkUpdateApplicationsAssignments(action) {
  try {
    const { assignments, removedAssignments } = action;

    if (removedAssignments && removedAssignments.length) {
      yield call(apiBulkDeleteAssignments, action);
    }

    if (assignments && assignments.length) {
      yield call(apiBulkAddAssignments, action);
    }

    yield put({
      type: constants.BULK_UPDATE_APPLICATIONS_ASSIGNMENTS.SUCCESS,
    });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.BULK_UPDATE_APPLICATIONS_ASSIGNMENTS.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onError)) {
      yield action.onError(e.message || e);
    }
  }
}

export function* watchBulkUpdateApplicationsAssignments() {
  yield takeLatest(
    constants.BULK_UPDATE_APPLICATIONS_ASSIGNMENTS.REQUEST,
    BulkUpdateApplicationsAssignments,
  );
}

// API

export function apiBulkDeleteAssignments({ applicationsIds, removedAssignments }) {
  return request('/assignments', {
    method: 'DELETE',
    body: {
      applications: applicationsIds && applicationsIds.length ? applicationsIds : [],
      assignment: removedAssignments,
    },
  });
}

export function apiBulkAddAssignments({ applicationsIds, assignments }) {
  return request('/assignments', {
    method: 'POST',
    body: {
      applications: applicationsIds && applicationsIds.length ? applicationsIds : [],
      assignment: assignments,
    },
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchBulkUpdateApplicationsAssignments,

  // optionally we may
  // api: {
  //   getAccount: apiGetAccount
  // }
};
