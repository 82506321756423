import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';

export const PlanForecastItemSchema = z.object({
  client_id: z.string(),
  client_name: z.string(),
  project_id: z.string().nullable(),
  project_name: z.string(),
  yyear: z.number(),
  is_forecast: z.boolean(),
  is_pre_opp: z.boolean(),
  is_lead: z.boolean(),
  is_new_client: z.boolean(),
  forecast_id: z.number().nullable(),
  is_frozen: z.boolean(),
  jan: z.number(),
  fab: z.number(),
  mar: z.number(),
  apr: z.number(),
  may: z.number(),
  jun: z.number(),
  jul: z.number(),
  aug: z.number(),
  sep: z.number(),
  oct: z.number(),
  nov: z.number(),
  dec: z.number(),
  total_year: z.number(),
});
export type PlanForecastItem = z.infer<typeof PlanForecastItemSchema>;

export const PlanForecastFiltersSchema = z.object({
  yyear: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
  version_id: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
  csvFilter: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
});
export type PlanForecastFilters = z.infer<typeof PlanForecastFiltersSchema>;

export const PlanForecastQuerySchema = z.object({
  filters: PlanForecastFiltersSchema.partial(),
});

export type PlanForecastQuery = z.infer<typeof PlanForecastQuerySchema>;

export type PlanForecastResponse = PlanForecastItem[];

export type PlanForecastEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/plan-forecast',
  PlanForecastResponse,
  undefined,
  PlanForecastQuery,
  undefined
>;
