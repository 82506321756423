import * as api from '@redux/api/application';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';

// GET FOUND LIST

function* getFoundList(action) {
  try {
    const foundList = yield call(api.getFoundList, action);
    yield put({ type: constants.APPLICATION_GET_FOUND.SUCCESS, foundList });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_GET_FOUND.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchGetFoundList() {
  yield takeLatest(constants.APPLICATION_GET_FOUND.REQUEST, getFoundList);
}
