import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export type GetTotalFutureReferralPayoutsEndpoint = Endpoint<
  Method.GET,
  `/api/v2/payouts/total-future-referral/:${GetTotalFutureReferralPayoutsParams['person_id']}`,
  GetTotalFutureReferralPayoutsResponse,
  GetTotalFutureReferralPayoutsParams,
  undefined,
  undefined
>;

export const GetTotalFutureReferralPayoutsParamsSchema = z.object({
  person_id: z.string(),
});
export type GetTotalFutureReferralPayoutsParams = z.infer<
  typeof GetTotalFutureReferralPayoutsParamsSchema
>;

export const GetTotalFutureReferralPayoutsResponseSchema = z.object({
  total: z.number(),
});
export type GetTotalFutureReferralPayoutsResponse = z.infer<
  typeof GetTotalFutureReferralPayoutsResponseSchema
>;
