import { z } from 'zod';
import { dateString } from '../../../infrastructure';

export const SFOpportunitySchema = z.object({
  brains_client_id: z.string(),
  opportunity_name: z.string(),
  sf_opportunity_id: z.string(),
  brains_owner_id: z.string(),
  end_date: dateString.nullable(),
  start_date: dateString.nullable(),
  revenue: z.number().nullable(),
  stage: z.string(),
  description: z.string().nullish(),
});
export type SFOpportunity = z.infer<typeof SFOpportunitySchema>;
