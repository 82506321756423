import { call, put, takeEvery } from 'redux-saga/effects';

import { apiGetJobPriorities } from './get';
import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

const constants = {
  JOB_PRIORITIES_DELETE: createSagaAction('JOB_PRIORITIES_DELETE'),
};

const actions = {
  delete: (args) => ({
    type: constants.JOB_PRIORITIES_DELETE.REQUEST,
    ...args,
  }),
};

const handlers = {
  [constants.JOB_PRIORITIES_DELETE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.JOB_PRIORITIES_DELETE.SUCCESS]: (state, { jobPriorities }) => {
    return { ...state, jobPriorities };
  },
};

function* deleteJobPriority(action) {
  try {
    yield call(apiDeleteJobPriority, action);
    yield put({ type: constants.JOB_PRIORITIES_DELETE.SUCCESS });
    const response = yield call(apiGetJobPriorities, action);
    yield put({
      type: constants.JOB_PRIORITIES_GET.SUCCESS,
      jobTypes: response.data,
    });
  } catch (e) {
    yield put({
      type: constants.JOB_PRIORITIES_DELETE.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchDeleteJobPriority() {
  yield takeEvery(constants.JOB_PRIORITIES_DELETE.REQUEST, deleteJobPriority);
}

function apiDeleteJobPriority({ _id }) {
  return request(`/job-priorities/${_id}`, {
    method: 'DELETE',
  });
}

export default {
  actions,
  constants,
  handlers,
  watcher: watchDeleteJobPriority,
};
