import { call, put, takeLatest } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  BULK_SCHEDULE_INTERVIEW: createSagaAction('BULK_SCHEDULE_INTERVIEW'),
};

const actions = {
  bulkScheduleInterview: (data) => ({
    ...data,
    type: constants.BULK_SCHEDULE_INTERVIEW.REQUEST,
  }),
};

const handlers = {
  [constants.BULK_SCHEDULE_INTERVIEW.REQUEST]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [constants.BULK_SCHEDULE_INTERVIEW.SUCCESS]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [constants.BULK_SCHEDULE_INTERVIEW.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

function* BulkScheduleInterview(action) {
  try {
    yield call(apiBulkScheduleInterview, action);
    yield put({
      type: constants.BULK_SCHEDULE_INTERVIEW.SUCCESS,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.BULK_SCHEDULE_INTERVIEW.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onError)) {
      yield action.onError();
    }
  }
}

export function* watchScheduleInterview() {
  yield takeLatest(constants.BULK_SCHEDULE_INTERVIEW.REQUEST, BulkScheduleInterview);
}

// API

export function apiBulkScheduleInterview({ applicationsIds, interviewerUsername }) {
  const promises = applicationsIds.map((applicationId) => {
    return request(`/applications/${applicationId}/calendly`, {
      method: 'PUT',
      body: {
        username: interviewerUsername,
      },
    });
  });

  return Promise.all(promises);
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchScheduleInterview,

  // optionally we may
  // api: {
  //   getAccount: apiGetAccount
  // }
};
