import { z } from 'zod';
import { PaymentProviderMonth, PaymentProviderMonthSchema } from './base';
import { Endpoint, Method } from '../../infrastructure';

export const PaymentProviderMonthUpdateSchema = PaymentProviderMonthSchema.pick({
  ymonth: true,
  payment_provider_id: true,
  cut_off_day: true,
  last_business_day: true,
}).partial({
  cut_off_day: true,
  last_business_day: true,
});
export type PaymentProviderMonthUpdate = z.infer<typeof PaymentProviderMonthUpdateSchema>;

export const PPMUpdateParamsSchema = z.object({
  ymonth: z.coerce.number(),
  payment_provider_id: z.coerce.number(),
});

export type PPMUpdateParams = z.infer<typeof PPMUpdateParamsSchema>;

export const PPMUpdateResponseSchema = z.object({
  message: z.string(),
});

export type PPMUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/payment-provider-months/${PPMUpdateParams['payment_provider_id']}/${PPMUpdateParams['ymonth']}`,
  PaymentProviderMonth,
  PPMUpdateParams,
  undefined,
  PaymentProviderMonthUpdate
>;
