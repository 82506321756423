import { Endpoint, Method } from '../../../infrastructure';
import { PsnRctrGoogleCalendarChannel } from '../base';
import { PsnRctrGoogleCalendarChannelCreateSchema } from '../crud';
import { z } from 'zod';

export const PsnRctrGoogleCalendarChannelCreateRequestSchema =
  PsnRctrGoogleCalendarChannelCreateSchema.pick({
    person_id: true,
    address: true,
    calendar_id: true,
  });
export type PsnRctrGoogleCalendarChannelCreateRequest = z.infer<
  typeof PsnRctrGoogleCalendarChannelCreateRequestSchema
>;

export type PsnRctrGoogleCalendarChannelCreateResponse = PsnRctrGoogleCalendarChannel;

export type PsnRctrGoogleCalendarChannelCreateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/psn-rctr-google-calendar-channel`,
  PsnRctrGoogleCalendarChannelCreateResponse,
  undefined,
  undefined,
  PsnRctrGoogleCalendarChannelCreateRequest
>;
