import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import '@src/monitoring';
import Routes from '@src/routes';
import { store } from '@redux/store';
import config from '@src/config';

import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';
import MicrosoftAuthenticationProvider from './shared/MicrosoftAuthenticationProvider';

const tagManagerArgs = {
  gtmId: config.GOOGLE_TAG_MANAGER,
};

TagManager.initialize(tagManagerArgs);
ReactGA.initialize(config.GOOGLE_ANALYTICS);

ReactDOM.render(
  <Provider store={store}>
    <MicrosoftAuthenticationProvider>
      <React.StrictMode>
        <Routes />
      </React.StrictMode>
    </MicrosoftAuthenticationProvider>
  </Provider>,
  document.getElementById('root'),
);

// Removing CRA service worker for users who have it installed
// so we can add ours
serviceWorker.unregister();
