import { z } from 'zod';
import { dateString, Endpoint, Method } from '../../infrastructure';

export const AddPositionExtensionRequestSchema = z.object({
  position_id: z.string(),
  dt_opp_extension: dateString,
});
export type AddPositionExtensionRequest = z.infer<typeof AddPositionExtensionRequestSchema>;

export type AddPositionExtensionEndpoint = Endpoint<
  Method.POST,
  '/api/v2/positions/opp-extension',
  void,
  undefined,
  undefined,
  AddPositionExtensionRequest
>;
