import { z } from 'zod';
import {
  Endpoint,
  Method,
  FilterOperations,
  ReportsQuerySchema,
  makeSorter,
  ReportResponse,
} from '../../infrastructure';
import { PayoutWithPerson } from '../payouts/base';
import { PersonSchema } from './base';
import { dateString } from '../../infrastructure/schema';

export type ListPersonPayoutsEndpoint = Endpoint<
  Method.GET,
  `/api/v2/people/:${ListPersonPayoutsParams['person_id']}/payouts`,
  ListPersonPayoutsResponse,
  ListPersonPayoutsParams,
  ListPersonPayoutsQuery,
  undefined
>;

export const ListPersonPayoutsParamsSchema = PersonSchema.pick({
  person_id: true,
});
export type ListPersonPayoutsParams = z.infer<typeof ListPersonPayoutsParamsSchema>;

export const ListPersonPayoutsFiltersSchema = z
  .object({
    payout_type_code: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN, FilterOperations.NOT_IN]),
      z.string().or(z.array(z.string())),
    ),
    acct_cost_code: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    target_pay_date: z.record(
      z.enum([FilterOperations.GREATER_THAN_OR_EQUAL, FilterOperations.LESS_THAN_OR_EQUAL]),
      dateString,
    ),
  })
  .partial();
export type ListPersonPayoutsFilters = z.infer<typeof ListPersonPayoutsFiltersSchema>;

export const ListPersonPayoutsSorterSchema = makeSorter(
  z.enum([
    'firstname',
    'surname',
    'amount',
    'target_pay_date',
    'acct_cost_code',
    'payout_type_code',
  ]),
);
export type ListPersonPayoutsSorter = z.infer<typeof ListPersonPayoutsSorterSchema>;

export const ListPersonPayoutsQuerySchema = ReportsQuerySchema.extend({
  sorter: ListPersonPayoutsSorterSchema.optional(),
  filters: ListPersonPayoutsFiltersSchema.optional(),
});
export type ListPersonPayoutsQuery = z.infer<typeof ListPersonPayoutsQuerySchema>;

export type ListPersonPayoutsResponse = ReportResponse<PayoutWithPerson>;
