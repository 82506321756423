import * as api from '@redux/api/application';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';
import isFunction from 'lodash/isFunction';

function* sendOnBoardingLetter(action) {
  try {
    const response = yield call(api.sendOnBoardingLetter, action);
    yield put({
      type: constants.APPLICATION_SEND_ONBOARDING_LETTER.SUCCESS,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(response.message);
    }
  } catch (e) {
    yield put({
      type: constants.APPLICATION_SEND_ONBOARDING_LETTER.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onError)) {
      console.error(e);
      yield action.onError(e.message || e);
    }
  }
}

export function* watchSendOnBoardingLetter() {
  yield takeLatest(constants.APPLICATION_SEND_ONBOARDING_LETTER.REQUEST, sendOnBoardingLetter);
}
