import { z } from 'zod';
import { PrjGoogleCalendarCreateSchema, PrjGoogleCalendar } from '..';
import { Endpoint, Method } from '../../../infrastructure';

export const PrjGoogleCalendarCreateRequestSchema = PrjGoogleCalendarCreateSchema.pick({
  project_id: true,
  title: true,
});

export type PrjGoogleCalendarCreateRequest = z.infer<typeof PrjGoogleCalendarCreateRequestSchema>;

export type PrjGoogleCalendarCreateResponse = PrjGoogleCalendar;

export type PrjGoogleCalendarCreateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/prj-google-calendars/`,
  PrjGoogleCalendarCreateResponse,
  undefined,
  undefined,
  PrjGoogleCalendarCreateRequest
>;
