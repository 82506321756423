import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  APPLICATION_INITIAL_PROJECT_UPDATE: createSagaAction('APPLICATION_INITIAL_PROJECT_UPDATE'),
};

const actions = {
  updateInitialProject: (applicationId, initialProject) => ({
    type: constants.APPLICATION_INITIAL_PROJECT_UPDATE.REQUEST,
    applicationId,
    initialProject,
  }),
};

const handlers = {
  [constants.APPLICATION_INITIAL_PROJECT_UPDATE.REQUEST]: (state, action) => {
    return {
      ...state,
      isInitialProjectUpdating: true,
      updateInitialProjectError: null,
    };
  },
  [constants.APPLICATION_INITIAL_PROJECT_UPDATE.SUCCESS]: (state, { data }) => {
    return {
      ...state,
      isInitialProjectUpdating: false,
      updateInitialProjectError: null,
    };
  },
  [constants.APPLICATION_INITIAL_PROJECT_UPDATE.FAILURE]: (state, { error }) => {
    return {
      ...state,
      isInitialProjectUpdating: false,
      updateInitialProjectError: error,
    };
  },
};

// SAGA

export function* sagaUpdateInitialProject({ applicationId, initialProject }) {
  try {
    yield call(apiUpdateInitialProject, applicationId, initialProject);
    yield put({
      type: constants.APPLICATION_INITIAL_PROJECT_UPDATE.SUCCESS,
      data: { applicationId, initialProject },
    });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_INITIAL_PROJECT_UPDATE.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchUpdateInitialProject() {
  yield takeLatest(constants.APPLICATION_INITIAL_PROJECT_UPDATE.REQUEST, sagaUpdateInitialProject);
}

// API

export function apiUpdateInitialProject(applicationId, initialProject) {
  return request(`/applications/${applicationId}/initial-project`, {
    method: 'PATCH',
    body: { initialProject },
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchUpdateInitialProject,
};
