import { call, put, takeLatest } from 'redux-saga/effects';

import qs from 'qs';

import { createSagaAction } from '@shared/sagas';
import request from '@shared/request';

// MODULE
const constants = {
  UNRESPONSIVE_REPORT_GET: createSagaAction('UNRESPONSIVE_REPORT_GET'),
};

const actions = {
  get: (data) => ({
    data,
    type: constants.UNRESPONSIVE_REPORT_GET.REQUEST,
  }),
};

const handlers = {
  [constants.UNRESPONSIVE_REPORT_GET.REQUEST]: (state) => {
    return { ...state, isLoading: true, data: [] };
  },
  [constants.UNRESPONSIVE_REPORT_GET.SUCCESS]: (state, { data, pagination }) => {
    return { ...state, isLoading: false, data, pagination };
  },
  [constants.UNRESPONSIVE_REPORT_GET.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA
function* sagaGetReport(action) {
  try {
    const data = yield call(apiGetReport, action);
    yield put({ type: constants.UNRESPONSIVE_REPORT_GET.SUCCESS, ...data });
  } catch (e) {
    yield put({
      type: constants.UNRESPONSIVE_REPORT_GET.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetReport() {
  yield takeLatest(constants.UNRESPONSIVE_REPORT_GET.REQUEST, sagaGetReport);
}

// API
function apiGetReport({ data }) {
  const query = qs.stringify(data, { encode: false, arrayFormat: 'comma' });

  return request(`/reports/unresponsive-applications?${query}`, {
    method: 'GET',
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchGetReport,
};
