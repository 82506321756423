import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { PayoutSchema, PayoutWithPersonSchema } from './base';

export type UpdatePayoutEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/payouts/:${UpdatePayoutParams['payout_id']}`,
  UpdatePayoutResponse,
  UpdatePayoutParams,
  undefined,
  UpdatePayoutRequest
>;

export const UpdatePayoutParamsSchema = PayoutSchema.pick({
  payout_id: true,
});
export type UpdatePayoutParams = z.infer<typeof UpdatePayoutParamsSchema>;

export const UpdatePayoutRequestSchema = PayoutSchema.pick({
  payout_type_code: true,
  acct_cost_code: true,
  amount: true,
  description: true,
  target_pay_date: true,
  paid_date: true,
  is_paid_immediately: true,
  meta: true,
}).partial();

export type UpdatePayoutRequest = z.infer<typeof UpdatePayoutRequestSchema>;

export const UpdatePayoutResponseSchema = PayoutWithPersonSchema;
export type UpdatePayoutResponse = z.infer<typeof UpdatePayoutResponseSchema>;
