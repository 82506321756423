import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const UploadPayoutsRequestSchema = z.object({
  payoutType: z.string(),
  createdBy: z.string(),
  fileUpstream: z.any().optional(),
});
export type UploadPayoutsRequest = z.infer<typeof UploadPayoutsRequestSchema>;

export type UploadPayoutsEndpoint = Endpoint<
  Method.POST,
  '/api/v2/payouts/upload',
  {},
  undefined,
  undefined,
  UploadPayoutsRequest
>;

export const UploadPayoutsRowErrorSchema = z.object({
  personIdOrEmail: z.string(),
  personName: z.string(),
  amount: z.number(),
  description: z.string().optional(),
  errorMessage: z.string(),
});
export type UploadPayoutsRowError = z.infer<typeof UploadPayoutsRowErrorSchema>;

export type PayoutUploadRow = {
  personIdOrEmail: string;
  personName: string;
  amount: number;
  description: string;
  payoutType?: string;
  paymentDate?: string;
  foundPersonId: string;
};

export const UploadPayoutsResponseSchema = z.object({
  total: z.number(),
  processed: z.number(),
  failures: z.number(),
  failedRecords: z.array(UploadPayoutsRowErrorSchema),
});
export type UploadPayoutsResponse = z.infer<typeof UploadPayoutsResponseSchema>;
