import reject from 'lodash/reject';

import { init as initApm } from '@elastic/apm-rum';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import config from './config';
import { isMongoObjectId } from '@shared/Util';

const tools = {};

if (config.SENTRY_URL) {
  Sentry.init({
    dsn: config.SENTRY_URL,
    environment: config.ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

if (typeof window !== 'undefined' && config.APM_RUM_URL && config.APM_RUM_NAME) {
  const parts = window.location.pathname.split('/');
  let pageName = window.location.pathname;
  if (parts.length > 0) {
    pageName = reject(parts, isMongoObjectId).join('/');
  }

  // eslint-disable-next-line no-console
  console.log(`Init APM RUM for a page: ${pageName}`);

  tools.apm = initApm({
    serviceName: config.APM_RUM_NAME,
    serverUrl: config.APM_RUM_URL,

    // Set service version (required for sourcemap feature)
    serviceVersion: '0.9.0',
    // logLevel: 'debug',
    // browserResponsivenessInterval: 2000,
    checkBrowserResponsiveness: false,
    transactionDurationThreshold: 5 * 60000,
    pageLoadTransactionName: pageName,
  });
}

export default tools;
