import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import { constants } from '@redux/modules/skills';
import { constants as applicationConstants } from '@redux/modules/application/application';

import * as skillsApi from '@redux/api/skills';

function* createSkill(action) {
  try {
    const { body } = action;
    const payload = yield call(skillsApi.addSkill, body);
    yield put({ type: constants.SETTINGS_SKILL_CREATE.SUCCESS, payload });
    yield put({ type: applicationConstants.APPLICATION_SKILLS_GET.REQUEST });
  } catch (e) {
    yield put({
      type: constants.SETTINGS_SKILL_CREATE.FAILURE,
      message: e.message || e,
    });
  }
}

function* watchCreateSkill() {
  yield takeLatest(constants.SETTINGS_SKILL_CREATE.REQUEST, createSkill);
}

export function* getSkillGroups() {
  try {
    const skillGroups = yield call(skillsApi.getSkillGroups);
    yield put({ type: constants.SETTINGS_SKILL_GROUPS_GET.SUCCESS, skillGroups });
  } catch (e) {
    yield put({
      type: constants.SETTINGS_SKILL_GROUPS_GET.FAILURE,
      message: e.message || e,
    });
  }
}

export function* watchGetSkillGroups() {
  yield takeLatest(constants.SETTINGS_SKILL_GROUPS_GET.REQUEST, getSkillGroups);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([fork(watchCreateSkill), fork(watchGetSkillGroups)]);
}
