import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const UnarchiveProjectParamsSchema = z.object({
  project_id: z.coerce.string(),
});
export type UnarchiveProjectParams = z.infer<typeof UnarchiveProjectParamsSchema>;

export type UnarchiveProjectEndpoint = Endpoint<
  Method.PUT,
  `/projects/${UnarchiveProjectParams['project_id']}/unarchive`,
  void,
  UnarchiveProjectParams,
  undefined,
  undefined
>;
