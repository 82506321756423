import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const ApplicationSetIsGemRequestSchema = z.object({
  is_gem: z.boolean(),
});
export type ApplicationSetIsGemRequest = z.infer<typeof ApplicationSetIsGemRequestSchema>;

export const ApplicationSetIsGemParamsSchema = z.object({
  application_id: z.string(),
});
export type ApplicationSetIsGemParams = z.infer<typeof ApplicationSetIsGemParamsSchema>;

export type ApplicationSetIsGemResponse = any;

export type ApplicationSetIsGemEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/applications/${ApplicationSetIsGemParams['application_id']}/set-is-gem`,
  ApplicationSetIsGemResponse,
  ApplicationSetIsGemParams,
  undefined,
  ApplicationSetIsGemRequest
>;
