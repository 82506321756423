import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';
import isFunction from 'lodash/isFunction';

// MODULE
const constants = {
  CREATE_UTM_PARAMS: createSagaAction('CREATE_UTM_PARAMS'),
};

const actions = {
  create: (data) => ({
    ...data,
    type: constants.CREATE_UTM_PARAMS.REQUEST,
  }),
};

const handlers = {
  [constants.CREATE_UTM_PARAMS.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.CREATE_UTM_PARAMS.SUCCESS]: (state, { utmParam }) => {
    return { ...state, isLoading: false, data: [utmParam, ...state.data] };
  },
  [constants.CREATE_UTM_PARAMS.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA
function* sagaCreateUtmParams(action) {
  try {
    const { utmParam } = yield call(apiCreateUtmParams, action);
    yield put({ type: constants.CREATE_UTM_PARAMS.SUCCESS, utmParam });
  } catch (e) {
    yield put({
      type: constants.CREATE_UTM_PARAMS.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onError)) {
      yield action.onError(e.message || e);
    }
  }
}

function* watchCreateUtmParams() {
  yield takeLatest(constants.CREATE_UTM_PARAMS.REQUEST, sagaCreateUtmParams);
}

// API
function apiCreateUtmParams(utm_param) {
  return request('/utm-param', {
    method: 'POST',
    body: {
      ...utm_param,
    },
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchCreateUtmParams,
};
