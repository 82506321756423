import { all, fork, call, put, takeLatest, takeEvery } from 'redux-saga/effects';

import * as api from '@redux/api/user';
import { constants } from '@redux/modules/users';

import { message, Modal } from 'antd';

import isFunction from 'lodash/isFunction';

//GET USER BY ID
function* getUser(action) {
  try {
    const response = yield call(api.getUser, action);

    yield put({ type: constants.USER_GET.SUCCESS, user: response.data });
  } catch (e) {
    yield put({
      type: constants.USER_GET.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetUser() {
  yield takeLatest(constants.USER_GET.REQUEST, getUser);
}

//SEARCH USERS BY EMAIL
function* searchUsersByEmail(action) {
  try {
    const response = yield call(api.searchUsersByEmail, action);

    yield put({ type: constants.SEARCH_USER_BY_EMAIL.SUCCESS, searchResult: response });
  } catch (e) {
    yield put({
      type: constants.SEARCH_USER_BY_EMAIL.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchSearchUsersByEmail() {
  yield takeLatest(constants.SEARCH_USER_BY_EMAIL.REQUEST, searchUsersByEmail);
}

//SEARCH USERS BY NAME
function* searchUsersByName(action) {
  try {
    const response = yield call(api.searchUsersByName, action);

    yield put({ type: constants.SEARCH_USER_BY_NAME.SUCCESS, searchResult: response });
  } catch (e) {
    yield put({
      type: constants.SEARCH_USER_BY_NAME.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchSearchUsersByName() {
  yield takeLatest(constants.SEARCH_USER_BY_NAME.REQUEST, searchUsersByName);
}

// EDIT APPLICATION_VIDEO_ANSWER
function* editUser(action) {
  try {
    const response = yield call(api.editUser, action);
    yield put({ type: constants.USER_PUT.SUCCESS, response });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(action.user);
    }
  } catch (e) {
    const error = e.message || e;

    yield put({
      type: constants.USER_PUT.FAILURE,
      error,
    });

    if (isFunction(action.onFail)) {
      yield action.onFail(error);
    }
  }
}

function* watchEditName() {
  yield takeEvery(constants.USER_PUT_NAME.REQUEST, editUserName);
}

// EDIT USER NAME
function* editUserName(action) {
  try {
    const response = yield call(api.editUserName, action);
    yield put({ type: constants.USER_PUT_NAME.SUCCESS, response });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(action.user);
    }
  } catch (e) {
    const error = e.message || e;

    yield put({
      type: constants.USER_PUT_NAME.FAILURE,
      error,
    });

    if (isFunction(action.onFail)) {
      yield action.onFail(error);
    }
  }
}

function* watchEdit() {
  yield takeEvery(constants.USER_PUT.REQUEST, editUser);
}

// CREATE GOOGLE ACCOUNT
function* createGoogleAccount(action) {
  try {
    const response = yield call(api.createGoogleAccount, action);
    yield put({ type: constants.CREATE_GOOGLE_ACCOUNT.SUCCESS });
    Modal.info({
      content: response.message,
    });
  } catch (e) {
    yield put({
      type: constants.CREATE_GOOGLE_ACCOUNT.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchCreateGoogleAccount() {
  yield takeLatest(constants.CREATE_GOOGLE_ACCOUNT.REQUEST, createGoogleAccount);
}

// SEND WELCOME LETTER
function* sendWelcomeLetter(action) {
  try {
    const response = yield call(api.sendWelcomeLetter, action);
    yield put({ type: constants.SEND_WELCOME_LETTER.SUCCESS });
    message.success(response.message);
  } catch (e) {
    yield put({
      type: constants.SEND_WELCOME_LETTER.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchSendWelcomeLetter() {
  yield takeLatest(constants.SEND_WELCOME_LETTER.REQUEST, sendWelcomeLetter);
}

// SEND FIRST DAY INSTRUCTIONS
function* sendFirstDayInstructions(action) {
  try {
    const response = yield call(api.sendFirstDayInstructions, action);
    yield put({ type: constants.SEND_FIRST_DAY_INSTRUCTIONS.SUCCESS });
    message.success(response.message);
  } catch (e) {
    yield put({
      type: constants.SEND_FIRST_DAY_INSTRUCTIONS.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchSendFirstDayInstructions() {
  yield takeLatest(constants.SEND_FIRST_DAY_INSTRUCTIONS.REQUEST, sendFirstDayInstructions);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetUser),
    fork(watchSearchUsersByEmail),
    fork(watchSearchUsersByName),
    fork(watchEdit),
    fork(watchEditName),
    fork(watchCreateGoogleAccount),
    fork(watchSendWelcomeLetter),
    fork(watchSendFirstDayInstructions),
  ]);
}
