import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const PositionStaffingSetOfferedParamsSchema = z.object({
  positionStaffingId: z.coerce.number(),
});
export type PositionStaffingSetOfferedParams = z.infer<
  typeof PositionStaffingSetOfferedParamsSchema
>;
export type PositionStaffingSetOferredEndpoint = Endpoint<
  Method.PUT,
  `/api/positions-staffing/${PositionStaffingSetOfferedParams['positionStaffingId']}/offered`,
  {},
  PositionStaffingSetOfferedParams,
  undefined,
  undefined
>;
