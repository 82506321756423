import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '@redux/api/application';
import { constants } from '@redux/modules/application/application';
import isFunction from 'lodash/isFunction';

function* getScorecardList(action) {
  try {
    const scorecardList = yield call(api.getScorecardList, action);
    yield put({ type: constants.APPLICATION_SCORECARD_LIST_GET.SUCCESS, scorecardList });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.APPLICATION_SCORECARD_LIST_GET.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFailure)) {
      yield action.onSuccess();
    }
  }
}

export function* watchGetScorecardList() {
  yield takeLatest(constants.APPLICATION_SCORECARD_LIST_GET.REQUEST, getScorecardList);
}

function* createScorecard(action) {
  try {
    const currentScorecard = yield call(api.createScorecard, action);
    yield put({ type: constants.APPLICATION_SCORECARD_CREATE.SUCCESS, currentScorecard });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
    const scorecardList = yield call(api.getScorecardList, action);
    yield put({ type: constants.APPLICATION_SCORECARD_LIST_GET.SUCCESS, scorecardList });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_SCORECARD_CREATE.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFailure)) {
      yield action.onFailure();
    }
  }
}

export function* watchCreateScorecard() {
  yield takeLatest(constants.APPLICATION_SCORECARD_CREATE.REQUEST, createScorecard);
}

function* updateScorecard(action) {
  try {
    const currentScorecard = yield call(api.updateScorecard, action);
    yield put({ type: constants.APPLICATION_SCORECARD_UPDATE.SUCCESS, currentScorecard });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.APPLICATION_SCORECARD_UPDATE.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFailure)) {
      yield action.onSuccess();
    }
  }
}

export function* watchUpdateScorecard() {
  yield takeLatest(constants.APPLICATION_SCORECARD_UPDATE.REQUEST, updateScorecard);
}

function* deleteScorecard(action) {
  try {
    yield call(api.deleteScorecard, action);
    yield put({ type: constants.APPLICATION_SCORECARD_DELETE.SUCCESS });
    const scorecardList = yield call(api.getScorecardList, action);
    yield put({ type: constants.APPLICATION_SCORECARD_LIST_GET.SUCCESS, scorecardList });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_SCORECARD_DELETE.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchDeleteScorecard() {
  yield takeLatest(constants.APPLICATION_SCORECARD_DELETE.REQUEST, deleteScorecard);
}

function* clearCurrentScorecard(action) {
  try {
    yield put({
      type: constants.APPLICATION_SCORECARD_CLEAR_CURRENT.SUCCESS,
      currentScorecard: {},
    });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_SCORECARD_CLEAR_CURRENT.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchClearCurrentScorecard() {
  yield takeLatest(constants.APPLICATION_SCORECARD_DELETE.REQUEST, clearCurrentScorecard);
}

function* getScorecardDetails(action) {
  try {
    const currentScorecard = yield call(api.getScorecardDetails, action);
    yield put({ type: constants.APPLICATION_SCORECARD_DETAILS_GET.SUCCESS, currentScorecard });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.APPLICATION_SCORECARD_DETAILS_GET.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFailure)) {
      yield action.onSuccess();
    }
  }
}

export function* watchGetScorecardDetails() {
  yield takeLatest(constants.APPLICATION_SCORECARD_DETAILS_GET.REQUEST, getScorecardDetails);
}
