import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const PositionStaffingMoveNotRelevantCandidaciesRequestSchema = z.object({
  position_staffing_id: z.number(),
  user: z
    .object({
      name: z.string().optional(),
      email: z.string().optional(),
    })
    .optional(),
});

export type PositionStaffingMoveNotRelevantCandidaciesRequest = z.infer<
  typeof PositionStaffingMoveNotRelevantCandidaciesRequestSchema
>;

export type PositionStaffingMoveNotRelevantCandidaciesResponse = {};

export const PositionStaffingMoveNotRelevantCandidaciesParamsSchema = z.object({
  position_staffing_id: z.coerce.number(),
  opportunity_id: z.string().optional(),
});
export type PositionStaffingMoveNotRelevantCandidaciesParams = z.infer<
  typeof PositionStaffingMoveNotRelevantCandidaciesParamsSchema
>;

export type PositionStaffingMoveNotRelevantCandidaciesEndpoint = Endpoint<
  Method.POST,
  `/api/v3/staffing-candidate/:${PositionStaffingMoveNotRelevantCandidaciesParams['position_staffing_id']}/move-not-relevant-candidacies`,
  PositionStaffingMoveNotRelevantCandidaciesResponse,
  PositionStaffingMoveNotRelevantCandidaciesParams,
  undefined,
  PositionStaffingMoveNotRelevantCandidaciesRequest
>;
