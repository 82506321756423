import request from '../../shared/request';

export function getCalendlyUserTypes() {
  return request('/calendly-user-types', {
    method: 'GET',
  });
}

export function createCalendlyUserType({ name, status }) {
  return request('/calendly-user-types', {
    method: 'POST',
    body: {
      name,
      status,
    },
  });
}

export function updateCalendlyUserType({ _id, data }) {
  return request(`/calendly-user-types/${_id}`, {
    method: 'PUT',
    body: data,
  });
}

export function deleteCalendlyUserType({ _id }) {
  return request(`/calendly-user-types/${_id}`, {
    method: 'DELETE',
  });
}
