import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

//Revenue data
export const YearlyRevenueEstimateSchema = z.object({
  client: z.string(),
  clientRank: z.number(),
  clientRevenue: z.array(
    z.object({
      ymonth: z.number(),
      revenue: z.number(),
    }),
  ),
  preLeadsComments: z.array(z.string()),
  hasPreLead: z.boolean(),
  isPreLeadClient: z.boolean(),
});
export type YearlyRevenueEstimate = z.infer<typeof YearlyRevenueEstimateSchema>;

//Query
export const YearlyRevenueEstimateQuerySchema = z.object({
  filters: z.object({
    innitialYmonth: z.string(),
  }),
});
export type YearlyRevenueEstimateQuery = z.infer<typeof YearlyRevenueEstimateQuerySchema>;

//Define the response
export type YearlyRevenueEstimateResponse = {
  YearlyRevenue: YearlyRevenueEstimate[];
};

//Define the endpoint
export type YearlyRevenueEstimateEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-report/yearly-revenue-estimate',
  YearlyRevenueEstimateResponse,
  undefined,
  YearlyRevenueEstimateQuery,
  undefined
>;
