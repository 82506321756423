import { z } from 'zod';
import { PsnDeepSkillStatus } from './base';

export const CreatePsnDeepSkillRequestSchema = z.object({
  psn_deep_skill_id: z.string(),
  skill: z.string(),
  display: z.string(),
  status: z.nativeEnum(PsnDeepSkillStatus).optional().default(PsnDeepSkillStatus.Pending),
  category: z.string().optional(),
  description: z.string().optional(),
  relatedSkills: z.array(z.string()).optional(),
});
export type CreatePsnDeepSkillRequest = z.infer<typeof CreatePsnDeepSkillRequestSchema>;
