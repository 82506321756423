import { call, put, takeLatest } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

const constants = {
  JOB_PRIORITIES_UPDATE: createSagaAction('JOB_PRIORITIES_UPDATE'),
};

const actions = {
  update: (data) => ({
    type: constants.JOB_PRIORITIES_UPDATE.REQUEST,
    ...data,
  }),
};

const handlers = {
  [constants.JOB_PRIORITIES_UPDATE.SUCCESS]: (state, { updatedJobPriority }) => {
    const idx = state.jobPriorities.findIndex((job) => job._id === updatedJobPriority._id);
    if (idx >= 0) {
      const newJobPriorities = [...state.jobPriorities];
      newJobPriorities[idx] = { ...updatedJobPriority };

      return {
        ...state,
        jobPriorities: [...newJobPriorities],
      };
    }
    return { ...state };
  },
  [constants.JOB_PRIORITIES_UPDATE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

function* updateJobPriority(action) {
  try {
    const response = yield call(apiUpdateJobPriority, action);
    const updatedJobPriority = response.data;

    yield put({ type: constants.JOB_PRIORITIES_UPDATE.SUCCESS, updatedJobPriority });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(updatedJobPriority);
    }
  } catch (error) {
    yield put({
      type: constants.JOB_PRIORITIES_UPDATE.FAILURE,
      error: error.message || error,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(error.message || error);
    }
  }
}

function* watchUpdateJobPriority() {
  yield takeLatest(constants.JOB_PRIORITIES_UPDATE.REQUEST, updateJobPriority);
}

function apiUpdateJobPriority({ _id, jobPriority }) {
  return request(`/job-priorities/${_id}`, {
    method: 'PUT',
    body: {
      ...jobPriority,
    },
  });
}

export default {
  actions,
  constants,
  handlers,
  watcher: watchUpdateJobPriority,
};
