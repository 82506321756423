import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const PersonActiveAssignmentItemSchema = z.object({
  person_id: z.string(),
  assignment_id: z.string(),
  dtstart: dateString,
  dtend: dateString,
  project_id: z.string(),
  project: z.string(),
  dtplannedstart: dateString,
  dtplannedend: dateString,
  client_id: z.string(),
  client: z.string(),
});

export type PersonActiveAssignmentItem = z.infer<typeof PersonActiveAssignmentItemSchema>;
