import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';

import { actions as authActions } from '@redux/modules/authentication';
import * as userSelectors from '@selectors/user';

const propTypes = {
  userEmail: PropTypes.string,
  fingerprint: PropTypes.string,
};

const MockLogin = () => {
  const dispatch = useDispatch();
  const params = useParams();

  // redux state

  const userEmail = useSelector((state) => userSelectors.getCurrentUserEmail(state, params));

  // hooks

  useEffect(() => {
    dispatch(authActions.mocklogin({ userEmail }));
  }, []);

  return <Redirect to="/admin/dashboard" />;
};

MockLogin.propTypes = propTypes;
export default MockLogin;
