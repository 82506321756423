import { all, fork, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { constants } from '@redux/modules/calendly-users';
import * as api from '@redux/api/calendly-users';
import isFunction from 'lodash/isFunction';

export function* getAll(action) {
  try {
    const response = yield call(api.getAllCalendlyUsers, action);
    yield put({ type: constants.CALENDLY_USERS_GET_ALL.SUCCESS, calendlyUsers: response.data });
  } catch (e) {
    yield put({
      type: constants.CALENDLY_USERS_GET_ALL.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchGetAll() {
  yield takeLatest(constants.CALENDLY_USERS_GET_ALL.REQUEST, getAll);
}

export function* getOne(action) {
  try {
    const response = yield call(api.getCalendlyUser, action);
    yield put({ type: constants.CALENDLY_USERS_GET.SUCCESS, calendlyUser: response.data });
  } catch (e) {
    yield put({
      type: constants.CALENDLY_USERS_GET.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchGetOne() {
  yield takeEvery(constants.CALENDLY_USERS_GET.REQUEST, getOne);
}

export function* getOneByUser(action) {
  try {
    const response = yield call(api.getCalendlyUserByUserId, action);
    yield put({ type: constants.CALENDLY_USERS_GET.SUCCESS, calendlyUser: response.data });
  } catch (e) {
    yield put({
      type: constants.CALENDLY_USERS_GET.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchGetOneByUser() {
  yield takeEvery(constants.CALENDLY_USERS_GET_BY_USER.REQUEST, getOneByUser);
}

export function* deleteOne(action) {
  try {
    yield call(api.deleteCalendlyUser, action);
    yield put({ type: constants.CALENDLY_USERS_DELETE.SUCCESS });
    yield getAll(action);
  } catch (e) {
    yield put({
      type: constants.CALENDLY_USERS_DELETE.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchDelete() {
  yield takeEvery(constants.CALENDLY_USERS_DELETE.REQUEST, deleteOne);
}

export function* save(action) {
  try {
    const response = yield call(api.save, action);
    yield put({
      type: constants.CALENDLY_USERS_SAVE.SUCCESS,
      calendlyUser: response.data,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(response);
    }
  } catch (e) {
    yield put({
      type: constants.CALENDLY_USERS_SAVE.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(e.message || e);
    }
  }
}
export function* watchSave() {
  yield takeEvery(constants.CALENDLY_USERS_SAVE.REQUEST, save);
}

export function* getTypes(action) {
  try {
    const response = yield call(api.getTypes, action);
    yield put({ type: constants.CALENDLY_USERS_GET_ALL_TYPES.SUCCESS, types: response.data });
  } catch (e) {
    yield put({
      type: constants.CALENDLY_USERS_GET_ALL_TYPES.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchGetTypes() {
  yield takeLatest(constants.CALENDLY_USERS_GET_ALL_TYPES.REQUEST, getTypes);
}

export function* getCategories(action) {
  try {
    const response = yield call(api.getCategories, action);
    yield put({
      type: constants.CALENDLY_USERS_GET_ALL_CATEGORIES.SUCCESS,
      categories: response.data,
    });
  } catch (e) {
    yield put({
      type: constants.CALENDLY_USERS_GET_ALL_CATEGORIES.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchGetCategories() {
  yield takeLatest(constants.CALENDLY_USERS_GET_ALL_CATEGORIES.REQUEST, getCategories);
}

export function* getEmailTemplates(action) {
  try {
    const response = yield call(api.getEmailTemplates, action);
    yield put({
      type: constants.CALENDLY_USERS_GET_ALL_EMAIL_TEMPLATES.SUCCESS,
      emailTemplates: response.data,
    });
  } catch (e) {
    yield put({
      type: constants.CALENDLY_USERS_GET_ALL_EMAIL_TEMPLATES.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchGetEmailTemplates() {
  yield takeLatest(constants.CALENDLY_USERS_GET_ALL_EMAIL_TEMPLATES.REQUEST, getEmailTemplates);
}

export function* updateEventType(action) {
  try {
    const response = yield call(api.updateEventType, action);
    yield put({
      type: constants.CALENDLY_USERS_UPDATE_EVENT_TYPE.SUCCESS,
      calendlyUser: response.calendlyUser,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(response);
    }
  } catch (e) {
    yield put({
      type: constants.CALENDLY_USERS_UPDATE_EVENT_TYPE.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(e.message || e);
    }
  }
}
export function* watchUpdateEventType() {
  yield takeEvery(constants.CALENDLY_USERS_UPDATE_EVENT_TYPE.REQUEST, updateEventType);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetAll),
    fork(watchGetOne),
    fork(watchDelete),
    fork(watchSave),
    fork(watchGetTypes),
    fork(watchGetCategories),
    fork(watchGetEmailTemplates),
    fork(watchGetOneByUser),
    fork(watchUpdateEventType),
  ]);
}
