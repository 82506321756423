import { z } from 'zod';

import { dateString } from '../../infrastructure/schema';
import { RevenueRateMongoSchema } from '../revenue-rate';

export enum HireGroup {
  'NeedToHire' = 'Need to hire',
  'BuildBench' = 'Build bench',
  'FillInternally' = 'Will Fill Internally',
  'NoRecruiting' = 'No recruiting',
}

export enum Seniority {
  '-' = 'Not Set',
  'J' = 'Junior',
  'M' = 'Mid',
  'MS' = 'Mid-Senior',
  'S' = 'Senior',
  'SS' = 'Super-Senior',
}

export enum EnglishLevel {
  'Not Set' = '0',
  'Native' = '1',
  'Professional' = '2',
  'Almost Professional' = '2.5',
  'Passable' = '3',
}

export const PositionSchema = z.object({
  name: z.string(),
  order: z.number(),
  isFilled: z.boolean(),
  startDate: dateString,
  endDate: dateString,
  revenueRates: z.array(RevenueRateMongoSchema),
  isNeedToHire: z.boolean(),
  isLead: z.boolean().optional(),
  _id: z.string().nullable(),
  project: z.string(),
  inv_pos_name: z.string().nullish(),
  shortNote: z.string().nullish(),
  dt_staffit: dateString.nullable(),
  skillIds: z.array(z.string()),
  skills: z.array(z.string()),
  seniority: z.string().nullable(),
  hard_date: z.boolean().nullable(),
  is_open_to_sales: z.boolean().nullish(),
  englishLevel: z.number().nullable(),
  hire_group: z.string().nullable(),
  sticky: z.boolean().nullish(),
  isConfirmed: z.boolean().nullish(),
  is_conflict_confirmed: z.boolean().nullish(),
  targetCost: z.number().nullable(),
  deleted_at: dateString.nullable(),
  deleted_by: z.string().nullable(),
});
export type Position = z.infer<typeof PositionSchema>;

export const PositionPostgresSchema = z.object({
  position_id: z.string(),
  project_id: z.string(),
  isactive: z.boolean(),
  isstaffit: z.boolean(),
  islead: z.boolean(),
  name: z.string(),
  dtsigned: dateString.nullable(),
  dtplannedstart: dateString,
  dtplannedend: dateString,
  dtcreate: dateString,
  dtupdate: dateString.nullable(),
  notes: z.string().nullable(),
  dtmropen: dateString.nullable(),
  inv_pos_name: z.string().nullable(),
  target_cost: z.number().nullable(),
  hire_group: z.nativeEnum(HireGroup),
  seniority: z.nativeEnum(Seniority).nullable(),
  english_level: z.number().nullable(),
  hard_date: z.boolean().nullable(),
  dt_staffit: dateString.nullable(),
  dt_opp_extension: dateString.nullable(),
  deleted_at: dateString.nullable(),
  deleted_by: z.string().nullable(),
  is_open_to_sales: z.boolean().default(false),
});

export type PositionPostgres = z.infer<typeof PositionPostgresSchema>;

export const PositionListItemPostgresSchema = PositionPostgresSchema.pick({
  position_id: true,
  name: true,
  project_id: true,
  dtplannedstart: true,
  dtplannedend: true,
});
// a

export type PositionListItemPostgres = z.infer<typeof PositionListItemPostgresSchema>;

export const PositionListItemSchema = PositionSchema.pick({
  _id: true,
  name: true,
  startDate: true,
  endDate: true,
  project: true,
});

export type PositionListItem = z.infer<typeof PositionListItemSchema>;

export const DEFAULT_TARGET_COST = 7;

export enum PosWarningTypes {
  'POS_COVER' = 'POS_COVER',
  'OPP_POS' = 'OPP_POS',
  'POS_OPP_EXT' = 'POS_OPP_EXT',
}

export const PosWarningSchema = z.object({
  project_id: z.string(),
  position_id: z.string(),
  warning_text: z.string(),
  warning_code: z.nativeEnum(PosWarningTypes),
});
export type PosWarning = z.infer<typeof PosWarningSchema>;

export const ERROR_POSITION_NOT_FOUND = 'Position not found.';
