import { z } from 'zod';
import { CommentTagSchema } from '../base';

export const CommentTagCreateSchema = CommentTagSchema.pick({
  name: true,
  description: true,
  mongo_id: true,
  emoji: true,
  color: true,
  author_id: true,
});
export type CommentTagCreate = z.infer<typeof CommentTagCreateSchema>;
