const actionDefinitions = [
  {
    key: 'MESSAGES_GET',
    opts: { successKey: 'data' },
  },
  {
    key: 'MESSAGE_POST',
    opts: { successKey: 'data' },
  },
  {
    key: 'MESSAGE_DELETE',
    opts: { successKey: 'data' },
  },
  {
    key: 'MESSAGE_UPDATE',
    opts: { successKey: 'data' },
  },
  {
    key: 'MESSAGE_TOGGLE_PIN',
    opts: { successKey: 'data' },
  },
];

export default actionDefinitions;
