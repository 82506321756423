import { call, put, takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';
import isFunction from 'lodash/isFunction';

import * as schemas from '@src/schemas';
import { actions as entitiesActions } from '@redux/modules/entities';

// MODULE

const constants = {
  COMMENT_PUT: createSagaAction('COMMENT_PUT'),
};

const actions = {
  editComment: (data) => ({
    ...data,
    type: constants.COMMENT_PUT.REQUEST,
  }),
};

const handlers = {
  [constants.COMMENT_PUT.REQUEST]: (state, action) => {
    return { ...state, isLoading: true, data: [] };
  },
  [constants.COMMENT_PUT.SUCCESS]: (state, { data }) => {
    return { ...state, isLoading: false, data };
  },
  [constants.COMMENT_PUT.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

function* sagaEditComment(action) {
  try {
    const data = yield call(apiEditComment, action);
    const normalized = normalize(data.application, schemas.application);
    const { comments, applications } = normalized.entities;

    yield put(entitiesActions.mergeApplications(applications));
    yield put(entitiesActions.mergeComments(comments));

    yield put({ type: constants.COMMENT_PUT.SUCCESS, data });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.COMMENT_PUT.FAILURE,
      error: e.message || e,
    });

    if (isFunction(action.onFailure)) {
      yield action.onFailure(e.message || e);
    }
  }
}

function* watchEditComment() {
  yield takeLatest(constants.COMMENT_PUT.REQUEST, sagaEditComment);
}

// API

function apiEditComment({ applicationId, commentId, comment }) {
  return request(`/applications/${applicationId}/comments/${commentId}`, {
    method: 'PUT',
    body: {
      comment,
    },
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchEditComment,

  // optionally we may
  // api: {
  //   editComment: apiEditComment
  // }
};
