import { z } from 'zod';
import { Endpoint, Method, dateString } from '../../infrastructure';
import { PositionPostgres } from './base';

export enum DateType {
  'Start' = 'startDate',
  'End' = 'endDate',
}
export const PositionBulkUpdateDateBodySchema = z.object({
  positions: z.array(z.string()),
  newDate: dateString,
  sticky: z.boolean().optional(),
  dateType: z.nativeEnum(DateType),
  isConfirmed: z.boolean().optional(),
  is_conflict_confirmed: z.boolean().optional(),
  project_id: z.string(),
});
export type PositionBulkUpdateDateBody = z.infer<typeof PositionBulkUpdateDateBodySchema>;

export type PositionBulkUpdateDateResponse = {};

export type PositionBulkUpdateDateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/positions/bulk-update-date`,
  PositionBulkUpdateDateResponse,
  undefined,
  undefined,
  PositionBulkUpdateDateBody
>;
