import { z } from 'zod';
import { PositionPostgresSchema } from '../';

export const SQLPositionCreateSchema = PositionPostgresSchema.pick({
  project_id: true,
  isactive: true,
  isstaffit: true,
  islead: true,
  name: true,
  dtsigned: true,
  dtplannedstart: true,
  dtplannedend: true,
  notes: true,
  inv_pos_name: true,
  target_cost: true,
  hire_group: true,
  seniority: true,
  english_level: true,
  hard_date: true,
  dt_staffit: true,
})
  .partial({
    isactive: true,
    isstaffit: true,
    islead: true,
    dtsigned: true,
    notes: true,
    inv_pos_name: true,
    target_cost: true,
    hire_group: true,
    seniority: true,
    english_level: true,
    hard_date: true,
    dt_staffit: true,
  })
  .extend({
    skills: z.string().array(),
  });
export type SQLPositionCreate = z.infer<typeof SQLPositionCreateSchema>;

export const SQLPositionCreatePropsSchema = z.object({
  recompute_name: z.boolean().nullish(),
});
export type SQLPositionCreateProps = z.infer<typeof SQLPositionCreatePropsSchema>;
