import { z } from 'zod';
import { BaseSchemaDW } from '../structure';
import { JobDWStatusEnum } from './Job';

export const JobStateSchema = z
  .object({
    job_id: z.number(),
    status: z.enum(JobDWStatusEnum),
    prev_status: z.enum(JobDWStatusEnum).nullable(),
    start_date: z.date(),
    end_date: z.date().nullable(),
  })
  .merge(BaseSchemaDW);
export type JobStateDW = z.infer<typeof JobStateSchema>;
