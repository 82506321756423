import * as api from '@redux/api/application';
import { constants } from '@redux/modules/application/application';
import { call, put, takeLatest } from 'redux-saga/effects';

function* getBrainsAssignments(action) {
  try {
    const brainsAssignments = yield call(api.getBrainsAssignments, action);

    yield put({ type: constants.APPLICATION_GET_BRAINS_ASSIGNMENTS.SUCCESS, brainsAssignments });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_GET_BRAINS_ASSIGNMENTS.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetBrainsAssignments() {
  yield takeLatest(constants.APPLICATION_GET_BRAINS_ASSIGNMENTS.REQUEST, getBrainsAssignments);
}

function* resetBrainsAssignments(action) {
  try {
    yield put({ type: constants.APPLICATION_RESET_BRAINS_ASSIGNMENTS.SUCCESS });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_RESET_BRAINS_ASSIGNMENTS.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchResetBrainsAssignments() {
  yield takeLatest(constants.APPLICATION_RESET_BRAINS_ASSIGNMENTS.REQUEST, resetBrainsAssignments);
}
