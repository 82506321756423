import { z } from 'zod';

export const GridKeySchema = z.string();
export type GridKey = z.infer<typeof GridKeySchema>;
export const GridValueSchema = z.number();
export type GridValue = z.infer<typeof GridValueSchema>;

export type GridCells = Record<GridKey, GridValue>;

export type GridPatch = Record<number, GridCells>;
export type GridKeyRange = [GridKey, GridKey] | Readonly<[GridKey, GridKey]>;

export type CalculateGridKey = (key: GridKey, diff: GridValue) => GridKey;
export type CompareGridKeys = (keyA: GridKey, keyB: GridKey) => number;

export const GridBaseSchema = z.object({
  grid_row_id: z.number(),
  label: z.string(),
  cells: z.record(GridKeySchema, GridValueSchema),
  meta: z.any(),

  dt_created: z.string(),
  dt_updated: z.string(),
});
export type GridBase = z.infer<typeof GridBaseSchema>;

export const isGrid = (obj: unknown): obj is GridBase =>
  !!Object.getOwnPropertyDescriptor(obj, 'grid_row_id');
