import { z } from 'zod';

export const PersonActiveDepartmentItemSchema = z.object({
  person_id: z.string(),
  department_id: z.string(),
  name: z.string(),
  isbillable: z.boolean(),
  assigned_cost: z.string(),
  unassigned_cost: z.string(),
  display_color: z.string().nullable(),
});

export type PersonActiveDepartmentItem = z.infer<typeof PersonActiveDepartmentItemSchema>;
