import request from '../../shared/request';
import qs from 'query-string';

export function getSummaryByCountryGroup({
  from,
  to,
  countries,
  jobs,
  sources,
  groupBy,
  statuses,
  timeResolution,
  jobStatuses,
}) {
  const query = qs.stringify({
    from,
    to,
    countries,
    jobs,
    sources,
    groupBy,
    statuses,
    timeResolution,
    jobStatuses,
  });

  return request(`/reports/applications-progress/country?${query}`, {
    method: 'GET',
  });
}

export function getSummaryByJob({
  from,
  to,
  countries,
  jobs,
  sources,
  groupBy,
  statuses,
  timeResolution,
  jobStatuses,
}) {
  const query = qs.stringify({
    from,
    to,
    countries,
    jobs,
    sources,
    groupBy,
    statuses,
    timeResolution,
    jobStatuses,
  });

  return request(`/reports/applications-progress/job?${query}`, {
    method: 'GET',
  });
}
