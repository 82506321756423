import { z } from 'zod';
import { FilterOperations } from '../../infrastructure';

export const ListPsnDeepSkillRequestSchema = z
  .object({
    filters: z
      .object({
        category: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
        status: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
      })
      .partial(),
  })
  .partial();
export type ListPsnDeepSkillRequest = z.infer<typeof ListPsnDeepSkillRequestSchema>;
