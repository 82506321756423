import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const DeleteStaffingCandidateParamsSchema = z
  .object({
    staffing_candidate_id: z.coerce.number(),
  })
  .extend({
    opportunity_id: z.string().optional(),
  });

export const DeleteStaffingCandidateRequestSchema = z.object({
  user: z
    .object({
      name: z.string().optional(),
      email: z.string().optional(),
    })
    .optional(),
});
export type DeleteStaffingCandidateRequest = z.infer<typeof DeleteStaffingCandidateRequestSchema>;

export type DeleteStaffingCandidateParams = z.infer<typeof DeleteStaffingCandidateParamsSchema>;

export type DeleteStaffingCandidateResponse = {};

export type DeleteStaffingCandidateEndpoint = Endpoint<
  Method.DELETE,
  `/api/v3/staffing-candidate/${DeleteStaffingCandidateParams['staffing_candidate_id']}`,
  DeleteStaffingCandidateResponse,
  DeleteStaffingCandidateParams,
  undefined,
  DeleteStaffingCandidateRequest
>;
