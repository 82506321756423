import { Endpoint, Method } from '../../infrastructure';

import { z } from 'zod';

export const AddressesMismatchesSchema = z.object({
  person_id: z.string(),
  name: z.string(),
  brains_profile: z.string(),
  email: z.string(),
  personal_country: z.string(),
  shipping_country: z.string(),
  shopify_country: z.string(),
  personal_address: z.string(),
  shipping_address: z.string(),
  shopify_address: z.string(),
});
export type AddressesMismatches = z.infer<typeof AddressesMismatchesSchema>;

//Define the response
export type AddressesMismatchesResponse = { rows: readonly AddressesMismatches[] };

//Define the endpoint
export type AddressesMismatchesEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/addresses-mismatches/',
  AddressesMismatchesResponse,
  undefined,
  undefined,
  undefined
>;
