import { PsnRctrGoogleCalendarChannelSchema } from '../base';
import { z } from 'zod';

export const PsnRctrGoogleCalendarChannelUpdateSchema = PsnRctrGoogleCalendarChannelSchema.pick({
  calendar_id: true,
  dt_expiration: true,
  dt_last_notification: true,
  is_active: true,
  resource_id: true,
  resource_uri: true,
  resource_uuid: true,
}).partial({
  dt_expiration: true,
  dt_last_notification: true,
  is_active: true,
  resource_id: true,
  resource_uri: true,
  resource_uuid: true,
});

export type PsnRctrGoogleCalendarChannelUpdate = z.infer<
  typeof PsnRctrGoogleCalendarChannelUpdateSchema
>;
