import { call, put, takeLatest } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';
import * as api from '@redux/api/application';
import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

let assignmentsReportActions;
import('@redux/assignments-report').then(({ actions }) => (assignmentsReportActions = actions));

// MODULE

const constants = {
  APPLICATION_REMOVE_ASSIGNMENT: createSagaAction('APPLICATION_REMOVE_ASSIGNMENT'),
  APPLICATION_TIMELINES_GET: createSagaAction('APPLICATION_TIMELINES_GET'),
};

const actions = {
  removeAssignment: (applicationId, assignmentId, onSuccess, onFailure) => ({
    type: constants.APPLICATION_REMOVE_ASSIGNMENT.REQUEST,
    applicationId,
    assignmentId,
    onSuccess,
    onFailure,
  }),
};

const handlers = {
  [constants.APPLICATION_REMOVE_ASSIGNMENT.REQUEST]: (state, action) => {
    return {
      ...state,
      removeAssignmentError: null,
    };
  },
  [constants.APPLICATION_REMOVE_ASSIGNMENT.SUCCESS]: (state, { data }) => {
    return {
      ...state,
      removeAssignmentError: null,
    };
  },
  [constants.APPLICATION_REMOVE_ASSIGNMENT.FAILURE]: (state, { error }) => {
    return {
      ...state,
      removeAssignmentError: error,
    };
  },
};

// SAGA

export function* sagaRemoveAssignment({ applicationId, assignmentId, onSuccess, onFailure }) {
  try {
    yield put(assignmentsReportActions.removeApplicationAssignment(applicationId, assignmentId));
    const response = yield call(apiRemoveAssignment, applicationId, assignmentId);
    yield put({
      type: constants.APPLICATION_REMOVE_ASSIGNMENT.SUCCESS,
    });
    yield put(assignmentsReportActions.removeApplicationAssignmentSuccess());

    if (isFunction(onSuccess)) {
      yield onSuccess(response.message || '');
    }

    const timelineResponse = yield call(api.getApplicationTimelineList, { applicationId });
    const timelineList = (timelineResponse && timelineResponse.data) || [];
    yield put({
      type: constants.APPLICATION_TIMELINES_GET.SUCCESS,
      timelineList,
    });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_REMOVE_ASSIGNMENT.FAILURE,
      error: e.message || e,
    });
    yield put(assignmentsReportActions.removeApplicationAssignmentFail(applicationId));

    if (isFunction(onFailure)) {
      yield onFailure(e.message || e);
    }
  }
}

export function* watchRemoveAssignment() {
  yield takeLatest(constants.APPLICATION_REMOVE_ASSIGNMENT.REQUEST, sagaRemoveAssignment);
}

// API

export function apiRemoveAssignment(applicationId, assignmentId) {
  return request(`/applications/${applicationId}/assignments/${assignmentId}`, {
    method: 'DELETE',
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchRemoveAssignment,
};
