import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import { constants } from '@redux/modules/reports/on-deck-report';
import * as api from '@redux/api/on-deck-report';
import * as applicationApi from '@redux/api/application';

// GET SOURCES
function* getOnDeckReport() {
  try {
    const response = yield call(api.getOnDeckReport);
    yield put({
      type: constants.ON_DECK_REPORT_GET.SUCCESS,
      reportByJobs: response,
    });
  } catch (e) {
    yield put({
      type: constants.ON_DECK_REPORT_GET.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetOnDeckReport() {
  yield takeLatest(constants.ON_DECK_REPORT_GET.REQUEST, getOnDeckReport);
}

function* updateApplicationMgmtReviewed({ applicationId, reviewed }) {
  try {
    yield call(applicationApi.updateApplicationMgmtReviewed, { applicationId, reviewed });
    yield put({
      type: constants.ON_DECK_UPDATE_APPLICATION_MGMT_REVIEWED.SUCCESS,
      applicationId,
      reviewed,
    });
  } catch (e) {
    yield put({
      type: constants.ON_DECK_UPDATE_APPLICATION_MGMT_REVIEWED.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchUpdateApplicationMgmtReviewed() {
  yield takeLatest(
    constants.ON_DECK_UPDATE_APPLICATION_MGMT_REVIEWED.REQUEST,
    updateApplicationMgmtReviewed,
  );
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([fork(watchGetOnDeckReport), fork(watchUpdateApplicationMgmtReviewed)]);
}
