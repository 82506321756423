import { z } from 'zod';
import { PersonTagAscSchema } from '../base';

export const PersonTagAscCreateSchema = PersonTagAscSchema.pick({
  person_id: true,
  person_tag_id: true,
  dt_start: true,
}).partial({
  dt_start: true,
});

export type PersonTagAscCreate = z.infer<typeof PersonTagAscCreateSchema>;

export const PersonTagAscRemoveSchema = PersonTagAscSchema.pick({
  person_id: true,
  person_tag_id: true,
  dt_end: true,
}).partial({
  dt_end: true,
});

export type PersonTagAscRemove = z.infer<typeof PersonTagAscRemoveSchema>;
