import { call, put, takeLatest } from 'redux-saga/effects';

import { createSagaAction } from '@shared/sagas';
import request from '@shared/request';

// MODULE
const constants = {
  LEAD_RESPONSIBLE_UPDATE: createSagaAction('LEAD_RESPONSIBLE_UPDATE'),
};

const actions = {
  updatePositionResponsible: ({ lead, position, field, responsible }) => ({
    type: constants.LEAD_RESPONSIBLE_UPDATE.REQUEST,
    data: {
      lead,
      position,
      field,
      responsible,
    },
  }),
};

const handlers = {
  [constants.LEAD_RESPONSIBLE_UPDATE.REQUEST]: (state) => {
    return {
      ...state,
      isLoading: true,
      updateSuccessMessage: null,
      updateErrorMessage: null,
      error: null,
    };
  },
  [constants.LEAD_RESPONSIBLE_UPDATE.SUCCESS]: (state) => {
    return {
      ...state,
      error: null,
      updateSuccessMessage: null,
      updateErrorMessage: null,
      isLoading: false,
    };
  },
  [constants.LEAD_RESPONSIBLE_UPDATE.FAILURE]: (state, { message }) => {
    return {
      ...state,
      updateErrorMessage: message,
      updateSuccessMessage: null,
      isLoading: false,
    };
  },
};

// SAGA
function* sagaUpdatePositionResponsible(action) {
  try {
    const updatedLead = yield call(apiUpdatePositionResponsible, action.data);

    yield put({
      type: constants.LEAD_RESPONSIBLE_UPDATE.SUCCESS,
      message: 'Opportunity updated successfully!',
      updatedLead,
    });
  } catch (e) {
    yield put({
      type: constants.LEAD_RESPONSIBLE_UPDATE.FAILURE,
      updateErrorMessage: e.message || e,
    });
  }
}

function* watchUpdatePositionResponsible() {
  yield takeLatest(constants.LEAD_RESPONSIBLE_UPDATE.REQUEST, sagaUpdatePositionResponsible);
}

// API
function apiUpdatePositionResponsible({ lead, position, responsible, field }) {
  return request(`/leads/${lead}/positions/${position}/responsible`, {
    method: 'PUT',
    body: {
      field,
      responsible,
    },
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchUpdatePositionResponsible,
};
