import { z } from 'zod';
import { PositionPostgresSchema } from './base';
import { FilterOperations } from '../../infrastructure';

export const ListSQLPositionsSchema = PositionPostgresSchema.extend({
  skills: z.string(),
  revenueamount: z.number(),
  is_assigned: z.boolean(),
  asn_start: z.string(),
  asn_end: z.string(),
  person_salary: z.number(),
});
export type ListSQLPositions = z.infer<typeof ListSQLPositionsSchema>;

//Filters
export const ListSQLPositionsFiltersSchema = z.object({
  project_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
});
export type ListSQLPositionsFilters = z.infer<typeof ListSQLPositionsFiltersSchema>;

//Query
export const ListSQLPositionsQuerySchema = z.object({
  filters: ListSQLPositionsFiltersSchema.optional(),
});
export type ListSQLPositionsQuery = z.infer<typeof ListSQLPositionsQuerySchema>;
