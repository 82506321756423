import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const PositionBulkMakeAsnStickyRequestSchema = z.object({
  positions_id: z.string().array(),
  project_id: z.string(),
});
export type PositionBulkMakeAsnStickyRequest = z.infer<
  typeof PositionBulkMakeAsnStickyRequestSchema
>;

export type PositionBulkMakeAsnStickyResponse = {};

export type PositionBulkMakeAsnStickyEndpoint = Endpoint<
  Method.POST,
  `/api/v2/sql-positions/bulk-make-asn-sticky`,
  PositionBulkMakeAsnStickyResponse,
  undefined,
  undefined,
  PositionBulkMakeAsnStickyRequest
>;
