import { call, put, takeLatest } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';
import { apiGetJobPriorities } from '@redux/job-priorities/get';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

const constants = {
  JOB_PRIORITIES_CREATE: createSagaAction('JOB_PRIORITIES_CREATE'),
};

const actions = {
  create: (args) => ({
    type: constants.JOB_PRIORITIES_CREATE.REQUEST,
    ...args,
  }),
};

const handlers = {
  [constants.JOB_PRIORITIES_CREATE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.JOB_PRIORITIES_CREATE.SUCCESS]: (state, { jobPriorities }) => {
    return { ...state, jobPriorities };
  },
};

function* createJobPriority(action) {
  try {
    const createJobPriorityResponse = yield call(apiCreateJobPriority, action);
    const JobPrioritiesResponse = yield call(apiGetJobPriorities, action);

    const JobPriorities = JobPrioritiesResponse.data;

    yield put({ type: constants.JOB_PRIORITIES_CREATE.SUCCESS, JobPriorities });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(createJobPriorityResponse.jobId);
    }
  } catch (error) {
    yield put({
      type: constants.JOB_PRIORITIES_CREATE.FAILURE,
      error: error.message || error,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(error.message || error);
    }
  }
}

function* watchCreateJobPriority() {
  yield takeLatest(constants.JOB_PRIORITIES_CREATE.REQUEST, createJobPriority);
}

function apiCreateJobPriority({ jobPriority }) {
  return request('/job-priorities', {
    method: 'POST',
    body: {
      ...jobPriority,
    },
  });
}

export default {
  actions,
  constants,
  handlers,
  watcher: watchCreateJobPriority,
};
