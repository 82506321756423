import { z } from 'zod';
import {
  CSVEndpoint,
  Endpoint,
  FilterOperations,
  Method,
  ReportsCompareResponseSchema,
} from '../../infrastructure';

export type CSVPaymentsEndpoint = CSVEndpoint<'/api/v2/sql-reports/payments.csv', CSVPaymentsQuery>;
export type ComparePaymentsEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/payments.csv',
  ComparePaymentsResponse,
  CSVPaymentsQuery,
  {},
  undefined
>;

export const CSVPaymentsFilterSchema = z.object({
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
  payment_provider_id: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
});

export type CSVPaymentsFilter = z.infer<typeof CSVPaymentsFilterSchema>;

export const CSVPaymentsQuerySchema = z.object({
  filters: CSVPaymentsFilterSchema,
  compare: z.coerce.boolean().optional(),
  confirmed: z.coerce.boolean().optional(),
});

export type CSVPaymentsQuery = z.infer<typeof CSVPaymentsQuerySchema>;

export const ComparePaymentsResponseSchema = ReportsCompareResponseSchema;

export type ComparePaymentsResponse = z.infer<typeof ComparePaymentsResponseSchema>;
