import applicationDripCampaigns from './application-drip-campaigns/application-drip-campaigns';
import application from './application/application';
import authentication from './authentication';
import calendlyUsers from './calendly-users';
import calendlyUserTypes from './calendly-user-types';
import candidate from './candidate';
import dashboard from './dashboard/dashboard';
import emails from './emails';
import entities from './entities';
import featureFlags from './feature-flags';
import jobTextQuestions from './job-text-questions';
import jobTitles from './job-titles';
import jobTypes from './job-types';
import messages from './messages/messages';
import metrics from './metrics/metrics';
import pageState from './page-state';
import questions from './questions';
import rejectionReasons from './rejection-resons';
import actionsReport from './reports/actions-report';
import applicationProgressReport from './reports/application-progress';
import applicationsReport from './reports/applications';
import assignmentTracing from './reports/assignment-tracing-report';
import assignmentsReport from './reports/assignments-report';
import dailyFunnelReport from './reports/daily-funnel-report';
import inboundEmailErrors from './reports/inbound-email-errors';
import scheduledEmails from './reports/scheduled-emails';
import sourcesReport from './reports/sources-report/index';
import statusesReport from './reports/statuses-report';
import testReminderCampaign from './reports/test-reminder-campaign';
import roles from './roles';
import scorecardQuestions from './scorecard-questions';
import sources from './sources';
import mediums from './mediums';
import todos from './todos';
import user from './users';
import skills from './skills';
import onDeckReport from './reports/on-deck-report';
import focusReport from './reports/focus-report';

// refactored
import { reducer as account } from '@redux/account';
import { reducer as applicationV2 } from '@redux/application';
import { reducer as assignmentsReportV2 } from '@redux/assignments-report';
import { reducer as comments } from '@redux/comments';
import { reducer as interviewsReport } from '@redux/interviews-report';
import { reducer as jobPriorities } from '@redux/job-priorities';
import { reducer as jobs } from '@redux/jobs';
import { reducer as leads } from '@redux/leads';
import { reducer as unresponsiveReport } from '@redux/reports/unresponsive-application';
import { reducer as search } from '@redux/search';
import { reducer as utmParams } from '@redux/utm-params';

export default {
  assignmentsReportV2,
  entities,
  comments,
  application,
  applicationV2,
  search,
  user,
  authentication,
  dashboard,
  emails,
  assignmentsReport,
  jobs,
  jobTypes,
  jobTitles,
  leads,
  jobPriorities,
  messages,
  metrics,
  sourcesReport,
  actionsReport,
  questions,
  rejectionReasons,
  sources,
  mediums,
  interviewsReport,
  testReminderCampaign,
  dailyFunnelReport,
  todos,
  scorecardQuestions,
  account,
  inboundEmailErrors,
  scheduledEmails,
  statusesReport,
  assignmentTracing,
  candidate,
  applicationsReport,
  applicationProgressReport,
  featureFlags,
  jobTextQuestions,
  calendlyUsers,
  calendlyUserTypes,
  applicationDripCampaigns,
  roles,
  pageState,
  unresponsiveReport,
  skills,
  utmParams,
  onDeckReport,
  focusReport,
};
