import { call, takeLatest, put } from 'redux-saga/effects';
import * as api from '@redux/api/application';
import { constants } from '@redux/modules/application/application';

function* getDepartmentOptions(action) {
  try {
    const payload = yield call(api.getDepartmentOptions, action);
    yield put({
      type: constants.APPLICATION_DEPARTMENT_OPTIONS_GET.SUCCESS,
      data: payload.data,
    });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_DEPARTMENT_OPTIONS_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetDepartmentOptions() {
  yield takeLatest(constants.APPLICATION_DEPARTMENT_OPTIONS_GET.REQUEST, getDepartmentOptions);
}
