import { z } from 'zod';

export const EmailTemplateSchema = z.object({
  template_id: z.number().int().nonnegative(),
  template_mongo_id: z.string().length(24),
  name: z.string(),
  slug: z.string(),
  html: z.string().nullish(),
  jade: z.string().nullish(),
  less: z.string().nullish(),
  subject: z.string().nullish(),
  type: z.enum(['html', 'jade']),
  delivery_method: z.enum(['email', 'slack', '']).default(''),
  send_as: z.string().nullish(),
  group: z.string(),
  application_status: z.string().nullish(),
  jade_json: z.string().or(z.object({})).nullish(),
  system_template: z.boolean(),
  campaign_type: z
    .enum(['Onboarding', 'Pre-onboarding', 'Ongouing', 'September-2020', 'Wellness'])
    .nullish(),
  campaign_periodicity: z.enum(['Month', 'Quarter', 'Semester', 'Year']).nullable(),
  campaign_start_date: z.date().nullish(),
  campaign_number_of_days_after_onboarding: z.number().int().nullish(),
});

export type EmailTemplate = z.infer<typeof EmailTemplateSchema>;

export const EmailTemplateCreateSchema = EmailTemplateSchema.omit({
  template_id: true,
});

export type EmailTemplateCreate = z.infer<typeof EmailTemplateCreateSchema>;
