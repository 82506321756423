import { z } from 'zod';

import { Endpoint, Method } from '../../infrastructure';
import { PreLeadSkillSchema, PreLeadSkill } from './base';

export type UpsertPreLeadSkillsParams = {
  grid_row_id: number;
};

export const UpsertPreLeadSkillsRequestSchema = z.object({
  skills: z.array(PreLeadSkillSchema.pick({ skill_id: true, count: true })),
});
export type UpsertPreLeadSkillsRequest = z.infer<typeof UpsertPreLeadSkillsRequestSchema>;

export type UpsertPreLeadSkillsResponse = { skills: PreLeadSkill[] };

export type UpsertPreLeadSkillsEndpoint = Endpoint<
  Method.POST,
  `/api/v3/pre-leads/${UpsertPreLeadSkillsParams['grid_row_id']}/skills`,
  UpsertPreLeadSkillsResponse,
  UpsertPreLeadSkillsParams,
  undefined,
  UpsertPreLeadSkillsRequest
>;
