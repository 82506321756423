import { Endpoint, Method } from '../../../infrastructure';
import { z } from 'zod';

// request schema (for validation of body)
export const GenericTablePostRequestSchema = z.any();
export type GenericTablePostRequest = z.infer<typeof GenericTablePostRequestSchema>;

//params schema (for validation of params)
export const GenericTablePostParamsSchema = z.object({
  table_name: z.string(),
});

export type GenericTablePostParams = z.infer<typeof GenericTablePostParamsSchema>;
//params type

//response type = any
export type GenericTablePostResponse = any;

//Endpoint -> Method, url, response, params, query, body / request
export type GenericTablePostEndpoint = Endpoint<
  Method.POST,
  `/api/v2/generic-table/${GenericTablePostParams['table_name']}`,
  GenericTablePostResponse,
  GenericTablePostParams,
  undefined,
  GenericTablePostRequest
>;
