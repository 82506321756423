import { z } from 'zod';
import { Lead } from './base';
import { Endpoint, Method } from '../../infrastructure';

export const OpportunityDraftConfirmationParamsSchema = z.object({
  project_id: z.string(),
});

export type OpportunityDraftConfirmationParams = z.infer<
  typeof OpportunityDraftConfirmationParamsSchema
>;

export type OpportunityDraftConfirmationResponse = Lead;

export type OpportunityDraftConfirmationEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/sql-leads/${OpportunityDraftConfirmationParams['project_id']}/confirm-draft`,
  OpportunityDraftConfirmationResponse,
  OpportunityDraftConfirmationParams,
  undefined,
  undefined
>;
