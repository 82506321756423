import { GoogleCalendarEventSchema } from '..';
import { z } from 'zod';

export const GoogleCalendarEventUpsertSchema = GoogleCalendarEventSchema.pick({
  id: true,
  calendar_id: true,
  attendees: true,
  creator: true,
  description: true,
  end: true,
  etag: true,

  location: true,
  organizer: true,
  start: true,
  status: true,
  summary: true,

  created: true,
  updated: true,
});

export type GoogleCalendarEventUpsert = z.infer<typeof GoogleCalendarEventUpsertSchema>;
