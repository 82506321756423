import { Endpoint, Method } from '../../../infrastructure';
import { InterviewCalendarEvent } from '../base';
import { z } from 'zod';

export const ICESetDroppedParamsSchema = z.object({
  interview_calendar_event_id: z.coerce.number(),
});
export type ICESetDroppedParams = z.infer<typeof ICESetDroppedParamsSchema>;

export type ICESetDroppedResponse = InterviewCalendarEvent;

export type ICESetDroppedEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/interview-calendar-events/${ICESetDroppedParams['interview_calendar_event_id']}/dropped`,
  ICESetDroppedResponse,
  ICESetDroppedParams,
  undefined,
  undefined
>;
