import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const RevenueRateMongoSchema = z.object({
  _id: z.string().nullable(),
  revenueAmount: z.number(),
  startDate: dateString,
});

export type RevenueRateMongo = z.infer<typeof RevenueRateMongoSchema>;

export const RevenueRateSchema = z.object({
  revenuerate_id: z.string(),
  project_id: z.string(),
  position_id: z.string(),
  revenueamount: z.number(),
  dtfrom: dateString,
  dtto: dateString,
  dtcreate: dateString,
  dtupdate: dateString,
});

export type RevenueRate = z.infer<typeof RevenueRateSchema>;
