import { JSX } from 'react';
import { ActionRequest, ActionRequestComponentKeys } from '@ct-internal/api';

import InterviewConducted from './InterviewConducted';
import RescheduleInterview from './RescheduleInterview';
import NoShowInterview from './NoShowInterview';
import DropInterview from './DropInterview';

export type ActionComponent = (props: { notification: ActionRequest }) => JSX.Element;

export const ActionConfig: Partial<Record<ActionRequestComponentKeys, ActionComponent>> = {
  [ActionRequestComponentKeys.InterviewConducted]: InterviewConducted,
  [ActionRequestComponentKeys.NoShowInterview]: NoShowInterview,
  [ActionRequestComponentKeys.DropInterview]: DropInterview,
  [ActionRequestComponentKeys.RescheduleInterview]: RescheduleInterview,
};
