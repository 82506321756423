import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { TimeOffRequest } from '../time_off_request';

export const GetTimeOffsParamsSchema = z.object({
  personId: z.string(),
});
export type GetTimeOffsParams = z.infer<typeof GetTimeOffsParamsSchema>;

export type GetTimeOffsResponse = {
  timeoffs: TimeOffRequest[];
};

export type GetTimeOffsEndpoint = Endpoint<
  Method.GET,
  `/api/v2/person/${GetTimeOffsParams['personId']}/timeoffs`,
  GetTimeOffsResponse,
  GetTimeOffsParams,
  undefined,
  undefined
>;
