import { createReducer } from '../../shared/reducers';

// Constants
export const constants = {
  MERGE_JOBS: 'MERGE_JOBS',
  MERGE_USERS: 'MERGE_USERS',
  MERGE_COMMENTS: 'MERGE_COMMENTS',
  MERGE_APPLICATIONS: 'MERGE_APPLICATIONS',
  MERGE_EMAILS: 'MERGE_EMAILS',
  MERGE_RESULTS: 'MERGE_RESULTS',
  MERGE_TODOS: 'MERGE_TODOS',
  SET_TODOS: 'MERGE_TODOS',
  MERGE_MOST_USED_EMAILS: 'MERGE_MOST_USED_EMAILS',
};

// Action Creators
export const actions = {
  mergeJobs: (jobs) => ({
    type: constants.MERGE_JOBS,
    jobs,
  }),
  mergeUsers: (users) => ({
    type: constants.MERGE_USERS,
    users,
  }),
  mergeComments: (comments) => ({
    type: constants.MERGE_COMMENTS,
    comments,
  }),
  mergeTodos: (todos) => ({
    type: constants.MERGE_TODOS,
    todos,
  }),
  mergeApplications: (applications) => ({
    type: constants.MERGE_APPLICATIONS,
    applications,
  }),
  mergeEmails: (emails) => ({
    type: constants.MERGE_EMAILS,
    emails,
  }),
  mergeResults: (results) => ({
    type: constants.MERGE_RESULTS,
    results,
  }),
  mergeMostUsedEmails: (mostUsedEmails) => ({
    type: constants.MERGE_MOST_USED_EMAILS,
    mostUsedEmails,
  }),
};

// Reducer
export const initialState = {
  jobs: {},
  users: {},
  emails: {},
  results: {},
  applications: {},
  todos: {},
};

const ACTION_HANDLERS = {
  [constants.MERGE_RESULTS]: (state, { results }) => {
    return {
      ...state,
      results: _getMergedProperty('results', state, results),
    };
  },
  [constants.MERGE_EMAILS]: (state, { emails }) => {
    return {
      ...state,
      emails: _getMergedProperty('emails', state, emails),
    };
  },
  [constants.MERGE_JOBS]: (state, { jobs }) => {
    return {
      ...state,
      jobs: _getMergedProperty('jobs', state, jobs),
    };
  },

  [constants.MERGE_USERS]: (state, { users }) => {
    return {
      ...state,
      users: _getMergedProperty('users', state, users),
    };
  },

  [constants.MERGE_COMMENTS]: (state, { comments }) => {
    return {
      ...state,
      comments: _getMergedProperty('comments', state, comments),
    };
  },

  [constants.MERGE_TODOS]: (state, { todos }) => {
    return {
      ...state,
      todos: _getMergedProperty('todos', state, todos),
    };
  },

  [constants.MERGE_APPLICATIONS]: (state, { applications }) => {
    return {
      ...state,
      applications: _getMergedProperty('applications', state, applications),
    };
  },

  [constants.MERGE_MOST_USED_EMAILS]: (state, { mostUsedEmails }) => {
    return {
      ...state,
      emails: _getMergedProperty('emails', state, mostUsedEmails),
    };
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
});

// private function
const _getMergedProperty = (property, state, newData) => {
  // get current state data
  const { [property]: currentData } = state;
  // if data was not set yet
  if (currentData === initialState[property]) {
    return newData;
  }
  return {
    ...currentData,
    ...newData,
  };
};
