import { z } from 'zod';

export const CountrySchema = z.object({
  country_id: z.string().max(2, { message: 'Country must be 2 or fewer characters long' }),
  name: z.string(),
  region: z.string(),
  subregion: z.string(),
  iso3: z.string(),
  phonecode: z.string(),
  capital: z.string(),
  currency: z.string(),
  currency_name: z.string(),
  currency_symbol: z.string(),
  latitude: z.number(),
  longitude: z.number(),
});

export type Country = z.infer<typeof CountrySchema>;
// export const StatesSchema = z.object({
//   state_id: z.string().max(5, { message: 'State must be 5 or fewer characters long' }),
//   country_id: z.string(),
//   name: z.string(),
// });
//
// export const CitySchema = z.object({
//   city_id: z.number(),
//   country_id: z.string(),
//   state_id: z.string(),
//   name: z.string(),
// });
//
