import { z } from 'zod';
import { Endpoint, FilterOperations, Method, ReportResponse } from '../../infrastructure';
import { LeadsStaffItFilterSchema, PreOppRevenueFilterSchema } from './plan-yearly-report';
//Base
export const ProjectAnalysisSchema = z.object({
  month_a: z.number(),
  month_b: z.number(),
  client_name: z.string(),
  client_id: z.string(),
  project_name: z.string(),
  project_id: z.string(),
  isproject_a_lead: z.boolean(),
  islead: z.boolean(),
  month_a_revenue: z.number(),
  month_b_revenue: z.number(),
  increase: z.number(),
  decrease: z.number(),
  is_starting: z.boolean(),
  is_ending: z.boolean(),
  is_wenting_down: z.boolean(),
  is_wenting_up: z.boolean(),
});
export type ProjectAnalysis = z.infer<typeof ProjectAnalysisSchema>;

export const ProjectAnalysisClientSchema = ProjectAnalysisSchema.pick({
  month_a: true,
  month_b: true,
  client_name: true,
  client_id: true,
  month_a_revenue: true,
  month_b_revenue: true,
  increase: true,
  decrease: true,
}).extend({
  projects: z.array(ProjectAnalysisSchema),
});
export type ProjectAnalysisClient = z.infer<typeof ProjectAnalysisClientSchema>;

//Filters
export const ProjectAnalysisFiltersSchema = z.object({
  month_a: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
  month_b: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
  leadsStaffIt: z.record(z.enum([FilterOperations.EQUAL]), LeadsStaffItFilterSchema).optional(),
  listPreLeads: z.record(z.enum([FilterOperations.EQUAL]), PreOppRevenueFilterSchema).optional(),
});
export type ProjectAnalysisFilters = z.infer<typeof ProjectAnalysisFiltersSchema>;

//Query
export const ProjectAnalysisQuerySchema = z.object({
  filters: ProjectAnalysisFiltersSchema.partial(),
});
export type ProjectAnalysisQuery = z.infer<typeof ProjectAnalysisQuerySchema>;

//Response
export type ProjectAnalysisResponse = ReportResponse<ProjectAnalysis>;

//Endpoint
export type ProjectAnalysisEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/project-analysis',
  ProjectAnalysisResponse,
  undefined,
  ProjectAnalysisQuery,
  undefined
>;
