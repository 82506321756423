import { z } from 'zod';
import { ClientSchema } from './base';

export const ClientCreateSchema = ClientSchema.pick({
  name: true,
  short_name: true,
  isdev: true,
  isactive: true,
  is_pre_client: true,
  is_forecast_client: true,
  client_sf_id: true,
}).partial({
  isactive: true,
  isdev: true,
  is_pre_client: true,
  is_forecast_client: true,
  client_sf_id: true,
});

export type ClientCreate = z.infer<typeof ClientCreateSchema>;
