import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

import isFunction from 'lodash/isFunction';

// MODULE

const constants = {
  INTERVIEWS_MARK_RECONCILED: createSagaAction('INTERVIEWS_MARK_RECONCILED'),
};

const actions = {
  markReconciled: (data) => ({
    ...data,
    type: constants.INTERVIEWS_MARK_RECONCILED.REQUEST,
  }),
};

const handlers = {
  [constants.INTERVIEWS_MARK_RECONCILED.REQUEST]: (state, action) => {
    return { ...state, isLoading: true, resultsFailed: [] };
  },
  [constants.INTERVIEWS_MARK_RECONCILED.SUCCESS]: (state, { data }) => {
    return { ...state, isLoading: false, resultsFailed: data };
  },
  [constants.INTERVIEWS_MARK_RECONCILED.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

export function* sagaMarkReconciled(action) {
  try {
    const data = yield call(apiMarkReconciled, action);
    yield put({ type: constants.INTERVIEWS_MARK_RECONCILED.SUCCESS, data });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.INTERVIEWS_MARK_RECONCILED.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchMarkReconciled() {
  yield takeLatest(constants.INTERVIEWS_MARK_RECONCILED.REQUEST, sagaMarkReconciled);
}

// API

export function apiMarkReconciled({ ids }) {
  return request('/applications/interviews/failed', {
    method: 'POST',
    body: {
      ids,
    },
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchMarkReconciled,

  // optionally we may
  // api: {
  //   getAccount: apiGetAccount
  // }
};
