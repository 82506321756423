import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const PsnPrjGoogleCalendarSchema = z.object({
  person_id: z.string(),
  prj_google_calendar_id: z.number(),
  acl_id: z.string(),
  dt_created: dateString,
});

export type PsnPrjGoogleCalendar = z.infer<typeof PsnPrjGoogleCalendarSchema>;
