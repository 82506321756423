import { Tooltip, Typography } from 'antd';

import moment from 'moment';

const formatRelativeDate = (date: Date) => {
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  if (diffInSeconds < 60) {
    return 'Just now';
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < 172800) {
    return 'Yesterday';
  } else {
    return moment(date).format('YYYY-MM-DD');
  }
};

export const NotificationDate = ({ date }: { date: Date }) => (
  <Tooltip title={moment(date).format('YYYY-MM-DD HH:mm')}>
    <Typography.Text type="secondary">{formatRelativeDate(date)}</Typography.Text>
  </Tooltip>
);

export const getDueDateTagColor = (dueDate: string): string => {
  const today = moment().startOf('day');
  const dueMoment = moment(dueDate).startOf('day');

  if (dueMoment.isSame(today)) {
    return 'gold';
  } else if (dueMoment.isBefore(today)) {
    return 'red';
  } else {
    return 'geekblue';
  }
};
