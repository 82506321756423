import { z } from 'zod';

import { Endpoint, Method } from '../../infrastructure';
import { PreLead, PreLeadMetaSchema, PreLeadSkillSchema } from './base';

export const CreateClientWithPreLeadRequestSchema = PreLeadMetaSchema.pick({
  name: true,
  from_ymonth: true,
  month_duration: true,
  team_size_range: true,
  rev_rate_range: true,
  pct_confidence: true,
})
  .extend({
    client_name: z.string(),
  })
  .partial({
    from_ymonth: true,
    month_duration: true,
    team_size_range: true,
    rev_rate_range: true,
    pct_confidence: true,
  });
export type CreateClientWithPreLeadRequest = z.infer<typeof CreateClientWithPreLeadRequestSchema>;

export type CreateClientWithPreLeadResponse = {
  client: {
    client_id: string;
    name: string;
    is_pre_client: boolean;
  };
  preLead: PreLead;
};

export type CreateClientWithPreLeadEndpoint = Endpoint<
  Method.POST,
  '/api/v3/pre-leads/with-client',
  CreateClientWithPreLeadResponse,
  undefined,
  undefined,
  CreateClientWithPreLeadRequest
>;
