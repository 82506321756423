import { all, fork, call, put, takeLatest, select } from 'redux-saga/effects';

import { constants } from '@redux/modules/reports/application-progress';
import { constants as jobConstants } from '@redux/jobs';

import * as api from '@redux/api/applications-progress';

function* getApplicationsByCountryGroup(action) {
  try {
    const response = yield call(api.getSummaryByCountryGroup, action);
    yield put({
      type: constants.APPLICATIONS_PROGRESS_REPORT_GET_COUNTRIES.SUCCESS,
      data: response,
    });
  } catch (e) {
    yield put({
      type: constants.APPLICATIONS_PROGRESS_REPORT_GET_COUNTRIES.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetApplicationsByCountryGroup() {
  yield takeLatest(
    constants.APPLICATIONS_PROGRESS_REPORT_GET_COUNTRIES.REQUEST,
    getApplicationsByCountryGroup,
  );
}

function* getApplicationsByJob(action) {
  try {
    const response = yield call(api.getSummaryByJob, action);
    yield put({ type: constants.APPLICATIONS_PROGRESS_REPORT_GET_JOBS.SUCCESS, data: response });
  } catch (e) {
    yield put({
      type: constants.APPLICATIONS_PROGRESS_REPORT_GET_JOBS.FAILURE,
      error: e.message || e,
    });
  }
}

function* refetchApplicationsByJob() {
  try {
    const query = yield select((state) => state.applicationProgressReport.activeQuery);
    const response = yield call(api.getSummaryByJob, query);
    yield put({ type: constants.APPLICATIONS_PROGRESS_REPORT_GET_JOBS.SUCCESS, data: response });
  } catch (e) {
    yield put({
      type: constants.APPLICATIONS_PROGRESS_REPORT_GET_JOBS.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchUpdateJobStatus() {
  yield takeLatest(jobConstants.UPDATE_JOB_STATUS.SUCCESS, refetchApplicationsByJob);
}

function* watchMoveJobApplications() {
  yield takeLatest(jobConstants.MOVE_JOB_APPLICATIONS.SUCCESS, refetchApplicationsByJob);
}

function* watchGetApplicationsByJob() {
  yield takeLatest(constants.APPLICATIONS_PROGRESS_REPORT_GET_JOBS.REQUEST, getApplicationsByJob);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetApplicationsByCountryGroup),
    fork(watchGetApplicationsByJob),
    fork(watchUpdateJobStatus),
    fork(watchMoveJobApplications),
  ]);
}
