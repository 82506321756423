import { z } from 'zod';
import { Endpoint, FilterOperations, Method, dateString, makeSorter } from '../../infrastructure';

export const AvailabilityReportLatestAssignmentSchema = z.object({
  assignment: z.object({
    assignment_id: z.string(),
    dtstart: dateString,
    dtend: dateString,
    position_id: z.string(),
    project_id: z.string(),
    person_id: z.string(),
    enddatecertainty: z.string().nullable(),
    dt_opp_extension: dateString.nullable(),
  }),
  project: z.object({
    project_id: z.string(),
    name: z.string(),
    client_id: z.string(),
    dtplannedstart: dateString,
    dtplannedend: dateString,
    islead: z.boolean(),
  }),
  client: z.object({
    client_id: z.string(),
    name: z.string(),
  }),
});
export type AvailabilityReportLatestAssignment = z.infer<
  typeof AvailabilityReportLatestAssignmentSchema
>;

export const AvailabilityReportDeepSkillSchema = z.object({
  psn_deep_skill_id: z.number(),
  name: z.string(),
  display_name: z.string(),
  description: z.string().nullable(),
  is_favorite: z.boolean(),
  experience: z.number(),
});
export type AvailabilityReportDeepSkill = z.infer<typeof AvailabilityReportDeepSkillSchema>;

export const AvailabilityReportItemSchema = z.object({
  person_id: z.string(),
  name: z.string(),
  email: z.string(),
  dthired: dateString,
  dtstart: dateString,
  timezone: z.string(),
  role: z.string(),
  gravatar_url: z.string(),
  country: z.string(),
  region: z.string(),
  current_salary: z.number().optional(),
  department: z.object({
    name: z.string(),
    department_id: z.string(),
    isbillable: z.boolean(),
  }),
  latest_assignments: z.array(AvailabilityReportLatestAssignmentSchema),
  deep_skills: z.array(AvailabilityReportDeepSkillSchema),
  available_date: dateString,
  dtmr_time_off: z.array(z.array(z.string(), z.string())),
  active_tags: z.array(z.string()),
  likely_extend: z.boolean(),
});
export type AvailabilityReportItem = z.infer<typeof AvailabilityReportItemSchema>;

export const AvailabilitySkillFilterItemSchema = z.object({
  psn_deep_skill_id: z.string(),
  is_favorite: z.coerce.number().nullish(),
  experience: z.coerce.number().nullish(),
});
export type AvailabilitySkillFilterItem = z.infer<typeof AvailabilitySkillFilterItemSchema>;

export const AvailabilityReportFiltersSchema = z.object({
  //Required
  period_start: z.record(z.enum([FilterOperations.EQUAL]), dateString),
  period_end: z.record(z.enum([FilterOperations.EQUAL]), dateString),
  minimum_availability: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),

  //Optional
  end_date_certainty: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  department_ids: z.record(z.enum([FilterOperations.EQUAL]), z.array(z.string())).optional(),
  skills_id: z
    .record(z.enum([FilterOperations.EQUAL]), z.array(AvailabilitySkillFilterItemSchema))
    .optional(),
  likely_extended: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
});

export type AvailabilityReportFilters = z.infer<typeof AvailabilityReportFiltersSchema>;

export const AvailabilityReportSorterSchema = makeSorter(
  z.enum(['name', 'current_salary', 'available_date']),
);
export type AvailabilityReportSorter = z.infer<typeof AvailabilityReportSorterSchema>;

export const AvailabilityReportQuerySchema = z.object({
  filters: AvailabilityReportFiltersSchema.optional(),
  sorter: AvailabilityReportSorterSchema.optional(),
});
export type AvailabilityReportQuery = z.infer<typeof AvailabilityReportQuerySchema>;

export type AvailabilityReportResponse = AvailabilityReportItem[];

export type AvailabilityReportEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/availability-report',
  AvailabilityReportResponse,
  undefined,
  AvailabilityReportQuery,
  undefined
>;
