import request from '@shared/request';

export function getAll() {
  return request('/roles', {
    method: 'GET',
  });
}

export function addEmail({ id, email }) {
  return request(`/roles/${id}/add-email`, {
    method: 'PUT',
    body: {
      email,
    },
  });
}

export function removeEmail({ id, email }) {
  return request(`/roles/${id}/remove-email`, {
    method: 'PUT',
    body: {
      email,
    },
  });
}
