import { z } from 'zod';
import { BaseSchemaDW } from '../structure';

export const SkillGroupDWSchema = z
  .object({
    mongo_group_id: z.string(),
    group_id: z.number().nullable(),
    group_name: z.string(),
  })
  .merge(BaseSchemaDW);
export type SkillGroupDW = z.infer<typeof SkillGroupDWSchema>;

export const SkillGroupMongoDWSchema = z.object({
  _id: z.string(),
  group: z.string(),
  color: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
});
export type SkillGroupMongoDW = z.infer<typeof SkillGroupMongoDWSchema>;
