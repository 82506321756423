import { all, fork, call, put, takeEvery } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';

import { constants } from '@redux/modules/questions';
import * as api from '@redux/api/questions';
import history from '@src/history';

function* getAll(action) {
  try {
    const response = yield call(api.getAllQuestions, action);
    yield put({ type: constants.QUESTIONS_GET_ALL.SUCCESS, questions: response.data });
  } catch (e) {
    yield put({
      type: constants.QUESTIONS_GET_ALL.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchGetAll() {
  yield takeEvery(constants.QUESTIONS_GET_ALL.REQUEST, getAll);
}

function* create(action) {
  try {
    yield call(api.createQuestion, action);
    yield put({ type: constants.QUESTIONS_CREATE.SUCCESS });
    const response = yield call(api.getAllQuestions, action);
    yield put({ type: constants.QUESTIONS_GET_ALL.SUCCESS, questions: response.data });
    history.push('/admin/questions');
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(response);
    }
  } catch (e) {
    yield put({
      type: constants.QUESTIONS_CREATE.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(e.message || e);
    }
  }
}
function* watchCreate() {
  yield takeEvery(constants.QUESTIONS_CREATE.REQUEST, create);
}

function* update(action) {
  try {
    yield call(api.updateQuestion, action);
    yield put({ type: constants.QUESTIONS_UPDATE.SUCCESS });
    const response = yield call(api.getAllQuestions, action);
    yield put({ type: constants.QUESTIONS_GET_ALL.SUCCESS, questions: response.data });
    history.push('/admin/questions');
  } catch (e) {
    yield put({
      type: constants.QUESTIONS_UPDATE.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchUpdate() {
  yield takeEvery(constants.QUESTIONS_UPDATE.REQUEST, update);
}

function* deleteQuestion(action) {
  try {
    yield call(api.deleteQuestion, action);
    yield put({ type: constants.QUESTIONS_DELETE.SUCCESS });
    const response = yield call(api.getAllQuestions, action);
    yield put({ type: constants.QUESTIONS_GET_ALL.SUCCESS, questions: response.data });
    history.push('/admin/questions');
  } catch (e) {
    yield put({
      type: constants.QUESTIONS_DELETE.FAILURE,
      error: e.message || e,
    });
  }
}
function* watchDelete() {
  yield takeEvery(constants.QUESTIONS_DELETE.REQUEST, deleteQuestion);
}

function* uploadVideo(action) {
  try {
    const response = yield call(api.uploadVideo, action);

    yield put({ type: constants.QUESTIONS_UPLOAD_VIDEO.SUCCESS });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(response);
    }
  } catch (e) {
    yield put({
      type: constants.QUESTIONS_UPLOAD_VIDEO.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(e.message || e);
    }
  }
}
function* watchUploadVideo() {
  yield takeEvery(constants.QUESTIONS_UPLOAD_VIDEO.REQUEST, uploadVideo);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetAll),
    fork(watchCreate),
    fork(watchUpdate),
    fork(watchDelete),
    fork(watchUploadVideo),
  ]);
}
