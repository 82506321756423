/* eslint-disable max-statements */
import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';
import get from 'lodash/get';
import find from 'lodash/find';
import remove from 'lodash/remove';
import moment from 'moment';
// Constants
export const constants = {
  TODO_GET: createSagaAction('TODO_GET'),
  TODO_CREATE: createSagaAction('TODO_CREATE'),
  TODO_UPDATE: createSagaAction('TODO_UPDATE'),
  TODO_DELETE: createSagaAction('TODO_DELETE'),
  FOLLOW_UP_MODAL_OPEN: createSagaAction('FOLLOW_UP_MODAL_OPEN'),
  FOLLOW_UP_MODAL_CLOSE: createSagaAction('FOLLOW_UP_MODAL_CLOSE'),
  TODO_WS_EVENT: createSagaAction('TODO_WS_EVENT'),
  TODO_SET_OPTIONS: createSagaAction('TODO_SET_OPTIONS'),
};

// Action Creators
export const actions = {
  get: (options) => ({
    type: constants.TODO_GET.REQUEST,
    options,
  }),
  create: (applicationId, todo, onSuccess, onFail) => ({
    type: constants.TODO_CREATE.REQUEST,
    applicationId,
    todo,
    onSuccess,
    onFail,
  }),
  update: (applicationId, todo, onSuccess, onFail) => ({
    type: constants.TODO_UPDATE.REQUEST,
    applicationId,
    todo,
    onSuccess,
    onFail,
  }),
  remove: (todoId, onSuccess, onFail) => ({
    type: constants.TODO_DELETE.REQUEST,
    todoId,
    onSuccess,
    onFail,
  }),
  openFollowUpModal: (application, todo) => ({
    type: constants.FOLLOW_UP_MODAL_OPEN.REQUEST,
    application,
    todo,
  }),
  closeFollowUpModal: () => ({
    type: constants.FOLLOW_UP_MODAL_CLOSE.REQUEST,
  }),
  processSocket: (data) => ({
    type: constants.TODO_WS_EVENT.REQUEST,
    data,
  }),
  setOptions: (data) => ({
    type: constants.TODO_SET_OPTIONS.REQUEST,
    data,
  }),
};

// Reducer
export const initialState = {
  isLoading: false,
  error: null,
  isFollowUpModalOpen: false,
  application: null,
  todo: null,
  results: [],
  options: {},
  saveSuccess: false,
};

const ACTION_HANDLERS = {
  // TODO_GET
  [constants.TODO_GET.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.TODO_GET.FAILURE]: (state) => {
    return { ...state, isLoading: false };
  },
  [constants.TODO_GET.SUCCESS]: (state, action) => {
    return { ...state, isLoading: false, results: action.results };
  },
  // TODO_CREATE
  [constants.TODO_CREATE.REQUEST]: (state) => {
    return { ...state, isLoading: true, saveSuccess: false };
  },
  [constants.TODO_CREATE.FAILURE]: (state, action) => {
    return { ...state, isLoading: false, error: action.error };
  },
  [constants.TODO_CREATE.SUCCESS]: (state) => {
    return { ...state, isLoading: false, error: null, saveSuccess: true };
  },
  // TODO_UPDATE
  [constants.TODO_UPDATE.REQUEST]: (state) => {
    return { ...state, isLoading: true, saveSuccess: false };
  },
  [constants.TODO_UPDATE.FAILURE]: (state, action) => {
    return { ...state, isLoading: false, error: action.error };
  },
  [constants.TODO_UPDATE.SUCCESS]: (state) => {
    return { ...state, isLoading: false, error: null, saveSuccess: true };
  },
  // TODO_DELETE
  [constants.TODO_DELETE.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.TODO_DELETE.FAILURE]: (state, action) => {
    return { ...state, isLoading: false, error: action.error };
  },
  [constants.TODO_DELETE.SUCCESS]: (state) => {
    return { ...state, isLoading: false, error: null };
  },
  // FOLLOW UP MODAL
  [constants.FOLLOW_UP_MODAL_OPEN.REQUEST]: (state, action) => {
    return {
      ...state,
      isFollowUpModalOpen: true,
      application: action.application,
      todo: action.todo,
      error: null,
    };
  },
  [constants.FOLLOW_UP_MODAL_CLOSE.REQUEST]: (state) => {
    return {
      ...state,
      isFollowUpModalOpen: false,
      application: null,
      todo: null,
      error: null,
    };
  },
  [constants.TODO_SET_OPTIONS.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.TODO_SET_OPTIONS.FAILURE]: (state, action) => {
    return { ...state };
  },
  // eslint-disable-next-line max-statements
  [constants.TODO_SET_OPTIONS.SUCCESS]: (state, { options }) => {
    const newState = { ...state };
    newState.options = options.state;
    return newState;
  },
  [constants.TODO_WS_EVENT.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.TODO_WS_EVENT.FAILURE]: (state, action) => {
    return { ...state };
  },
  // eslint-disable-next-line max-statements
  // eslint-disable-next-line complexity
  [constants.TODO_WS_EVENT.SUCCESS]: (state, { socketEvent }) => {
    const event = get(socketEvent, 'event');
    const createdOrUpdated = get(event, 'created') || get(event, 'updated');
    const deleted = get(event, 'deleted');
    const initialOptions = {
      showDoneItems: false,
      showFutureItems: false,
      assignee: { key: '-1' },
    };
    const newState = { ...state };
    newState.options = { ...state.options };
    newState.results = [...state.results];
    //Building options to filter
    initialOptions.showDoneItems = newState.options.showDoneItems
      ? newState.options.showDoneItems
      : false;
    initialOptions.showFutureItems = newState.options.showFutureItems
      ? newState.options.showFutureItems
      : false;
    initialOptions.assignee = newState.options.assignee ? newState.options.assignee : { key: '-1' };

    const application = get(event, 'application') || null;
    const todoItem = get(event, 'todoItem') || null;
    const todoState = todoItem
      ? find(newState.results, (element) => {
          return element._id === todoItem._id;
        })
      : null;

    const removeTodo = () => {
      if (todoState && todoItem) {
        remove(newState.results, { _id: todoItem._id });
      }
    };
    const updateTodo = () => {
      if (todoItem) {
        if (
          todoItem.assignee._id === initialOptions.assignee.key ||
          initialOptions.assignee.key === '-1'
        ) {
          removeTodo();
          newState.results.push(todoItem);
        } else {
          removeTodo();
        }
      }
    };
    if (deleted) {
      if (deleted.includes('todo')) {
        updateTodo();
        //If are removing the application, then for each todo, will remove from state
      } else if (deleted.includes('application')) {
        const todosApp = get(application, 'todos');
        todosApp.forEach((todo) => {
          const todoInState = find(newState.results, { _id: todo._id });
          if (todoInState) {
            remove(newState.results, { _id: todo._id });
          }
        });
      }
    } else if (createdOrUpdated) {
      if (createdOrUpdated.includes('todo')) {
        const matchFuture =
          moment(todoItem.dueDateTime).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD');
        if (todoItem.status === 1 || matchFuture) {
          if (todoItem.status === 1 && matchFuture) {
            if (initialOptions.showDoneItems && initialOptions.showFutureItems) {
              updateTodo();
            } else {
              removeTodo();
            }
          } else if (todoItem.status === 1 && !matchFuture) {
            if (initialOptions.showDoneItems) {
              updateTodo();
            } else {
              removeTodo();
            }
          } else if (todoItem.status !== 1 && matchFuture) {
            if (initialOptions.showFutureItems) {
              updateTodo();
            } else {
              removeTodo();
            }
          }
        } else {
          updateTodo();
        }
      }
    }
    return newState;
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : { ...state, isLoading: false };
});
