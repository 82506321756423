import { z } from 'zod';
import { Lead } from './base';
import { Endpoint, Method } from '../../infrastructure';

export const LeadSetIsSFLockedRequestSchema = z.object({
  is_sf_locked: z.boolean(),
});
export type LeadSetIsSFLockedRequest = z.infer<typeof LeadSetIsSFLockedRequestSchema>;

export const LeadSetIsSFLockedParamsSchema = z.object({
  project_id: z.string(),
});
export type LeadSetIsSFLockedParams = z.infer<typeof LeadSetIsSFLockedParamsSchema>;

export type LeadSetIsSFLockedResponse = Lead;

export type LeadSetIsSFLockedEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/sql-leads/${LeadSetIsSFLockedParams['project_id']}/sf-is-locked`,
  LeadSetIsSFLockedResponse,
  LeadSetIsSFLockedParams,
  undefined,
  LeadSetIsSFLockedRequest
>;
