import { z } from 'zod';
import { dateString, Endpoint, Method } from '../../infrastructure';

export const ProjectEarlyTerminationRequestSchema = z.object({
  dt_early_termination: dateString,
});
export type ProjectEarlyTerminationRequest = z.infer<typeof ProjectEarlyTerminationRequestSchema>;

export const ProjectEarlyTerminationParamsSchema = z.object({
  project_id: z.string(),
});
export type ProjectEarlyTerminationParams = z.infer<typeof ProjectEarlyTerminationParamsSchema>;

export type ProjectEarlyTerminationResponse = void;
export type ProjectEarlyTerminationEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/sql-projects/early-termination/${ProjectEarlyTerminationParams['project_id']}`,
  ProjectEarlyTerminationResponse,
  ProjectEarlyTerminationParams,
  undefined,
  ProjectEarlyTerminationRequest
>;
