import { z } from 'zod';
import { AppActionCreateSchema } from '../crud';
import { AppAction } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const AppActionCreateRequestSchema = AppActionCreateSchema.pick({
  dt_action: true,
  result: true,
  action_name: true,
  application_id: true,
  action_recruiter_id: true,
})
  .extend({
    action_recruiter_email: z.string().nullable(),
  })
  .partial({
    result: true,
    action_recruiter_email: true,
    action_recruiter_id: true,
  });
export type AppActionCreateRequest = z.infer<typeof AppActionCreateRequestSchema>;

export type AppActionCreateResponse = AppAction | null;

export type AppActionCreateEndpoint = Endpoint<
  Method.GET,
  '/api/actions',
  AppActionCreateResponse,
  undefined,
  undefined,
  AppActionCreateRequest
>;
