import { all, fork } from 'redux-saga/effects';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import merge from 'lodash/merge';
import { createReducer } from '@shared/reducers';

import GetLeads from './get-leads';
import GetLeadComments from './get-lead-comments';
import GetLeadAssignments from './get-lead-assignments';
import GetLeadById from './get-lead-by-id';
import GetAllLeadsAssignments from './get-all-leads-assignments';
import UpdateLeadName from './update-lead-name';
import UpdateLeadStartDate from './update-lead-start-date';
import UpdateApplicationExcitement from './update-application-excitement';
import UpdateApplicationAssignment from './update-application-assignment';
import UpdateApplicationAssignments from './update-application-assignments';
import UpdateApplicationBrainsAssignment from './update-application-brains-assignment';
import RemoveApplicationAssignment from './remove-application-assignment';
import ProcessSocketUpdate from './process-socket-update';
import OnChangeSkillTagFilter from './on-change-skill-tag-filter';
import OnClearSkillTagFilter from './on-clear-skill-tag-filter';
import OnClearAllSkillTagFilter from './on-clear-all-skill-tag-filter';
import UpdatePositionHireGroup from './update-position-hire-group';
import GetAllLeadComments from './get-all-lead-comments';
import GetApplications from './get-applications';

const modules = [
  GetLeads,
  GetLeadById,
  GetLeadComments,
  GetLeadAssignments,
  GetAllLeadsAssignments,
  UpdateLeadName,
  UpdateLeadStartDate,
  UpdateApplicationExcitement,
  UpdateApplicationAssignment,
  UpdateApplicationAssignments,
  UpdateApplicationBrainsAssignment,
  UpdatePositionHireGroup,
  RemoveApplicationAssignment,
  ProcessSocketUpdate,
  OnChangeSkillTagFilter,
  OnClearSkillTagFilter,
  OnClearAllSkillTagFilter,
  GetAllLeadComments,
  GetApplications,
];

// Reducer

export const initialState = {
  isLeadsLoading: false,
  isLeadLoading: false,
  isLeadAssignmentsLoading: false,
  isLeadCommentsLoading: false,
  isAllLeadsAssignmentsLoading: false,
  leadsData: {},
  allLeadsAssignmentsData: null,
  isActionRunning: false,
  leadsDetailsData: {},
  leadsCommentsData: {},
  leadsAssignmentsData: {},
  englishLevels: {
    0: 'English level - not set',
    1: '1 | Native',
    2: '2 | Professional',
    2.5: '2.5 | Almost professional',
    3: '3 | Passable',
  },
  skillTagFilter: [],
  allLeadscomments: [],
};

const ACTION_HANDLERS = reduce(map(modules, 'handlers'), merge, {});

export const reducer = createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLeadsLoading: false };
});

// Actions

export const actions = reduce(map(modules, 'actions'), merge, {});

export const constants = reduce(map(modules, 'constants'), merge, {});

// Root saga

export function* rootSaga() {
  const sagas = map(modules, 'watcher');
  yield all(sagas.map(fork));
}
