import { z } from 'zod';
import { PositionStaffingSchema } from './base';

export const PositionStaffingUpdateSchema = PositionStaffingSchema.pick({
  position_staffing_id: true,
  next_position_staffing_id: true,
  person_id: true,
  assignment_id: true,
  dt_canceled: true,
  dt_changed_rectg: true,
  dt_changed_spec: true,
  dt_filled: true,
  dt_oferred: true,
  dt_recruiting: true,
  dt_changed_dt_staffit: true,
  recruiting_category: true,
  position_specs: true,
  dt_staffit: true,
  status: true,
  closed: true,
}).partial({
  assignment_id: true,
  person_id: true,
  next_position_staffing_id: true,
  dt_canceled: true,
  dt_changed_rectg: true,
  dt_changed_spec: true,
  dt_filled: true,
  dt_oferred: true,
  dt_recruiting: true,
  dt_changed_dt_staffit: true,
  closed: true,
  recruiting_category: true,
  position_specs: true,
  dt_staffit: true,
  status: true,
});

export type PositionStaffingUpdate = z.infer<typeof PositionStaffingUpdateSchema>;
