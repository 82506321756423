import React from 'react';
import { Modal, Collapse } from 'antd';

import './HelpOptions.less';

const Panel = Collapse.Panel;

export const showKeyboardShortCuts = (activeKey) => {
  const modalContent = (
    <Collapse defaultActiveKey={activeKey}>
      <Panel header="Search Applications Page" key="1">
        <p>
          - <strong>Press [C]</strong> to add a new comment to selected application (available with
          1 line selected only)
        </p>
        <p>
          - <strong>Press [S]</strong> to change application status, use [1] - [7] keys to set one
          of interviewing status or mouse click to set any status.
        </p>
        <p>
          - <strong>Press [B]</strong> to book an interview.
        </p>
        <p>
          - <strong>Press [CTRL+C or CMD+C]</strong> to copy selected applications details to
          clipboard.
        </p>
        <p>
          - <strong>Press [SHIFT+E]</strong> to reject the selected applications.
        </p>
        <p>
          - <strong>Press [R]</strong> to set the selected applications as unresponsive.
        </p>
      </Panel>
      <Panel header="Application Page" key="2">
        <p>
          - <strong>Press [SHIFT+E]</strong> to reject the application.
        </p>
        <p>
          - <strong>Press [SHIFT+T]</strong> to open the Send Assessment Popup.
        </p>
        <p>
          - <strong>Press [SHIFT+R]</strong> to set the application as unresponsive.
        </p>
        <p>
          - <strong>Press [SHIFT+H]</strong> to set the application on hold.
        </p>
        <p>
          - <strong>Press [X]</strong> to go back to search applications page.
        </p>
        <p>
          - <strong>Press [RIGHT(→) or K]</strong> to navigate to the next application of the search
          results.
        </p>
        <p>
          - <strong>Press [LEFT(←) or J]</strong> to navigate to the previous application of the
          search results.
        </p>
        {/* <p>
          - <strong>Press [SHIFT+W]</strong> to send Expensive Candidate email to the candidate,
            and set the application status as <strong>Hold, high cost of living</strong>
        </p> */}
      </Panel>
    </Collapse>
  );
  Modal.info({
    title: 'Keyboard Shortcuts',
    className: 'keyboard-shortcuts-modal',
    content: modalContent,
    onOk() {},
  });
};

const HelpOptions = () => {
  return <a onClick={showKeyboardShortCuts}>Keyboard</a>;
};

export default HelpOptions;
