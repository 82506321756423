import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { TimeOffRequest } from '../time_off_request';

export const GetTimeOffHistoryParamsSchema = z.object({
  personId: z.string(),
});

export type GetTimeOffHistoryParams = z.infer<typeof GetTimeOffHistoryParamsSchema>;

export type GetTimeOffHistoryResponse = {
  timeoffHistory: TimeOffRequest[];
};

export type GetTimeOffHistoryEndpoint = Endpoint<
  Method.GET,
  `/api/v2/people/${GetTimeOffHistoryParams['personId']}/timeoffs/history`,
  GetTimeOffHistoryResponse,
  GetTimeOffHistoryParams,
  undefined,
  undefined
>;
