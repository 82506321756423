import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';

import { constants } from '@redux/modules/messages/messages';
import { constants as assignmentReportConstants } from '@redux/modules/reports/assignments-report';
import * as api from '@redux/api/messages';
import { message } from 'antd';

import find from 'lodash/find';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

// GET MESSAGES
function* getMessages(action) {
  try {
    const { data } = yield call(api.getMessages, action);

    yield put({
      type: constants.MESSAGES_GET.SUCCESS,
      data,
    });
    if (isFunction(action.onSuccess)) {
      action.onSuccess(data);
    }
  } catch (e) {
    yield put({
      type: constants.MESSAGES_GET.FAILURE,
      error: e.message || e,
    });
  }
}

// POST MESSAGE
function* postMessage(action) {
  try {
    const message = yield call(api.postMessage, action);

    yield put({
      type: constants.MESSAGE_POST.SUCCESS,
      message: message,
    });

    if (isFunction(action.onSuccess)) {
      action.onSuccess(get(message, '_id'));
    }
  } catch (e) {
    yield put({
      type: constants.MESSAGE_POST.FAILURE,
      error: e.message || e,
    });
  }
}

// DELETE MESSAGE
function* deleteMessage({ messageId, message }) {
  try {
    yield call(api.deleteMessage, messageId);

    yield put({
      type: constants.MESSAGE_DELETE.SUCCESS,
      messageId,
    });

    const skillId = get(message, 'tags.assignment.id');
    const { pinned, lead, assignment, application } = message;
    if (pinned && lead && !assignment && !application) {
      yield put({
        type: assignmentReportConstants.ASSIGNMENTS_REPORT__LEAD_CHANGED_PINNED_COMMENT.REQUEST,
        lead,
      });
    } else if (pinned && lead && skillId && !application) {
      yield put({
        type: assignmentReportConstants.ASSIGNMENTS_REPORT__ASSIGNMENT_CHANGED_PINNED_COMMENT
          .REQUEST,
        skillId,
        leadId: lead,
      });
    }
  } catch (e) {
    message.error('Could not delete comment.', 2);
    yield put({
      type: constants.MESSAGE_DELETE.FAILURE,
      error: e.message || e,
    });
  }
}

// UPDATE MESSAGE
function* updateMessage({ messageId, content, skillId }) {
  try {
    const { data } = yield call(api.updateMessage, messageId, content);

    yield put({
      type: constants.MESSAGE_UPDATE.SUCCESS,
      messageId,
      updatedMessage: data,
    });

    const { pinned, lead, assignment, application } = data;
    if (pinned && lead && !assignment && !application) {
      yield put({
        type: assignmentReportConstants.ASSIGNMENTS_REPORT__LEAD_CHANGED_PINNED_COMMENT.REQUEST,
        comment: content,
        lead,
      });
    } else if (pinned && lead && skillId && !application) {
      yield put({
        type: assignmentReportConstants.ASSIGNMENTS_REPORT__ASSIGNMENT_CHANGED_PINNED_COMMENT
          .REQUEST,
        comment: content,
        skillId,
        leadId: lead,
      });
    }
  } catch (e) {
    message.error('Could not update comment.', 2);
    yield put({
      type: constants.MESSAGE_UPDATE.FAILURE,
      error: e.message || e,
    });
  }
}

// TOGGLE PIN MESSAGE
function* togglePinMessage({ messageId, groupBy }) {
  try {
    const { data } = yield call(api.togglePinMessage, messageId, groupBy);

    yield put({
      type: constants.MESSAGE_TOGGLE_PIN.SUCCESS,
      messages: data,
      groupBy,
    });

    const toggledMessage = find(data, ({ _id }) => _id === messageId);
    const { pinned, application, comment, lead } = toggledMessage || {};
    const skillId = get(toggledMessage, 'tags.assignment.id');

    if (groupBy === 'lead') {
      yield put({
        type: assignmentReportConstants.ASSIGNMENTS_REPORT__LEAD_CHANGED_PINNED_COMMENT.REQUEST,
        comment: pinned ? comment : undefined,
        lead,
      });
    } else if (lead && skillId && !application) {
      yield put({
        type: assignmentReportConstants.ASSIGNMENTS_REPORT__ASSIGNMENT_CHANGED_PINNED_COMMENT
          .REQUEST,
        skillId,
        comment: pinned ? comment : undefined,
        leadId: lead,
        position: toggledMessage.position,
      });
    }
  } catch (e) {
    message.error('Could not toggle pin comment.', 2);
    yield put({
      type: constants.MESSAGE_TOGGLE_PIN.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetMessages() {
  yield takeLatest(constants.MESSAGES_GET.REQUEST, getMessages);
}

export function* watchPostMessage() {
  yield takeEvery(constants.MESSAGE_POST.REQUEST, postMessage);
}

export function* watchDeleteMessage() {
  yield takeEvery(constants.MESSAGE_DELETE.REQUEST, deleteMessage);
}

export function* watchUpdateMessage() {
  yield takeEvery(constants.MESSAGE_UPDATE.REQUEST, updateMessage);
}

export function* watchTogglePinMessage() {
  yield takeEvery(constants.MESSAGE_TOGGLE_PIN.REQUEST, togglePinMessage);
}
