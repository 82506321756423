import { z } from 'zod';

export const StaffingCandidateSlackSearchItemSchema = z.object({
  project_id: z.string(),
  project_name: z.string(),
  position_id: z.string(),
  position_name: z.string(),
  application_id: z.string(),
  name: z.string(),
});
export type StaffingCandidateSlackSearchItem = z.infer<
  typeof StaffingCandidateSlackSearchItemSchema
>;

export const StaffingCandidateSlackSearchFilterSchema = z.object({
  name: z.string().optional(),
});
export type StaffingCandidateSlackSearchFilter = z.infer<
  typeof StaffingCandidateSlackSearchFilterSchema
>;
