import { z } from 'zod';
import { Endpoint, Method } from '../endpoint';
import {
  SorterQuerySchema,
  FiltersQuerySchema,
  PaginationQuerySchema,
  PaginatedResponse,
} from '../query';

export const ReportsQuerySchema = z.object({
  sorter: SorterQuerySchema.optional(),
  filters: FiltersQuerySchema.optional(),
  pagination: PaginationQuerySchema.optional(),
});
export const ReportsCompareResponseSchema = z.object({
  diff: z.array(z.string()),
  html: z.string(),
});
export type ReportsCompareResponse = z.infer<typeof ReportsCompareResponseSchema>;

export type ReportsQuery = z.infer<typeof ReportsQuerySchema>;

export type CSVEndpoint<
  URL extends string,
  QUERY extends ReportsQuery,
  PARAMS extends {} = {},
> = Endpoint<Method.GET, URL, string, PARAMS, QUERY, undefined>;

export type CSVRequest = () => Promise<string>;

// Node env doesn't have Blob
export interface Blob {
  readonly size: number;
  readonly type: string;
  prototype: Blob;
  new (): Blob;
  arrayBuffer(): Promise<ArrayBuffer>;
  slice(start?: number, end?: number, contentType?: string): Blob;
  // @ts-ignore not sure what the error is here
  stream(): ReadableStream<Uint8Array>;
  text(): Promise<string>;
}
export type BlobRequest = () => Promise<Blob>;

export type CSVCompareRequest = () => Promise<ReportsCompareResponse>;

export type ReportResponse<ENTITY> = PaginatedResponse<ENTITY>;
