import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const TimeOffRequestDeleteParamsSchema = z.object({
  time_off_request_id: z.coerce.number(),
});
export type TimeOffRequestDeleteParams = z.infer<typeof TimeOffRequestDeleteParamsSchema>;

export type TimeOffRequestDeleteResponse = void;

export type TimeOffRequestDeleteEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/time-off-requests/${TimeOffRequestDeleteParams['time_off_request_id']}`,
  TimeOffRequestDeleteResponse,
  TimeOffRequestDeleteParams,
  undefined,
  undefined
>;
