import { Endpoint, Method } from '../../../infrastructure';
import { InterviewRequest } from '../base';
import { InterviewRequestCreateSchema } from '../crud';
import { z } from 'zod';

export const InterviewRequestCreateRequestSchema = InterviewRequestCreateSchema.pick({
  applicant_id: true,
  application_id: true,
  ask_recruiter_id: true,
  num_days: true,
  req_recruiter_id: true,
  stage: true,
});

export type InterviewRequestCreateRequest = z.infer<typeof InterviewRequestCreateRequestSchema>;

export type InterviewRequestCreateResponse = InterviewRequest;

export type InterviewRequestCreateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/interview-requests`,
  InterviewRequestCreateResponse,
  undefined,
  undefined,
  InterviewRequestCreateRequest
>;
