import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const PsnDepartmentSchema = z.object({
  psn_department_id: z.number(),
  person_id: z.string(),
  department_id: z.string(),
  dt_start: dateString,
  dt_end: dateString.nullable(),
  dt_create: dateString.nullable(),
  dt_update: dateString.nullable(),
});
export type PsnDepartment = z.infer<typeof PsnDepartmentSchema>;
