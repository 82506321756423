import request from '../../shared/request';

export function updateLeadShortNote({ leadId, skillId, leadShortNote, skillShortNote }) {
  return request('/leads/update-short-note', {
    method: 'PUT',
    body: {
      leadId,
      skillId,
      leadShortNote,
      skillShortNote,
    },
  });
}

export function getLeadByID({ leadId }) {
  return request(`/leads/${leadId}`, {
    method: 'GET',
  });
}

export function updateLeadNotes(lead) {
  return request('/leads/update-notes', {
    method: 'PUT',
    body: {
      ...lead,
    },
  });
}

export function removeBrainsAssign(leadId, positionId, assignment) {
  return request(`/leads/${leadId}/positions/${positionId}/assign`, {
    method: 'DELETE',
    body: { assignment },
  });
}

export function addBrainsAssign(leadId, positionId, personId) {
  return request(`/leads/${leadId}/positions/${positionId}/assign`, {
    method: 'PUT',
    body: { personId },
  });
}
