import { z } from 'zod';
import { dateString, Endpoint, Method } from '../../infrastructure';

export const CreateMultiplePositionsRequestSchema = z.object({
  project: z.string(),
  skillIds: z.string().array(),
  skills: z.string().array(),
  revenueAmount: z.number(),
  qty: z.number(),
  startDate: dateString,
  endDate: dateString,
  seniority: z.string().nullish(),
  englishLevel: z.number().nullish(),
  targetCost: z.number().nullish(),
});

export type CreateMultiplePositionsRequest = z.infer<typeof CreateMultiplePositionsRequestSchema>;

export type CreateMultiplePositionsResponse = Array<string>;

export type CreateMultiplePositionsEndpoint = Endpoint<
  Method.POST,
  `/api/v2/positions/multiple-positions`,
  CreateMultiplePositionsResponse,
  undefined,
  undefined,
  CreateMultiplePositionsRequest
>;
