import request from '@shared/request';

// JOB TITLES
export function getJobTitles() {
  return request('/job-titles', {
    method: 'GET',
  });
}

export function createJobTitle({ jobTitle }) {
  return request('/job-titles', {
    method: 'POST',
    body: {
      ...jobTitle,
    },
  });
}

export function updateJobTitle({ _id, jobTitle }) {
  return request(`/job-titles/${_id}`, {
    method: 'PUT',
    body: {
      ...jobTitle,
    },
  });
}

export function deleteJobTitle({ _id }) {
  return request(`/job-titles/${_id}`, {
    method: 'DELETE',
  });
}

export function sortJobTitles({ jobTitlesIds }) {
  return request('/job-titles/sort', {
    method: 'POST',
    body: {
      jobTitlesIds: jobTitlesIds || [],
    },
  });
}
