import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';
import { setActions } from '@redux/modules/_utils';
import ACTION_MAP from './actions/actionsMap';
import actionDefinitions from './actions/actionsDefinitions';

import values from 'lodash/values';
import map from 'lodash/map';
import zipObject from 'lodash/zipObject';
import mapValues from 'lodash/mapValues';
import defaults from 'lodash/defaults';

const _keys = values(ACTION_MAP);
const _values = map(_keys, (key) => createSagaAction(key));

// Constants
export const constants = zipObject(_keys, _values);

// Action Creators
export const actions = mapValues(ACTION_MAP, (key) => {
  return (data) => defaults({ type: constants[key].REQUEST }, data);
});

// Reducer
export const initialState = {
  applications: [],
  isLoading: false,
  error: null,
};

const ACTION_HANDLERS = setActions(actionDefinitions, constants);

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
