import { all, fork } from 'redux-saga/effects';

import { watchGetMetricStatusMap } from './metrics';

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  const sagas = [watchGetMetricStatusMap].map(fork);

  yield all(sagas);
}
