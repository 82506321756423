import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const PaymentProviderMonthSchema = z.object({
  ymonth: z.number(),
  payment_provider_id: z.number(),
  last_business_day: dateString.nullable(),
  cut_off_day: dateString.nullable(),
  dt_created: dateString,
  dt_updated: dateString.nullable(),
});
export type PaymentProviderMonth = z.infer<typeof PaymentProviderMonthSchema>;
