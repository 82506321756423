import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE
const constants = {
  GET_UTM_PARAMS: createSagaAction('GET_UTM_PARAMS'),
};

const actions = {
  get: (data) => ({
    ...data,
    type: constants.GET_UTM_PARAMS.REQUEST,
  }),
};

const handlers = {
  [constants.GET_UTM_PARAMS.REQUEST]: (state) => {
    return { ...state, isLoading: true };
  },
  [constants.GET_UTM_PARAMS.SUCCESS]: (state, { data }) => {
    return { ...state, isLoading: false, data };
  },
  [constants.GET_UTM_PARAMS.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA
function* sagaGetUtmParams(action) {
  try {
    const { data } = yield call(apiGetUtmParams, action);
    yield put({ type: constants.GET_UTM_PARAMS.SUCCESS, data });
  } catch (e) {
    yield put({
      type: constants.GET_UTM_PARAMS.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetUtmParams() {
  yield takeLatest(constants.GET_UTM_PARAMS.REQUEST, sagaGetUtmParams);
}

// API
function apiGetUtmParams() {
  return request('/utm-param', {
    method: 'GET',
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchGetUtmParams,
};
