import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

import isFunction from 'lodash/isFunction';

// MODULE

const constants = {
  INTERVIEWS_REQUESTED_GET: createSagaAction('INTERVIEWS_REQUESTED_GET'),
};

const actions = {
  getInterviewsRequested: (data) => ({
    ...data,
    type: constants.INTERVIEWS_REQUESTED_GET.REQUEST,
  }),
};

const handlers = {
  [constants.INTERVIEWS_REQUESTED_GET.REQUEST]: (state, action) => {
    return { ...state, isLoading: true, resultsRequested: [] };
  },
  [constants.INTERVIEWS_REQUESTED_GET.SUCCESS]: (state, { data }) => {
    return { ...state, isLoading: false, resultsRequested: data };
  },
  [constants.INTERVIEWS_REQUESTED_GET.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

export function* sagaGetInterviewsRequested(action) {
  try {
    const data = yield call(apiGetInterviewsRequested, action);
    yield put({ type: constants.INTERVIEWS_REQUESTED_GET.SUCCESS, data });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.INTERVIEWS_REQUESTED_GET.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchGetInterviewsRequested() {
  yield takeLatest(constants.INTERVIEWS_REQUESTED_GET.REQUEST, sagaGetInterviewsRequested);
}

// API

export function apiGetInterviewsRequested({ urlParams }) {
  return request(`/applications/interviews?${urlParams}`, {
    method: 'GET',
  });
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchGetInterviewsRequested,

  // optionally we may
  // api: {
  //   getAccount: apiGetAccount
  // }
};
