import request from '../../shared/request';
import qs from 'query-string';

// API

export function getLeads() {
  return request('/assignments/report', {
    method: 'GET',
  });
}

// STATUS APPLICATIONS
export function getStatusApplications({ brainsIDs }) {
  return request(
    `/applications/people-statuses?${qs.stringify(
      { brainsIDs },
      {
        arrayFormat: 'bracket',
      },
    )}`,
    {
      method: 'GET',
    },
  );
}

// ASSIGN TO BRAINS POSITION
export function assignOnBrains({ applicationId, positionId, leadId }) {
  return request(`/applications/${applicationId}/assign-on-brains`, {
    method: 'PUT',
    body: {
      positionId,
      leadId,
    },
  });
}

export function assignPersonOrApplicationOnBrains({ personId, applicationId, positionId, leadId }) {
  return request(`/applications/assign-on-brains`, {
    method: 'POST',
    body: {
      positionId,
      leadId,
      applicationId,
      personId,
    },
  });
}
