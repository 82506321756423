import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  FEATURE_FLAGS_GET: createSagaAction('FEATURE_FLAGS_GET'),
};

// Action Creators

export const actions = {
  getFeatureFlags: () => ({
    type: constants.FEATURE_FLAGS_GET.REQUEST,
  }),
};

// Reducer

const initialState = {
  featureFlags: null,
  loadingFeatureFlags: false,
};

export default createReducer(initialState, (state, action) => {
  switch (action.type) {
    case constants.FEATURE_FLAGS_GET.REQUEST: {
      return {
        ...state,
        error: null,
        loadingFeatureFlags: true,
      };
    }

    case constants.FEATURE_FLAGS_GET.SUCCESS: {
      const { features: featureFlags } = action;
      return {
        ...state,
        featureFlags,
        error: null,
        loadingFeatureFlags: false,
      };
    }

    case constants.FEATURE_FLAGS_GET.FAILURE: {
      return {
        ...state,
        error: action.message,
        loadingFeatureFlags: false,
      };
    }

    default:
      return state;
  }
});
