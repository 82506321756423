import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const PDFByRequestsSchema = z.object({
  pdf_id: z.coerce.number(),
  person: z.object({
    _id: z.string(),
    name: z.string(),
    gravatarURL: z.string(),
  }),
  year: z.number(),
  amount: z.number().finite(),
  reason: z.string(),
  status: z.string(),
  receipt_url: z.string().nullable(),
  reimbursement_date: dateString.nullable(),
  dtcreated: dateString.nullable(),
  dtupdated: dateString.nullable(),
});
export type PDFByRequests = z.infer<typeof PDFByRequestsSchema>;

export const PDFByPeopleSchema = z.object({
  _id: z.coerce.number(),
  person: z.object({
    _id: z.string(),
    name: z.string(),
    gravatarURL: z.string(),
  }),
  amount_spent: z.number().finite(),
  amount_available: z.number().finite(),
});
export type PDFByPeople = z.infer<typeof PDFByPeopleSchema>;

export const PDFLimitSchema = z.object({
  pdf_request_limit_id: z.coerce.number(),
  amount: z.number().finite(),
  year: z.number(),
  created_at: dateString.nullable(),
});
export type PDFLimit = z.infer<typeof PDFLimitSchema>;
