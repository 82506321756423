import { call, put, takeLatest } from 'redux-saga/effects';

import { createSagaAction } from '@shared/sagas';
import request from '@shared/request';

// MODULE
const constants = {
  LEAD_POSITION_COST_UPDATE: createSagaAction('LEAD_POSITION_COST_UPDATE'),
};

const actions = {
  updatePositionTargetCost: ({ lead, position, field, targetCost }, onSuccess = null) => ({
    type: constants.LEAD_POSITION_COST_UPDATE.REQUEST,
    data: {
      lead,
      position,
      field,
      targetCost,
    },
    onSuccess,
  }),
};

const handlers = {
  [constants.LEAD_POSITION_COST_UPDATE.REQUEST]: (state) => {
    return {
      ...state,
      isLoading: true,
      updateSuccessMessage: null,
      updateErrorMessage: null,
      error: null,
    };
  },
  [constants.LEAD_POSITION_COST_UPDATE.SUCCESS]: (state, action) => {
    action?.onSuccess?.();
    return {
      ...state,
      error: null,
      updateSuccessMessage: null,
      updateErrorMessage: null,
      isLoading: false,
    };
  },
  [constants.LEAD_POSITION_COST_UPDATE.FAILURE]: (state, { message }) => {
    return {
      ...state,
      updateErrorMessage: message,
      updateSuccessMessage: null,
      isLoading: false,
    };
  },
};

// SAGA
function* sagaUpdatePositionCost(action) {
  try {
    const updatedLead = yield call(apiUpdatePositionCost, action.data);

    yield put({
      type: constants.LEAD_POSITION_COST_UPDATE.SUCCESS,
      message: 'Opportunity updated successfully!',
      updatedLead,
      onSuccess: action?.onSuccess,
    });
  } catch (e) {
    yield put({
      type: constants.LEAD_POSITION_COST_UPDATE.FAILURE,
      message: e.message || e,
    });
  }
}

function* watchUpdatePositionCost() {
  yield takeLatest(constants.LEAD_POSITION_COST_UPDATE.REQUEST, sagaUpdatePositionCost);
}

// API
function apiUpdatePositionCost({ lead, position, targetCost, field }) {
  return request(`/leads/${lead}/positions/${position}/target-cost`, {
    method: 'PUT',
    body: {
      field,
      targetCost,
    },
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchUpdatePositionCost,
};
