import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const SkillGroupSchema = z.object({
  skill_group_id: z.number().nullable(),
  mongo_skill_group_id: z.string(),
  name: z.string(),
  color: z.string(),
  dt_created: dateString,
  dt_updated: dateString,
});

export type SkillGroup = z.infer<typeof SkillGroupSchema>;

export const SkillGroupMongoSchema = z.object({
  _id: z.string(),
  group: z.string(),
  color: z.string(),
  created_at: dateString,
  updated_at: dateString,
});

export type SkillGroupMongo = z.infer<typeof SkillGroupMongoSchema>;
