import { message as Message } from 'antd';
import { call, put, takeEvery } from 'redux-saga/effects';

import find from 'lodash/find';
import omit from 'lodash/omit';
import flatten from 'lodash/flatten';
import mapValues from 'lodash/mapValues';

import { createSagaAction } from '@shared/sagas';
import request from '@shared/request';

// MODULE
const constants = {
  LEAD_POSITION_HIRE_GROUP_UPDATE: createSagaAction('LEAD_POSITION_HIRE_GROUP_UPDATE'),
};

const actions = {
  updatePositionHireGroup: ({ lead, position, hire_group }, onSuccess = null) => ({
    type: constants.LEAD_POSITION_HIRE_GROUP_UPDATE.REQUEST,
    data: {
      lead,
      position,
      hire_group,
    },
    onSuccess,
  }),
};

const updatePositionHireGroup = ({ leads, ...data }) => {
  return mapValues(leads, (leadsList) => {
    return leadsList.map((lead) => {
      const position = find(lead.positions, { _id: data.position });

      const newPositions = !position
        ? lead.positions
        : lead.positions.map((p) => {
            if (p._id === position._id) {
              return {
                ...p,
                hire_group: data.hire_group,
              };
            }

            return p;
          });

      return {
        ...lead,
        positions: newPositions,
      };
    });
  });
};

const handlers = {
  [constants.LEAD_POSITION_HIRE_GROUP_UPDATE.REQUEST]: (state, { data }) => {
    const fullList = flatten(Object.values(state.leadsData));

    const lead = find(fullList, { _id: data.lead });
    const position = find(lead?.positions, { _id: data.position });
    const newLeads = updatePositionHireGroup({ leads: state.leadsData, ...data, loading: true });

    return {
      ...state,
      leadsData: newLeads,
      previousHireGroup: {
        ...state.previousHireGroup,
        [position?._id]: position?.hire_group,
      },
      loadingPositions: {
        ...state.loadingPositions,
        [position?._id]: true,
      },
      isLoading: true,
      updateSuccessMessage: null,
      updateErrorMessage: null,
      error: null,
      isUpdatingHireGroup: true,
    };
  },
  [constants.LEAD_POSITION_HIRE_GROUP_UPDATE.SUCCESS]: (state, { data, onSuccess }) => {
    Message.success('Position sucessfully updated.');

    const newLeads = updatePositionHireGroup({
      ...data,
      leads: state.leadsData,
      loading: false,
    });

    onSuccess?.();

    return {
      ...state,
      leadsData: newLeads,
      previousHireGroup: omit(state.previousHireGroup, data.position),
      loadingPositions: omit(state.loadingPositions, data.position),
      error: null,
      updateSuccessMessage: null,
      updateErrorMessage: null,
      isLoading: false,
      isUpdatingHireGroup: false,
    };
  },
  [constants.LEAD_POSITION_HIRE_GROUP_UPDATE.FAILURE]: (state, { message, data }) => {
    const newLeads = updatePositionHireGroup({
      ...data,
      leads: state.leadsData,
      hire_group: state.previousHireGroup[data.position],
      loading: false,
    });

    Message.error(message);
    return {
      ...state,
      previousHireGroup: omit(state.previousHireGroup, data.position),
      loadingPositions: omit(state.loadingPositions, data.position),
      leadsData: newLeads,
      updateErrorMessage: message,
      updateSuccessMessage: null,
      isLoading: false,
      isUpdatingHireGroup: false,
    };
  },
};

// SAGA
function* saga(action) {
  try {
    const updatedLead = yield call(api, action.data);

    yield put({
      type: constants.LEAD_POSITION_HIRE_GROUP_UPDATE.SUCCESS,
      message: 'Opportunity updated successfully!',
      updatedLead,
      data: action.data,
      onSuccess: action.onSuccess,
    });
  } catch (e) {
    yield put({
      type: constants.LEAD_POSITION_HIRE_GROUP_UPDATE.FAILURE,
      message: e.message || e,
      data: action.data,
    });
  }
}

function* watcher() {
  yield takeEvery(constants.LEAD_POSITION_HIRE_GROUP_UPDATE.REQUEST, saga);
}

// API
function api({ lead, position, hire_group }) {
  return request(`/leads/${lead}/positions/${position}/hire-group`, {
    method: 'PUT',
    body: {
      hire_group,
    },
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher,
};
