import { z } from 'zod';

export const SortDirectionSchema = z.enum(['asc', 'desc']);
export type SortDirection = z.infer<typeof SortDirectionSchema>;

export const SorterQuerySchema = z.array(
  z
    .tuple([z.enum<any, any>([]), SortDirectionSchema])
    .refine((arr: string[]) => Object.keys(SortDirectionSchema.Values).includes(arr[1]), {
      message: 'Invalid sorting options',
    }),
);
export type SorterSchema = typeof SorterQuerySchema;
export type SorterQuery = z.infer<typeof SorterQuerySchema>;

export type InferSorter<T extends SorterSchema> = z.infer<T>;
export type InferSorterReadonly<T extends SorterSchema> = InferSorter<T> extends (infer U)[]
  ? Readonly<U>[]
  : never;
export type InferSorterKeys<T extends SorterSchema> = z.infer<T> extends (infer S)[]
  ? S extends [infer K, ...any]
    ? K
    : never
  : never;

export function makeSorter<T extends z.ZodEnum<any>>(schema: T) {
  return z.array(
    z
      .tuple([schema, SortDirectionSchema])
      .refine((arr: string[]) => Object.keys(SortDirectionSchema.Values).includes(arr[1]), {
        message: 'Invalid sorting options',
      }),
  );
}
