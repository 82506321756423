import { InterviewRequestSchema } from '../base';
import { z } from 'zod';

export const InterviewRequestCreateSchema = InterviewRequestSchema.pick({
  applicant_id: true,
  application_id: true,
  stage: true,
  req_recruiter_id: true,
  ask_recruiter_id: true,
  num_days: true,
  ts_interview_request: true,
}).partial({
  ts_interview_request: true,
  num_days: true,
});
export type InterviewRequestCreate = z.infer<typeof InterviewRequestCreateSchema>;
