import * as api from '@redux/api/application';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';

// GET MEDIUM LIST

function* getMediumList(action) {
  try {
    const mediumList = yield call(api.getMediumList, action);
    yield put({ type: constants.APPLICATION_GET_MEDIUM.SUCCESS, mediumList });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_GET_MEDIUM.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchGetMediumList() {
  yield takeLatest(constants.APPLICATION_GET_MEDIUM.REQUEST, getMediumList);
}
