import { PsnRctrSchema } from '../base';
import { z } from 'zod';

export const PsnRctrUpdateSchema = PsnRctrSchema.pick({
  person_id: true,
  zoom_link: true,
  rctr_group: true,
  rctr_role: true,
  email: true,
}).partial({
  zoom_link: true,
  rctr_group: true,
  rctr_role: true,
  email: true,
});
export type PsnRctrUpdate = z.infer<typeof PsnRctrUpdateSchema>;
