import { z } from 'zod';
import { dateString } from '../../infrastructure';
export const PositionPeriodChangeSchema = z.object({
  position_id: z.string(),
  is_sticky: z.boolean().optional(),
  is_confirmed: z.boolean().optional(),
  dtplannedstart: dateString,
  dtplannedend: dateString,
});
export type PositionPeriodChange = z.infer<typeof PositionPeriodChangeSchema>;
