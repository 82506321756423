import { z } from 'zod';
import { TimeOffRequestSchema } from '../base';
import { dateString, Endpoint, Method } from '../../../infrastructure';

export const TimeOffRequestGetByMongoIdItemSchema = TimeOffRequestSchema.pick({
  approved_by: true,
  category: true,
  date_range: true,
  declined_by: true,
  dt_approved: true,
  dt_created: true,
  dt_declined: true,
  dt_updated: true,
  exceptional_not_counted: true,
  is_flexible: true,
  is_from_hire: true,
  is_paid: true,
  last_year_days: true,
  mongo_id: true,
  person_id: true,
  status: true,
  time_off_request_id: true,
  working_days: true,
  working_days_last_year: true,
  working_days_next_year: true,
}).extend({
  person_name: z.string(),
  dthired: dateString,
});
export type TimeOffRequestGetByMongoIdItem = z.infer<typeof TimeOffRequestGetByMongoIdItemSchema>;

export const TimeOffRequestGetByMongoIdParamsSchema = z.object({
  mongo_id: z.string(),
});

export type TimeOffRequestGetByMongoIdParams = z.infer<
  typeof TimeOffRequestGetByMongoIdParamsSchema
>;

export type TimeOffRequestGetByMongoIdResponse = TimeOffRequestGetByMongoIdItem;

export type TimeOffRequestGetByMongoIdEndpoint = Endpoint<
  Method.GET,
  `/api/v2/time-off-requests/${TimeOffRequestGetByMongoIdParams['mongo_id']}/by-mongo-id`,
  TimeOffRequestGetByMongoIdResponse,
  TimeOffRequestGetByMongoIdParams,
  undefined,
  undefined
>;
