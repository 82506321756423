/* eslint-disable max-lines */
import React from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import get from 'lodash/get';
import isString from 'lodash/isString';
import reduce from 'lodash/reduce';
import getUrls from 'get-urls';
import filter from 'lodash/filter';
import map from 'lodash/map';
import last from 'lodash/last';
import find from 'lodash/find';
import split from 'lodash/split';
import orderBy from 'lodash/orderBy';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import extend from 'lodash/extend';
import isNaN from 'lodash/isNaN';
import values from 'lodash/values';
import isFunction from 'lodash/isFunction';
import groupBy from 'lodash/groupBy';
import flow from 'lodash/flow';
import drop from 'lodash/drop';
import dropRight from 'lodash/dropRight';
import startsWith from 'lodash/startsWith';
import replace from 'lodash/replace';
import each from 'lodash/each';
import join from 'lodash/join';
import includes from 'lodash/includes';
import toString from 'lodash/toString';
import marked from 'marked';
import Fingerprint2 from 'fingerprintjs2';
import DOMPurify from 'dompurify';
import jwt_decode from 'jwt-decode';

import {
  getFormattedCost,
  getFormattedSalaryWithContractType,
} from '@components/Person/PersonCostAndSalary';

import {
  reverseAggregatePermissions,
  reverseAggregateLegacyPermissions,
} from '@ct-internal/permission-utils';

export const isDynamicEnv = (frontendBase) =>
  window.location.host.includes('-' + frontendBase.replace(/(^\w+:|^)\/\//, ''));

export const DEFAULT_TIMEZONE = 'America/New_York';

const reObjectId = /^[0-9a-fA-F]{24}$/;
const defaultRelativeTime = {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 'few seconds ago',
    ss: '%ds',
    m: '1min',
    mm: '%dmin',
    h: 'h',
    hh: '%dh',
    d: '1d',
    // Custom format for weeks since moment doesn't support this relative time override
    dd: (days) => {
      const weeks = Math.round(days / 7);
      return days < 7 ? `${days} d` : `${weeks}w`;
    },
    M: '1m',
    MM: '%dm',
    y: '1y',
    yy: '%dy ',
  },
};

export const doNothing = noop;

export const isObjectEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

export const isMongoObjectId = (id) => reObjectId.test(id);

export const distinguishDuplicates = (array, key) => {
  const unique = [];
  const duplicate = flow([
    (itemList) => groupBy(itemList, key),
    (groupList) =>
      filter(groupList, (group) => {
        if (group.length === 1) {
          unique.push(...group);
          return false;
        }
        return group.length > 1;
      }),
  ])(array);

  return { duplicate, unique };
};

export const noPropagation = (e) => {
  if (!e || !isFunction(e.stopPropagation)) {
    return;
  }

  e.stopPropagation();
};

export const moveCaretAtEnd = (event) => {
  //taken from https://coderwall.com/p/0iz_zq/how-to-put-focus-at-the-end-of-an-input-with-react-js
  if (event && event.target) {
    const tempValue = event.target.value;
    event.target.value = '';
    event.target.value = tempValue;
  }
};

export const getRateValue = (user) => {
  const salary =
    ((user && user.negotiatedSalary) || '').trim() ||
    ((user && user.negotiated_salary) || '').trim() ||
    ((user && user.salary) || '').trim() ||
    null;

  return salary ? parseInt(salary.match(/\d+/gim), 10) : null;
};

export const getDesiredSalaryValue = (user) => {
  const salary = ((user && user.salary) || '').trim() || null;

  return salary ? parseInt(salary.match(/\d+/gim), 10) : null;
};

export const getUserCTEmail = (user) => {
  return user.clevertechEmail || getGeneratedEmail(user);
};

export function getLocationValue(application, applicationUser) {
  return formatLocation(application, true) || formatLocation(applicationUser);
}

export const getGeneratedEmail = (user) => {
  if (!user.latin_name) {
    return null;
  }

  const parts = (user.latin_name || '')
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .toLowerCase()
    .split(' ');

  return `${parts.join('.')}@clevertech.biz`;
};

export const getTimezoneShort = (timezone) => {
  const timezoneArray = (timezone || '').split(',');
  const timezoneShort = last(timezoneArray);

  return (timezoneShort && timezoneShort.trim()) || '';
};

export const isUSA = (country) => {
  const options = ['US', 'USA', 'United States'];
  return includes(options, country);
};

export const nChars = (o, n) => {
  const s = (o && o.toString()) || '';

  return s.substr(0, n).trim();
};

export const compressJobName = (sentence, maxWordLength) => {
  const s = sentence
    ? sentence
        .replace(/JavaScript/gi, 'Js')
        .replace(/developer/gi, 'DEV')
        .replace(/fullstack/gi, 'FStack')
        .replace(/devops/gi, 'DO')
        .replace(/Engineer/gi, 'ENG')
        .replace(/Node.js/gi, 'node')
    : '';

  return map(s.split(' '), (w) => w.substring(0, maxWordLength)).join(' ');
};

export const getFormattedJob = (job) => {
  return job.shortName || nChars(compressJobName(job.name), 10);
};

export const tightHours = (s) => {
  return s ? s.replace(/m/gi, '').replace(/to/gi, '-').replace(/\s/g, '').toLowerCase().trim() : s;
};

export const getNtoken = (csv, n) => {
  // eslint-disable-next-line no-param-reassign
  csv = csv ? csv.trim() : '';
  const tokens = csv.split(',');

  return tokens.length > n ? tokens[n].trim() : '';
};

export const getFormattedWorkTime = (user) => nChars(tightHours(getNtoken(user.hours, 0)), 6);

export const formatDuration = (start, finish) => {
  // function twoDigits ( n ) {
  //   return n > 9 ? String( n ) : `0${ n }`;
  // }

  if (!start || !finish) {
    return '';
  }

  const duration = moment.duration(moment(finish).diff(moment(start)));
  if (duration.days() > 0) {
    return `${duration.days()} days`;
  }

  return moment.utc(duration.asMilliseconds()).format('HH:mm:ss');

  /*
  return `${ twoDigits( duration.hours() )
    }:${ twoDigits( duration.minutes() )
    }:${ twoDigits( duration.seconds() ) }`;
  */
};

export const getValidDate = (date) => {
  if (!date) {
    return null;
  }

  const mDate = moment(date);
  return mDate && mDate.isValid() ? mDate : null;
};

export const isValidContractType = (contractType) => {
  const validTypes = ['W2', 'T4'];
  return validTypes.includes(contractType?.substring(0, 2));
};

export const timeElapsed = (fromDate = moment(), toDate, customRelative) => {
  const locale = customRelative || defaultRelativeTime;
  moment.updateLocale('en', locale);
  // If we specify a explicit "toDate" we use different moment method
  if (toDate) {
    return moment(fromDate).from(toDate);
  }
  // else we fall back to now
  return moment(fromDate).fromNow();
};

export const getTimeElapsedUntilNow = (from) => {
  const now = moment();
  const date = moment(from);

  if (date.diff(now, 'days') === 0) {
    return 'today';
  }
  return timeElapsed(from, now);
};

export const printOptionForAssignment = (optionForAssignment) => {
  if (!optionForAssignment || optionForAssignment === 4) {
    return '';
  }
  if (optionForAssignment === 1) {
    return 'A';
  }
  if (optionForAssignment === 2) {
    return 'B';
  }
  if (optionForAssignment === 3) {
    return 'C';
  }
};

export const trimLines = (str) => {
  return str
    .trim()
    .split('\n')
    .map((line) => line.trim())
    .join('\n');
};

export const parseQuery = (queryString) => {
  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  const test = pairs
    .map((p) => p.split('='))
    .reduce((prevValue, nextValue) => {
      const newValue = { [nextValue[0]]: nextValue[1] };

      return { ...prevValue, ...newValue };
    }, query);

  return { ...test };
};

export const saveLastSearchState = (state) => {
  sessionStorage.setItem('SEARCH_APPLICATIONS_STATE', JSON.stringify(state));
};

export const getLastSearchState = (presetId = 'SEARCH_APPLICATIONS_STATE') => {
  try {
    const lastSearchState = sessionStorage.getItem(presetId);
    const parsedObject = JSON.parse(lastSearchState);
    return parsedObject;
  } catch (error) {
    console.error(error);
  }
};

export const saveLastSearchResultsIds = (applicationsIds) => {
  if (applicationsIds) {
    sessionStorage.setItem('SEARCH_APPLICATIONS_RESULTS_IDS', applicationsIds.join(','));
  }
};

export const getLastSearchResultsIds = () => {
  const applicationsIdsString = sessionStorage.getItem('SEARCH_APPLICATIONS_RESULTS_IDS');
  if (applicationsIdsString) {
    return applicationsIdsString.split(',');
  }
  return [];
};

export const getTestOrLast = (application, examId) => {
  const { devskillerTests = [] } = application;
  let test;

  if (examId) {
    test = find(devskillerTests, { examId });
  }

  if (isNil(test)) {
    test = last(devskillerTests);
  }

  return test;
};

export const notTakenTestStatuses = [
  'undefined',
  'ERROR',
  'CANCELED',
  'TOKEN_EXPIRED',
  'NEW',
  'TOKEN_SENT',
  'TEST_FINISHED',
];

const testStatusByNumber = {
  61: { name: 'undefined', index: 0 },
  '-9': { name: 'ERROR', index: 1 },
  '-1': { name: 'CANCELED', index: 2 },
  63: { name: 'ASSESSMENT_COMPLETED', index: 3 },
  '-5': { name: 'TOKEN_EXPIRED', index: 4 },
  62: { name: 'TEST_FINISHED', index: 5 },
};

/* eslint-disable max-statements */
export const processRequestedAssessments = (requested, application) => {
  const tests = map(requested, (request) => {
    const externalTestId = get(request, 'externalTestId', '');
    const exam = find(application, {
      id: externalTestId,
    });

    const result = {
      examId: exam?.id,
      examName: exam && formatAssessmentTestName(exam.name),
      request: true,
      mRequested: getValidDate(request.requestedAt),
      result: null,
      user: request.user,
      archived: request.archived,
    };

    const statusInfo = get(testStatusByNumber, request.status, testStatusByNumber['61']);
    const strStatus = statusInfo.name;
    const mCreated = getValidDate(request.created_at);
    const mRequested = getValidDate(request.requestedAt);
    const mUpdated = getValidDate(request.updated_at);
    const mOrderDate = notTakenTestStatuses.includes(strStatus)
      ? mCreated
      : mUpdated || mRequested || mCreated;

    return {
      id: request._id,
      isRequested: true,
      sections: [],
      examId: externalTestId,
      examName: exam && formatAssessmentTestName(get(exam, 'name', '')),
      examUrl: '',
      reportUrl: '',
      ordStatus: statusInfo.index,
      status: request.status,
      strStatus,
      result,
      skills: [],
      score: '-',
      scoreNumber: 0,
      scoreShort: '--%',
      scoreColor: '',
      mCreated,
      mRequested,
      mUpdated,
      mFinished: null,
      mOrderDate,
      testCommitBy: request.testCommitBy,
      archived: request.archived,
    };
  });

  return tests;
};

const testStatusByName = {
  undefined: { status: 61, index: 0 },
  ERROR: { status: -9, index: 1 },
  CANCELED: { status: -1, index: 2 },
  ASSESSMENT_COMPLETED: { status: 63, index: 3 },
  TOKEN_EXPIRED: { status: -5, index: 4 },
  NEW: { status: 62, index: 5 },
  TOKEN_SENT: { status: 62, index: 6 },
  TEST_STARTED: { status: 62, index: 7 },
  TEST_FINISHED: { status: 62, index: 8 },
};

export const processDevskillerAssessments = (devskiller, application) => {
  const tests = map(filter(devskiller, Boolean), (devskillerAssessment) => {
    const examIdV1 = get(devskillerAssessment, 'examIdV1', '');
    const exam = find(application, {
      id: examIdV1,
    });

    const result = extend(get(devskillerAssessment, 'score', {}), {
      startDate: get(devskillerAssessment, 'startDate', null),
      finishDate: get(devskillerAssessment, 'finishDate', null),
      creationDate: get(devskillerAssessment, 'creationDate', null),
    });
    const strStatus =
      result && result?.finishDate && result?.startDate
        ? 'ASSESSMENT_COMPLETED'
        : String(devskillerAssessment.status);

    const skills = [];
    const score = formatSectionScore(result);
    const scoreShort = `${get(result, 'percentage', '--')}%`;
    const percentage = get(result, 'percentage', 0);
    const scoreColor = percentageToColor(percentage);
    const status = get(testStatusByName, `${strStatus}.status`);

    const mCreated = getValidDate(devskillerAssessment.created_at);
    const mRequested = getValidDate(devskillerAssessment.requestedAt);
    const mUpdated = getValidDate(devskillerAssessment.updated_at);
    const mFinished = getValidDate(
      devskillerAssessment.finishDate || devskillerAssessment.scoredAt,
    );
    const mOrderDate = notTakenTestStatuses.includes(strStatus)
      ? mCreated
      : mFinished || mUpdated || mRequested || mCreated;

    return {
      sections: get(devskillerAssessment, 'sections'),
      examId: examIdV1,
      id: devskillerAssessment.externalAssessmentId,
      examName: exam && formatAssessmentTestName(exam.name),
      examUrl: get(devskillerAssessment, '_links.candidateAccess.href', '#'),
      reportUrl: get(devskillerAssessment, '_links.onlineReport.href', '#'),
      _id: devskillerAssessment._id,
      ordStatus: get(testStatusByName[strStatus], 'index', ''),
      status,
      strStatus,
      result,
      skills,
      score,
      scoreNumber: percentage,
      scoreShort,
      scoreColor,
      mCreated,
      mRequested,
      mUpdated,
      mFinished,
      mOrderDate,
    };
  });

  return tests;
};

export const getJoinedAssessments = (
  requested,
  devskiller,
  application,
  removeArchived,
  order = 'desc',
) => {
  const devSkillerIds = devskiller?.map((t) => t._id);
  const requestedWithoutScored = requested?.filter(
    (t) => !devSkillerIds.includes(t.externalAssessmentId),
  );
  const requestedAssessments = processRequestedAssessments(requestedWithoutScored, application);
  const devskillerAssessments = processDevskillerAssessments(devskiller, application);

  let joinedAssessments = devskillerAssessments.concat(requestedAssessments);

  if (removeArchived) {
    joinedAssessments = filter(joinedAssessments, (assessment) => !assessment.archived);
  }

  return orderBy(joinedAssessments, ['scoreNumber', 'mOrderDate'], [order, order]);
};

export const getTestScore = (application, applicationTests) => {
  const { devskillerAssessments, requestedAssessments } = application;
  const completedAssessments = getLatestCompletedAssessments(
    devskillerAssessments,
    requestedAssessments,
    applicationTests,
  );

  if (!completedAssessments || isEmpty(completedAssessments)) {
    return '';
  }

  const filteredSkills = groupAssessmentsSkills(completedAssessments);

  return map(filteredSkills, (skill) => ` ${skill.short}${skill.percentage}%`);
};

export const groupAssessmentsSkills = (assessments) => {
  const skills = {};

  each(assessments, (assessment) => {
    each(assessment.skills, (skill) => {
      const { skill: name, percentage } = skill;
      const foundSkill = skills[name];
      if (!foundSkill || percentage > foundSkill.percentage) {
        skills[name] = skill;
      }
    });
  });

  return values(skills);
};

export const getLatestCompletedAssessments = (devskiller, requested, applicationTests) => {
  if (!devskiller && !requested) {
    return null;
  }

  const REMOVE_ARCHIVED = true;
  const processedAssessments = getJoinedAssessments(
    requested,
    devskiller,
    applicationTests,
    REMOVE_ARCHIVED,
  );

  const completedAssessments = filter(processedAssessments, ['strStatus', 'ASSESSMENT_COMPLETED']);

  if (!completedAssessments.length) {
    return null;
  }

  return completedAssessments;
};

export const percentageToColor = (percentage) => {
  const _percentage = Number(percentage);

  if (!isValidNumber(_percentage)) {
    return '';
  }

  let color = 'green';

  if (_percentage < 80) {
    color = 'orange';
  }
  if (_percentage < 50) {
    color = 'red';
  }

  return color;
};

export const compareTimeToColor = ({ timeTakenInSeconds, timeLimitInSeconds }) => {
  const mTaken = moment.duration(timeTakenInSeconds, 'seconds').asMinutes();
  const timeTaken = Math.floor(mTaken);

  const mLimit = moment.duration(timeLimitInSeconds, 'seconds').asMinutes();
  const timeLimit = Math.floor(mLimit);

  let color = 'green';
  if (timeTaken > timeLimit || timeTaken === 0) {
    color = 'red';
  }
  return color;
};

export const formatAssessmentTestName = (name) => {
  return split(name, '|', 1)[0];
};

export const formatAssessmentSection = (section) => {
  return {
    score: formatSectionScore(section.score),
    timeTaken: formatSectionTime(section),
    timeShort: formatSectionTime(section, true),
    timeColor: compareTimeToColor(section),
    color: percentageToColor(section.score.percentage),
    percentage: section.score.percentage,
    answers: section.answers,
  };
};

export const getApplicationUrl = (applicationId) => {
  const protocol = window.location.protocol;
  const host = window.location.host;
  return `${protocol}//${host}/admin/application/${applicationId}`;
};

export const getTechApplicationUrl = (applicationId) => {
  const protocol = window.location.protocol;
  const host = window.location.host;
  return `${protocol}//${host}/tech/application/${applicationId}`;
};

const formatSkillName = (skill) => {
  const reNative = /native/i;
  if (reNative.test(skill)) {
    return 'React Native';
  } else if (includes(skill, 'JavaScript | React')) {
    return 'React';
    // eslint-disable-next-line no-script-url
  } else if (includes(skill, 'JavaScript: Parallel Async')) {
    return 'ASync';
  } else if (skill === 'Code Review') {
    return skill;
  } else if (skill) {
    const skillName = skill.split(' ')[0];

    return skillName.toLowerCase() === 'nodejs' ? 'Node' : skillName;
  } else {
    return 'Other';
  }
};

export const appSkills = [
  { skill: 'Lead', short: 'LD' },
  { skill: 'Git', short: 'Git' },
  { skill: 'Kubernetes', short: 'K8s' },
  { skill: 'QA', short: 'QA' },
  { skill: 'Code Review', short: 'CR' },
  { skill: '3D', short: '3D' },
  { skill: 'UX', short: 'UX' },
  { skill: 'Product', short: '' },
  { skill: 'WordPress', short: 'WP' },
  { skill: 'Ionic', short: 'I' },
  { skill: 'iOS', short: 'iOS' },
  { skill: 'ASync', short: 'ASY' },
  { skill: 'Asynchronous', name: 'ASync', short: 'ASY' },
  { skill: 'Lambda', short: 'L' },
  { skill: 'Android', short: 'A' },
  { skill: 'Serverless', short: 'SL' },
  { skill: 'AWS', short: 'AWS' },
  { skill: 'Unique', short: 'U' },
  { skill: 'Devops', short: 'OPS' },
  { skill: 'SQL', short: 'SQL' },
  { skill: 'Data Lake', short: 'DL' },
  { skill: 'Apex', short: 'AX' },
  { skill: 'React Native', short: 'RN' },
  { skill: 'React', short: 'R' },
  { skill: 'Angular', short: 'NG' },
  { skill: 'Node', short: 'N' },
  { skill: 'Node.js', short: 'N' },
  { skill: 'NodeJS', short: 'N' },
  { skill: 'JavaScript', short: 'JS' },
  { skill: 'Java', short: 'J' },
  { skill: 'GoLang', short: 'GO' },
  { skill: 'Flask', short: 'F' },
  { skill: 'Python', short: 'P' },
  { skill: '.Net Core', short: '.N' },
  { skill: '.Net', short: '.N' },
  { skill: 'Ruby', short: 'RU' },
  { skill: 'PHP', short: 'PHP' },
  { skill: 'C++', short: 'C++' },
  { skill: 'FullStack', short: 'FS' },
];

export const getSkillName = (skill) => {
  const skillName = formatSkillName(skill);

  const skillObj = find(appSkills, function (sk) {
    return sk.skill.toLowerCase() === skillName.toLowerCase();
  });

  if (skillObj) {
    return skillObj.skill;
  }
  if (!skillObj) {
    return skillName;
  }
};

export const getSkillsFromTitle = (title) => {
  let _skill = '';
  let _short = '';

  find(appSkills, (item) => {
    const { skill, short, name } = item;
    const skillFound = findFirstSub(title, skill);
    if (skillFound) {
      _skill = name || skillFound;
      _short = short;
      return true;
    }
  });

  if (_skill) {
    return {
      skill: _skill,
      short: _short,
      title,
    };
  }

  _skill = formatSkillName(title);

  return {
    skill: _skill || 'Other Tech',
    short: _skill ? _skill[0] : 'Other',
    title,
  };
};

export const findFirstSub = (string, word) => {
  const wrapper = '[( |:)*?]';
  /* eslint-disable-next-line */
  const sub = word.replace(/[-\/\\^$*+?.()|[\]{}]/, '\\$&');
  const re = new RegExp(`${wrapper}${sub}${wrapper}|^${sub}${wrapper}`, 'i');

  if (re.test(string)) {
    return word;
  }

  return '';
};

export const standardizeSkillShort = (skillName) => {
  const skillObj = find(appSkills, function (sk) {
    return sk.skill.toLowerCase() === skillName.toLowerCase();
  });
  if (skillObj) {
    return skillObj.short;
  } else {
    return skillName.substring(0, 3);
  }
};

export const getNativeLanguage = (application) => {
  if (isNil(get(application, 'nativeEnglish')) && isNil(get(application, 'englishLevel'))) {
    return 0;
  }

  let str = application.nativeEnglish ? 'Y' : 'N';

  if (str === 'N') {
    str =
      application.englishLevel && application.englishLevel !== '0' ? application.englishLevel : str;
  }

  return str;
};

export const getAIEnglishLevel = (application) => {
  if (isNil(get(application, 'automatedEnglishLevel'))) {
    return 0;
  }
  return get(application, 'automatedEnglishLevel');
};

export const getEnglishLevel = (user) => {
  if (!user.englishLevel || user.englishLevel > 6) {
    return 0;
  }

  return user.englishLevel;
};

export const getLeadExperience = (application) => {
  if (application.leadExperience === null || application.leadExperience === undefined) {
    return 0;
  }

  return application.leadExperience ? 'Y' : 'N';
};

export const getSeniorityText = (application) => {
  if (!application || !application.seniority || application.seniority === '0') {
    return 'N/A';
  }

  return application.seniority;
};

const getSeniority = (application) => {
  return application.seniority;
};

export const getBlueChip = (application) => {
  if (application.blueChip === null || application.blueChip === undefined) {
    return 0;
  }

  const str = application.blueChip ? 'Y' : 'N';
  return str;
};

const getZoomVideoLinks = (comments) => {
  const zoomVideoLink = reduce(
    comments,
    (result, { comment }) => {
      const zoomRecordingRegExp = /https:\/\/clevertech\.zoom\.us\/(rec\/share\/comment)|play/;

      if (!zoomRecordingRegExp.test(comment)) {
        return result;
      }

      const urlsSet = getUrls(comment);
      return urlsSet.size ? urlsSet.values().next().value : result;
    },
    '',
  );

  return zoomVideoLink ? `<${zoomVideoLink} | VID>` : '';
};

export const getApplicationCopyElements = ({
  application,
  jobName,
  optionForAssignment,
  withSummary,
  comments,
  short = false,
}) => {
  const copyText = getApplicationCopyText({
    application,
    jobName,
    optionForAssignment,
    withSummary,
    comments,
    short,
  });
  const modCopy = drop(dropRight(copyText.split(','))).filter(
    (copy) => !copy.includes('undefined'),
  );
  const copyElements = modCopy.map((str) => {
    if (str === '' || str.includes('undefined')) {
      return null;
    }
    if (str.includes('linkedin')) {
      return (
        <a target="_blank" rel="noreferrer" href={application.urls}>
          LI
        </a>
      );
    }
    return React.createElement('p', {}, str.trim());
  });

  return copyElements.filter((copy) => !isEmpty(copy));
};

export const getSimplifiedApplicationText = (application, hiddenSensibleData) => {
  const salaryPrefix = application.salaryCurrency === 'CAD' ? 'CA$' : '$';

  const getSalaryValueText = () => {
    if (hiddenSensibleData) {
      return '';
    }

    if (application.salaryValue) {
      return `${salaryPrefix}${application.salaryValue}k`;
    }

    return 'N/A';
  };

  const yearsOfExp = application.degreeYear
    ? `${moment().year() - application.degreeYear}y`
    : 'N/A';

  const seniority =
    getSeniority(application) + getNativeLanguage(application) + getLeadExperience(application) ||
    '';

  const location =
    application.city && application.country
      ? `${application.city}, ${application.country}`
      : application.countryShort || application.country;

  return [
    getSalaryValueText(),
    seniority,
    yearsOfExp,
    hiddenSensibleData ? '' : application.availabilityShort,
    hiddenSensibleData ? '' : application.statusShort,
    hiddenSensibleData ? '' : application.timezoneShort,
    hiddenSensibleData ? '' : location,
  ]
    .filter((value) => value)
    .join(', ');
};

// eslint-disable-next-line complexity
export const getApplicationCopyText = ({
  application,
  applicationTests,
  jobName,
  optionForAssignment,
  withSummary,
  comments,
  short = false,
  informationSimplified = false,
  noLinkedin = false,
}) => {
  // Manually get the number of years of experience
  const yearsOfExp = application.degreeYear
    ? `${moment().year() - application.degreeYear}y`
    : 'N/A';
  const shortUrl =
    // application.shortUrl ||
    getApplicationUrl(application._id) || window.location.href;

  const names = (get(application, 'ownerName') || '').split(' ');
  const responsible = names.length > 1 ? `-${names[0][0] + names[1][0]}` : '';
  const job = `(${jobName || get(application, 'jobShortName') || ''}${responsible})`;
  const linkedinURL = noLinkedin
    ? ''
    : application.linkedinURL ||
      (isString(application.urls) &&
        get(application.urls.match(/(https?:\/\/(www.)?)?linkedin\S*/), '[0]', '')) ||
      '';
  const linkedinURLMarkdown = linkedinURL ? `<${linkedinURL} | LI>` : '';

  const salaryData = {
    cost_usd: (application.cost ?? application.salaryValue) * 1000,
    currency: application.salaryCurrency,
    currency_salary: application.salaryValue * 1000,
    usd_salary: application.usdSalary ?? application.salaryValue,
    contract_type: application.contractType,
  };
  const { formattedYearlySalary, contractTypeFormatted } = getFormattedSalaryWithContractType({
    data: salaryData,
    details: true,
  });

  const salaryText = `${getFormattedCost({
    data: salaryData,
    details: true,
  })} - ${formattedYearlySalary}${contractTypeFormatted}`;
  const referredLine = application.referredBy
    ? (application.referredByName && ` – *REF: ${application.referredByName}* –`) ||
      ' – *Referred* –'
    : '';
  const { statusShort } = application;
  const country = application.countryShort || application.country || '';
  const summary = (withSummary && application.extraText) || '';
  const zoomVideoLink = getZoomVideoLinks(comments);

  if (short) {
    return `<${shortUrl} | ${application.name}>`;
  }
  if (informationSimplified) {
    return ''
      .concat(salaryText)
      .concat(', ')
      .concat(
        '' +
          getSeniority(application) +
          ', ' +
          getNativeLanguage(application) +
          ', ' +
          getLeadExperience(application) || '',
      )
      .concat(', ')
      .concat(linkedinURLMarkdown)
      .concat(', ')
      .concat(yearsOfExp)
      .concat(', ')
      .concat(', ')
      .concat(', ')
      .concat(application.availabilityShort || '')
      .concat(', ')
      .concat(statusShort || '')
      .concat(', ')
      .concat(application.timezoneShort || '')
      .concat(', ')
      .concat(country)
      .replace(/\s\s+/g, ' ')
      .replace(/(,*\s*,\s)+/g, ', ');
  }

  return '/ct '
    .concat(`<${shortUrl} | ${application.name}>`)
    .concat(referredLine)
    .concat(' ')
    .concat(job || '')
    .concat(', ')
    .concat(salaryText)
    .concat(', ')
    .concat(
      '' +
        getSeniority(application) +
        getNativeLanguage(application) +
        getLeadExperience(application) || '',
    )
    .concat(', ')
    .concat(linkedinURLMarkdown)
    .concat(', ')
    .concat(yearsOfExp)
    .concat(', ')
    .concat(printOptionForAssignment(optionForAssignment))
    .concat(', ')
    .concat(getTestScore(application, applicationTests))
    .concat(', ')
    .concat(application.availabilityShort || '')
    .concat(', ')
    .concat(statusShort || '')
    .concat(', ')
    .concat(application.timezoneShort || '')
    .concat(', ')
    .concat(country)
    .concat(summary ? `, ${summary}` : '')
    .concat(zoomVideoLink ? `, ${zoomVideoLink}` : '')
    .replace(/\s\s+/g, ' ')
    .replace(/(,*\s*,\s)+/g, ', ');
};

export const englishLevelMap = {
  0: 'N/A',
  1: 'Native',
  2: 'Accent, professional English (no missed words), good speed',
  2.5: 'Better than passable, not quite professional',
  3: 'Accent, slow English, no missing words',
  4: 'Accent, missing words',
  5: 'Accent, slow, missing words, struggling',
};

export const seniorityLevelMap = {
  0: 'N/A',
  J: 'Junior',
  M: 'Mid',
  MS: 'Mid-Senior',
  S: 'Senior',
  SS: 'Super-Senior',
};

export const workingStatusLevelMap = {
  0: 'N/A',
  N: 'No Job',
  JN: 'In job but doesn’t like it',
  JE: 'Job is ending at specific date',
  JO: 'Job is ok, open to offers',
};

export const skillLevelMap = ['Jr', 'Mid', 'Sr'];

export const onDeckFocusMatchMap = {
  Exact: { sortingValue: 1, keyword: 'exact', color: 'Green' },
  Close: { sortingValue: 2, keyword: 'close', color: 'Orange' },
  Partial: { sortingValue: 3, keyword: 'partially', color: 'Red' },
  Vacant: { sortingValue: 4, keyword: 'vacant', color: 'Brown' },
};

export const downloadUrl = (documentUrl, { token, type, open = false, asUrl = false }) => {
  const promise = fetch(documentUrl, {
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
    },
  }).then((response) => {
    return response.blob();
  });

  if (open || asUrl) {
    promise.then((blob) => {
      const newBlob = new Blob([blob], {
        type: type || 'application/octet-stream',
      });
      const url = URL.createObjectURL(newBlob);

      if (asUrl) {
        return url;
      }

      return window.open(url);
    });
  } else {
    return promise;
  }
};

export const isValidNumber = (number) => {
  return isNumber(number) && !isNaN(number);
};

export const formatSectionScore = ({ scoredPoints, maxPoints, percentage }, short = false) => {
  const _percentage = Number(percentage);
  const _scoredPoints = Number(scoredPoints);
  const _maxPoints = Number(maxPoints);

  if (!isValidNumber(_percentage)) {
    return '-';
  }

  if (short) {
    return `${_percentage.toFixed(0)}%`;
  }

  const validScored = isValidNumber(_scoredPoints) ? _scoredPoints : '-';
  const validMax = isValidNumber(_maxPoints) ? _maxPoints : '-';

  return `${_percentage.toFixed(0)}% (${validScored}/${validMax})`;
};

const formatSectionTime = ({ timeTakenInSeconds, timeLimitInSeconds }, short = false) => {
  const taken = timeTakenInSeconds < 0 ? 0 : timeTakenInSeconds;
  const limit = timeLimitInSeconds < 0 ? 0 : timeLimitInSeconds;

  const mTaken = moment.duration(taken, 'seconds').asMinutes();
  const timeTaken = Math.floor(mTaken);

  const mLimit = moment.duration(limit, 'seconds').asMinutes();
  const timeLimit = Math.floor(mLimit);

  const timeTakenFmt = isValidNumber(timeTaken) && timeTaken !== 0 ? timeTaken : `-`;
  const timeLimitFmt = isValidNumber(timeLimit) && timeLimit !== 0 ? timeLimit : `-`;

  const shortSufix = short ? 'm' : 'min';

  return `(${timeTakenFmt}/${timeLimitFmt})${shortSufix}`;
};

const difficultyRank = {
  EASY: {
    min: 0,
    max: 1,
  },
  MEDIUM: {
    min: 1,
    max: 2,
  },
  HARD: {
    min: 2,
    max: 3,
  },
};

export const formatDifficulty = (difficulty) => {
  return get(difficultyRank, difficulty, {}).min;
};

export const calculateDifficulty = (value) => {
  if (!isNumber(value) || value < 0) {
    return '';
  }

  const { EASY, MEDIUM } = difficultyRank;
  if (value >= EASY.min && value < EASY.max) {
    return 'EASY';
  }

  if (value >= MEDIUM.min && value < MEDIUM.max) {
    return 'MEDIUM';
  }

  return 'HARD';
};

export const formatSectionRow = (section, index) => {
  return `${index + 1}. ${compressJobName(
    get(section, 'answers[0].title', ` Section ${index + 1} Assessment`),
  )}: \n - Scored ${formatSectionScore(section.score)} in ${formatSectionTime(section)} `;
};

export const getSkillColor = (skill) => {
  switch (skill) {
    default:
      return 'red';
  }
};

export const rubericColors = {
  First: '#76C1D3',
  Second: '#8DCCDA',
  Third: '#A4D6E1',
  Fourth: '#BAE0E9',
  Fifth: '#D1EAF0',
  Sixth: '#E8F4F7',
};

export const statusesQuickOptions = {
  'Late Stage': ['interviewingMgmt', 'pendingOffer', 'hired'],
  'Full Pipe Rest': ['new', 'interviewingTeam'],
  'Full Pipe': ['new', 'groupInterviewing'],
  Clear: [],
};

export const getFunctionParamsName = (func) => {
  var str = func.toString();

  str = str
    .replace(/\/\*[\s\S]*?\*\//g, '')
    .replace(/\/\/(.)*/g, '')
    .replace(/{[\s\S]*}/, '')
    .replace(/=>/g, '')
    .trim();

  var start = str.indexOf('(') + 1;
  var end = str.length - 1;
  var result = str.substring(start, end).split(', ');
  var params = [];

  result.forEach((element) => {
    // eslint-disable-next-line no-param-reassign
    element = element.replace(/=[\s\S]*/g, '').trim();

    if (element.length > 0) {
      params.push(element);
    }
  });

  return params;
};

export const getRandomString = (length = 24) => {
  const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return result;
};

export const getRandomSlug = (name) => {
  const firstPart = name.replace(/ /g, '-').toLowerCase();
  return `${firstPart}-${getRandomString(9)}`;
};

export const matchStrings = (a, b) => {
  //remove special characters and to lowercase
  // eslint-disable-next-line no-param-reassign
  a = a
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

  // eslint-disable-next-line no-param-reassign
  b = b
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

  const aTokens = a.split(/\s+/);

  const anyFound = find(aTokens, (token) => {
    return token.startsWith(b);
  });

  return !isEmpty(anyFound);
};

export const truncateText = (input) => {
  if (input.length > 30) {
    return input.substring(0, 30) + '...';
  }
  return input;
};

export const getLinkedInUserNameFromUrl = (url) => {
  if (url) {
    const regex = /\/in\/([a-zA-Z0-9-_]+)/;
    const match = url.match(regex);

    if (match) {
      return match[1];
    }
  }

  return null;
};

export const emailRegex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const urlRegex = new RegExp(
  '' +
    /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\./.source +
    /[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.source,
);
const regex = /^(http(s)?:\/\/)?([\w]+\.)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/(.+)(\/)?/;
export const linkedinRgx = regex;
export const isLinkedinUrl = (url) => linkedinRgx.test(url);

export const processLinkedinUrl = (url) => {
  if (!isLinkedinUrl(url)) {
    return '';
  }

  if (startsWith(url, 'https://')) {
    return url;
  }

  if (startsWith(url, 'linkedin.com')) {
    return `https://${url}`;
  }

  return addUrlProtocol(url);
};

export const addUrlProtocol = (url) => {
  if (startsWith(url, 'https://')) {
    return url;
  }

  if (startsWith(url, 'http://www.')) {
    return replace(url, 'http://www.', 'https://');
  }

  if (startsWith(url, 'www.')) {
    return replace(url, 'www.', 'https://');
  }

  if (startsWith(url, 'http://')) {
    return replace(url, 'http://', 'https://');
  }

  return `https://${url}`;
};

export const processURL = (url) => {
  const _url = addUrlProtocol(url);
  try {
    const urlObj = new URL(_url);
    return urlObj.href;
  } catch (err) {
    console.error(err);
    return '';
  }
};

export const pushNewUrl = (urlsString, url) => {
  const urlsArray = getUrls(urlsString);
  if (includes(urlsArray, url)) {
    return urlsString;
  }

  return `${url} ${urlsString}`;
};

export const getLastOrPinnedComment = (commentsList) => {
  if (!commentsList.length) {
    return null;
  }
  const pinnedComment = find(commentsList, (comment) => comment.pinned);
  if (pinnedComment) {
    return pinnedComment;
  }
  return reduce(
    commentsList,
    (lastComment, comment) =>
      lastComment && moment(lastComment.created_at).isAfter(comment.created_at)
        ? lastComment
        : comment,
    null,
  );
};

export const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const filterJoinString = (array, separator = ', ', _default = '') => {
  return join(filter(array, Boolean), separator) || _default;
};

export const formatLocation = (source, fallback = false) => {
  if (fallback && !source.country && !source.city && !source.state) {
    return '';
  }

  const isUS = isUSA(source.country);
  const location = isUS ? _formatLocationUSA(source) : _formatLocation(source);

  return filterJoinString(location, ', ', isUS ? 'USA' : source.country);
};

export const formatLocationShort = (source, fallback = false) => {
  if (fallback && !source.country && !source.city && !source.state) {
    return '';
  }

  const isUS = isUSA(source.country);
  const location = isUS ? _formatLocationShortUSA(source) : _formatLocationShort(source);

  return filterJoinString(location, ', ', isUS ? 'USA' : source.country);
};

const _isShort = (short) => {
  return short.length > 1 && short.length < 3;
};

const _formatLocationUSA = (source) => {
  const { city: userCity = '', state: userState = '', country: userCountry = '' } = source;

  return [userCity, userState, userCountry];
};

const _formatLocation = (source) => {
  const { city = '', state = '', country: userCountry = '' } = source;

  const userCity = isEmpty(city) ? state : city;
  return [userCity, userCountry];
};

const _formatLocationShortUSA = (source) => {
  const { city: userCity = '', regionShort = '', state = '' } = source;

  const userState = _isShort(regionShort) ? regionShort : state;
  return [userCity, userState];
};

const _formatLocationShort = (source) => {
  const { city = '', state = '', country: userCountry = '' } = source;

  const userCity = isEmpty(city) ? state : city;
  return [userCity, userCountry];
};

export const timezoneNumberToEST = (timezoneNumber) => {
  if (timezoneNumber > 0) {
    return `EST+${timezoneNumber}`;
  }
  if (timezoneNumber < 0) {
    return `EST${timezoneNumber}`;
  }
  return 'EST';
};

export const formatTimezone = (source) => {
  if (source.timezoneNumber === 0) {
    return '';
  }
  if (source.timezoneNumber > 0) {
    return `+${source.timezoneNumber}`;
  }
  return toString(source.timezoneNumber);
};

export const prepareLocationObject = (source) => {
  const _country = get(source, 'country', '');
  const _countryShort = get(source, 'countryShort', '');
  const _state = get(source, 'state', '');
  const _regionShort = get(source, 'regionShort', '');

  // fallback to avoid nulls
  const country = _country || _countryShort || '';
  const state = _state || _regionShort || '';

  const isUnitedState = isUSA(country);
  const stateCountryArr = [
    isUnitedState && _regionShort ? _regionShort : state,
    isUnitedState ? 'USA' : country,
  ];

  let shortAddress = isUnitedState
    ? filterJoinString(stateCountryArr, ', ')
    : isUnitedState
    ? 'USA'
    : country;
  let fullAddress = formatLocation(source);

  const tzNumber = get(source, 'timezoneNumber', '');
  const tzString = formatTimezone(source);

  if (tzString && !isUnitedState) {
    const tzEST = timezoneNumberToEST(tzNumber);
    shortAddress = `${shortAddress} ${tzString}`;
    fullAddress = `${fullAddress}, ${tzEST}`;
  }

  return {
    shortAddress,
    fullAddress,
  };
};

export const getInitials = (fullName) =>
  fullName ? map(fullName.split(' '), (word) => word.charAt(0)).join('') : '';

export const getClientLeadFullName = (lead) => {
  if (get(lead, 'displayName')) {
    return lead.displayName;
  }

  const clientName = get(lead, 'client.name');
  const leadName = get(lead, 'name', 'N/A');

  if (clientName) {
    return `${clientName} - ${leadName}`;
  }

  return leadName;
};

export const getMarkdownText = (comment) => {
  if (!comment) {
    return null;
  }

  const renderer = new marked.Renderer();
  const linkRenderer = renderer.link;
  renderer.link = (href, title, text) => {
    const html = linkRenderer.call(renderer, href, title, text);
    return html.replace(/^<a /, '<a target="_blank" ');
  };
  const rawMarkup = DOMPurify.sanitize(
    marked(comment, {
      renderer,
      breaks: true,
    }),
  );
  return { __html: rawMarkup };
};

export const sortDates = (date1, date2) => {
  if (!date1) {
    return 1;
  }
  if (!date2) {
    return -1;
  }
  const dateOne = moment(date1, 'YYYY-MM-DD');
  const dateTwo = moment(date2, 'YYYY-MM-DD');
  return dateOne.isSameOrAfter(dateTwo) ? 1 : -1;
};

export const rangePickerRanges = {
  Today: [dayjs(), dayjs()],
  Yesterday: [dayjs().subtract(1, 'days').startOf('day'), dayjs().subtract(1, 'days').endOf('day')],
  'This Week': [dayjs().startOf('week'), dayjs().endOf('week')],
  'Last Week': [
    dayjs().subtract(1, 'weeks').startOf('week'),
    dayjs().subtract(1, 'weeks').endOf('week'),
  ],
  'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
  'Last Month': [
    dayjs().subtract(1, 'months').startOf('month'),
    dayjs().subtract(1, 'months').endOf('month'),
  ],
};

export const getFingerprint = () =>
  new Promise(function (resolve, reject) {
    try {
      new Fingerprint2({
        excludeCanvas: true, // required for more consistent results on iphone
      }).get(resolve);
    } catch (e) {
      reject(e);
    }
  });

export const getJwtData = (payload) => {
  // new auth flow v3 via auth-api
  const { accessToken, refreshToken } = payload;
  const jwtData = jwt_decode(accessToken);

  const {
    legacyHireUser,
    legacyHirePermissions,
    permissions, // this is new auth-api permissions
  } = jwtData;

  const retrofitPermissions = {
    ...reverseAggregateLegacyPermissions(legacyHirePermissions),
    ...reverseAggregatePermissions(permissions),
  };

  return {
    user: {
      ...legacyHireUser,
      expires: jwtData.exp,
      accessToken,
      refreshToken,
    },
    permissions: retrofitPermissions,
  };
};
export const downloadFile = async ({ requestFn, fileName }) => {
  const response = await requestFn();

  if (!response || !response.blob) {
    return console.error('downloadFile, file not found', fileName);
  }

  const blob = await response.blob();

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;

  a.click();
};

export const buildTableSortOrderer = (tableSortedInformation) => (dataIndex) =>
  tableSortedInformation.columnKey === dataIndex ? tableSortedInformation.order : null;
