import { Typography, Space, Flex, Popover, Descriptions } from 'antd';

import formatting from '@src/shared/formatting';
import { isValidContractType } from '@src/shared/Util.js';

interface PersonCostAndSalaryData {
  cost_usd?: number | null;
  currency?: string | null;
  currency_salary?: number | null;
  usd_salary?: number | null;
  contract_type?: string | null;
}

interface PersonCostAndSalaryProps {
  data: PersonCostAndSalaryData;
  multipleLines?: boolean;
  url?: string;
}

function formatMoneyK(amount: number): string {
  return `US${formatting.formatMoney(formatting.round(amount / 1000, 1), 1)}k`;
}

function formatAnnualSalaryK(currency: string, currency_salary: number): string {
  return `${
    currency && currency !== 'USD' ? `${currency === 'CAD' ? 'CD' : currency}` : 'US'
  }${formatting.formatMoneyK(formatting.round((currency_salary * 12) / 1000, 0) * 1000, 0)}`;
}

export function PersonCostAndSalaryPopContent({
  data: { cost_usd, currency, currency_salary, usd_salary, contract_type },
  table = true,
}: {
  data: PersonCostAndSalaryData;
  table?: boolean;
}) {
  const validContractType = isValidContractType(contract_type?.substring(0, 2));
  const contractTypeLabel = validContractType ? 'Loaded Cost' : 'Monthly Cost';
  const formattedCost = formatMoneyK(cost_usd ?? 0);
  const contractTypeSuffix = validContractType ? ` (${contract_type?.substring(0, 2)})` : '';
  const formattedUsdSalary = formatMoneyK(usd_salary ?? 0);
  const annualSalary = formatAnnualSalaryK(currency ?? 'USD', currency_salary ?? 0);

  if (table) {
    return (
      <Descriptions column={1} bordered size="small">
        <Descriptions.Item label={contractTypeLabel}>
          {formattedCost}
          {contractTypeSuffix}
        </Descriptions.Item>
        {validContractType && (
          <Descriptions.Item label="Salary">{formattedUsdSalary}</Descriptions.Item>
        )}
        <Descriptions.Item label="Yearly Salary">{annualSalary}</Descriptions.Item>
      </Descriptions>
    );
  } else {
    return (
      <Space.Compact direction="vertical">
        <Flex gap={5}>
          <Typography.Text strong>{contractTypeLabel}</Typography.Text>
          <Typography.Text>
            {formattedCost}
            {contractTypeSuffix}
          </Typography.Text>
        </Flex>
        {validContractType && (
          <Flex gap={5}>
            <Typography.Text strong>Salary:</Typography.Text>
            <Typography.Text>{formattedUsdSalary}</Typography.Text>
          </Flex>
        )}
        <Flex gap={5}>
          <Typography.Text strong>Annual Salary:</Typography.Text>
          <Typography.Text>{annualSalary}</Typography.Text>
        </Flex>
      </Space.Compact>
    );
  }
}

export function getFormattedCost({
  data: { cost_usd },
  details = false,
}: {
  data: PersonCostAndSalaryData;
  details?: boolean;
}): string {
  return `${details ? 'Cost: ' : ''}${formatMoneyK(cost_usd ?? 0)}`;
}

export function getFormattedSalaryWithContractType({
  data: { currency, currency_salary, contract_type },
  details = false,
}: {
  data: PersonCostAndSalaryData;
  details?: boolean;
}): { formattedYearlySalary: string; contractTypeFormatted: string } {
  const formattedYearlySalary = `${details ? 'Annual Salary: ' : ''}${formatAnnualSalaryK(
    currency ?? 'USD',
    currency_salary ?? 0,
  )}`;

  const shortContractType = contract_type?.substring(0, 2);
  const isRelevantContractType = isValidContractType(shortContractType);
  const contractTypeFormatted = isRelevantContractType
    ? details
      ? `, Contract Type: ${shortContractType}`
      : ` (${shortContractType})`
    : '';

  return { formattedYearlySalary, contractTypeFormatted };
}

function PersonCostAndSalary({ data, multipleLines, url }: PersonCostAndSalaryProps) {
  const { cost_usd, currency, currency_salary, contract_type } = data;

  const formattedCost = getFormattedCost({ data: { cost_usd } });
  const { formattedYearlySalary, contractTypeFormatted } = getFormattedSalaryWithContractType({
    data: {
      currency,
      currency_salary,
      contract_type,
    },
  });

  const color = url ? '#faad14' : '';

  return (
    <>
      <Popover content={<PersonCostAndSalaryPopContent data={data} />}>
        {multipleLines ? (
          <>
            <Space.Compact direction="vertical">
              <Flex gap={3}>
                <Typography.Text strong>{formattedCost}</Typography.Text>
                <Typography.Text type="secondary">{contractTypeFormatted}</Typography.Text>
              </Flex>
              <Typography.Text type="secondary">{`${formattedYearlySalary}`}</Typography.Text>
            </Space.Compact>
          </>
        ) : (
          <>
            <Typography.Text strong className="person-rate" style={{ color }}>
              {formattedCost}
            </Typography.Text>
            <Typography.Text className="person-rate" style={{ color }}>
              {` - ${formattedYearlySalary}${contractTypeFormatted}`}
            </Typography.Text>
          </>
        )}
      </Popover>
    </>
  );
}

export default PersonCostAndSalary;
