import { z } from 'zod';
import { Endpoint, Method, FilterOperations, ReportsQuerySchema } from '../../infrastructure';
import { dateString } from '../../infrastructure/schema';
import { GetFunnelReportItemSchema } from './base';

export type GetFunnelReportEndpoint = Endpoint<
  Method.GET,
  '/api/v2/funnel-report',
  GetFunnelReportResponse,
  undefined,
  GetFunnelReportQuery,
  undefined
>;

export const GetFunnelReportFiltersSchema = z
  .object({
    jobs: z.record(z.enum([FilterOperations.IN]), z.string().array()),
    after: z.record(z.enum([FilterOperations.EQUAL]), dateString),
    before: z.record(z.enum([FilterOperations.EQUAL]), dateString),
  })
  .partial();
export type GetFunnelReportFilters = z.infer<typeof GetFunnelReportFiltersSchema>;

export const GetFunnelReportQuerySchema = ReportsQuerySchema.extend({
  filters: GetFunnelReportFiltersSchema.optional(),
});
export type GetFunnelReportQuery = z.infer<typeof GetFunnelReportQuerySchema>;

export const GetFunnelReportResponseSchema = z.object({
  funnel: GetFunnelReportItemSchema,
  current: GetFunnelReportItemSchema,
});

export type GetFunnelReportResponse = z.infer<typeof GetFunnelReportResponseSchema>;
