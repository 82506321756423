import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE
const constants = {
  GET_JOB_APPLICATIONS_SUMMARY: createSagaAction('GET_JOB_APPLICATIONS_SUMMARY'),
};

const actions = {
  getJobApplicationsSummary: (args) => ({
    type: constants.GET_JOB_APPLICATIONS_SUMMARY.REQUEST,
    ...args,
  }),
};

const handlers = {
  [constants.GET_JOB_APPLICATIONS_SUMMARY.SUCCESS]: (state, { applicationsSummary }) => {
    return { ...state, applicationsSummary };
  },
  [constants.GET_JOB_APPLICATIONS_SUMMARY.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

// SAGA
function* getJobApplicationsSummary(action) {
  try {
    const response = yield call(getJobApplicationsSummaryApi, action);
    const applicationsSummary = response.data;

    yield put({ type: constants.GET_JOB_APPLICATIONS_SUMMARY.SUCCESS, applicationsSummary });
  } catch (error) {
    yield put({
      type: constants.GET_JOB_APPLICATIONS_SUMMARY.FAILURE,
      error: error.message || error,
    });
  }
}

function* watchGetJobApplicationsSummary() {
  yield takeLatest(constants.GET_JOB_APPLICATIONS_SUMMARY.REQUEST, getJobApplicationsSummary);
}

// API
export function getJobApplicationsSummaryApi() {
  return request('/jobs/applications-summary', {
    method: 'GET',
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchGetJobApplicationsSummary,
};
