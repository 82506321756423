import { z } from 'zod';

export const RNVWaterfallSchema = z.object({
  rnv_waterfall_id: z.number(),
  years_of_service: z.number(),
  anniversary_units: z.number(),
  previous_balance: z.number(),
  new_balance: z.number(),
});

export type RNVWaterfall = z.infer<typeof RNVWaterfallSchema>;
