import { Endpoint, Method } from '../../../infrastructure';
import { PsnRctrGoogleCalendarChannel } from '../base';
import { PsnRctrGoogleCalendarChannelUpdateSchema } from '../crud';
import { z } from 'zod';

export const PsnRctrGoogleCalendarChannelUpdateRequestSchema =
  PsnRctrGoogleCalendarChannelUpdateSchema.pick({
    dt_expiration: true,
    dt_last_notification: true,
    is_active: true,
    resource_id: true,
    resource_uri: true,
  });
export type PsnRctrGoogleCalendarChannelUpdateRequest = z.infer<
  typeof PsnRctrGoogleCalendarChannelUpdateRequestSchema
>;

export const PsnRctrGoogleCalendarChannelUpdateParamsSchema =
  PsnRctrGoogleCalendarChannelUpdateSchema.pick({
    calendar_id: true,
  });

export type PsnRctrGoogleCalendarChannelUpdateParams = z.infer<
  typeof PsnRctrGoogleCalendarChannelUpdateParamsSchema
>;

export type PsnRctrGoogleCalendarChannelUpdateResponse = PsnRctrGoogleCalendarChannel;

export type PsnRctrGoogleCalendarChannelUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/psn-rctr-google-calendar-channel/${PsnRctrGoogleCalendarChannelUpdateParams['calendar_id']}`,
  PsnRctrGoogleCalendarChannelUpdateResponse,
  PsnRctrGoogleCalendarChannelUpdateParams,
  undefined,
  PsnRctrGoogleCalendarChannelUpdateRequest
>;
