import { z } from 'zod';

import { Endpoint, Method, FilterOperations } from '../../infrastructure';
import { PreLead, PreLeadClient } from './base';

export const ListPreLeadsFiltersSchema = z
  .object({
    ymonth: z.record(
      z.enum([FilterOperations.GREATER_THAN_OR_EQUAL, FilterOperations.LESS_THAN_OR_EQUAL]),
      z.coerce.number(),
    ),
  })
  .partial();
export type ListPreLeadsFilters = z.infer<typeof ListPreLeadsFiltersSchema>;

export const ListPreLeadsQuerySchema = z.object({
  filters: ListPreLeadsFiltersSchema.optional(),
});
export type ListPreLeadsQuery = z.infer<typeof ListPreLeadsQuerySchema>;

export type ListPreLeadsResponse = {
  clients: Array<PreLeadClient>;
  preLeads: PreLead[];
};

export type ListPreLeadsEndpoint = Endpoint<
  Method.GET,
  '/api/v3/pre-leads',
  ListPreLeadsResponse,
  undefined,
  ListPreLeadsQuery,
  undefined
>;
