import { z } from 'zod';

import { Project } from '../projects';

export const ClientSchema = z.object({
  client_id: z.string(),
  name: z.string(),
  isactive: z.boolean(),
  isdev: z.boolean(),
  is_pre_client: z.boolean(),
  is_forecast_client: z.boolean(),
  dtcreate: z.string(),
  dtupdate: z.string().nullable(),
  ext_billing_client_id: z.string().nullable(),
  short_name: z.string().nullable(),
  client_as_of_ymonth: z.number().nullish(),
  client_sf_id: z.string().nullable(),
  industry: z.string().nullable(),
  size: z.string().nullable(),
  country_id: z.string().max(2).nullable(),
  state_or_province: z.string().nullable(),
  dt_archived: z.string().nullable(),
});

export type Client = z.infer<typeof ClientSchema>;

export const ClientMongoSchema = z.object({
  _id: z.string(),
  name: z.string(),
  shortName: z.string().nullable(),
  phone: z.string().nullish(),
  email: z.string().nullish(),
  logo: z.string().nullish(),
  notes: z.string().nullish(),
  isArchived: z.boolean().default(false),
  created_at: z.string(),
  updated_at: z.string(),
});

export type ClientMongo = z.infer<typeof ClientMongoSchema>;

type ClientFields =
  | 'client_id'
  | 'name'
  | 'short_name'
  | 'dt_archived'
  | 'industry'
  | 'size'
  | 'country_id'
  | 'state_or_province';

type ProjectFields = 'project_id' | 'name' | 'short_name' | 'dt_archived';

export type ClientWithProject = Pick<Client, ClientFields> & {
  projects: Pick<Project, ProjectFields>[];
};

export type ClientWithProjectStats = Pick<Client, ClientFields> & {
  total_projects: number;
  active_projects: number;
};
