import { z } from 'zod';
import {
  Endpoint,
  Method,
  FilterOperations,
  ReportsQuerySchema,
  makeSorter,
  ReportResponse,
} from '../../infrastructure';
import { PaymentWithPerson } from './base';

export type ListPaymentsEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/payments',
  ListPaymentsResponse,
  undefined,
  ListPaymentsQuery,
  undefined
>;

export const ListPaymentsFiltersSchema = z
  .object({
    person_name: z.record(z.enum([FilterOperations.CONTAIN]), z.string()),
    ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
    payment_provider_id: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
  })
  .partial();
export type ListPaymentsFilters = z.infer<typeof ListPaymentsFiltersSchema>;

export const ListPaymentsSorterSchema = makeSorter(z.enum(['person_name', 'total_amount']));
export type ListPaymentsSorter = z.infer<typeof ListPaymentsSorterSchema>;

export const ListPaymentsQuerySchema = ReportsQuerySchema.extend({
  sorter: ListPaymentsSorterSchema.optional(),
  filters: ListPaymentsFiltersSchema.optional(),
});
export type ListPaymentsQuery = z.infer<typeof ListPaymentsQuerySchema>;

export type ListPaymentsResponse = ReportResponse<PaymentWithPerson>;
