import { PropsWithChildren } from 'react';

import config from '@src/config';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

const msalInstanceProvider = new PublicClientApplication({
  auth: {
    clientId: config.POWER_BI_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${config.POWER_BI_AZURE_TENANT_ID}/`,
    redirectUri: window.location.origin + '/admin/dashboard/',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
});

const MicrosoftAuthenticationProvider = ({ children }: PropsWithChildren) => {
  return <MsalProvider instance={msalInstanceProvider}>{children}</MsalProvider>;
};

export default MicrosoftAuthenticationProvider;
