import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { PreLead } from './base';
import { CreatePreLeadRequestSchema } from './createPreLead';

export const OppConversionToPreOppRequestSchema = CreatePreLeadRequestSchema.extend({
  lead_id: z.string(),
});
export type OppConversionToPreOppRequest = z.infer<typeof OppConversionToPreOppRequestSchema>;

export type OppConversionToPreOppResponse = PreLead;

export type OppConversionToPreOppEndpoint = Endpoint<
  Method.POST,
  '/api/v3/pre-leads/opp-conversion',
  OppConversionToPreOppResponse,
  undefined,
  undefined,
  OppConversionToPreOppRequest
>;
