import { z } from 'zod';
import { PersonSchema } from './base';
import { Endpoint, Method } from '../../infrastructure';

export const PersonGetActiveSalaryParamsSchema = z.object({
  person_id: z.string(),
});
export type PersonGetActiveSalaryParams = z.infer<typeof PersonGetActiveSalaryParamsSchema>;

export const PersonGetActiveSalaryResponseSchema = PersonSchema.pick({
  person_id: true,
  name: true,
  dthired: true,
  dtstart: true,
}).extend({
  cost: z.number(),
  salary_currency: z.number(),
  currency: z.string(),
  usd_salary: z.number(),
  contract_type: z.string(),
});
export type PersonGetActiveSalaryResponse = z.infer<
  typeof PersonGetActiveSalaryResponseSchema
> | void;

export type PersonGetActiveSalaryEnpdoint = Endpoint<
  Method.GET,
  `/api/v2/person/${PersonGetActiveSalaryParams['person_id']}/active-salary`,
  PersonGetActiveSalaryResponse,
  PersonGetActiveSalaryParams,
  undefined,
  undefined
>;
