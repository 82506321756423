import { z } from 'zod';
import { CommentListItemSchema } from '../../comment';
import { Endpoint, Method } from '../../../infrastructure';

export const PrjCommentListItemSchema = CommentListItemSchema.extend({
  project_id: z.string(),
  project_name: z.string(),
});
export type PrjCommentListItem = z.infer<typeof PrjCommentListItemSchema>;

export const PrjCommentListParamsSchema = z.object({
  project_id: z.string(),
});
export type PrjCommentListParams = z.infer<typeof PrjCommentListParamsSchema>;

export type PrjCommentListResponse = PrjCommentListItem[];

export type PrjCommentListEndpoint = Endpoint<
  Method.GET,
  `/api/v2/prj-comments/${PrjCommentListParams['project_id']}`,
  PrjCommentListResponse,
  PrjCommentListParams,
  undefined,
  undefined
>;
