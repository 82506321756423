import { call, put, takeLatest } from 'redux-saga/effects';

import isFunction from 'lodash/isFunction';
import map from 'lodash/map';
import get from 'lodash/get';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE
export const constants = {
  UPDATE_JOB_IMAGE: createSagaAction('UPDATE_JOB_IMAGE'),
};

const actions = {
  updateJobImage: (args) => ({
    type: constants.UPDATE_JOB_IMAGE.REQUEST,
    ...args,
  }),
};

const handlers = {
  [constants.UPDATE_JOB_IMAGE.SUCCESS]: (state, { updatedJob }) => {
    const stateAllJobs = get(state, 'allJobs', []);

    const newAllJobs = map(stateAllJobs, (job) => {
      if (get(job, '_id') === get(updatedJob, '_id')) {
        return { ...updatedJob };
      }
      return job;
    });

    return {
      ...state,
      allJobs: [...newAllJobs],
    };
  },
  [constants.UPDATE_JOB_IMAGE.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

// SAGA
function* updateJobImage(action) {
  try {
    const response = yield call(apiUpdateJobImage, action);

    const updatedJob = response.data;

    yield put({ type: constants.UPDATE_JOB_IMAGE.SUCCESS, updatedJob });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(updatedJob);
    }
  } catch (error) {
    yield put({
      type: constants.UPDATE_JOB_IMAGE.FAILURE,
      error: error.message || error,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(error.message || error);
    }
  }
}

function* watchUpdateJobImage() {
  yield takeLatest(constants.UPDATE_JOB_IMAGE.REQUEST, updateJobImage);
}

// API
export function apiUpdateJobImage({ jobId, imageFile }) {
  return request(`/jobs/${jobId}/image`, {
    method: 'PUT',
    body: imageFile,
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchUpdateJobImage,
};
