import { z } from 'zod';
import { ProjectSchema } from './base';
import { Endpoint, Method } from '../../infrastructure';

export const ProjectDeleteSchema = ProjectSchema.pick({
  project_id: true,
});

export type ProjectDelete = z.infer<typeof ProjectDeleteSchema>;

export const ProjectDeleteParamsSchema = ProjectSchema.pick({
  project_id: true,
});
export type ProjectDeleteParams = z.infer<typeof ProjectDeleteParamsSchema>;

export type ProjectDeleteEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/sql-projects/${ProjectDeleteParams['project_id']}`,
  {},
  ProjectDeleteParams,
  undefined,
  undefined
>;
