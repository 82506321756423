import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE
const constants = {
  GET_JOB_QUESTIONS: createSagaAction('GET_JOB_QUESTIONS'),
};

const actions = {
  getJobQuestions: (args) => ({
    type: constants.GET_JOB_QUESTIONS.REQUEST,
    ...args,
  }),
};

const handlers = {
  [constants.GET_JOB_QUESTIONS.SUCCESS]: (state, { questions }) => {
    return { ...state, questions };
  },
  [constants.GET_JOB_QUESTIONS.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

// SAGA
function* getJobQuestions(action) {
  try {
    const response = yield call(apiGetJobQuestions, action);
    const questions = response.data;

    yield put({ type: constants.GET_JOB_QUESTIONS.SUCCESS, questions });
  } catch (error) {
    yield put({
      type: constants.GET_JOB_QUESTIONS.FAILURE,
      error: error.message || error,
    });
  }
}

function* watchGetJobQuestions() {
  yield takeLatest(constants.GET_JOB_QUESTIONS.REQUEST, getJobQuestions);
}

// API
export function apiGetJobQuestions({ jobId }) {
  return request(`/jobs/${jobId}/questions`, {
    method: 'GET',
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchGetJobQuestions,
};
