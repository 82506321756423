// SCHEDULE INTERVIEW

import * as api from '@redux/api/application';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';

import isFunction from 'lodash/isFunction';
import * as schemas from '@src/schemas';
import { normalize } from 'normalizr';
import { actions as entitiesActions } from '@redux/modules/entities';

// GET INTERVIEW LIST

function* getInterviewList(action) {
  try {
    const interviewList = yield call(api.getApplicationInterviewList, action);

    yield put({ type: constants.APPLICATION_INTERVIEW_LIST_GET.SUCCESS, interviewList });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_INTERVIEW_LIST_GET.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchGetInterviewList() {
  yield takeLatest(constants.APPLICATION_INTERVIEW_LIST_GET.REQUEST, getInterviewList);
}

function* scheduleInterview(action) {
  try {
    const response = yield call(api.scheduleInterview, action);

    // update the application state with the response
    const normalized = normalize(response.data.application, schemas.application);
    const { users, applications } = normalized.entities;
    // //;
    yield put(entitiesActions.mergeApplications(applications));
    yield put(entitiesActions.mergeUsers(users));

    yield put({
      type: constants.APPLICATION_SCHEDULE_INTERVIEW.SUCCESS,
    });

    const interviewList = yield call(api.getApplicationInterviewList, action);
    yield put({ type: constants.APPLICATION_INTERVIEW_LIST_GET.SUCCESS, interviewList });

    const timelineResponse = yield call(api.getApplicationTimelineList, action);
    const timelineList = (timelineResponse && timelineResponse.data) || [];
    yield put({ type: constants.APPLICATION_TIMELINES_GET.SUCCESS, timelineList });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.APPLICATION_SCHEDULE_INTERVIEW.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail();
    }
  }
}

export function* watchScheduleInterview() {
  yield takeEvery(constants.APPLICATION_SCHEDULE_INTERVIEW.REQUEST, scheduleInterview);
}

function* scheduleInterviewNotification(action) {
  try {
    yield call(api.scheduleInterviewNotification, action);
    yield put({
      type: constants.APPLICATION_SCHEDULE_INTERVIEW_NOTIFICATION.SUCCESS,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.APPLICATION_SCHEDULE_INTERVIEW_NOTIFICATION.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail();
    }
  }
}

export function* watchScheduleInterviewNotification() {
  yield takeEvery(
    constants.APPLICATION_SCHEDULE_INTERVIEW_NOTIFICATION.REQUEST,
    scheduleInterviewNotification,
  );
}

export function* bookCalendlyExtensionInterview(action) {
  try {
    const calendlyLink = yield call(api.bookCalendlyExtensionInterview, action);
    yield put({
      type: constants.APPLICATION_SCHEDULE_INTERVIEW_GET_CALENDLY_LINK.SUCCESS,
      calendlyLink,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(calendlyLink);
    }
  } catch (e) {
    yield put({
      type: constants.APPLICATION_SCHEDULE_INTERVIEW_GET_CALENDLY_LINK.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail();
    }
  }
}

export function* watchBookCalendlyExtensionInterview() {
  yield takeEvery(
    constants.APPLICATION_SCHEDULE_INTERVIEW_GET_CALENDLY_LINK.REQUEST,
    bookCalendlyExtensionInterview,
  );
}

// CREATE NEW INTERVIEW

function* createApplicationInterview(action) {
  try {
    yield call(api.createApplicationInterview, action);
    yield put({
      type: constants.APPLICATION_CREATE_INTERVIEW.SUCCESS,
    });
    const interviewList = yield call(api.getApplicationInterviewList, action);
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
    yield put({ type: constants.APPLICATION_INTERVIEW_LIST_GET.SUCCESS, interviewList });
    const { applicationId } = action;
    yield put({ type: constants.APPLICATION_GET.REQUEST, applicationId });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_CREATE_INTERVIEW.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFailure)) {
      yield action.onFailure(e);
    }
  }
}

export function* watchCreateApplicationInterview() {
  yield takeLatest(constants.APPLICATION_CREATE_INTERVIEW.REQUEST, createApplicationInterview);
}

// DELETE INTERVIEW

function* deleteApplicationInterview(action) {
  try {
    yield call(api.deleteApplicationInterview, action);

    // update the application state with the response
    yield put({
      type: constants.APPLICATION_DELETE_INTERVIEW.SUCCESS,
    });

    const { applicationId } = action;
    yield put({ type: constants.APPLICATION_GET.REQUEST, applicationId });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_DELETE_INTERVIEW.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchDeleteApplicationInterview() {
  yield takeLatest(constants.APPLICATION_DELETE_INTERVIEW.REQUEST, deleteApplicationInterview);
}

// GET INTERVIEWER AVAILABILITY
function* getInterviewerAvailability(action) {
  try {
    if (!action.interviewerId || !action.date) {
      return yield put({
        type: constants.APPLICATION_INTERVIEWER_AVAILABILITY_GET.SUCCESS,
        interviewerAvailability: {
          interviewer: action.interviewerId,
          date: action.date,
          list: [],
        },
      });
    } else {
      const availability = yield call(api.getInterviewerAvailability, action);
      yield put({
        type: constants.APPLICATION_INTERVIEWER_AVAILABILITY_GET.SUCCESS,
        interviewerAvailability: {
          interviewer: action.interviewerId,
          date: action.date,
          list: availability.calendars.primary.busy,
        },
      });
    }
  } catch (e) {
    yield {
      type: constants.APPLICATION_INTERVIEWER_AVAILABILITY_GET.FAILURE,
      error: e.message || e,
    };
  }
}

export function* watchGetInterviewerAvailability() {
  yield takeLatest(
    constants.APPLICATION_INTERVIEWER_AVAILABILITY_GET.REQUEST,
    getInterviewerAvailability,
  );
}

// GET INTERVIEW DETAILS

function* getInterviewDetails(action) {
  try {
    const interviewDetails = yield call(api.getApplicationInterview, action);
    yield put({ type: constants.APPLICATION_GET_INTERVIEW_DETAILS.SUCCESS, interviewDetails });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_GET_INTERVIEW_DETAILS.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchGetInterviewDetails() {
  yield takeLatest(constants.APPLICATION_GET_INTERVIEW_DETAILS.REQUEST, getInterviewDetails);
}
// UPDATE INTERVIEW

function* updateApplicationInterview(action) {
  try {
    yield call(api.updateApplicationInterview, action);
    yield put({
      type: constants.APPLICATION_UPDATE_INTERVIEW.SUCCESS,
    });
    const interviewList = yield call(api.getApplicationInterviewList, action);
    yield put({ type: constants.APPLICATION_INTERVIEW_LIST_GET.SUCCESS, interviewList });
    const { applicationId } = action;
    yield put({ type: constants.APPLICATION_GET.REQUEST, applicationId });

    if (isFunction(action.onSuccess)) {
      yield action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: constants.APPLICATION_UPDATE_INTERVIEW.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onFailure)) {
      yield action.onFailure(e);
    }
  }
}

export function* watchUpdateApplicationInterview() {
  yield takeLatest(constants.APPLICATION_UPDATE_INTERVIEW.REQUEST, updateApplicationInterview);
}

// RESET INTERVIEW DETAILS

function* resetInterviewDetails(action) {
  try {
    yield put({ type: constants.APPLICATION_RESET_INTERVIEW_DETAILS.SUCCESS });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_RESET_INTERVIEW_DETAILS.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchResetInterviewDetails() {
  yield takeLatest(constants.APPLICATION_RESET_INTERVIEW_DETAILS.REQUEST, resetInterviewDetails);
}

// RESET APPLICATION DETAILS

function* resetApplicationInfo(action) {
  try {
    yield put({ type: constants.APPLICATION_RESET_APPLICATION_INFO.SUCCESS });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_RESET_APPLICATION_INFO.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchResetApplicationInfo() {
  yield takeLatest(constants.APPLICATION_RESET_APPLICATION_INFO.REQUEST, resetApplicationInfo);
}

// RESET APPLICATION VIDEO ANSWERS

function* resetApplicationVideoAnswers(action) {
  try {
    yield put({ type: constants.APPLICATION_RESET_APPLICATION_VIDEO_ANSWERS.SUCCESS });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_RESET_APPLICATION_VIDEO_ANSWERS.FAILURE,
      error: e.message || e,
    });
  }
}
export function* watchResetApplicationVideoAnswers() {
  yield takeLatest(
    constants.APPLICATION_RESET_APPLICATION_VIDEO_ANSWERS.REQUEST,
    resetApplicationVideoAnswers,
  );
}
