import { z } from 'zod';
import { SkillDWSchema } from './Skill';
import { SkillGroupDWSchema } from './SkillGroup';

export const SkillGroupAscDWSchema = z.object({
  skill_id: z.number(),
  skill_group_id: z.number(),
});
export type SkillGroupAscDW = z.infer<typeof SkillGroupAscDWSchema>;

export const SkillGroupAscDWWithMongoIdsSchema = SkillGroupAscDWSchema.merge(
  SkillDWSchema.pick({ mongo_skill_id: true }),
).merge(
  SkillGroupDWSchema.pick({
    mongo_group_id: true,
  }),
);
export type SkillGroupAscDWWithMongoIds = z.infer<typeof SkillGroupAscDWWithMongoIdsSchema>;

export const SkillGroupAscMongoSchema = z.object({
  group_id: z.string(),
  skill_id: z.string(),
});

export type SkillGroupAscMongo = z.infer<typeof SkillGroupAscMongoSchema>;
