import { z } from 'zod';

import { Endpoint, Method } from '../../infrastructure';
import { NumberRangeSchema } from '../../infrastructure/schema';
import { PreLead } from './base';

export const UpdatePreLeadRequestSchema = z
  .object({
    name: z.string(),
    cells: z.record(z.number()),
    from_ymonth: NumberRangeSchema,
    month_duration: NumberRangeSchema,
    team_size_range: NumberRangeSchema,
    rev_rate_range: NumberRangeSchema,
    pct_confidence: z.number().min(0).max(100),
  })
  .partial();

export type UpdatePreLeadRequest = z.infer<typeof UpdatePreLeadRequestSchema>;

export type UpdatePreLeadResponse = PreLead;

export type UpdatePreLeadParams = {
  grid_row_id: number;
};

export type UpdatePreLeadEndpoint = Endpoint<
  Method.PUT,
  `/api/v3/pre-leads/:${UpdatePreLeadParams['grid_row_id']}`,
  UpdatePreLeadResponse,
  UpdatePreLeadParams,
  undefined,
  UpdatePreLeadRequest
>;
