import { z } from 'zod';
import { PsnPrjGoogleCalendarDeleteSchema } from '../crud';
import { PsnPrjGoogleCalendar } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const PsnPrjGoogleCalendarDeleteParamsSchema = PsnPrjGoogleCalendarDeleteSchema.pick({
  person_id: true,
  prj_google_calendar_id: true,
});

export type PsnPrjGoogleCalendarDeleteParams = z.infer<
  typeof PsnPrjGoogleCalendarDeleteParamsSchema
>;

export type PsnPrjGoogleCalendarDeleteResponse = PsnPrjGoogleCalendar;

export type PsnPrjGoogleCalendarDeleteEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/psn-prj-google-calendar/${PsnPrjGoogleCalendarDeleteParams['person_id']}/${PsnPrjGoogleCalendarDeleteParams['prj_google_calendar_id']}`,
  PsnPrjGoogleCalendarDeleteResponse,
  undefined,
  undefined,
  undefined
>;
