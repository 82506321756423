import { z } from 'zod';
import { POCSchema, POCStatus } from './basePOC';

export const DeclinePOCSchema = POCSchema.pick({
  payment_option_change_id: true,
  declined_at: true,
  declined_by: true,
}).extend({
  status: z.enum([POCStatus.Declined]),
});

export type DeclinePOC = z.infer<typeof DeclinePOCSchema>;
