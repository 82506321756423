import * as api from '@redux/api/application';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';
import { actions as entitiesActions } from '@redux/modules/entities';
import { normalize } from 'normalizr';
import * as schemas from '@src/schemas';
import isFunction from 'lodash/isFunction';

//JOB_IMAGE_UPDATE
function* uploadDocument(action) {
  try {
    const response = yield call(api.uploadDocument, action);

    const updatedApplication = response.data;
    const normalized = normalize(updatedApplication, schemas.application);
    const { applications } = normalized.entities;
    yield put(entitiesActions.mergeApplications(applications));

    yield put({ type: constants.APPLICATION_UPLOAD_DOCUMENT.SUCCESS, updatedApplication });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(updatedApplication);
    }
  } catch (error) {
    yield put({
      type: constants.APPLICATION_UPLOAD_DOCUMENT.FAILURE,
      error: error.message || error,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(error.message || error);
    }
  }
}

export function* watchUploadDocument() {
  yield takeLatest(constants.APPLICATION_UPLOAD_DOCUMENT.REQUEST, uploadDocument);
}

function* deleteDocument(action) {
  try {
    const response = yield call(api.deleteDocument, action);

    const updatedApplication = response.data;
    const normalized = normalize(updatedApplication, schemas.application);
    const { applications } = normalized.entities;
    yield put(entitiesActions.mergeApplications(applications));

    yield put({ type: constants.APPLICATION_DELETE_DOCUMENT.SUCCESS, updatedApplication });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(updatedApplication);
    }
  } catch (error) {
    yield put({
      type: constants.APPLICATION_DELETE_DOCUMENT.FAILURE,
      error: error.message || error,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(error.message || error);
    }
  }
}

export function* watchDeleteDocument() {
  yield takeLatest(constants.APPLICATION_DELETE_DOCUMENT.REQUEST, deleteDocument);
}

function* downloadDocument(action) {
  try {
    const response = yield call(api.downloadDocument, action);
    const blob = yield response.blob();

    yield put({
      type: constants.APPLICATION_DOWNLOAD_DOCUMENT.SUCCESS,
      blob,
      documentUrl: action.documentUrl,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess({
        blob,
        documentUrl: action.documentUrl,
      });
    }
  } catch (error) {
    yield put({
      type: constants.APPLICATION_DOWNLOAD_DOCUMENT.FAILURE,
      error: error.message || error,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(error.message || error);
    }
  }
}

export function* watchDownloadDocument() {
  yield takeLatest(constants.APPLICATION_DOWNLOAD_DOCUMENT.REQUEST, downloadDocument);
}
