import { all, fork, call, put, takeLatest, select } from 'redux-saga/effects';

import { constants } from '@redux/modules/reports/applications';
import { constants as jobConstants } from '@redux/jobs';

import * as api from '@redux/api/applications-report';

function* getApplications(action) {
  try {
    const response = yield call(api.getApplications, action);
    yield put({ type: constants.APPLICATIONS_REPORT_GET.SUCCESS, data: response });
  } catch (e) {
    yield put({
      type: constants.APPLICATIONS_REPORT_GET.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetApplications() {
  yield takeLatest(constants.APPLICATIONS_REPORT_GET.REQUEST, getApplications);
}

function* refetchApplicationsByJob() {
  try {
    const query = yield select((state) => state.applicationProgressReport.activeQuery);
    const response = yield call(api.getApplications, query);
    yield put({ type: constants.APPLICATIONS_REPORT_GET.SUCCESS, data: response });
  } catch (e) {
    yield put({
      type: constants.APPLICATIONS_REPORT_GET.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchUpdateJobStatus() {
  yield takeLatest(jobConstants.UPDATE_JOB_STATUS.SUCCESS, refetchApplicationsByJob);
}

function* watchMoveJobApplications() {
  yield takeLatest(jobConstants.MOVE_JOB_APPLICATIONS.SUCCESS, refetchApplicationsByJob);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetApplications),
    fork(watchUpdateJobStatus),
    fork(watchMoveJobApplications),
  ]);
}
