import request from '../../shared/request';

export function getAllRejectionReasons() {
  return request('/rejection-reasons', {
    method: 'GET',
  });
}

export function createRejectionReasons({ rejectionReason }) {
  return request('/rejection-reasons', {
    method: 'POST',
    body: {
      ...rejectionReason,
    },
  });
}

export function updateRejectionReasons({ id, rejectionReason }) {
  return request(`/rejection-reasons/${id}`, {
    method: 'PUT',
    body: {
      ...rejectionReason,
    },
  });
}

export function deleteRejectionReasons({ id }) {
  return request(`/rejection-reasons/${id}`, {
    method: 'DELETE',
  });
}

//Get all application that was reject
export function getAllRejectedApplications() {
  return request('/rejection-reasons/applications-rejected', {
    method: 'GET',
  });
}
