import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@shared/request';
import { createSagaAction } from '@shared/sagas';

// MODULE
const constants = {
  GET_SEARCHABLE_JOBS: createSagaAction('GET_SEARCHABLE_JOBS'),
};

const actions = {
  getSearchable: () => ({
    type: constants.GET_SEARCHABLE_JOBS.REQUEST,
  }),
};

const handlers = {
  [constants.GET_SEARCHABLE_JOBS.SUCCESS]: (state, { searchableJobs }) => {
    return { ...state, searchableJobs };
  },
  [constants.GET_SEARCHABLE_JOBS.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

// SAGA
function* getSearchableJobs(action) {
  try {
    const response = yield call(apiGetSearchableJobs, action);
    const searchableJobs = response.data;

    yield put({ type: constants.GET_SEARCHABLE_JOBS.SUCCESS, searchableJobs });
  } catch (e) {
    yield put({
      type: constants.GET_SEARCHABLE_JOBS.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchGetSearchableJobs() {
  yield takeLatest(constants.GET_SEARCHABLE_JOBS.REQUEST, getSearchableJobs);
}

// API
export function apiGetSearchableJobs() {
  return request('/jobs?mode=clean', {
    method: 'GET',
  });
}

// INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchGetSearchableJobs,
};
