import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const PositionSkillSchema = z.object({
  position_id: z.string(),
  skill_id: z.number(),
  dt_created: dateString,
  dt_updated: dateString,
});

export type PositionSkill = z.infer<typeof PositionSkillSchema>;
