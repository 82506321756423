import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const TimeOffRequestCommentDeleteParamsSchema = z.object({
  time_off_request_comment_id: z.coerce.number(),
});
export type TimeOffRequestCommentDeleteParams = z.infer<
  typeof TimeOffRequestCommentDeleteParamsSchema
>;

export type TimeOffRequestCommentDeleteResponse = void;

export type TimeOffRequestCommentDeleteEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/time-off-request-comments/${TimeOffRequestCommentDeleteParams['time_off_request_comment_id']}`,
  TimeOffRequestCommentDeleteResponse,
  TimeOffRequestCommentDeleteParams,
  undefined,
  undefined
>;
