import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const UploadBadgeGrantsRequestSchema = z.object({
  fileUpstream: z.any().optional(),
});
export type UploadBadgeGrantsRequest = z.infer<typeof UploadBadgeGrantsRequestSchema>;

export type UploadBadgeGrantsEndpoint = Endpoint<
  Method.POST,
  '/api/v2/check-in/badge/upload',
  UploadBadgeGrantsResponse,
  undefined,
  undefined,
  UploadBadgeGrantsRequest
>;

export const BadgeGrantUploadRowSchema = z.object({
  personIdOrEmail: z.string(),
  personName: z.string(),
  slug: z.string(),
  amount: z.number(),
  description: z.string(),
});
export type BadgeGrantUploadRow = z.infer<typeof BadgeGrantUploadRowSchema>;

export const UploadBadgeGrantsRowErrorSchema = z.object({
  personIdOrEmail: z.string(),
  personName: z.string(),
  slug: z.string(),
  amount: z.number(),
  description: z.string(),
  errorMessage: z.string(),
});
export type UploadBadgeGrantsRowError = z.infer<typeof UploadBadgeGrantsRowErrorSchema>;

export const UploadBadgeGrantsResponseSchema = z.object({
  total: z.number(),
  processed: z.number(),
  failures: z.number(),
  failedRecords: z.array(UploadBadgeGrantsRowErrorSchema),
});
export type UploadBadgeGrantsResponse = z.infer<typeof UploadBadgeGrantsResponseSchema>;
