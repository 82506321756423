import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const ApplicationTagAscSchema = z.object({
  application_tag_asc_id: z.number(),
  application_id: z.string(),
  application_tag_id: z.number(),
  dt_start: z.union([z.number(), dateString]),
  dt_end: z.union([z.number(), dateString]).nullable(),
  is_active: z.boolean(),
});

export type ApplicationTagAsc = z.infer<typeof ApplicationTagAscSchema>;
