import { createSagaAction } from '@shared/sagas';
import { createReducer } from '@shared/reducers';

// Constants
export const constants = {
  USER_GET: createSagaAction('USER_GET'),
  USER_PUT: createSagaAction('USER_PUT'),
  USER_PUT_NAME: createSagaAction('USER_PUT_NAME'),
  CREATE_GOOGLE_ACCOUNT: createSagaAction('CREATE_GOOGLE_ACCOUNT'),
  SEND_WELCOME_LETTER: createSagaAction('SEND_WELCOME_LETTER'),
  SEND_FIRST_DAY_INSTRUCTIONS: createSagaAction('SEND_FIRST_DAY_INSTRUCTIONS'),
  SEARCH_USER_BY_EMAIL: createSagaAction('SEARCH_USER_BY_EMAIL'),
  SEARCH_USER_BY_NAME: createSagaAction('SEARCH_USER_BY_NAME'),
};

// Action Creators
export const actions = {
  getbyId: (userId) => ({
    type: constants.USER_GET.REQUEST,
    userId,
  }),
  updateById: (args) => ({
    type: constants.USER_PUT.REQUEST,
    ...args,
  }),
  updateNameById: (args) => ({
    type: constants.USER_PUT_NAME.REQUEST,
    ...args,
  }),
  createGoogleAccount: (userId) => ({
    type: constants.CREATE_GOOGLE_ACCOUNT.REQUEST,
    userId,
  }),
  sendWelcomeLetter: (userId, email, role, title) => ({
    type: constants.SEND_WELCOME_LETTER.REQUEST,
    userId,
    email,
    role,
    title,
  }),
  sendFirstDayInstructions: (userId) => ({
    type: constants.SEND_FIRST_DAY_INSTRUCTIONS.REQUEST,
    userId,
  }),
  searchByEmail: (email) => ({
    type: constants.SEARCH_USER_BY_EMAIL.REQUEST,
    email,
  }),
  searchByName: (name) => ({
    type: constants.SEARCH_USER_BY_NAME.REQUEST,
    name,
  }),
};

// Reducer
export const initialState = {
  isLoading: false,
  error: null,
  user: {},
  searchResult: {
    byEmail: [],
    byName: [],
  },
};

const ACTION_HANDLERS = {
  // USER_GET
  [constants.USER_GET.SUCCESS]: (state, { user }) => {
    return { ...state, user };
  },
  [constants.USER_GET.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  // USER_PUT
  [constants.USER_PUT.SUCCESS]: (state) => {
    return { ...state, isLoading: false };
  },
  [constants.USER_PUT.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  // CREATE_GOOGLE_ACCOUNT
  [constants.CREATE_GOOGLE_ACCOUNT.SUCCESS]: (state) => {
    return { ...state, isLoading: false };
  },
  [constants.CREATE_GOOGLE_ACCOUNT.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  // SEND_WELCOME_LETTER
  [constants.SEND_WELCOME_LETTER.SUCCESS]: (state) => {
    return { ...state, isLoading: false };
  },
  [constants.SEND_WELCOME_LETTER.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  // SEND_FIRST_DAY_INSTRUCTIONS
  [constants.SEND_FIRST_DAY_INSTRUCTIONS.SUCCESS]: (state) => {
    return { ...state, isLoading: false };
  },
  [constants.SEND_FIRST_DAY_INSTRUCTIONS.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  // SEARCH_USER_BY_EMAIL
  [constants.SEARCH_USER_BY_EMAIL.SUCCESS]: (state, { searchResult }) => {
    return { ...state, searchResult: { ...state.searchResult, byEmail: [...searchResult] } };
  },
  [constants.SEARCH_USER_BY_EMAIL.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
  // SEARCH_USER_BY_NAME
  [constants.SEARCH_USER_BY_NAME.SUCCESS]: (state, { searchResult }) => {
    return { ...state, searchResult: { ...state.searchResult, byName: [...searchResult] } };
  },
  [constants.SEARCH_USER_BY_NAME.FAILURE]: (state, { error }) => {
    return { ...state, error };
  },
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
