import { z } from 'zod';
import { CommentTagSchema } from '../base';

export const CommentTagUpdateSchema = CommentTagSchema.pick({
  comment_tag_id: true,
  name: true,
  description: true,
  emoji: true,
  color: true,
}).partial({
  color: true,
  description: true,
  name: true,
  emoji: true,
});
export type CommentTagUpdate = z.infer<typeof CommentTagUpdateSchema>;
