//Redux
import { put, takeEvery } from 'redux-saga/effects';
import { createSagaAction } from '@shared/sagas';

//MODULES
const constants = {
  ASSIGNMENTS_REPORT_ON_CLEAR_ALL_SKILL_TAG_FILTER: createSagaAction(
    'ASSIGNMENTS_REPORT_ON_CLEAR_ALL_SKILL_TAG_FILTER',
  ),
};

const actions = {
  onClearAllSkillTagFilter: () => ({
    type: constants.ASSIGNMENTS_REPORT_ON_CLEAR_ALL_SKILL_TAG_FILTER.REQUEST,
  }),
};

const handlers = {
  [constants.ASSIGNMENTS_REPORT_ON_CLEAR_ALL_SKILL_TAG_FILTER.REQUEST]: (state) => {
    return { ...state };
  },
  [constants.ASSIGNMENTS_REPORT_ON_CLEAR_ALL_SKILL_TAG_FILTER.SUCCESS]: (state) => {
    const newState = { ...state };
    newState.skillTagFilter = [];
    return newState;
  },
  [constants.ASSIGNMENTS_REPORT_ON_CLEAR_ALL_SKILL_TAG_FILTER.FAILURE]: (state) => {
    return { ...state };
  },
};

//SAGA
function* onClearAllSkillTagFilter() {
  try {
    yield put({
      type: constants.ASSIGNMENTS_REPORT_ON_CLEAR_ALL_SKILL_TAG_FILTER.SUCCESS,
    });
  } catch (e) {
    yield put({
      type: constants.ASSIGNMENTS_REPORT_ON_CLEAR_ALL_SKILL_TAG_FILTER.FAILURE,
      message: e.message || e,
    });
  }
}

export function* watchOnClearAllSkillTagFilter() {
  yield takeEvery(
    constants.ASSIGNMENTS_REPORT_ON_CLEAR_ALL_SKILL_TAG_FILTER.REQUEST,
    onClearAllSkillTagFilter,
  );
}

//INDEX
export default {
  actions,
  constants,
  handlers,
  watcher: watchOnClearAllSkillTagFilter,
};
