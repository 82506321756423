import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { dateString } from '../../infrastructure/schema';

export const BulkPositionSignOppExtensionRequestSchema = z.object({
  positions_id: z.array(z.string()),
  project_id: z.string(),
  dt_opp_extension_signed: dateString,
});
export type BulkPositionSignOppExtensionRequest = z.infer<
  typeof BulkPositionSignOppExtensionRequestSchema
>;

export type BulkPositionSignOppExtensionResponse = void;

export type BulkPositionSignOppExtensionEndpoint = Endpoint<
  Method.POST,
  `/api/v2/positions/bulk-opp-extension`,
  BulkPositionSignOppExtensionResponse,
  undefined,
  undefined,
  BulkPositionSignOppExtensionRequest
>;
