import { z } from 'zod';
import { BaseSchemaDW } from '../structure';

export const ApplicationStageDWSchema = z
  .object({
    stage_name: z.string(),
    stage_id: z.number().optional(),
  })
  .merge(BaseSchemaDW);
export type ApplicationStageDW = z.infer<typeof ApplicationStageDWSchema>;

export const ApplicationStageStatusDWSchema = z
  .object({
    stage_id: z.number(),
    status_code: z.number(),
  })
  .merge(BaseSchemaDW);
export type ApplicationStageStatusDW = z.infer<typeof ApplicationStageStatusDWSchema>;

export const ApplicationStageWorkflowDWSchema = z.object({
  outcome: z.string().nullable(),
  stage_id: z.number(),
  application_id: z.number(),
  start_date: z.date(),
  end_date: z.date(),
  is_current: z.boolean(),
  person_termination: z.number(),
  cycle_id: z.number(),
});
export type ApplicationStageWorkflowDW = z.infer<typeof ApplicationStageWorkflowDWSchema>;

export const ApplicationStageMongoSchema = z.object({
  name: z.string(),
  shortName: z.string().nullish(),
  id: z.string(),
  optgroup: z.string().nullish(),
  status: z.number().nullish(),
  aggregates: z.array(z.number()).nullable(),
});

export type ApplicationStageMongo = z.infer<typeof ApplicationStageMongoSchema>;
