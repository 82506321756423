import { z } from 'zod';
import {
  Endpoint,
  Method,
  FilterOperations,
  ReportsQuerySchema,
  makeSorter,
  ReportResponse,
} from '../../infrastructure';

export type ListPDFsByPeopleEndpoint = Endpoint<
  Method.GET,
  '/api/v2/pdfs/people',
  ListPDFsByPeopleResponse,
  undefined,
  ListPDFsByPeopleQuery,
  undefined
>;

export const ListPDFsByPeopleFiltersSchema = z
  .object({
    person_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    year: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
  })
  .partial();
export type ListPDFsByPeopleFilters = z.infer<typeof ListPDFsByPeopleFiltersSchema>;

export const ListPDFsByPeopleSorterSchema = makeSorter(
  z.enum(['firstname', 'surname', 'amount_spent', 'amount_available']),
);
export type ListPDFsByPeopleSorter = z.infer<typeof ListPDFsByPeopleSorterSchema>;

export const ListPDFsByPeopleQuerySchema = ReportsQuerySchema.extend({
  sorter: ListPDFsByPeopleSorterSchema.optional(),
  filters: ListPDFsByPeopleFiltersSchema.optional(),
});
export type ListPDFsByPeopleQuery = z.infer<typeof ListPDFsByPeopleQuerySchema>;

export const ListPDFsByPeopleSchema = z.object({
  person: z.object({
    _id: z.string(),
    name: z.string(),
    firstname: z.string(),
    surname: z.string(),
    gravatarURL: z.string(),
  }),
  year: z.number(),
  amount_spent: z.number().finite(),
  amount_available: z.number().finite(),
});

export type ListPDFByPeople = z.infer<typeof ListPDFsByPeopleSchema>;

export type ListPDFsByPeopleResponse = ReportResponse<ListPDFByPeople>;
