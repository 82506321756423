import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const PrjGoogleCalendarDeleteParamsSchema = z.object({
  prj_google_calendar_id: z.coerce.number(),
});
export type PrjGoogleCalendarDeleteParams = z.infer<typeof PrjGoogleCalendarDeleteParamsSchema>;

export type PrjGoogleCalendarDeleteResponse = {};

export type PrjGoogleCalendarDeleteEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/prj-google-calendars/${PrjGoogleCalendarDeleteParams['prj_google_calendar_id']}`,
  PrjGoogleCalendarDeleteResponse,
  PrjGoogleCalendarDeleteParams,
  undefined,
  undefined
>;
