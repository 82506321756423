import React, { useEffect } from 'react';

import { Button } from 'antd';
import { ReloadOutlined, SlackOutlined } from '@ant-design/icons';

import history from '@src/history';
import config from '@src/config';
import './ErrorBoundaryFallback.less';

const ErrorBoundaryFallback = ({ resetError }) => {
  useEffect(() => {
    const unlistenHistory = history.listen(resetError);
    return () => {
      unlistenHistory();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="error-boundary-container">
      <img src="/assets/images/error-boundary-image.svg" alt="error boundary" />
      <p className="title">An unexpected error has occured. Please try again later.</p>
      <p className="subtitle">
        The Internal Team has been notified about this error, but you can submit further details in
        the{' '}
        <a href={`https://slack.com/app_redirect?channel=${config.SLACK_INTERNAL_TEAM}`}>
          Internal Channel on Slack <SlackOutlined />
        </a>
        .
      </p>

      <Button
        type="primary"
        onClick={() => {
          resetError();
        }}
      >
        <ReloadOutlined /> Try again
      </Button>
    </div>
  );
};

export default ErrorBoundaryFallback;
