import { all, fork, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';
import * as api from '@redux/api/job-titles';
import { constants } from '@redux/modules/job-titles';

//GET JOB TITLES
function* getJobTitles(action) {
  try {
    const response = yield call(api.getJobTitles, action);
    const jobTitles = response.data;

    yield put({ type: constants.JOB_TITLES_LIST.SUCCESS, jobTitles });
  } catch (error) {
    yield put({
      type: constants.JOB_TITLES_LIST.FAILURE,
      error: error.message || error,
    });
  }
}

function* watchGetJobTitles() {
  yield takeLatest(constants.JOB_TITLES_LIST.REQUEST, getJobTitles);
}

//JOB_TITLES_CREATE
function* createJobTitle(action) {
  try {
    const createJobTitleResponse = yield call(api.createJobTitle, action);
    const jobTitlesResponse = yield call(api.getJobTitles, action);
    const jobTitles = jobTitlesResponse.data;

    yield put({ type: constants.JOB_TITLES_LIST.SUCCESS, jobTitles });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(createJobTitleResponse.jobId);
    }
  } catch (error) {
    yield put({
      type: constants.JOB_TITLES_LIST.FAILURE,
      error: error.message || error,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(error.message || error);
    }
  }
}

function* watchCreateJobTitle() {
  yield takeLatest(constants.JOB_TITLES_CREATE.REQUEST, createJobTitle);
}

//UPDATE_JOB
function* updateJobTitle(action) {
  try {
    const response = yield call(api.updateJobTitle, action);
    const updatedJobTitle = response.data;

    yield put({ type: constants.JOB_TITLES_UPDATE.SUCCESS, updatedJobTitle });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(updatedJobTitle);
    }
  } catch (error) {
    yield put({
      type: constants.JOB_TITLES_UPDATE.FAILURE,
      error: error.message || error,
    });
    if (isFunction(action.onFail)) {
      yield action.onFail(error.message || error);
    }
  }
}

function* watchUpdateJobTitle() {
  yield takeLatest(constants.JOB_TITLES_UPDATE.REQUEST, updateJobTitle);
}

//SORT
function* sortJobTitles(action) {
  try {
    const response = yield call(api.sortJobTitles, action);

    yield put({
      type: constants.JOB_TITLES_SORT.SUCCESS,
      jobTitles: response.data,
    });
  } catch (e) {
    yield put({
      type: constants.JOB_TITLES_SORT.FAILURE,
      error: e.message || e,
    });
  }
}

export function* watchSortJobTitles() {
  yield takeLatest(constants.JOB_TITLES_SORT.REQUEST, sortJobTitles);
}

function* deleteJobTitle(action) {
  try {
    yield call(api.deleteJobTitle, action);
    yield put({ type: constants.JOB_TITLES_DELETE.SUCCESS });
    const response = yield call(api.getJobTitles, action);
    yield put({
      type: constants.JOB_TITLES_LIST.SUCCESS,
      jobTitles: response.data,
    });
  } catch (e) {
    yield put({
      type: constants.JOB_TITLES_DELETE.FAILURE,
      error: e.message || e,
    });
  }
}

function* watchDeleteJobTitle() {
  yield takeEvery(constants.JOB_TITLES_DELETE.REQUEST, deleteJobTitle);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetJobTitles),
    fork(watchCreateJobTitle),
    fork(watchUpdateJobTitle),
    fork(watchSortJobTitles),
    fork(watchDeleteJobTitle),
  ]);
}
