import * as api from '@redux/api/application';
import * as schemas from '@src/schemas';
import { actions as entitiesActions } from '@redux/modules/entities';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';
import { normalize } from 'normalizr';
import isFunction from 'lodash/isFunction';

function* updateUserOfferTerms(action) {
  try {
    const response = yield call(api.updateUserOfferTerms, action);
    const normalized = normalize(response.application, schemas.application);
    const { users, applications } = normalized.entities;

    yield put(entitiesActions.mergeApplications(applications));

    yield put(entitiesActions.mergeUsers(users));

    yield put({
      type: constants.APPLICATION_USER_OFFER_TERMS_UPDATE.SUCCESS,
    });
    if (isFunction(action.onSuccess)) {
      yield action.onSuccess(response.message);
    }
    const timelineResponse = yield call(api.getApplicationTimelineList, action);
    const timelineList = (timelineResponse && timelineResponse.data) || [];
    yield put({ type: constants.APPLICATION_TIMELINES_GET.SUCCESS, timelineList });
  } catch (e) {
    yield put({
      type: constants.APPLICATION_USER_OFFER_TERMS_UPDATE.FAILURE,
      error: e.message || e,
    });
    if (isFunction(action.onError)) {
      yield action.onError(e.message || e);
    }
  }
}

export function* watchUpdateUserOfferTerms() {
  yield takeLatest(constants.APPLICATION_USER_OFFER_TERMS_UPDATE.REQUEST, updateUserOfferTerms);
}
