import { z } from 'zod';
import { AssignmentSchema } from './base';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';
import { dateString } from '../../infrastructure/schema';

//Base
export const ListActiveAssignmentsByProjectSchema = AssignmentSchema.extend({
  client_id: z.string(),
  position_name: z.string(),
  person_name: z.string(),
  person_salary: z.number().nullish(),
  person_rev: z.number().nullish(),
  person_cost: z.number().nullish(),
  person_margin: z.number(),
  person_color: z.string(),
  person_timezone: z.string(),
  person_isbillable: z.boolean(),
});
export type ListActiveAssignmentsByProject = z.infer<typeof ListActiveAssignmentsByProjectSchema>;

//Params
export const ListActiveAssignmentsByProjectParamsSchema = z.object({
  project_id: z.string(),
});

export type ListActiveAssignmentsByProjectParams = z.infer<
  typeof ListActiveAssignmentsByProjectParamsSchema
>;

//Filters
export const ListActiveAssignmentsByProjectFiltersSchema = z.object({
  dt_start: z
    .record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), z.union([z.number(), dateString]))
    .optional(),
  dt_end: z
    .record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.union([z.number(), dateString]))
    .optional(),
});
export type ListActiveAssignmentsByProjectFilters = z.infer<
  typeof ListActiveAssignmentsByProjectFiltersSchema
>;

//Query
export const ListActiveAssignmentsByProjectQuerySchema = z.object({
  filters: ListActiveAssignmentsByProjectFiltersSchema.optional(),
});
export type ListActiveAssignmentsByProjectQuery = z.infer<
  typeof ListActiveAssignmentsByProjectQuerySchema
>;

//Reponse
export type ListActiveAssignmentsByProjectResponse = ListActiveAssignmentsByProject[];

//Endpoint
export type ListActiveAssignmentsByProjectEndpoint = Endpoint<
  Method.GET,
  '/api/v2/assignments/all-active',
  ListActiveAssignmentsByProjectResponse,
  ListActiveAssignmentsByProjectParams,
  ListActiveAssignmentsByProjectQuery,
  undefined
>;
