const ACTION_MAP = {
  // api-function-name: saga-action-name
  getMessages: 'MESSAGES_GET',
  postMessage: 'MESSAGE_POST',
  deleteMessage: 'MESSAGE_DELETE',
  updateMessage: 'MESSAGE_UPDATE',
  togglePinComment: 'MESSAGE_TOGGLE_PIN',
  openCommentsModal: 'COMMENTS_MODAL_OPEN',
  closeCommentsModal: 'COMMENTS_MODAL_CLOSE',
  processSocketUpdate: 'PROCESS_SOCKET_UPDATE',
};

export default ACTION_MAP;
