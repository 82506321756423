import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const CTCalSchema = z.object({
  dt: dateString,
  ymonth: z.number(),
  nday: z.number(),
  daytype: z.string(),
  dtcreate: dateString,
  dtupdate: dateString.nullable(),
});
export type CTCal = z.infer<typeof CTCalSchema>;
