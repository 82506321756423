import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const PayoutDownloadMstSchema = z.object({
  pdlf_id: z.coerce.number(),
  dt_download: dateString,
  payment_provider_id: z.number(),
  ymonth: z.number(),
  num_payouts: z.number(),
  num_persons: z.number(),
  file_amount: z.number(),
  dt_confirmed: z.date(),
  dtcreate: dateString,
  dtupdate: dateString,
  csv_url: z.string(),
});

export type PayoutDownloadMst = z.infer<typeof PayoutDownloadMstSchema>;
