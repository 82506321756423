import { z } from 'zod';
import { Lead, LeadSchema } from './base';
import { Endpoint, Method } from '../../infrastructure';

export const LeadPostPoneParamsSchema = LeadSchema.pick({
  project_id: true,
});
export type LeadPostPoneParams = z.infer<typeof LeadPostPoneParamsSchema>;

export type LeadPostPoneResponse = Lead;

export type LeadPostPoneEndpoint = Endpoint<
  Method.POST,
  `/api/sql-leads/${LeadPostPoneParams['project_id']}/post-pone`,
  LeadPostPoneResponse,
  LeadPostPoneParams,
  undefined,
  undefined
>;
