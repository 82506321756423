import { z } from 'zod';
import { Endpoint, Method } from '../../..';
import { Forecast } from '../';

export const ForecastListFiltersSchema = z.object({
  yyear: z.coerce.number(),
});
export type ForecastListFilters = z.infer<typeof ForecastListFiltersSchema>;

export const ForecastListQuerySchema = z.object({
  filters: ForecastListFiltersSchema.partial(),
});
export type ForecastListQuery = z.infer<typeof ForecastListQuerySchema>;

export type ForecastListResponse = Forecast[];

export type ForecastListEndpoint = Endpoint<
  Method.GET,
  '/api/v2/forecasts/',
  ForecastListResponse,
  undefined,
  ForecastListQuery,
  undefined
>;
