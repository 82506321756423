import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';
import { PayoutSchema } from './base';

export const CreatePayoutRequestSchema = PayoutSchema.pick({
  person_id: true,
  created_by: true,
  payout_type_code: true,
  acct_cost_code: true,
  amount: true,
  description: true,
  target_pay_date: true,
  request_date: true,
  paid_date: true,
  is_paid_immediately: true,
  meta: true,
}).partial({
  description: true,
  request_date: true,
  acct_cost_code: true,
  paid_date: true,
  is_paid_immediately: true,
  meta: true,
});
export type CreatePayoutRequest = z.infer<typeof CreatePayoutRequestSchema>;

export type CreatePayoutEndpoint = Endpoint<
  Method.POST,
  '/api/v2/payouts',
  {},
  undefined,
  undefined,
  CreatePayoutRequest
>;
