import * as api from '@redux/api/application';
import * as schemas from '@src/schemas';
import { actions as entitiesActions } from '@redux/modules/entities';
import { call, put, takeLatest } from 'redux-saga/effects';
import { constants } from '@redux/modules/application/application';
import { normalize } from 'normalizr';

type UpdateMgmtReviewedApiResponse = Awaited<ReturnType<typeof api.updateApplicationMgmtReviewed>>;
type UpdateMgmtReviewedAction = Parameters<typeof api.updateApplicationMgmtReviewed>[0];

export function* updateApplicationMgmtReviewed(action: UpdateMgmtReviewedAction) {
  try {
    const response: UpdateMgmtReviewedApiResponse = yield call(
      api.updateApplicationMgmtReviewed,
      action,
    );
    const normalized = normalize(response.application, schemas.application);
    const { users, applications } = normalized.entities;

    yield put(entitiesActions.mergeApplications(applications));

    yield put(entitiesActions.mergeUsers(users));

    yield put({
      type: constants.APPLICATION_MGMT_REVIEWED_UPDATE.SUCCESS,
    });
  } catch (e) {
    const error = e instanceof Error ? e.message : e;

    yield put({
      type: constants.APPLICATION_MGMT_REVIEWED_UPDATE.FAILURE,
      error,
    });
  }
}

export function* watchUpdateApplicationMgmtReviewed() {
  // @ts-ignore
  yield takeLatest(
    constants.APPLICATION_MGMT_REVIEWED_UPDATE.REQUEST,
    updateApplicationMgmtReviewed,
  );
}
