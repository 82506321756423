import { z } from 'zod';
import { CSVEndpoint } from '../../infrastructure';
import { FilterOperations } from '@ct-internal/api';
import { CSVPaymentsQuery } from './csvPayments';

export type PDFPaymentsEndpoint = CSVEndpoint<'/api/v2/sql-reports/payments.zip', CSVPaymentsQuery>;

export const PDFPaymentsFilterSchema = z.object({
  confirmed: z.record(z.enum([FilterOperations.EQUAL]), z.boolean()).optional(),
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
  payment_provider_id: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
});

export type PDFPaymentsFilter = z.infer<typeof PDFPaymentsFilterSchema>;

export const PDFPaymentsQuerySchema = z.object({
  filters: PDFPaymentsFilterSchema,
});

export type PDFPaymentsQuery = z.infer<typeof PDFPaymentsQuerySchema>;
