import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const AddRevenueRateSchema = z.object({
  project_id: z.string(),
  position_id: z.string(),
  dtfrom: dateString,
  revenueamount: z.number(),
});
export type AddRevenueRate = z.infer<typeof AddRevenueRateSchema>;
