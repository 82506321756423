import { put, takeLatest } from 'redux-saga/effects';

import { createSagaAction } from '@shared/sagas';

// MODULE

const constants = {
  SEARCH_ERROR_DELETE: createSagaAction('SEARCH_ERROR_DELETE'),
};

const actions = {
  deleteError: (data) => ({
    ...data,
    type: constants.SEARCH_ERROR_DELETE.REQUEST,
  }),
};

const handlers = {
  [constants.SEARCH_ERROR_DELETE.REQUEST]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [constants.SEARCH_ERROR_DELETE.SUCCESS]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
  [constants.SEARCH_ERROR_DELETE.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, error };
  },
};

// SAGA

function* deleteError() {
  yield put({
    type: constants.SEARCH_ERROR_DELETE.SUCCESS,
    error: null,
  });
}

export function* watchDeleteError() {
  yield takeLatest(constants.SEARCH_ERROR_DELETE.REQUEST, deleteError);
}

// INDEX

export default {
  actions,
  constants,
  handlers,
  watcher: watchDeleteError,
};
