import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';
import { Row, Col } from 'antd';

import config from '@src/config';

import { actions as authActions } from '@redux/modules/authentication';

import LoginButton from '@components/MainMenu/LoginButton';
import { isDynamicEnv } from '@src/shared/Util';

const propTypes = {};

const EnsureAuthenticatedUser = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  // redux state
  const user = useSelector((state) => state.authentication.user);
  const fingerprint = useSelector((state) => state.authentication.fingerprint);

  const isDynEnv = isDynamicEnv(config.FRONTEND_BASE);

  const logout = () => {
    dispatch(authActions.logout());
  };

  // render
  if (!user) {
    const loginProps = {
      location: location,
      user,
      fingerprint,
      login: (data) => dispatch(authActions.login(data)),
      logout,
    };

    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: 'calc( 100vh - 64px - 69px - 24px)',
          minHeight: '25rem',
        }}
      >
        <Col span={isDynEnv ? 12 : 6}>
          <h3>Unauthorized</h3>
          {isDynEnv ? <DynamicEnvLogin /> : <LoginSection {...loginProps} />}
        </Col>
      </Row>
    );
  }

  if (!user) {
    return <Redirect to="/" />;
  }

  return props.children || null;
};

EnsureAuthenticatedUser.propTypes = propTypes;
export default EnsureAuthenticatedUser;

const LoginSection = (props) => {
  return (
    <>
      <p>Sorry, you have to be logged in to get access to the page.</p>
      <p>Please use button below to log in.</p>

      <LoginButton {...props} className="ant-btn ant-btn-primary" style={{}} />
    </>
  );
};

const DynamicEnvLogin = () => {
  return (
    <>
      <p>
        Sorry, but the only way to be authenticated in a dynamic env is via the Dynamic Environment
        Manager.
      </p>
      <p>
        Please go to{' '}
        <a href="https://dyn-envs-manager.clevertech.dev/">
          https://dyn-envs-manager.clevertech.dev
        </a>{' '}
        and click on the link of this environment to be authenticated.
      </p>
      <p>
        If you came from the manager and it didn't log in immediately, it could be because the env's
        backend was off and it's getting back online. You can check the progress{' '}
        <a
          href="https://github.com/clevertech-int/internal/actions/workflows/dynamic_env_manager.yml"
          target="_blank"
          rel="noreferrer"
        >
          here.
        </a>{' '}
        After it finishes, wait around 5 mins and just reload this page or click the link from the
        manager again and you'll be able to log in :)
      </p>
    </>
  );
};
