import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const PositionBulkTargetCostSchema = z.object({
  project_id: z.string(),
  positions: z.array(z.string()),
  target_cost: z.number(),
});

export type PositionBulkTargetCostBody = z.infer<typeof PositionBulkTargetCostSchema>;

export type PositionBulkTargetCostResponse = {};

export type PositionBulkTargetCostEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/positions/bulk-target-cost`,
  PositionBulkTargetCostResponse,
  undefined,
  undefined,
  PositionBulkTargetCostBody
>;
