import { z } from 'zod';
import { stringToBoolean } from '../../infrastructure/schema';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';

export const GetPosAppMatchItemSchema = z.object({
  project_id: z.string(),
  skills: z.array(z.string()),
  stage: z.string(),
  count: z.number(),
});
export type GetPosAppMatchItem = z.infer<typeof GetPosAppMatchItemSchema>;

export const GetPosAppMatchFiltersSchema = z.object({
  project_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  position_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  is_stale: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean).optional(),
  is_aged: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean).optional(),
});
export type GetPosAppMatchFilters = z.infer<typeof GetPosAppMatchFiltersSchema>;

//Query
export const GetPosAppMatchQuerySchema = z.object({
  filters: GetPosAppMatchFiltersSchema.optional(),
});
export type GetPosAppMatchQuery = z.infer<typeof GetPosAppMatchQuerySchema>;

export type GetPosAppMatchResponse = GetPosAppMatchItem[];

export type GetPosAppMatchEndpoint = Endpoint<
  Method.GET,
  `/api/v2/positions/pos-app-match`,
  GetPosAppMatchResponse,
  undefined,
  GetPosAppMatchQuery,
  undefined
>;
