import { PersonSchema } from '../people';
import { AssignmentSchema } from '../assignment';
import { z } from 'zod';
import { PsnPrjGoogleCalendarSchema } from '../psn_prj_google_calendar';
import { dateString } from '../../infrastructure';

export enum AsnActionQueueType {
  'STARTING' = 'STARTING',
  'ENDING' = 'ENDING',
}
export const AsnActionQueueSchema = AssignmentSchema.pick({
  assignment_id: true,
}).extend({
  action_type: z.nativeEnum(AsnActionQueueType),
  dt_created: dateString,
});
export type AsnActionQueue = z.infer<typeof AsnActionQueueSchema>;
